import React, { useCallback, useEffect, useState } from 'react';
import PDF from '../../../common/PDF';
import Excel from '../../../common/Excel';
import Util from '../../../common/Util';
import toast from 'react-hot-toast';
import { Note } from "../../../common/Note";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from 'react-redux';
import {
	selectAuth,
	updateNavigation,
	assignAuthorizersAsync,
	unAssignAuthorizersAsync,
	deleteAmountRangeAsync,
	toggleSidebarCollapsed,
	skipProcessAsync,
	fetchAllUsersAsync,
	fetchAllAmountRangesAsync
} from '../../auth/authSlice';
import { resources } from '../../../resources/index';
import {
	Button,
	ButtonGroup,
	Card,
	Icon
} from '@blueprintjs/core';
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/table/lib/css/table.css';
import TextField from "@material-ui/core/TextField";
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { AuthorizerSpreadsheet } from '../../../spreadsheets/AuthorizerSpreadsheet';

export function Authorizer() {

	const auth = useSelector(selectAuth);
	const history = useHistory();
	const translate = resources['lang'][auth.locale];
	const dispatch = useDispatch();
	//const [current_application] = useState(auth.applications.find((app) => app.name===translate.text.ASSIGN_AUTHORIZER));
	const [document_type, setDocumentType] = useState(null);
	const [levels] = useState([
		{value:1, label:'Level 1 Authorizer/s'},
		{value:2, label:'Level 2 Authorizer/s'},
		{value:3, label:'Level 3 Authorizer/s'},
		{value:4, label:'Level 4 Authorizer/s'},
		{value:5, label:'Level 5 Authorizer/s'}
	]);
	const [level, setLevel] = useState(null);
	const [selected_users, setSelectedUsers] = useState([]);
	const [start_amount, setStartAmount] = useState('');
	const [end_amount, setEndAmount] = useState('');
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);
	const [authorizers, setAuthorizers] = useState([]);
	const [approval_options] = useState(Object.values(auth.enums.approval_setup).map((as) => ({label:as, value:as})));
	const [approval_setup, setApprovalSetup] = useState(approval_options.find((ao) => ao.value===auth.enums.approval_setup.ENABLE_APPROVAL));
	const [users_to_unassign, setUsersToUnAssign] = useState([]);
	const [document_types] = useState(auth.document_types.filter((dt) => dt.name!==auth.enums.document_types.ITEM_LOCATION_TRANSFER).map((documentType) => ({label:documentType.name, value:documentType.id})));
	const [document_type_filter, setDocumentTypeFilter] = useState(null);
	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.AUTHORIZER));
		const handleResponse = (response) => {
			let dataSet = response.payload.data.map((ds) => [
				ds.document_type.module,
				ds.document_type.name,
				auth.enums.approval_setup.ENABLE_APPROVAL,
				`${ds.start_amount} to ${ds.end_amount}`,
				ds.authorizations[0],
				ds.authorizations[1],
				ds.authorizations[2],
				ds.authorizations[3],
				ds.authorizations[4],
			]);

			let disabledApprovalDocumentTypes = auth.document_types.filter((dt) => !dt.is_approval_enabled);
			if (disabledApprovalDocumentTypes.length>0) {
				dataSet = [...dataSet, ...disabledApprovalDocumentTypes.map((dt) => ([
					dt.module,
					dt.name,
					auth.enums.approval_setup.DISABLE_APPROVAL,
					'',
					'',
					'',
					'',
					'',
					'',
				]))];
			}

			setWorkSheet({
				is_readonly_mode: true,
				num_rows: 1000,
				num_cols: 10,
				num_frozen_columns: 0,
				import_limit: 700,
				focused_cell: null,
				selected_regions: null,
				header: translate.worksheet.default_header.AUTHORIZER,
				alignment: translate.worksheet.default_alignment.AUTHORIZER,
				comment: [],
				data_set: dataSet
			})
		}
		dispatch(fetchAllAmountRangesAsync({}))
		.then((response) => response.payload.data ? 
			handleResponse(response) : void(0)
		);
	}, [
		dispatch,
		translate.breadcrumb.AUTHORIZER,
		auth.document_types,
		auth.enums.approval_setup,
		translate.worksheet.default_alignment.AUTHORIZER,
		translate.worksheet.default_header.AUTHORIZER
	]);

	useEffect(() => {
		dispatch(fetchAllUsersAsync({account_status:auth.enums.account_status.VERIFIED})).then((response) => (response.payload.status===resources.status.SUCCESS ? setAuthorizers(response.payload.data.users.filter((usr) => usr.roles.includes(auth.enums.roles.AUTHORIZER)).map((user) => ({label:`${user.name}`, value:user.id}))) : void(0)));
	}, [
		dispatch,
		auth.enums.roles.AUTHORIZER,
		translate.text.ASSIGN_AUTHORIZER,
		auth.enums.account_status.VERIFIED
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const onDocumentTypeSelected = (documentType) => {
		let docType = auth.document_types.find((dt) => dt.name===documentType.label);
		let isApprovalEnabled = Util.stringToBoolean(docType.is_approval_enabled);
		setApprovalSetup(approval_options.find((ao) => (isApprovalEnabled===(ao.value===auth.enums.approval_setup.ENABLE_APPROVAL))));
		setDocumentType(document_types.find((dt) => (dt.value===documentType.value)));
	}

	const onApprovalSetupSelected = (approvalSetup) => {
		setSelectedUsers([]);
		setLevel(null);
		setStartAmount('');
		setEndAmount('');
		setApprovalSetup(approvalSetup);
	}

	const onClearButtonClicked = () => {
		setApprovalSetup(approval_options[0]);
		setDocumentType(null);
		setSelectedUsers([]);
		setLevel(null);
		setStartAmount('');
		setEndAmount('');
		setSelectedRows([]);
		setUsersToUnAssign([]);
		setDocumentTypeFilter(null);
	};

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const onSkipButtonClicked = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'authorizer'})).then((response) => response.payload.data && response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onDeleteRowsButtonClicked = () => {
		let rows = selected_rows.map(
			(row) => worksheet.data_set[row]
		);
		let promises = [];
		for (let i = 0; i < rows.length; i++) {
			let documentType = rows[i][1];
			let status = rows[i][2];
			let amount = rows[i][3];
			if (status===auth.enums.approval_setup.ENABLE_APPROVAL) {
				promises.push(
					dispatch(
						deleteAmountRangeAsync({
							document_type:documentType,
							amount:amount
						  })
					)
				);
			}
		}
		Promise.all(promises).then((response) => {
			var success = true;
			var message = null;
			for (let i = 0; i < response.length; i++) {
				if (response[i].payload !== undefined) {
					if (response[i].payload.status !== resources.status.SUCCESS) {
						success = false;
						break;
					}
					else {
						if (message == null) {
							message = response[i].payload.message;
						}
					}
				}
			}
			if (success === true) {
				toast.success(
					message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
				setSelectedRows([]);
			}
		});
	};

	const onUnassignButtonClicked = () => {
		const handleUnassignResponse = (response) => {
			let dataSet = response.payload.data.amount_ranges.map((ds) => [
				ds.document_type.name,
				auth.enums.approval_setup.ENABLE_APPROVAL,
				`${ds.start_amount} to ${ds.end_amount}`,
				ds.authorizations[0],
				ds.authorizations[1],
				ds.authorizations[2],
				ds.authorizations[3],
				ds.authorizations[4],
			]);

			let disabledApprovalDocumentTypes = auth.document_types.filter((dt) => !dt.is_approval_enabled);
			if (disabledApprovalDocumentTypes.length>0) {
				dataSet = [...dataSet, ...disabledApprovalDocumentTypes.map((dt) => ([
					dt.name,
					auth.enums.approval_setup.DISABLE_APPROVAL,
					'',
					'',
					'',
					'',
					'',
					'',
				]))];
			}

			setWorkSheet({
				is_readonly_mode: true,
				num_rows: 1000,
				num_cols: 10,
				num_frozen_columns: 0,
				import_limit: 700,
				focused_cell: null,
				selected_regions: null,
				header: translate.worksheet.default_header.AUTHORIZER,
				alignment: translate.worksheet.default_alignment.AUTHORIZER,
				comment: [],
				data_set: dataSet
			})
		}
		dispatch(
			unAssignAuthorizersAsync({
				document_type_filter:document_type_filter!=null ? document_type_filter.value : "",
				authorizers_to_unassign:users_to_unassign!=null ? users_to_unassign.map(user => user.value) : []
			  })
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUnassignResponse(response) : null))
	}

	const onSaveButtonClicked = () => {
		const handleSaveResponse = (response) => {
			let dataSet = response.payload.data.amount_ranges.map((ds) => [
				ds.document_type.name,
				auth.enums.approval_setup.ENABLE_APPROVAL,
				`${ds.start_amount} to ${ds.end_amount}`,
				ds.authorizations[0],
				ds.authorizations[1],
				ds.authorizations[2],
				ds.authorizations[3],
				ds.authorizations[4],
			]);

			let disabledApprovalDocumentTypes = auth.document_types.filter((dt) => !dt.is_approval_enabled);
			if (disabledApprovalDocumentTypes.length>0) {
				dataSet = [...dataSet, ...disabledApprovalDocumentTypes.map((dt) => ([
					dt.name,
					auth.enums.approval_setup.DISABLE_APPROVAL,
					'',
					'',
					'',
					'',
					'',
					'',
				]))];
			}

			setWorkSheet({
				is_readonly_mode: true,
				num_rows: 1000,
				num_cols: 10,
				num_frozen_columns: 0,
				import_limit: 700,
				focused_cell: null,
				selected_regions: null,
				header: translate.worksheet.default_header.AUTHORIZER,
				alignment: translate.worksheet.default_alignment.AUTHORIZER,
				comment: [],
				data_set: dataSet
			});
		}
		dispatch(
			assignAuthorizersAsync({
				approval_setup:approval_setup.value,
				start_amount:start_amount,
				end_amount:end_amount,
				document_type:document_type!=null ? document_type.value : null,
				level:level!=null ? level.value : null,
				authorizers:selected_users!=null ? selected_users.map(user => user.value) : []
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleSaveResponse(response) : null))
	};

	const onSaveAsPDFButtonClicked = () => {
		PDF.export(
			translate.worksheet.file_name.AUTHORIZER,
			worksheet.header,
			worksheet.data_set,
			auth.active_company,
			"a3",
			"landscape",
			[],
			[80, 120, 80, 100, "*", "*", "*", "*", "*"]
		);
	}

	const onSaveAsXLSXButtonClicked = () => {
		Excel.export(
			translate.worksheet.file_name.AUTHORIZER,
			worksheet.header,
			worksheet.data_set,
			auth.active_company
		);
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_ASSIGN_LABEL,
							value: translate.text.NOTE_ASSIGN_VALUE
						},
						{
							label: translate.text.NOTE_AUTHORIZERS_FIELD_LABEL,
							value: translate.text.NOTE_AUTHORIZERS_FIELD_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
						{
							label: translate.text.NOTE_DOCUMENT_TYPE_LABEL,
							value: translate.text.NOTE_DOCUMENT_TYPE_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSkipButtonClicked}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.ASSIGN_AUTHORIZER) }


				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={approval_setup.value===auth.enums.approval_setup.ENABLE_APPROVAL ? translate.button.ASSIGN : translate.button.SAVE}
						className="function-button"
						disabled={auth.status === resources.status.LOADING}
						icon={<Icon icon="floppy-disk" className="orange"/>}
						onClick={onSaveButtonClicked}
					/>
					<Button
						text={translate.button.CLEAR}
						className="function-button"
						icon={<Icon icon="reset" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={onClearButtonClicked}
					/>
				</ButtonGroup>

				<Card className="function-card">
					<div className="row">
						<div className="col-sm-12 col-md-3 col-lg-3 text-field">
							<MuiAutocomplete
								setSelectedValue={onDocumentTypeSelected}
								selected_value={document_type}
								isMultiple={false}
								label={"Document Type"}
								data={document_types}
							/>
						</div>
						<div className="col-sm-12 col-md-3 col-lg-3 text-field">
							<MuiAutocomplete
								setSelectedValue={onApprovalSetupSelected}
								selected_value={approval_setup}
								isMultiple={false}
								label={"Status"}
								data={approval_options}
							/>
						</div>
						{
							approval_setup.value===auth.enums.approval_setup.ENABLE_APPROVAL &&
							<div className="col-sm-12 col-md-2 col-lg-2 text-field">
								<MuiAutocomplete
									setSelectedValue={setLevel}
									selected_value={level}
									isMultiple={false}
									label={"Level"}
									data={levels}
								/>
							</div>
						}
						{
							approval_setup.value===auth.enums.approval_setup.ENABLE_APPROVAL &&
							<div className="col-sm-12 col-md-3 col-lg-3 text-field">
								<MuiAutocomplete
									setSelectedValues={setSelectedUsers}
									selected_values={selected_users}
									isMultiple={true}
									label={translate.placeholder.SELECT_AUTHORIZER}
									data={authorizers}
								/>
							</div>
						}
					</div>
					{
						approval_setup.value===auth.enums.approval_setup.ENABLE_APPROVAL &&
						<div className="row">
							<TextField
								className="col-sm-12 col-md-3 col-lg-2 text-field"
								size="small"
								variant="outlined"
								value={start_amount}
								onChange={(e) => setStartAmount(e.target.value)}
								label={"Start amount"}
								type="number"
								InputProps={{
									inputProps: {
										min: 0
									}
								}}
							/>
							<div className="col-sm-12 col-md-1 col-lg-1 text-field center authorizer-up-to">
								{translate.text.UP_TO}
							</div>
							<TextField
								className="col-sm-12 col-md-3 col-lg-2 text-field"
								size="small"
								variant="outlined"
								value={end_amount}
								onChange={(e) => setEndAmount(e.target.value)}
								label={"End amount"}
								type="number"
								InputProps={{
									inputProps: {
										min: 0
									}
								}}
							/>
						</div>
					}
				</Card>

				{ Util.functionTitle(translate.text.UNASSIGN_AUTHORIZER) }

				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={translate.button.UNASSIGN}
						className="function-button"
						disabled={auth.status === resources.status.LOADING}
						icon={<Icon icon="floppy-disk" className="orange"/>}
						onClick={onUnassignButtonClicked}
					/>
				</ButtonGroup>

				<Card className="function-card">
					<div className="row">
						<div className="col-sm-12 col-md-3 col-lg-3 text-field">
							<MuiAutocomplete
								setSelectedValue={setDocumentTypeFilter}
								selected_value={document_type_filter}
								isMultiple={false}
								label={"Filter by Document Type"}
								data={document_types}
							/>
						</div>
						<div className="col-sm-12 col-md-4 col-lg-4 text-field">
							<MuiAutocomplete
								setSelectedValues={setUsersToUnAssign}
								selected_values={users_to_unassign}
								isMultiple={true}
								label={translate.placeholder.SELECT_AUTHORIZER_TO_UNASSIGN}
								data={authorizers}
							/>
						</div>
					</div>
				</Card>

				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={translate.button.SAVE_AS_PDF}
						className="function-button"
						icon={<Icon icon="document" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={onSaveAsPDFButtonClicked}
					/>
					<Button
						text={translate.button.SAVE_AS_XLSX}
						className="function-button"
						icon={<Icon icon="th" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={onSaveAsXLSXButtonClicked}
					/>
					<Button
						text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
						disabled={auth.status === resources.status.LOADING}
						className="function-button"
						icon={<Icon icon="select" className="orange"/>}
						onClick={e => setSelectedRows(selected_rows.length>0 ? [] : [...Array(worksheet.data_set.length).keys()])}
					/>
					{
						selected_rows.length>0 &&
						<Button
							text={`${translate.button.DELETE_ROWS}  (${selected_rows.length})`}
							disabled={auth.status === resources.status.LOADING}
							className="function-button"
							icon={<Icon icon="delete" className="orange"/>}
							onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
						/>
					}
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onFreezePaneButtonClicked}
						/>
					}
				</ButtonGroup>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={onDeleteRowsButtonClicked}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>System will automatically conduct further verification if deletion of certain amount range/s is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<AuthorizerSpreadsheet
					enable_select={true}
					enable_open={false}
					enable_edit={false}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
				/>

			</div>
		</div>
	);

}
