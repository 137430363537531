import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { useParams } from "react-router";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createStockAdjustmentAsync,
	updateStockAdjustmentAsync,
	submitStockAdjustmentAsync,
	rejectStockAdjustmentAsync,
	approveStockAdjustmentAsync,
	voidStockAdjustmentAsync,
	fetchAllStockAdjustmentLineItemsAsync,
	toggleSidebarCollapsed,
	fetchAllItemsAsync,
	fetchAllItemClassesAsync,
	fetchAllLocationsAsync,
	fetchAllProfitCostCentersAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	H3,
	H5,
	FormGroup,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { StockAdjustmentLineItem } from "./StockAdjustmentLineItem";
import { SideBar } from '../../../SideBar';
import { Attachment } from "../../../common/Attachment";
import { Comment } from "../../../common/Comment";
import { TransactionLog } from "../../../common/TransactionLog";
import { useHistory } from "react-router-dom";
import { StockAdjustmentSpreadsheet } from "../../../spreadsheets/StockAdjustmentSpreadsheet";

export function StockAdjustment() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const default_line_items = Array(7).fill({
		id:"",
		item:null,
		increment:true,
		quantity:"",
		unit_cost:"",
		amount:"",
		uom:null,
		location:null,
		serial_numbers:[],
		expiry_dates:[],
		expiry_quantities:[],
		item_conditions:[],
	});
	const default_journal_entries = Array(1).fill({
		account_code:"",
		account_description:"",
		profit_cost_center:null,
		debit_amount:"",
		credit_amount:"",
	});
	const { document_id } = useParams();
	const [id, setId] = useState(document_id ? document_id : null);
	const [stock_adjustment, setStockAdjustment] = useState(null);
	const [line_items, setLineItems] = useState(default_line_items);
	const [document_date, setDocumentDate] = useState(stock_adjustment ? moment(stock_adjustment.document_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT) : moment(new Date()).format(resources.date_format.INPUT));
	const [reference_number, setReferenceNumber] = useState("");
	const [transaction_description, setTransactionDescription] = useState("");
	const [transaction_logs, setTransactionLogs] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_delete_dialog_open, setIsConfirmDeleteDialogOpen] = useState(false);
	const [is_confirm_submit_dialog_open, setIsConfirmSubmitDialogOpen] = useState(false);
	const [is_confirm_approve_dialog_open, setIsConfirmApproveDialogOpen] = useState(false);
	const [is_confirm_reject_dialog_open, setIsConfirmRejectDialogOpen] = useState(false);
	const [is_confirm_void_dialog_open, setIsConfirmVoidDialogOpen] = useState(false);
	const [profit_cost_centers, setProfitCostCenters] = useState([]);
	const [profit_cost_center, setProfitCostCenter] = useState(null);
	const [journal_entries, setJournalEntries] = useState(default_journal_entries);
	const [balancing_account, setBalancingAccount] = useState("");
	const [is_editable, setIsEditable] = useState(true);
	const [is_authorizable, setIsAuthorizable] = useState(document_id ? auth.approval_pipelines.find((pipe) => pipe.stock_adjustment_id===document_id)!==undefined : false);
	const [items, setItems] = useState([]);
	const [locations, setLocations] = useState([]);
	const [item_classes, setItemClasses] = useState([]);

	const [with_expiry_serialized, setHasExpirySerialized] = useState(false);
	const [with_expiry, setWithExpiry] = useState(false);
	const [serialized, setSerialized] = useState(false);

	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: translate.worksheet.default_header.STOCK_ADJUSTMENT,
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.STOCK_ADJUSTMENT));
	}, [
		dispatch,
		translate.breadcrumb.STOCK_ADJUSTMENT
	]);

	useEffect(() => {
		if (auth.worksheet.is_readonly_mode) {
			dispatch(fetchAllItemsAsync({})).then((response) => response.payload.data ? setItems(response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)))) : void(0));
			dispatch(fetchAllItemClassesAsync({})).then((response) => (response.payload.data ? setItemClasses(response.payload.data.item_classes.filter((ic) => Util.testDuplicateRegex(ic.name)).map((ic) => ({label:ic.name, value:ic.id}))) : void(0)));
			dispatch(fetchAllLocationsAsync({})).then((response) => response.payload.data ? setLocations(response.payload.data.locations) : void(0));
			const handleFetchProfitCostCenterResponse = (response) => {
				if (response.payload.data) {
					let branches = response.payload.data.branches.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((branch) => ({label:`${branch.code} | ${branch.name}`, type:branch.type, value:branch.id}));
					let departments = response.payload.data.departments.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((department) => ({label:`${department.code} | ${department.name}`, type:department.type, value:department.id}));
					let projects = response.payload.data.projects.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((project) => ({label:`${project.code} | ${project.name}`, type:project.type, value:project.id}));
					setProfitCostCenters(branches.concat(departments).concat(projects));
				}
			}
			dispatch(fetchAllProfitCostCentersAsync({})).then((response) => handleFetchProfitCostCenterResponse(response));
		}
	}, [
		dispatch,
		auth.worksheet.is_readonly_mode
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const loadDocument = useCallback((stockAdjustment, stockAdjustmentLineItems, journalEntries) => {
		let profitCostCenter = JSON.parse(stockAdjustment.profit_cost_center);
		profitCostCenter = profitCostCenter ? profit_cost_centers.find((pcc) => pcc.type===profitCostCenter.type && pcc.value===profitCostCenter.value) : null;
		setStockAdjustment(stockAdjustment);
		setId(stockAdjustment.id);
		setReferenceNumber(stockAdjustment.reference_number);
		setTransactionLogs(stockAdjustment.transaction_logs);
		setProfitCostCenter(profitCostCenter ? profitCostCenter : null);
		setTransactionDescription(stockAdjustment.description);
		setIsEditable(stockAdjustment.is_editable);
		setIsAuthorizable(stockAdjustment.id ? auth.approval_pipelines.find((pipe) => pipe.stock_adjustment_id===stockAdjustment.id)!==undefined : false);
		setLineItems( stockAdjustmentLineItems.map((stockAdjustmentLineItem) => ({
			id:stockAdjustmentLineItem.id,
			item:{
				label:stockAdjustmentLineItem.item.label, 
				default_uom_name:stockAdjustmentLineItem.item.unit_of_measure.code, 
				item_costing:stockAdjustmentLineItem.item.item_costing,
				serial_number_type:stockAdjustmentLineItem.item.serial_number_type, 
				value:stockAdjustmentLineItem.item.id
			},
			quantity:stockAdjustmentLineItem.quantity,
			increment:stockAdjustmentLineItem.operation===auth.enums.stock_adjustment_operations.INCREASE,
			unit_cost:stockAdjustmentLineItem.unit_cost,
			amount:stockAdjustmentLineItem.amount,
			uom:{label:stockAdjustmentLineItem.unit_of_measure.code, value:stockAdjustmentLineItem.unit_of_measure.id},
			location:{label:`${stockAdjustmentLineItem.location.code} | ${stockAdjustmentLineItem.location.name}`, value:stockAdjustmentLineItem.location.id},
			serial_numbers:stockAdjustmentLineItem.serial_numbers,
			expiry_dates:stockAdjustmentLineItem.expiry_dates.map((expDate) => (expDate.length>0 ? moment(expDate, resources.date_format.SYSTEM).format(resources.date_format.INPUT) : "")),
			expiry_quantities:stockAdjustmentLineItem.expiry_quantities.map((expQty) => (expQty.length>0 ? parseFloat(expQty) : 0)),
			item_conditions:stockAdjustmentLineItem.item_conditions,
		})) );
		setHasExpirySerialized(false);
		setWithExpiry(false);
		setSerialized(false);
		for (let i=0; i<stockAdjustmentLineItems.length; i++) {
			let stockAdjustmentLineItem = stockAdjustmentLineItems[i];
			if (stockAdjustment.approval_status===auth.enums.approval_status.DRAFT) {
				let inventoryItem = items.find((itm) => itm.id===stockAdjustmentLineItem.item.id);
				if (inventoryItem) {
					let hasExpiry = Util.stringToBoolean(inventoryItem.has_expiry);
					let withSerialNumber = Util.stringToBoolean(inventoryItem.with_serial_number);
					if (hasExpiry && withSerialNumber) {
						setHasExpirySerialized(true);
					} else 
					if (withSerialNumber) {
						if (inventoryItem.serial_number_type===auth.enums.serial_number_types.EXTERNAL) setSerialized(true);
					} else 
					if (hasExpiry) {
						setWithExpiry(true);
					}
				}
			}
		}
		setBalancingAccount(auth.enums.balancing_accounts.stock_adjustment.GENERAL);
		setJournalEntries(journalEntries.filter((journalEntry) => (journalEntry.stock_adjustment_id===stockAdjustment.id)).map((journalEntry) => ({
			account_code:journalEntry.account.code,
			account_description:journalEntry.account.description,
			profit_cost_center:stockAdjustment.profit_cost_center ? JSON.parse(stockAdjustment.profit_cost_center) : null,
			debit_amount:journalEntry.debit_amount,
			credit_amount:journalEntry.credit_amount,
		})));
	},[auth.enums.serial_number_types.EXTERNAL, auth.enums.balancing_accounts.stock_adjustment.GENERAL, auth.enums.stock_adjustment_operations, auth.approval_pipelines, profit_cost_centers, auth.enums.approval_status.DRAFT, items]);

	useEffect(() => {
		if (id) {
			dispatch(fetchAllStockAdjustmentLineItemsAsync({stock_adjustment_id:id})).then((response) => loadDocument(response.payload.data.stock_adjustment, response.payload.data.stock_adjustment_line_items, response.payload.data.journal_entries));
		}
	}, [dispatch, id, loadDocument]);

	const handleUpdateButtonClickedResponse = (response) => {
		let doc = response.payload.data.stock_adjustment;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.item_location_transfer.id ? [
				doc.id,
				doc.document_number,
				doc.document_date,
				doc.approval_status,
				doc.reference_number,
				doc.description,
				doc.profit_cost_center ? JSON.parse(doc.profit_cost_center).label : "",
				doc.profit_cost_center ? JSON.parse(doc.profit_cost_center).type : "",
				doc.created_at,
				doc.updated_at,
			] : ds))
		});
		loadDocument(response.payload.data.stock_adjustment, response.payload.data.stock_adjustment_line_items, response.payload.data.journal_entries);
		setAttachments([]);
	}

	const handleCreateButtonClickedResponse = (response) => {
		let doc = response.payload.data.stock_adjustment;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:[
				[
					doc.id,
					doc.document_number,
					doc.document_date,
					doc.approval_status,
					doc.reference_number,
					doc.description,
					doc.profit_cost_center ? JSON.parse(doc.profit_cost_center).label : "",
					doc.profit_cost_center ? JSON.parse(doc.profit_cost_center).type : "",
					doc.created_at,
					doc.updated_at,
				],
				...worksheet.data_set
			]
		});
		loadDocument(response.payload.data.stock_adjustment, response.payload.data.stock_adjustment_line_items, response.payload.data.journal_entries);
		setAttachments([]);
	}

	const clearInputFields = () => {
		setId(null);
		setStockAdjustment(null);
		setReferenceNumber("");
		setTransactionDescription("");
		setProfitCostCenter(null);
		setLineItems(default_line_items);
		setAttachments([]);
		setIsEditable(true);
		setIsAuthorizable(false);
		setSerialized(false);
		setWithExpiry(false);
		setHasExpirySerialized(false);
		setJournalEntries(default_journal_entries);
	}

	const handleClearButtonClickedEvent = () => {
		clearInputFields();
		history.push('/warehouse-inventory/stock-adjustment');
	};

	const refreshData = () => {
		handleClearButtonClickedEvent();
	}

	const handleDeleteRowsButtonClickedEvent = () => {
		let rows = selected_rows.map(
			(row) => auth.worksheet.data_set[row]
		);
		let promises = [];
		for (let i = 0; i < rows.length; i++) {
			promises.push(
				dispatch(
					voidStockAdjustmentAsync({
						id: rows[i][0]
					})
				)
			);
		}
		Promise.all(promises).then((response) => {
			var success = true;
			var message = null;
			for (let i = 0; i < response.length; i++) {
				if (response[i].payload !== undefined) {
					if (response[i].payload.status !== resources.status.SUCCESS) {
						success = false;
						break;
					}
					else {
						if (message == null) {
							message = response[i].payload.message;
						}
					}
				}
			}
			if (success === true) {
				toast.success(
					message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			handleClearButtonClickedEvent();
		});
	};

	const handleSubmitButtonClickedEvent = () => {
		dispatch(
			submitStockAdjustmentAsync({
				id:id,
				document_date:document_date,
				reference_number:reference_number,
				transaction_description:transaction_description,
				profit_cost_center:profit_cost_center,
				line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null)),
				attachments:attachments,
				operations:auth.enums.stock_adjustment_operations,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleApproveButtonClickedEvent = () => {
		dispatch(
			approveStockAdjustmentAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleRejectButtonClickedEvent = () => {
		dispatch(
			rejectStockAdjustmentAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleVoidButtonClickedEvent = () => {
		dispatch(
			voidStockAdjustmentAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleClearButtonClickedEvent() : null))
	};

	const handleEditButtonClickedEvent = (row) => {
		let id = worksheet.data_set[row][0];
		setId(id);
		window.scrollTo(0,0);
	};

	const handleProfitCostCenterChangedEvent = (profitCostCenter) => {
		setProfitCostCenter(profitCostCenter);
		setLineItems(line_items.map((li) => ({
			id:li.id,
			item:li.item,
			increment:li.increment,
			quantity:li.quantity,
			unit_cost:li.unit_cost,
			amount:li.unit_cost,
			uom:li.uom,
			location:null,
			serial_numbers:li.serial_numbers,
			expiry_dates:li.expiry_dates,
			expiry_quantities:li.expiry_quantities,
			item_conditions:li.item_conditions,
		})));
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>


				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
						{
							label: translate.text.NOTE_INLINE_EDITING_FIELD_LABEL,
							value: translate.text.NOTE_INLINE_EDITING_FIELD_VALUE
						},
						{
							label: translate.text.NOTE_PASTING_DATA_LABEL,
							value: translate.text.NOTE_PASTING_DATA_VALUE
						},
					]}
				/>

				{ Util.functionTitle(translate.text.STOCK_ADJUSTMENT) }
				<div className="function-sub-title">
					{translate.text.STOCK_ADJUSTMENT_SUBTITLE}
				</div>

				{
					auth.worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						{
							id===null ?
							<>
								<Button
									text={translate.button.CREATE_DRAFT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING||!is_editable}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={(e) =>
										dispatch(
											createStockAdjustmentAsync({
												document_date:document_date,
												reference_number:reference_number,
												transaction_description:transaction_description,
												profit_cost_center:profit_cost_center,
												line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null)),
												attachments:attachments,
												operations:auth.enums.stock_adjustment_operations,
											})
										).then((response) => (response.payload.status===resources.status.SUCCESS ? handleCreateButtonClickedResponse(response) : null))
									}
								/>
							</>
							:
							<>
								{
									is_editable &&
									<Button
										text={translate.button.UPDATE}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="floppy-disk" className="orange"/>}
										onClick={(e) =>
											dispatch(
												updateStockAdjustmentAsync({
													id:id,
													document_date:document_date,
													reference_number:reference_number,
													transaction_description:transaction_description,
													profit_cost_center:profit_cost_center,
													line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null)),
													attachments:attachments,
													operations:auth.enums.stock_adjustment_operations,
												})
											).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateButtonClickedResponse(response) : null))
										}
									/>
								}
								{
									is_editable &&
									<Button
										text={translate.button.SUBMIT}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="send-message" className="orange"/>}
										onClick={e => setIsConfirmSubmitDialogOpen(!is_confirm_submit_dialog_open)}
									/>
								}
								<Button
									text={translate.button.PRINT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="print" className="orange"/>}
									onClick={() =>
										PDF.exportStockAdjustmentForm(
											translate.worksheet.file_name.STOCK_ADJUSTMENT,
											[stock_adjustment],
											line_items,
											auth.active_company
										)
									}
								/>
								{
									is_editable &&
									<Button
										text={translate.button.VOID}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="ban-circle" className="orange"/>}
										onClick={e => setIsConfirmVoidDialogOpen(!is_confirm_void_dialog_open)}
									/>
								}
								{
									stock_adjustment && stock_adjustment.approval_status===auth.enums.approval_status.FOR_APPROVAL && is_authorizable &&
									<>
										<Button
											text={translate.button.APPROVE}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="tick" className="orange"/>}
											onClick={e => setIsConfirmApproveDialogOpen(!is_confirm_approve_dialog_open)}
										/>
										<Button
											text={translate.button.REJECT}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="cross" className="orange"/>}
											onClick={e => setIsConfirmRejectDialogOpen(!is_confirm_reject_dialog_open)}
										/>
									</>
								}
							</>
						}
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleClearButtonClickedEvent}
						/>
					</ButtonGroup>
				}

				{
					(with_expiry_serialized || serialized || with_expiry) &&
					<div style={{textAlign:"center", marginBottom:20, color:"blue"}}>
						{
							with_expiry_serialized &&
							<div>
								Your next step is to assign expiry date/s to corresponding serial number/s.
							</div>
						}
						{
							serialized &&
							<div>
								Your next step is to assign serial number/s to corresponding quantity.
							</div>
						}
						{
							with_expiry &&
							<div>
								Your next step is to assign expiry date to each quantity.
							</div> 
						}
					</div>
				}

				{
					auth.worksheet.is_readonly_mode &&
					<div className="function-card">
						<div className="row">
							<Card
								className="col-sm-12 col-md-4 col-lg-3"
								style={{
									background: "lightblue",
								}}
							>
								<FormGroup>
									<H3 className="center" style={{color:"blue"}}>{stock_adjustment ? stock_adjustment.document_number : null}</H3>
									<H5 className="center" style={{color:"green"}}>{stock_adjustment ? stock_adjustment.approval_status : null}</H5>
								</FormGroup>


								<div className="row mb-5">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										id="document_date"
										label={translate.placeholder.PICK_A_DATE}
										type="date"
										variant="outlined"
										size="small"
										defaultValue={document_date}
										inputProps={{readOnly:!is_editable}}
										onChange={(e) => setDocumentDate(e.target.value)}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={reference_number}
										inputProps={{readOnly:!is_editable}}
										label={translate.placeholder.ENTER_TRANSACTION_REFERENCE_NUMBER}
										onChange={(e) =>
											setReferenceNumber(
												e.target.value
											)
										}
									/>
						        </div>

								<div className="row mb-5 mt-5">
									<div className="col-sm-12 col-md-12 col-lg-12">
										<MuiAutocomplete
											setSelectedValue={handleProfitCostCenterChangedEvent}
											selected_value={profit_cost_center}
											isMultiple={false}
											label={"Select Profit/Cost Center"}
											data={profit_cost_centers}
											isDisabled={!is_editable}
										/>
									</div>
								</div>

								<div className="row mb-5 mt-5">
									<TextField
										className="col-sm-12 col-md-12 col-lg-12"
										multiline
										minRows={4}
										size="small"
										variant="outlined"
										label={translate.placeholder.ENTER_TRANSACTION_DESCRIPTION}
										value={transaction_description}
										onChange={(e) =>
											setTransactionDescription(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<Attachment
									id={id}
									transaction={stock_adjustment}
									setTransaction={setStockAdjustment}
									attachments={attachments}
									setAttachments={setAttachments}
								/>

								{
									stock_adjustment && id!==null &&
									<>
										<Comment
											id={id}
											document_type={stock_adjustment.document_type.name}
											comments={stock_adjustment.comments}
											created_by={stock_adjustment.created_by}
											setDocument={setStockAdjustment}
											is_editable={is_editable}
											is_authorizable={is_authorizable}
										/>
										<TransactionLog
											transaction_logs={transaction_logs}
										/>
				                    </>
								}

							</Card>
							{
								<StockAdjustmentLineItem
									id={id}
									document_date={document_date}
									is_editable={is_editable}
									profit_cost_center={profit_cost_center}
									default_line_items={default_line_items}
									line_items={line_items}
									setLineItems={setLineItems}
									default_journal_entries={default_journal_entries}
									journal_entries={journal_entries}
									setJournalEntries={setJournalEntries}
									balancing_account={balancing_account}
									setBalancingAccount={setBalancingAccount}
									items={items}
									item_classes={item_classes}
									locations={locations}
								/>
							}
						</div>
					</div>
				}

				<Card className="function-card-spreadsheet">
					<StockAdjustmentSpreadsheet
						enable_select={true}
						enable_edit={true}
						selected_rows={selected_rows}
						setSelectedRows={setSelectedRows}
						handleEditButtonClickedEvent={handleEditButtonClickedEvent}
						handleClearButtonClickedEvent={handleClearButtonClickedEvent}
						worksheet={worksheet}
						setWorkSheet={setWorkSheet}
						refreshData={refreshData}
						is_confirm_delete_dialog_open={is_confirm_delete_dialog_open}
						setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
						profit_cost_centers={profit_cost_centers}
						DEFAULT_DATA_SET={DEFAULT_DATA_SET}
					/>
				</Card>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_delete_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDeleteDialogOpen}
					confirmAction={handleDeleteRowsButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted/voided, document will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain document is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_submit_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmSubmitDialogOpen}
					confirmAction={handleSubmitButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">SUBMIT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_approve_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmApproveDialogOpen}
					confirmAction={handleApproveButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">APPROVE</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_reject_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmRejectDialogOpen}
					confirmAction={handleRejectButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">REJECT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_void_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmVoidDialogOpen}
					confirmAction={handleVoidButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">VOID</span> this document ?
						</span>
					}
				/>

			</div>
		</div>
	);
}
