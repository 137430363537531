import React, { useCallback, useEffect, useState } from "react";
import { Dialog, Classes, ControlGroup, HTMLTable, Icon } from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';
import Util from "../../../common/Util";
import {
	selectAuth,
	fetchAllUnitOfMeasureConversionsAsync,
} from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { resources } from "../../../resources";

export function ItemDetailDialog({is_item_detail_dialog_open, setIsItemDetailDialogOpen, item}) {

    const auth = useSelector(selectAuth);
    const dispatch = useDispatch();
    const [unit_of_measure_conversions, setUnitOfMeasureConversions] = useState([]);
    const [selected_unit_of_measure_conversions, setSelectedUnitOfMeasureConversions] = useState([]);

	const initFetch = useCallback(() => {
		dispatch(fetchAllUnitOfMeasureConversionsAsync({})).then((response) => ( response.payload.status===resources.status.SUCCESS ? setUnitOfMeasureConversions(response.payload.data.unit_of_measure_conversions.map((umc, key) => ({label:`${umc.code} | ${umc.description} → ${umc.source_quantity} ${umc.source_unit_of_measure.code} = ${umc.convert_quantity} ${umc.convert_unit_of_measure.code}`, value:umc.id}))) : void(0)));
	}, [
		dispatch,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (item && unit_of_measure_conversions.length>0) {
            let uom_ids = auth.item_unit_of_measure_conversion.filter((iumc) => (iumc.item_id===item.id)).map((iumc) => iumc.unit_of_measure_conversion_id);
            setSelectedUnitOfMeasureConversions(unit_of_measure_conversions.filter((unitOfMeasureConversion) => uom_ids.includes(unitOfMeasureConversion.value)));
        }
	}, [item, unit_of_measure_conversions, auth.item_unit_of_measure_conversion]);

    return (
        <Dialog
            title="Item Details"
            icon={<Icon icon="info-sign" className="orange"/>}
            isOpen={is_item_detail_dialog_open}
            onClose={e => setIsItemDetailDialogOpen(false)}
            isCloseButtonShown={true}
            className="col-sm-12 col-md-9 col-lg-7"
        >
            <div className={Classes.DIALOG_BODY}>
                <div className="center">
                    {
                        item && 
                        <ControlGroup vertical={false}>
                            <ControlGroup vertical={true}>
                                <img alt={""} src={item.image_source} style={{width:'140px',height:'140px', margin:'10px', border:'2px solid grey'}}/>
                                {
                                    Util.stringToBoolean(item.is_barcode_enabled) && 
                                    <div style={{width:'140px',height:'140px', margin:'10px', paddingTop:"30px", border:'2px solid grey'}}>
                                        <Barcode 
                                            background="transparent"
                                            value={item.custom_barcode_number ? item.custom_barcode_number : item.code} 
                                            format={'CODE128'} 
                                            width={1}
                                            height={55}
                                            font={'monospace'}
                                            fontSize={15}
                                            margin={0}
                                            renderer={"img"}
                                        />
                                    </div>
                                } 
                                {
                                    Util.stringToBoolean(item.is_qr_code_enabled) && 
                                    <div style={{width:'140px',height:'140px', margin:'10px', border:'2px solid grey'}}>
                                        <QRCode 
                                            size={128}
                                            value={item.custom_barcode_number ? item.custom_barcode_number : item.code}
                                            includeMargin={true}
                                            level={'M'}
                                        />
                                    </div>
                                } 
                            </ControlGroup>
                            
                                <ControlGroup vertical={true} style={{textAlign:"left"}}>
                                    <HTMLTable condensed={true}>
                                        <tr>
                                            <td className="bold">Item Code:</td>
                                            <td>{item.code}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">Item Description:</td>
                                            <td>{item.description}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">Item Class:</td>
                                            <td>{item.item_class.name}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">Inventory Source:</td>
                                            <td>{item.inventory_source}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">Item Costing:</td>
                                            <td>{item.item_costing}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">Part/Model Number:</td>
                                            <td>{item.part_number}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">Custom Barcode Number:</td>
                                            <td>{item.custom_barcode_number}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">Default Unit of Measure:</td>
                                            <td>{item.unit_of_measure.code}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">UoM Conversion/s:</td>
                                            <td>
                                                {
                                                    selected_unit_of_measure_conversions.map((umc, key) => 
                                                        <div key={key}>
                                                            {umc.label}
                                                        </div>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bold">Has Expiry:</td>
                                            <td>{Util.stringToBoolean(item.has_expiry) ? 'yes' : 'no'}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">Barcode Enabled:</td>
                                            <td>{Util.stringToBoolean(item.is_barcode_enabled) ? 'yes' : 'no'}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">QR Code Enabled:</td>
                                            <td>{Util.stringToBoolean(item.is_qr_code_enabled) ? 'yes' : 'no'}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">With Components:</td>
                                            <td>{Util.stringToBoolean(item.with_components) ? 'yes' : 'no'}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">With Serial Number:</td>
                                            <td>{Util.stringToBoolean(item.with_serial_number) ? 'yes' : 'no'}</td>
                                        </tr>
                                        {
                                            Util.stringToBoolean(item.with_serial_number) &&
                                            <tr>
                                                <td className="bold">Serial Number Type:</td>
                                                <td>{item.serial_number_type}</td>
                                            </tr>
                                        }
                                    </HTMLTable>
                                </ControlGroup>
                            
                        </ControlGroup>
                    }
                </div>
            </div>
        </Dialog>
    );
}