import React, { useCallback, useEffect, useState } from "react";
import Util from "../../../common/Util";
import { Note } from "../../../common/Note";
import { SideBar } from '../../../SideBar';
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	toggleSidebarCollapsed,
	skipProcessAsync,
	fetchAllItemsAsync,
	removeInventoryItemsAsync,
	fetchPosServicesAsync,
	createServiceAsync,
	updateServiceAsync,
	fetchAllTaxesAsync,
	fetchAllDiscountsAsync,
	updateServiceImageAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	ControlGroup,
	Icon,
	Checkbox,
	Divider,
	FileInput
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import { useHistory } from "react-router-dom";
import { SelectPosItemsDialog } from "./SelectPosItemsDialog";
import TextField from "@material-ui/core/TextField";
import { MuiAutocomplete } from "../../../common/MuiAutocomplete";
import no_image_available from "../../../no_image_available.png";

export function PosItemsAndServices() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [role_user] = useState(auth.role_user.filter((roleUser) => (roleUser.user_id===auth.user.id)).map((roleUser) => roleUser.role_id));
	const [items, setItems] = useState([]);
	const [is_select_pos_items_dialog_open, setIsSelectPosItemsDialogOpen] = useState(false);
	const [selected_sellable_items, setSelectedSellableItems] = useState([]);
	const [id, setId] = useState(null);
	const [service_code, setServiceCode] = useState("");
	const [service_name, setServiceName] = useState("");
	const [selling_price, setSellingPrice] = useState("");
	const [item_type, setItemType] = useState("");
	const [services, setServices] = useState([]);
	const [taxes, setTaxes] = useState([]);
	const [tax, setTax] = useState(null);
	const [discounts, setDiscounts] = useState([]);
	const [discount, setDiscount] = useState(null);
	const [service_image, setServiceImage] = useState(null);
	const [service_image_source, setServiceImageSource] = useState(null);

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.POS_ITEMS_AND_SERVICES));
		dispatch(fetchPosServicesAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setServices(response.payload.data.services) : void(0)));
		dispatch(fetchAllTaxesAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setTaxes(response.payload.data.taxes.map((tax, key) => ({label:tax.name, value:tax.id}))) : void(0)));
		dispatch(fetchAllDiscountsAsync({scheduled:true})).then((response) => (response.payload.status===resources.status.SUCCESS ? setDiscounts(response.payload.data.discounts.filter((dsc) => (dsc.name!==auth.enums.regular_discounts.SENIOR_CITIZEN.name && dsc.name!==auth.enums.regular_discounts.PWD.name && dsc.name!==auth.enums.regular_discounts.DIPLOMAT.name)).map((discount, key) => ({label:discount.name, value:discount.id}))) : void(0)));
	}, [
		dispatch,
		translate.breadcrumb.POS_ITEMS_AND_SERVICES,
		auth.enums.regular_discounts
		
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (!is_select_pos_items_dialog_open) {
			dispatch(fetchAllItemsAsync({is_pos_item:1})).then((response) => (response.payload.data ? setItems(response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)))) : void(0)));
		}
	}, [
		dispatch,
		is_select_pos_items_dialog_open,
		auth.enums.boolean,
	]);

	const handleSkipButtonClickedEvent = () => {
		if (auth.active_company.application_id) {
			dispatch(skipProcessAsync({application:'pos-items-and-services'})).then((response) => response.payload.status===resources.status.SUCCESS ? history.push("/point-of-sale") : void(0));
		}
	};

	const handleRemoveItemsFromList = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setItems(items.filter((itm) => (!response.payload.data.item_ids.includes(itm.id))));
				setSelectedSellableItems([]);
			}
		}
		dispatch(removeInventoryItemsAsync({item_ids:selected_sellable_items})).then((response) => handleResponse(response));
	};
	
	const resetServiceFields = () => {
		setId("");
		setServiceCode("");
		setServiceName("");
		setSellingPrice("");
		setTax(null);
		setDiscount(null);
		setServiceImage(null);
		setServiceImageSource(null);
	}

	const handleUploadServiceImageEvent = (event) => {
		setServiceImage(event.target.files[0]);
		if (id!==null) {
			dispatch(updateServiceImageAsync({id:id, url:service_image_source, service_image:event.target.files[0]})).then((response) => setServiceImageSource(response.payload.data));
		}
	};

	const imageExists = (image_url) => {
	    var http = new XMLHttpRequest();
	    http.open('HEAD', image_url, false);
	    http.send();
	    return http.status !== 404;
	}

	const handleCreateServiceButtonEvent = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				resetServiceFields();
				setServices([...services, response.payload.data.service]);
			}
		}
		dispatch(createServiceAsync({
			service_code:service_code, 
			service_name:service_name, 
			selling_price:selling_price, 
			tax:tax ? tax.value : "",
			discount:discount ? discount.value : "",
			service_image: service_image
		})).then((response) => handleResponse(response));
	}

	const handleUpdateServiceButtonEvent = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				resetServiceFields();
				setServices(services.map((srvc) => (srvc.id===response.payload.data.service.id ? response.payload.data.service : srvc)));
			}
		}
		dispatch(updateServiceAsync({
			id:id, 
			service_code:service_code, 
			service_name:service_name, 
			selling_price:selling_price,
			tax:tax ? tax.value : "",
			discount:discount ? discount.value : "",
		})).then((response) => handleResponse(response));
	}

	const handleEditServiceButtonEvent = (service) => {
		setId(service.id);
		setServiceCode(service.code);
		setServiceName(service.name);
		setSellingPrice(service.selling_price);
		let tax = taxes.find((tax) => (tax.value===service.input_tax.id));
		setTax(tax ? tax : null);
		let discount = discounts.find((dsc) => (dsc.value===service.discount.id));
		setDiscount(discount ? discount : null);
		if (service.image_source!==null && imageExists(service.image_source)) {
			setServiceImageSource(service.image_source);
		}
		else {
			setServiceImageSource(null);
		}
	}

	const handleSyncForSaleItems = () => {
		setIsSelectPosItemsDialogOpen(true);
		setItemType(auth.enums.pos_item_types.FOR_SALE);
	}

	const handleSyncNotForSaleItems = () => {
		setIsSelectPosItemsDialogOpen(true);
		setItemType(auth.enums.pos_item_types.NOT_FOR_SALE);
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id && role_user.includes(auth.roles.find((rl) => (rl.name===auth.enums.roles.SYSTEM_ADMIN)).id) && (items.length>0||services.length>0) &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleSkipButtonClickedEvent}
						/>
					</div>
				}

				{
					auth.active_company.nature!==auth.enums.company_business_nature.SERVICE_ONLY &&
					<>
						{ Util.functionTitle(translate.text.ITEMS_TO_SELL) }
						<div>
							<ButtonGroup className="button-group" minimal={false}>
								<SelectPosItemsDialog
									is_select_pos_items_dialog_open={is_select_pos_items_dialog_open}
									setIsSelectPosItemsDialogOpen={setIsSelectPosItemsDialogOpen}
									itemType={item_type}
								/>
								{
									selected_sellable_items.length>0 ? 
									<Button
										text={`${translate.button.REMOVE_FROM_LIST} (${selected_sellable_items.length})`}
										disabled={auth.status === resources.status.LOADING}
										className="function-button"
										icon={<Icon icon="delete" className="orange"/>}
										onClick={() => handleRemoveItemsFromList()}
									/> : 
									<>
										<Button
											text={'Sync "For Sale" Item/s'}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="import" className="orange"/>}
											onClick={() => handleSyncForSaleItems()}
										/>
										<Button
											text={'Sync "Not For Sale" Item/s'}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="import" className="orange"/>}
											onClick={() => handleSyncNotForSaleItems()}
										/>
									</>
								}
							</ButtonGroup>
							<Card className="function-card">
								<div className="row">
									{
										items.map((item, key) => (
											<ControlGroup vertical={false} className="col-lg-3 col-md-5 col-sm-12 pos-item-container" key={key} onClick={() => (selected_sellable_items.includes(item.id) ? setSelectedSellableItems(selected_sellable_items.filter((itemId) => itemId!==item.id)) : setSelectedSellableItems([item.id, ...selected_sellable_items]))}>
												<Card className="pos-item-image-card">
													<img 
														alt="item" 
														src={item.image_source} 
														style={{width:"60px", height:"60px"}}
														onError={(e) => {
															e.target.src = no_image_available
														}}
													/>
												</Card>
												<Card className="pos-item-details-card">
													<Checkbox
														style={{float:"right", margin:0, padding:0}}
														checked={ selected_sellable_items.includes(item.id) }
														large={true}
														inline={true}
														onChange={() => (selected_sellable_items.includes(item.id) ? setSelectedSellableItems(selected_sellable_items.filter((itemId) => itemId!==item.id)) : setSelectedSellableItems([item.id, ...selected_sellable_items]))}
													/>
													<div>{item.code}</div>
													<div>{item.description}</div>
													<div>{item.item_class.name}</div>
													<div>{auth.active_company.currency.symbol} {item.unit_cost}</div>
													<div style={{bottom:0}}>{item.discount ? item.discount.name : ""}</div>
													<div>{item.pos_item_type}</div>
												</Card>
											</ControlGroup>
										))
									}
								</div>
							</Card>
						</div>
					</>
				}

				{
					auth.active_company.nature!==auth.enums.company_business_nature.ITEM_ONLY &&
					<>
						{ Util.functionTitle(translate.text.SERVICES_TO_SELL) }

						<div>
							<ButtonGroup className="button-group" minimal={false}>
								<Button
									text={id ? translate.button.UPDATE : translate.button.CREATE}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={() => id ? handleUpdateServiceButtonEvent() : handleCreateServiceButtonEvent()}
								/>
								<Button
									text={translate.button.CLEAR}
									className="function-button"
									icon={<Icon icon="reset" className="orange"/>}
									disabled={auth.status === resources.status.LOADING}
									onClick={resetServiceFields}
								/>
							</ButtonGroup>
							<Card className="function-card">
								<div className="row">
									{
										id!==null && service_image_source!==null  ?
										<div className="col-sm-12 col-md-8 col-lg-8 center">
											<img alt="item" src={service_image_source} style={{margin:'10px'}}/>
										</div>
										:
										null

									}
									<FileInput  className="col-sm-12 col-md-7 col-lg-6 mb-5 center" disabled={false} large={false} text={id===null&&service_image ? service_image.name : translate.text.CHOOSE_SERVICE_FILE} onInputChange={handleUploadServiceImageEvent} />
								</div>
								<div className="row">
									<TextField
										className="col-sm-12 col-md-2 col-lg-2 text-field"
										size="small"
										variant="outlined"
										value={service_code}
										onChange={(e) => setServiceCode(e.target.value)}
										label={translate.placeholder.ENTER_SERVICE_CODE}
									/>
									<TextField
										className="col-sm-12 col-md-3 col-lg-2 text-field"
										size="small"
										variant="outlined"
										value={service_name}
										onChange={(e) => setServiceName(e.target.value)}
										label={translate.placeholder.ENTER_SERVICE_NAME}
									/>
									<TextField
										className="col-sm-12 col-md-3 col-lg-2 text-field"
										size="small"
										variant="outlined"
										type="number"
										value={selling_price}
										onChange={(e) => setSellingPrice(e.target.value)}
										label={`${translate.placeholder.ENTER_SELLING_PRICE} (${auth.active_company.currency.symbol})`}
									/>
									<div className="col-sm-12 col-md-2 col-lg-2 text-field">
										<MuiAutocomplete
											setSelectedValue={setTax}
											selected_value={tax}
											isMultiple={false}
											label={translate.placeholder.SELECT_TAX}
											data={taxes}
										/>
									</div>
									<div className="col-sm-12 col-md-3 col-lg-2 text-field">
										<MuiAutocomplete
											setSelectedValue={setDiscount}
											selected_value={discount}
											isMultiple={false}
											label={translate.placeholder.SELECT_DISCOUNT}
											data={discounts}
										/>
									</div>
								</div>
								<Divider></Divider>
								<div className="row">
									{
										services.map((service, key) => (
											<ControlGroup vertical={false} className="col-lg-3 col-md-5 col-sm-12 pos-item-container" key={key} onClick={() => handleEditServiceButtonEvent(service)}>
												<Card className="pos-item-image-card">
													<img 
														alt="service" 
														src={service.image_source} 
														style={{width:"60px", height:"60px"}}
														onError={(e) => {
															e.target.src = no_image_available
														}}
													/>
												</Card>
												<Card className="pos-item-details-card">
													<div>{service.code}</div>
													<div>{service.name}</div>
													<div>{auth.active_company.currency.symbol} {service.selling_price}</div>
													<div style={{bottom:0}}>{service.discount ? service.discount.name : ""}</div>
												</Card>
											</ControlGroup>
										))
									}
								</div>
							</Card>
						</div>
					</>
				}

			</div>
		</div>
	);
}
