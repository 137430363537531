import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { MuiAutocomplete } from "../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	//deletePrintJobAsync,
	//importPrintJobsAsync,
	updateItemSerialNumberCountAsync,
	toggleSidebarCollapsed,
	fetchAllItemsAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Switch,
	Card,
	ControlGroup,
	Icon,
	H4,
	Slider,
	HandleType
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import Barcode from 'react-barcode';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode.react';
import { SideBar } from '../../../SideBar';

export function InventoryMobileScanner() {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [items, setItems] = useState([]);
	const [item_options, setItemOptions] = useState([]);
	const [serial_number_formats] = useState(Object.values(auth.enums.serial_number_formats).map((sn, key) => ({label:sn, value:sn})));
	const [serial_number_format, setSerialNumberFormat] = useState(serial_number_formats[0]);
	const [serial_number_count, setSerialNumberCount] = useState(items.length>0 ? parseInt(items[0].serial_number_series) : 5);
	const [barcode_fields, setBarcodeFields] = useState(Object.values(auth.enums.barcode_fields).map((barcode_field) => ({label:barcode_field, value:barcode_field})));
	const [barcode_field, setBarcodeField] = useState(null);
	const [barcode_formats] = useState(Object.values(auth.enums.barcode_types).map((barcode_type) => ({label:barcode_type, value:barcode_type})));
	const [barcode_format, setBarcodeFormat] = useState(barcode_formats[0]);
	const [barcode_fonts] = useState(Object.values(auth.enums.barcode_fonts).map((barcode_font) => ({label:barcode_font, value:barcode_font})));
	const [barcode_font, setBarcodeFont] = useState(barcode_fonts[0]);
	const [barcode_font_size, setBarcodeFontSize] = useState(15);
	const [barcode_value, setBarcodeValue] = useState(items.length>0 ? items[0].code : "");
	const [qr_code_value, setQrCodeValue] = useState(items.length>0 ? items[0].code : "");
	const [item, setItem] = useState(null);
	const [itemObj, setItemObj] = useState(null);
	const [width, setWidth] = useState(1);
	const [height, setHeight] = useState(55);
	const [canvas_width, setCanvasWidth] = useState(140);
	const [canvas_height, setCanvasHeight] = useState(98);
	const [qr_code_size, setQrCodeSize] = useState(100);
	const [qr_ecc_levels] = useState(Object.values(auth.enums.qr_ecc_levels).map((level) => ({label:level, value:level})));
	const [qr_ecc_level, setQrEccLevel] = useState(qr_ecc_levels.find((qrEccLevel) => qrEccLevel.value===auth.enums.qr_ecc_levels.MEDIUM));
	const [include_margin, setIncludeMargin] = useState(true);
	const [quantity, setQuantity] = useState(1);
	const [barcode_position, setBarcodePosition] = useState(0);
	const [qrcode_position, setQrcodePosition] = useState(0);
	const [barcode_values, setBarcodeValues] = useState([items.length>0 ? items[0].code : ""]);
	const [qr_code_values, setQrCodeValues] = useState([items.length>0 ? items[0].code : ""]);
	const [dataToReprint, setDataToReprint] = useState("");
	const [qr_image_size, setQrImageSize] = useState(100);

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.MANAGE_BARCODE_QR_CODE));
		const handleResponse = (response) => {
			if (response.payload.data) {
				setItems(response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description))));
				setItemOptions(response.payload.data.items.map((itm) => ({label:itm.label, value:itm.id})));
			}
		}
		dispatch(fetchAllItemsAsync({})).then((response) => handleResponse(response));
	}, [
		dispatch,
		translate.breadcrumb.MANAGE_BARCODE_QR_CODE,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const handleItemSelectChangedEvent = (value) => {
		if (value!==null) {
			let itemObj = items.filter((itm) => itm.id===value.value)[0];
			setItemObj(itemObj);
			setItem(value);
			let barcodeFields = [];
			if (Util.stringToBoolean(itemObj.with_serial_number)===true) {
				barcodeFields = Object.values(auth.enums.barcode_fields).filter((barcodeField) => (barcodeField===auth.enums.barcode_fields.SERIAL_NUMBER||barcodeField===auth.enums.barcode_fields.MANUAL_REPRINTING)).map((barcode_field) => ({label:barcode_field, value:barcode_field}));
				if (itemObj.serial_number_type===auth.enums.serial_number_types.IN_HOUSE) {
					let barcodeField = barcodeFields.filter((bf) => bf.value===auth.enums.barcode_fields.SERIAL_NUMBER)[0];
					setBarcodeFields(barcodeFields);
					setBarcodeField(barcodeField);
					if (itemObj) {
						setSerialNumberFormat({label:itemObj.serial_number_format, value:itemObj.serial_number_format});
						setSerialNumberCount(parseInt(itemObj.serial_number_series));
					}
					let position = 0;
					setQrcodePosition(position);
					setBarcodePosition(position);
					let qty = parseInt(quantity);
					setQuantity(qty);
			
					let qrCodeValue = generateQrCodeValue(position, barcodeField, itemObj, dataToReprint);
					setQrCodeValue(qrCodeValue);
					let qrCodeValues = Array(qty).fill("").map((qrVal, key) => generateQrCodeValue(key, barcodeField, itemObj, dataToReprint));
					setQrCodeValues(qrCodeValues);
			
					let barcodeValue = generateBarCodeValue(position, barcodeField, itemObj, dataToReprint);
					setBarcodeValue(barcodeValue);
					let barcodeValues = Array(qty).fill("").map((barcodeVal, key) => generateBarCodeValue(key, barcodeField, itemObj, dataToReprint));
					setBarcodeValues(barcodeValues);
				}
			}
			else {
				barcodeFields = Object.values(auth.enums.barcode_fields).filter((barcodeField) => (barcodeField!==auth.enums.barcode_fields.SERIAL_NUMBER)).map((barcodeField) => ({label:barcodeField, value:barcodeField}));
				setBarcodeFields(barcodeFields);
				setBarcodeField(null);
			}
		}
	};

	const generateQrCodeValue = (j, barcodeField, itemObj, dataToReprint = "") => {
		let text = "";
		switch (barcodeField.value) {
			case auth.enums.barcode_fields.ITEM_CODE : {
				text = itemObj.code;
				break;
			}
			case auth.enums.barcode_fields.PART_NUMBER : {
				text = itemObj.part_number;
				break;
			}
			case auth.enums.barcode_fields.SERIAL_NUMBER : {
				let qrSerialCount = itemObj.qr_serial_number_count+1;
				let qr_serial_number = qrSerialCount.toString().padStart(parseInt(itemObj.serial_number_series),"0");
				switch (itemObj.serial_number_format) {
					case auth.enums.serial_number_formats.ITEM_CODE_SERIES : {
						text = `${itemObj.code} ${Util.incrementString(qr_serial_number, j)}`;
						break;
					}
					case auth.enums.serial_number_formats.PART_NUMBER_SERIES : {
						text = `${itemObj.part_number} ${Util.incrementString(qr_serial_number, j)}`;
						break;
					}
					case auth.enums.serial_number_formats.ITEM_CODE_PART_NUMBER_SERIES : {
						text = `${itemObj.code} ${itemObj.part_number} ${Util.incrementString(qr_serial_number, j)}`;
						break;
					}
					case auth.enums.serial_number_formats.PART_NUMBER_ITEM_CODE_SERIES : {
						text = `${itemObj.part_number} ${itemObj.code} ${Util.incrementString(qr_serial_number, j)}`;
						break;
					}
					default: {
						break;
					}
				}
				break;
			}
			case auth.enums.barcode_fields.CUSTOM_BARCODE_NUMBER : {
				text = itemObj.custom_barcode_number;
				break;
			}
			case auth.enums.barcode_fields.MANUAL_REPRINTING : {
				text = dataToReprint;
				break;
			}
			default : {
				break;
			}
		}
		let textObj = {
			item:{
				label:itemObj.label,
				unit_of_measure:{label:itemObj.unit_of_measure.code, value:itemObj.unit_of_measure.id},
				value:itemObj.id
			},
			inventory_tracking:text
		};
		return JSON.stringify(textObj);
	}

	const generateBarCodeValue = (j, barcodeField, itemObj, dataToReprint = "") => {
		let text = "";
		switch (barcodeField.value) {
			case auth.enums.barcode_fields.ITEM_CODE : {
				text = itemObj.code;
				break;
			}
			case auth.enums.barcode_fields.PART_NUMBER : {
				text = itemObj.part_number;
				break;
			}
			case auth.enums.barcode_fields.SERIAL_NUMBER : {
				let barcodeSerialCount = itemObj.serial_number_count+1;
				let barcode_serial_number = barcodeSerialCount.toString().padStart(parseInt(itemObj.serial_number_series),"0");
				switch (itemObj.serial_number_format) {
					case auth.enums.serial_number_formats.ITEM_CODE_SERIES : {
						text = `${itemObj.code} ${Util.incrementString(barcode_serial_number, j)}`;
						break;
					}
					case auth.enums.serial_number_formats.PART_NUMBER_SERIES : {
						text = `${itemObj.part_number} ${Util.incrementString(barcode_serial_number, j)}`;
						break;
					}
					case auth.enums.serial_number_formats.ITEM_CODE_PART_NUMBER_SERIES : {
						text = `${itemObj.code} ${itemObj.part_number} ${Util.incrementString(barcode_serial_number, j)}`;
						break;
					}
					case auth.enums.serial_number_formats.PART_NUMBER_ITEM_CODE_SERIES : {
						text = `${itemObj.part_number} ${itemObj.code} ${Util.incrementString(barcode_serial_number, j)}`;
						break;
					}
					default: {
						break;
					}
				}
				break;
			}
			case auth.enums.barcode_fields.CUSTOM_BARCODE_NUMBER : {
				text = itemObj.custom_barcode_number;
				break;
			}
			case auth.enums.barcode_fields.MANUAL_REPRINTING : {
				text = dataToReprint;
				break;
			}
			default : {
				break;
			}
		}
		return text;
	}

	const handleBarcodeFieldChangedEvent = (barcodeFieldValue) => {
		if (item!==null) {
			let itemObj = items.filter((itm) => itm.id===item.value)[0];
			if (barcodeFieldValue.value===auth.enums.barcode_fields.SERIAL_NUMBER && Util.stringToBoolean(itemObj.with_serial_number)===false) {
				toast.error(translate.message.ITEM_HAS_NO_SERIAL_NUMBER, Util.getToasterStyle(resources.status.ERROR));
			}
			else {
				setBarcodeField(barcodeFieldValue);
				setQrcodePosition(qrcode_position);
				let qrCodeValue = generateQrCodeValue(qrcode_position, barcodeFieldValue, itemObj, dataToReprint);
				setQrCodeValue(qrCodeValue);
				let qrCodeValues = Array(parseInt(quantity)).fill("").map((qrVal, key) => generateQrCodeValue(key, barcodeFieldValue, itemObj, dataToReprint));
				setQrCodeValues(qrCodeValues);

				let barcodeValue = generateBarCodeValue(barcode_position, barcodeFieldValue, itemObj, dataToReprint);
				setBarcodeValue(barcodeValue);
				let barcodeValues = Array(parseInt(quantity)).fill("").map((barcodeVal, key) => generateBarCodeValue(key, barcodeFieldValue, itemObj, dataToReprint));
				setBarcodeValues(barcodeValues);
			}
		}

	};

	function textToBase64Barcode(canvas, text, margin, marginTop, marginLeft, marginBottom, marginRight){
	  JsBarcode(
	  		canvas,
	  		text,
	  		{
	  			format: barcode_format.value,
	  			width: parseInt(width),
	  			height: parseInt(height),
	  			font: barcode_font.value,
	  			fontSize: parseInt(barcode_font_size),
	  			margin: margin,
	  			marginTop: marginTop,
	  			marginLeft: marginLeft,
	  			marginBottom: marginBottom,
	  			marginRight: marginRight,
	  		}
	  	);
	  return canvas.toDataURL("image/jpeg");
	}

	const handlePrintBarcodeButtonClickedEvent = () => {
		let barcode_images = [];
		//let dataSet = auth.worksheet.data_set;
		let canvas = null;
		let img = document.querySelector("#barcode-container img");
		let verticalMargin = (canvas_height - img.naturalHeight) / 2;
		let horizontalMargin = (canvas_width - img.naturalWidth) / 2;

		if (item) {
			if (barcode_field) {
				let qty = parseInt(quantity);
				if (barcode_field.value===auth.enums.barcode_fields.SERIAL_NUMBER) {
					let serialCount = parseInt(itemObj.serial_number_count);
					let assignedQty = parseInt(itemObj.assigned_serial_number_count);
					if (qty+serialCount > assignedQty) {
						toast.error(translate.message.QUANTITY_TO_PRINT_EXCEEDED_ASSIGNED_QUANTITY, Util.getToasterStyle(resources.status.ERROR));
						return null;
					}
					else {
						dispatch(updateItemSerialNumberCountAsync({id:item.value, quantity:qty, type:auth.enums.print_job_types.BAR_CODE})).then((response) => (response.payload.data ? setItemObj(response.payload.data) : void(0)));
					}
				}
				for (let j=0; j<qty; j++) {
					let text = generateBarCodeValue(j, barcode_field, itemObj, dataToReprint);
					setBarcodeValue(text);
					canvas = document.createElement("canvas");
					let image = textToBase64Barcode(canvas, text, 0, undefined, undefined, undefined, undefined);
					barcode_images.push({image:image});
				}
				if (barcode_images.length>0) {
					PDF.exportBarcodes(
						translate.worksheet.file_name.BARCODE,
						auth.worksheet.header,
						barcode_images,
						parseInt(canvas_width),
						parseInt(canvas_height),
						parseInt(horizontalMargin),
						parseInt(verticalMargin)
					);
				}
			}
			else {
				toast.error(translate.message.BARCODE_FIELD_IS_REQUIRED, Util.getToasterStyle(resources.status.ERROR));
			}
		}
		else {
			toast.error(translate.message.ITEM_TO_PRINT_REQUIRED, Util.getToasterStyle(resources.status.ERROR));
		}

	};

	const handleManualReprintingEvent = (e) => {
		let position = 0;
		setQrcodePosition(position);
		setBarcodePosition(position);
		setDataToReprint(e.target.value);

		let qrCodeValue = generateQrCodeValue(position, barcode_field, itemObj, e.target.value);
		setQrCodeValue(qrCodeValue);
		let qrCodeValues = Array(parseInt(quantity)).fill("").map((qrVal, key) => generateQrCodeValue(key, barcode_field, itemObj, e.target.value));
		setQrCodeValues(qrCodeValues);

		let barcodeValue = generateBarCodeValue(position, barcode_field, itemObj, e.target.value);
		setBarcodeValue(barcodeValue);
		let barcodeValues = Array(parseInt(quantity)).fill("").map((barcodeVal, key) => generateBarCodeValue(key, barcode_field, itemObj, e.target.value));
		setBarcodeValues(barcodeValues);
	}

	const handleBarCodePreviousButtonClickedEvent = (e) => {
		let barcodePosition = barcode_position-1>=0 ? barcode_position-1 : barcode_position;
		let value = generateBarCodeValue(barcodePosition, barcode_field, itemObj, dataToReprint);
		setBarcodePosition(barcodePosition);
		setBarcodeValue(value);
	}

	const handleBarCodeNextButtonClickedEvent = (e) => {
		let barcodePosition = barcode_position+1<barcode_values.length ? barcode_position+1 : barcode_position;
		let value = generateBarCodeValue(barcodePosition, barcode_field, itemObj, dataToReprint);
		setBarcodePosition(barcodePosition);
		setBarcodeValue(value);
	}

	const handleQrCodePreviousButtonClickedEvent = (e) => {
		let qrcodePosition = qrcode_position-1>=0 ? qrcode_position-1 : qrcode_position;
		let value = generateQrCodeValue(qrcodePosition, barcode_field, itemObj, dataToReprint);
		setQrcodePosition(qrcodePosition);
		setQrCodeValue(value);
	}

	const handleQrCodeNextButtonClickedEvent = (e) => {
		let qrcodePosition = qrcode_position+1<qr_code_values.length ? qrcode_position+1 : qrcode_position;
		let value = generateQrCodeValue(qrcodePosition, barcode_field, itemObj, dataToReprint);
		setQrcodePosition(qrcodePosition);
		setQrCodeValue(value);
	}

	const handlePrintQRCodeButtonClickedEvent = () => {
		//let dataSet = auth.worksheet.data_set;
		//let margin = include_margin ? 5 : 0;
		let qrCodeSize = parseInt(qr_code_size);
		let qr_codes = [];
		let margins = {
			100:12,
			105:22,
			110:28,
			115:30,
			120:34,
			125:38,
			130:44,
		};
		let marginTop = margins[qrCodeSize]/2;

		if (item) {
			if (barcode_field) {
				let qty = parseInt(quantity);
				if (barcode_field.value===auth.enums.barcode_fields.SERIAL_NUMBER) {
					let serialCount = parseInt(itemObj.qr_serial_number_count);
					let assignedQty = parseInt(itemObj.assigned_serial_number_count);
					if (qty+serialCount > assignedQty) {
						toast.error(translate.message.QUANTITY_TO_PRINT_EXCEEDED_ASSIGNED_QUANTITY, Util.getToasterStyle(resources.status.ERROR));
						return null;
					}
					else {
						dispatch(updateItemSerialNumberCountAsync({id:item.value, quantity:qty, type:auth.enums.print_job_types.QR_CODE})).then((response) => setItemObj(response.payload.data));
					}
				}
				for (let j=0; j<qty; j++) {
					let textObj = generateQrCodeValue(j, barcode_field, itemObj, dataToReprint);
					setQrCodeValue(textObj);
					qr_codes.push(textObj);
				}
				if (qr_codes.length>0) {
					let qrString = qr_codes.map((qr_code) => ({
						qr:qr_code, 
						eccLevel:qr_ecc_level.value, 
						fit:qrCodeSize, 
						alignment:'center',
					}));
					PDF.exportQRCodes(
						translate.worksheet.file_name.QR_CODE,
						auth.worksheet.header,
						qrString,
						qrCodeSize+10,
						qrCodeSize+10,
						marginTop
					);
				}
			}
			else {
				toast.error(translate.message.BARCODE_FIELD_IS_REQUIRED, Util.getToasterStyle(resources.status.ERROR));
			}
		}
		else {
			toast.error(translate.message.ITEM_TO_PRINT_REQUIRED, Util.getToasterStyle(resources.status.ERROR));
		}

	};

	var barcode_image = (
		<Barcode
			background="transparent"
			value={barcode_value.length>0 ? barcode_value : "Preview"}
			format={barcode_format.value}
			width={parseInt(width)}
			height={parseInt(height)}
			font={barcode_font.value}
			fontSize={parseInt(barcode_font_size)}
			margin={0}
			renderer={"img"}
		/>
	);

	var qrcode_image = (
		<QRCode
			size={parseInt(qr_code_size)}
			value={qr_code_value.length>0 ? qr_code_value : "Preview"}
			includeMargin={include_margin}
			level={qr_ecc_level.value}
		/>
	);

	const handleQuantityChangedEvent = (e) => {
		let position = 0;
		setQrcodePosition(position);
		setBarcodePosition(position);
		let qty = !isNaN(e.target.value) ? parseInt(e.target.value) : 1;
		setQuantity(qty);

		let qrCodeValue = generateQrCodeValue(position, barcode_field, itemObj, dataToReprint);
		setQrCodeValue(qrCodeValue);
		let qrCodeValues = Array(qty).fill("").map((qrVal, key) => generateQrCodeValue(key, barcode_field, itemObj, dataToReprint));
		setQrCodeValues(qrCodeValues);

		let barcodeValue = generateBarCodeValue(position, barcode_field, itemObj, dataToReprint);
		setBarcodeValue(barcodeValue);
		let barcodeValues = Array(qty).fill("").map((barcodeVal, key) => generateBarCodeValue(key, barcode_field, itemObj, dataToReprint));
		setBarcodeValues(barcodeValues);
	}

	const downloadQRCode = () => {
		const qrURL = document.getElementById('qr-image').toDataURL("image/png").replace("image/png", "image/octet-stream");
		let downloadButton = document.createElement("a");
		downloadButton.href = qrURL;
		downloadButton.download = "Inventory_Mobile_Scanner_Android_QR_Code.png";
		document.body.appendChild(downloadButton);
		downloadButton.click();
		document.body.removeChild(downloadButton);
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>
				<Note
					contents={[
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				<div style={{height:"100vh"}}>

					{ Util.functionTitle(translate.text.INVENTORY_MOBILE_SCANNER) }

					<ButtonGroup className="button-group" minimal={false}>
					</ButtonGroup>
					<Card className="function-card">
						<div className="row">
							<div className="col-sm-12 col-md-4 col-lg-4 center">
								<QRCode
									id="qr-image"
									size={qr_image_size}
									value={auth.active_company.inventory_mobile_scanner.url}
									includeMargin={true}
									level={auth.enums.qr_ecc_levels.LOW}
								/>
								<div style={{margin:10}}>
									<div>Resize Image:</div>
									<Slider
										type={HandleType.FULL}
										value={qr_image_size}
										min={60}
										max={300}
										stepSize={10}
										labelStepSize={20}
										onChange={(num) => setQrImageSize(num)}
									/>
								</div>

								<div style={{marginTop:"10px", color:"blue", fontWeight:"bold"}}>
									You can download and use the QR image to distribute the mobile application to your users.
								</div>
								<Button
									text={translate.button.DOWNLOAD_QR_CODE}
									className="function-button download-btn"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="download" className="orange"/>}
									onClick={downloadQRCode}
								/>

							</div>
						</div>
					</Card>

					{ Util.functionTitle("Item Barcode / QR Code Label Printing") }

					<ButtonGroup className="button-group" minimal={false}>
						{/*
						<Button
							text={translate.button.CLEAR_PRINT_QUEUE}
							className="function-button"
							icon="reset"
							disabled={auth.status === resources.status.LOADING}
							onClick={handleClearButtonClickedEvent}
						/>
						*/}
					</ButtonGroup>

					<Card className="function-card">

						{/*
						<div className="row">
							<div className="column center">
								<p style={{fontSize:"10pt"}}><Icon size={17} icon="info-sign" intent="primary"/>{" "}For multiple printing and/or reprinting of item barcode/qr code, use the import spreadsheet.</p>
							</div>
						</div>
						*/}

						<div className="row">

							<div className="col-sm-12 col-md-4 col-lg-4 text-field">
								<MuiAutocomplete
									setSelectedValue={handleItemSelectChangedEvent}
									selected_value={item}
									isMultiple={false}
									label={"Select item"}
									data={item_options}
								/>
							</div>
							<div className="col-sm-12 col-md-2 col-lg-3 text-field">
								<MuiAutocomplete
									setSelectedValue={handleBarcodeFieldChangedEvent}
									selected_value={barcode_field}
									isMultiple={false}
									isDisabled={item===null}
									label={"Field to print on the sticker"}
									data={barcode_fields}
								/>
							</div>
								<TextField
									className="col-sm-12 col-md-1 col-lg-1 text-field"
									size="small"
									variant="outlined"
									value={quantity}
									disabled={item===null}
									onChange={handleQuantityChangedEvent}
									label={"Quantity"}
								    type="number"
								    InputProps={{
								        inputProps: {
								            min: 1
								        }
								    }}
								/>
							{
								barcode_field!==null && barcode_field.value===auth.enums.barcode_fields.MANUAL_REPRINTING &&
								<TextField
									className="col-sm-12 col-md-3 col-lg-3 text-field"
									size="small"
									variant="outlined"
									value={dataToReprint}
									onChange={handleManualReprintingEvent}
									label={"Enter the text you want to reprint"}
								/>

							}
							{
								barcode_field!==null && barcode_field.value===auth.enums.barcode_fields.SERIAL_NUMBER &&
								<div className="col-sm-12 col-md-2 col-lg-2 text-field">
									<MuiAutocomplete
										setSelectedValue={setSerialNumberFormat}
										selected_value={serial_number_format}
										isMultiple={false}
										isDisabled={true}
										label={"Serial number format"}
										data={serial_number_formats}
									/>
								</div>
							}
							{
								barcode_field!==null && barcode_field.value===auth.enums.barcode_fields.SERIAL_NUMBER &&

									<TextField
										className="col-sm-12 col-md-1 col-lg-1 text-field"
										size="small"
										variant="outlined"
										value={serial_number_count}
										onChange={(e) =>
											setSerialNumberCount(
												e.target.value
											)
										}
										label={"Number of digits"}
									    inputProps={{readOnly:true}}
									/>

							}

						</div>

						<div className="row">

								<Card
									className="col-sm-12 col-md-6 col-lg-6"
									style={{
										textAlign: "center",
										background: "lightgrey",
									}}
								>
									<H4 className="left">Barcode</H4>
									<div style={{fontSize:"10pt", margin:"5px", fontWeight:"bold"}}>Preview {barcode_position+1} of {barcode_values.length}</div>
									<ControlGroup style={{background:"lightblue", width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"10px"}} vertical={false}>
										<Button
											large={true}
											minimal={true}
											icon={<Icon size={20} icon="caret-left"/>}
											onClick={handleBarCodePreviousButtonClickedEvent}
										/>
											<div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"210px"}}>
												{
													<div id="barcode-container" style={{display:"flex", alignItems:"center", justifyContent:"center", background:"white",width:(canvas_width)+"px",height:(canvas_height)+"px"}}>
														{barcode_image}
													</div>
												}
											</div>
										<Button
											large={true}
											minimal={true}
											icon={<Icon size={20} icon="caret-right"/>}
											onClick={handleBarCodeNextButtonClickedEvent}
										/>
									</ControlGroup>
									{
										item && barcode_field &&
										<Button
											className="function-button"
											icon={<Icon icon="print" className="orange"/>}
											onClick={handlePrintBarcodeButtonClickedEvent}
										>
											Print
										</Button>
									}
									<div className="row">
										<div className="col-sm-12 col-md-3 col-lg-3 text-field">
											<MuiAutocomplete
												setSelectedValue={setBarcodeFormat}
												selected_value={barcode_format}
												isMultiple={false}
												label={"Barcode format"}
												data={barcode_formats}
											/>
										</div>
										<div className="col-sm-12 col-md-4 col-lg-4 text-field">
											<MuiAutocomplete
												setSelectedValue={setBarcodeFont}
												selected_value={barcode_font}
												isMultiple={false}
												label={"Font"}
												data={barcode_fonts}
											/>
										</div>
										<TextField
											className="col-sm-12 col-md-3 col-lg-3 text-field"
											size="small"
											variant="outlined"
											value={barcode_font_size}
											onChange={(e) =>
												setBarcodeFontSize(
													e.target.value
												)
											}
											label={"Font size"}
										    type="number"
										    InputProps={{
										        inputProps: {
										            max: 40, min: 10
										        }
										    }}
										/>
									</div>
									<div className="row">
										<TextField
											className="col-sm-12 col-md-3 col-lg-3 text-field"
											size="small"
											variant="outlined"
											value={height}
											onChange={(e) =>
												setHeight(
													e.target.value
												)
											}
											label={"Barcode stripe height (in pixel)"}
										    type="number"
										    InputProps={{
										        inputProps: {
										            max: 125,
										            min: 25
										        }
										    }}
										/>
										<TextField
											className="col-sm-12 col-md-3 col-lg-2 text-field"
											size="small"
											variant="outlined"
											value={width}
											onChange={(e) =>
												setWidth(
													e.target.value
												)
											}
											label={"Barcode stripe width (in pixel)"}
										    type="number"
										    InputProps={{
										        inputProps: {
										            max: 3,
										            min: 1
										        }
										    }}
										/>
										<TextField
											className="col-sm-12 col-md-3 col-lg-3 text-field"
											size="small"
											variant="outlined"
											value={canvas_height}
											onChange={(e) =>
												setCanvasHeight(
													e.target.value
												)
											}
											label={"Sticker height (in millimeter)"}
										    type="number"
										    InputProps={{
										        inputProps: {
										            max: 150,
										            min: 50
										        }
										    }}
										/>
										<TextField
											className="col-sm-12 col-md-3 col-lg-2 text-field"
											size="small"
											variant="outlined"
											value={canvas_width}
											onChange={(e) =>
												setCanvasWidth(
													e.target.value
												)
											}
											label={"Sticker width (in millimeter)"}
										    type="number"
										    InputProps={{
										        inputProps: {
										            max: 550,
										            min: 100
										        }
										    }}
										/>
									</div>


										{/*
										<Button
											className="function-button"
											icon="add"
											onClick={(e) =>
												dispatch(
													createPrintJobAsync({
														item: item.value,
														quantity: quantity,
														type: auth.enums.print_job_types.BAR_CODE,
														field: barcode_field.value,
														value: barcode_value
													})
												)
											}
										>
											Add Barcode item to Print Queue
										</Button>
										<Button
											className="function-button"
											icon="floppy-disk"
											onClick={handlePrintSelectedRowsBarcodeButtonClickedEvent}
										>
											Print Selected Row/s
										</Button>
										*/}


								</Card>
								<Card
									className="col-sm-12 col-md-6 col-lg-6"
									style={{
										textAlign: "center",
										background: "lightgrey",
									}}
								>
									<H4 className="left">QR Code</H4>
									<div style={{fontSize:"10pt", margin:"5px", fontWeight:"bold"}}>Preview {qrcode_position+1} of {qr_code_values.length}</div>
									<ControlGroup style={{background:"lightblue", width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"10px"}} vertical={false}>
										<Button
											large={true}
											minimal={true}
											icon={<Icon size={20} icon="caret-left"/>}
											onClick={handleQrCodePreviousButtonClickedEvent}
										/>
											<div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"210px"}}>
												{
													<div id="qrcode-container" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
														{qrcode_image}
													</div>
												}
											</div>
										<Button
											large={true}
											minimal={true}
											icon={<Icon size={20} icon="caret-right"/>}
											onClick={handleQrCodeNextButtonClickedEvent}
										/>
									</ControlGroup>
									{
										item && barcode_field &&
										<Button
											className="function-button"
											icon={<Icon icon="print" className="orange"/>}
											onClick={handlePrintQRCodeButtonClickedEvent}
										>
											Print
										</Button>
									}

									<div className="row">
										<TextField
											className="col-sm-12 col-md-3 col-lg-3 text-field"
											size="small"
											variant="outlined"
											value={qr_code_size}
											onChange={(e) =>
												setQrCodeSize(
													e.target.value
												)
											}
											label={"Enter qr code size"}
										    type="number"
										    InputProps={{
										        inputProps: {
										            max: 200, min: 100, step: 5
										        }
										    }}
										/>
										<div className="col-sm-12 col-md-3 col-lg-3 text-field">
											<MuiAutocomplete
												setSelectedValue={setQrEccLevel}
												selected_value={qr_ecc_level}
												isMultiple={false}
												label={"Select Level"}
												data={qr_ecc_levels}
											/>
										</div>
									</div>
									{
										false &&
										<div className="row">
											<div className="col-sm-12 col-md-12 col-lg-12 text-field">
												<Switch alignIndicator="center" large={true} innerLabelChecked="Show Margin" innerLabel="Hide Margin"  inline={true} checked={include_margin} onChange={(e) => setIncludeMargin(!include_margin)}/>
											</div>
										</div>
									}

									{/*
									<Button
										className="function-button"
										icon="add"
										onClick={(e) =>
											dispatch(
												createPrintJobAsync({
													item: item.value,
													quantity: quantity,
													type: auth.enums.print_job_types.QR_CODE,
													field: barcode_field.value,
													value: qr_code_value
												})
											)
										}
									>
										Add QR Code item to Print Queue
									</Button>
									<Button
										className="function-button"
										icon="floppy-disk"
										onClick={handlePrintSelectedRowsQRCodeButtonClickedEvent}
									>
										Print Selected Row/s
									</Button>
									*/}

								</Card>
							
						</div>
					</Card>

				</div>

				{/*
				<ButtonGroup className="button-group" minimal={false}>
					<Switch
						className="switch-mode"
						checked={!auth.worksheet.is_readonly_mode}
						disabled={auth.status === resources.status.LOADING}
						labelElement={translate.label.SWITCH_MODE}
						innerLabelChecked={translate.label.IMPORT}
						innerLabel={translate.label.READ_ONLY}
						alignIndicator={Alignment.BOTTOM}
						inline={true}
						onChange={e => dispatch(switchWorksheetMode())}
					/>
					{
						auth.worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon="document"
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => PDF.export(
										translate.worksheet.file_name.PROFIT_COST_CENTER,
										auth.worksheet.header,
										auth.worksheet.data_set
									)
								}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon="th"
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => Excel.export(
										translate.worksheet.file_name.PROFIT_COST_CENTER,
										auth.worksheet.header,
										auth.worksheet.data_set,
										auth.active_company
									)
								}
							/>
							<Button
								text={selected_rows.length>0 ? translate.button.UNSELECT_ALL : translate.button.SELECT_ALL}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon="select"
								onClick={e => setSelectedRows(selected_rows.length>0 ? [] : auth.worksheet.data_set.map((dataSet, key) => key))}
							/>
							{
								selected_rows.length>0 &&
								<Button
									text={translate.button.DELETE_ROWS}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon="delete"
									onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
								/>
							}
						</>
					}
					{
						!auth.worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.IMPORT}
								className="function-button"
								icon="upload"
								disabled={auth.status===resources.status.LOADING}
								onClick={handleImportButtonClickedEvent}
							/>
							<Button
								text={translate.button.CLEAR_SELECTED_CELLS}
								className="function-button"
								icon="clean"
								disabled={auth.status===resources.status.LOADING}
								onClick={e => dispatch(clearSelectedCells())}
							/>
							<Button
								text={translate.button.CLEAR_CELLS}
								className="function-button"
								icon="clean"
								disabled={auth.status===resources.status.LOADING}
								onClick={e => dispatch(clearCells())}
							/>
						</>
					}
					<Button
						text={auth.worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
						className="function-button"
						icon="panel-table"
						disabled={auth.status === resources.status.LOADING}
						onClick={() => dispatch(toggleFreezePane())}
					/>
				</ButtonGroup>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={handleDeleteRowsButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>System will automatically conduct further verification if deletion of certain print job/s is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				*/}

			</div>
		</div>
	);
}
