import React, { useCallback, useEffect, useState } from "react";
import Util from "../../../common/Util";
import { Note } from "../../../common/Note";
import { SideBar } from '../../../SideBar';
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	toggleSidebarCollapsed,
	skipProcessAsync,
	fetchLaundryMachineSetsAsync,
	fetchAllProfitCostCentersAsync,
	savePosTerminalSettingsAsync,
	createLaundryMachineSetAsync,
	createPosTerminalAsync,
	fetchPosTerminalsAsync,
	resetPosTerminalAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	ControlGroup,
	Icon,
	RadioGroup,
	Radio,
	Checkbox,
	FormGroup
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import QRCode from 'qrcode.react';
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";

export function PosTerminalSettings() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [role_user] = useState(auth.role_user.filter((roleUser) => (roleUser.user_id===auth.user.id)).map((roleUser) => roleUser.role_id));
	const [qr_code_size] = useState(120);
	const [laundry_machine_sets, setLaundryMachineSets] = useState([]);
	const [branches, setBranches] = useState([]);
	const [branch, setBranch] = useState(null);
	const [assigned_branch, setAssignedBranch] = useState(null);
	const [company_business_nature, setCompanyBusinessNature] = useState(auth.active_company.nature);
	const [enable_legal_discounts, setEnableLegalDiscounts] = useState(auth.active_company.enable_legal_discounts);
	const [dryer_number, setDryerNumber] = useState("");
	const [washer_number, setWasherNumber] = useState("");
	const [dryer_initial_cycle_count, setDryerInitialCycleCount] = useState("");
	const [washer_initial_cycle_count, setWasherInitialCycleCount] = useState("");
	const [pos_terminal_name, setPosTerminalName] = useState("");
	const [pos_terminal_branch, setPosTerminalBranch] = useState(null);
	const [pos_terminals, setPosTerminals] = useState([]);
	const [cash_remittance_intervals] = useState([
		{label:'1 End-of-Day', value:1},
		{label:'2 End-of-Days', value:2},
		{label:'3 End-of-Days', value:3},
		{label:'4 End-of-Days', value:4},
		{label:'5 End-of-Days', value:5},
		{label:'6 End-of-Days', value:6},
		{label:'7 End-of-Days', value:7},
	]);
	const [cash_remittance_interval, setCashRemittanceInterval] = useState(auth.active_company.cash_remittance_interval ? cash_remittance_intervals.find((vl) => (vl.value===auth.active_company.cash_remittance_interval)) : null);

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.POS_TERMINAL_SETTINGS));
		dispatch(fetchLaundryMachineSetsAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setLaundryMachineSets(response.payload.data.laundry_machine_sets) : void(0)));
		dispatch(fetchPosTerminalsAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setPosTerminals(response.payload.data.pos_terminals) : void(0)));
		const updateBranchList = (results) => {
			let branches = results.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((branch) => ({label:`${branch.code} | ${branch.name}`, type:branch.type, value:branch.id}));
			setBranches(branches);
		}
		dispatch(fetchAllProfitCostCentersAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? updateBranchList(response.payload.data.branches) : void(0)));
	}, [
		dispatch,
		translate.breadcrumb.POS_TERMINAL_SETTINGS
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (branch) {
			dispatch(fetchLaundryMachineSetsAsync({branch:branch ? branch.value : ""})).then((response) => (response.payload.status===resources.status.SUCCESS ? setLaundryMachineSets(response.payload.data.laundry_machine_sets) : void(0)));
		}
	}, [
		dispatch,
		branch
	]);

	const handleSkipButtonClickedEvent = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'pos-terminal-settings'})).then((response) => response.payload.data && response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const downloadQRCode = () => {
		const qrURL = document.getElementById('qr-image').toDataURL("image/png").replace("image/png", "image/octet-stream");
		let downloadButton = document.createElement("a");
		downloadButton.href = qrURL;
		downloadButton.download = "Point_of_Sale_Android_QR_Code.png";
		document.body.appendChild(downloadButton);
		downloadButton.click();
		document.body.removeChild(downloadButton);
	}

	const resetLaundryMachineSetFields = () => {
		setAssignedBranch(null);
		setWasherNumber("");
		setDryerNumber("");
		setWasherInitialCycleCount("");
		setDryerInitialCycleCount("");
	};

	const resetPosTerminalFields = () => {
		setPosTerminalBranch(null);
		setPosTerminalName("");
	};

	const createLaundryMachineSet = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setLaundryMachineSets([...laundry_machine_sets, response.payload.data.laundry_machine_set]);
				resetLaundryMachineSetFields();
			}
		}
		dispatch(createLaundryMachineSetAsync({
			assigned_branch:assigned_branch ? assigned_branch.value : "",
			dryer_number:dryer_number,
			washer_number:washer_number,
			dryer_initial_cycle_count:dryer_initial_cycle_count,
			washer_initial_cycle_count:washer_initial_cycle_count,
		})).then((response) => handleResponse(response))
	};

	const createPosTerminal = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setPosTerminals([...pos_terminals, response.payload.data.pos_terminal]);
				resetPosTerminalFields();
			}
		}
		dispatch(createPosTerminalAsync({
			pos_terminal_branch:pos_terminal_branch ? pos_terminal_branch.value : "",
			pos_terminal_name:pos_terminal_name,
		})).then((response) => handleResponse(response))
	};

	const handleResetTerminalIdButtonClickedEvent = (id) => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setPosTerminals(pos_terminals.map((pt) => (pt.id===response.payload.data.pos_terminal.id ? response.payload.data.pos_terminal : pt)));
			}
		}
		dispatch(resetPosTerminalAsync({
			id:id,
		})).then((response) => handleResponse(response))
	};
	
	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id && role_user.includes(auth.roles.find((rl) => (rl.name===auth.enums.roles.SYSTEM_ADMIN)).id) && auth.active_company.nature && 
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleSkipButtonClickedEvent}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.POS_TERMINAL_SETTINGS) }

				{
					auth.worksheet.is_readonly_mode &&
					<>
						<ButtonGroup className="button-group" minimal={false}>
							<Button
								text={translate.button.SAVE_SETTINGS}
								className="function-button"
								disabled={auth.status === resources.status.LOADING}
								icon={<Icon icon="floppy-disk" className="orange"/>}
								onClick={() => dispatch(savePosTerminalSettingsAsync({
									nature_of_company_or_business:company_business_nature ? company_business_nature : "",
									enable_legal_discounts:enable_legal_discounts,
									cash_remittance_interval:cash_remittance_interval ? cash_remittance_interval.value : ""
								}))}
							/>
						</ButtonGroup>
						<Card className="function-card">
							<div className="row">
								<TextField
									className="col-sm-12 col-md-3 col-lg-3 text-field"
									size="small"
									variant="outlined"
									inputProps={
										{ readOnly: true }
									}
									value={`${auth.active_company.code} | ${auth.active_company.name}`}
									label={"Company / Business Name"}
								/>
								<TextField
									className="col-sm-12 col-md-3 col-lg-4 text-field"
									size="small"
									variant="outlined"
									inputProps={
										{ readOnly: true }
									}
									value={`${auth.active_company.address}`}
									label={"Company / Business Address"}
								/>
							</div>
							<div className="row">
								<div className="col-sm-12 col-md-3 col-lg-3 text-field">
									<RadioGroup
										label={`Nature of Company or Business (${auth.active_company.industry})`}
										inline={true}
										selectedValue={company_business_nature}
										onChange={(event) => setCompanyBusinessNature(event.currentTarget.value)}
									>
										{
											Object.values(auth.enums.company_business_nature).filter((cbn) => (!(cbn===auth.enums.company_business_nature.ITEM_ONLY && auth.active_company.industry===auth.enums.industries.LAUNDRY))).map((cbn, key) => (
												<Radio 
													label={cbn} 
													value={cbn} 
													key={key}
												/>
											))
										}
									</RadioGroup>
								</div>
								<div className="col-sm-12 col-md-4 col-lg-4 text-field">
									<MuiAutocomplete
										setSelectedValue={setCashRemittanceInterval}
										selected_value={cash_remittance_interval}
										isMultiple={false}
										label={translate.placeholder.CASH_REMITTANCE_INTERVAL}
										data={cash_remittance_intervals}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12 col-md-7 col-lg-7">
									<FormGroup>
										<Checkbox
											checked={ enable_legal_discounts }
											large={true}
											inline={true}
											label={<span style={{fontSize:14}}>Enable Legal Discounts</span>}
											onChange={e => setEnableLegalDiscounts(!enable_legal_discounts)}
										/>
									</FormGroup>
								</div>
							</div>
						</Card>
					</>
				}

				{ Util.functionTitle(translate.text.POS_TERMINAL) }

				<>
					<ButtonGroup className="button-group" minimal={false}>
						<div style={{width:"200px"}}>
							<MuiAutocomplete
								setSelectedValue={setBranch}
								selected_value={branch}
								isMultiple={false}
								label={"Filter by Branch"}
								data={branches}
							/>
						</div>

						<Button
							text={translate.button.CREATE}
							className="function-button"
							disabled={auth.status === resources.status.LOADING}
							icon={<Icon icon="floppy-disk" className="orange"/>}
							onClick={() => createPosTerminal()}
						/>
					</ButtonGroup>
					<Card className="function-card">
						<div className="row">
							<div className="col-sm-12 col-md-2 col-lg-2 text-field">
								<MuiAutocomplete
									setSelectedValue={setPosTerminalBranch}
									selected_value={pos_terminal_branch}
									isMultiple={false}
									label={"Select POS Terminal Branch"}
									data={branches}
								/>
							</div>
							<TextField
								className="col-sm-12 col-md-2 col-lg-2 text-field"
								size="small"
								variant="outlined"
								value={pos_terminal_name}
								onChange={(e) => setPosTerminalName(e.target.value)}
								label={"Enter POS Terminal Name"}
							/>
						</div>
						<div className="row">
							{
								pos_terminals.map((pt, key) => (
									<Card className="col-lg-2 col-md-3 col-sm-12 ml-5" key={key}>
										<ControlGroup vertical={true}>
											<p>POS Term. Name: {pt.terminal_name}</p>
											<p>POS Term. ID: {pt.terminal_id}</p>
											<p>Branch: {pt.branch_name}</p>
											<p>Status: {pt.device_id ? "Currently installed in a device" : "Not yet installed"}</p>
											{
												<Button
													text={"Generate new Terminal ID to Transfer to a New Device"}
													style={{backgroundColor:pt.device_id===null ? "transparent" : "cyan"}}
													large={true}
													rightIcon={<Icon icon="reset" className="orange"/>}
													disabled={auth.status === resources.status.LOADING||pt.device_id===null}
													onClick={() => handleResetTerminalIdButtonClickedEvent(pt.id)}
													data-tooltip="test"
												/>
											}
										</ControlGroup>
									</Card>
								))
							}
							{
								pos_terminals.length===0 &&
								<h4 className="red">There are no pos terminal/s available.  You need to create pos terminal and then assign the generated terminal id to the POS application.</h4>
							}
						</div>
						{
							pos_terminals.length>0 &&
							<div className="row mt-5">
								<div className="col-sm-12 col-md-2 col-lg-1">
									<QRCode
										id="qr-image"
										size={qr_code_size}
										value={auth.active_company.ordering_and_appointment_app.url}
										includeMargin={true}
										level={auth.enums.qr_ecc_levels.LOW}
									/>
								</div>
								<div className="col-sm-12 col-md-4 col-lg-5">
									<div style={{marginTop:"10px", marginLeft:"10px", color:"blue", fontWeight:"bold"}}>
										You can scan the QR image to download and install the POS application to your android device .
									</div>
									<Button
										text={translate.button.DOWNLOAD_QR_CODE}
										className="function-button download-btn"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="download" className="orange"/>}
										onClick={downloadQRCode}
									/>
								</div>
							</div>
						}
					</Card>
				</>

				{
					auth.active_company.industry===auth.enums.industries.LAUNDRY &&
					<>
						{ Util.functionTitle(translate.text.LAUNDRY_MACHINE_SET) }
						<ButtonGroup className="button-group" minimal={false}>
							<div style={{width:"200px"}}>
								<MuiAutocomplete
									setSelectedValue={setBranch}
									selected_value={branch}
									isMultiple={false}
									label={"Filter by Branch"}
									data={branches}
								/>
							</div>

							<Button
								text={translate.button.CREATE}
								className="function-button"
								disabled={auth.status === resources.status.LOADING}
								icon={<Icon icon="floppy-disk" className="orange"/>}
								onClick={() => createLaundryMachineSet()}
							/>
						</ButtonGroup>
						<Card className="function-card">
							<div className="row">
								<div className="col-sm-12 col-md-2 col-lg-2 text-field">
									<MuiAutocomplete
										setSelectedValue={setAssignedBranch}
										selected_value={assigned_branch}
										isMultiple={false}
										label={"Assigned Branch"}
										data={branches}
									/>
								</div>
								<TextField
									className="col-sm-12 col-md-2 col-lg-2 text-field"
									size="small"
									variant="outlined"
									value={dryer_number}
									onChange={(e) => setDryerNumber(e.target.value)}
									label={"Dryer Name / Number"}
								/>
								<TextField
									className="col-sm-12 col-md-2 col-lg-2 text-field"
									size="small"
									variant="outlined"
									value={washer_number}
									onChange={(e) => setWasherNumber(e.target.value)}
									label={"Washer Number"}
								/>
							</div>
							<div className="row">
								<div className="col-sm-12 col-md-2 col-lg-2 text-field">
								</div>
								<TextField
									className="col-sm-12 col-md-2 col-lg-2 text-field"
									size="small"
									variant="outlined"
									value={dryer_initial_cycle_count}
									onChange={(e) => setDryerInitialCycleCount(e.target.value)}
									label={"Dryer Initial Cycle Count"}
									type="number"
								/>
								<TextField
									className="col-sm-12 col-md-2 col-lg-2 text-field"
									size="small"
									variant="outlined"
									value={washer_initial_cycle_count}
									onChange={(e) => setWasherInitialCycleCount(e.target.value)}
									label={"Washer Initial Cycle Count"}
									type="number"
								/>
							</div>
							<div className="row">
								{
									laundry_machine_sets.map((lms, key) => (
										<Card className="col-lg-2 col-md-3 col-sm-12 laundry-machine-set-container" key={key}>
											<h4>Laundry Machine Set {key+1}</h4>
											<h5>{lms.branch_name}</h5>
											<ControlGroup vertical={true}>
												<Card className="laundry-machine-set-card">
													<div className="row">
														<div className="col-sm-12 col-md-6 col-lg-6">
															<p>{lms.dryer_number}</p>
															<p style={{fontSize:11}}>Dryer</p>
														</div>
														<div className="col-sm-12 col-md-6 col-lg-6 right">
															<p>Cycle Count: {lms.dryer_initial_cycle_count}</p>
														</div>
													</div>
												</Card>
												<Card className="laundry-machine-set-card">
													<div className="row">
														<div className="col-sm-12 col-md-6 col-lg-6">
															<p>{lms.washer_number}</p>
															<p style={{fontSize:11}}>Washer</p>
														</div>
														<div className="col-sm-12 col-md-6 col-lg-6 right">
															<p>Cycle Count: {lms.washer_initial_cycle_count}</p>
														</div>
													</div>
												</Card>
											</ControlGroup>
										</Card>
									))
								}
								{
									laundry_machine_sets.length===0 &&
									<h4 className="red">There are no laundry machine set/s found.</h4>
								}
							</div>
						</Card>
					</>
				}

			</div>
		</div>
	);
}
