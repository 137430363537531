import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
//import { resources } from "../resources/index";

const combinedReducer = combineReducers({
	auth: authReducer,
});

const rootReducer = (state, action) => {
	/*
	let userIsNotAuthenticated = (action.payload && action.payload.message &&action.payload.message==='Unauthenticated.');
	if ((action.type && action.type==="auth/logout/fulfilled") || userIsNotAuthenticated) {
		localStorage.removeItem(resources.slice.AUTH);
		if (userIsNotAuthenticated) {
			window.location.reload();
		}
	}
	*/
	return combinedReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
	  immutableCheck: false,
    }),
});