import React, { useCallback, useEffect, useState } from 'react';
import PDF from '../../../common/PDF';
import Excel from '../../../common/Excel';
import Util from '../../../common/Util';
import TextField from "@material-ui/core/TextField";
import toast from "react-hot-toast";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { Note } from "../../../common/Note";
//import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from 'react-redux';
import {
	selectAuth,
	updateNavigation,
	toggleSidebarCollapsed,
	skipProcessAsync,
	updateDiscountAsync,
	createDiscountAsync,
	fetchAllItemsAsync,
	fetchDiscountAsync,
	fetchAllDiscountsAsync,
	deleteDiscountsAsync,
	importDiscountsAsync
} from '../../auth/authSlice';
import { resources } from '../../../resources/index';
import {
	Button,
	ButtonGroup,
	Card,
	ControlGroup,
	FormGroup,
	Icon,
	Switch
} from '@blueprintjs/core';
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/table/lib/css/table.css';
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { DiscountSpreadsheet } from '../../../spreadsheets/DiscountSpreadsheet';
import moment from "moment";

export function Discounts() {

	const auth = useSelector(selectAuth);
	const history = useHistory();
	const translate = resources['lang'][auth.locale];
	const dispatch = useDispatch();
	const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);
	const [selected_rows, setSelectedRows] = useState([]);
	const [discount_types] = useState(Object.values(auth.enums.discount_types).map((discountType) => ({label:discountType, value:discountType})));
	const [discount_type, setDiscountType] = useState(null);
	const [discount_rate, setDiscountRate] = useState("");
	const [source, setSource] = useState(null);
	const [sources] = useState(Object.values(auth.enums.discount_sources).map((src) => ({label:src, value:src})));
	const [description, setDescription] = useState("");
	const [items, setItems] = useState([]);
	const [purchase_quantity, setPurchaseQuantity] = useState("");
	const [freebie_quantity, setFreebieQuantity] = useState("");
	const [freebie_item, setFreebieItem] = useState(null);
	const [id, setId] = useState(null);
	const [discount_start_date, setDiscountStartDate] = useState("");
	const [discount_expiry_date, setDiscountExpiryDate] = useState("");

	const DEFAULT_HEADER = translate.worksheet.default_header.DISCOUNTS;
	const DEFAULT_ALIGNMENT = translate.worksheet.default_alignment.DISCOUNTS;
	const IMPORT_HEADER = translate.worksheet.import_header.DISCOUNTS;
	const IMPORT_ALIGNMENT = translate.worksheet.import_alignment.DISCOUNTS;
	const COMMENT = translate.worksheet.import_comment.DISCOUNTS;
	const NUM_ROWS = 1000;
	const NUM_COLS = DEFAULT_HEADER.length;
	const IMPORT_LIMIT = 700;
	let DEFAULT_DATA_SET = new Array(NUM_ROWS).fill("").map(() => new Array(NUM_COLS).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.DISCOUNTS));
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllItemsAsync({})).then((response) => (response.payload.data ? setItems(response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description))).map((itm) => ({label:itm.description, uom:itm.unit_of_measure.code, value:itm.id}))) : void(0)));
			dispatch(fetchAllDiscountsAsync({})).then((response) => response.payload.data ? 
				setWorkSheet({
					is_readonly_mode: true,
					num_rows: NUM_ROWS>response.payload.data.discounts.length ? NUM_ROWS : response.payload.data.discounts.length,
					num_cols: DEFAULT_HEADER.length,
					num_frozen_columns: 0,
					import_limit: IMPORT_LIMIT,
					focused_cell: null,
					selected_regions: null,
					header: DEFAULT_HEADER,
					alignment: DEFAULT_ALIGNMENT,
					comment: [],
					data_set: response.payload.data.discounts.map((ds) => [
						ds.id,
						ds.source,
						ds.type,
						ds.name,
						ds.created_by ? ds.created_by : "System-Defined",
						ds.rate,
						ds.purchase_quantity,
						ds.freebie_quantity,
						ds.freebie_item_description,
						ds.discount_start_date,
						ds.discount_expiry_date,
					])
				}) : void(0)
			);
		}
	}, [
		dispatch,
		translate.breadcrumb.DISCOUNTS,
		worksheet.is_readonly_mode,
		setWorkSheet,
		DEFAULT_ALIGNMENT,
		DEFAULT_HEADER
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const onSkipButtonClicked = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:"discounts"})).then((response) => response.payload.data && response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onClearButtonClicked  = () => {
		setId(null);
		setDiscountType(null);
		setSource(null);
		setDiscountRate("");
		setDescription("");
		setDiscountStartDate("");
		setDiscountExpiryDate("");
		setPurchaseQuantity("");
		setFreebieItem(null);
		setFreebieQuantity("");
	}

	const onDiscountTypeChanged  = (discountType) => {
		setDiscountRate("");
		setPurchaseQuantity("");
		setFreebieItem(null);
		setFreebieQuantity("");
		setDiscountType(discountType);
	}

	const onEditButtonClicked = (row) => {
		const loadDiscount = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setId(response.payload.data.discount.id);
				setSource(sources.find((src) => (src.value===response.payload.data.discount.source)));
				setDiscountType(discount_types.find((dsc) => (dsc.value===response.payload.data.discount.type)));
				setDescription(response.payload.data.discount.name);
				setDiscountStartDate(response.payload.data.discount.discount_start_date ? moment(response.payload.data.discount.discount_start_date, resources.date_format.SYSTEM).format(resources.date_format.INPUT) : "");
				setDiscountExpiryDate(response.payload.data.discount.discount_expiry_date ? moment(response.payload.data.discount.discount_expiry_date, resources.date_format.SYSTEM).format(resources.date_format.INPUT) : "");
				setDiscountRate(response.payload.data.discount.rate);
				setPurchaseQuantity(response.payload.data.discount.purchase_quantity);
				setFreebieQuantity(response.payload.data.discount.freebie_quantity);
				setFreebieItem(items.find((itm) => (itm.value===response.payload.data.discount.freebie_item_id)));
				window.scrollTo(0,0);
			}
		}
		dispatch(fetchDiscountAsync({id:worksheet.data_set[row][0]})).then((response) => loadDiscount(response));
	}

	const onDeleteRowsButtonClicked = () => {
		let ids = selected_rows.map(
			(row) => worksheet.data_set[row][0]
		);
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: worksheet.data_set.filter((ds) => (!ids.includes(ds[0])))
				});
				setSelectedRows([]);
			}
		}
		dispatch(deleteDiscountsAsync(ids)).then((response) => handleResponse(response));
	};

	const onCreateButtonClicked = (response) => {
		onClearButtonClicked();
		setWorkSheet({
			is_readonly_mode: true,
			num_rows: 1000,
			num_cols: 10,
			num_frozen_columns: 0,
			import_limit: 700,
			focused_cell: null,
			selected_regions: null,
			header: translate.worksheet.default_header.DISCOUNTS,
			alignment: translate.worksheet.default_alignment.DISCOUNTS,
			comment: [],
			data_set: [
				[
					response.payload.data.discount.id,
					response.payload.data.discount.source,
					response.payload.data.discount.type,
					response.payload.data.discount.name,
					response.payload.data.discount.created_by ? response.payload.data.discount.created_by : "System-Defined",
					response.payload.data.discount.rate,
					response.payload.data.purchase_quantity,
					response.payload.data.freebie_quantity,
					response.payload.data.freebie_item_description,
					response.payload.data.discount.discount_start_date,
					response.payload.data.discount.discount_expiry_date,
				],
				...worksheet.data_set
			]
		});
	}

	const onUpdateButtonClicked = (response) => {
		setWorkSheet({
			is_readonly_mode: true,
			num_rows: 1000,
			num_cols: 10,
			num_frozen_columns: 0,
			import_limit: 700,
			focused_cell: null,
			selected_regions: null,
			header: translate.worksheet.default_header.DISCOUNTS,
			alignment: translate.worksheet.default_alignment.DISCOUNTS,
			comment: [],
			data_set: worksheet.data_set.map((ds) => (ds[0]===response.payload.data.discount.id ? [
				response.payload.data.discount.id,
				response.payload.data.discount.source,
				response.payload.data.discount.type,
				response.payload.data.discount.name,
				response.payload.data.discount.created_by ? response.payload.data.discount.created_by : "System-Defined",
				response.payload.data.discount.rate,
				response.payload.data.purchase_quantity,
				response.payload.data.freebie_quantity,
				response.payload.data.freebie_item_description,
				response.payload.data.discount.discount_start_date,
				response.payload.data.discount.discount_expiry_date,
			] : ds))
		});
	}

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const onSaveAsPDFButtonClicked = () => {
		PDF.export(
			translate.worksheet.file_name.DISCOUNTS,
			worksheet.header,
			worksheet.data_set,
			auth.active_company,
			"a3",
			"landscape",
			[],
			[40, 90, 90, 120, 80, 100, "*", "*", "*", "*", "*"]
		);
	}

	const onSaveAsXLSXButtonClicked = () => {
		Excel.export(
			translate.worksheet.file_name.DISCOUNTS,
			worksheet.header,
			worksheet.data_set,
			auth.active_company
		);
	}

	const onSpreadSheetModeSwitchChanged = () => {
		let newMode = !worksheet.is_readonly_mode;
		setWorkSheet({
			is_readonly_mode: newMode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onImportButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			let dataSet = worksheet.data_set.filter((dataSet) => dataSet[0].length>0||dataSet[1].length>0||dataSet[2].length>0||dataSet[3].length>0||dataSet[4].length>0);
			if (dataSet.length<=worksheet.import_limit) {
				const handleResponse = (i, length, status) => {
					if (status===resources.status.SUCCESS) {
						if (i===0) {
							onClearCellsButtonClicked();
							toast.success(translate.message.IMPORT_USERS_QUEUED, Util.getToasterStyle(resources.status.SUCCESS));
						}
						if (i===length-1) {
							toast.success(translate.message.IMPORT_USERS_FINISHED, Util.getToasterStyle(resources.status.SUCCESS));
						}
					}
				} 
				let size = 10;
				let chunks = Util.chunk(dataSet, size);
				let length = chunks.length;
				for (let i=0; i<chunks.length; i++) {
					dispatch(
						importDiscountsAsync(chunks[i])
					).then((response) => (handleResponse(i, length, response.payload.status)));
				}
			}
			else {
				toast.error(
					translate.message.IMPORT_LIMIT_REACHED,
					Util.getToasterStyle(resources.status.ERROR)
				);
			}
		}
	};

	const onClearCellsButtonClicked = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearSelectedCellsButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			if (worksheet.selected_regions!=null) {
				let dataSet = [];
				let selectedRegions = worksheet.selected_regions;
				for (let i=0; i<worksheet.data_set.length; i++) {
					let row = [];
					for (let j=0; j<worksheet.data_set[i].length; j++) {
						row.push(worksheet.data_set[i][j]);
					}
					dataSet.push(row);
				}
				for (let i=0; i<selectedRegions.length; i++) {
					let selectedRows = selectedRegions[i].rows;
					let selectedCols = selectedRegions[i].cols;
					let startRow = selectedRows[0];
					let endRow = selectedRows[1];
					let startCol = selectedCols[0];
					let endCol = selectedCols[1];
					for (let j=startRow; j<=endRow; j++) {
						if (dataSet[j]!==undefined) {
							for (let k=startCol; k<=endCol; k++) {
								if (dataSet[j][k]!==undefined) {
									dataSet[j][k] = "";
								}
							}
						}
					}
				}
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: dataSet
				});
			}
		}
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSkipButtonClicked}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.DISCOUNTS) }

				{
					worksheet.is_readonly_mode &&
					<div className="function-sub-title">
						On top of the predefined discounts below, you may create your own promo discount based on the following fields provided.
					</div>
				}

				{
					worksheet.is_readonly_mode &&
					<>
						<ButtonGroup className="button-group" minimal={false}>
							<Button
								text={id===null ? translate.button.CREATE : translate.button.UPDATE}
								className="function-button"
								disabled={auth.status === resources.status.LOADING}
								icon={<Icon icon="floppy-disk" className="orange"/>}
								onClick={(e) => 
									id===null ? 
									dispatch(createDiscountAsync({
										discount_type:discount_type ? discount_type.value : "",
										source:source ? source.value : "",
										discount_rate:discount_rate ? discount_rate : 0,
										description:description,
										discount_start_date:discount_start_date,
										discount_expiry_date:discount_expiry_date,
										purchase_quantity:purchase_quantity,
										freebie_quantity:freebie_quantity,
										freebie_item:freebie_item ? freebie_item.value : "",
									})).then((response) => response.payload.status===resources.status.SUCCESS ? onCreateButtonClicked(response) : void(0)) 
									:
									dispatch(updateDiscountAsync({
										id:id,
										discount_type:discount_type ? discount_type.value : "",
										source:source ? source.value : "",
										discount_rate:discount_rate ? discount_rate : 0,
										description:description,
										discount_start_date:discount_start_date,
										discount_expiry_date:discount_expiry_date,
										purchase_quantity:purchase_quantity,
										freebie_quantity:freebie_quantity,
										freebie_item:freebie_item ? freebie_item.value : "",
									})).then((response) => response.payload.status===resources.status.SUCCESS ? onUpdateButtonClicked(response) : void(0))
								}
							/>
							<Button
								text={translate.button.CLEAR}
								className="function-button"
								icon={<Icon icon="reset" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onClearButtonClicked}
							/>
						</ButtonGroup>

						<Card className="function-card">
							<div className="row">
								<div className="col-sm-12 col-md-3 col-lg-2 text-field">
									<MuiAutocomplete
										setSelectedValue={setSource}
										selected_value={source}
										isMultiple={false}
										label={"Select Source"}
										data={sources}
									/>
								</div>
								<div className="col-sm-12 col-md-3 col-lg-2 text-field">
									<MuiAutocomplete
										setSelectedValue={onDiscountTypeChanged}
										selected_value={discount_type}
										isMultiple={false}
										label={"Select Discount Type"}
										data={discount_types}
									/>
								</div>
								{
									discount_type && discount_type.value===auth.enums.discount_types.REGULAR &&
									<TextField
										className="col-sm-12 col-md-3 col-lg-1 text-field"
										size="small"
										variant="outlined"
										value={discount_rate}
										onChange={(e) => setDiscountRate(e.target.value)}
										label={"Discount Rate"}
										type="number"
										InputProps={{
											inputProps: {
												min: 0,
												max: 100
											}
										}}
									/>
								}
								<TextField
									className="col-sm-12 col-md-3 col-lg-2 text-field"
									size="small"
									variant="outlined"
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									label={"Description"}
								/>
								<TextField
									className="col-sm-12 col-md-2 col-lg-2 text-field"
									size="small"
									type="date"
									variant="outlined"
									value={discount_start_date}
									onChange={(e) =>
										setDiscountStartDate(
											e.target.value
										)
									}
									label={translate.placeholder.ENTER_DISCOUNT_START}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<TextField
									className="col-sm-12 col-md-2 col-lg-2 text-field"
									size="small"
									type="date"
									variant="outlined"
									value={discount_expiry_date}
									onChange={(e) =>
										setDiscountExpiryDate(
											e.target.value
										)
									}
									label={translate.placeholder.ENTER_DISCOUNT_EXPIRY}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</div>
							{
								discount_type && discount_type.value===auth.enums.discount_types.PROMO &&
								<div className="row">
									<div className="col-sm-12 col-md-3 col-lg-2 text-field">
										<ControlGroup vertical={false} style={{marginTop:24}}>
											For every purchase of the selected item:
											<TextField
												style={{width:"30%"}}
												size="small"
												variant="outlined"
												value={purchase_quantity}
												onChange={(e) => setPurchaseQuantity(e.target.value)}
												label={"Quantity"}
												type='number'
												InputProps={{
													inputProps: {
														min: 0,
													}
												}}
											/>
										</ControlGroup>
									</div>
									<div className="col-sm-12 col-md-4 col-lg-3 text-field">
										<FormGroup
											label="Get a freebie item/s of:"
										>
											<ControlGroup vertical={false}>
												<TextField
													style={{width:"20%"}}
													size="small"
													variant="outlined"
													value={freebie_quantity}
													onChange={(e) => setFreebieQuantity(e.target.value)}
													label={"Quantity"}
													type='number'
													InputProps={{
														inputProps: {
															min: 0,
														}
													}}
												/>
												<div style={{width:"80%"}}>
													<MuiAutocomplete
														label="Freebie Item"
														setSelectedValue={setFreebieItem}
														selected_value={freebie_item}
														isMultiple={false}
														data={items}
													/>
												</div>
											</ControlGroup>
										</FormGroup>
									</div>
								</div>
							}
						</Card>					
					</>
				}

				<ButtonGroup className="button-group" minimal={false}>
					<Switch
						className="switch-mode"
						checked={!worksheet.is_readonly_mode}
						disabled={auth.status === resources.status.LOADING}
						labelElement={<span className="switch-mode-label">{translate.label.SWITCH_MODE}</span>}
						innerLabelChecked={translate.label.IMPORT}
						innerLabel={translate.label.READ_ONLY}
						onChange={onSpreadSheetModeSwitchChanged}
					/>
					{
						worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsPDFButtonClicked}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsXLSXButtonClicked}
							/>
							<Button
								text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon={<Icon icon="select" className="orange"/>}
								onClick={e => setSelectedRows(selected_rows.length>0 ? [] : [...Array(worksheet.data_set.length).keys()])}
							/>
							{
								selected_rows.length>0 &&
								<Button
									text={`${translate.button.DELETE_ROWS} (${selected_rows.length})`}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="delete" className="orange"/>}
									onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
								/>
							}
						</>
					}
					{
						!worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.IMPORT}
								className="function-button"
								icon={<Icon icon="upload" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onImportButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_SELECTED_CELLS}
								className="function-button"
								icon={<Icon icon="clean" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearSelectedCellsButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_CELLS}
								className="function-button"
								icon={<Icon icon="graph-remove" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearCellsButtonClicked}
							/>
						</>
					}
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onFreezePaneButtonClicked}
						/>
					}
				</ButtonGroup>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={onDeleteRowsButtonClicked}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>System will automatically conduct further verification if deletion of certain discount/s is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<DiscountSpreadsheet
					enable_select={true}
					enable_edit={true}
					onEditButtonClicked={onEditButtonClicked}
					onClearButtonClicked={onClearButtonClicked}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
				/>

			</div>
		</div>
	);

}
