import React from "react";
import logo from "../../logo.png";
import { useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetPasswordAsync, selectAuth } from "./authSlice";
import { resources } from "../../resources/index";
import {
	Button,
	FormGroup,
	Icon,
	IconSize,
	InputGroup,
	Checkbox,
	Text
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

export function ForgotPassword() {
	const auth = useSelector(selectAuth);
	const { password_reset_token } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const translate = resources["lang"][auth.locale];
	const [password, setPassword] = useState("");
	const [confirm_password, setConfirmPassword] = useState("");
	const [is_password_visible, setIsPasswordVisible] = useState(false);

	const handleTogglePasswordVisibilityEvent = () => {
		setIsPasswordVisible(!is_password_visible);
	}

	return (
			<div className="auth-form" style={{minHeight:"100vh"}}>
				<img
					src={logo}
					className="auth-logo"
					alt={resources.app.NAME}
				/>
				<form>
					<FormGroup
						className="input-form-group mt-5"
						label={translate.label.PASSWORD}
						labelInfo={translate.label.REQUIRED}
					>
						<InputGroup
							leftIcon={
								<Icon
									icon="key"
									size={IconSize.SMALL}
									className="orange"
								/>
							}
							placeholder={translate.placeholder.ENTER_NEW_PASSWORD}
							type={is_password_visible ? "text" : "password"}
							large={true}
							round={true}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							autoComplete="off"
							maxLength={100}
						></InputGroup>
					</FormGroup>

					<FormGroup
						className="input-form-group"
						label={translate.label.CONFIRM_PASSWORD}
						labelInfo={translate.label.REQUIRED}
					>
						<InputGroup
							leftIcon={
								<Icon
									icon="key"
									size={IconSize.SMALL}
									className="orange"
								/>
							}
							placeholder={translate.placeholder.ENTER_CONFIRM_NEW_PASSWORD}
							type={is_password_visible ? "text" : "password"}
							large={true}
							round={true}
							value={confirm_password}
							onChange={(e) => setConfirmPassword(e.target.value)}
							autoComplete="off"
							maxLength={100}
						></InputGroup>
					</FormGroup>

					<div className="row mb-5">
						<div className="col-sm-12 col-md-12 col-lg-12">
							<Text style={{color:"blue", fontWeight:"bold", fontSize:"10pt", marginLeft:"10px"}}>
								<div>Password must have at least 8 characters.</div>
								<div>Password must contain at least one of the special characters: @#$%^&+=</div>
							</Text>
						</div>
					</div>

					<div className="row mb-5">
						<div className="col-sm-12 col-md-12 col-lg-12 center mt-5">
							<Checkbox
								checked={ is_password_visible }
								large={false}
								inline={true}
								label={is_password_visible ? "Hide Password" : "Show Password"}
								onChange={e => handleTogglePasswordVisibilityEvent()}
							>
								<Icon className="ml-5 orange" size={20} icon={is_password_visible ? "eye-open" : "eye-off"}/>
							</Checkbox>
						</div>
					</div>

					<Button
						disabled={auth.status === resources.status.LOADING}
						text={translate.button.RESET_PASSWORD}
						fill={true}
						large={true}
						onClick={() =>
							dispatch(
								resetPasswordAsync({
									password_reset_token: password_reset_token,
									password: password,
									confirm_password: confirm_password,
								})
							).then((response) => response&&response.payload&&response.payload.status===resources.status.SUCCESS ? history.push("/login") : void(0))
						}
					/>

				</form>
			</div>
	);
}
