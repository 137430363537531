import React, { /*useCallback,*/ useEffect, useState } from "react";
import { /*useDispatch,*/ useSelector } from "react-redux";
import { Dialog, Classes, Button, InputGroup, FormGroup, ControlGroup, FileInput, Intent, Icon } from "@blueprintjs/core";
import { resources } from "../../../resources/index";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import Papa from "papaparse";
import {
    selectAuth,
    //fetchSerialNumbersAsync,
    //fetchExpiryDatesAsync,
} from "../../auth/authSlice";
import OmnibarSelect from "../../../common/OmnibarSelect";
import moment from "moment";

export function InventoryTrackingDialogMod({document_type, is_inventory_tracking_dialog_open, setIsInventoryTrackingDialogOpen, quantity_label, selected_inventory_tracking, has_expiry, with_serial_number, handleSaveInventoryTrackingButtonClickedEvent, inventory_tracking_row, is_editable, serial_number_type}) {

    const auth = useSelector(selectAuth);
    //const dispatch = useDispatch();
    const [quantity] = useState(selected_inventory_tracking.quantity.length>0 ? parseFloat(selected_inventory_tracking.quantity) : 0);
    const [serial_numbers, setSerialNumbers] = useState(selected_inventory_tracking.serial_numbers.length>0 ? selected_inventory_tracking.serial_numbers : (with_serial_number ? Array(quantity).fill("") : []));
    const [expiry_quantities, setExpiryQuantities] = useState(selected_inventory_tracking.expiry_quantities.length>0 ? selected_inventory_tracking.expiry_quantities : (has_expiry ? (with_serial_number ? Array(quantity).fill("1") : []) : []));
    const [expiry_dates, setExpiryDates] = useState(selected_inventory_tracking.expiry_dates.length>0 ? selected_inventory_tracking.expiry_dates : (has_expiry ? (with_serial_number ? Array(quantity).fill("") : []) : []));
    const [item_conditions, setItemConditions] = useState(selected_inventory_tracking.item_conditions.length>0 ? selected_inventory_tracking.item_conditions : (with_serial_number ? Array(quantity).fill(auth.enums.item_conditions.GOOD) : Array(expiry_dates.length).fill(auth.enums.item_conditions.GOOD)));
    const [total_expiry_quantity, setTotalExpiryQuantity] = useState(with_serial_number ? quantity : expiry_quantities.reduce((a,b) => parseFloat(a)+parseFloat(b), 0));
    const [parsed_data, setParsedData] = useState([]);
    const [is_omnibar_open, setIsOmnibarOpen] = useState(false);
    const [omnibar_data, setOmnibarData] = useState([]);
    const [omnibar_ds_type, setOmnibarDsType] = useState(null);
    const [is_serial_number_auto_generated] = useState(
                                                serial_number_type===auth.enums.serial_number_types.IN_HOUSE &&
                                                document_type!==auth.enums.document_types.ITEM_LOCATION_TRANSFER && 
                                                document_type!==auth.enums.document_types.STOCK_ISSUANCE &&
                                                ( document_type!==auth.enums.document_types.STOCK_ADJUSTMENT && !selected_inventory_tracking.increment )
                                            );

    useEffect(() => {
        if (parsed_data.length>0) {
            try {
                if (with_serial_number && has_expiry) {
                    let serialNumbers = [];
                    let expiryDates = [];
                    let expiryQuantities = [];
                    if (serial_number_type===auth.enums.serial_number_types.IN_HOUSE) {
                        for (let i=0; i<serial_numbers.length; i++) {
                            let rows = parsed_data[i+1];
                            if (rows && rows.length>1) {
                                if (expiryDates.length<parseFloat(quantity)) {
                                    let expDate = new Date(rows[1]);
                                    expiryDates.push( expDate ? moment(expDate).format(resources.date_format.INPUT) : '' );
                                    expiryQuantities.push(1);
                                }
                            }
                        }
                        if (expiryQuantities.length>0||expiryDates.length>0) {
                            let diff = serial_numbers.length - expiryDates.length;
                            setExpiryQuantities(diff>0 ? [...expiryQuantities, ...Array(diff).fill(1)] : expiryQuantities);
                            setExpiryDates(diff>0 ? [...expiryDates, ...Array(diff).fill(null)] : expiryDates);
                        }
                    }
                    else {
                        for (let i=0; i<serial_numbers.length; i++) {
                            let rows = parsed_data[i+1];
                            if (rows && rows.length>1) {
                                if (serialNumbers.length<parseFloat(quantity)) {
                                    serialNumbers.push(rows[0]);
                                    let expDate = new Date(rows[1]);
                                    expiryDates.push( expDate ? moment(expDate).format(resources.date_format.INPUT) : '' );
                                    expiryQuantities.push(1);
                                }
                            }
                            else {
                                serialNumbers.push("");
                                expiryDates.push("");
                                expiryQuantities.push("");
                            }
                        }
                        if (serialNumbers.length>0||expiryQuantities.length>0||expiryDates.length>0) {
                            setSerialNumbers(serialNumbers);
                            setExpiryQuantities(expiryQuantities);
                            setExpiryDates(expiryDates);
                        }
                    }
                    setTotalExpiryQuantity(expiryQuantities.reduce((a,b) => parseFloat(a)+parseFloat(b), 0));
                } 
                else if (!with_serial_number && has_expiry) {
                    let expiryDates = [];
                    let expiryQuantities = [];
                    let itemConditions = [];
                    for (let i=0; i<parsed_data.length; i++) {
                        let rows = parsed_data[i+1];
                        if (rows && rows.length>0) {
                            //if (expiryDates.length<parseFloat(quantity)) {
                                if (rows[0].length>0 && rows[1].length>0) {
                                    expiryQuantities.push(rows[0]);
                                    let expDate = new Date(rows[1]);
                                    expiryDates.push( expDate ? moment(expDate).format(resources.date_format.INPUT) : '' );
                                    itemConditions.push(auth.enums.item_conditions.GOOD);
                                }
                            //}
                        }
                    }
                    if (expiryQuantities.length>0||expiryDates.length>0) {
                        setExpiryQuantities(expiryQuantities);
                        setExpiryDates(expiryDates);
                        setItemConditions(itemConditions);
                    }
                    setTotalExpiryQuantity(expiryQuantities.reduce((a,b) => parseFloat(a)+parseFloat(b), 0));
                } 
                else if (with_serial_number && !has_expiry) {
                    let serialNumbers = [];
                    for (let i=0; i<serial_numbers.length; i++) {
                        let rows = parsed_data[i+1];
                        if (rows && rows.length>0) {
                            if (serialNumbers.length<parseFloat(quantity)) {
                                serialNumbers.push(rows[0]);
                            }
                        }
                        else {
                            serialNumbers.push("");
                        }
                    }
                    if (serialNumbers.length>0) {
                        setSerialNumbers(serialNumbers);
                    }
                }
            }
            catch (e) {
                console.log(e);
                toast.error(
                    "There is an error on the file that you have uploaded. Please download and use the template provided.",
                    Util.getToasterStyle(resources.status.ERROR)
                );
            }
        }
    }, [parsed_data, with_serial_number, has_expiry, quantity, auth.enums.serial_number_types.IN_HOUSE, auth.enums.item_conditions.GOOD, serial_number_type, serial_numbers.length]);

    const handleAddNewExpiryDateButtonClickedEvent = () => {
        let expiryQuantities = [...expiry_quantities, total_expiry_quantity-quantity>0 ? total_expiry_quantity-quantity : 1];
        setExpiryQuantities(expiryQuantities);
        setExpiryDates([...expiry_dates, ""]);
        setItemConditions([...item_conditions, auth.enums.item_conditions.GOOD]);
        setTotalExpiryQuantity(expiryQuantities.reduce((a,b) => parseFloat(a)+parseFloat(b), 0));
    }

    const handleDeleteExpiryDateButtonClickedEvent = (row) => {
        let expiryQuantities = expiry_quantities.filter((expQty,key) => (key!==row));
        setExpiryQuantities(expiryQuantities);
        setExpiryDates(expiry_dates.filter((expDate,key) => (key!==row)));
        setItemConditions(item_conditions.filter((itemCondition,key) => (key!==row)));
        setTotalExpiryQuantity(expiryQuantities.reduce((a,b) => parseFloat(a)+parseFloat(b), 0));
    }

    const handleSerialNumberTextChangedEvent = (event, row) => {
        setSerialNumbers(serial_numbers.map((sn,key) => (row===key ? event.target.value : sn)));
    }

    const handleExpiryQuantityTextChangedEvent = (event, row) => {
        let expiryQuantities = expiry_quantities.map((expQty,key) => (row===key ? event.target.value : expQty));
        setExpiryQuantities(expiryQuantities);
        setTotalExpiryQuantity(expiryQuantities.reduce((a,b) => parseFloat(a)+parseFloat(b), 0));
    }

    const handleExpiryDateTextChangedEvent = (event, row) => {
        setExpiryDates(expiry_dates.map((expDate,key) => (row===key ? event.target.value : expDate)));
    }

    const handleFileChange = (e) => {
        const allowedExtensions = ["csv"];
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                toast.error(
                    "Please download and use the template provided.",
                    Util.getToasterStyle(resources.status.ERROR)
                );
                return;
            }
            if (!inputFile) {
                toast.error(
                    "Please download and use the template provided.",
                    Util.getToasterStyle(resources.status.ERROR)
                );
            }
            const reader = new FileReader();
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, { header: false });
                const parsedData = csv?.data;
                setParsedData(parsedData);
            };
            reader.readAsText(inputFile);
        }
    };

    const handleOmnibarItemSelectedEvent = (selectedItem) => {
        switch (selectedItem.ds_type.data_source) {
            case resources.omnibar_ds_types.ITEM_CONDITION : {
                setItemConditions(item_conditions.map((itemCondition,key) => (key===selectedItem.ds_type.row ? selectedItem.item.label : itemCondition)));
                break;
            }
            default: break;
        }
    }

    const handleItemConditionInputClickedEvent = (row) => {
        if (is_editable) {
            setIsOmnibarOpen(true);
            setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.ITEM_CONDITION});
            setOmnibarData(Object.values(auth.enums.item_conditions).map((itemCondition, key) => ({label:itemCondition, value:itemCondition})));
        }
    }

    return (
        <Dialog
            title="Expiry Date and Serial Number Tracking"
            icon={<Icon icon="info-sign" className="orange"/>}
            isOpen={is_inventory_tracking_dialog_open}
            onClose={e => handleSaveInventoryTrackingButtonClickedEvent({inventory_tracking_row:inventory_tracking_row, serial_numbers:serial_numbers, expiry_dates:expiry_dates, expiry_quantities:expiry_quantities, item_conditions:item_conditions})}
            isCloseButtonShown={true}
            
            style={{width:"80vw", marginTop:55}}
        >
            <div className={Classes.DIALOG_BODY} style={{maxHeight:"500px",overflowY:"scroll"}}>
                <OmnibarSelect 
                    is_omnibar_open={is_omnibar_open} 
                    setIsOmnibarOpen={setIsOmnibarOpen} 
                    omnibar_data={omnibar_data}
                    omnibar_ds_type={omnibar_ds_type}
                    handleOmnibarItemSelectedEvent={handleOmnibarItemSelectedEvent}
                />
                {
                    selected_inventory_tracking && selected_inventory_tracking.item &&
                    <>

                        <div className="row" style={{textAlign:"left"}}>
                            <div className="col-sm-12 col-md-6 col-lg-5 mb-5">
                                <div>Item: <span style={{color:'blue'}}>{selected_inventory_tracking.item.label}</span></div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-5 mb-5">
                                <div>Total {quantity_label}: <span style={{color:'blue'}}>{Util.formatQuantity(selected_inventory_tracking.quantity)} {selected_inventory_tracking.uom ? selected_inventory_tracking.uom.label : ''}</span></div>                                
                            </div>
                        </div>

                        <div className="row" style={{textAlign:"left"}}>
                            <div className="col-sm-12 col-md-6 col-lg-5 mb-5">
                                <div>Default UoM: <span style={{color:'blue'}}>{selected_inventory_tracking.item.default_uom_name}</span></div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-5 mb-5">
                                <div>Item Costing: <span style={{color:'blue'}}>{selected_inventory_tracking.item.item_costing}</span></div>                                
                            </div>
                        </div>

                        <div className="row" style={{textAlign:"left"}}>
                            <div className="col-sm-12 col-md-6 col-lg-5 mb-5">
                                <div>Has Expiry: <span style={{color:'blue'}}>{has_expiry ? 'yes' : 'no'}</span></div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-5 mb-5">
                                <div>With Serial Number: <span style={{color:'blue'}}>{with_serial_number ? 'yes' : 'no'}</span></div>    
                            </div>
                        </div>

                        <div className="row" style={{textAlign:"left"}}>
                            <div className="col-sm-12 col-md-6 col-lg-5 mb-5">
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-5 mb-5">
                                {
                                    with_serial_number &&
                                    <div style={{marginBottom:"10px"}}>Serial Number Type: <span style={{color:'blue'}}>{serial_number_type}</span></div>
                                }
                            </div>
                        </div>

                        {
                            with_serial_number && is_editable && is_serial_number_auto_generated &&
                            <div style={{width:"100%", paddingLeft:10, paddingRight:10}}>
                                <p style={{fontSize:"10pt"}}><Icon size={17} icon="info-sign" className="orange"/>{" "}In Serial Number Type "In-house", serial number/s will be automatically generated upon creation of the transaction document. The quantity cannot be modified once the serial numbers are generated but you can still delete the line item or void the document if you've made a mistake.</p>
                            </div>
                        }

                        {
                            is_editable &&
                            <div className="row">
                                {
                                    with_serial_number && has_expiry && !is_serial_number_auto_generated
                                    ? 
                                    <div className="col-sm-12 col-md-10 col-lg-9">
                                        <ControlGroup vertical={false}>
                                            <FileInput fill={true} text="Attach the template to upload Serial Numbers & Expiry Dates" style={{margin:"2px"}} onInputChange={e => handleFileChange(e)}/>
                                            <Button
                                                minimal={true}
                                                icon={<Icon icon="download" className="orange"/>}
                                            >
                                                <a href={(process.env.REACT_APP_PORT ? `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}` : `${process.env.REACT_APP_HOST}`)+`/serial_numbers_and_expiry_dates.csv`}>
                                                    {"Download Template with Sample Data"}
                                                </a>
                                            </Button>
                                        </ControlGroup>
                                    </div>
                                    :
                                    <div className="column">
                                        {
                                            with_serial_number && !is_serial_number_auto_generated && 
                                            <div className="row">
                                                <ControlGroup className="col-sm-12 col-md-10 col-lg-9" vertical={false}>
                                                    <FileInput fill={true} text="Attach the template to upload Serial Numbers" style={{margin:"2px"}} onInputChange={e => handleFileChange(e)}/>
                                                    <Button
                                                        minimal={true}
                                                        icon={<Icon icon="download" className="orange"/>}
                                                    >
                                                        <a href={(process.env.REACT_APP_PORT ? `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}` : `${process.env.REACT_APP_HOST}`)+`/serial_numbers.csv`}>
                                                            {"Download Template with Sample Data"}
                                                        </a>
                                                    </Button>
                                                </ControlGroup>
                                            </div>
                                        }
                                        {
                                            has_expiry &&
                                            <div className="row"> 
                                                <ControlGroup className="col-sm-12 col-md-10 col-lg-9" vertical={false}>
                                                    <FileInput fill={true} text="Attach the template to upload Expiry Dates" style={{margin:"2px"}} onInputChange={e => handleFileChange(e)}/>
                                                    <Button
                                                        minimal={true}
                                                        icon={<Icon icon="download" className="orange"/>}
                                                    >
                                                        <a href={(process.env.REACT_APP_PORT ? `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}` : `${process.env.REACT_APP_HOST}`)+`/expiry_dates.csv`}>
                                                            {"Download Template with Sample Data"}
                                                        </a>
                                                    </Button>
                                                </ControlGroup>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <ControlGroup vertical={false}>
                                        <ControlGroup vertical={true}>
                                        {
                                            with_serial_number &&
                                            serial_numbers.map((sn, key) => (
                                                <div key={key}>
                                                    <InputGroup readOnly={!is_editable||is_serial_number_auto_generated} className="inventory-tracking-input " placeholder={"Serial Number"} value={sn} onChange={e => handleSerialNumberTextChangedEvent(e,key)}/>
                                                </div>
                                            ))
                                        }
                                        </ControlGroup>
                                        <ControlGroup vertical={true}>
                                            {
                                                !with_serial_number ? 
                                                expiry_quantities.map((expQty, key) => (
                                                    <div key={key}>
                                                        <InputGroup readOnly={!is_editable} className="inventory-tracking-input" placeholder="Qty" value={expQty} type="number" onChange={e => handleExpiryQuantityTextChangedEvent(e,key)}/>
                                                    </div>
                                                ))
                                                : null
                                            }
                                            {
                                                has_expiry && !with_serial_number && expiry_dates.length>0 &&
                                                <InputGroup className="inventory-tracking-input" type="number" style={{textAlign:"right"}} readOnly={true} value={total_expiry_quantity}/>
                                            }
                                        </ControlGroup>
                                        <ControlGroup vertical={true}>
                                            {
                                                item_conditions.map((item_condition, key) => (
                                                    <div key={key}>
                                                        <InputGroup className="inventory-tracking-input" placeholder="Item Condition" readOnly={true} value={item_condition ? item_condition : ""} onClick={e => handleItemConditionInputClickedEvent(key)}/>
                                                    </div>
                                                ))
                                            }
                                        </ControlGroup>
                                        <ControlGroup vertical={true}>
                                            {
                                                expiry_dates.map((exp, key) => (
                                                    <div key={key}>
                                                        {
                                                            has_expiry ? 
                                                            <InputGroup readOnly={!is_editable} className="inventory-tracking-input" type="date" defaultValue={exp} onChange={e => handleExpiryDateTextChangedEvent(e,key)} rightElement={
                                                                !with_serial_number ? 
                                                                <Button
                                                                    icon={"cross"}
                                                                    intent={Intent.DANGER}
                                                                    minimal={true}
                                                                    onClick={e => handleDeleteExpiryDateButtonClickedEvent(key)}
                                                                    disabled={!is_editable||expiry_dates.length<2}
                                                                />
                                                                : null
                                                            }/>
                                                            : null
                                                        }
                                                    </div>
                                                ))
                                            }
                                            {
                                                !with_serial_number && has_expiry && expiry_dates.length>0 &&
                                                <FormGroup
                                                    style={{padding:"0px",margin:"0px"}}
                                                    inline={true}
                                                    helperText={total_expiry_quantity!==quantity ? <span style={{color:"red", marginLeft:"5px"}}>The total quantity must be equal to {quantity}.</span> : ""}
                                                />
                                            }
                                        </ControlGroup>
                                    </ControlGroup>
                                </div>

                            </div>
                        </div>
                    </>
                }
            </div>
            <div style={{textAlign:"center"}}>
                {
                    !with_serial_number &&
                    <Button 
                        className="inventory-tracking-input"
                        large={false} 
                        icon={<Icon icon="add" className="orange"/>} 
                        minimal={true}
                        text={"Add New Expiry Date"}
                        onClick={handleAddNewExpiryDateButtonClickedEvent}
                        disabled={!is_editable}
                    />
                }
            </div>
            <div style={{textAlign:"center"}}>
                <Button
                    className={'function-button'}
                    icon={<Icon icon="floppy-disk" className="orange"/>}
                    onClick={e => handleSaveInventoryTrackingButtonClickedEvent({inventory_tracking_row:inventory_tracking_row, serial_numbers:serial_numbers, expiry_dates:expiry_dates, expiry_quantities:expiry_quantities, item_conditions:item_conditions})}
                    disabled={(!is_editable)||quantity!==total_expiry_quantity}
                >
                    {"Save & Close"}
                </Button>
            </div>
        </Dialog>
    );
}