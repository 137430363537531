import React, { useEffect, useState } from "react";
import { Button, Popover, Menu, MenuItem, Icon, MenuDivider } from '@blueprintjs/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { 
	selectAuth, 
	toggleSidebarCollapsed, 
	fetchDocumentsCountAsync, 
	fetchMessagesCountAsync, 
	fetchAllOrdersAsync,
	cycleCountReconCountAsync
} from './features/auth/authSlice';
import { resources } from "./resources/index";
import './App.css';
import Util from "./common/Util";

export function Notifier() {

	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const history = useHistory();
	const [ draft_count, setDraftCount ] = useState(0);
	const [ rejected_count, setRejectedCount ] = useState(0);
	const [ approved_count, setApprovedCount ] = useState(0);
	const [ for_approval_count, setForApprovalCount ] = useState(0);
	const [ documents_to_approve_count, setDocumentsToApprove ] = useState(0);
	const [ voided_count, setVoidedCount] = useState(0);
	const [ notifier_total_count, setTotalNotifierCount ] = useState(0);
	const [ application_in_progress, setApplicationInProgress ] = useState(null);
	const [ unread_messages, setUnreadMessages ] = useState(0);
	const [ orders_count, setOrdersCount ] = useState([]);
	const [ cycle_count_recon_count, setCycleCountReconCount ] = useState(0);

	useEffect(() => {
		dispatch(fetchAllOrdersAsync()).then((response) => (response.payload.status===resources.status.SUCCESS ? setOrdersCount(response.payload.data.orders_count) : null));
		dispatch(fetchMessagesCountAsync()).then((response) => (response.payload.status===resources.status.SUCCESS ? setUnreadMessages(response.payload.data.count) : null));
	}, [dispatch, auth.notifier]);

	useEffect(() => {
		if (auth.active_company && auth.active_company.application_id && auth.user.roles.includes(auth.enums.roles.SYSTEM_ADMIN)) {
			setApplicationInProgress(auth.applications.find((app) => app.id===auth.active_company.application_id));
		}
		else {
			setApplicationInProgress(null);
		}
	}, [auth.active_company, auth.applications, auth.user.roles, auth.enums.roles.SYSTEM_ADMIN]);

	useEffect(() => {

		dispatch(fetchDocumentsCountAsync({
			status:'draft'
		})).then((response) => setDraftCount(response.payload.data ? response.payload.data : 0));

		dispatch(fetchDocumentsCountAsync({
			status:'rejected'
		})).then((response) => setRejectedCount(response.payload.data ? response.payload.data : 0));

		dispatch(fetchDocumentsCountAsync({
			status:'for-approval'
		})).then((response) => setForApprovalCount(response.payload.data ? response.payload.data : 0));

		dispatch(fetchDocumentsCountAsync({
			status:'for-my-approval'
		})).then((response) => setDocumentsToApprove(response.payload.data ? response.payload.data : 0));

		dispatch(fetchDocumentsCountAsync({
			status:'approved'
		})).then((response) => setApprovedCount(response.payload.data ? response.payload.data : 0));

		dispatch(fetchDocumentsCountAsync({
			status:'voided'
		})).then((response) => setVoidedCount(response.payload.data ? response.payload.data : 0));

	}, [
		dispatch,
		auth.notifier
	]);

	useEffect(() => {

		if (auth.active_company) {
			if (auth.active_company.application_id!==null) {
				setTotalNotifierCount(
					(application_in_progress ? 1 : 0)
				);
			}
			else {
				setTotalNotifierCount(
					draft_count+
					rejected_count+
					for_approval_count+
					documents_to_approve_count+
					(orders_count.length>0 ? orders_count[0]+orders_count[1]+orders_count[3] : 0) +
					unread_messages+
					(application_in_progress ? 1 : 0)+
					cycle_count_recon_count
				);
			}
		}

	}, [
		draft_count, 
		rejected_count, 
		for_approval_count, 
		documents_to_approve_count, 
		auth.user,
		auth.approval_pipelines,
		auth.active_company,
		application_in_progress,
		unread_messages,
		orders_count,
		cycle_count_recon_count
	]);

	useEffect(() => {
		if (auth.active_company && auth.active_company.application_id===null && (auth.user.position===auth.enums.positions.MANAGER)) {
			dispatch(cycleCountReconCountAsync()).then((res) => res.payload.data ? setCycleCountReconCount(res.payload.data.laundry_cycle_count) : void(0));
		}
	}, [
		dispatch,
		auth.active_company,
		auth.user.position,
		auth.enums.positions.MANAGER,
		auth.notifier
	]);

	const onNotificationClicked = () => {
		if (!auth.is_sidebar_collapsed) {
			dispatch(toggleSidebarCollapsed());
		}
	}

	const handleMessageClickedEvent = (url) => {
		if (history) {
			history.push(url);
		}
	}

	const handleMenuItemClickedEvent = (url) => {
		if (history) {
			history.push(url);
		}
	}

	return (
		<Popover
			content={
				<Menu large={false} style={{maxWidth:"40vw"}}>

					<MenuItem icon={<Icon icon="envelope" className="orange"/>} onClick={() => handleMessageClickedEvent("/inbox")} className={`notifier-text ${unread_messages>0 ? 'red bold' : ''}`} text={`${translate.text.MY_MESSAGES} (${unread_messages})`}/>
					{
						auth.active_company && auth.active_company.aimeos_site_url &&
						<MenuItem icon={<Icon icon="link" className="orange"/>} onClick={() => window.open(auth.active_company.aimeos_site_url, '_blank').focus()} className="notifier-text" text={`Go to your Online Shop`}/>
					}
					{
						application_in_progress && <MenuDivider title={translate.text.SYSTEM_IMPLEMENTATION} />
					}
					{
						application_in_progress && <MenuItem className="notifier-text red bold" onClick={() => handleMenuItemClickedEvent(application_in_progress.route)} text={`${application_in_progress.name}`}/>
					}
					{
						auth.active_company && auth.active_company.application_id && !auth.user.roles.includes(auth.enums.roles.SYSTEM_ADMIN) &&
						<MenuDivider title={translate.text.SYSTEM_IMPLEMENTATION_IS_ONGOING} />
					}

					{
						auth.active_company && auth.active_company.application_id===null && auth.user.is_admin===0 &&
						<MenuDivider title={translate.text.MY_DOCUMENTS} />
					}
					{
						auth.active_company && auth.active_company.application_id===null && auth.user.is_admin===0 &&
						<MenuDivider />
					}
					{
						auth.active_company && auth.active_company.application_id===null && auth.user.is_admin===0 &&
						<MenuItem className={`notifier-text ${draft_count>0 ? 'red bold' : ''}`} onClick={() => handleMenuItemClickedEvent("/documents/draft")} text={`${translate.text.DRAFT} (${draft_count})`}/>
					}
					{
						auth.active_company && auth.active_company.application_id===null && auth.user.is_admin===0 &&
						<MenuItem className={`notifier-text ${rejected_count>0 ? 'red bold' : ''}`} onClick={() => handleMenuItemClickedEvent("/documents/rejected")} text={`${translate.text.REJECTED} (${rejected_count})`}/>
					}
					{
						auth.active_company && auth.active_company.application_id===null && auth.user.is_admin===0 &&
						<MenuItem className={`notifier-text ${for_approval_count>0 ? 'red bold' : ''}`} onClick={() => handleMenuItemClickedEvent("/documents/for-approval")} text={`${translate.text.SUBMITTED_FOR_APPROVAL} (${for_approval_count})`}/>
					}
					{
						auth.active_company && auth.active_company.application_id===null && auth.user.is_admin===0 &&
						<MenuItem className={`notifier-text ${documents_to_approve_count>0 ? 'red bold' : ''}`} onClick={() => handleMenuItemClickedEvent("/documents/for-my-approval")} text={`${translate.text.FOR_MY_APPROVAL} (${documents_to_approve_count})`}/>
					}
					{
						auth.active_company && auth.active_company.application_id===null && auth.user.is_admin===0 &&
						<MenuItem className="notifier-text" onClick={() => handleMenuItemClickedEvent("/documents/approved")} text={`${translate.text.APPROVED} (${approved_count})`}/>
					}
					{
						auth.active_company && auth.active_company.application_id===null && auth.user.is_admin===0 &&
						<MenuItem className="notifier-text" onClick={() => handleMenuItemClickedEvent("/documents/voided")} text={`${translate.text.VOIDED} (${voided_count})`}/>
					}

					{
						auth.active_company && auth.active_company.application_id===null && (Util.stringToBoolean(auth.user.is_order_and_appointment_app_user)||Util.stringToBoolean(auth.user.is_order_and_appointment_app_monitor_user)) && auth.user.is_admin===0 &&
						<MenuDivider title={translate.text.ORDERS_AND_APPOINTMENTS} />
					}
					{
						auth.active_company && auth.active_company.application_id===null && (Util.stringToBoolean(auth.user.is_order_and_appointment_app_user)||Util.stringToBoolean(auth.user.is_order_and_appointment_app_monitor_user)) && auth.user.is_admin===0 &&
						<MenuDivider />
					}
					{
						auth.active_company && auth.active_company.application_id===null && (Util.stringToBoolean(auth.user.is_order_and_appointment_app_user)||Util.stringToBoolean(auth.user.is_order_and_appointment_app_monitor_user)) && orders_count.length>0 && auth.user.is_admin===0 &&
						Object.values(auth.enums.oaa_order_status).map((status, key) => (
							<MenuItem key={key} className={`notifier-text ${((key===0||key===1||key===3)&&orders_count[key]>0) ? "red bold" : ""}`} onClick={() => handleMenuItemClickedEvent(`/orders/${status.toLowerCase().replaceAll(" ","-")}`)} text={`${status} (${orders_count[key]})`}/>
						))
					}
					{
						auth.active_company && auth.active_company.application_id===null && (auth.user.position===auth.enums.positions.MANAGER) && auth.user.is_admin===0 &&
						<MenuDivider title={translate.text.POINT_OF_SALE} />
					}
					{
						auth.active_company && auth.active_company.application_id===null && (auth.user.position===auth.enums.positions.MANAGER) && auth.user.is_admin===0 &&
						<MenuItem className={`notifier-text ${cycle_count_recon_count>0 ? "red bold" : ""}`} onClick={() => handleMenuItemClickedEvent("/cycle-count-reconciliation")} text={`Cycle Count Reconciliation (${cycle_count_recon_count})`}/>
					}
				</Menu>
			}
			minimal={false}
			placement="bottom"
		>
			<Button
				icon={<Icon style={{color:"darkorange"}} icon='notifications' className={notifier_total_count>0 ? "notifier" : ""}/>}
				text={notifier_total_count>0 ? <span className={`notifier-total-count ${notifier_total_count>0 ? 'red' : ''}`}>({notifier_total_count})</span> : ""}
				className='bp3-minimal navbar-notifications'
				onClick={() => onNotificationClicked()}
			/>
		</Popover>
	);

}
