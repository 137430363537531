import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import { Note } from "../../../common/Note";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	toggleSidebarCollapsed,
	fetchAllCustomersAsync,
	fetchAllUsersAsync,
	assignOrderingAndAppointmentAppUsersAsync,
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	Icon,
	Slider,
	HandleType,
	Switch
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import { SideBar } from '../../../SideBar';
import QRCode from 'qrcode.react';
import TextField from "@material-ui/core/TextField";
import { OrderingAndAppointmentAppSpreadsheet } from "../../../spreadsheets/OrderingAndAppointmentAppSpreadsheet";

export function OrderingAndAppointmentApp() {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [selected_rows, setSelectedRows] = useState([]);
	const [users, setUsers] = useState([]);
	const [assigned_users, setAssignedUsers] = useState([]);
	const [assigned_monitor_users, setAssignedMonitorUsers] = useState([]);
	const [customers, setCustomers] = useState([]);
	const [auto_approve_customers, setAutoApproveCustomers] = useState([]);
	const [auto_approve_message, setAutoApproveMessage] = useState(auth.active_company.ordering_and_appointment_app.auto_approve_message);
	const [auto_approve_settings_data] = useState(Object.values(auth.enums.oaa_auto_approve_settings).map((setting) => ({label:auth.enums.oaa_auto_approve_settings_value[setting], value:setting})));
	const [auto_approve_settings, setAutoApproveSettings] = useState(auto_approve_settings_data.find((setting) => (setting.value===auth.active_company.ordering_and_appointment_app.auto_approve_settings)));
	const [qr_code_size, setQrCodeSize] = useState(100);
	const [profit_cost_center_types] = useState(Object.values(auth.enums.profit_cost_center_types).filter((pccType) => ((pccType===auth.enums.profit_cost_center_types.BRANCH && auth.active_company.branch_count>0)||(pccType===auth.enums.profit_cost_center_types.DEPARTMENT && auth.active_company.department_count>0)||(pccType===auth.enums.profit_cost_center_types.PROJECT && auth.active_company.project_count>0))).map((pccType) => ({ value: pccType, label: pccType })));
	const [profit_cost_center_type, setProfitCostCenterType] = useState(auth.active_company.ordering_and_appointment_app.profit_cost_center_type.length>0 ? profit_cost_center_types.find((pc) => pc.value===auth.active_company.ordering_and_appointment_app.profit_cost_center_type[0]) : null);
	const [loyalty_reward_system, setLoyaltyRewardSystem] = useState(auth.active_company.ordering_and_appointment_app.loyalty_reward_system);
	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});
	
	const initFetch = useCallback(() => {
		const handleFetchCustomersResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				let customerData = response.payload.data.customers.map((cst) => ({value:cst.id, label:`${cst.first_name} ${cst.last_name}`}));
				setCustomers(customerData);
				setAutoApproveCustomers(customerData.filter((cstData) => response.payload.data.auto_approve_customers.includes(cstData.value)));
				setWorkSheet({
					is_readonly_mode: true,
					num_rows: 1000,
					num_cols: 10,
					num_frozen_columns: 0,
					import_limit: 700,
					focused_cell: null,
					selected_regions: null,
					header: translate.worksheet.default_header.ORDERING_AND_APPOINTMENT_APP,
					alignment: translate.worksheet.default_alignment.ORDERING_AND_APPOINTMENT_APP,
					comment: [],
					data_set: response.payload.data.customers.map((ds) => [
						ds.id,
						ds.email,
						ds.first_name,
						ds.last_name,
						ds.mobile_number,
						ds.created_at,
						ds.updated_at,
					])
				});
			}
		}
		dispatch(fetchAllCustomersAsync({})).then((response) => handleFetchCustomersResponse(response));
		dispatch(updateNavigation(translate.breadcrumb.ORDERING_AND_APPOINTMENT_APP));
		const handleResponse = (response) => {
			let users = response.payload.data.users.map((user) => ({label:`${user.name}`, is_order_and_appointment_app_user:user.is_order_and_appointment_app_user, is_order_and_appointment_app_monitor_user:user.is_order_and_appointment_app_monitor_user, value:user.id}));
			setUsers(users);
			setAssignedUsers(users.filter((usr) => (Util.stringToBoolean(usr.is_order_and_appointment_app_user))));
			setAssignedMonitorUsers(users.filter((usr) => (Util.stringToBoolean(usr.is_order_and_appointment_app_monitor_user))));
		}
		dispatch(fetchAllUsersAsync({account_status:auth.enums.account_status.VERIFIED})).then((response) => (response.payload.status===resources.status.SUCCESS ? handleResponse(response) : void(0)));
	}, [
		dispatch,
		translate.breadcrumb.ORDERING_AND_APPOINTMENT_APP,
		auth.enums.account_status.VERIFIED,
		translate.worksheet.default_alignment.ORDERING_AND_APPOINTMENT_APP,
		translate.worksheet.default_header.ORDERING_AND_APPOINTMENT_APP
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const refreshData = () => {
		const handleFetchCustomersResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				let customerData = response.payload.data.customers.map((cst) => ({value:cst.id, label:`${cst.first_name} ${cst.last_name}`}));
				setCustomers(customerData);
				setAutoApproveCustomers(customerData.filter((cstData) => response.payload.data.auto_approve_customers.includes(cstData.value)));
			}
		}
		dispatch(fetchAllCustomersAsync({})).then((response) => handleFetchCustomersResponse(response));
	}

	const onAutoApproveSettingsChanged = (autoApproveSettings) => {
		setAutoApproveCustomers([]);
		setAutoApproveSettings(autoApproveSettings);
	}

	const downloadQRCode = () => {
		const qrURL = document.getElementById('qr-image').toDataURL("image/png").replace("image/png", "image/octet-stream");
		let downloadButton = document.createElement("a");
		downloadButton.href = qrURL;
		downloadButton.download = "Ordering_And_Appointment_App_Android_QR_Code.png";
		document.body.appendChild(downloadButton);
		downloadButton.click();
		document.body.removeChild(downloadButton);
	}

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SAVE_LABEL,
							value: translate.text.NOTE_SAVE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{ Util.functionTitle(translate.text.ORDERING_AND_APPOINTMENT_APP) }
				<div className="function-sub-title">
					{translate.text.ORDERING_AND_APPOINTMENT_APP_SUBTITLE}
				</div>

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						<Button
							text={translate.button.SAVE}
							className="function-button"
							disabled={auth.status === resources.status.LOADING}
							icon={<Icon icon="floppy-disk" className="orange"/>}
							onClick={(e) =>
								dispatch(
									assignOrderingAndAppointmentAppUsersAsync({
											assigned_users: assigned_users.map((assignedUser) => assignedUser.value),
											assigned_monitor_users: assigned_monitor_users.map((assignedMonitorUser) => assignedMonitorUser.value),
											auto_approve_message: auto_approve_message,
											auto_approve_customers: auto_approve_customers.map((cst) => (cst.value)),
											auto_approve_settings: auto_approve_settings ? auto_approve_settings.value : "",
											profit_cost_center_type: profit_cost_center_type ? profit_cost_center_type.value : "",
											loyalty_reward_system: loyalty_reward_system ? loyalty_reward_system : "",
									})
								).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
							}
						/>
					</ButtonGroup>
				}

				{
					worksheet.is_readonly_mode &&
					<Card className="function-card">
						<div className="row">

							<div className="col-sm-12 col-md-3 col-lg-3 left">
								{
									auth.active_company.ordering_and_appointment_app.profit_cost_center_type.length>0 ? 
									<>
										<div className="center">
											<QRCode
												id="qr-image"
												size={qr_code_size}
												value={auth.active_company.ordering_and_appointment_app.url}
												includeMargin={true}
												level={auth.enums.qr_ecc_levels.LOW}
											/>
										</div>

										<div style={{margin:20}}>
											<div>Resize Image:</div>
											<Slider
												type={HandleType.FULL}
												value={qr_code_size}
												min={60}
												max={300}
												stepSize={10}
												labelStepSize={20}
												onChange={(num) => setQrCodeSize(num)}
											/>
										</div>

										<div style={{marginTop:"10px", color:"blue", fontWeight:"bold"}}>
											You can download and use the QR image to distribute the mobile application to your customers.
										</div>
										<Button
											text={translate.button.DOWNLOAD_QR_CODE}
											className="function-button download-btn"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="download" className="orange"/>}
											onClick={downloadQRCode}
										/>
									</> : 
									<div style={{marginTop:"10px", textAlign:"left", color:"blue", fontWeight:"bold"}}>
										You must complete the setup on the right side in order to unlock the QR image for your customers' mobile application (Android).
									</div>
								}
							</div>

							<div className="col-sm-12 col-md-6 col-lg-6 text-field">
								<div style={{marginTop:12, marginBottom:12}}>
									<MuiAutocomplete
										setSelectedValue={setProfitCostCenterType}
										selected_value={profit_cost_center_type}
										isMultiple={false}
										label={"Select the profit cost center type to display on the mobile app"}
										data={profit_cost_center_types}
									/>
								</div>
								<div style={{marginTop:12, marginBottom:12}}>
									<MuiAutocomplete
										setSelectedValues={setAssignedUsers}
										selected_values={assigned_users}
										isMultiple={true}
										label={"Assign user/s who will accept and verify the order/s of the customer/s."}
										data={users.filter((usr) => (!assigned_monitor_users.includes(usr)))}
									/>
								</div>
								<div style={{marginTop:12, marginBottom:12}}>
									<MuiAutocomplete
										setSelectedValues={setAssignedMonitorUsers}
										selected_values={assigned_monitor_users}
										isMultiple={true}
										label={"Assign user/s who will monitor the orders / appointments."}
										data={users.filter((usr) => (!assigned_users.includes(usr)))}
									/>
								</div>
								<div style={{marginTop:12, marginBottom:12}}>
									<MuiAutocomplete
										setSelectedValue={onAutoApproveSettingsChanged}
										selected_value={auto_approve_settings}
										isMultiple={false}
										label={"Email Notification Receipt Settings"}
										data={auto_approve_settings_data}
									/>
								</div>
								{
									auto_approve_settings && auto_approve_settings.value===auth.enums.oaa_auto_approve_settings.AUTO_APPROVE_SELECTED &&
									<div style={{marginTop:12, marginBottom:12}}>
										<MuiAutocomplete									
											setSelectedValues={setAutoApproveCustomers}
											selected_values={auto_approve_customers}
											isMultiple={true}
											label={"Selection List: Select customer/s who can receive email notification receipt."}
											data={customers}
										/>
									</div>
								}
								{
									auto_approve_settings && auto_approve_settings.value!==auth.enums.oaa_auto_approve_settings.AUTO_APPROVE_DISABLED &&
									<div style={{marginTop:12}}>
										<TextField
											multiline
											minRows={4}
											className="col-sm-12 col-md-12 col-lg-12"
											size="small"
											variant="outlined"
											label={`Email Notification Message: This message will be automatically sent to the email of ${auto_approve_settings.value===auth.enums.oaa_auto_approve_settings.AUTO_APPROVE_SELECTED ? "customer/s included in the Selection List" : "all the customers"} when order is submitted.`}
											value={auto_approve_message}
											inputProps={{
												maxLength: 1000,
											}}
											onChange={(e) =>
												setAutoApproveMessage(
													e.target.value
												)
											}
										/>
									</div>
								}

								<div className="mt-5">
									<Switch
										checked={loyalty_reward_system}
										disabled={auth.status === resources.status.LOADING}
										labelElement={`Switch ${loyalty_reward_system ? "OFF" : "ON"}`}
										innerLabelChecked={"Loyalty Reward is ON"}
										innerLabel={"Loyalty Reward is OFF"}
										onChange={e => setLoyaltyRewardSystem(!loyalty_reward_system)}
									/>
								</div>

							</div>
						</div>
					</Card>
				}

				<ButtonGroup className="button-group" minimal={false}>
					{
						worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => PDF.export(
										translate.worksheet.file_name.ORDERING_AND_APPOINTMENT_APP,
										worksheet.header,
										worksheet.data_set,
										auth.active_company,
										"a4",
										"landscape",
										[],
										[50, 120, 90, 90, 90, 90, 90]
									)
								}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => Excel.export(
										translate.worksheet.file_name.ORDERING_AND_APPOINTMENT_APP,
										worksheet.header,
										worksheet.data_set,
										auth.active_company
									)
								}
							/>
						</>
					}
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onFreezePaneButtonClicked}
						/>
					}
				</ButtonGroup>

				<OrderingAndAppointmentAppSpreadsheet
					enable_select={false}
					enable_edit={false}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
				/>

			</div>
		</div>
	);
}
