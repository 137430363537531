import React from "react";
import logo from "../../logo.png";
import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CreateAccountDialog } from "./CreateAccountDialog";
import { loginAsync, selectAuth } from "./authSlice";
import { resources } from "../../resources/index";
import {
	Button,
	FormGroup,
	Icon,
	IconSize,
	InputGroup,
	Checkbox
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

export function Auth() {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const history = useHistory();
	const translate = resources["lang"][auth.locale];
	const [email, setEmail] = useState(process.env.NODE_ENV === 'production' ? '' : "liveadmin@amoladulce.com");
	const [password, setPassword] = useState(process.env.NODE_ENV === 'production' ? '' : "p@ssword");
	const [is_create_account_dialog_open, setIsCreateAccountDialogOpen] = useState(false);
	const [is_password_visible, setIsPasswordVisible] = useState(false);

	const authSuccess = useCallback(() => {
		if (auth.token !== null) {
			history.push(auth.selected_application.route);
		}
	}, [auth.token, auth.selected_application, history]);

	useEffect(() => {
		authSuccess();
	}, [authSuccess]);

	const handleTogglePasswordVisibilityEvent = () => {
		setIsPasswordVisible(!is_password_visible);
	}

	return (
			<div className="auth-form">
				<img
					src={logo}
					className="auth-logo"
					alt={resources.app.NAME}
				/>
				<form>
					<FormGroup
						className="input-form-group"
						label={translate.label.EMAIL_ADDRESS}
						labelInfo={translate.label.REQUIRED}
					>
						<InputGroup
							leftIcon={
								<Icon
									icon="envelope"
									size={IconSize.SMALL}
									className="orange"
								/>
							}
							placeholder={translate.placeholder.ENTER_EMAIL_ADDRESS}
							large={true}
							round={true}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						></InputGroup>
					</FormGroup>
					<FormGroup
						className="input-form-group"
						label={translate.label.PASSWORD}
						labelInfo={translate.label.REQUIRED}
					>
						<InputGroup
							leftIcon={
								<Icon
									icon="key"
									size={IconSize.SMALL}
									className="orange"
								/>
							}
							placeholder={translate.placeholder.ENTER_PASSWORD}
							type={is_password_visible ? "text" : "password"}
							large={true}
							round={true}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							autoComplete="off"
						></InputGroup>
					</FormGroup>

					<div className="row">
						<div className="col-sm-12 col-md-12 col-lg-12 center mt-5">
							<Checkbox
								checked={ is_password_visible }
								large={false}
								inline={true}
								label={is_password_visible ? "Hide Password" : "Show Password"}
								onChange={e => handleTogglePasswordVisibilityEvent()}
							>
								<Icon className="ml-5 orange" size={20} icon={is_password_visible ? "eye-open" : "eye-off"}/>
							</Checkbox>
						</div>
					</div>

					<Button
						text={translate.button.FORGOT_PASSWORD}
						fill={true}
						large={false}
						minimal={true}
						onClick={() => history.push("/password-reset-link")}
					/>

					<Button
						disabled={auth.status === resources.status.LOADING}
						text={translate.button.LOGIN}
						fill={true}
						large={true}
						onClick={() =>
							dispatch(
								loginAsync({
									email: email,
									password: password,
								})
							)
						}
					/>
					<FormGroup>
						<p>or</p>
					</FormGroup>
					<div style={{marginBottom:5}}>
						<span className="auth-register-prompt">
							{translate.text.DONT_HAVE_ACCOUNT_YET}
						</span>
					</div>
					<Button
						disabled={auth.status === resources.status.LOADING}
						text={translate.button.CREATE_NEW_ACCOUNT}
						fill={true}
						large={true}
						onClick={() => setIsCreateAccountDialogOpen(true)}
					/>
				</form>
				<CreateAccountDialog
					is_create_account_dialog_open={is_create_account_dialog_open}
					setIsCreateAccountDialogOpen={setIsCreateAccountDialogOpen}
				/>
			</div>
	);
}
