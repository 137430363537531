import React from "react";
import {
	Callout
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

export function AccountDeletion() {

	return (
		<>
			<div style={{marginTop:70}}></div>
			<div className='row'>
				<div className='col-lg-12 col-md-12 col-sm-12'>
					<Callout intent='warning'>
						<h2>Ordering & Appointment App Customer Data Deletion</h2>
						<p style={{fontSize:18, marginTop:50}}>
							You may request for account deletion by following these steps:
							<ol>
								<li>Send an email to techsupport@finximus.com indicating your request to delete account. Please provide your registered email address and full name.</li>
								<li>All your account data including your email, saved addresses, orders and appointments will be automatically deleted within 7 business days from your date of request.</li>
								<li>A message will be sent to you upon successful deletion of your account data.</li>
							</ol>
						</p>
					</Callout>
				</div>
			</div>
		</>
	);
}
