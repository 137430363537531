import React, { useCallback, useEffect, useState } from 'react';
import PDF from '../../../common/PDF';
import Excel from '../../../common/Excel';
import Util from '../../../common/Util';
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from 'react-redux';
import {
	selectAuth,
	updateNavigation,
	toggleSidebarCollapsed,
	skipProcessAsync,
	createTaxAsync,
	updateTaxAsync,
	deleteTaxesAsync,
	fetchAllTaxesAsync,
	importTaxesAsync
} from '../../auth/authSlice';
import { resources } from '../../../resources/index';
import {
	Button,
	ButtonGroup,
	Card,
	Icon,
	Switch
} from '@blueprintjs/core';
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/table/lib/css/table.css';
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { TaxSpreadsheet } from '../../../spreadsheets/TaxSpreadsheet';
import TextField from "@material-ui/core/TextField";

export function Taxes() {

	const auth = useSelector(selectAuth);
	const history = useHistory();
	const translate = resources['lang'][auth.locale];
	const dispatch = useDispatch();
	const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);
	const [selected_rows, setSelectedRows] = useState([]);
	const [id, setId] = useState(null);
	const [code, setCode] = useState("");
	const [description, setDescription] = useState("");
	const [rate, setRate] = useState("");
	const [tax_types] = useState(Object.values(auth.enums.tax_types).map((taxType) => ({label:taxType, value:taxType})));
	const [tax_type, setTaxType] = useState(null);
	const [tax_computations] = useState(Object.values(auth.enums.tax_computations).map((tc) => ({label:tc, value:tc})));
	const [tax_computation, setTaxComputation] = useState(null);

	const DEFAULT_HEADER = translate.worksheet.default_header.TAXES;
	const DEFAULT_ALIGNMENT = translate.worksheet.default_alignment.TAXES;
	const IMPORT_HEADER = translate.worksheet.import_header.TAXES;
	const IMPORT_ALIGNMENT = translate.worksheet.import_alignment.TAXES;
	const COMMENT = translate.worksheet.import_comment.TAXES;
	const NUM_ROWS = 1000;
	const NUM_COLS = DEFAULT_HEADER.length;
	const IMPORT_LIMIT = 700;
	let DEFAULT_DATA_SET = new Array(NUM_ROWS).fill("").map(() => new Array(NUM_COLS).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.TAXES));
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllTaxesAsync({})).then((response) => response.payload.data ? 
				setWorkSheet({
					is_readonly_mode: true,
					num_rows: NUM_ROWS>response.payload.data.taxes.length ? NUM_ROWS : response.payload.data.taxes.length,
					num_cols: DEFAULT_HEADER.length,
					num_frozen_columns: 0,
					import_limit: IMPORT_LIMIT,
					focused_cell: null,
					selected_regions: null,
					header: DEFAULT_HEADER,
					alignment: DEFAULT_ALIGNMENT,
					comment: [],
					data_set: response.payload.data.taxes.map((ds) => [
						ds.id,
						ds.type,
						ds.code,
						ds.name,
						ds.rate,
						ds.computation,
						ds.created_at,
						ds.updated_at,
					])
				}) : void(0)
			);
		}
	}, [
		dispatch,
		translate.breadcrumb.TAXES,
		DEFAULT_ALIGNMENT,
		DEFAULT_HEADER,
		worksheet.is_readonly_mode
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const onClearButtonClicked = () => {
		setId(null);
		setTaxType(null);
		setCode("");
		setDescription("");
		setRate("");
		setTaxComputation(null);
	};

	const onCreateButtonClicked = () => {
		const handleCreateResponse = (response) => {
			let tax = response.payload.data.tax;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: [
					[
						tax.id,
						tax.type,
						tax.code,
						tax.name,
						tax.rate,
						tax.computation,
						tax.created_at,
						tax.updated_at,
					],
					...worksheet.data_set
				]
			});
			onClearButtonClicked();
		}
		dispatch(
			createTaxAsync({
				tax_type:tax_type ? tax_type.value : "",
				code:code,
				description:description,
				rate:rate,
				tax_computation:tax_computation ? tax_computation.value : "",
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleCreateResponse(response) : null))
	}

	const onUpdateButtonClicked = () => {
		const handleUpdateResponse = (response) => {
			let tax = response.payload.data.tax;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set.map((ds) => (ds[0]===tax.id ? [
					tax.id,
					tax.type,
					tax.code,
					tax.name,
					tax.rate,
					tax.computation,
					tax.created_at,
					tax.updated_at,
				] : ds))
			});
		}
		dispatch(
			updateTaxAsync({
				id: id,
				code:code,
				description:description,
				rate:rate,
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleUpdateResponse(response) : null))
	}

	const onEditButtonClicked = (row) => {
		let taxType = worksheet.data_set[row][1] ? tax_types.find((taxType) => taxType.value===worksheet.data_set[row][1]) : null;
		let taxComputation = worksheet.data_set[row][5] ? tax_computations.find((taxComputation) => taxComputation.value===worksheet.data_set[row][5]) : null;
		setId(worksheet.data_set[row][0]);
		setTaxType(taxType);
		setCode(worksheet.data_set[row][2]);
		setDescription(worksheet.data_set[row][3]);
		setRate(worksheet.data_set[row][4]);
		setTaxComputation(taxComputation);
		window.scrollTo(0,0);
	};

	const onDeleteRowsButtonClicked = () => {
		let ids = selected_rows.map(
			(row) => worksheet.data_set[row][0]
		);
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: worksheet.data_set.filter((ds) => (!ids.includes(ds[0])))
				});
				setSelectedRows([]);
			}
		}
		dispatch(deleteTaxesAsync(ids)).then((response) => handleResponse(response));
	};

	const handleSkipButtonClickedEvent = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:"taxes"})).then((response) => response.payload.data && response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const onImportButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			let dataSet = worksheet.data_set.filter((dataSet) => dataSet[0].length>0||dataSet[1].length>0||dataSet[2].length>0||dataSet[3].length>0||dataSet[4].length>0);
			if (dataSet.length<=worksheet.import_limit) {
				const handleResponse = (i, length, status) => {
					if (status===resources.status.SUCCESS) {
						if (i===0) {
							onClearCellsButtonClicked();
							toast.success(translate.message.IMPORT_TAXES_QUEUED, Util.getToasterStyle(resources.status.SUCCESS));
						}
						if (i===length-1) {
							toast.success(translate.message.IMPORT_TAXES_FINISHED, Util.getToasterStyle(resources.status.SUCCESS));
						}
					}
				} 
				let size = 10;
				let chunks = Util.chunk(dataSet, size);
				let length = chunks.length;
				for (let i=0; i<chunks.length; i++) {
					dispatch(
						importTaxesAsync(chunks[i])
					).then((response) => (handleResponse(i, length, response.payload.status)));
				}
			}
			else {
				toast.error(
					translate.message.IMPORT_LIMIT_REACHED,
					Util.getToasterStyle(resources.status.ERROR)
				);
			}
		}
	};

	const onClearCellsButtonClicked = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearSelectedCellsButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			if (worksheet.selected_regions!=null) {
				let dataSet = [];
				let selectedRegions = worksheet.selected_regions;
				for (let i=0; i<worksheet.data_set.length; i++) {
					let row = [];
					for (let j=0; j<worksheet.data_set[i].length; j++) {
						row.push(worksheet.data_set[i][j]);
					}
					dataSet.push(row);
				}
				for (let i=0; i<selectedRegions.length; i++) {
					let selectedRows = selectedRegions[i].rows;
					let selectedCols = selectedRegions[i].cols;
					let startRow = selectedRows[0];
					let endRow = selectedRows[1];
					let startCol = selectedCols[0];
					let endCol = selectedCols[1];
					for (let j=startRow; j<=endRow; j++) {
						if (dataSet[j]!==undefined) {
							for (let k=startCol; k<=endCol; k++) {
								if (dataSet[j][k]!==undefined) {
									dataSet[j][k] = "";
								}
							}
						}
					}
				}
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: dataSet
				});
			}
		}
	}

	const onSaveAsPDFButtonClicked = () => {
		PDF.export(
			translate.worksheet.file_name.TAXES,
			worksheet.header,
			worksheet.data_set,
			auth.active_company,
			"a3",
			"landscape",
			[],
			[120, 80, 100, "*", "*", "*", "*", "*"]
		)
	}

	const onSaveAsXLSXButtonClicked = () => {
		Excel.export(
			translate.worksheet.file_name.TAXES,
			worksheet.header,
			worksheet.data_set,
			auth.active_company
		);
	}

	const onSpreadSheetModeSwitchChanged = () => {
		let newMode = !worksheet.is_readonly_mode;
		setWorkSheet({
			is_readonly_mode: newMode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						}
					]}
				/>

				{
					auth.active_company.application_id &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleSkipButtonClickedEvent}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.TAXES) }

				{
					worksheet.is_readonly_mode &&
					<>
						<ButtonGroup className="button-group" minimal={false}>
							{
								id===null ? 
								<Button
									text={translate.button.CREATE}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={onCreateButtonClicked}
								/> :
								<Button
									text={translate.button.UPDATE}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={onUpdateButtonClicked}
								/>
							}
							<Button
								text={translate.button.CLEAR}
								className="function-button"
								icon={<Icon icon="reset" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onClearButtonClicked}
							/>
						</ButtonGroup>

						<Card className="function-card">
							<div className="row">
								<div className="col-sm-12 col-md-3 col-lg-2 text-field">
									<MuiAutocomplete
										setSelectedValue={setTaxType}
										selected_value={tax_type}
										isMultiple={false}
										label={"Select Tax Type"}
										data={tax_types}
										isDisabled={id!==null}
									/>
								</div>

								<TextField
									className="col-sm-12 col-md-3 col-lg-2 text-field"
									size="small"
									variant="outlined"
									value={code}
									onChange={(e) => setCode(e.target.value)}
									label={translate.placeholder.ENTER_TAX_CODE}
								/>
								<TextField
									className="col-sm-12 col-md-3 col-lg-2 text-field"
									size="small"
									variant="outlined"
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									label={translate.placeholder.ENTER_TAX_DESCRIPTION}
								/>
								<TextField
									className="col-sm-12 col-md-2 col-lg-2 text-field"
									size="small"
									variant="outlined"
									value={rate}
									onChange={(e) => setRate(e.target.value)}
									label={translate.placeholder.ENTER_TAX_RATE}
									type="number"
									InputProps={{
										inputProps: {
											min: 0
										}
									}}
								/>
								<div className="col-sm-12 col-md-3 col-lg-2 text-field">
									<MuiAutocomplete
										setSelectedValue={setTaxComputation}
										selected_value={tax_computation}
										isMultiple={false}
										label={"Select Tax Computation"}
										data={tax_computations}
										isDisabled={id!==null}
									/>
								</div>
							</div>
						</Card>
					</>
				}

				<ButtonGroup className="button-group" minimal={false}>
					<Switch
						className="switch-mode"
						checked={!worksheet.is_readonly_mode}
						disabled={auth.status === resources.status.LOADING}
						labelElement={<span className="switch-mode-label">{translate.label.SWITCH_MODE}</span>}
						innerLabelChecked={translate.label.IMPORT}
						innerLabel={translate.label.READ_ONLY}
						onChange={onSpreadSheetModeSwitchChanged}
					/>
					{
						worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsPDFButtonClicked}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsXLSXButtonClicked}
							/>
							<Button
								text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon={<Icon icon="select" className="orange"/>}
								onClick={e => setSelectedRows(selected_rows.length>0 ? [] : [...Array(worksheet.data_set.length).keys()])}
							/>
							{
								selected_rows.length>0 &&
								<Button
									text={`${translate.button.DELETE_ROWS} (${selected_rows.length})`}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="delete" className="orange"/>}
									onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
								/>
							}
						</>
					}
					{
						!worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.IMPORT}
								className="function-button"
								icon={<Icon icon="upload" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onImportButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_SELECTED_CELLS}
								className="function-button"
								icon={<Icon icon="clean" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearSelectedCellsButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_CELLS}
								className="function-button"
								icon={<Icon icon="graph-remove" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearCellsButtonClicked}
							/>
						</>
					}
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onFreezePaneButtonClicked}
						/>
					}
				</ButtonGroup>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={onDeleteRowsButtonClicked}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>System will automatically conduct further verification if deletion of certain taxes is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<TaxSpreadsheet
					enable_select={true}
					enable_edit={true}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
					onEditButtonClicked={onEditButtonClicked}
				/>

			</div>
		</div>
	);

}
