import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from "../../auth/authSlice";
import { Column, Table2, Cell, ColumnHeaderCell } from "@blueprintjs/table";
import { resources } from "../../../resources/index";
import {
	Text,
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";

export function StockCountSheet({ending_inventories}) {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const [num_frozen_columns] = useState(0);

	const cell = (row, col) => {
		let value = ending_inventories[row]&&ending_inventories[row][col] ? ending_inventories[row][col] : "";
		return ( 
			<Cell interactive={false} style={{textAlign:col>5 ? "right" : "left"}}>
				{value}
			</Cell>
		)
	}

	const nameRenderer = (name, index) => {
		return (
			<Text ellipsize={true}>
				{translate.worksheet.import_header.STOCK_COUNT_SHEET[index].toUpperCase()}
		    </Text>
		)
	}

	const columnHeaderCellRenderer = (index) => {
		return (
			<ColumnHeaderCell 
				nameRenderer={nameRenderer}
			/>
		)
	}

	const renderColumns = () => {
		let headerColumns = translate.worksheet.import_header.STOCK_COUNT_SHEET.map(
			(header, headerKey) => (
            	<Column 
            		key={headerKey} 
            		name={header.toUpperCase()}
            		columnHeaderCellRenderer={columnHeaderCellRenderer}
            		cellRenderer={cell} 
            	/>
			)
		)
		return headerColumns;
	};

	return (
		
			<Table2
				className="stock-count-sheet-table" 
				defaultColumnWidth={130}
				numRows={ending_inventories.length>100 ? ending_inventories.length : 100}
				enableRowResizing={false}
				numFrozenColumns={num_frozen_columns}
				enableGhostCells={false}
			>
				{renderColumns()}
			</Table2>
		
	);
}