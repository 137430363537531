import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	fetchEndOfDayAsync,
	toggleSidebarCollapsed,
	fetchAllProfitCostCentersAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	Icon,
	Checkbox
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import moment from "moment";
import { SideBar } from '../../../SideBar';
import TextField from "@material-ui/core/TextField";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import toast from "react-hot-toast";

export function ZReading() {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [from_date, setFromDate] = useState(null);
	const [to_date, setToDate] = useState(null);
	const [branch, setBranch] = useState(null);
	const [branches, setBranches] = useState([]);
	const [year_to_date, setYearToDate] = useState(false);

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.ZREADING));
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setBranches(response.payload.data.branches.map((br) => ({
					label:br.name,
					value:br.id,
				})));
			}
		}
		dispatch(fetchAllProfitCostCentersAsync({type:auth.enums.profit_cost_center_types.BRANCH})).then((res) => handleResponse(res));
	}, [
		dispatch,
		translate.breadcrumb.ZREADING,
		auth.enums.profit_cost_center_types.BRANCH
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const handleDownloadAsPDF = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				if (response.payload.data.end_of_day.length>0) {
					let header = [" ", " ", " ", " ", " ", " "];
					let data_set = [];
					for (let i=0; i<response.payload.data.end_of_day.length; i++) {
						let eod = response.payload.data.end_of_day[i];
						data_set = data_set.concat([
							[" ", " ", " ", " ", " ", " "],
							[" ", " ", " ", " ", " ", " "],
							["Close-Time", eod.closing_time, " ", " ", "Z-Count", "1"],
							["POS Terminal Name", eod.terminal_name, " ", " ", "Store", eod.branch_name],
							[" ", " ", " ", " ", " ", " "],
							[" ", " ", " ", " ", " ", "Amount"],
							["Gross Sales", " ", " ", " ", " ", eod.gross_sales],
							["Discounts", " ", " ", " ", " ", eod.discount],
							["VAT from SC/PWD/Diplomat Discounts", " ", " ", " ", " ", eod.vat_discount],
							[" ", " ", " ", " ", "Net Sales", eod.net_sales],
							[" ", " ", " ", " ", " ", " "],
							/*["Gross Sales (YTD)", " ", " ", " ", " ", eodytd.gross_sales],
							["Discounts (YTD)", " ", " ", " ", " ", eodytd.discount],
							["VAT from SC/PWD/Diplomat Discounts (YTD)", " ", " ", " ", " ", eodytd.vat_discount],
							[" ", " ", " ", " ", "Net Sales (YTD)", eodytd.net_sales],
							[" ", " ", " ", " ", " ", " "],*/
							["Collections", " ", "Transactions", " ", " ", "Amount"],
							["Cash", " ", eod.cash_transactions, "", " ", eod.cash_amount],
							["Credit Card", " ", eod.credit_card_transactions, "", " ", eod.credit_card_amount],
							["Loyalty Rewards", " ", eod.loyalty_transactions, "", " ", eod.loyalty_amount],
							["Paymaya", " ", eod.paymaya_transactions, "", " ", eod.paymaya_amount],
							["Gcash", " ", eod.gcash_transactions, "", " ", eod.gcash_amount],
							[" ", " ", " ", " ", "Total Collection", eod.total_collection],
							[" ", " ", " ", " ", " ", " "],
							["Loyalty Credits Purchase", " ", " ", " ", " ", " "],
							["Collections", " ", "Transactions", " ", " ", "Amount"],
							["Cash", " ", eod.cash_transactions_lc, "", " ", eod.cash_amount_lc],
							["Credit Card", " ", eod.credit_card_transactions_lc, "", " ", eod.credit_card_amount_lc],
							["Loyalty Rewards", " ", eod.loyalty_transactions_lc, "", " ", eod.loyalty_amount_lc],
							["Paymaya", " ", eod.paymaya_transactions_lc, "", " ", eod.paymaya_amount_lc],
							["Gcash", " ", eod.gcash_transactions_lc, "", " ", eod.gcash_amount_lc],
							[" ", " ", " ", " ", "Total Collection", eod.total_collection_lc],
							[" ", " ", " ", " ", " ", " "],
							/*["Cash (YTD)", " ", "0", "", " ", "0"],
							["Credit Card (YTD)", " ", "0", "", " ", "0"],
							["Loyalty Rewards (YTD)", " ", "0", "", " ", "0"],
							["Paymaya (YTD)", " ", "0", "", " ", "0"],
							["Gcash (YTD)", " ", "0", "", " ", "0"],
							[" ", " ", " ", " ", "Total Collection (YTD)", "0"],
							[" ", " ", " ", " ", " ", " "],*/
							["Other Info", " ", "", " ", " ", "Amount"],
							["Vatable Sales", " ", "", "", " ", eod.vatable_sales],
							["VAT Amount", " ", "", "", " ", eod.vat_amount],
							["VAT Exempt Sales", " ", "", "", " ", eod.vat_exempt_sales],
							[" ", " ", " ", " ", "Total", eod.total_sales],
							[" ", " ", " ", " ", " ", " "],
							/*["Vatable Sales (YTD)", " ", "", "", " ", "0"],
							["VAT Amount (YTD)", " ", "", "", " ", "0"],
							["VAT Exempt Sales (YTD)", " ", "", "", " ", "0"],
							[" ", " ", " ", " ", "Total (YTD)", "0"],
							[" ", " ", " ", " ", " ", " "],*/
							["Discounts", " ", " ", " ", " ", "Amount"],
							["Senior Citizen", " ", " ", " ", " ", eod.senior_citizen_amount],
							["PWD", " ", " ", " ", " ", eod.pwd_amount],
							["Diplomat", " ", " ", " ", " ", eod.diplomat_amount],
							["Promo Discounts", " ", " ", " ", " ", eod.promo_discount_amount],
							[" ", " ", " ", " ", "Total", eod.total_discount],
							[" ", " ", " ", " ", " ", " "],
							/*["Senior Citizen (YTD)", " ", " ", " ", " ", "0"],
							["PWD (YTD)", " ", " ", " ", " ", "0"],
							["Diplomat (YTD)", " ", " ", " ", " ", "0"],
							["Promo Discounts (YTD)", " ", " ", " ", " ", "0"],
							[" ", " ", " ", " ", " ", " "],*/
							["Summary", " ", " ", " ", " ", " "],
							["Beginning (O.R.)", eod.invoice_start ? eod.invoice_start : "", " ", " ", " ", ""],
							["End (O.R.)", eod.invoice_end ? eod.invoice_end : "", " ", " ", " ", ""],
						]);
					}
					PDF.exportWithoutHeader(
						translate.worksheet.file_name.ZREADING+(year_to_date ? " Year-to-Date" : ""),
						header,
						data_set,
						auth.active_company,
						"letter",
						"portrait",
						[2, 5],
						[100, 80, "*", 100, "*", 80]
					);
				}
				else {
					toast.error(
						translate.message.NO_RECORD_FOUND,
						Util.getToasterStyle(resources.status.ERROR)
					);
				}
			}
		}
		dispatch(fetchEndOfDayAsync({
			from_date:from_date ? moment(from_date).format(resources.date_format.SYSTEM) : "",
			to_date:to_date ? moment(to_date).format(resources.date_format.SYSTEM) : "",
			branch:branch ? branch.value : "",
			year_to_date:year_to_date,
		})).then((res) => handleResponse(res));
	}

	const handleDownloadAsXLSX = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				if (response.payload.data.end_of_day.length>0) {
					let header = [" ", " ", " ", " ", " ", " "];
					let data_set = [];
					for (let i=0; i<response.payload.data.end_of_day.length; i++) {
						let eod = response.payload.data.end_of_day[i];
						data_set = data_set.concat([
							[" ", " ", " ", " ", " ", " "],
							[" ", " ", " ", " ", " ", " "],
							["Close-Time", eod.closing_time, " ", " ", "Z-Count", "1"],
							["POS Terminal Name", eod.terminal_name, " ", " ", "Store", eod.branch_name],
							[" ", " ", " ", " ", " ", " "],
							[" ", " ", " ", " ", " ", "Amount"],
							["Gross Sales", " ", " ", " ", " ", eod.gross_sales],
							["Discounts", " ", " ", " ", " ", eod.discount],
							["VAT from SC/PWD/Diplomat Discounts", " ", " ", " ", " ", eod.vat_discount],
							[" ", " ", " ", " ", "Net Sales", eod.net_sales],
							[" ", " ", " ", " ", " ", " "],
							/*["Gross Sales (YTD)", " ", " ", " ", " ", eodytd.gross_sales],
							["Discounts (YTD)", " ", " ", " ", " ", eodytd.discount],
							["VAT from SC/PWD/Diplomat Discounts (YTD)", " ", " ", " ", " ", eodytd.vat_discount],
							[" ", " ", " ", " ", "Net Sales (YTD)", eodytd.net_sales],
							[" ", " ", " ", " ", " ", " "],*/
							["Collections", " ", "Transactions", " ", " ", "Amount"],
							["Cash", " ", eod.cash_transactions, "", " ", eod.cash_amount],
							["Credit Card", " ", eod.credit_card_transactions, "", " ", eod.credit_card_amount],
							["Loyalty Rewards", " ", eod.loyalty_transactions, "", " ", eod.loyalty_amount],
							["Paymaya", " ", eod.paymaya_transactions, "", " ", eod.paymaya_amount],
							["Gcash", " ", eod.gcash_transactions, "", " ", eod.gcash_amount],
							[" ", " ", " ", " ", "Total Collection", eod.total_collection],
							[" ", " ", " ", " ", " ", " "],
							/*["Cash (YTD)", " ", "0", "", " ", "0"],
							["Credit Card (YTD)", " ", "0", "", " ", "0"],
							["Loyalty Rewards (YTD)", " ", "0", "", " ", "0"],
							["Paymaya (YTD)", " ", "0", "", " ", "0"],
							["Gcash (YTD)", " ", "0", "", " ", "0"],
							[" ", " ", " ", " ", "Total Collection (YTD)", "0"],
							[" ", " ", " ", " ", " ", " "],*/
							["Other Info", " ", "", " ", " ", "Amount"],
							["Vatable Sales", " ", "", "", " ", eod.vatable_sales],
							["VAT Amount", " ", "", "", " ", eod.vat_amount],
							["VAT Exempt Sales", " ", "", "", " ", eod.vat_exempt_sales],
							[" ", " ", " ", " ", "Total", eod.total_sales],
							[" ", " ", " ", " ", " ", " "],
							/*["Vatable Sales (YTD)", " ", "", "", " ", "0"],
							["VAT Amount (YTD)", " ", "", "", " ", "0"],
							["VAT Exempt Sales (YTD)", " ", "", "", " ", "0"],
							[" ", " ", " ", " ", "Total (YTD)", "0"],
							[" ", " ", " ", " ", " ", " "],*/
							["Discounts", " ", " ", " ", " ", "Amount"],
							["Senior Citizen", " ", " ", " ", " ", eod.senior_citizen_amount],
							["PWD", " ", " ", " ", " ", eod.pwd_amount],
							["Diplomat", " ", " ", " ", " ", eod.diplomat_amount],
							["Promo Discounts", " ", " ", " ", " ", eod.promo_discount_amount],
							[" ", " ", " ", " ", "Total", eod.total_discount],
							[" ", " ", " ", " ", " ", " "],
							/*["Senior Citizen (YTD)", " ", " ", " ", " ", "0"],
							["PWD (YTD)", " ", " ", " ", " ", "0"],
							["Diplomat (YTD)", " ", " ", " ", " ", "0"],
							["Promo Discounts (YTD)", " ", " ", " ", " ", "0"],
							[" ", " ", " ", " ", " ", " "],*/
							["Summary", " ", " ", " ", " ", " "],
							["Beginning (O.R.)", eod.invoice_start ? eod.invoice_start : "", " ", " ", " ", ""],
							["End (O.R.)", eod.invoice_end ? eod.invoice_end : "", " ", " ", " ", ""],
						]);
					}
					Excel.export(
						translate.worksheet.file_name.ZREADING+(year_to_date ? " Year-to-Date" : ""),
						header,
						data_set,
						auth.active_company
					)
				}
				else {
					toast.error(
						translate.message.NO_RECORD_FOUND,
						Util.getToasterStyle(resources.status.ERROR)
					);
				}
			}
		}
		dispatch(fetchEndOfDayAsync({
			from_date:from_date ? moment(from_date).format(resources.date_format.SYSTEM) : "",
			to_date:to_date ? moment(to_date).format(resources.date_format.SYSTEM) : "",
			branch:branch ? branch.value : "",
			year_to_date:year_to_date,
		})).then((res) => handleResponse(res));
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container center" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>
				{ Util.functionTitle(translate.text.ZREADING) }
				<div className="function-sub-title">
				</div>

				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={"Download as PDF"}
						className="function-button"
						icon={<Icon icon="document-share" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={
							e => handleDownloadAsPDF()
						}
					/>
					<Button
						text={"Download as XLSX"}
						className="function-button"
						icon={<Icon icon="th" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={
							e => handleDownloadAsXLSX()
						}
					/>
				</ButtonGroup>

				<Card className="function-card">
					<div className="row mb-5">
						<Checkbox
							checked={ year_to_date }
							large={true}
							inline={true}
							label={"Year-to-Date"}
							onChange={() => setYearToDate(!year_to_date)}
						/>
					</div>
					<div className="row mb-5">
				   		<div className="col-sm-12 col-md-3 col-lg-2 mt-5">
							<MuiAutocomplete
								setSelectedValue={setBranch}
								selected_value={branch}
								isMultiple={false}
								label={"Filter by Branch"}
								data={branches}
							/>
						</div>
						{
							!year_to_date &&
							<TextField
								className="col-sm-12 col-md-2 col-lg-1 mt-5"
								id="from_date"
								label={translate.placeholder.FROM_DATE}
								type="date"
								variant="outlined"
								size="small"
								defaultValue={from_date}
								onChange={(e) => setFromDate(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						}
						<TextField
							className="col-sm-12 col-md-2 col-lg-1 mt-5"
							id="to_date"
							label={translate.placeholder.TO_DATE}
							type="date"
							variant="outlined"
							size="small"
							defaultValue={to_date}
							onChange={(e) => setToDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>
				</Card>

			</div>
		</div>
	);
}
