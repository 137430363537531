import Util from "../../common/Util";
import toast from "react-hot-toast";
import { resources } from "./../../resources/index";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	detachAttachment,
	postComment,
	createImportedItemsReceipt,
	updateImportedItemsReceipt,
	submitImportedItemsReceipt,
	approveImportedItemsReceipt,
	rejectImportedItemsReceipt,
	importImportedItemsReceipts,
	login,
	verify,
	pendingVerification,
	acceptInvitation,
	logout,
	switchCompany,
	signup,
	createSubscription,
	createUser,
	updateUser,
	deleteUser,
	deleteUsers,
	importUsers,
	createBranch,
	updateBranch,
	createDepartment,
	updateDepartment,
	createProject,
	updateProject,
	deleteBranch,
	deleteDepartment,
	deleteProject,
	importProfitCostCenters,
	updatePermission,
	assignAuthorizers,
	unAssignAuthorizers,
	deleteAmountRange,
	createMakers,
	deleteMaker,
	createUnitOfMeasure,
	createUnitOfMeasureConversion,
	createItem,
	updateItem,
	createItemClass,
	deleteItem,
	deleteItems,
	deleteItemImage,
	importItems,
	updateItemImage,
	updateServiceImage,
	createFinishedGoodComponents,
	importFinishedGoodComponents,
	createPrintJob,
	deletePrintJob,
	importPrintJobs,
	updateItemSerialNumberCount,
	createLocationType,
	createLocation,
	deleteLocation,
	deleteLocations,
	importLocations,
	createStockTransfer,
	updateStockTransfer,
	submitStockTransfer,
	rejectStockTransfer,
	approveStockTransfer,
	voidStockTransfer,
	importStockTransfers,
	updateLocation,
	updateUnitOfMeasureConversion,
	importUnitOfMeasureConversions,
	deleteUnitOfMeasureConversion,
	deleteItemClass,
	createStockRequest,
	updateStockRequest,
	submitStockRequest,
	rejectStockRequest,
	approveStockRequest,
	voidStockRequest,
	importStockRequests,
	createStockIssuance,
	updateStockIssuance,
	submitStockIssuance,
	rejectStockIssuance,
	approveStockIssuance,
	voidStockIssuance,
	firstInFirstOut,
	lastInFirstOut,
	firstOutFirstIn,
	createStockAcceptance,
	updateStockAcceptance,
	submitStockAcceptance,
	rejectStockAcceptance,
	approveStockAcceptance,
	voidStockAcceptance,
	voidImportedItemsReceipt,
	importStockAcceptances,
	createBeginningBalance,
	updateBeginningBalance,
	submitBeginningBalance,
	rejectBeginningBalance,
	approveBeginningBalance,
	voidBeginningBalance,
	importBeginningBalances,
	fetchAverageCostMethod,
	updateGeneralSettings,
	fetchAllStockRequests,
	fetchAllStockTransfers,
	fetchAllStockAcceptances,
	fetchAllBeginningBalances,
	fetchAllUsers,
	fetchUser,
	fetchAllProfitCostCenters,
	fetchAllUnitOfMeasures,
	fetchAllUnitOfMeasureConversions,
	fetchAllLocations,
	fetchLocationTree,
	fetchAllLocationTypes,
	fetchItem,
	fetchAllItems,
	fetchAllFinishedGoodComponents,
	fetchAllStockAdjustmentLineItems,
	fetchAllStockRequestLineItems,
	fetchAllStockCountLineItems,
	fetchAllStockTransferLineItems,
	fetchAllStockIssuanceLineItems,
	fetchAllStockAcceptanceLineItems,
	fetchAllImportedItemsReceiptLineItems,
	fetchSerialNumbers,
	fetchExpiryDates,
	createItemLocationTransfer,
	updateItemLocationTransfer,
	submitItemLocationTransfer,
	rejectItemLocationTransfer,
	approveItemLocationTransfer,
	voidItemLocationTransfer,
	importItemLocationTransfers,
	fetchAllItemLocationTransferLineItems,
	importStockIssuances,
	fetchAllStockIssuances,
	updateItemClass,
	importItemClasses,
	fetchAllItemClasses,
	createFinishedGoodProduced,
	updateFinishedGoodProduced,
	submitFinishedGoodProduced,
	fetchAllFinishedGoodProducedLineItems,
	createStockAdjustment,
	updateStockAdjustment,
	submitStockAdjustment,
	rejectStockAdjustment,
	approveStockAdjustment,
	voidStockAdjustment,
	importStockAdjustments,
	fetchAllStockAdjustments,
	fetchEndingInventories,
	fetchStockCountSheet,
	importStockCounts,
	fetchAllStockCounts,
	updateStockCount,
	approveStockCount,
	rejectStockCount,
	voidStockCount,
	submitStockCount,
	byPassStockCountSchedule,
	updateInventoryTrackings,
	fetchItemHistories,
	fetchStockMovements,
	fetchStockOnHand,
	fetchItemLedger,
	fetchInventoryItemByStatus,
	fetchAllCountries,
	resetPassword,
	forgotPassword,
	changePassword,
	fetchOnHandInventory,
	fetchFastMovingItems,
	fetchNearStockoutSkus,
	fetchZeroStockInventories,
	fetchInventoryValueByItemClass,
	fetchFinishedGoodsSold,
	fetchOnHandFinishedGoods,
	fetchAllDocuments,
	uploadCompanyLogo,
	submitDocuments,
	skipProcess,
	updateMessageStatus,
	updateMessagesStatus,
	fetchDeliveryReceipt,
	fetchIssuedItemsForAcceptance,
	fetchAllItemLocationTransfers,
	fetchAccountingPeriods,
	fetchStockCountSchedules,
	byPassYearEndClosing,
	fetchDocumentsCount,
	fetchAllImportedItemsReceipts,
	fetchAllFinishedGoodProduces,
	fetchAllSubscriptions,
	fetchAllCompanies,
	fetchAllMessages,
	fetchMessagesCount,
	fetchAllAmountRanges,
	fetchItemConditionsPercentage,
	fetchItemConditions,
	voidFinishedGoodProduced,
	approveFinishedGoodProduced,
	rejectFinishedGoodProduced,
	transferDocuments,
	validateVerificationCode,
	duplicateProfitCostCenters,
	duplicateItemClasses,
	duplicateItems,
	assignOrderingAndAppointmentAppUsers,
	payOrderingAndAppointment,
	fetchAllCustomers,
	fetchAllOrders,
	fetchOrder,
	updateOrder,
	sendMessage,
	deleteMessages,
	fetchCustomerProfitCostCenters,
	fetchUserCompanies,
	saveCashierShift,
	fetchCashierShifts,
	fetchLaundryMachineSets,
	fetchPosTerminals,
	savePosTerminalSettings,
	pullInventoryItems,
	removeInventoryItems,
	fetchPosServices,
	createService,
	updateService,
	fetchAllTaxes,
	createTax,
	updateTax,
	fetchAllDiscounts,
	createDiscount,
	fetchSales,
	fetchEndOfDay,
	fetchEndOfDayReport,
	createLaundryMachineSet,
	createPosTerminal,
	reconCount,
	resetPOSTerminal,
	fetchDiscount,
	updateDiscount,
	forRecon,
	updateLaundryCycleCount,
	finalizeLaundryCycleCount,
	fetchSubscription,
	updateSubscription,
	deleteProfitCostCenters,
	deleteTaxes,
	deleteDiscounts,
	importTaxes,
	importDiscounts,
	deleteUnitOfMeasureConversions,
	deleteItemClasses,
	deleteFinishedGoodComponents
} from "./authAPI";

let auth = localStorage.getItem(resources.slice.AUTH);
export const initialValue = {
	is_session_active: false,
	user: null,
	token: null,
	notifier: false,
	is_sidebar_collapsed: true,
	active_company: null,
	selected_application: null,
	selected_module: null,
	navigation: [],
	status: resources.status.IDLE,
	locale: resources.locale.ENGLISH,
	enums: [],
	applications: [],
	companies: [],
	user_department_ids: [],
	user_branch_ids: [],
	user_project_ids: [],
	roles: [],
	document_types: [],
	application_user: [],
	role_user: [],
	item_unit_of_measure_conversion: [],
	location_tree: [],
	branch_user: [],
	department_user: [],
	project_user: [],
	item_class_location: [],
	finished_good_produced_stock_acceptance: [],
	general_settings: null,
	approval_pipelines: [],
	subscriptions: [],
	dashboard: {
		on_hand_inventories: null,
		fast_moving_inventories: null,
		near_stockout_skus: null,
		zero_stock_inventories: null,
		on_hand_and_sold_finished_goods: null,
		inventory_value_by_item_class: null,
		on_hand_finished_goods_by_source: null,
		item_conditions_percentage: null,
		item_conditions_percentage_colors: null,
		item_conditions_percentage_labels: null,
		item_conditions_percentage_item_classes: null,
	},
	worksheet: {
		is_readonly_mode: true,
		num_rows: 1000000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: resources.default_dataset,
	}
};
const initialState = auth ? JSON.parse(auth) : initialValue;

export const detachAttachmentAsync = createAsyncThunk(
	"attachment/detach",
	async (transaction, { rejectWithValue }) => {
		try {
			const response = await detachAttachment(transaction);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const postCommentAsync = createAsyncThunk(
	"comment/post",
	async (transaction, { rejectWithValue }) => {
		try {
			const response = await postComment(transaction);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const sendMessageAsync = createAsyncThunk(
	"message/send",
	async (message, { rejectWithValue }) => {
		try {
			const response = await sendMessage(message);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const transferDocumentsAsync = createAsyncThunk(
	"documents/transfer",
	async (documents, { rejectWithValue }) => {
		try {
			const response = await transferDocuments(documents);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createImportedItemsReceiptAsync = createAsyncThunk(
	"imported-items-receipt/create",
	async (importedItemsReceipt, { rejectWithValue }) => {
		try {
			const response = await createImportedItemsReceipt(importedItemsReceipt);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateImportedItemsReceiptAsync = createAsyncThunk(
	"imported-items-receipt/update",
	async (importedItemsReceipt, { rejectWithValue }) => {
		try {
			const response = await updateImportedItemsReceipt(importedItemsReceipt);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const submitImportedItemsReceiptAsync = createAsyncThunk(
	"imported-items-receipt/submit",
	async (importedItemsReceipt, { rejectWithValue }) => {
		try {
			const response = await submitImportedItemsReceipt(importedItemsReceipt);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const approveImportedItemsReceiptAsync = createAsyncThunk(
	"imported-items-receipt/approve",
	async (importedItemsReceipt, { rejectWithValue }) => {
		try {
			const response = await approveImportedItemsReceipt(importedItemsReceipt);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const rejectImportedItemsReceiptAsync = createAsyncThunk(
	"imported-items-receipt/reject",
	async (importedItemsReceipt, { rejectWithValue }) => {
		try {
			const response = await rejectImportedItemsReceipt(importedItemsReceipt);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importImportedItemsReceiptsAsync = createAsyncThunk(
	"imported-items-receipt/import",
	async (importedItemsReceipts, { rejectWithValue }) => {
		try {
			const response = await importImportedItemsReceipts(importedItemsReceipts);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);


export const loginAsync = createAsyncThunk(
	"auth/login",
	async (credentials, { rejectWithValue }) => {
		try {
			const response = await login(credentials);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const signupAsync = createAsyncThunk(
	"auth/signup",
	async (account, { rejectWithValue }) => {
		try {
			const response = await signup(account);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const forgotPasswordAsync = createAsyncThunk(
	"auth/forgot-password",
	async (email, { rejectWithValue }) => {
		try {
			const response = await forgotPassword(email);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const resetPasswordAsync = createAsyncThunk(
	"auth/reset-password",
	async (credentials, { rejectWithValue }) => {
		try {
			const response = await resetPassword(credentials);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const changePasswordAsync = createAsyncThunk(
	"auth/change-password",
	async (credentials, { rejectWithValue }) => {
		try {
			const response = await changePassword(credentials);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const pendingVerificationAsync = createAsyncThunk(
	"auth/pending-verification",
	async (verificationCode, { rejectWithValue }) => {
		try {
			const response = await pendingVerification(verificationCode);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const validateVerificationCodeAsync = createAsyncThunk(
	"auth/validate-verification-code",
	async (verificationCode, { rejectWithValue }) => {
		try {
			const response = await validateVerificationCode(verificationCode);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const duplicateProfitCostCentersAsync = createAsyncThunk(
	"profit-cost-center/duplicate",
	async (profitCostCenters, { rejectWithValue }) => {
		try {
			const response = await duplicateProfitCostCenters(profitCostCenters);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const duplicateItemClassesAsync = createAsyncThunk(
	"item-class/duplicate",
	async (itemClasses, { rejectWithValue }) => {
		try {
			const response = await duplicateItemClasses(itemClasses);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const duplicateItemsAsync = createAsyncThunk(
	"item/duplicate",
	async (items, { rejectWithValue }) => {
		try {
			const response = await duplicateItems(items);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const acceptInvitationAsync = createAsyncThunk(
	"auth/accept-invitation",
	async (params, { rejectWithValue }) => {
		try {
			const response = await acceptInvitation(params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const verifyAsync = createAsyncThunk(
	"auth/verify",
	async (credentials, { rejectWithValue }) => {
		try {
			const response = await verify(credentials);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const skipProcessAsync = createAsyncThunk(
	"auth/skip-process",
	async (params, { rejectWithValue }) => {
		try {
			const response = await skipProcess(params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateMessageStatusAsync = createAsyncThunk(
	"auth/update-message-status",
	async (message, { rejectWithValue }) => {
		try {
			const response = await updateMessageStatus(message);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateMessagesStatusAsync = createAsyncThunk(
	"auth/update-messages-status",
	async (params, { rejectWithValue }) => {
		try {
			const response = await updateMessagesStatus(params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const deleteMessagesAsync = createAsyncThunk(
	"auth/delete-messages",
	async (params, { rejectWithValue }) => {
		try {
			const response = await deleteMessages(params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const logoutAsync = createAsyncThunk(
	"auth/logout",
	async () => {
		try {
			const response = await logout();
			return response.data;
		} catch (error) {
		}
	}
);

export const switchCompanyAsync = createAsyncThunk(
	"auth/switch-company",
	async (company, { rejectWithValue }) => {
		try {
			const response = await switchCompany(company.user, {
				company_id: company.id,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

//system admin

export const createUserAsync = createAsyncThunk(
    "user/create",
    async (user, { rejectWithValue }) => {
        try {
            const response = await createUser(user);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const createSubscriptionAsync = createAsyncThunk(
    "subscription/create",
    async (subscription, { rejectWithValue }) => {
        try {
            const response = await createSubscription(subscription);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const updateSubscriptionAsync = createAsyncThunk(
	"subscription/update",
	async (subscription, { rejectWithValue }) => {
		try {
			const response = await updateSubscription(subscription);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateUserAsync = createAsyncThunk(
    "user/update",
    async (user, { rejectWithValue }) => {
        try {
            const response = await updateUser(user);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteUserAsync = createAsyncThunk(
    "user/delete",
    async (user, { rejectWithValue }) => {
        try {
            const response = await deleteUser(user);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteUsersAsync = createAsyncThunk(
    "user/batch-delete",
    async (users, { rejectWithValue }) => {
        try {
            const response = await deleteUsers(users);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const importUsersAsync = createAsyncThunk(
	"user/import",
	async (users, { rejectWithValue }) => {
		try {
			const response = await importUsers(users);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createProfitCostCenterAsync = createAsyncThunk(
    "profit-cost-center/create",
    async (profitCostCenter, { rejectWithValue }) => {
        try {
            switch (profitCostCenter.type) {
                case profitCostCenter.enums.profit_cost_center_types.BRANCH: {
                    const response = await createBranch(profitCostCenter);
                    return response.data;
                }
                case profitCostCenter.enums.profit_cost_center_types.DEPARTMENT: {
                    const response = await createDepartment(profitCostCenter);
                    return response.data;
                }
                case profitCostCenter.enums.profit_cost_center_types.PROJECT: {
                    const response = await createProject(profitCostCenter);
                    return response.data;
                }
                default: {
                    break;
                }
            }
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const assignOrderingAndAppointmentAppUsersAsync = createAsyncThunk(
    "ordering-and-appointment-app/assign",
    async (app, { rejectWithValue }) => {
        try {
			const response = await assignOrderingAndAppointmentAppUsers(app);
			return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const payOrderingAndAppointmentAsync = createAsyncThunk(
    "ordering-and-appointment-app/payment",
    async (app, { rejectWithValue }) => {
        try {
			const response = await payOrderingAndAppointment(app);
			return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const updateBranchAsync = createAsyncThunk(
    "branch/update",
    async (branch, { rejectWithValue }) => {
        try {
            const response = await updateBranch(branch);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const updateDepartmentAsync = createAsyncThunk(
    "department/update",
    async (department, { rejectWithValue }) => {
        try {
            const response = await updateDepartment(department);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const updateProjectAsync = createAsyncThunk(
    "project/update",
    async (project, { rejectWithValue }) => {
        try {
            const response = await updateProject(project);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteBranchAsync = createAsyncThunk(
    "branch/delete",
    async (branch, { rejectWithValue }) => {
        try {
            const response = await deleteBranch(branch);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteDepartmentAsync = createAsyncThunk(
    "department/delete",
    async (department, { rejectWithValue }) => {
        try {
            const response = await deleteDepartment(department);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteProjectAsync = createAsyncThunk(
    "project/delete",
    async (project, { rejectWithValue }) => {
        try {
            const response = await deleteProject(project);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const importLocationsAsync = createAsyncThunk(
	"location/import",
	async (locations, { rejectWithValue }) => {
		try {
			const response = await importLocations(locations);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const deleteProfitCostCentersAsync = createAsyncThunk(
    "profit-cost-center/batch-delete",
    async (profitCostCenters, { rejectWithValue }) => {
        try {
            const response = await deleteProfitCostCenters(profitCostCenters);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const importProfitCostCentersAsync = createAsyncThunk(
    "profit-cost-center/import",
    async (profitCostCenters, { rejectWithValue }) => {
        try {
            const response = await importProfitCostCenters(profitCostCenters);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const updatePermissionAsync = createAsyncThunk(
    "permission/update",
    async (permission, { rejectWithValue }) => {
        try {
            const response = await updatePermission(permission);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const assignAuthorizersAsync = createAsyncThunk(
    "authorizer/assign",
    async (authorizers, { rejectWithValue }) => {
        try {
            const response = await assignAuthorizers(authorizers);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const unAssignAuthorizersAsync = createAsyncThunk(
    "authorizer/unassign",
    async (authorizers, { rejectWithValue }) => {
        try {
            const response = await unAssignAuthorizers(authorizers);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteAmountRangeAsync = createAsyncThunk(
    "amount-range/delete",
    async (amountRange, { rejectWithValue }) => {
        try {
            const response = await deleteAmountRange(amountRange);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const createMakersAsync = createAsyncThunk(
    "maker/assign",
    async (makers, { rejectWithValue }) => {
        try {
            const response = await createMakers(makers);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteMakerAsync = createAsyncThunk(
    "maker/delete",
    async (maker, { rejectWithValue }) => {
        try {
            const response = await deleteMaker(maker);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const createUnitOfMeasureAsync = createAsyncThunk(
	"unit-of-measure/create",
	async (unitOfMeasure, { rejectWithValue }) => {
		try {
			const response = await createUnitOfMeasure(unitOfMeasure);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createUnitOfMeasureConversionAsync = createAsyncThunk(
	"unit-of-measure-conversion/create",
	async (unitOfMeasureConversion, { rejectWithValue }) => {
		try {
			const response = await createUnitOfMeasureConversion(unitOfMeasureConversion);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createItemAsync = createAsyncThunk(
	"item/create",
	async (item, { rejectWithValue }) => {
		try {
			const response = await createItem(item);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateItemAsync = createAsyncThunk(
	"item/update",
	async (item, { rejectWithValue }) => {
		try {
			const response = await updateItem(item);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createItemClassAsync = createAsyncThunk(
	"item-class/create",
	async (itemClass, { rejectWithValue }) => {
		try {
			const response = await createItemClass(itemClass);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateItemClassAsync = createAsyncThunk(
	"item-class/update",
	async (itemClass, { rejectWithValue }) => {
		try {
			const response = await updateItemClass(itemClass);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const deleteItemAsync = createAsyncThunk(
    "item/delete",
    async (item, { rejectWithValue }) => {
        try {
            const response = await deleteItem(item);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteItemsAsync = createAsyncThunk(
	"items/delete",
	async (items, { rejectWithValue }) => {
		try {
			const response = await deleteItems(items);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const deleteItemImageAsync = createAsyncThunk(
    "item/delete-image",
    async (item, { rejectWithValue }) => {
        try {
            const response = await deleteItemImage(item);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const importItemsAsync = createAsyncThunk(
	"item/import",
	async (items, { rejectWithValue }) => {
		try {
			const response = await importItems(items);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateItemImageAsync = createAsyncThunk(
	"item/update-image",
	async (item, { rejectWithValue }) => {
		try {
			const response = await updateItemImage(item);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateServiceImageAsync = createAsyncThunk(
	"service/update-image",
	async (service, { rejectWithValue }) => {
		try {
			const response = await updateServiceImage(service);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const uploadCompanyLogoAsync = createAsyncThunk(
	"company/upload-logo",
	async (company, { rejectWithValue }) => {
		try {
			const response = await uploadCompanyLogo(company);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createFinishedGoodComponentsAsync = createAsyncThunk(
	"finished-good-component/create",
	async (item, { rejectWithValue }) => {
		try {
			const response = await createFinishedGoodComponents(item);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importFinishedGoodComponentsAsync = createAsyncThunk(
	"finished-good-component/import",
	async (items, { rejectWithValue }) => {
		try {
			const response = await importFinishedGoodComponents(items);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const deleteFinishedGoodComponentsAsync = createAsyncThunk(
    "finished-good-component/batch-delete",
    async (finishedGoodComponents, { rejectWithValue }) => {
        try {
            const response = await deleteFinishedGoodComponents(finishedGoodComponents);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const createPrintJobAsync = createAsyncThunk(
	"print-job/create",
	async (printJob, { rejectWithValue }) => {
		try {
			const response = await createPrintJob(printJob);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const deletePrintJobAsync = createAsyncThunk(
    "print-job/delete",
    async (printJob, { rejectWithValue }) => {
        try {
            const response = await deletePrintJob(printJob);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const importPrintJobsAsync = createAsyncThunk(
	"print-job/import",
	async (printJobs, { rejectWithValue }) => {
		try {
			const response = await importPrintJobs(printJobs);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateItemSerialNumberCountAsync = createAsyncThunk(
	"item/update-serial-number-count",
	async (item, { rejectWithValue }) => {
		try {
			const response = await updateItemSerialNumberCount(item);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createLocationTypeAsync = createAsyncThunk(
	"location-type/create",
	async (locationType, { rejectWithValue }) => {
		try {
			const response = await createLocationType(locationType);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createLocationAsync = createAsyncThunk(
	"location/create",
	async (location, { rejectWithValue }) => {
		try {
			const response = await createLocation(location);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const deleteLocationAsync = createAsyncThunk(
    "location/delete",
    async (location, { rejectWithValue }) => {
        try {
            const response = await deleteLocation(location);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteLocationsAsync = createAsyncThunk(
    "location/batch-delete",
    async (locations, { rejectWithValue }) => {
        try {
            const response = await deleteLocations(locations);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const createStockRequestAsync = createAsyncThunk(
	"stock-request/create",
	async (stockRequest, { rejectWithValue }) => {
		try {
			const response = await createStockRequest(stockRequest);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateStockRequestAsync = createAsyncThunk(
	"stock-request/update",
	async (stockRequest, { rejectWithValue }) => {
		try {
			const response = await updateStockRequest(stockRequest);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const submitDocumentsAsync = createAsyncThunk(
	"documents/submit",
	async (documents, { rejectWithValue }) => {
		try {
			const response = await submitDocuments(documents);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const submitStockRequestAsync = createAsyncThunk(
	"stock-request/submit",
	async (stockRequest, { rejectWithValue }) => {
		try {
			const response = await submitStockRequest(stockRequest);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const approveStockRequestAsync = createAsyncThunk(
	"stock-request/approve",
	async (stockRequest, { rejectWithValue }) => {
		try {
			const response = await approveStockRequest(stockRequest);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const rejectStockRequestAsync = createAsyncThunk(
	"stock-request/reject",
	async (stockRequest, { rejectWithValue }) => {
		try {
			const response = await rejectStockRequest(stockRequest);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const voidStockRequestAsync = createAsyncThunk(
	"stock-request/void",
	async (stockRequest, { rejectWithValue }) => {
		try {
			const response = await voidStockRequest(stockRequest);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importStockRequestsAsync = createAsyncThunk(
	"stock-request/import",
	async (stockRequests, { rejectWithValue }) => {
		try {
			const response = await importStockRequests(stockRequests);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateStockCountAsync = createAsyncThunk(
	"stock-count/update",
	async (stockCount, { rejectWithValue }) => {
		try {
			const response = await updateStockCount(stockCount);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const submitStockCountAsync = createAsyncThunk(
	"stock-count/submit",
	async (stockCount, { rejectWithValue }) => {
		try {
			const response = await submitStockCount(stockCount);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const approveStockCountAsync = createAsyncThunk(
	"stock-count/approve",
	async (stockCount, { rejectWithValue }) => {
		try {
			const response = await approveStockCount(stockCount);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const rejectStockCountAsync = createAsyncThunk(
	"stock-count/reject",
	async (stockCount, { rejectWithValue }) => {
		try {
			const response = await rejectStockCount(stockCount);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const voidStockCountAsync = createAsyncThunk(
	"stock-count/void",
	async (stockCount, { rejectWithValue }) => {
		try {
			const response = await voidStockCount(stockCount);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importStockCountsAsync = createAsyncThunk(
	"stock-count/import",
	async (stockCounts, { rejectWithValue }) => {
		try {
			const response = await importStockCounts(stockCounts);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createStockIssuanceAsync = createAsyncThunk(
	"stock-issuance/create",
	async (stockIssuance, { rejectWithValue }) => {
		try {
			const response = await createStockIssuance(stockIssuance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateStockIssuanceAsync = createAsyncThunk(
	"stock-issuance/update",
	async (stockIssuance, { rejectWithValue }) => {
		try {
			const response = await updateStockIssuance(stockIssuance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const submitStockIssuanceAsync = createAsyncThunk(
	"stock-issuance/submit",
	async (stockIssuance, { rejectWithValue }) => {
		try {
			const response = await submitStockIssuance(stockIssuance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const approveStockIssuanceAsync = createAsyncThunk(
	"stock-issuance/approve",
	async (stockIssuance, { rejectWithValue }) => {
		try {
			const response = await approveStockIssuance(stockIssuance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const rejectStockIssuanceAsync = createAsyncThunk(
	"stock-issuance/reject",
	async (stockIssuance, { rejectWithValue }) => {
		try {
			const response = await rejectStockIssuance(stockIssuance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const voidStockIssuanceAsync = createAsyncThunk(
	"stock-issuance/void",
	async (stockIssuance, { rejectWithValue }) => {
		try {
			const response = await voidStockIssuance(stockIssuance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createFinishedGoodProducedAsync = createAsyncThunk(
	"finished-good-produced/create",
	async (finishedGoodsProduced, { rejectWithValue }) => {
		try {
			const response = await createFinishedGoodProduced(finishedGoodsProduced);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateFinishedGoodProducedAsync = createAsyncThunk(
	"finished-good-produced/update",
	async (finishedGoodsProduced, { rejectWithValue }) => {
		try {
			const response = await updateFinishedGoodProduced(finishedGoodsProduced);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const submitFinishedGoodProducedAsync = createAsyncThunk(
	"finished-good-produced/submit",
	async (finishedGoodsProduced, { rejectWithValue }) => {
		try {
			const response = await submitFinishedGoodProduced(finishedGoodsProduced);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const approveFinishedGoodProducedAsync = createAsyncThunk(
	"finished-good-produced/approve",
	async (fgp, { rejectWithValue }) => {
		try {
			const response = await approveFinishedGoodProduced(fgp);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const rejectFinishedGoodProducedAsync = createAsyncThunk(
	"finished-good-produced/reject",
	async (fgp, { rejectWithValue }) => {
		try {
			const response = await rejectFinishedGoodProduced(fgp);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const voidFinishedGoodProducedAsync = createAsyncThunk(
	"finished-good-produced/void",
	async (fgp, { rejectWithValue }) => {
		try {
			const response = await voidFinishedGoodProduced(fgp);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const saveCashierShiftAsync = createAsyncThunk(
	"cashier-shift/save",
	async (cashierShift, { rejectWithValue }) => {
		try {
			const response = await saveCashierShift(cashierShift);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const savePosTerminalSettingsAsync = createAsyncThunk(
	"pos-terminal-settings/save",
	async (posTerminalSettings, { rejectWithValue }) => {
		try {
			const response = await savePosTerminalSettings(posTerminalSettings);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createLaundryMachineSetAsync = createAsyncThunk(
	"laundry-machine-set/create",
	async (laundryMachineSet, { rejectWithValue }) => {
		try {
			const response = await createLaundryMachineSet(laundryMachineSet);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createPosTerminalAsync = createAsyncThunk(
	"pos-terminal/create",
	async (posTerminal, { rejectWithValue }) => {
		try {
			const response = await createPosTerminal(posTerminal);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const resetPosTerminalAsync = createAsyncThunk(
	"pos-terminal/reset",
	async (posTerminal, { rejectWithValue }) => {
		try {
			const response = await resetPOSTerminal(posTerminal);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const cycleCountReconCountAsync = createAsyncThunk(
	"cycle-count-recon/count",
	async (params, { rejectWithValue }) => {
		try {
			const response = await reconCount(params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const forCycleCountReconAsync = createAsyncThunk(
	"cycle-count-recon/for-recon",
	async (params, { rejectWithValue }) => {
		try {
			const response = await forRecon(params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateLaundryCycleCountAsync = createAsyncThunk(
	"cycle-count-recon/update",
	async (params, { rejectWithValue }) => {
		try {
			const response = await updateLaundryCycleCount(params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const finalizeLaundryCycleCountAsync = createAsyncThunk(
	"cycle-count-recon/finalize",
	async (params, { rejectWithValue }) => {
		try {
			const response = await finalizeLaundryCycleCount(params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const pullInventoryItemsAsync = createAsyncThunk(
	"pull-inventory-items/save",
	async (inventoryItems, { rejectWithValue }) => {
		try {
			const response = await pullInventoryItems(inventoryItems);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const removeInventoryItemsAsync = createAsyncThunk(
	"remove-inventory-items/save",
	async (inventoryItems, { rejectWithValue }) => {
		try {
			const response = await removeInventoryItems(inventoryItems);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createServiceAsync = createAsyncThunk(
	"service/create",
	async (service, { rejectWithValue }) => {
		try {
			const response = await createService(service);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createStockAcceptanceAsync = createAsyncThunk(
	"stock-acceptance/create",
	async (stockAcceptance, { rejectWithValue }) => {
		try {
			const response = await createStockAcceptance(stockAcceptance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateStockAcceptanceAsync = createAsyncThunk(
	"stock-acceptance/update",
	async (stockAcceptance, { rejectWithValue }) => {
		try {
			const response = await updateStockAcceptance(stockAcceptance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const submitStockAcceptanceAsync = createAsyncThunk(
	"stock-acceptance/submit",
	async (stockAcceptance, { rejectWithValue }) => {
		try {
			const response = await submitStockAcceptance(stockAcceptance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const approveStockAcceptanceAsync = createAsyncThunk(
	"stock-acceptance/approve",
	async (stockAcceptance, { rejectWithValue }) => {
		try {
			const response = await approveStockAcceptance(stockAcceptance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const rejectStockAcceptanceAsync = createAsyncThunk(
	"stock-acceptance/reject",
	async (stockAcceptance, { rejectWithValue }) => {
		try {
			const response = await rejectStockAcceptance(stockAcceptance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const voidStockAcceptanceAsync = createAsyncThunk(
	"stock-acceptance/void",
	async (stockAcceptance, { rejectWithValue }) => {
		try {
			const response = await voidStockAcceptance(stockAcceptance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const voidImportedItemsReceiptAsync = createAsyncThunk(
	"imported-items-receipt/void",
	async (importedItemsReceipt, { rejectWithValue }) => {
		try {
			const response = await voidImportedItemsReceipt(importedItemsReceipt);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importStockAcceptancesAsync = createAsyncThunk(
	"stock-acceptance/import",
	async (stockAcceptances, { rejectWithValue }) => {
		try {
			const response = await importStockAcceptances(stockAcceptances);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importStockIssuancesAsync = createAsyncThunk(
	"stock-issuance/import",
	async (data, { rejectWithValue }) => {
		try {
			const response = await importStockIssuances(data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createStockAdjustmentAsync = createAsyncThunk(
	"stock-adjustment/create",
	async (stockAdjustment, { rejectWithValue }) => {
		try {
			const response = await createStockAdjustment(stockAdjustment);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateStockAdjustmentAsync = createAsyncThunk(
	"stock-adjustment/update",
	async (stockAdjustment, { rejectWithValue }) => {
		try {
			const response = await updateStockAdjustment(stockAdjustment);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const submitStockAdjustmentAsync = createAsyncThunk(
	"stock-adjustment/submit",
	async (stockAdjustment, { rejectWithValue }) => {
		try {
			const response = await submitStockAdjustment(stockAdjustment);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const approveStockAdjustmentAsync = createAsyncThunk(
	"stock-adjustment/approve",
	async (stockAdjustment, { rejectWithValue }) => {
		try {
			const response = await approveStockAdjustment(stockAdjustment);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const rejectStockAdjustmentAsync = createAsyncThunk(
	"stock-adjustment/reject",
	async (stockAdjustment, { rejectWithValue }) => {
		try {
			const response = await rejectStockAdjustment(stockAdjustment);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const voidStockAdjustmentAsync = createAsyncThunk(
	"stock-adjustment/void",
	async (stockAdjustment, { rejectWithValue }) => {
		try {
			const response = await voidStockAdjustment(stockAdjustment);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importStockAdjustmentsAsync = createAsyncThunk(
	"stock-adjustment/import",
	async (stockAdjustments, { rejectWithValue }) => {
		try {
			const response = await importStockAdjustments(stockAdjustments);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createItemLocationTransferAsync = createAsyncThunk(
	"item-location-transfer/create",
	async (itemLocationTransfer, { rejectWithValue }) => {
		try {
			const response = await createItemLocationTransfer(itemLocationTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateItemLocationTransferAsync = createAsyncThunk(
	"item-location-transfer/update",
	async (itemLocationTransfer, { rejectWithValue }) => {
		try {
			const response = await updateItemLocationTransfer(itemLocationTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const submitItemLocationTransferAsync = createAsyncThunk(
	"item-location-transfer/submit",
	async (itemLocationTransfer, { rejectWithValue }) => {
		try {
			const response = await submitItemLocationTransfer(itemLocationTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const approveItemLocationTransferAsync = createAsyncThunk(
	"item-location-transfer/approve",
	async (itemLocationTransfer, { rejectWithValue }) => {
		try {
			const response = await approveItemLocationTransfer(itemLocationTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const rejectItemLocationTransferAsync = createAsyncThunk(
	"item-location-transfer/reject",
	async (itemLocationTransfer, { rejectWithValue }) => {
		try {
			const response = await rejectItemLocationTransfer(itemLocationTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const voidItemLocationTransferAsync = createAsyncThunk(
	"item-location-transfer/void",
	async (itemLocationTransfer, { rejectWithValue }) => {
		try {
			const response = await voidItemLocationTransfer(itemLocationTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importItemLocationTransfersAsync = createAsyncThunk(
	"item-location-transfer/import",
	async (itemLocationTransfers, { rejectWithValue }) => {
		try {
			const response = await importItemLocationTransfers(itemLocationTransfers);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createStockTransferAsync = createAsyncThunk(
	"stock-transfer/create",
	async (stockTransfer, { rejectWithValue }) => {
		try {
			const response = await createStockTransfer(stockTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateStockTransferAsync = createAsyncThunk(
	"stock-transfer/update",
	async (stockTransfer, { rejectWithValue }) => {
		try {
			const response = await updateStockTransfer(stockTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const submitStockTransferAsync = createAsyncThunk(
	"stock-transfer/submit",
	async (stockTransfer, { rejectWithValue }) => {
		try {
			const response = await submitStockTransfer(stockTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const approveStockTransferAsync = createAsyncThunk(
	"stock-transfer/approve",
	async (stockTransfer, { rejectWithValue }) => {
		try {
			const response = await approveStockTransfer(stockTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const rejectStockTransferAsync = createAsyncThunk(
	"stock-transfer/reject",
	async (stockTransfer, { rejectWithValue }) => {
		try {
			const response = await rejectStockTransfer(stockTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const voidStockTransferAsync = createAsyncThunk(
	"stock-transfer/void",
	async (stockTransfer, { rejectWithValue }) => {
		try {
			const response = await voidStockTransfer(stockTransfer);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importStockTransfersAsync = createAsyncThunk(
	"stock-transfer/import",
	async (stockTransfers, { rejectWithValue }) => {
		try {
			const response = await importStockTransfers(stockTransfers);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createBeginningBalanceAsync = createAsyncThunk(
	"beginning-balance/create",
	async (beginningBalance, { rejectWithValue }) => {
		try {
			const response = await createBeginningBalance(beginningBalance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateBeginningBalanceAsync = createAsyncThunk(
	"beginning-balance/update",
	async (beginningBalance, { rejectWithValue }) => {
		try {
			const response = await updateBeginningBalance(beginningBalance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const submitBeginningBalanceAsync = createAsyncThunk(
	"beginning-balance/submit",
	async (beginningBalance, { rejectWithValue }) => {
		try {
			const response = await submitBeginningBalance(beginningBalance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const approveBeginningBalanceAsync = createAsyncThunk(
	"beginning-balance/approve",
	async (beginningBalance, { rejectWithValue }) => {
		try {
			const response = await approveBeginningBalance(beginningBalance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const rejectBeginningBalanceAsync = createAsyncThunk(
	"beginning-balance/reject",
	async (beginningBalance, { rejectWithValue }) => {
		try {
			const response = await rejectBeginningBalance(beginningBalance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const voidBeginningBalanceAsync = createAsyncThunk(
	"beginning-balance/void",
	async (beginningBalance, { rejectWithValue }) => {
		try {
			const response = await voidBeginningBalance(beginningBalance);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importBeginningBalancesAsync = createAsyncThunk(
	"beginning-balance/import",
	async (beginningBalances, { rejectWithValue }) => {
		try {
			const response = await importBeginningBalances(beginningBalances);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateGeneralSettingsAsync = createAsyncThunk(
	"general-settings/update",
	async (generalSettings, { rejectWithValue }) => {
		try {
			const response = await updateGeneralSettings(generalSettings);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const byPassStockCountScheduleAsync = createAsyncThunk(
	"stock-count/bypass",
	async (stockCount, { rejectWithValue }) => {
		try {
			const response = await byPassStockCountSchedule();
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const byPassYearEndClosingAsync = createAsyncThunk(
	"stock-count/year-end-closing",
	async (params, { rejectWithValue }) => {
		try {
			const response = await byPassYearEndClosing();
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateInventoryTrackingsAsync = createAsyncThunk(
	"inventory-tracking/update",
	async (inventoryTrackings, { rejectWithValue }) => {
		try {
			const response = await updateInventoryTrackings(inventoryTrackings);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateLocationAsync = createAsyncThunk(
	"location/update",
	async (location, { rejectWithValue }) => {
		try {
			const response = await updateLocation(location);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importItemClassesAsync = createAsyncThunk(
	"item-classes/import",
	async (itemClasses, { rejectWithValue }) => {
		try {
			const response = await importItemClasses(itemClasses);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const importUnitOfMeasureConversionsAsync = createAsyncThunk(
	"unit-of-measure-conversion/import",
	async (unitOfMeasureConversions, { rejectWithValue }) => {
		try {
			const response = await importUnitOfMeasureConversions(unitOfMeasureConversions);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateUnitOfMeasureConversionAsync = createAsyncThunk(
	"unit-of-measure-conversion/update",
	async (unitOfMeasureConversion, { rejectWithValue }) => {
		try {
			const response = await updateUnitOfMeasureConversion(unitOfMeasureConversion);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const deleteUnitOfMeasureConversionAsync = createAsyncThunk(
    "unit-of-measure-conversion/delete",
    async (unitOfMeasureConversion, { rejectWithValue }) => {
        try {
            const response = await deleteUnitOfMeasureConversion(unitOfMeasureConversion);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteUnitOfMeasureConversionsAsync = createAsyncThunk(
    "unit-of-measure-conversion/batch-delete",
    async (umcs, { rejectWithValue }) => {
        try {
            const response = await deleteUnitOfMeasureConversions(umcs);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteItemClassAsync = createAsyncThunk(
    "item-class/delete",
    async (itemClass, { rejectWithValue }) => {
        try {
            const response = await deleteItemClass(itemClass);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteItemClassesAsync = createAsyncThunk(
    "item-class/batch-delete",
    async (itemClasses, { rejectWithValue }) => {
        try {
            const response = await deleteItemClasses(itemClasses);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAverageCostMethodAsync = createAsyncThunk(
    "average-cost-method/fetch",
    async (averageCostMethod, { rejectWithValue }) => {
        try {
            const response = await fetchAverageCostMethod(averageCostMethod);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllUsersAsync = createAsyncThunk(
    "user/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllUsers(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllCustomersAsync = createAsyncThunk(
    "customer/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllCustomers(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllOrdersAsync = createAsyncThunk(
    "orders/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllOrders(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchCustomerProfitCostCentersAsync = createAsyncThunk(
    "customer-profit-cost-centers/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchCustomerProfitCostCenters(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchUserCompaniesAsync = createAsyncThunk(
    "user-companies/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchUserCompanies(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchOrderAsync = createAsyncThunk(
    "order/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchOrder(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const updateOrderAsync = createAsyncThunk(
    "order/update",
    async (order, { rejectWithValue }) => {
        try {
            const response = await updateOrder(order);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchUserAsync = createAsyncThunk(
    "user/retrieve",
    async (user, { rejectWithValue }) => {
        try {
            const response = await fetchUser(user);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchSerialNumbersAsync = createAsyncThunk(
    "serial-number/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchSerialNumbers(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchExpiryDatesAsync = createAsyncThunk(
    "expiry-date/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchExpiryDates(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchDocumentsCountAsync = createAsyncThunk(
    "documents-count/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchDocumentsCount(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllDocumentsAsync = createAsyncThunk(
    "documents/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllDocuments(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllFinishedGoodProducedLineItemsAsync = createAsyncThunk(
    "finished-good-produced-line-item/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllFinishedGoodProducedLineItems(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockAdjustmentLineItemsAsync = createAsyncThunk(
    "stock-adjustment-line-item/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockAdjustmentLineItems(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockRequestLineItemsAsync = createAsyncThunk(
    "stock-request-line-item/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockRequestLineItems(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockCountLineItemsAsync = createAsyncThunk(
    "stock-count-line-item/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockCountLineItems(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockTransferLineItemsAsync = createAsyncThunk(
    "stock-transfer-line-item/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockTransferLineItems(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockAcceptanceLineItemsAsync = createAsyncThunk(
    "stock-acceptance-line-item/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockAcceptanceLineItems(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllImportedItemsReceiptLineItemsAsync = createAsyncThunk(
    "imported-items-receipt-line-item/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllImportedItemsReceiptLineItems(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllItemLocationTransferLineItemsAsync = createAsyncThunk(
    "item-location-transfer-line-item/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllItemLocationTransferLineItems(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockIssuanceLineItemsAsync = createAsyncThunk(
    "stock-issuance-line-item/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockIssuanceLineItems(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllProfitCostCentersAsync = createAsyncThunk(
    "profit-cost-center/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllProfitCostCenters(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllLocationsAsync = createAsyncThunk(
    "location/fetch",
    async (params, { rejectWithValue }) => {
        try {
            const response = await fetchAllLocations();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllLocationTypesAsync = createAsyncThunk(
    "location-type/fetch",
    async (params, { rejectWithValue }) => {
        try {
            const response = await fetchAllLocationTypes();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchLocationTreeAsync = createAsyncThunk(
    "location-tree/fetch",
    async (params, { rejectWithValue }) => {
        try {
            const response = await fetchLocationTree();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllItemClassesAsync = createAsyncThunk(
    "item-class/fetch",
    async (itemClass, { rejectWithValue }) => {
        try {
            const response = await fetchAllItemClasses();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchItemAsync = createAsyncThunk(
    "item/fetch",
    async (item, { rejectWithValue }) => {
        try {
            const response = await fetchItem(item);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchDiscountAsync = createAsyncThunk(
    "discount/fetch",
    async (discount, { rejectWithValue }) => {
        try {
            const response = await fetchDiscount(discount);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllItemsAsync = createAsyncThunk(
    "items/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllItems(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllFinishedGoodComponentsAsync = createAsyncThunk(
    "finished-good-components/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllFinishedGoodComponents(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllUnitOfMeasureConversionsAsync = createAsyncThunk(
    "unit-of-measure-conversion/fetch",
    async (user, { rejectWithValue }) => {
        try {
            const response = await fetchAllUnitOfMeasureConversions();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllUnitOfMeasuresAsync = createAsyncThunk(
    "unit-of-measure/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllUnitOfMeasures(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockRequestsAsync = createAsyncThunk(
    "stock-request/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockRequests(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockTransfersAsync = createAsyncThunk(
    "stock-transfer/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockTransfers(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockAcceptancesAsync = createAsyncThunk(
    "stock-acceptance/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockAcceptances(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllImportedItemsReceiptsAsync = createAsyncThunk(
    "imported-items-receipt/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllImportedItemsReceipts(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllSubscriptionsAsync = createAsyncThunk(
    "subscriptions/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllSubscriptions();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchSubscriptionAsync = createAsyncThunk(
    "subscription/fetch",
    async (subscription, { rejectWithValue }) => {
        try {
            const response = await fetchSubscription(subscription);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllCompaniesAsync = createAsyncThunk(
    "companies/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllCompanies();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllMessagesAsync = createAsyncThunk(
    "messages/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllMessages(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchMessagesCountAsync = createAsyncThunk(
    "messages/count/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchMessagesCount();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllAmountRangesAsync = createAsyncThunk(
    "amount-ranges/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllAmountRanges();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllFinishedGoodProducesAsync = createAsyncThunk(
    "finished-good-produces/fetch",
    async (fgp, { rejectWithValue }) => {
        try {
            const response = await fetchAllFinishedGoodProduces();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockIssuancesAsync = createAsyncThunk(
    "stock-issuance/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockIssuances(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockAdjustmentsAsync = createAsyncThunk(
    "stock-adjustment/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockAdjustments(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllItemLocationTransfersAsync = createAsyncThunk(
    "item-location-transfer/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllItemLocationTransfers(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAccountingPeriodsAsync = createAsyncThunk(
    "accounting-period/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAccountingPeriods(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchStockCountSchedulesAsync = createAsyncThunk(
    "stock-count-schedules/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchStockCountSchedules(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchItemHistoriesAsync = createAsyncThunk(
    "item-histories/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchItemHistories(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchSalesAsync = createAsyncThunk(
    "sales/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchSales(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchEndOfDayAsync = createAsyncThunk(
    "end-of-day/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchEndOfDay(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchEndOfDayReportAsync = createAsyncThunk(
    "end-of-day-report/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchEndOfDayReport(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchStockMovementsAsync = createAsyncThunk(
    "stock-movements/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchStockMovements(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchOnHandInventoryAsync = createAsyncThunk(
    "on-hand-inventory/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchOnHandInventory(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchFastMovingItemsAsync = createAsyncThunk(
    "fast-moving-items/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchFastMovingItems(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchNearStockoutSkusAsync = createAsyncThunk(
    "near-stockout-skus/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchNearStockoutSkus(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchZeroStockInventoriesAsync = createAsyncThunk(
    "zero-stock-inventories/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchZeroStockInventories(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchItemConditionsPercentageAsync = createAsyncThunk(
    "item-conditions-percentage/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchItemConditionsPercentage(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchInventoryValueByItemClassAsync = createAsyncThunk(
    "inventory-value-by-item-class/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchInventoryValueByItemClass(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchFinishedGoodsSoldAsync = createAsyncThunk(
    "finished-goods-sold/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchFinishedGoodsSold(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchOnHandFinishedGoodsAsync = createAsyncThunk(
    "on-hand-finished-goods/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchOnHandFinishedGoods(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchItemConditionsAsync = createAsyncThunk(
    "item-conditions/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchItemConditions(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchStockOnHandAsync = createAsyncThunk(
    "stock-on-hand/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchStockOnHand(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchItemLedgerAsync = createAsyncThunk(
    "item-ledger/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchItemLedger(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchDeliveryReceiptAsync = createAsyncThunk(
    "delivery-receipt/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchDeliveryReceipt(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchIssuedItemsForAcceptanceAsync = createAsyncThunk(
    "issued-items-for-acceptance/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchIssuedItemsForAcceptance(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchInventoryItemByStatusAsync = createAsyncThunk(
    "inventory-item-by-status/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchInventoryItemByStatus(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchCashierShiftsAsync = createAsyncThunk(
    "cashier-shifts/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchCashierShifts(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchPosServicesAsync = createAsyncThunk(
    "pos-services/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchPosServices(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchLaundryMachineSetsAsync = createAsyncThunk(
    "laundry-machine-sets/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchLaundryMachineSets(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchPosTerminalsAsync = createAsyncThunk(
    "pos-terminals/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchPosTerminals(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchTaxesAsync = createAsyncThunk(
    "taxes/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchLaundryMachineSets(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllCountriesAsync = createAsyncThunk(
    "countries/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllCountries(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchEndingInventoriesAsync = createAsyncThunk(
    "ending-inventories/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchEndingInventories();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllStockCountsAsync = createAsyncThunk(
    "stock-count/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllStockCounts(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchStockCountSheetAsync = createAsyncThunk(
    "stock-count-sheet/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchStockCountSheet();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllBeginningBalancesAsync = createAsyncThunk(
    "beginning-balance/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllBeginningBalances(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const firstInFirstOutAsync = createAsyncThunk(
    "first-in-first-out/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await firstInFirstOut(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const lastInFirstOutAsync = createAsyncThunk(
    "last-in-first-out/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await lastInFirstOut(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const firstOutFirstInAsync = createAsyncThunk(
    "first-out-first-in/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await firstOutFirstIn(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const updateServiceAsync = createAsyncThunk(
    "service/update",
    async (service, { rejectWithValue }) => {
        try {
            const response = await updateService(service);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

const resetLocationTreeIsSelectedState = (node) => {
	node.isSelected = false;
	var childNodes = node.childNodes;
	for (let i=0; i<childNodes.length; i++) {
		resetLocationTreeIsSelectedState(childNodes[i]);
	}
}

const updateSelectedApplication = (state) => {
	if (state.active_company.application_id) {
		let nextApplication = state.applications.find((app) => (app.id===state.active_company.application_id));
		if (nextApplication) {
			let module = state.applications.find((app) => (app.id===nextApplication.application_id));
			let app = state.applications.find((app) => (app.id===module.application_id));
			state.selected_application = app;
		}
	}
}

export const fetchAllTaxesAsync = createAsyncThunk(
    "taxes/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllTaxes(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const createTaxAsync = createAsyncThunk(
	"taxes/create",
	async (params, { rejectWithValue }) => {
		try {
			const response = await createTax(params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateTaxAsync = createAsyncThunk(
    "tax/update",
    async (tax, { rejectWithValue }) => {
        try {
            const response = await updateTax(tax);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteTaxesAsync = createAsyncThunk(
    "tax/batch-delete",
    async (taxes, { rejectWithValue }) => {
        try {
            const response = await deleteTaxes(taxes);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const importTaxesAsync = createAsyncThunk(
	"tax/import",
	async (taxes, { rejectWithValue }) => {
		try {
			const response = await importTaxes(taxes);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const createDiscountAsync = createAsyncThunk(
	"discount/create",
	async (discount, { rejectWithValue }) => {
		try {
			const response = await createDiscount(discount);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

export const updateDiscountAsync = createAsyncThunk(
    "discount/update",
    async (discount, { rejectWithValue }) => {
        try {
            const response = await updateDiscount(discount);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const fetchAllDiscountsAsync = createAsyncThunk(
    "discounts/fetch",
    async (query, { rejectWithValue }) => {
        try {
            const response = await fetchAllDiscounts(query);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const deleteDiscountsAsync = createAsyncThunk(
    "discount/batch-delete",
    async (discounts, { rejectWithValue }) => {
        try {
            const response = await deleteDiscounts(discounts);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.data);
        }
    }
);

export const importDiscountsAsync = createAsyncThunk(
	"discount/import",
	async (discounts, { rejectWithValue }) => {
		try {
			const response = await importDiscounts(discounts);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.data);
		}
	}
);

const resetAuthState = (state, isLogOutAction) => {
	let isSessionActive = state.is_session_active;
	state.is_session_active = false;
	state.user = null;
	state.token = null;
	state.notifier = false;
	state.is_sidebar_collapsed = true;
	state.active_company = null;
	state.selected_application = null;
	state.selected_module = null;
	state.navigation = [];
	state.status = resources.status.IDLE;
	state.locale = resources.locale.ENGLISH;
	state.enums = [];
	state.applications = [];
	state.companies = [];
	state.user_department_ids = [];
	state.user_branch_ids = [];
	state.user_project_ids = [];
	state.roles = [];
	state.document_types = [];
	state.application_user = [];
	state.role_user = [];
	state.item_unit_of_measure_conversion = [];
	state.location_tree = [];
	state.branch_user = [];
	state.department_user = [];
	state.project_user = [];
	state.item_class_location = [];
	state.finished_good_produced_stock_acceptance = [];
	state.general_settings = null;
	state.approval_pipelines = [];
	state.subscriptions = [];
	state.accounting_calendars = [];
	state.dashboard.on_hand_and_sold_finished_goods = null;
	state.dashboard.on_hand_finished_goods_by_source = null;
	state.worksheet = {
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: resources.default_dataset
	};
	localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
	if (isSessionActive && !isLogOutAction) {
		toast.error(
			resources.lang[state.locale].message.SESSION_EXPIRED,
			Util.getToasterStyle(resources.status.ERROR)
		);
	}
}

const handleRejectedState = (state, action) => {
	if (action.payload) {
		if (action.type!=="auth/login/rejected" && (action.payload.code===401||(action.payload.message && action.payload.message==="Unauthenticated."))) {
			resetAuthState(state, false);
		}
		else {
			
			toast.error(
				action.payload.message,
				Util.getToasterStyle(resources.status.ERROR)
			);
		}
	}
}

export const authSlice = createSlice({
	name: resources.slice.AUTH,
	initialState,
	reducers: {
		resetWorksheetMode: (state) => {
			state.worksheet.is_readonly_mode = true;
			state.worksheet.num_frozen_columns = 0;
			state.worksheet.header = [];
			state.worksheet.data_set = [];
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		updateDashboardData: (state, action) => {
			if (action.payload.on_hand_inventories) {
				state.dashboard.on_hand_inventories = action.payload.on_hand_inventories;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			}
			if (action.payload.fast_moving_inventories) {
				state.dashboard.fast_moving_inventories = action.payload.fast_moving_inventories;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			}
			if (action.payload.near_stockout_skus) {
				state.dashboard.near_stockout_skus = action.payload.near_stockout_skus;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			}
			if (action.payload.zero_stock_inventories) {
				state.dashboard.zero_stock_inventories = action.payload.zero_stock_inventories;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			}
			if (action.payload.on_hand_and_sold_finished_goods) {
				state.dashboard.on_hand_and_sold_finished_goods = action.payload.on_hand_and_sold_finished_goods;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			}
			if (action.payload.inventory_value_by_item_class) {
				state.dashboard.inventory_value_by_item_class = action.payload.inventory_value_by_item_class;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			}
			if (action.payload.on_hand_finished_goods_by_source) {
				state.dashboard.on_hand_finished_goods_by_source = action.payload.on_hand_finished_goods_by_source;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			}
		},
		toggleSidebarCollapsed: (state) => {
			state.is_sidebar_collapsed = !state.is_sidebar_collapsed;
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		selectApplication: (state, action) => {
			state.selected_application = action.payload;
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		selectModule: (state, action) => {
			state.selected_module = action.payload;
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		updateApplicationUser: (state, action) => {
			state.application_user = [...state.application_user, action.payload];
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		updateNavigation: (state, action) => {
			state.navigation = action.payload;
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		updateCompanyGeneralSettings: (state, action) => {
			state.active_company.general_settings = action.payload.general_settings;
			state.general_settings = action.payload.general_settings;
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		updateJournalEntries: (state, action) => {
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		receivePusherData: (state, action) => {
			switch (action.payload.event) {
				case 'UserImportedEvent' : {
					let users = state.users;
					state.users = users.concat(action.payload.data.message);
					break;
				}
				default : break;
			}
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		setFocusedCell: (state, action) => {
			state.worksheet.focused_cell = action.payload;
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		setSelectedRegions: (state, action) => {
			state.worksheet.selected_regions = action.payload;
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		switchWorksheetMode: (state, action) => {
			state.worksheet.is_readonly_mode = !state.worksheet.is_readonly_mode;
			if (!state.worksheet.is_readonly_mode) {
				//window.location.reload();
				state.focused_cell = null;
			}
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		toggleFreezePane: (state) => {
			state.worksheet.num_frozen_columns = state.worksheet.num_frozen_columns===0 ? (state.worksheet.focused_cell!=null ? state.worksheet.focused_cell.col : 2) : 0;
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		clearCells: (state, action) => {
			if (!state.worksheet.is_readonly_mode) {
				state.worksheet.data_set = resources.default_dataset;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			}
		},
		clearSelectedCells: (state, action) => {
			if (!state.worksheet.is_readonly_mode) {
				if (state.worksheet.selected_regions!=null) {
					let dataSet = [];
					let selectedRegions = state.worksheet.selected_regions;
					for (let i=0; i<state.worksheet.data_set.length; i++) {
						let row = [];
						for (let j=0; j<state.worksheet.data_set[i].length; j++) {
							row.push(state.worksheet.data_set[i][j]);
						}
						dataSet.push(row);
					}
					for (let i=0; i<selectedRegions.length; i++) {
						let selectedRows = selectedRegions[i].rows;
						let selectedCols = selectedRegions[i].cols;
						let startRow = selectedRows[0];
						let endRow = selectedRows[1];
						let startCol = selectedCols[0];
						let endCol = selectedCols[1];
						for (let j=startRow; j<=endRow; j++) {
							if (dataSet[j]!==undefined) {
								for (let k=startCol; k<=endCol; k++) {
									if (dataSet[j][k]!==undefined) {
										dataSet[j][k] = "";
									}
								}
							}
						}
					}
					state.worksheet.data_set = dataSet;
				}
			}
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		initializeWorksheet: (state, action) => {
			const translate = resources["lang"][state.locale];
			state.worksheet.header = [];
			state.worksheet.alignment = [];
			state.worksheet.comment = [];
			state.worksheet.data_set = [];
			if (state.worksheet.is_readonly_mode) {
				switch (action.payload.function_title) {
					case translate.text.PERMISSION : {
						state.worksheet.header = translate.worksheet.default_header.PERMISSION;
						state.worksheet.alignment = translate.worksheet.default_alignment.PERMISSION;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((user) => [
							user.id,
							user.email,
							user.name,
							user.first_name,
							user.last_name,
							user.account_status,
							user.login_status,
							user.last_logged_out_at,
							`${user.permission_count} permission/s assigned`,
							user.permission_count>0 ? user.updated_at : ""
						]);
						break;
					}
					case translate.text.ASSIGN_AUTHORIZER : {
						state.worksheet.header = translate.worksheet.default_header.AUTHORIZER;
						state.worksheet.alignment = translate.worksheet.default_alignment.AUTHORIZER;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((amountRange) => [
							amountRange.document_type.name,
							state.enums.approval_setup.ENABLE_APPROVAL,
							`${amountRange.start_amount} to ${amountRange.end_amount}`,
							amountRange.authorizations[0],
							amountRange.authorizations[1],
							amountRange.authorizations[2],
							amountRange.authorizations[3],
							amountRange.authorizations[4],
						]);
						let disabledApprovalDocumentTypes = state.document_types.filter((dt) => !dt.is_approval_enabled);
						if (disabledApprovalDocumentTypes.length>0) {
							state.worksheet.data_set = [...state.worksheet.data_set, ...disabledApprovalDocumentTypes.map((dt) => ([
								dt.name,
								state.enums.approval_setup.DISABLE_APPROVAL,
								'',
								'',
								'',
								'',
								'',
								'',
							]))];
						}
						break;
					}
					case translate.text.ASSIGN_MAKER : {
						state.worksheet.header = translate.worksheet.default_header.MAKER;
						state.worksheet.alignment = translate.worksheet.default_alignment.MAKER;
						state.worksheet.comment = [];
						state.worksheet.data_set = state.makers.map((maker) => [
							maker.id,
							maker.document_type_name,
							maker.user,
							maker.created_at,
							maker.updated_at,
						]);
						break;
					}
					case translate.text.UNIT_OF_MEASURE : {
						state.worksheet.header = translate.worksheet.default_header.UNIT_OF_MEASURE;
						state.worksheet.alignment = translate.worksheet.default_alignment.UNIT_OF_MEASURE;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((umc) => [
							umc.id,
							umc.code,
							umc.description,
							umc.source_quantity,
							umc.source_unit_of_measure.code,
							umc.convert_quantity,
							umc.convert_unit_of_measure.code,
							umc.created_at,
							umc.updated_at,
						]);
						break;
					}
					case translate.text.LOCATION : {
						state.worksheet.header = translate.worksheet.default_header.LOCATION;
						state.worksheet.alignment = translate.worksheet.default_alignment.LOCATION;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.locations.map((location) => [
							location.id,
							location.parent_location_code,
							location.location_type.name,
							location.code,
							location.name,
							location.created_at,
							location.updated_at,
						]);
						if (action.payload.update_tree) {
							state.location_tree = action.payload.data.location_tree;
						}
						break;
					}
					case translate.text.ITEM_CLASS : {
						state.worksheet.header = translate.worksheet.default_header.ITEM_CLASS;
						state.worksheet.alignment = translate.worksheet.default_alignment.ITEM_CLASS;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((itemClass) => [
							itemClass.id,
							itemClass.name,
							itemClass.location_names.join(', '),
							itemClass.for_delivery_receipt ? state.enums.boolean.TRUE : state.enums.boolean.FALSE,
							itemClass.created_at,
							itemClass.updated_at,
						]);
						break;
					}
					case translate.text.ITEM : {
						state.worksheet.header = translate.worksheet.default_header.ITEM;
						state.worksheet.alignment = translate.worksheet.default_alignment.ITEM;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((item) => [
							item.id,
							item.code,
							item.description,
							item.item_class.name,
							item.unit_of_measure.code,
							item.item_costing,
							item.inventory_source,
							item.part_number,
							item.custom_barcode_number,
							item.inventory_account&&item.inventory_account.code ? item.inventory_account.code : "",
							item.inventory_account&&item.inventory_account.description ? item.inventory_account.description : "",
							item.reorder_point,
							item.has_expiry ? 'yes' : 'no',
							item.with_components ? 'yes' : 'no',
							item.is_barcode_enabled ? 'yes' : 'no',
							item.is_qr_code_enabled ? 'yes' : 'no',
							item.with_serial_number ? 'yes' : 'no',
							item.created_at,
							item.updated_at,
						]);
						break;
					}
					case translate.text.FINISHED_GOODS_COMPONENT : {
						state.worksheet.header = translate.worksheet.default_header.FINISHED_GOOD_COMPONENT;
						state.worksheet.alignment = translate.worksheet.default_alignment.FINISHED_GOOD_COMPONENT;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((item) => [
							item.id,
							item.code,
							item.description,
							item.item_class.name,
							item.unit_of_measure.code,
							item.item_costing,
							item.inventory_source,
							item.has_expiry ? 'yes' : 'no',
							item.with_components ? 'yes' : 'no',
							item.is_barcode_enabled ? 'yes' : 'no',
							item.is_qr_code_enabled ? 'yes' : 'no',
							item.created_at,
							item.updated_at,
						]);
						break;
					}
					case translate.text.MANAGE_BARCODE_QR_CODE : {
						state.worksheet.header = translate.worksheet.default_header.MANAGE_BARCODE_QR_CODE;
						state.worksheet.alignment = translate.worksheet.default_alignment.MANAGE_BARCODE_QR_CODE;
						state.worksheet.comment = [];
						state.worksheet.data_set = [];
						break;
					}
					case translate.text.MANAGE_TRANSACTIONS : {
						state.worksheet.header = translate.worksheet.default_header.MANAGE_TRANSACTIONS;
						state.worksheet.alignment = translate.worksheet.default_alignment.MANAGE_TRANSACTIONS;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((ds) => [
							ds.id,
							ds.document_type_name,
							ds.document_number,
							ds.document_date,
							ds.source,
							ds.approval_status,
							ds.description,
							ds.created_by,
						]);
						break;
					}
					case translate.text.ORDERING_AND_APPOINTMENT_APP : {
						state.worksheet.header = translate.worksheet.default_header.ORDERING_AND_APPOINTMENT_APP;
						state.worksheet.alignment = translate.worksheet.default_alignment.ORDERING_AND_APPOINTMENT_APP;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((ds) => [
							ds.id,
							ds.email,
							ds.first_name,
							ds.last_name,
							ds.mobile_number,
							ds.created_at,
							ds.updated_at,
						]);
						break;
					}
					case translate.text.STOCK_REQUEST : {
						state.worksheet.header = translate.worksheet.default_header.STOCK_REQUEST;
						state.worksheet.alignment = translate.worksheet.default_alignment.STOCK_REQUEST;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((stockRequest) => [
							stockRequest.id,
							stockRequest.document_number,
							stockRequest.document_date,
							stockRequest.approval_status,
							stockRequest.reference_number,
							stockRequest.branch.code ? stockRequest.branch.code : (stockRequest.department.code ? stockRequest.department.code : (stockRequest.project.code ? stockRequest.project.code : null)),
							stockRequest.branch.name ? stockRequest.branch.name : (stockRequest.department.name ? stockRequest.department.name : (stockRequest.project.name ? stockRequest.project.name : null)),
							stockRequest.branch.name ? state.enums.profit_cost_center_types.BRANCH : (stockRequest.department.name ? state.enums.profit_cost_center_types.DEPARTMENT : (stockRequest.project.name ? state.enums.profit_cost_center_types.PROJECT : null)),
							stockRequest.description,
							stockRequest.created_at,
							stockRequest.updated_at,
						]);
						break;
					}
					case translate.text.STOCK_TRANSFER : {
						state.worksheet.header = translate.worksheet.default_header.STOCK_TRANSFER;
						state.worksheet.alignment = translate.worksheet.default_alignment.STOCK_TRANSFER;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((stockTransfer) => [
							stockTransfer.id,
							stockTransfer.document_number,
							stockTransfer.document_date,
							stockTransfer.approval_status,
							stockTransfer.reference_number,
							stockTransfer.description,
							stockTransfer.transfer_from!==null&&JSON.parse(stockTransfer.transfer_from)!==null ? JSON.parse(stockTransfer.transfer_from).label : "",
							stockTransfer.transfer_from!==null&&JSON.parse(stockTransfer.transfer_from)!==null ? JSON.parse(stockTransfer.transfer_from).type : "",
							stockTransfer.transfer_to!==null&&JSON.parse(stockTransfer.transfer_to)!==null ? JSON.parse(stockTransfer.transfer_to).label : "",
							stockTransfer.transfer_to!==null&&JSON.parse(stockTransfer.transfer_to)!==null ? JSON.parse(stockTransfer.transfer_to).type : "",
							stockTransfer.created_at,
							stockTransfer.updated_at,
						]);
						break;
					}
					case translate.text.STOCK_ACCEPTANCE : {
						state.worksheet.header = translate.worksheet.default_header.STOCK_ACCEPTANCE;
						state.worksheet.alignment = translate.worksheet.default_alignment.STOCK_ACCEPTANCE;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((stockAcceptance) => [
							stockAcceptance.id,
							stockAcceptance.document_number,
							stockAcceptance.document_date,
							stockAcceptance.approval_status,
							stockAcceptance.reference_number,
							stockAcceptance.source,
							stockAcceptance.stock_issuances.length>0 ? stockAcceptance.stock_issuances[0].source : "",
							stockAcceptance.description,
							stockAcceptance.transfer_to ? JSON.parse(stockAcceptance.transfer_to).label : "",
							stockAcceptance.transfer_to ? JSON.parse(stockAcceptance.transfer_to).type : "",
							stockAcceptance.created_at,
							stockAcceptance.updated_at,
						]);
						break;
					}
					case translate.text.IMPORTED_ITEMS_RECEIPT : {
						state.worksheet.header = translate.worksheet.default_header.IMPORTED_ITEMS_RECEIPT;
						state.worksheet.alignment = translate.worksheet.default_alignment.IMPORTED_ITEMS_RECEIPT;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((iir) => [
							iir.id,
							iir.document_number,
							iir.document_date,
							iir.approval_status,
							iir.reference_number,
							iir.source,
							iir.transfer_to ? JSON.parse(iir.transfer_to).label : "",
							iir.transfer_to ? JSON.parse(iir.transfer_to).type : "",
							iir.shipment_cost_per_invoice,
							iir.customs_duties,
							iir.value_added_tax,
							iir.brokerage_fee,
							iir.freight_and_insurance,
							iir.wharfage_and_arrastre,
							iir.documentary_stamps,
							iir.demurrage_and_storage,
							iir.other_charges,
							iir.total_import_cost,
							iir.total_inventory_cost,
							iir.description,
							iir.created_at,
							iir.updated_at,
						]);
						break;
					}
					case translate.text.STOCK_ADJUSTMENT : {
						state.worksheet.header = translate.worksheet.default_header.STOCK_ADJUSTMENT;
						state.worksheet.alignment = translate.worksheet.default_alignment.STOCK_ADJUSTMENT;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.filter((stockAdjustment) => (stockAdjustment.type!==state.enums.stock_adjustment_types.BEGINNING_BALANCE)).map((stockAdjustment) => [
							stockAdjustment.id,
							stockAdjustment.document_number,
							stockAdjustment.document_date,
							stockAdjustment.approval_status,
							stockAdjustment.reference_number,
							stockAdjustment.description,
							stockAdjustment.profit_cost_center ? JSON.parse(stockAdjustment.profit_cost_center).label : "",
							stockAdjustment.profit_cost_center ? JSON.parse(stockAdjustment.profit_cost_center).type : "",
							stockAdjustment.created_at,
							stockAdjustment.updated_at,
						]);
						break;
					}
					case translate.text.STOCK_ISSUANCE : {
						state.worksheet.header = translate.worksheet.default_header.STOCK_ISSUANCE;
						state.worksheet.alignment = translate.worksheet.default_alignment.STOCK_ISSUANCE;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((stockIssuance) => [
							stockIssuance.id,
							stockIssuance.document_number,
							stockIssuance.document_date,
							stockIssuance.approval_status,
							stockIssuance.reference_number,
							stockIssuance.source,
							stockIssuance.finished_good_item ? stockIssuance.finished_good_item.description : "",
							stockIssuance.description,
							stockIssuance.transfer_from!==null ? JSON.parse(stockIssuance.transfer_from).label : null,
							stockIssuance.transfer_from!==null ? JSON.parse(stockIssuance.transfer_from).type : null,
							stockIssuance.transfer_to!==null ? JSON.parse(stockIssuance.transfer_to).label : null,
							stockIssuance.transfer_to!==null ? JSON.parse(stockIssuance.transfer_to).type : null,
							stockIssuance.created_at,
							stockIssuance.updated_at,
						]);
						break;
					}
					case translate.text.FINISHED_GOOD_PRODUCED : {
						state.worksheet.header = translate.worksheet.default_header.FINISHED_GOOD_PRODUCED;
						state.worksheet.alignment = translate.worksheet.default_alignment.FINISHED_GOOD_PRODUCED;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((finishedGoodsProduced) => [
							finishedGoodsProduced.id,
							finishedGoodsProduced.document_number,
							finishedGoodsProduced.document_date,
							finishedGoodsProduced.approval_status,
							finishedGoodsProduced.reference_number,
							finishedGoodsProduced.finished_good_item ? finishedGoodsProduced.finished_good_item.description : "",
							finishedGoodsProduced.description,
							finishedGoodsProduced.created_at,
							finishedGoodsProduced.updated_at,
						]);
						break;
					}
					case translate.text.BEGINNING_BALANCE : {
						state.worksheet.header = translate.worksheet.default_header.BEGINNING_BALANCE;
						state.worksheet.alignment = translate.worksheet.default_alignment.BEGINNING_BALANCE;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.filter((stockAdjustment) => stockAdjustment.type===state.enums.stock_adjustment_types.BEGINNING_BALANCE).map((stockAdjustment) => [
							stockAdjustment.id,
							stockAdjustment.document_number,
							stockAdjustment.document_date,
							stockAdjustment.approval_status,
							stockAdjustment.reference_number,
							stockAdjustment.profit_cost_center ? JSON.parse(stockAdjustment.profit_cost_center).label : '',
							stockAdjustment.description,
							stockAdjustment.created_at,
							stockAdjustment.updated_at,
						]);
						break;
					}
					case translate.text.STOCK_COUNT : {
						state.worksheet.header = translate.worksheet.default_header.STOCK_COUNT;
						state.worksheet.alignment = translate.worksheet.default_alignment.STOCK_COUNT;
						state.worksheet.comment = [];
						state.worksheet.data_set = action.payload.data.map((stockCount) => [
							stockCount.id,
							stockCount.document_number,
							stockCount.document_date,
							stockCount.approval_status,
							stockCount.reference_number,
							stockCount.description,
							stockCount.created_at,
							stockCount.updated_at,
						]);
						break;
					}
					case translate.text.ITEM_HISTORY : {
						state.worksheet.header = translate.worksheet.default_header.ITEM_HISTORY;
						state.worksheet.alignment = translate.worksheet.default_alignment.ITEM_HISTORY;
						state.worksheet.comment = [];
						state.worksheet.data_set = [];
						break;
					}
					case translate.text.SALES : {
						state.worksheet.header = action.payload.summarized ? translate.worksheet.default_header.SALES_SUMMARIZED : translate.worksheet.default_header.SALES;
						state.worksheet.alignment = action.payload.summarized ? translate.worksheet.default_alignment.SALES_SUMMARIZED : translate.worksheet.default_alignment.SALES;
						state.worksheet.comment = [];
						state.worksheet.data_set = [];
						break;
					}
					case translate.text.STOCK_MOVEMENT : {
						state.worksheet.header = translate.worksheet.default_header.STOCK_MOVEMENT;
						state.worksheet.alignment = translate.worksheet.default_alignment.STOCK_MOVEMENT;
						state.worksheet.comment = [];
						state.worksheet.data_set = [];
						break;
					}
					case translate.text.STOCK_ON_HAND : {
						state.worksheet.header = translate.worksheet.default_header.STOCK_ON_HAND;
						state.worksheet.alignment = translate.worksheet.default_alignment.STOCK_ON_HAND;
						state.worksheet.comment = [];
						state.worksheet.data_set = [];
						break;
					}
					case translate.text.INVENTORY_ITEM_BY_STATUS : {
						state.worksheet.header = translate.worksheet.default_header.INVENTORY_ITEM_BY_STATUS;
						state.worksheet.alignment = translate.worksheet.default_alignment.INVENTORY_ITEM_BY_STATUS;
						state.worksheet.comment = [];
						state.worksheet.data_set = [];
						break;
					}
					case translate.text.ITEM_CONDITIONS : {
						state.worksheet.header = translate.worksheet.default_header.ITEM_CONDITIONS;
						state.worksheet.alignment = translate.worksheet.default_alignment.ITEM_CONDITIONS;
						state.worksheet.comment = [];
						state.worksheet.data_set = [];
						break;
					}
					case translate.text.DELIVERY_RECEIPT : {
						state.worksheet.header = action.payload.format===state.enums.dr_report_formats.SUMMARIZED ? translate.worksheet.default_header.DELIVERY_RECEIPT : translate.worksheet.default_header.DELIVERY_RECEIPT_DETAILED;
						state.worksheet.alignment = action.payload.format===state.enums.dr_report_formats.SUMMARIZED ? translate.worksheet.default_alignment.DELIVERY_RECEIPT : translate.worksheet.default_alignment.DELIVERY_RECEIPT_DETAILED;
						state.worksheet.comment = [];
						state.worksheet.data_set = [];
						break;
					}
					case translate.text.ISSUED_ITEMS_FOR_ACCEPTANCE : {
						state.worksheet.header = translate.worksheet.default_header.ISSUED_ITEMS_FOR_ACCEPTANCE;
						state.worksheet.alignment = translate.worksheet.default_alignment.ISSUED_ITEMS_FOR_ACCEPTANCE;
						state.worksheet.comment = [];
						state.worksheet.data_set = [];
						break;
					}
					default : break;
				}
			}
			else {
				switch (action.payload.function_title) {
					case translate.text.ASSIGN_MAKER : {
						state.worksheet.header = translate.worksheet.default_header.MAKER;
						state.worksheet.alignment = translate.worksheet.default_alignment.MAKER;
						state.worksheet.comment = [];
						state.worksheet.data_set = state.makers.map((maker) => [
							maker.id,
							maker.document_type_name,
							maker.user,
							maker.created_at,
							maker.updated_at,
						]);
						break;
					}
					case translate.text.UNIT_OF_MEASURE : {
						state.worksheet.header = translate.worksheet.import_header.UNIT_OF_MEASURE;
						state.worksheet.alignment = translate.worksheet.import_header.UNIT_OF_MEASURE;
						state.worksheet.comment = translate.worksheet.import_comment.UNIT_OF_MEASURE;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.LOCATION : {
						state.worksheet.header = translate.worksheet.import_header.LOCATION;
						state.worksheet.alignment = translate.worksheet.import_header.LOCATION;
						state.worksheet.comment = translate.worksheet.import_comment.LOCATION;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.ITEM_CLASS : {
						state.worksheet.header = translate.worksheet.import_header.ITEM_CLASS;
						state.worksheet.alignment = translate.worksheet.import_header.ITEM_CLASS;
						state.worksheet.comment = translate.worksheet.import_comment.ITEM_CLASS;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.ITEM : {
						state.worksheet.header = translate.worksheet.import_header.ITEM;
						state.worksheet.alignment = translate.worksheet.import_header.ITEM;
						state.worksheet.comment = translate.worksheet.import_comment.ITEM;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.FINISHED_GOODS_COMPONENT : {
						state.worksheet.header = translate.worksheet.import_header.FINISHED_GOOD_COMPONENT;
						state.worksheet.alignment = translate.worksheet.import_header.FINISHED_GOOD_COMPONENT;
						state.worksheet.comment = translate.worksheet.import_comment.FINISHED_GOOD_COMPONENT;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.MANAGE_BARCODE_QR_CODE : {
						state.worksheet.header = translate.worksheet.import_header.MANAGE_BARCODE_QR_CODE;
						state.worksheet.alignment = translate.worksheet.import_header.MANAGE_BARCODE_QR_CODE;
						state.worksheet.comment = translate.worksheet.import_comment.MANAGE_BARCODE_QR_CODE;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.ITEM_LOCATION_TRANSFER : {
						state.worksheet.header = translate.worksheet.import_header.ITEM_LOCATION_TRANSFER;
						state.worksheet.alignment = translate.worksheet.import_header.ITEM_LOCATION_TRANSFER;
						state.worksheet.comment = translate.worksheet.import_comment.ITEM_LOCATION_TRANSFER;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.STOCK_REQUEST : {
						state.worksheet.header = translate.worksheet.import_header.STOCK_REQUEST;
						state.worksheet.alignment = translate.worksheet.import_header.STOCK_REQUEST;
						state.worksheet.comment = translate.worksheet.import_comment.STOCK_REQUEST;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.STOCK_TRANSFER : {
						state.worksheet.header = translate.worksheet.import_header.STOCK_TRANSFER;
						state.worksheet.alignment = translate.worksheet.import_header.STOCK_TRANSFER;
						state.worksheet.comment = translate.worksheet.import_comment.STOCK_TRANSFER;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.STOCK_ACCEPTANCE : {
						state.worksheet.header = translate.worksheet.import_header.STOCK_ACCEPTANCE;
						state.worksheet.alignment = translate.worksheet.import_header.STOCK_ACCEPTANCE;
						state.worksheet.comment = translate.worksheet.import_comment.STOCK_ACCEPTANCE;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.IMPORTED_ITEMS_RECEIPT : {
						state.worksheet.header = translate.worksheet.import_header.IMPORTED_ITEMS_RECEIPT;
						state.worksheet.alignment = translate.worksheet.import_header.IMPORTED_ITEMS_RECEIPT;
						state.worksheet.comment = translate.worksheet.import_comment.IMPORTED_ITEMS_RECEIPT;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.STOCK_ISSUANCE : {
						if (action.payload.transaction_source===state.enums.transaction_sources.stock_issuance.INTERNAL_DELIVERY_RECEIPT||action.payload.transaction_source===state.enums.transaction_sources.stock_issuance.EXTERNAL_DELIVERY_RECEIPT) {
							state.worksheet.header = translate.worksheet.import_header.STOCK_ISSUANCE;
							state.worksheet.alignment = translate.worksheet.import_header.STOCK_ISSUANCE;
							state.worksheet.comment = translate.worksheet.import_comment.STOCK_ISSUANCE;
						}
						if (action.payload.transaction_source===state.enums.transaction_sources.stock_issuance.DIRECT_ISSUANCE) {
							state.worksheet.header = translate.worksheet.import_header.STOCK_ISSUANCE_DIRECT;
							state.worksheet.alignment = translate.worksheet.import_header.STOCK_ISSUANCE_DIRECT;
							state.worksheet.comment = translate.worksheet.import_comment.STOCK_ISSUANCE_DIRECT;
						}
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.FINISHED_GOOD_PRODUCED : {
						state.worksheet.header = translate.worksheet.import_header.FINISHED_GOOD_PRODUCED;
						state.worksheet.alignment = translate.worksheet.import_header.FINISHED_GOOD_PRODUCED;
						state.worksheet.comment = translate.worksheet.import_comment.FINISHED_GOOD_PRODUCED;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.STOCK_ADJUSTMENT : {
						state.worksheet.header = translate.worksheet.import_header.STOCK_ADJUSTMENT;
						state.worksheet.alignment = translate.worksheet.import_header.STOCK_ADJUSTMENT;
						state.worksheet.comment = translate.worksheet.import_comment.STOCK_ADJUSTMENT;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.BEGINNING_BALANCE : {
						state.worksheet.header = translate.worksheet.import_header.BEGINNING_BALANCE;
						state.worksheet.alignment = translate.worksheet.import_header.BEGINNING_BALANCE;
						state.worksheet.comment = translate.worksheet.import_comment.BEGINNING_BALANCE;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					case translate.text.STOCK_COUNT : {
						state.worksheet.header = translate.worksheet.import_header.STOCK_COUNT_IMPORT_SHEET;
						state.worksheet.alignment = translate.worksheet.import_alignment.STOCK_COUNT_IMPORT_SHEET;
						state.worksheet.comment = translate.worksheet.import_comment.STOCK_COUNT_IMPORT_SHEET;
						state.worksheet.data_set = resources.default_dataset;
						break;
					}
					default : break;
				}
			}
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		pasteFromClipboard: (state, action) => {
			if (!state.worksheet.is_readonly_mode) {
				if (state.worksheet.focused_cell!=null) {
					let offsetRow = state.worksheet.focused_cell.row;
					let offsetCol = state.worksheet.focused_cell.col;
					let rows = action.payload.clipboardData.replace(/[^a-zA-Z0-9 _.,/:;@*()-=+><?}{!&%#$/(\r\n\t|\r|\n|\t)/]/g,'').split("\n");
					let dataSet = state.worksheet.data_set;
					let rowSize = dataSet.length>offsetRow+rows.length ? dataSet.length : offsetRow+rows.length;
					let colSize = state.worksheet.header.length;
					let data = [];
					for (let i=0; i<rowSize; i++) {
						let row = [];
						for (let j=0; j<colSize; j++) {
							if (dataSet[i]!==undefined) {
								if (dataSet[j]!==undefined) {
									row.push(dataSet[i][j]);
								}
								else {
									row.push("");
								}
							}
							else {
								row.push("");
							}
						}
						data.push(row);
					}
					for (let i=offsetRow; i<offsetRow+rows.length; i++) {
						let row = rows[i-offsetRow];
						let cells = row.split("\t");
						for (let j=offsetCol; j<offsetCol+cells.length; j++) {
							let cell = cells[j-offsetCol];
							//let cleanedCell = Util.cleanCell(cell);
							data[i][j] = cell;
						}
					}
					state.worksheet.data_set = data;
				}
			}
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		confirmCell: (state, action) => {
			state.worksheet.data_set[action.payload.row][action.payload.col] = action.payload.value;
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		pushLocationTreeNode: (state, action) => {
			let node = action.payload.node;
			let path = action.payload.path;
			let target = path.reduce((prev, cur) => Array.isArray(prev) ? prev[cur] : prev.childNodes[cur], state.location_tree);
			let childNodes = target.childNodes;
			Object.assign(target, {isSelected:false});
			Object.assign(target, {isExpanded:true});
			Object.assign(target, {childNodes:[...childNodes, node]});
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		expandLocationTreeNode: (state, action) => {
			let path = action.payload.path;
			let target = path.reduce((prev, cur) => Array.isArray(prev) ? prev[cur] : prev.childNodes[cur], state.location_tree);
			Object.assign(target, {isExpanded:true});
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		collapseLocationTreeNode: (state, action) => {
			let path = action.payload.path;
			let target = path.reduce((prev, cur) => Array.isArray(prev) ? prev[cur] : prev.childNodes[cur], state.location_tree);
			Object.assign(target, {isExpanded:false});
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
		toggleSelectLocationTreeNode: (state, action) => {
			let path = action.payload.path;
			let target = path.reduce((prev, cur) => Array.isArray(prev) ? prev[cur] : prev.childNodes[cur], state.location_tree);
			for( let i=0; i<state.location_tree.length; i++ ) {
				resetLocationTreeIsSelectedState(state.location_tree[i]);
			}
			Object.assign(target, {isSelected:!target.isSelected});
			localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(detachAttachmentAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(detachAttachmentAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(detachAttachmentAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(postCommentAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(postCommentAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(postCommentAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(sendMessageAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(sendMessageAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(sendMessageAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateMessageStatusAsync.pending, (state) => {
			})
			.addCase(updateMessageStatusAsync.rejected, (state, action) => {
			})
			.addCase(updateMessageStatusAsync.fulfilled, (state, action) => {
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(updateMessagesStatusAsync.pending, (state) => {
			})
			.addCase(updateMessagesStatusAsync.rejected, (state, action) => {
			})
			.addCase(updateMessagesStatusAsync.fulfilled, (state, action) => {
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(deleteMessagesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(deleteMessagesAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
			})
			.addCase(deleteMessagesAsync.fulfilled, (state, action) => {
				state.notifier = !state.notifier;
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(transferDocumentsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(transferDocumentsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(transferDocumentsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				state.branch_user = action.payload.data.branch_user;
				state.department_user = action.payload.data.department_user;
				state.project_user = action.payload.data.project_user;
				state.application_user = action.payload.data.application_user;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(createImportedItemsReceiptAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createImportedItemsReceiptAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createImportedItemsReceiptAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateImportedItemsReceiptAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateImportedItemsReceiptAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateImportedItemsReceiptAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(submitImportedItemsReceiptAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(submitImportedItemsReceiptAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(submitImportedItemsReceiptAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(approveImportedItemsReceiptAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(approveImportedItemsReceiptAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(approveImportedItemsReceiptAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(rejectImportedItemsReceiptAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(rejectImportedItemsReceiptAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(rejectImportedItemsReceiptAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(importImportedItemsReceiptsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importImportedItemsReceiptsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importImportedItemsReceiptsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(loginAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(loginAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(loginAsync.fulfilled, (state, action) => {
				state.is_session_active = true;
				state.status = resources.status.IDLE;
				state.user = action.payload.data.user;
				state.token = action.payload.data.token;
				state.notifier = false;
				state.enums = action.payload.data.enums;
				state.applications = action.payload.data.applications;
				state.companies = action.payload.data.companies;
				state.active_company = state.companies.filter((company) => company.id===state.user.active_company_id)[0];
				state.selected_application =  action.payload.data.applications[0];
				updateSelectedApplication(state);
				state.user_department_ids = action.payload.data.user_department_ids;
				state.user_branch_ids = action.payload.data.user_branch_ids;
				state.user_project_ids = action.payload.data.user_project_ids;
				state.roles = action.payload.data.roles;
				state.document_types = action.payload.data.document_types;
				state.application_user = action.payload.data.application_user;
				state.role_user = action.payload.data.role_user;
				state.item_unit_of_measure_conversion = action.payload.data.item_unit_of_measure_conversion;
				state.branch_user = action.payload.data.branch_user;
				state.department_user = action.payload.data.department_user;
				state.project_user = action.payload.data.project_user;
				state.item_class_location = action.payload.data.item_class_location;
				state.finished_good_produced_stock_acceptance = action.payload.data.finished_good_produced_stock_acceptance;
				state.general_settings = action.payload.data.general_settings;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.subscriptions = action.payload.data.subscriptions;
				state.accounting_calendars = action.payload.data.accounting_calendars;
				state.location_tree = action.payload.data.location_tree;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(signupAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(signupAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(signupAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(resetPasswordAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(resetPasswordAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(resetPasswordAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(changePasswordAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(changePasswordAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(changePasswordAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(forgotPasswordAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(forgotPasswordAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(forgotPasswordAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(pendingVerificationAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(pendingVerificationAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(pendingVerificationAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(acceptInvitationAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(acceptInvitationAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(acceptInvitationAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(verifyAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(verifyAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(verifyAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(skipProcessAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(skipProcessAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(skipProcessAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				else {
					if (action.payload.data.applications) {
						state.active_company.application_id = null;
						state.applications = action.payload.data.applications;
						toast.success(
							action.payload.message,
							Util.getToasterStyle(resources.status.SUCCESS)
						);
					}
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(logoutAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(logoutAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(logoutAsync.fulfilled, (state) => {
				resetAuthState(state, true);
			})

			.addCase(switchCompanyAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(switchCompanyAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(switchCompanyAsync.fulfilled, (state, action) => {
				state.is_session_active = true;
				state.status = resources.status.IDLE;
				state.user = action.payload.data.user;
				state.notifier = false;
				state.enums = action.payload.data.enums;
				state.applications = action.payload.data.applications;
				state.companies = action.payload.data.companies;
				state.active_company = state.companies.filter((company) => company.id===state.user.active_company_id)[0];
				state.selected_application =  action.payload.data.applications[0];
				updateSelectedApplication(state);
				state.user_department_ids = action.payload.data.user_department_ids;
				state.user_branch_ids = action.payload.data.user_branch_ids;
				state.user_project_ids = action.payload.data.user_project_ids;
				state.roles = action.payload.data.roles;
				state.document_types = action.payload.data.document_types;
				state.application_user = action.payload.data.application_user;
				state.role_user = action.payload.data.role_user;
				state.item_unit_of_measure_conversion = action.payload.data.item_unit_of_measure_conversion;
				state.branch_user = action.payload.data.branch_user;
				state.department_user = action.payload.data.department_user;
				state.project_user = action.payload.data.project_user;
				state.item_class_location = action.payload.data.item_class_location;
				state.finished_good_produced_stock_acceptance = action.payload.data.finished_good_produced_stock_acceptance;
				state.general_settings = action.payload.data.general_settings;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.subscriptions = action.payload.data.subscriptions;
				state.accounting_calendars = action.payload.data.accounting_calendars;
				state.dashboard = {
					on_hand_inventories: null,
					fast_moving_inventories: null,
					near_stockout_skus: null,
					zero_stock_inventories: null,
					on_hand_and_sold_finished_goods: null,
					inventory_value_by_item_class: null,
					on_hand_finished_goods_by_source: null,
					item_conditions_percentage: null,
					item_conditions_percentage_colors: null,
					item_conditions_percentage_labels: null,
					item_conditions_percentage_item_classes: null,
				};
				state.worksheet = {
					is_readonly_mode: true,
					num_rows: 1000000,
					num_cols: 10,
					num_frozen_columns: 0,
					import_limit: 700,
					focused_cell: null,
					selected_regions: null,
					header: [],
					alignment: [],
					comment: [],
					data_set: resources.default_dataset,
				};
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			//system admin

            .addCase(createSubscriptionAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(createSubscriptionAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(createSubscriptionAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
                state.companies = [...state.companies, action.payload.data.company];
                state.subscriptions = [...state.subscriptions, action.payload.data.subscription];
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

			.addCase(updateSubscriptionAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateSubscriptionAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateSubscriptionAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

            .addCase(createUserAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(createUserAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(createUserAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
                state.role_user = action.payload.data.role_user;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(saveCashierShiftAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(saveCashierShiftAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(saveCashierShiftAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(savePosTerminalSettingsAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(savePosTerminalSettingsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(savePosTerminalSettingsAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				state.active_company = action.payload.data.company;
				state.companies = state.companies.map((cmp) => (cmp.id===action.payload.data.company.id ? action.payload.data.company : cmp));
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(createLaundryMachineSetAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(createLaundryMachineSetAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(createLaundryMachineSetAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(createPosTerminalAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(createPosTerminalAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(createPosTerminalAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(resetPosTerminalAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(resetPosTerminalAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(resetPosTerminalAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(cycleCountReconCountAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(cycleCountReconCountAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(cycleCountReconCountAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(forCycleCountReconAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(forCycleCountReconAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(forCycleCountReconAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(updateLaundryCycleCountAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(updateLaundryCycleCountAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(updateLaundryCycleCountAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(finalizeLaundryCycleCountAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(finalizeLaundryCycleCountAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(finalizeLaundryCycleCountAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(pullInventoryItemsAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(pullInventoryItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(pullInventoryItemsAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(removeInventoryItemsAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(removeInventoryItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(removeInventoryItemsAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(createServiceAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(createServiceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(createServiceAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(updateServiceAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(updateServiceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(updateServiceAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

			.addCase(updateUserAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateUserAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateUserAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.role_user = action.payload.data.role_user;
				state.application_user = action.payload.data.application_user;
				if (action.payload.data.user.roles.includes(state.enums.roles.SUBSCRIBER)) {
					state.user = action.payload.data.user;
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

            .addCase(deleteUserAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteUserAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(deleteUserAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(deleteUsersAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteUsersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.error(
					action.payload.message,
					Util.getToasterStyle(resources.status.ERROR)
				);
            })
            .addCase(deleteUsersAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
            })

            .addCase(assignOrderingAndAppointmentAppUsersAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(assignOrderingAndAppointmentAppUsersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(assignOrderingAndAppointmentAppUsersAsync.fulfilled, (state, action) => {
            	if (action.payload) {
					state.active_company = action.payload.data.company;
					toast.success(
						action.payload.message,
						Util.getToasterStyle(resources.status.SUCCESS)
					);
				}
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(payOrderingAndAppointmentAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(payOrderingAndAppointmentAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(payOrderingAndAppointmentAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(createProfitCostCenterAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(createProfitCostCenterAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(createProfitCostCenterAsync.fulfilled, (state, action) => {
            	if (action.payload) {
					if (action.payload.data.user_branch_ids) {
						state.user_branch_ids = action.payload.data.user_branch_ids;
						state.branch_user = action.payload.data.branch_user;
						state.active_company.branch_count = action.payload.data.branch_count;
					}
					if (action.payload.data.user_department_ids) {
						state.user_department_ids = action.payload.data.user_department_ids;
						state.department_user = action.payload.data.department_user;
						state.active_company.department_count = action.payload.data.department_count;
					}
					if (action.payload.data.user_project_ids) {
						state.user_project_ids = action.payload.data.user_project_ids;
						state.project_user = action.payload.data.project_user;
						state.active_company.project_count = action.payload.data.project_count;
					}
					if (action.payload.data.next_application) {
						state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
						state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
					}
					updateSelectedApplication(state);
					toast.success(
						action.payload.message,
						Util.getToasterStyle(resources.status.SUCCESS)
					);
				}
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(duplicateProfitCostCentersAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(duplicateProfitCostCentersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(duplicateProfitCostCentersAsync.fulfilled, (state, action) => {
				state.user_branch_ids = action.payload.data.user_branch_ids;
				state.branch_user = action.payload.data.branch_user;
				state.user_department_ids = action.payload.data.user_department_ids;
				state.department_user = action.payload.data.department_user;
				state.user_project_ids = action.payload.data.user_project_ids;
				state.project_user = action.payload.data.project_user;
				state.item_class_location = action.payload.data.item_class_location;
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(duplicateItemClassesAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(duplicateItemClassesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(duplicateItemClassesAsync.fulfilled, (state, action) => {
				state.item_class_location = action.payload.data.item_class_location;
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(duplicateItemsAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(duplicateItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(duplicateItemsAsync.fulfilled, (state, action) => {
				state.item_unit_of_measure_conversion = action.payload.data.item_unit_of_measure_conversion;
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

			.addCase(updateBranchAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateBranchAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateBranchAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.user_branch_ids = action.payload.data.user_branch_ids;
				state.branch_user = action.payload.data.branch_user;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateDepartmentAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateDepartmentAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateDepartmentAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
                state.user_department_ids = action.payload.data.user_department_ids;
                state.department_user = action.payload.data.department_user;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateProjectAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateProjectAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateProjectAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
                state.user_project_ids = action.payload.data.user_project_ids;
                state.project_user = action.payload.data.project_user;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

            .addCase(deleteBranchAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteBranchAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(deleteBranchAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				state.user_branch_ids = action.payload.data.user_branch_ids;
				state.branch_user = action.payload.data.branch_user;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(deleteDepartmentAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteDepartmentAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(deleteDepartmentAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
                state.user_department_ids = action.payload.data.user_department_ids;
                state.department_user = action.payload.data.department_user;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(deleteProjectAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteProjectAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(deleteProjectAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
                state.user_project_ids = action.payload.data.user_project_ids;
                state.project_user = action.payload.data.project_user;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(deleteProfitCostCentersAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteProfitCostCentersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.error(
					action.payload.message,
					Util.getToasterStyle(resources.status.ERROR)
				);
            })
            .addCase(deleteProfitCostCentersAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				state.user_branch_ids = action.payload.data.user_branch_ids;
				state.branch_user = action.payload.data.branch_user;
                state.user_department_ids = action.payload.data.user_department_ids;
                state.department_user = action.payload.data.department_user;
                state.user_project_ids = action.payload.data.user_project_ids;
                state.project_user = action.payload.data.project_user;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
            })

            .addCase(importProfitCostCentersAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(importProfitCostCentersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(importProfitCostCentersAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
            	if (action.payload.data.branches) {
	                state.user_branch_ids = action.payload.data.user_branch_ids;
	                state.branch_user = action.payload.data.branch_user;
            	}
            	if (action.payload.data.departments) {
	                state.user_department_ids = action.payload.data.user_department_ids;
	                state.department_user = action.payload.data.department_user;
            	}
            	if (action.payload.data.projects) {
	                state.user_project_ids = action.payload.data.user_project_ids;
	                state.project_user = action.payload.data.project_user;
            	}
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

			.addCase(importLocationsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importLocationsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importLocationsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

            .addCase(importUnitOfMeasureConversionsAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(importUnitOfMeasureConversionsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(importUnitOfMeasureConversionsAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(importItemClassesAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(importItemClassesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(importItemClassesAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(updatePermissionAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(updatePermissionAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(updatePermissionAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
                //state.users = action.payload.data.users;
                //state.makers = action.payload.data.makers;
                state.application_user = action.payload.data.application_user;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(assignAuthorizersAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(assignAuthorizersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(assignAuthorizersAsync.fulfilled, (state, action) => {
            	state.document_types = state.document_types.map((dt) => (dt.id===action.payload.data.document_type.id ? action.payload.data.document_type : dt));
                state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(unAssignAuthorizersAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(unAssignAuthorizersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(unAssignAuthorizersAsync.fulfilled, (state, action) => {
            	state.document_types = action.payload.data.document_types;
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(deleteAmountRangeAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteAmountRangeAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(deleteAmountRangeAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
                state.document_types = state.document_types.map((dt) => (dt.id===action.payload.data.document_type.id ? action.payload.data.document_type : dt));
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(createMakersAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(createMakersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(createMakersAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
                state.makers = action.payload.data.makers;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(deleteMakerAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteMakerAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(deleteMakerAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
                let makers = state.makers.filter((maker) => maker.id!==action.payload.data.id);
                state.makers = makers;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

			.addCase(createUnitOfMeasureAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createUnitOfMeasureAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createUnitOfMeasureAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(createUnitOfMeasureConversionAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createUnitOfMeasureConversionAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createUnitOfMeasureConversionAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateUnitOfMeasureConversionAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateUnitOfMeasureConversionAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateUnitOfMeasureConversionAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

            .addCase(deleteUnitOfMeasureConversionAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteUnitOfMeasureConversionAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(deleteUnitOfMeasureConversionAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(deleteUnitOfMeasureConversionsAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteUnitOfMeasureConversionsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.error(
					action.payload.message,
					Util.getToasterStyle(resources.status.ERROR)
				);
            })
            .addCase(deleteUnitOfMeasureConversionsAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
            })

            .addCase(deleteItemClassAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteItemClassAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(deleteItemClassAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })

            .addCase(deleteItemClassesAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteItemClassesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.error(
					action.payload.message,
					Util.getToasterStyle(resources.status.ERROR)
				);
            })
            .addCase(deleteItemClassesAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
            })

            .addCase(createItemAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(createItemAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(createItemAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				state.item_unit_of_measure_conversion = action.payload.data.item_unit_of_measure_conversion;
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

            .addCase(updateItemAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(updateItemAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
            })
            .addCase(updateItemAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				state.item_unit_of_measure_conversion = action.payload.data.item_unit_of_measure_conversion;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
                toast.success(
                    action.payload.message,
                    Util.getToasterStyle(resources.status.SUCCESS)
                );
            })

			.addCase(createItemClassAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createItemClassAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createItemClassAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.item_class_location = action.payload.data.item_class_location;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateItemClassAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateItemClassAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateItemClassAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.item_class_location = action.payload.data.item_class_location;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateItemImageAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateItemImageAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateItemImageAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(updateServiceImageAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateServiceImageAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateServiceImageAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(uploadCompanyLogoAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(uploadCompanyLogoAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(uploadCompanyLogoAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.active_company.logo_source = action.payload.data;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(deleteItemAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(deleteItemAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(deleteItemAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(deleteItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(deleteItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(deleteItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(deleteItemImageAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(deleteItemImageAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(deleteItemImageAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(createFinishedGoodComponentsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createFinishedGoodComponentsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createFinishedGoodComponentsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(importFinishedGoodComponentsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importFinishedGoodComponentsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importFinishedGoodComponentsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

            .addCase(deleteFinishedGoodComponentsAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteFinishedGoodComponentsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.error(
					action.payload.message,
					Util.getToasterStyle(resources.status.ERROR)
				);
            })
            .addCase(deleteFinishedGoodComponentsAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
            })

			.addCase(createPrintJobAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createPrintJobAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createPrintJobAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.print_jobs = [...state.print_jobs, action.payload.data];
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(deletePrintJobAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(deletePrintJobAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(deletePrintJobAsync.fulfilled, (state, action) => {
				state.print_jobs = state.print_jobs.filter((printJob) => (`${printJob.id}`!==`${action.payload.data}`));
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(importPrintJobsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importPrintJobsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importPrintJobsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				let print_jobs = state.print_jobs;
				print_jobs.concat(action.payload.data);
				state.print_jobs = print_jobs;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateItemSerialNumberCountAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateItemSerialNumberCountAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateItemSerialNumberCountAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(createLocationTypeAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createLocationTypeAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createLocationTypeAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateInventoryTrackingsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateInventoryTrackingsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateInventoryTrackingsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(createLocationAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createLocationAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createLocationAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateLocationAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateLocationAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateLocationAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(deleteLocationAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(deleteLocationAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(deleteLocationAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(deleteLocationsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(deleteLocationsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(deleteLocationsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.location_tree = action.payload.data.location_tree;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(createStockRequestAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createStockRequestAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createStockRequestAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateStockRequestAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateStockRequestAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateStockRequestAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(submitStockRequestAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(submitStockRequestAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(submitStockRequestAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(approveStockRequestAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(approveStockRequestAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(approveStockRequestAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(rejectStockRequestAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(rejectStockRequestAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(rejectStockRequestAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(voidStockRequestAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(voidStockRequestAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(voidStockRequestAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(createFinishedGoodProducedAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createFinishedGoodProducedAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createFinishedGoodProducedAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateFinishedGoodProducedAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateFinishedGoodProducedAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateFinishedGoodProducedAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(submitFinishedGoodProducedAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(submitFinishedGoodProducedAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(submitFinishedGoodProducedAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(createStockIssuanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createStockIssuanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createStockIssuanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateStockIssuanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateStockIssuanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateStockIssuanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(submitStockIssuanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(submitStockIssuanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(submitStockIssuanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(approveStockIssuanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(approveStockIssuanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(approveStockIssuanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(approveFinishedGoodProducedAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(approveFinishedGoodProducedAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(approveFinishedGoodProducedAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(rejectStockIssuanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(rejectStockIssuanceAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(rejectStockIssuanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(rejectFinishedGoodProducedAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(rejectFinishedGoodProducedAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(rejectFinishedGoodProducedAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(voidStockIssuanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(voidStockIssuanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(voidStockIssuanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(voidFinishedGoodProducedAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(voidFinishedGoodProducedAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(voidFinishedGoodProducedAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(firstInFirstOutAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(firstInFirstOutAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(firstInFirstOutAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(lastInFirstOutAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(lastInFirstOutAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(lastInFirstOutAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(firstOutFirstInAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(firstOutFirstInAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(firstOutFirstInAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(createStockAcceptanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createStockAcceptanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createStockAcceptanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateStockAcceptanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateStockAcceptanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateStockAcceptanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(submitStockAcceptanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(submitStockAcceptanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(submitStockAcceptanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(approveStockAcceptanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(approveStockAcceptanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(approveStockAcceptanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(rejectStockAcceptanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(rejectStockAcceptanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(rejectStockAcceptanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(voidStockAcceptanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(voidStockAcceptanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(voidStockAcceptanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(voidImportedItemsReceiptAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(voidImportedItemsReceiptAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(voidImportedItemsReceiptAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(importStockAcceptancesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importStockAcceptancesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importStockAcceptancesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(importStockIssuancesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importStockIssuancesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importStockIssuancesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(createStockAdjustmentAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createStockAdjustmentAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createStockAdjustmentAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateStockAdjustmentAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateStockAdjustmentAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateStockAdjustmentAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(submitStockAdjustmentAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(submitStockAdjustmentAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(submitStockAdjustmentAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(approveStockAdjustmentAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(approveStockAdjustmentAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(approveStockAdjustmentAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(rejectStockAdjustmentAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(rejectStockAdjustmentAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(rejectStockAdjustmentAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(voidStockAdjustmentAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(voidStockAdjustmentAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(voidStockAdjustmentAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(importStockAdjustmentsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importStockAdjustmentsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importStockAdjustmentsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(createItemLocationTransferAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createItemLocationTransferAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createItemLocationTransferAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateItemLocationTransferAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateItemLocationTransferAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateItemLocationTransferAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(submitItemLocationTransferAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(submitItemLocationTransferAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(submitItemLocationTransferAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(approveItemLocationTransferAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(approveItemLocationTransferAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(approveItemLocationTransferAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(rejectItemLocationTransferAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(rejectItemLocationTransferAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(rejectItemLocationTransferAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(voidItemLocationTransferAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(voidItemLocationTransferAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(voidItemLocationTransferAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(importItemLocationTransfersAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importItemLocationTransfersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importItemLocationTransfersAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(createStockTransferAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createStockTransferAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createStockTransferAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateStockTransferAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateStockTransferAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateStockTransferAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(submitStockTransferAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(submitStockTransferAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(submitStockTransferAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(approveStockTransferAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(approveStockTransferAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(approveStockTransferAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(rejectStockTransferAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(rejectStockTransferAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(rejectStockTransferAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})
			
			.addCase(updateGeneralSettingsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateGeneralSettingsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateGeneralSettingsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.active_company.id = action.payload.data.general_settings.company_id;
				state.active_company.code = action.payload.data.general_settings.company_code;
				state.active_company.name = action.payload.data.general_settings.company_name;
				state.active_company.general_settings = action.payload.data.general_settings;
				let company = state.companies.filter((cmp) => cmp.id===action.payload.data.general_settings.company_id)[0];
				company.code = action.payload.data.general_settings.company_code;
				company.name = action.payload.data.general_settings.company_name;
				state.companies = state.companies.map((cmp) => cmp.id===company.id ? company : cmp);
				state.general_settings = action.payload.data.general_settings;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(byPassStockCountScheduleAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(byPassStockCountScheduleAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(byPassStockCountScheduleAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.active_company.general_settings = action.payload.data;
				state.general_settings = action.payload.data;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(byPassYearEndClosingAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(byPassYearEndClosingAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(byPassYearEndClosingAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.active_company.general_settings = action.payload.data;
				state.general_settings = action.payload.data;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(createBeginningBalanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createBeginningBalanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(createBeginningBalanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				state.notifier = !state.notifier;
				/*state.active_company.application_id = null;
				state.applications = action.payload.data.applications;
				state.notifier = !state.notifier;
				updateSelectedApplication(state);*/
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateBeginningBalanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateBeginningBalanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateBeginningBalanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.active_company.application_id = null;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(submitBeginningBalanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(submitBeginningBalanceAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(submitBeginningBalanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.active_company.application_id = null;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(approveBeginningBalanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(approveBeginningBalanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(approveBeginningBalanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(rejectBeginningBalanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(rejectBeginningBalanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(rejectBeginningBalanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.approval_pipelines = action.payload.data.approval_pipelines;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(voidBeginningBalanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(voidBeginningBalanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(voidBeginningBalanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(importItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(importUsersAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importUsersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importUsersAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
                state.role_user = action.payload.data.role_user;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(importStockRequestsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importStockRequestsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importStockRequestsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateStockCountAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateStockCountAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(updateStockCountAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(submitStockCountAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(submitStockCountAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(submitStockCountAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(approveStockCountAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(approveStockCountAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(approveStockCountAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(rejectStockCountAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(rejectStockCountAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(rejectStockCountAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(importStockCountsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importStockCountsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importStockCountsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(voidStockCountAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(voidStockCountAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(voidStockCountAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.stock_counts = state.stock_counts.filter((stockCount) => (stockCount.id!==action.payload.data.stock_count.id));
				state.stock_count_line_items = state.stock_count_line_items.filter((stockCountLineItem) => (stockCountLineItem.stock_count_id!==action.payload.data.stock_count.id));
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(importStockTransfersAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importStockTransfersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importStockTransfersAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(fetchInventoryValueByItemClassAsync.pending, (state) => {
				state.status = resources.status.SILENT_LOADING;
			})
			.addCase(fetchInventoryValueByItemClassAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchInventoryValueByItemClassAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchItemConditionsPercentageAsync.pending, (state) => {
				state.status = resources.status.SILENT_LOADING;
			})
			.addCase(fetchItemConditionsPercentageAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchItemConditionsPercentageAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.result) {
					state.dashboard.item_conditions_percentage = action.payload.data.result;
					state.dashboard.item_conditions_percentage_colors = action.payload.data.colors;
					state.dashboard.item_conditions_percentage_labels = action.payload.data.labels;
					state.dashboard.item_conditions_percentage_item_classes = action.payload.data.item_classes.map((ic) => ({label:ic.name, value:ic.id}));
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchSerialNumbersAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchSerialNumbersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchSerialNumbersAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchExpiryDatesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchExpiryDatesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchExpiryDatesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllUsersAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllUsersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllUsersAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllCustomersAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllCustomersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllCustomersAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllOrdersAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllOrdersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllOrdersAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.user = action.payload.data.user;
				state.companies = action.payload.data.companies;
				state.active_company = state.companies.filter((company) => company.id===state.user.active_company_id)[0];
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchOrderAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchOrderAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchOrderAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(updateOrderAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateOrderAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(updateOrderAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(fetchAverageCostMethodAsync.pending, (state) => {
			})
			.addCase(fetchAverageCostMethodAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAverageCostMethodAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
			})

			.addCase(fetchUserCompaniesAsync.pending, (state) => {
			})
			.addCase(fetchUserCompaniesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchUserCompaniesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.companies = action.payload.data.companies;
			})

			.addCase(fetchAllDocumentsAsync.pending, (state) => {
				//state.status = resources.status.LOADING;
			})
			.addCase(fetchAllDocumentsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllDocumentsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchDocumentsCountAsync.pending, (state) => {
			})
			.addCase(fetchDocumentsCountAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchDocumentsCountAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllFinishedGoodProducedLineItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllFinishedGoodProducedLineItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllFinishedGoodProducedLineItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockAdjustmentLineItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllStockAdjustmentLineItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockAdjustmentLineItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockRequestLineItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllStockRequestLineItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockRequestLineItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockCountLineItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllStockCountLineItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockCountLineItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockTransferLineItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllStockTransferLineItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockTransferLineItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockAcceptanceLineItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllStockAcceptanceLineItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockAcceptanceLineItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllImportedItemsReceiptLineItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllImportedItemsReceiptLineItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllImportedItemsReceiptLineItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllItemLocationTransferLineItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllItemLocationTransferLineItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllItemLocationTransferLineItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockIssuanceLineItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllStockIssuanceLineItemsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockIssuanceLineItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllProfitCostCentersAsync.pending, (state) => {
				//state.status = resources.status.LOADING;
			})
			.addCase(fetchAllProfitCostCentersAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllProfitCostCentersAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllLocationsAsync.pending, (state) => {
			})
			.addCase(fetchAllLocationsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllLocationsAsync.fulfilled, (state, action) => {
				state.location_tree = action.payload.data.location_tree;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllLocationTypesAsync.pending, (state) => {
			})
			.addCase(fetchAllLocationTypesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllLocationTypesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchLocationTreeAsync.pending, (state) => {
			})
			.addCase(fetchLocationTreeAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchLocationTreeAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllItemClassesAsync.pending, (state) => {
			})
			.addCase(fetchAllItemClassesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllItemClassesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.item_class_location = action.payload.data.item_class_location;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchItemAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchItemAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchItemAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchDiscountAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchDiscountAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchDiscountAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllItemsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllItemsAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllItemsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.item_unit_of_measure_conversion = action.payload.data.item_unit_of_measure_conversion;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllFinishedGoodComponentsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllFinishedGoodComponentsAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllFinishedGoodComponentsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllUnitOfMeasureConversionsAsync.pending, (state) => {
			})
			.addCase(fetchAllUnitOfMeasureConversionsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllUnitOfMeasureConversionsAsync.fulfilled, (state, action) => {
				state.item_unit_of_measure_conversion = action.payload.data.item_unit_of_measure_conversion;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllUnitOfMeasuresAsync.pending, (state) => {
			})
			.addCase(fetchAllUnitOfMeasuresAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllUnitOfMeasuresAsync.fulfilled, (state, action) => {
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockRequestsAsync.pending, (state) => {
			})
			.addCase(fetchAllStockRequestsAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockRequestsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockTransfersAsync.pending, (state) => {
			})
			.addCase(fetchAllStockTransfersAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockTransfersAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockAcceptancesAsync.pending, (state) => {
			})
			.addCase(fetchAllStockAcceptancesAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockAcceptancesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllImportedItemsReceiptsAsync.pending, (state) => {
			})
			.addCase(fetchAllImportedItemsReceiptsAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllImportedItemsReceiptsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllSubscriptionsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllSubscriptionsAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllSubscriptionsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchSubscriptionAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchSubscriptionAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchSubscriptionAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			
			.addCase(fetchAllCompaniesAsync.pending, (state) => {
			})
			.addCase(fetchAllCompaniesAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllCompaniesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllMessagesAsync.pending, (state) => {
			})
			.addCase(fetchAllMessagesAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllMessagesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllFinishedGoodProducesAsync.pending, (state) => {
			})
			.addCase(fetchAllFinishedGoodProducesAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllFinishedGoodProducesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllAmountRangesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllAmountRangesAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllAmountRangesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockIssuancesAsync.pending, (state) => {
			})
			.addCase(fetchAllStockIssuancesAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockIssuancesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockAdjustmentsAsync.pending, (state) => {
			})
			.addCase(fetchAllStockAdjustmentsAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockAdjustmentsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllItemLocationTransfersAsync.pending, (state) => {
			})
			.addCase(fetchAllItemLocationTransfersAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllItemLocationTransfersAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllStockCountsAsync.pending, (state) => {
			})
			.addCase(fetchAllStockCountsAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllStockCountsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchEndingInventoriesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchEndingInventoriesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchEndingInventoriesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchItemHistoriesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchItemHistoriesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchItemHistoriesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.worksheet.data_set = action.payload.data.item_histories.map((itemHistory) => [
					itemHistory.item_code,
					itemHistory.item_description,
					itemHistory.item_class,
					itemHistory.document_date,
					itemHistory.document_number,
					itemHistory.document_type,
					itemHistory.profit_cost_center,
					itemHistory.location,
					itemHistory.uom,
					itemHistory.quantity,
				]);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchEndOfDayAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchEndOfDayAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchEndOfDayAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchEndOfDayReportAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchEndOfDayReportAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchEndOfDayReportAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchSalesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchSalesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchSalesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.summarized) {
					state.worksheet.data_set = action.payload.data.receipts.map((rcpt) => [
						rcpt.reference_number,
						rcpt.branch_name,
						rcpt.customer_first_name && rcpt.customer_last_name ? `${rcpt.customer_first_name} ${rcpt.customer_last_name}` : '',
						rcpt.discount_name,
						rcpt.payment_status,
						rcpt.payment_method,
						rcpt.document_date,
						rcpt.amount_due,
						rcpt.amount_paid,
						rcpt.amount_change,
					]);
				}
				else {
					state.worksheet.data_set = action.payload.data.receipts.map((rcpt) => [
						rcpt.reference_number,
						rcpt.branch_name,
						rcpt.customer_first_name && rcpt.customer_last_name ? `${rcpt.customer_first_name} ${rcpt.customer_last_name}` : '',
						rcpt.discount_name,
						rcpt.payment_status,
						rcpt.document_date,
						rcpt.amount_due,
						rcpt.amount_paid,
						rcpt.amount_change,
						rcpt.item_description,
						rcpt.service_name,
						rcpt.quantity,
						rcpt.price,
						rcpt.amount,
					]);
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchStockCountSchedulesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchStockCountSchedulesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchStockCountSchedulesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllCountriesAsync.pending, (state) => {
			})
			.addCase(fetchAllCountriesAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllCountriesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchLaundryMachineSetsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchLaundryMachineSetsAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchLaundryMachineSetsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchPosTerminalsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchPosTerminalsAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchPosTerminalsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchStockMovementsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchStockMovementsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchStockMovementsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.worksheet.data_set = action.payload.data.stock_movements.map((stockMovement) => [
					stockMovement.item_code,
					stockMovement.item_description,
					stockMovement.item_class,
					stockMovement.document_date,
					stockMovement.document_number,
					stockMovement.document_type,
					stockMovement.profit_cost_center,
					stockMovement.location,
					stockMovement.uom,
					stockMovement.quantity,
					stockMovement.unit_cost,
					stockMovement.amount,
				]);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchStockOnHandAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchStockOnHandAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchStockOnHandAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.worksheet.data_set = action.payload.data.stock_on_hand.map((stockOnHand) => [
					stockOnHand.item_code,
					stockOnHand.item_description,
					stockOnHand.item_class,
					stockOnHand.profit_cost_center,
					stockOnHand.location,
					stockOnHand.uom,
					stockOnHand.quantity,
					stockOnHand.amount,
					stockOnHand.average_unit_cost,
				]);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchItemConditionsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchItemConditionsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchItemConditionsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.worksheet.data_set = action.payload.data.item_conditions.map((ic) => [
					ic.item_code,
					ic.item_description,
					ic.item_class,
					ic.serial_number,
					ic.expiry_date,
					ic.uom,
					ic.quantity,
					ic.item_condition,
					ic.location,
				]);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchInventoryItemByStatusAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchInventoryItemByStatusAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchInventoryItemByStatusAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.worksheet.data_set = action.payload.data.inventory_item_by_status.map((inventoryItemByStatus) => [
					inventoryItemByStatus.item_code,
					inventoryItemByStatus.item_description,
					inventoryItemByStatus.item_class,
					inventoryItemByStatus.uom,
					inventoryItemByStatus.on_hand_quantity,
					inventoryItemByStatus.on_hand_amount,
					inventoryItemByStatus.reorder_point,
					inventoryItemByStatus.item_status,
					inventoryItemByStatus.turnover_ranking>0 ? state.enums.turnover_status.FAST_MOVING : state.enums.turnover_status.SLOW_MOVING,
					inventoryItemByStatus.turnover_ranking===0 ? "" : inventoryItemByStatus.turnover_ranking,
				]);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchItemLedgerAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchItemLedgerAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchItemLedgerAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchDeliveryReceiptAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchDeliveryReceiptAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchDeliveryReceiptAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.format===state.enums.dr_report_formats.SUMMARIZED) {
					state.worksheet.data_set = action.payload.data.delivery_receipts.map((dr) => [
						dr.document_number,
						dr.document_date,
						dr.approval_status,
						dr.reference_number,
						dr.source,
						dr.description,
						dr.sold_by ? JSON.parse(dr.sold_by).label : "",
						dr.sold_by ? JSON.parse(dr.sold_by).type : "",
						dr.ship_to ,
						dr.authorization_amount
					]);
				}
				else {
					state.worksheet.data_set = action.payload.data.delivery_receipts.map((dr) => [
						dr.document_number,
						dr.document_date,
						dr.approval_status,
						dr.reference_number,
						dr.source,
						dr.description,
						dr.sold_by ? JSON.parse(dr.sold_by).label : "",
						dr.sold_by ? JSON.parse(dr.sold_by).type : "",
						dr.ship_to ,
						dr.authorization_amount,
						dr.item_code,
						dr.item_description,
						dr.uom,
						dr.quantity,
						dr.unit_cost,
						dr.amount,
						dr.location_name,
					]);
				}
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchIssuedItemsForAcceptanceAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchIssuedItemsForAcceptanceAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchIssuedItemsForAcceptanceAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.worksheet.data_set = action.payload.data.issued_items_for_acceptance.map((ii) => [
					ii.document_number,
					ii.document_date,
					ii.approval_status,
					ii.reference_number,
					ii.source,
					ii.description,
					ii.transfer_from ? JSON.parse(ii.transfer_from).label : "",
					ii.transfer_from ? JSON.parse(ii.transfer_from).type : "",
					ii.transfer_to ? JSON.parse(ii.transfer_to).label : "",
					ii.transfer_to ? JSON.parse(ii.transfer_to).type : "",
					ii.authorization_amount,
					ii.item_code,
					ii.item_description,
					ii.uom,
					ii.quantity,
					ii.accepted_quantity,
					ii.cancelled_quantity,
					ii.unit_cost,
					ii.amount,
					ii.location_name,
				]);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchStockCountSheetAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchStockCountSheetAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchStockCountSheetAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllBeginningBalancesAsync.pending, (state) => {
			})
			.addCase(fetchAllBeginningBalancesAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllBeginningBalancesAsync.fulfilled, (state, action) => {
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(importBeginningBalancesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importBeginningBalancesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importBeginningBalancesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				state.notifier = !state.notifier;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllTaxesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllTaxesAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllTaxesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(fetchAllDiscountsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(fetchAllDiscountsAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(fetchAllDiscountsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(createTaxAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createTaxAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(createTaxAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateTaxAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateTaxAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(updateTaxAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

            .addCase(deleteTaxesAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteTaxesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.error(
					action.payload.message,
					Util.getToasterStyle(resources.status.ERROR)
				);
            })
            .addCase(deleteTaxesAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
            })

			.addCase(importTaxesAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importTaxesAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importTaxesAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

			.addCase(createDiscountAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(createDiscountAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(createDiscountAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				if (action.payload.data.next_application) {
					state.active_company.application_id = action.payload.data.next_application ? action.payload.data.next_application.id : state.active_company.application_id;
					state.applications = state.applications.map((app) => (app.id===action.payload.data.next_application.id ? action.payload.data.next_application : app));
				}
				updateSelectedApplication(state);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

			.addCase(updateDiscountAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(updateDiscountAsync.rejected, (state, action) => {
				state.status = resources.status.IDLE;
				handleRejectedState(state, action);
			})
			.addCase(updateDiscountAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			})

            .addCase(deleteDiscountsAsync.pending, (state) => {
                state.status = resources.status.LOADING;
            })
            .addCase(deleteDiscountsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.error(
					action.payload.message,
					Util.getToasterStyle(resources.status.ERROR)
				);
            })
            .addCase(deleteDiscountsAsync.fulfilled, (state, action) => {
                state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
				toast.success(
					action.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
            })

			.addCase(importDiscountsAsync.pending, (state) => {
				state.status = resources.status.LOADING;
			})
			.addCase(importDiscountsAsync.rejected, (state, action) => {
                state.status = resources.status.IDLE;
				handleRejectedState(state, action);
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})
			.addCase(importDiscountsAsync.fulfilled, (state, action) => {
				state.status = resources.status.IDLE;
				localStorage.setItem(resources.slice.AUTH, JSON.stringify(state));
			})

            ;

	},
});

export const {
	toggleSidebarCollapsed,
	resetWorksheetMode,
	selectApplication,
	updateApplicationUser,
	selectModule,
	updateNavigation,
	receivePusherData,
	switchWorksheetMode,
	toggleFreezePane,
	initializeWorksheet,
	clearCells,
	clearSelectedCells,
	setFocusedCell,
	setSelectedRegions,
	pasteFromClipboard,
	pushLocationTreeNode,
	expandLocationTreeNode,
	collapseLocationTreeNode,
	toggleSelectLocationTreeNode,
	updateCompanyGeneralSettings,
	updateJournalEntries,
	confirmCell,
	updateDashboardData,
} = authSlice.actions;

export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
