import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changePasswordAsync, selectAuth, toggleSidebarCollapsed } from "./authSlice";
import { resources } from "../../resources/index";
import {
	Button,
	FormGroup,
	Icon,
	IconSize,
	InputGroup,
	Checkbox,
	Text
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { SideBar } from '../../SideBar';
import Util from '../../common/Util';
import { Note } from '../../common/Note';

export function ChangePassword() {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [old_password, setOldPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirm_password, setConfirmPassword] = useState("");
	const [is_password_visible, setIsPasswordVisible] = useState(false);

	const resetForm = () => {
		setOldPassword('');
		setPassword('');
		setConfirmPassword('');
	}

	const handleTogglePasswordVisibilityEvent = () => {
		setIsPasswordVisible(!is_password_visible);
	}

	return (

		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<div style={{visibility:"hidden"}}>
					<Note
						contents={[
						]}
					/>
				</div>

				{ Util.functionTitle(translate.text.CHANGE_PASSWORD) }
				<div className="function-sub-title"></div>

				<div style={{height:"100vh"}}>
					<div className="row">
						<div className="col-sm-12 col-md-4 col-lg-3 mt-5">
							<FormGroup
								className="input-form-group center"
								label={translate.label.OLD_PASSWORD}
								labelInfo={translate.label.REQUIRED}
							>
								<InputGroup
									leftIcon={
										<Icon
											icon="key"
											size={IconSize.SMALL}
											className="orange"
										/>
									}
									placeholder={translate.placeholder.ENTER_OLD_PASSWORD}
									type={is_password_visible ? "text" : "password"}
									large={true}
									round={true}
									value={old_password}
									onChange={(e) => setOldPassword(e.target.value)}
									autoComplete="off"
									maxLength={100}
								></InputGroup>
							</FormGroup>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 col-md-4 col-lg-3 mt-2">
							<FormGroup
								className="input-form-group center"
								label={translate.label.PASSWORD}
								labelInfo={translate.label.REQUIRED}
							>
								<InputGroup
									leftIcon={
										<Icon
											icon="key"
											size={IconSize.SMALL}
											className="orange"
										/>
									}
									placeholder={"New Password"}
									type={is_password_visible ? "text" : "password"}
									large={true}
									round={true}
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									autoComplete="off"
									maxLength={100}
								></InputGroup>
							</FormGroup>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 col-md-4 col-lg-3 mt-2">
							<FormGroup
								className="input-form-group center"
								label={translate.label.CONFIRM_PASSWORD}
								labelInfo={translate.label.REQUIRED}
							>
								<InputGroup
									leftIcon={
										<Icon
											icon="key"
											size={IconSize.SMALL}
											className="orange"
										/>
									}
									placeholder={translate.placeholder.ENTER_CONFIRM_NEW_PASSWORD}
									type={is_password_visible ? "text" : "password"}
									large={true}
									round={true}
									value={confirm_password}
									onChange={(e) => setConfirmPassword(e.target.value)}
									autoComplete="off"
									maxLength={100}
								></InputGroup>
							</FormGroup>
						</div>
					</div>

					<div className="row mb-5">
						<div className="col-sm-12 col-md-5 col-lg-3 center">
							<Text style={{color:"blue", fontWeight:"bold", fontSize:"10pt", marginLeft:"10px", textAlign:"left"}}>
								<div>Password must have at least 8 characters.</div>
								<div>Password must contain at least one of the special characters: @#$%^&+=</div>
							</Text>
						</div>
					</div>

					<div className="row">
						<div className="col-sm-12 col-md-12 col-lg-12 center mt-5">
							<Checkbox
								checked={ is_password_visible }
								large={false}
								inline={true}
								label={is_password_visible ? "Hide Password" : "Show Password"}
								onChange={e => handleTogglePasswordVisibilityEvent()}
							>
								<Icon className="ml-5 orange" size={20} icon={is_password_visible ? "eye-open" : "eye-off"}/>
							</Checkbox>
						</div>
					</div>

					<div className="row">
						<div className="col-sm-12 col-md-4 col-lg-3 mt-5">
							<Button
								disabled={auth.status === resources.status.LOADING}
								text={translate.button.CHANGE_PASSWORD}
								fill={true}
								large={true}
								onClick={() =>
									dispatch(
										changePasswordAsync({
											old_password: old_password,
											password: password,
											confirm_password: confirm_password,
										})
									).then((response) => response&&response.payload&&response.payload.status===resources.status.SUCCESS ? resetForm() : void(0))
								}
							/>
						</div>
					</div>

				</div>

			</div>
		</div>		

	);
}