import React, { useCallback, useEffect, useState } from 'react';
import PDF from '../../../common/PDF';
import Excel from '../../../common/Excel';
import Util from '../../../common/Util';
import { Note } from "../../../common/Note";
import { useDispatch, useSelector } from 'react-redux';
import {
	selectAuth,
	updateNavigation,
	initializeWorksheet,
	toggleSidebarCollapsed,
	fetchAllUsersAsync,
	fetchAllDocumentsAsync,
	transferDocumentsAsync,
} from '../../auth/authSlice';
import { resources } from '../../../resources/index';
import {
	Button,
	ButtonGroup,
	Card,
	Icon,
	Checkbox
} from '@blueprintjs/core';
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/table/lib/css/table.css';
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { ManageTransactionsSpreadsheet } from '../../../spreadsheets/ManageTransactionsSpreadsheet';

export function ManageTransactions() {

	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources['lang'][auth.locale];
	const [ users, setUsers ] = useState([]);
	const [ transfer_from, setTransferFrom ] = useState(null);
	const [ transfer_to, setTransferTo ] = useState(null);
	const [ actions ] = useState(Object.values(auth.enums.transfer_actions).map((action) => ({label:action, value:action})));
	const [ action, setAction ] = useState(null);
	const [ selected_rows, setSelectedRows ] = useState([]);
	const [ auto_assign_permission, setAutoAssignPermission ] = useState(false);
	const [ auto_replace_authorizer, setAutoReplaceAuthorizer ] = useState(false);
	const [ auto_add_authorizer, setAutoAddAuthorizer ] = useState(false);
	const [ is_confirm_transfer_dialog_open, setIsConfirmTransferDialogOpen ] = useState(false);
	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.MANAGE_TRANSACTIONS));
	}, [
		dispatch,
		translate.breadcrumb.MANAGE_TRANSACTIONS
	]);

	useEffect(() => {
		dispatch(fetchAllUsersAsync({account_status:auth.enums.account_status.VERIFIED})).then((response) => (response.payload.data ? setUsers(response.payload.data.users.map((user) => ({label:`${user.name}`, email:user.email, roles:user.roles, value:user.id}))) : void(0)));
	}, [
		dispatch,
		translate.text.MANAGE_TRANSACTIONS,
		auth.enums.account_status.VERIFIED
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (transfer_from || action) {
			const handleResponse = (response) => {
				if (response.payload.data) {
					dispatch(initializeWorksheet({function_title:translate.text.MANAGE_TRANSACTIONS, data:response.payload.data}));
					setSelectedRows([]);	
				}
			}
			dispatch(fetchAllDocumentsAsync({
				user:action && action.value===auth.enums.transfer_actions.TRANSFER_PENDING_DOCUMENTS_TO_NEW_AUTHORIZER ? "" : (transfer_from ? transfer_from.value : ""),
				authorizer:action && action.value===auth.enums.transfer_actions.TRANSFER_PENDING_DOCUMENTS_TO_NEW_MAKER ? "" : (transfer_from ? transfer_from.value : ""),
				action:action ? action.value : "",
			})).then((response) => handleResponse(response));
		}
	}, [dispatch, transfer_from, action, auth.enums.transfer_actions, translate.text.MANAGE_TRANSACTIONS]);

	const handleClearButtonClickedEvent = () => {
		setAction(null);
		setTransferFrom(null);
		setTransferTo(null);
		setSelectedRows([]);
		setAutoAssignPermission(false);
		setAutoReplaceAuthorizer(false);
	};

	const handleFreezePaneButtonClickedEvent = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const refreshData = () => {
		handleClearButtonClickedEvent();
		const handleResponse = (response) => {
			if (response.payload.data) {
				dispatch(initializeWorksheet({function_title:translate.text.MANAGE_TRANSACTIONS, data:response.payload.data}));
			}
		}
		dispatch(fetchAllDocumentsAsync({})).then((response) => handleResponse(response));
	}

	const handleEditButtonClickedEvent = async (row) => {
		let id = auth.worksheet.data_set[row][0];
		let documentType = auth.worksheet.data_set[row][1];
		switch (documentType) {
			case auth.enums.document_types.STOCK_TRANSFER : {
				history.push(`/warehouse-inventory/stock-transfer-request/${id}`);
				break;
			}
			case auth.enums.document_types.STOCK_REQUEST : {
				history.push(`/warehouse-inventory/stock-purchase-request/${id}`);
				break;
			}
			case auth.enums.document_types.ITEM_LOCATION_TRANSFER : {
				history.push(`/warehouse-inventory/item-location-transfer/${id}`);
				break;
			}
			case auth.enums.document_types.STOCK_ACCEPTANCE : {
				history.push(`/warehouse-inventory/stock-acceptance/${id}`);
				break;
			}
			case auth.enums.document_types.IMPORTED_ITEMS_RECEIPT : {
				history.push(`/warehouse-inventory/imported-items-receipt/${id}`);
				break;
			}
			case auth.enums.document_types.STOCK_ADJUSTMENT : {
				if (auth.worksheet.data_set[row][4]===auth.enums.stock_adjustment_types.BEGINNING_BALANCE) {
					history.push(`/warehouse-inventory/beginning-balance/${id}`);
				}
				else {
					history.push(`/warehouse-inventory/stock-adjustment/${id}`);
				}
				break;
			}
			case auth.enums.document_types.FINISHED_GOODS_PRODUCED : {
				history.push(`/warehouse-inventory/finished-goods-produced/${id}`);
				break;
			}
			case auth.enums.document_types.STOCK_ISSUANCE : {
				history.push(`/warehouse-inventory/stock-issuance/${id}`);
				break;
			}
			case auth.enums.document_types.STOCK_COUNT : {
				history.push(`/warehouse-inventory/stock-count/${id}`);
				break;
			}
			default : break;
		}
	};

	const handleSelectAllButtonClickedEvent = () => {
		setSelectedRows(selected_rows.length>0 ? [] : auth.worksheet.data_set.map((dataSet, key) => key));
	}

	const handleTransferDocumentsButtonClickedEvent = () => {
		let rows = selected_rows.map(
			(row) => auth.worksheet.data_set[row]
		);
		let document_ids = rows.map((row) => row[0]);
		let document_types = rows.map((row) => row[1]);
		dispatch(
			transferDocumentsAsync({
				action:action ? action.value : "",
				document_ids:document_ids,
				document_types:document_types,
				transfer_from:transfer_from ? transfer_from.value : "",
				transfer_to:transfer_to ? transfer_to.value : "",
				auto_assign_permission:action&&action.value===auth.enums.transfer_actions.TRANSFER_PENDING_DOCUMENTS_TO_NEW_MAKER ? auto_assign_permission : "",
				auto_replace_authorizer:action&&action.value===auth.enums.transfer_actions.TRANSFER_PENDING_DOCUMENTS_TO_NEW_AUTHORIZER ? auto_replace_authorizer : "",
				auto_add_authorizer:action&&action.value===auth.enums.transfer_actions.TRANSFER_PENDING_DOCUMENTS_TO_NEW_AUTHORIZER ? auto_add_authorizer : "",
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleTransferFromChangedEvent = (transferFrom) => {
		setTransferFrom(transferFrom);
		setTransferTo(null);
	}
	
	const handleActionChangedEvent = (action) => {
		setAction(action);
		setTransferFrom(null);
		setTransferTo(null);
	}

	const handleAutoReplaceAuthorizer = () => {
		let autoReplaceAuthorizer = !auto_replace_authorizer;
		setAutoReplaceAuthorizer(autoReplaceAuthorizer);
		if (autoReplaceAuthorizer) {
			setAutoAddAuthorizer(false);
		}
	}

	const handleAutoAddAuthorizer = () => {
		let autoAddAuthorizer = !auto_add_authorizer;
		setAutoAddAuthorizer(autoAddAuthorizer);
		if (autoAddAuthorizer) {
			setAutoReplaceAuthorizer(false);
		}
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SAVE_LABEL,
							value: translate.text.NOTE_SAVE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{ Util.functionTitle(translate.text.MANAGE_TRANSACTIONS) }
				<div className="function-sub-title">
				</div>

				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={translate.button.TRANSFER_DOCUMENTS+` (${selected_rows.length})`}
						className="function-button"
						disabled={auth.status === resources.status.LOADING||!(selected_rows.length>0 && action!==null && transfer_from!==null && transfer_to!==null)}
						icon={<Icon icon="arrow-right" className="orange"/>}
						onClick={e => setIsConfirmTransferDialogOpen(!is_confirm_transfer_dialog_open)}
					/>
					<Button
						text={translate.button.CLEAR}
						className="function-button"
						icon={<Icon icon="reset" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={handleClearButtonClickedEvent}
					/>
				</ButtonGroup>

				{
					auth.worksheet.is_readonly_mode &&
					<Card className="function-card">
						<div className="row">
							<div className="col-sm-12 col-md-3 col-lg-3 mb-5">
								<MuiAutocomplete
									setSelectedValue={handleActionChangedEvent}
									selected_value={action}
									isMultiple={false}
									label={"Select action"}
									data={actions}
								/>
							</div>
							<div className="col-sm-12 col-md-3 col-lg-2 mb-5">
								<MuiAutocomplete
									setSelectedValue={handleTransferFromChangedEvent}
									selected_value={transfer_from}
									isMultiple={false}
									label={"Transfer from"}
									data={action ? users.filter((usr) => (usr.roles.indexOf(action.value===auth.enums.transfer_actions.TRANSFER_PENDING_DOCUMENTS_TO_NEW_AUTHORIZER ? auth.enums.roles.AUTHORIZER : auth.enums.roles.MAKER)>=0)) : users}
									isDisabled={action===null}
								/>
							</div>
							<div className="col-sm-12 col-md-3 col-lg-2 mb-5">
								<MuiAutocomplete
									setSelectedValue={setTransferTo}
									selected_value={transfer_to}
									isMultiple={false}
									label={"Transfer to"}
									data={action && transfer_from ? users.filter((usr) => (usr.value!==transfer_from.value && usr.roles.indexOf(action.value===auth.enums.transfer_actions.TRANSFER_PENDING_DOCUMENTS_TO_NEW_AUTHORIZER ? auth.enums.roles.AUTHORIZER : auth.enums.roles.MAKER)>=0)) : users}
									isDisabled={transfer_from===null}
								/>
							</div>
						</div>
						{
							action && action.value===auth.enums.transfer_actions.TRANSFER_PENDING_DOCUMENTS_TO_NEW_MAKER &&
							<div className='row'>
								<Checkbox
									checked={auto_assign_permission}
									large={true}
									inline={false}
									label="Automatically assign the needed permission/s and profit cost center access to target user"
									onChange={(event) => setAutoAssignPermission(!auto_assign_permission)}
								>
								</Checkbox>
							</div>
						}
						{
							action && action.value===auth.enums.transfer_actions.TRANSFER_PENDING_DOCUMENTS_TO_NEW_AUTHORIZER &&
							<>
								<div className='row'>
									<div className='col-sm-12 col-md-9 col-lg-8'>
										<Checkbox
											checked={auto_replace_authorizer}
											large={true}
											inline={false}
											label="Automatically replace the old authorizer (transfer from) with new authorizer (transfer to) in the approval setup"
											onChange={(event) => handleAutoReplaceAuthorizer()}
										>
										</Checkbox>
									</div>
								</div>
								<div className='row'>
									<div className='col-sm-12 col-md-9 col-lg-8'>
										<Checkbox
											checked={auto_add_authorizer}
											large={true}
											inline={false}
											label={<span>Automatically add the new authorizer (transfer to) in the approval setup if <span className='bold blue'>NOT FOUND</span></span>}
											onChange={(event) => handleAutoAddAuthorizer()}
										>
										</Checkbox>
									</div>
								</div>
							</>
						}
					</Card>
				}

				<ButtonGroup className="button-group" minimal={false}>
					{
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => PDF.export(
										translate.worksheet.file_name.MANAGE_TRANSACTIONS,
										auth.worksheet.header,
										auth.worksheet.data_set,
										auth.active_company,
										"a3",
										"landscape",
										[],
										[50, 120, 100, 120, 120, 90, 60, 90, "*", 90]
									)
								}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => Excel.export(
										translate.worksheet.file_name.MANAGE_TRANSACTIONS,
										auth.worksheet.header,
										auth.worksheet.data_set,
										auth.active_company
									)
								}
							/>
							<Button
								text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon={<Icon icon="select" className="orange"/>}
								onClick={handleSelectAllButtonClickedEvent}
							/>
						</>
					}
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={() => handleFreezePaneButtonClickedEvent()}
						/>
					}
				</ButtonGroup>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_transfer_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmTransferDialogOpen}
					confirmAction={handleTransferDocumentsButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">TRANSFER</span> the selected document/s ?
						</span>
					}
				/>

				<ManageTransactionsSpreadsheet
					enable_select={true}
					enable_open={false}
					enable_edit={true}
					handleEditButtonClickedEvent={handleEditButtonClickedEvent}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
				/>

			</div>
		</div>
	);

}
