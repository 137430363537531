import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createUnitOfMeasureAsync,
	createUnitOfMeasureConversionAsync,
	updateUnitOfMeasureConversionAsync,
	importUnitOfMeasureConversionsAsync,
	toggleSidebarCollapsed,
	skipProcessAsync,
	deleteUnitOfMeasureConversionsAsync,
	fetchAllUnitOfMeasureConversionsAsync,
	fetchAllUnitOfMeasuresAsync
} from "../../auth/authSlice";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Switch,
	Icon,
	Card,
	InputGroup,
	IconSize,
	ControlGroup,
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { UnitOfMeasureSpreadsheet } from "../../../spreadsheets/UnitOfMeasureSpreadsheet";

export function UnitOfMeasure() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [current_application] = useState(auth.applications.find((app) => app.name===translate.text.UNIT_OF_MEASURE));
	const [id, setId] = useState(null);
	const [uom_conversion_code, setUomConversionCode] = useState("");
	const [uom_conversion_description, setUomConversionDescription] = useState("");
	const [source_unit_of_measure, setSourceUnitOfMeasure] = useState(null);
	const [source_quantity, setSourceQuantity] = useState("");
	const [convert_unit_of_measure, setConvertUnitOfMeasure] = useState(null);
	const [convert_quantity, setConvertQuantity] = useState("");
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);
	const [unit_of_measures, setUnitOfMeasures] = useState([]);
	const DEFAULT_HEADER = translate.worksheet.default_header.UNIT_OF_MEASURE;
	const DEFAULT_ALIGNMENT = translate.worksheet.default_alignment.UNIT_OF_MEASURE;
	const IMPORT_HEADER = translate.worksheet.import_header.UNIT_OF_MEASURE;
	const IMPORT_ALIGNMENT = translate.worksheet.import_alignment.UNIT_OF_MEASURE;
	const COMMENT = translate.worksheet.import_comment.UNIT_OF_MEASURE;
	const NUM_ROWS = 1000;
	const NUM_COLS = DEFAULT_HEADER.length;
	const IMPORT_LIMIT = 700;
	let DEFAULT_DATA_SET = new Array(NUM_ROWS).fill("").map(() => new Array(NUM_COLS).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: NUM_ROWS,
		num_cols: NUM_COLS,
		num_frozen_columns: 0,
		import_limit: IMPORT_LIMIT,
		focused_cell: null,
		selected_regions: null,
		header: DEFAULT_HEADER,
		alignment: DEFAULT_ALIGNMENT,
		comment: COMMENT,
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.UNIT_OF_MEASURE));
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllUnitOfMeasureConversionsAsync({})).then((response) => response.payload.data ? 
				setWorkSheet({
					is_readonly_mode: true,
					num_rows: NUM_ROWS>response.payload.data.unit_of_measure_conversions.length ? NUM_ROWS : response.payload.data.unit_of_measure_conversions.length,
					num_cols: DEFAULT_HEADER.length,
					num_frozen_columns: 0,
					import_limit: IMPORT_LIMIT,
					focused_cell: null,
					selected_regions: null,
					header: DEFAULT_HEADER,
					alignment: DEFAULT_ALIGNMENT,
					comment: [],
					data_set: response.payload.data.unit_of_measure_conversions.map((ds) => [
						ds.id,
						ds.code,
						ds.description,
						ds.source_quantity,
						ds.source_unit_of_measure.code,
						ds.convert_quantity,
						ds.convert_unit_of_measure.code,
						ds.created_at,
						ds.updated_at,
					])
				}) : void(0)
			);
			dispatch(fetchAllUnitOfMeasuresAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setUnitOfMeasures(response.payload.data.unit_of_measures.map((uom) => ({label:uom.code, value:uom.id}))) : void(0)));
		}
	}, [
		dispatch,
		translate.breadcrumb.UNIT_OF_MEASURE,
		DEFAULT_HEADER,
		DEFAULT_ALIGNMENT,
		worksheet.is_readonly_mode
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const onClearButtonClicked = () => {
		setId(null);
		setUomConversionCode("");
		setUomConversionDescription("");
		setSourceQuantity("");
		setConvertQuantity("");
		setSourceUnitOfMeasure(null);
		setConvertUnitOfMeasure(null);
		setSelectedRows([]);
	};

	const onSkipButtonClicked = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'unit-of-measure-conversion'})).then((response) => response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onDeleteRowsButtonClicked = () => {
		let ids = selected_rows.map(
			(row) => worksheet.data_set[row][0]
		);
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: worksheet.data_set.filter((ds) => (!ids.includes(ds[0])))
				});
				setSelectedRows([]);
			}
		}
		dispatch(deleteUnitOfMeasureConversionsAsync(ids)).then((response) => handleResponse(response));
	};

	const onClearCellsButtonClicked = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearSelectedCellsButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			if (worksheet.selected_regions!=null) {
				let dataSet = [];
				let selectedRegions = worksheet.selected_regions;
				for (let i=0; i<worksheet.data_set.length; i++) {
					let row = [];
					for (let j=0; j<worksheet.data_set[i].length; j++) {
						row.push(worksheet.data_set[i][j]);
					}
					dataSet.push(row);
				}
				for (let i=0; i<selectedRegions.length; i++) {
					let selectedRows = selectedRegions[i].rows;
					let selectedCols = selectedRegions[i].cols;
					let startRow = selectedRows[0];
					let endRow = selectedRows[1];
					let startCol = selectedCols[0];
					let endCol = selectedCols[1];
					for (let j=startRow; j<=endRow; j++) {
						if (dataSet[j]!==undefined) {
							for (let k=startCol; k<=endCol; k++) {
								if (dataSet[j][k]!==undefined) {
									dataSet[j][k] = "";
								}
							}
						}
					}
				}
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: dataSet
				});
			}
		}
	}

	const onImportButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			let dataSet = worksheet.data_set.filter((dataSet) => dataSet[0].length>0||dataSet[1].length>0||dataSet[2].length>0||dataSet[3].length>0||dataSet[4].length>0||dataSet[5].length>0);
			if (dataSet.length<=worksheet.import_limit) {
				const handleResponse = (i, length, size, status) => {
					if (status===resources.status.SUCCESS) {
						if (i===0) {
							onClearCellsButtonClicked();
							toast.success(translate.message.IMPORT_UOM_CONVERSION_QUEUED, Util.getToasterStyle(resources.status.SUCCESS));
						}
						if (i===length-1) {
							toast.success(translate.message.IMPORT_UOM_CONVERSION_FINISHED, Util.getToasterStyle(resources.status.SUCCESS));
						}
					}
				} 
				let size = 20;
				let chunks = Util.chunk(dataSet, size);
				let length = chunks.length;
				for (let i=0; i<chunks.length; i++) {
					dispatch(
						importUnitOfMeasureConversionsAsync(chunks[i])
					).then((response) => (handleResponse(i, length, size, response.payload.status)));
				}
			}
			else {
				toast.error(
					translate.message.IMPORT_LIMIT_REACHED,
					Util.getToasterStyle(resources.status.ERROR)
				);
			}
		}
	};

	const onEditButtonClicked = async (row) => {
		let sourceUnit = unit_of_measures.find((uom) => (uom.label===worksheet.data_set[row][4]));
		let convertUnit = unit_of_measures.find((uom) => (uom.label===worksheet.data_set[row][6]));
		let sourceQuantity = worksheet.data_set[row][3].replaceAll(",","");
		let convertQuantity = worksheet.data_set[row][5].replaceAll(",","");
		setId(worksheet.data_set[row][0]);
		setUomConversionCode(worksheet.data_set[row][1]);
		setUomConversionDescription(worksheet.data_set[row][2]);
		setSourceUnitOfMeasure(sourceUnit);
		setSourceQuantity(sourceQuantity);
		setConvertUnitOfMeasure(convertUnit);
		setConvertQuantity(convertQuantity);
		window.scrollTo(0,0);
	}

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const onCreateButtonClicked = (response) => {
		let umc = response.payload.data.unit_of_measure_conversion;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set: [
				[
					umc.id,
					umc.code,
					umc.description,
					umc.source_quantity,
					umc.source_unit_of_measure.code,
					umc.convert_quantity,
					umc.convert_unit_of_measure.code,
					umc.created_at,
					umc.updated_at,
				],
				...worksheet.data_set
			]
		});
		onClearButtonClicked();
	}

	const onUpdateButtonClicked = (response) => {
		let umc = response.payload.data.unit_of_measure_conversion;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set: worksheet.data_set.map((ds) => (ds[0]===umc.id ? [
				umc.id,
				umc.code,
				umc.description,
				umc.source_quantity,
				umc.source_unit_of_measure.code,
				umc.convert_quantity,
				umc.convert_unit_of_measure.code,
				umc.created_at,
				umc.updated_at,
			] : ds))
		});
	}

	const onSaveAsPDFButtonClicked = () => {
		PDF.export(
			translate.worksheet.file_name.UNIT_OF_MEASURE,
			worksheet.header,
			worksheet.data_set,
			auth.active_company,
			"legal",
			"landscape",
			[3, 5],
			[50, 90, "*", 90, 70, 90, 70, 90, 90]
		);
	}

	const onSaveAsXLSXButtonClicked = () => {
		Excel.export(
			translate.worksheet.file_name.UNIT_OF_MEASURE,
			worksheet.header,
			worksheet.data_set,
			auth.active_company
		);
	}

	const onSpreadSheetModeSwitchChanged = () => {
		let newMode = !worksheet.is_readonly_mode;
		setWorkSheet({
			is_readonly_mode: newMode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
						{
							label: translate.text.NOTE_INLINE_EDITING_FIELD_LABEL,
							value: translate.text.NOTE_INLINE_EDITING_FIELD_VALUE
						},
						{
							label: translate.text.NOTE_PASTING_DATA_LABEL,
							value: translate.text.NOTE_PASTING_DATA_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id && auth.active_company.application_id!==current_application.id &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSkipButtonClicked}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.UNIT_OF_MEASURE) }

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						<Button
							text={id==null ? translate.button.CREATE : translate.button.UPDATE}
							className="function-button"
							disabled={auth.status === resources.status.LOADING}
							icon={<Icon icon="floppy-disk" className="orange"/>}
							onClick={(e) =>
									id==null ? 
									dispatch(
										createUnitOfMeasureConversionAsync({
											uom_conversion_code: uom_conversion_code,
											uom_conversion_description: uom_conversion_description,
											source_quantity: source_quantity,
											convert_quantity: convert_quantity,
											source_unit_of_measure: source_unit_of_measure !== null ? source_unit_of_measure.value : null,
											convert_unit_of_measure: convert_unit_of_measure !== null ? convert_unit_of_measure.value : null,
										})
									).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? onCreateButtonClicked(response) : null)) :
									dispatch(
										updateUnitOfMeasureConversionAsync({
											id: id,
											uom_conversion_code: uom_conversion_code,
											uom_conversion_description: uom_conversion_description,
											source_quantity: source_quantity,
											convert_quantity: convert_quantity,
											source_unit_of_measure: source_unit_of_measure !== null ? source_unit_of_measure.value : null,
											convert_unit_of_measure: convert_unit_of_measure !== null ? convert_unit_of_measure.value : null,
										})
									).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? onUpdateButtonClicked(response) : null))
							}
						/>
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onClearButtonClicked}
						/>

					</ButtonGroup>
				}

				{
					worksheet.is_readonly_mode &&
					<Card className="function-card">
						<div className="row">
							<TextField
								className="col-sm-12 col-md-3 col-lg-3 text-field"
								size="small"
								variant="outlined"
								value={uom_conversion_code}
								onChange={(e) => setUomConversionCode(e.target.value)}
								label={translate.placeholder.ENTER_UOM_CONVERSION_CODE}
							/>
							<TextField
								className="col-sm-12 col-md-7 col-lg-4 text-field"
								size="small"
								variant="outlined"
								value={uom_conversion_description}
								onChange={(e) => setUomConversionDescription(e.target.value)}
								label={translate.placeholder.ENTER_UOM_CONVERSION_DESCRIPTION}
							/>
						</div>
						<div className="row">
							<div className="col-sm-12 col-md-4 col-lg-3">
								<ControlGroup
									fill={true}
									vertical={true}
								>
									<InputGroup
										leftIcon={
											<Icon
												icon="calculator"
												size={IconSize.SMALL}
												className="orange"
											/>
										}
										disabled={
											!(
												uom_conversion_code.length >
													0 ||
												uom_conversion_description.length >
													0
											)
										}
										style={{
											marginTop: "8px",
											marginBottom: "12px",
										}}
										large={true}
										placeholder={
											translate.placeholder
												.ENTER_QUANTITY
										}
										onChange={(e) =>
											setSourceQuantity(
												e.target.value
											)
										}
										value={source_quantity}
										type="number"
									/>
									<MuiAutocomplete
										isMultiple={false}
										isDisabled={false}
										isReadOnly={false}
										label={translate.placeholder.ADD_NEW_SELECT_UOM}
										data={unit_of_measures}
										createFunction={
											createUnitOfMeasureAsync
										}
										createParameters={{
											uom_code: null,
											uom_description: null,
										}}
										setSelectedValue={setSourceUnitOfMeasure}
										selected_value={source_unit_of_measure}
										setSelectedValues={null}
										selected_values={null}
										onInputChange={null}
										setData={setUnitOfMeasures}
									/>
								</ControlGroup>
							</div>
							<div className="col-sm-12 col-md-4 col-lg-3 center">
								<h5>{"Is Equivalent to :"}</h5>
							</div>
							<div className="col-sm-12 col-md-4 col-lg-3">
								<ControlGroup
									fill={true}
									vertical={true}
								>
									<InputGroup
										leftIcon={
											<Icon
												icon="calculator"
												size={IconSize.SMALL}
												className="orange"
											/>
										}
										disabled={
											!(
												uom_conversion_code.length >
													0 ||
												uom_conversion_description.length >
													0
											)
										}
										style={{
											marginTop: "8px",
											marginBottom: "12px",
										}}
										large={true}
										placeholder={
											translate.placeholder
												.ENTER_QUANTITY
										}
										onChange={(e) =>
											setConvertQuantity(
												e.target.value
											)
										}
										value={convert_quantity}
										type="number"
									/>
									<MuiAutocomplete
										isMultiple={false}
										isDisabled={false}
										isReadOnly={false}
										label={translate.placeholder.ADD_NEW_SELECT_UOM}
										data={unit_of_measures}
										createFunction={
											createUnitOfMeasureAsync
										}
										createParameters={{
											uom_code: null,
											uom_description: null,
										}}
										setSelectedValue={setConvertUnitOfMeasure}
										selected_value={convert_unit_of_measure}
										setSelectedValues={null}
										selected_values={null}
										onInputChange={null}
										setData={setUnitOfMeasures}
									/>
								</ControlGroup>
							</div>
						</div>
					</Card>
				}

				<ButtonGroup className="button-group" minimal={false}>
					<Switch
						className="switch-mode"
						checked={!worksheet.is_readonly_mode}
						disabled={auth.status === resources.status.LOADING}
						labelElement={<span className="switch-mode-label">{translate.label.SWITCH_MODE}</span>}
						innerLabelChecked={translate.label.IMPORT}
						innerLabel={translate.label.READ_ONLY}
						onChange={onSpreadSheetModeSwitchChanged}
					/>
					{
						worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsPDFButtonClicked}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsXLSXButtonClicked}
							/>
							<Button
								text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon={<Icon icon="select" className="orange"/>}
								onClick={e => setSelectedRows(selected_rows.length>0 ? [] : worksheet.data_set.map((dataSet, key) => key))}
							/>
							{
								selected_rows.length>0 &&
								<Button
									text={`${translate.button.DELETE_ROWS}  (${selected_rows.length})`}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="delete" className="orange"/>}
									onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
								/>
							}
						</>
					}
					{
						!worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.IMPORT}
								className="function-button"
								icon={<Icon icon="upload" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onImportButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_SELECTED_CELLS}
								className="function-button"
								icon={<Icon icon="clean" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearSelectedCellsButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_CELLS}
								className="function-button"
								icon={<Icon icon="graph-remove" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearCellsButtonClicked}
							/>
						</>
					}
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={() => onFreezePaneButtonClicked()}
						/>
					}
				</ButtonGroup>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={onDeleteRowsButtonClicked}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted, Unit of measure conversion/s will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain unit of measure conversion/s is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<UnitOfMeasureSpreadsheet
					enable_select={true}
					enable_open={false}
					enable_edit={true}
					onEditButtonClicked={onEditButtonClicked}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
				/>

			</div>
		</div>
	);
}
