import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import {
	selectAuth,
	updateNavigation,
	expandLocationTreeNode,
	collapseLocationTreeNode,
	toggleSelectLocationTreeNode,
	clearCells,
	createLocationTypeAsync,
	createLocationAsync,
	deleteLocationsAsync,
	importLocationsAsync,
	pushLocationTreeNode,
	toggleSidebarCollapsed,
	skipProcessAsync,
	fetchAllLocationTypesAsync,
	fetchAllLocationsAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	//Switch,
	//Alignment,
	Icon,
	Card,
	FormGroup,
	InputGroup,
	IconSize,
	Tree,
	Callout
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { LocationSpreadsheet } from "../../../spreadsheets/LocationSpreadsheet";

export function Location() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [current_application] = useState(auth.applications.find((app) => app.name===translate.text.LOCATION));
	const [id, setId] = useState(null);
	const [parent_location, setParentLocation] = useState(null);
	const [parent_location_path, setParentLocationPath] = useState(null);
	const [location_types, setLocationTypes] = useState([]);
	const [location_type, setLocationType] = useState(null);
	const [location_code, setLocationCode] = useState(null);
	const [location_description, setLocationDescription] = useState(null);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);
	const DEFAULT_HEADER = translate.worksheet.default_header.LOCATION;
	const DEFAULT_ALIGNMENT = translate.worksheet.default_alignment.LOCATION;
	const IMPORT_HEADER = translate.worksheet.import_header.LOCATION;
	const IMPORT_ALIGNMENT = translate.worksheet.import_alignment.LOCATION;
	const COMMENT = translate.worksheet.import_comment.LOCATION;
	const NUM_ROWS = 1000;
	const NUM_COLS = DEFAULT_HEADER.length;
	const IMPORT_LIMIT = 700;
	let DEFAULT_DATA_SET = new Array(NUM_ROWS).fill("").map(() => new Array(NUM_COLS).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: NUM_ROWS,
		num_cols: NUM_COLS,
		num_frozen_columns: 0,
		import_limit: IMPORT_LIMIT,
		focused_cell: null,
		selected_regions: null,
		header: DEFAULT_HEADER,
		alignment: DEFAULT_ALIGNMENT,
		comment: COMMENT,
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.LOCATION));
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllLocationsAsync({}))
			.then((response) => response.payload.data ? 
				setWorkSheet({
					is_readonly_mode: true,
					num_rows: NUM_ROWS>response.payload.data.locations.length ? NUM_ROWS : response.payload.data.locations.length,
					num_cols: DEFAULT_HEADER.length,
					num_frozen_columns: 0,
					import_limit: IMPORT_LIMIT,
					focused_cell: null,
					selected_regions: null,
					header: DEFAULT_HEADER,
					alignment: DEFAULT_ALIGNMENT,
					comment: [],
					data_set: response.payload.data.locations.map((ds) => [
						ds.id,
						ds.parent_location_code,
						ds.location_type.name,
						ds.code,
						ds.name,
						ds.created_at,
						ds.updated_at,
					])
				}) : void(0)
			);
			dispatch(fetchAllLocationTypesAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setLocationTypes(response.payload.data.location_types.map((locType) => ({label:locType.name, value:locType.id}))) : void(0)));
		}
	}, [
		dispatch,
		translate.breadcrumb.LOCATION,
		DEFAULT_ALIGNMENT,
		DEFAULT_HEADER,
		worksheet.is_readonly_mode
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const onClearButtonClicked = () => {
		setId(null);
		setLocationCode("");
		setLocationDescription("");
		setLocationType(null);
		setParentLocation(null);
		setParentLocationPath(null);
		setSelectedRows([]);
	};

	const onSkipButtonClicked = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'location'})).then((response) => response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onImportButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			let dataSet = worksheet.data_set.filter((dataSet) => dataSet[0].length>0||dataSet[1].length>0||dataSet[2].length>0||dataSet[3].length>0);
			if (dataSet.length<=worksheet.import_limit) {
				dispatch(
					importLocationsAsync(dataSet)
				).then((response) => (response.payload.status===resources.status.SUCCESS ? dispatch(clearCells()) : null));
			}
			else {
				toast.error(
					translate.message.IMPORT_LIMIT_REACHED,
					Util.getToasterStyle(resources.status.ERROR)
				);
			}
		}
	};

	const onEditButtonClicked = (row) => {
	}

	const onDeleteRowsButtonClicked = () => {
		let ids = selected_rows.map(
			(row) => worksheet.data_set[row][0]
		);
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: worksheet.data_set.filter((ds) => (!ids.includes(ds[0])))
				});
				setSelectedRows([]);
			}
		}
		dispatch(deleteLocationsAsync(ids)).then((response) => handleResponse(response));
	};

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const handleNodeExpandEvent = (node, path) => {
		dispatch(expandLocationTreeNode({node:node, path:path}));
	}

	const handleNodeCollapseEvent = (node, path) => {
		dispatch(collapseLocationTreeNode({node:node, path:path}));
	}

	const handleNodeClickEvent = (node, path) => {
		if (node.parent_location_id!==null) {
			onClearButtonClicked();
			dispatch(toggleSelectLocationTreeNode({node:node, path:path}));
			setParentLocation(node);
			setParentLocationPath(path);
		}
		else {
			if (node.isExpanded) {
				dispatch(collapseLocationTreeNode({node:node, path:path}));
			}
			else {
				dispatch(expandLocationTreeNode({node:node, path:path}));
			}
		}
	}

	const onCreateButtonClicked = () => {
		const handleCreateResponse = (response) => {
			let data = response.payload.data;
			let node = data.location;
			let path = data.parent_location_path;
			let location = response.payload.data.location;
			dispatch(pushLocationTreeNode({node:node, path:path}));
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: [...worksheet.data_set, [
					location.id,
					location.parent_location_code,
					location.location_type.name,
					location.code,
					location.name,
					location.created_at,
					location.updated_at,
				]]
			});
			onClearButtonClicked();
		}
		dispatch(
			createLocationAsync({
				location_code: location_code,
				location_description: location_description,
				parent_location: parent_location!==null ? parent_location.id : null,
				location_type: location_type!==null ? location_type.value : null,
				parent_location_path: parent_location_path,
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleCreateResponse(response) : null))
	}

	const onClearCellsButtonClicked = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearSelectedCellsButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			if (worksheet.selected_regions!=null) {
				let dataSet = [];
				let selectedRegions = worksheet.selected_regions;
				for (let i=0; i<worksheet.data_set.length; i++) {
					let row = [];
					for (let j=0; j<worksheet.data_set[i].length; j++) {
						row.push(worksheet.data_set[i][j]);
					}
					dataSet.push(row);
				}
				for (let i=0; i<selectedRegions.length; i++) {
					let selectedRows = selectedRegions[i].rows;
					let selectedCols = selectedRegions[i].cols;
					let startRow = selectedRows[0];
					let endRow = selectedRows[1];
					let startCol = selectedCols[0];
					let endCol = selectedCols[1];
					for (let j=startRow; j<=endRow; j++) {
						if (dataSet[j]!==undefined) {
							for (let k=startCol; k<=endCol; k++) {
								if (dataSet[j][k]!==undefined) {
									dataSet[j][k] = "";
								}
							}
						}
					}
				}
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: dataSet
				});
			}
		}
	}

	const onSaveAsPDFButtonClicked = () => {
		PDF.export(
			translate.worksheet.file_name.LOCATION,
			worksheet.header,
			worksheet.data_set,
			auth.active_company,
			"letter",
			"landscape",
			[],
			[50, 80, 90, 80, "*", 90, 90]
		);
	}

	const onSaveAsXLSXButtonClicked = () => {
		Excel.export(
			translate.worksheet.file_name.LOCATION,
			worksheet.header,
			worksheet.data_set,
			auth.active_company
		);
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>


				<Note
					contents={[
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id && auth.active_company.application_id!==current_application.id &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSkipButtonClicked}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.LOCATION) }

				{
					worksheet.is_readonly_mode && 
					<ButtonGroup className="button-group" minimal={false}>
						{
							parent_location!==null &&
							<>
								<Button
									text={id==null ? translate.button.CREATE : translate.button.UPDATE}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={onCreateButtonClicked}
								/>
							</>
						}
						{
							parent_location!==null &&
							<Button
								text={translate.button.CLEAR}
								className="function-button"
								icon={<Icon icon="reset" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onClearButtonClicked}
							/>
						}
					</ButtonGroup>
				}

				{
					worksheet.is_readonly_mode &&
					<Card className="function-card">
						<div className="row">
							<div className="col-sm-12 col-md-5 col-lg-5 mr-3" style={{height:"280px", overflowY:"scroll"}}>
								<div>
								{
									<div style={{textAlign:"left"}}>
									<Callout
										style={{background:"white"}}
										icon={<Icon className="orange" icon="info-sign"/>}
										title={
											id===null&&parent_location===null ?
												<div style={{fontSize:"12pt"}}>
													{translate.text.SELECT_PARENT_LOCATION}
												</div>
											:
											(
											id===null ?
											<div style={{fontSize:"12pt", marginBottom:"20px"}}>
												{translate.text.CREATE_SUBLOCATION}
												<span style={{color:"blue"}}>
												{parent_location.name}
												</span>
											</div> :
											<div style={{fontSize:"12pt"}}>
												{translate.text.CLICK_UPDATE_AFTER_EDITING}
											</div>
											)
										}
									>
										{
										(parent_location!==null||id!==null) &&
										<div style={{marginTop:"10px", height:"200px"}}>

											<MuiAutocomplete
												setSelectedValue={setLocationType}
												selected_value={location_type}
												isMultiple={false}
												label={translate.placeholder.ADD_NEW_SELECT_LOCATION_TYPE}
												data={location_types}
												createFunction={createLocationTypeAsync}
												createParameters={{
													name: null
												}}
												setData={setLocationType}
											/>

											<FormGroup style={{marginTop:"10px"}}>
												<InputGroup
													leftIcon={
														<Icon
															icon="tag"
															size={IconSize.SMALL}
															className="orange"
														/>
													}
													placeholder={
														translate.placeholder
															.ENTER_LOCATION_CODE
													}
													large={true}
													value={location_code}
													onChange={(e) =>
														setLocationCode(
															e.target.value
														)
													}
												/>
											</FormGroup>
											<FormGroup>
												<InputGroup
													leftIcon={
														<Icon
															icon="edit"
															size={IconSize.SMALL}
															className="orange"
														/>
													}
													placeholder={
														translate.placeholder.ENTER_LOCATION_DESCRIPTION
													}
													large={true}
													value={location_description}
													onChange={(e) =>
														setLocationDescription(
															e.target.value
														)
													}
												/>
											</FormGroup>
										</div>
										}
									</Callout>
									{
										id===null&&parent_location===null&&
										<Callout
											style={{marginTop:"10px", background:"white"}}
											icon={<Icon className="orange" icon="info-sign"/>}
											title={
												<>
													<div style={{fontSize:"12pt"}}>Location Group:</div>
													<div className="location-group-info">
														{
															location_types.map((locationType,key) => (
																<div key={key}>
																	{locationType.label}
																</div>
															))
														}
													</div>
												</>
											}
										>
										</Callout>
									}
									</div>
								}
								</div>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-6 location-tree" style={{height:"280px", overflowY:"scroll"}}>
								<div style={{fontSize:"12pt", marginLeft:"10px"}}>
									<h4>Location Tree:</h4>
								</div>
								<Tree
									contents={auth.location_tree}
									onNodeExpand={(node, path)=>handleNodeExpandEvent(node, path)}
									onNodeCollapse={(node, path)=>handleNodeCollapseEvent(node, path)}
									onNodeClick={(node, path)=>handleNodeClickEvent(node, path)}
								/>
							</div>

						</div>
					</Card>
				}

				<ButtonGroup className="button-group" minimal={false}>
					{/*
					<Switch
						className="switch-mode"
						checked={!worksheet.is_readonly_mode}
						disabled={auth.status === resources.status.LOADING}
						labelElement={translate.label.SWITCH_MODE}
						innerLabelChecked={translate.label.IMPORT}
						innerLabel={translate.label.READ_ONLY}
						alignIndicator={Alignment.BOTTOM}
						inline={true}
						onChange={e => dispatch(switchWorksheetMode())}
					/>
					*/}
					{
						worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsPDFButtonClicked}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsXLSXButtonClicked}
							/>
							<Button
								text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon={<Icon icon="select" className="orange"/>}
								onClick={e => setSelectedRows(selected_rows.length>0 ? [] : worksheet.data_set.map((dataSet, key) => key))}
							/>
							{
								selected_rows.length>0 &&
								<Button
									text={`${translate.button.DELETE_ROWS}  (${selected_rows.length})`}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="delete" className="orange"/>}
									onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
								/>
							}
						</>
					}
					{
						!worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.IMPORT}
								className="function-button"
								icon={<Icon icon="upload" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onImportButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_SELECTED_CELLS}
								className="function-button"
								icon={<Icon icon="clean" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearSelectedCellsButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_CELLS}
								className="function-button"
								icon={<Icon icon="graph-remove" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearCellsButtonClicked}
							/>
						</>
					}
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={() => onFreezePaneButtonClicked()}
						/>
					}
				</ButtonGroup>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={onDeleteRowsButtonClicked}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted, Item class/es already assigned in this location will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain location/s is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<LocationSpreadsheet
					enable_select={true}
					enable_open={false}
					enable_edit={false}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
					onEditButtonClicked={onEditButtonClicked}
					onClearButtonClicked={onClearButtonClicked}
				/>

			</div>
		</div>
	);
}
