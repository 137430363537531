import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { useParams } from "react-router";
import { MuiAutocomplete } from "../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createImportedItemsReceiptAsync,
	updateImportedItemsReceiptAsync,
	submitImportedItemsReceiptAsync,
	rejectImportedItemsReceiptAsync,
	approveImportedItemsReceiptAsync,
	voidImportedItemsReceiptAsync,
	fetchAllImportedItemsReceiptLineItemsAsync,
	toggleSidebarCollapsed,
	fetchAllProfitCostCentersAsync,
	fetchAllItemsAsync,
	fetchAllLocationsAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	ControlGroup,
	H3,
	H5,
	FormGroup,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { Attachment } from "../../../common/Attachment";
import { Comment } from "../../../common/Comment";
import { TransactionLog } from "../../../common/TransactionLog";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { DatatableDialog } from "../../../common/DatatableDialog";
import { ImportedItemsReceiptLineItem } from "./ImportedItemsReceiptLineItem";
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { ImportedItemsReceiptSpreadsheet } from "../../../spreadsheets/ImportedItemsReceiptSpreadsheet";

export function ImportedItemsReceipt() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const default_line_items = Array(12).fill({
		id:"",
		item:null,
		quantity:"",
		unit_cost:"",
		amount:"",
		landed_unit_cost:"",
		landed_amount:"",
		uom:null,
		to_location:null,
		serial_numbers:[],
		expiry_quantities:[],
		expiry_dates:[],
		item_conditions:[],
	});
	const default_journal_entries = Array(0).fill({
		account_code:"",
		account_description:"",
		profit_cost_center:null,
		debit_amount:"",
		credit_amount:"",
	});
	const { document_id } = useParams();
	const [id, setId] = useState(document_id ? document_id : null);
	const [selected_document_number, setSelectedDocumentNumber] = useState('');
	const [selected_document_ids, setSelectedDocumentIds] = useState([]);
	const [imported_items_receipt, setImportedItemsReceipt] = useState(null);
	const [line_items, setLineItems] = useState(default_line_items);
	const [document_date, setDocumentDate] = useState(imported_items_receipt ? moment(imported_items_receipt.document_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT) : moment(new Date()).format(resources.date_format.INPUT));
	const [reference_number, setReferenceNumber] = useState("");
	const [transfer_to, setTransferTo] = useState(null);
	const [transfer_from, setTransferFrom] = useState(null);
	const [transaction_description, setTransactionDescription] = useState("");
	const [transaction_logs, setTransactionLogs] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [transaction_sources] = useState(Object.values(auth.enums.transaction_sources.imported_items_receipt).map((transactionSource) => ({label:transactionSource, value:transactionSource})));
	const [transaction_source , setTransactionSource] = useState(null);
	const [profit_cost_centers, setProfitCostCenters] = useState([]);
	const [items, setItems] = useState([]);
	const [locations, setLocations] = useState([]);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_delete_dialog_open, setIsConfirmDeleteDialogOpen] = useState(false);
	const [is_confirm_submit_dialog_open, setIsConfirmSubmitDialogOpen] = useState(false);
	const [is_confirm_approve_dialog_open, setIsConfirmApproveDialogOpen] = useState(false);
	const [is_confirm_reject_dialog_open, setIsConfirmRejectDialogOpen] = useState(false);
	const [is_confirm_void_dialog_open, setIsConfirmVoidDialogOpen] = useState(false);
	const [is_datatable_open, setIsDatatableDialogOpen] = useState(false);
	const [journal_entries, setJournalEntries] = useState(default_journal_entries);
	const [balancing_account, setBalancingAccount] = useState("");
	const [shipment_cost_per_invoice, setShipmentCostPerInvoice] = useState("");
	const [freight_and_insurance, setFreightAndInsurance] = useState("");
	const [customs_duties, setCustomsDuties] = useState("");
	const [value_added_tax, setValueAddedTax] = useState("");
	const [documentary_stamps, setDocumentaryStamps] = useState("");
	const [demurrage_and_storage, setDemurrageAndStorage] = useState("");
	const [brokerage_fee, setBrokerageFee] = useState("");
	const [wharfage_and_arrastre, setWharfageAndArrastre] = useState("");
	const [other_charges, setOtherCharges] = useState("");
	const [total_import_cost, setTotalImportCost] = useState("");
	const [total_inventory_cost, setTotalInventoryCost] = useState("");
	const [is_editable, setIsEditable] = useState(true);
	const [is_authorizable, setIsAuthorizable] = useState(document_id ? auth.approval_pipelines.find((pipe) => pipe.imported_items_receipt_id===document_id)!==undefined : false);

	const [with_expiry_serialized, setHasExpirySerialized] = useState(false);
	const [with_expiry, setWithExpiry] = useState(false);
	const [serialized, setSerialized] = useState(false);

	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: translate.worksheet.default_header.IMPORTED_ITEMS_RECEIPT,
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const loadDocument = useCallback((importedItemsReceipt, importedItemsReceiptLineItems, journalEntries) => {
		let transactionSource = transaction_sources.filter((ts) => ts.value===importedItemsReceipt.source)[0];
		let transferTo = importedItemsReceipt.transfer_to ? JSON.parse(importedItemsReceipt.transfer_to) : null;
		let receivingUnit = transferTo ? profit_cost_centers.filter((pcc) => pcc.type===transferTo.type&&pcc.value===transferTo.value)[0] : null;
		setTransactionSource(transactionSource);
		setImportedItemsReceipt(importedItemsReceipt);
		setId(importedItemsReceipt.id);
		setReferenceNumber(importedItemsReceipt.reference_number ? importedItemsReceipt.reference_number : "");
		setTransactionLogs(importedItemsReceipt.transaction_logs);
		setAttachments([]);
		setTransactionDescription(importedItemsReceipt.description);
		setShipmentCostPerInvoice(importedItemsReceipt.shipment_cost_per_invoice);
		setFreightAndInsurance(importedItemsReceipt.freight_and_insurance);
		setCustomsDuties(importedItemsReceipt.customs_duties);
		setValueAddedTax(importedItemsReceipt.value_added_tax);
		setDocumentaryStamps(importedItemsReceipt.documentary_stamps);
		setDemurrageAndStorage(importedItemsReceipt.demurrage_and_storage);
		setBrokerageFee(importedItemsReceipt.brokerage_fee);
		setWharfageAndArrastre(importedItemsReceipt.wharfage_and_arrastre);
		setOtherCharges(importedItemsReceipt.other_charges);
		setTotalImportCost(importedItemsReceipt.total_import_cost);
		setTotalInventoryCost(importedItemsReceipt.total_inventory_cost);
		setJournalEntries(journalEntries.map((journalEntry) => ({
			account_code:journalEntry.account.code,
			account_description:journalEntry.account.description,
			profit_cost_center:importedItemsReceipt.transfer_to ? JSON.parse(importedItemsReceipt.transfer_to) : null,
			debit_amount:journalEntry.debit_amount,
			credit_amount:journalEntry.credit_amount,
		})));
		setIsEditable(importedItemsReceipt.is_editable);
		setIsAuthorizable(importedItemsReceipt.id ? auth.approval_pipelines.find((pipe) => pipe.imported_items_receipt_id===importedItemsReceipt.id)!==undefined : false);
		setTransferFrom(null);
		setTransferTo(receivingUnit);
		setLineItems( importedItemsReceiptLineItems.map((importedItemsReceiptLineItem) => ({
			id:importedItemsReceiptLineItem.id,
			item:{
				label:importedItemsReceiptLineItem.item.label, 
				default_uom_name:importedItemsReceiptLineItem.item.unit_of_measure.name, 
				item_costing:importedItemsReceiptLineItem.item.item_costing, 
				serial_number_type:importedItemsReceiptLineItem.item.serial_number_type, 
				value:importedItemsReceiptLineItem.item.id
			},
			quantity:importedItemsReceiptLineItem.quantity,
			unit_cost:importedItemsReceiptLineItem.unit_cost,
			amount:importedItemsReceiptLineItem.amount,
			landed_unit_cost:importedItemsReceiptLineItem.landed_unit_cost,
			landed_amount:importedItemsReceiptLineItem.landed_amount,
			uom:{label:importedItemsReceiptLineItem.unit_of_measure.code, value:importedItemsReceiptLineItem.unit_of_measure.id},
			to_location:{label:`${importedItemsReceiptLineItem.location.code} | ${importedItemsReceiptLineItem.location.name}`, value:importedItemsReceiptLineItem.location.id},
			serial_numbers:importedItemsReceiptLineItem.serial_numbers,
			expiry_dates:importedItemsReceiptLineItem.expiry_dates.map((expDate) => (expDate.length>0 ? moment(expDate, resources.date_format.SYSTEM).format(resources.date_format.INPUT) : "")),
			expiry_quantities:importedItemsReceiptLineItem.expiry_quantities.map((expQty) => (expQty.length>0 ? parseFloat(expQty) : 0)),
			item_conditions:importedItemsReceiptLineItem.item_conditions,
		})) );
		setHasExpirySerialized(false);
		setWithExpiry(false);
		setSerialized(false);
		for (let i=0; i<importedItemsReceiptLineItems.length; i++) {
			let importedItemsReceiptLineItem = importedItemsReceiptLineItems[i];
			if (importedItemsReceipt.approval_status===auth.enums.approval_status.DRAFT) {
				let inventoryItem = items.find((itm) => itm.id===importedItemsReceiptLineItem.item.id);
				if (inventoryItem) {
					let hasExpiry = Util.stringToBoolean(inventoryItem.has_expiry);
					let withSerialNumber = Util.stringToBoolean(inventoryItem.with_serial_number);
					if (hasExpiry && withSerialNumber) {
						setHasExpirySerialized(true);
					} else 
					if (withSerialNumber) {
						if (inventoryItem.serial_number_type===auth.enums.serial_number_types.EXTERNAL) setSerialized(true);
					} else 
					if (hasExpiry) {
						setWithExpiry(true);
					}
				}
			}
		}
	},[auth.enums.serial_number_types.EXTERNAL, transaction_sources, profit_cost_centers, auth.approval_pipelines, auth.enums.approval_status.DRAFT, items]);

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.IMPORTED_ITEMS_RECEIPT));
	}, [
		dispatch,
		translate.breadcrumb.IMPORTED_ITEMS_RECEIPT
	]);

	useEffect(() => {
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllItemsAsync({})).then((response) => response.payload.data ? setItems(response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)))) : void(0));
			dispatch(fetchAllLocationsAsync({})).then((response) => response.payload.data ? setLocations(response.payload.data.locations) : void(0));
			const handleFetchProfitCostCenterResponse = (response) => {
				if (response.payload.data) {
					let branches = response.payload.data.branches.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((branch) => ({label:`${branch.code} | ${branch.name}`, type:branch.type, value:branch.id}));
					let departments = response.payload.data.departments.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((department) => ({label:`${department.code} | ${department.name}`, type:department.type, value:department.id}));
					let projects = response.payload.data.projects.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((project) => ({label:`${project.code} | ${project.name}`, type:project.type, value:project.id}));
					setProfitCostCenters(branches.concat(departments).concat(projects));
				}
			}
			dispatch(fetchAllProfitCostCentersAsync({})).then((response) => handleFetchProfitCostCenterResponse(response));
		}
	}, [
		dispatch,
		worksheet.is_readonly_mode,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const refreshData = () => {
		handleClearButtonClickedEvent();
	}

	useEffect(() => {
		if (id) {
			dispatch(fetchAllImportedItemsReceiptLineItemsAsync({imported_items_receipt_id:id})).then((response) => response.payload.data ? loadDocument(response.payload.data.imported_items_receipt, response.payload.data.imported_items_receipt_line_items, response.payload.data.journal_entries) : void(0));
		}
	}, [dispatch, id, loadDocument]);

	useEffect(() => {
		let shipmentCostPerInvoice = 0;
		for (let i=0; i<line_items.length; i++) {
			let amount = line_items[i].amount ? parseFloat(line_items[i].amount) : 0;
			shipmentCostPerInvoice+=amount;
		}
		setShipmentCostPerInvoice(shipmentCostPerInvoice.toString());
	}, [line_items]);

	useEffect(() => {
		let totalImportCost = 0;
		let shipmentCostPerInvoice = shipment_cost_per_invoice.length>0 ? parseFloat(shipment_cost_per_invoice) : 0;
		let freightAndInsurance = freight_and_insurance.length>0 ? parseFloat(freight_and_insurance) : 0;
		let customsDuties = customs_duties.length>0 ? parseFloat(customs_duties) : 0;
		let valueAddedTax = value_added_tax.length>0 ? parseFloat(value_added_tax) : 0;
		let documentaryStamps = documentary_stamps.length>0 ? parseFloat(documentary_stamps) : 0;
		let demurrageAndStorage = demurrage_and_storage.length>0 ? parseFloat(demurrage_and_storage) : 0;
		let brokerageFee = brokerage_fee.length>0 ? parseFloat(brokerage_fee) : 0;
		let wharfageAndArrastre = wharfage_and_arrastre.length>0 ? parseFloat(wharfage_and_arrastre) : 0;
		let otherCharges = other_charges.length>0 ? parseFloat(other_charges) : 0;
		totalImportCost = shipmentCostPerInvoice + freightAndInsurance + customsDuties + valueAddedTax + documentaryStamps + demurrageAndStorage + brokerageFee + wharfageAndArrastre + otherCharges;
		setTotalImportCost(totalImportCost.toString());
	}, [shipment_cost_per_invoice, freight_and_insurance, customs_duties, value_added_tax, documentary_stamps, demurrage_and_storage, brokerage_fee, wharfage_and_arrastre, other_charges]);

	useEffect(() => {
		let totalInventoryCost = 0;
		let totalImportCost = total_import_cost.length>0 ? parseFloat(total_import_cost) : 0;
		let valueAddedTax = value_added_tax.length>0 ? parseFloat(value_added_tax) : 0;
		totalInventoryCost = totalImportCost - valueAddedTax;
		setTotalInventoryCost(totalInventoryCost.toString());
	}, [total_import_cost, value_added_tax]);

	useEffect(() => {
		let shipmentCostPerInvoice = shipment_cost_per_invoice.length>0 ? parseFloat(shipment_cost_per_invoice) : 0;
		let totalInventoryCost = total_inventory_cost.length>0 ? parseFloat(total_inventory_cost) : 0;
		setLineItems(oldLineItems => oldLineItems.map((li) => ({
			id:li.id,
			item:li.item,
			quantity:li.quantity,
			unit_cost:li.unit_cost,
			amount:li.amount,
			landed_unit_cost:li.quantity.length>0&&li.amount.length>0&&parseFloat(li.quantity)>0 ? (((parseFloat(li.amount)/shipmentCostPerInvoice)*totalInventoryCost).toFixed(2)/parseFloat(li.quantity).toFixed(4)).toFixed(2) : 0,
			landed_amount:li.amount.length>0&&parseFloat(li.amount)>0 ? ((parseFloat(li.amount)/shipmentCostPerInvoice)*totalInventoryCost).toFixed(2).toString() : "0.00",
			uom:li.uom,
			to_location:li.to_location,
			serial_numbers:li.serial_numbers,
			expiry_quantities:li.expiry_quantities,
			expiry_dates:li.expiry_dates,
			item_conditions:li.item_conditions,
		})));
	}, [
		shipment_cost_per_invoice, 
		total_inventory_cost
	]);

	useEffect(() => {
		if (transaction_source) {
			switch(transaction_source.value) {
				case auth.enums.transaction_sources.imported_items_receipt.DIRECT_RECEIPT : {
					setBalancingAccount(auth.enums.balancing_accounts.imported_items_receipt.DIRECT_RECEIPT);
					break;
				}
				case auth.enums.transaction_sources.imported_items_receipt.FOR_PURCHASE_ORDER : {
					setBalancingAccount(auth.enums.balancing_accounts.imported_items_receipt.FOR_PURCHASE_ORDER);
					break;
				}
				default : break;
			}
		}
	}, [transaction_source, auth.enums.transaction_sources.imported_items_receipt, auth.enums.balancing_accounts]);

	const handleResetResponse = (response) => {
		setAttachments([]);
		handleClearButtonClickedEvent();
		refreshData();
	}

	const handleClearButtonClickedEvent = () => {
		setId(null);
		setDocumentDate(moment(new Date()).format(resources.date_format.INPUT));
		setImportedItemsReceipt(null);
		setReferenceNumber("");
		setTransactionDescription("");
		setShipmentCostPerInvoice("");
		setFreightAndInsurance("");
		setCustomsDuties("");
		setValueAddedTax("");
		setDocumentaryStamps("");
		setDemurrageAndStorage("");
		setBrokerageFee("");
		setWharfageAndArrastre("");
		setOtherCharges("");
		setTotalImportCost("");
		setTotalInventoryCost("");
		setTransferFrom(null);
		setTransferTo(null);
		setTransactionSource(null);
		setSelectedDocumentNumber("");
		setSelectedDocumentIds([]);
		setLineItems(default_line_items);
		setJournalEntries(default_journal_entries);
		setBalancingAccount("");
		setAttachments([]);
		setIsEditable(true);
		setIsAuthorizable(false);
		setSerialized(false);
		setWithExpiry(false);
		setHasExpirySerialized(false);
		history.push('/warehouse-inventory/imported-items-receipt');
	};

	const handleUpdateButtonClickedResponse = (response) => {
		let doc = response.payload.data.imported_items_receipt;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.imported_items_receipt.id ? [
				doc.id,
				doc.document_number,
				doc.document_date,
				doc.approval_status,
				doc.reference_number,
				doc.source,
				doc.transfer_to ? JSON.parse(doc.transfer_to).label : "",
				doc.transfer_to ? JSON.parse(doc.transfer_to).type : "",
				doc.shipment_cost_per_invoice,
				doc.customs_duties,
				doc.value_added_tax,
				doc.brokerage_fee,
				doc.freight_and_insurance,
				doc.wharfage_and_arrastre,
				doc.documentary_stamps,
				doc.demurrage_and_storage,
				doc.other_charges,
				doc.total_import_cost,
				doc.total_inventory_cost,
				doc.description,
			] : ds))
		});
		loadDocument(response.payload.data.imported_items_receipt, response.payload.data.imported_items_receipt_line_items, response.payload.data.journal_entries);
		setAttachments([]);
	}

	const handleCreateButtonClickedResponse = (response) => {
		let doc = response.payload.data.imported_items_receipt;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:[
				[
					doc.id,
					doc.document_number,
					doc.document_date,
					doc.approval_status,
					doc.reference_number,
					doc.source,
					doc.transfer_to ? JSON.parse(doc.transfer_to).label : "",
					doc.transfer_to ? JSON.parse(doc.transfer_to).type : "",
					doc.shipment_cost_per_invoice,
					doc.customs_duties,
					doc.value_added_tax,
					doc.brokerage_fee,
					doc.freight_and_insurance,
					doc.wharfage_and_arrastre,
					doc.documentary_stamps,
					doc.demurrage_and_storage,
					doc.other_charges,
					doc.total_import_cost,
					doc.total_inventory_cost,
					doc.description,
				],
				...worksheet.data_set
			]
		});
		loadDocument(response.payload.data.imported_items_receipt, response.payload.data.imported_items_receipt_line_items, response.payload.data.journal_entries);
		setAttachments([]);
	}

	const handleDeleteRowsButtonClickedEvent = () => {
		let rows = selected_rows.map(
			(row) => worksheet.data_set[row]
		);
		let promises = [];
		for (let i = 0; i < rows.length; i++) {
			promises.push(
				dispatch(
					voidImportedItemsReceiptAsync({
						id: rows[i][0]
					})
				)
			);
		}
		Promise.all(promises).then((response) => {
			var success = true;
			var message = null;
			for (let i = 0; i < response.length; i++) {
				if (response[i].payload !== undefined) {
					if (response[i].payload.status !== resources.status.SUCCESS) {
						success = false;
						break;
					}
					else {
						if (message == null) {
							message = response[i].payload.message;
						}
					}
				}
			}
			if (success === true) {
				toast.success(
					message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			handleClearButtonClickedEvent();
		});
	};

	const handleSubmitButtonClickedEvent = () => {
		dispatch(
			submitImportedItemsReceiptAsync({
				id:id,
				document_date:document_date,
				selected_document_ids:selected_document_ids,
				reference_number:reference_number,
				transaction_source:transaction_source,
				shipment_cost_per_invoice:shipment_cost_per_invoice,
				freight_and_insurance:freight_and_insurance,
				customs_duties:customs_duties,
				value_added_tax:value_added_tax,
				documentary_stamps:documentary_stamps,
				demurrage_and_storage:demurrage_and_storage,
				brokerage_fee:brokerage_fee,
				wharfage_and_arrastre:wharfage_and_arrastre,
				other_charges:other_charges,
				total_import_cost:total_import_cost,
				total_inventory_cost:total_inventory_cost,
				transaction_description:transaction_description,
				transfer_from:transfer_from,
				transfer_to:transfer_to,
				line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.to_location!==null)),
				attachments:attachments,
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleResetResponse(response) : null))
	};

	const handleApproveButtonClickedEvent = () => {
		dispatch(
			approveImportedItemsReceiptAsync({
				id:id,
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleResetResponse(response) : null))
	};

	const handleRejectButtonClickedEvent = () => {
		dispatch(
			rejectImportedItemsReceiptAsync({
				id:id,
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleResetResponse(response) : null))
	};

	const handleVoidButtonClickedEvent = () => {
		dispatch(
			voidImportedItemsReceiptAsync({
				id:id,
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleResetResponse(response) : null))
	};

	const handleEditButtonClickedEvent = async (row) => {
		let id = worksheet.data_set[row][0];
		setId(id);
		window.scrollTo(0,0);
	};

	const handleProcessSelectedDocuments = (selectionObject) => {
		Util.sortByKey(selectionObject.selectedRows, 'id');
		let ERROR = null;
		let requestingUnit = null;
		let selected_ids = [];
		let selected_document_numbers = [];
		let selected_descriptions = [];
		let selected_reference_numbers = [];
		let selected_line_items = [];
		for (let i=0; i<selectionObject.selectedRows.length; i++) {
			if (transaction_source.value===auth.enums.transaction_sources.imported_items_receipt.FOR_PURCHASE_ORDER) {
				if (!selected_ids.includes(selectionObject.selectedRows[i].id)) selected_ids.push(selectionObject.selectedRows[i].id);
				if (!selected_document_numbers.includes(selectionObject.selectedRows[i].document_number)) selected_document_numbers.push(selectionObject.selectedRows[i].document_number);
				if (!selected_descriptions.includes(selectionObject.selectedRows[i].transaction_description)) selected_descriptions.push(selectionObject.selectedRows[i].transaction_description);
				if (!selected_reference_numbers.includes(selectionObject.selectedRows[i].reference_number)) selected_reference_numbers.push(selectionObject.selectedRows[i].reference_number);
				if (requestingUnit!==null) {
					if (requestingUnit!==selectionObject.selectedRows[i].requesting_unit) {
						ERROR = translate.message.CONSOLIDATION_MUST_BELONG_TO_SAME_REQUESTING_UNIT;
						break;
					}
				}
				else {
					requestingUnit = selectionObject.selectedRows[i].requesting_unit;
				}
			}
		}
		setLineItems(selected_line_items);
		if (ERROR) {
			toast.error(
				ERROR,
				Util.getToasterStyle(resources.status.ERROR)
			);
		}
		else {
			setSelectedDocumentIds(selected_ids);
			setSelectedDocumentNumber(selected_document_numbers.join(", "));
			setReferenceNumber(selected_reference_numbers.join(", "));
			setTransactionDescription(selected_descriptions.join(", "));
			if (transaction_source.value===auth.enums.transaction_sources.imported_items_receipt.STOCK_PURCHASE_REQUEST) {
				let stockPurchaseRequest = auth.stock_requests.filter((stockRequest) => (selected_ids.includes(stockRequest.id)))[0];
				if (stockPurchaseRequest && stockPurchaseRequest.branch.id) {
					setTransferTo({label:stockPurchaseRequest.branch.name, type:auth.enums.profit_cost_center_types.BRANCH, value:stockPurchaseRequest.branch.name.id});
				}
				if (stockPurchaseRequest && stockPurchaseRequest.department.id) {
					setTransferTo({label:stockPurchaseRequest.department.name, type:auth.enums.profit_cost_center_types.DEPARTMENT, value:stockPurchaseRequest.department.name.id});
				}
				if (stockPurchaseRequest && stockPurchaseRequest.project.id) {
					setTransferTo({label:stockPurchaseRequest.project.name, type:auth.enums.profit_cost_center_types.PROJECT, value:stockPurchaseRequest.project.name.id});
				}
			}
			if (transaction_source.value===auth.enums.transaction_sources.imported_items_receipt.STOCK_ISSUANCE) {
				let stockIssuance = auth.stock_issuances.filter((stockIssuance) => (selected_ids.includes(stockIssuance.id)))[0];
				setTransferFrom(JSON.parse(stockIssuance.transfer_from));
				setTransferTo(JSON.parse(stockIssuance.transfer_to));
				let journalEntries = [];
				for (let i=0; i<selected_line_items.length; i++) {
					let itemObj = auth.items.filter((item) => (item.id===selected_line_items[i].item.value))[0];
					journalEntries.push({
			            account_code:itemObj.inventory_account.code,
			            account_description:itemObj.inventory_account.description,
			            profit_cost_center:JSON.parse(stockIssuance.transfer_to),
			            debit_amount:selected_line_items[i].amount,
			            credit_amount:(0).toFixed(2),
					});
				}
				setJournalEntries(journalEntries);
			}
			setIsDatatableDialogOpen(false);
		}
	};

	const handleTransferToChangedEvent = (transferTo) => {
		setTransferTo(transferTo);
        setJournalEntries(journal_entries.map((journalEntry,key) => (
        {
            account_code:journalEntry.account_code,
            account_description:journalEntry.account_description,
            profit_cost_center:journalEntry.account_code ? transferTo : journalEntry.profit_cost_center,
            debit_amount:journalEntry.debit_amount,
            credit_amount:journalEntry.credit_amount,
        })));
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{ Util.functionTitle(translate.text.IMPORTED_ITEMS_RECEIPT) }
				<div className="function-sub-title">
				</div>

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						{
							id===null ?
							<>
								<Button
									text={translate.button.CREATE_DRAFT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING||!is_editable}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={(e) =>
										dispatch(
											createImportedItemsReceiptAsync({
												document_date:document_date,
												selected_document_ids:selected_document_ids,
												reference_number:reference_number,
												transaction_source:transaction_source,
												shipment_cost_per_invoice:shipment_cost_per_invoice,
												freight_and_insurance:freight_and_insurance,
												customs_duties:customs_duties,
												value_added_tax:value_added_tax,
												documentary_stamps:documentary_stamps,
												demurrage_and_storage:demurrage_and_storage,
												brokerage_fee:brokerage_fee,
												wharfage_and_arrastre:wharfage_and_arrastre,
												other_charges:other_charges,
												total_import_cost:total_import_cost,
												total_inventory_cost:total_inventory_cost,
												transaction_description:transaction_description,
												transfer_from:transfer_from,
												transfer_to:transfer_to,
												line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.to_location!==null)),
												attachments:attachments,
											})
										).then((response) => (response.payload.status===resources.status.SUCCESS ? handleCreateButtonClickedResponse(response) : null))
									}
								/>
							</>
							:
							<>
								{
									is_editable &&
									<Button
										text={translate.button.UPDATE}
										className="function-button"
										disabled={auth.status === resources.status.LOADING||!is_editable}
										icon={<Icon icon="floppy-disk" className="orange"/>}
										onClick={(e) =>
											dispatch(
												updateImportedItemsReceiptAsync({
													id:id,
													document_date:document_date,
													selected_document_ids:selected_document_ids,
													reference_number:reference_number,
													transaction_source:transaction_source,
													shipment_cost_per_invoice:shipment_cost_per_invoice,
													freight_and_insurance:freight_and_insurance,
													customs_duties:customs_duties,
													value_added_tax:value_added_tax,
													documentary_stamps:documentary_stamps,
													demurrage_and_storage:demurrage_and_storage,
													brokerage_fee:brokerage_fee,
													wharfage_and_arrastre:wharfage_and_arrastre,
													other_charges:other_charges,
													total_import_cost:total_import_cost,
													total_inventory_cost:total_inventory_cost,
													transaction_description:transaction_description,
													transfer_from:transfer_from,
													transfer_to:transfer_to,
													line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.to_location!==null)),
													attachments:attachments,
												})
											).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateButtonClickedResponse(response) : null))
										}
									/>
								}
								{
									is_editable &&
									<Button
										text={translate.button.SUBMIT}
										className="function-button"
										disabled={auth.status === resources.status.LOADING||!is_editable}
										icon={<Icon icon="send-message" className="orange"/>}
										onClick={e => setIsConfirmSubmitDialogOpen(!is_confirm_submit_dialog_open)}
									/>
								}
								<Button
									text={translate.button.PRINT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="print" className="orange"/>}
									onClick={() =>
										PDF.exportImportedItemsReceiptForm(
											translate.worksheet.file_name.IMPORTED_ITEMS_RECEIPT,
											[imported_items_receipt],
											line_items,
											auth.active_company
										)
									}
								/>
								{
									is_editable &&
									<Button
										text={translate.button.VOID}
										className="function-button"
										disabled={auth.status === resources.status.LOADING||!is_editable}
										icon={<Icon icon="ban-circle" className="orange"/>}
										onClick={e => setIsConfirmVoidDialogOpen(!is_confirm_void_dialog_open)}
									/>
								}
								{
									imported_items_receipt.approval_status===auth.enums.approval_status.FOR_APPROVAL && is_authorizable &&
									<>
										<Button
											text={translate.button.APPROVE}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="tick" className="orange"/>}
											onClick={e => setIsConfirmApproveDialogOpen(!is_confirm_approve_dialog_open)}
										/>
										<Button
											text={translate.button.REJECT}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="cross" className="orange"/>}
											onClick={e => setIsConfirmRejectDialogOpen(!is_confirm_reject_dialog_open)}
										/>
									</>
								}
							</>
						}
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleClearButtonClickedEvent}
						/>
					</ButtonGroup>
				}

				{
					(with_expiry_serialized || serialized || with_expiry) &&
					<div style={{textAlign:"center", marginBottom:20, color:"blue"}}>
						{
							with_expiry_serialized &&
							<div>
								Your next step is to assign expiry date/s to corresponding serial number/s.
							</div>
						}
						{
							serialized &&
							<div>
								Your next step is to assign serial number/s to corresponding quantity.
							</div>
						}
						{
							with_expiry &&
							<div>
								Your next step is to assign expiry date to each quantity.
							</div> 
						}
					</div>
				}

				{
					worksheet.is_readonly_mode &&
					<div className="function-card">
						<div className="row">
							<Card
								className="col-sm-12 col-md-4 col-lg-3"
								style={{
									background: "lightblue"
								}}
							>
								<FormGroup>
									<H3 className="center" style={{color:"blue"}}>{imported_items_receipt!==null ? imported_items_receipt.document_number : null}</H3>
									<H5 className="center" style={{color:"green"}}>{imported_items_receipt!==null ? imported_items_receipt.approval_status : null}</H5>
								</FormGroup>

								<div className="row mb-5">

									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										label={translate.placeholder.PICK_A_DATE}
										type="date"
										variant="outlined"
										size="small"
										defaultValue={document_date}
										inputProps={{readOnly:!is_editable}}
										onChange={(e) => setDocumentDate(e.target.value)}
										InputLabelProps={{
											shrink: true,
										}}
									/>

									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={reference_number}
										label={translate.placeholder.ENTER_TRANSACTION_REFERENCE_NUMBER}
										onChange={(e) =>
											setReferenceNumber(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
						        </div>

								<div className="row mt-5">
									<div className={"col-sm-12 "+(transaction_source && transaction_source.value!==auth.enums.transaction_sources.imported_items_receipt.DIRECT_RECEIPT ? "col-md-6 col-lg-6" : "col-md-12 col-lg-12")}>
										
										<MuiAutocomplete
											isDisabled={id!==null||!is_editable}
											setSelectedValue={setTransactionSource}
											selected_value={transaction_source}
											isMultiple={false}
											label={translate.placeholder.SELECT_TRANSACTION_SOURCE}
											data={transaction_sources}
										/>

									</div>
									{
										transaction_source && transaction_source.value!==auth.enums.transaction_sources.imported_items_receipt.DIRECT_RECEIPT &&
										<div className={"col-sm-12 col-md-6 col-lg-6"}>
											<ControlGroup vertical={false}>
												<TextField
													inputProps={{readOnly:true}}
													size="small"
													variant="outlined"
													value={selected_document_number}
													label={"Search a document"}
												/>
												<Button
													minimal={true}
													large={true}
													text={translate.button.SEARCH}
													icon="search"
													disabled={auth.status === resources.status.LOADING}
													onClick={e => setIsDatatableDialogOpen(true)}
												/>
											</ControlGroup>
										</div>
									}
								</div>

								{
									transaction_source!==null &&
									<div className="row">
										<div className="col-sm-12 col-md-6 col-lg-6 mt-5">
											<MuiAutocomplete
												setSelectedValue={handleTransferToChangedEvent}
												selected_value={transfer_to}
												isMultiple={false}
												isDisabled={(transaction_source&&transaction_source.value!==auth.enums.transaction_sources.imported_items_receipt.DIRECT_RECEIPT)||!is_editable}
												label={"Receiving Unit"}
												data={profit_cost_centers}
											/>
										</div>
										<TextField
											className="col-sm-12 col-md-6 col-lg-6 mt-5"
											size="small"
											variant="outlined"
											value={shipment_cost_per_invoice}
											label={translate.placeholder.SHIPMENT_COST_PER_INVOICE}
											onChange={(e) =>
												setShipmentCostPerInvoice(
													e.target.value
												)
											}
											inputProps={{readOnly:true}}
											type="number"
										/>
									</div>
								}

								<div className="row">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={customs_duties}
										label={translate.placeholder.CUSTOMS_DUTIES}
										onChange={(e) =>
											setCustomsDuties(
												e.target.value
											)
										}
										type="number"
										inputProps={{readOnly:!is_editable}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={value_added_tax}
										label={translate.placeholder.VALUE_ADDED_TAX}
										onChange={(e) =>
											setValueAddedTax(
												e.target.value
											)
										}
										type="number"
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<div className="row">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={brokerage_fee}
										label={translate.placeholder.BROKERAGE_FEE}
										onChange={(e) =>
											setBrokerageFee(
												e.target.value
											)
										}
										type="number"
										inputProps={{readOnly:!is_editable}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={freight_and_insurance}
										label={translate.placeholder.FREIGHT_AND_INSURANCE}
										onChange={(e) =>
											setFreightAndInsurance(
												e.target.value
											)
										}
										type="number"
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<div className="row">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={wharfage_and_arrastre}
										label={translate.placeholder.WHARFAGE_AND_ARRASTRE}
										onChange={(e) =>
											setWharfageAndArrastre(
												e.target.value
											)
										}
										type="number"
										inputProps={{readOnly:!is_editable}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={documentary_stamps}
										label={translate.placeholder.DOCUMENTARY_STAMPS}
										onChange={(e) =>
											setDocumentaryStamps(
												e.target.value
											)
										}
										type="number"
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<div className="row mb-5">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={demurrage_and_storage}
										label={translate.placeholder.DEMURRAGE_AND_STORAGE}
										onChange={(e) =>
											setDemurrageAndStorage(
												e.target.value
											)
										}
										type="number"
										inputProps={{readOnly:!is_editable}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={other_charges}
										label={translate.placeholder.OTHER_CHARGES}
										onChange={(e) =>
											setOtherCharges(
												e.target.value
											)
										}
										type="number"
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								{
									id && 
									<div className="row mb-5 mt-5">
										<TextField
											className="col-sm-12 col-md-6 col-lg-6"
											size="small"
											variant="outlined"
											value={total_import_cost}
											label={translate.placeholder.TOTAL_IMPORT_COST}
											onChange={(e) =>
												setTotalImportCost(
													e.target.value
												)
											}
											type="number"
											inputProps={{readOnly:true}}
										/>
										<TextField
											className="col-sm-12 col-md-6 col-lg-6"
											size="small"
											variant="outlined"
											value={total_inventory_cost}
											label={translate.placeholder.TOTAL_INVENTORY_COST}
											onChange={(e) =>
												setTotalInventoryCost(
													e.target.value
												)
											}
											type="number"
											inputProps={{readOnly:true}}
										/>
									</div>
								}

								<div className="row mb-5 mt-5">
									<TextField
										className="col-sm-12 col-md-12 col-lg-12"
										multiline
										minRows={4}
										size="small"
										variant="outlined"
										label={translate.placeholder.ENTER_TRANSACTION_DESCRIPTION}
										value={transaction_description}
										onChange={(e) =>
											setTransactionDescription(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<Attachment
									id={id}
									transaction={imported_items_receipt}
									setTransaction={setImportedItemsReceipt}
									attachments={attachments}
									setAttachments={setAttachments}
								/>

								{
									id!==null && imported_items_receipt &&
									<>
										<Comment
											id={id}
											document_type={imported_items_receipt.document_type.name}
											comments={imported_items_receipt.comments}
											created_by={imported_items_receipt.created_by}
											setDocument={setImportedItemsReceipt}
											is_editable={is_editable}
											is_authorizable={is_authorizable}
										/>
										<TransactionLog
											transaction_logs={transaction_logs}
										/>
				                    </>
								}
							</Card>
							<ImportedItemsReceiptLineItem
								id={id}
								is_editable={is_editable}
								transfer_to={transfer_to}
								transaction_source={transaction_source}
								default_line_items={default_line_items}
								line_items={line_items}
								setLineItems={setLineItems}
								default_journal_entries={default_journal_entries}
								journal_entries={journal_entries}
								setJournalEntries={setJournalEntries}
								profit_cost_centers={profit_cost_centers}
								balancing_account={balancing_account}
								setBalancingAccount={setBalancingAccount}
								items={items}
								locations={locations}
							/>
						</div>
					</div>
				}

				<Card className="function-card-spreadsheet">
					<ImportedItemsReceiptSpreadsheet
						enable_select={true}
						enable_edit={true}
						selected_rows={selected_rows}
						setSelectedRows={setSelectedRows}
						handleEditButtonClickedEvent={handleEditButtonClickedEvent}
						handleClearButtonClickedEvent={handleClearButtonClickedEvent}
						worksheet={worksheet}
						setWorkSheet={setWorkSheet}
						refreshData={refreshData}
						is_confirm_delete_dialog_open={is_confirm_delete_dialog_open}
						setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
						profit_cost_centers={profit_cost_centers}
						DEFAULT_DATA_SET={DEFAULT_DATA_SET}
					/>
				</Card>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_delete_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDeleteDialogOpen}
					confirmAction={handleDeleteRowsButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted/voided, document will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain document is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_submit_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmSubmitDialogOpen}
					confirmAction={handleSubmitButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">SUBMIT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_approve_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmApproveDialogOpen}
					confirmAction={handleApproveButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">APPROVE</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_reject_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmRejectDialogOpen}
					confirmAction={handleRejectButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">REJECT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_void_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmVoidDialogOpen}
					confirmAction={handleVoidButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">VOID</span> this document ?
						</span>
					}
				/>

				<DatatableDialog
					is_datatable_open={is_datatable_open}
					setIsDatatableDialogOpen={setIsDatatableDialogOpen}
					transaction_source={transaction_source}
					handleProcessSelectedDocuments={handleProcessSelectedDocuments}
				/>

			</div>
		</div>
	);
}
