import React, {useState} from "react";
import { Omnibar } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core";

export function OmnibarSelect({is_omnibar_open, setIsOmnibarOpen, omnibar_data, omnibar_ds_type, handleOmnibarItemSelectedEvent}) {
	const [omnibar_query, setOmnibarQuery] = useState('');

	const itemPredicate = (query, item, _index, exactMatch) => {
	    const normalizedDatasetName = item.label.toLowerCase();
	    const normalizedQuery = query.toLowerCase();
	    if (exactMatch) {
	      return normalizedDatasetName === normalizedQuery;
	    } else {
	      return normalizedDatasetName.indexOf(normalizedQuery) >= 0;
	    }
	}

	const onQueryChange = (keyword) => {
		setOmnibarQuery(keyword);
	}

	const handleSelect = (item) => {
		setIsOmnibarOpen(false);
		setOmnibarQuery('');
		handleOmnibarItemSelectedEvent({item:item, ds_type:omnibar_ds_type});
	}

	const itemRenderer = (item) => {
		return (
		<MenuItem
			key={item.id}
			label={""}
			text={item.label}
			onClick={e => handleSelect(item)}
			shouldDismissPopover={true}
		/>
		)
	}

	return (
		<div>
			<Omnibar 
				className="omnibar-select col-sm-12 col-md-6 col-md-6"
				inputProps={{placeholder:omnibar_ds_type ? `Type a keyword to find the ${omnibar_ds_type.data_source} & click to select. | press <ESC> or click outside to exit.` : 'Search'}}
				isOpen={is_omnibar_open} 
				items={omnibar_data}
				onClose={e => setIsOmnibarOpen(false)}
				itemRenderer={itemRenderer}
				itemsEqual={"id"}
				resetOnSelect={true}
				query={omnibar_query}
				onQueryChange={onQueryChange}
				initialContent={omnibar_data.slice(0,10).map((item) => (
					<MenuItem
						key={item.id}
						label={""}
						text={item.label}
						onClick={e => handleSelect(item)}
						shouldDismissPopover={true}
					/>
				))}
				itemPredicate={itemPredicate}
				noResults={<div style={{textAlign:"center", color:"red"}}>No results found. Try changing the keyword...</div>}
			/>
		</div>
	);
}

export default OmnibarSelect;