import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { useParams } from "react-router";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	initializeWorksheet,
	createFinishedGoodProducedAsync,
	updateFinishedGoodProducedAsync,
	submitFinishedGoodProducedAsync,
	rejectFinishedGoodProducedAsync,
	approveFinishedGoodProducedAsync,
	voidFinishedGoodProducedAsync,
	fetchAllFinishedGoodProducedLineItemsAsync,
	toggleSidebarCollapsed,
	fetchAllStockAcceptanceLineItemsAsync,
	fetchItemAsync,
	fetchAllFinishedGoodProducesAsync,
	fetchAllLocationsAsync,
	fetchAllItemsAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	ControlGroup,
	H3,
	H5,
	FormGroup,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { DatatableDialog } from "../../../common/DatatableDialog";
import { FinishedGoodProducedLineItem } from "./FinishedGoodProducedLineItem";
import { SideBar } from '../../../SideBar';
import { Attachment } from "../../../common/Attachment";
import { Comment } from "../../../common/Comment";
import { TransactionLog } from "../../../common/TransactionLog";
import { useHistory } from "react-router-dom";
import { FinishedGoodProducedSpreadsheet } from "../../../spreadsheets/FinishedGoodProducedSpreadsheet";

export function FinishedGoodProduced() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const default_line_items = Array(5).fill({
		id:"",
		item:null,
		quantity:"",
		unit_cost:"",
		amount:"",
		uom:null,
		location:null,
		serial_numbers:[],
		expiry_quantities:[],
		expiry_dates:[],
		item_conditions:[],
	});
	const default_component_line_items = Array(4).fill({
		id:null,
		item:null,
		quantity:"",
		unit_cost:"",
		amount:"",
		uom:null,
		location:null,
		serial_numbers:[],
		expiry_quantities:[],
		expiry_dates:[],
		item_conditions:[],
	});
	const default_journal_entries = Array(2).fill({
		account_code:"",
		account_description:"",
		profit_cost_center:null,
		debit_amount:"",
		credit_amount:"",
	});
	const { document_id } = useParams();
	const [id, setId] = useState(document_id ? document_id : null);
	const [selected_document_number, setSelectedDocumentNumber] = useState('');
	const [selected_document_ids, setSelectedDocumentIds] = useState([]);
	const [finished_good_produced, setFinishedGoodProduced] = useState(null);
	const [line_items, setLineItems] = useState(default_line_items);
	const [document_date, setDocumentDate] = useState(finished_good_produced ? moment(finished_good_produced.document_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT) : moment(new Date()).format(resources.date_format.INPUT));
	const [reference_number, setReferenceNumber] = useState("");
	const [transaction_description, setTransactionDescription] = useState("");
	const [transaction_logs, setTransactionLogs] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [transaction_source , setTransactionSource] = useState({label:auth.enums.transaction_sources.finished_good_produced.FINISHED_GOOD_PRODUCED, value:auth.enums.transaction_sources.finished_good_produced.FINISHED_GOOD_PRODUCED});
	const [selected_rows, setSelectedRows] = useState([]);
	const [locations, setLocations] = useState([]);
	const [is_confirm_delete_dialog_open, setIsConfirmDeleteDialogOpen] = useState(false);
	const [is_confirm_submit_dialog_open, setIsConfirmSubmitDialogOpen] = useState(false);
	const [is_confirm_approve_dialog_open, setIsConfirmApproveDialogOpen] = useState(false);
	const [is_confirm_reject_dialog_open, setIsConfirmRejectDialogOpen] = useState(false);
	const [is_confirm_void_dialog_open, setIsConfirmVoidDialogOpen] = useState(false);
	const [is_datatable_open, setIsDatatableDialogOpen] = useState(false);
	const [items, setItems] = useState([]);
	const [finished_good_items, setFinishedGoodItems] = useState([]);
	const [finished_goods_produced, setFinishedGoodsProduced] = useState(null);
	const [yield_quantity, setYieldQuantity] = useState("");
	const [yield_uom, setYieldUom] = useState("");
	const [yield_unit_of_measure, setYieldUnitOfMeasure] = useState(null);
	const [number_of_batches, setNumberOfBatches] = useState("");
	const [standard_production, setStandardProduction] = useState("");
	const [actual_production, setActualProduction] = useState("");
	const [direct_labor_cost, setDirectLaborCost] = useState("");
	const [overhead_cost, setOverheadCost] = useState("");
	const [total_production_cost, setTotalProductionCost] = useState("");
	const [finished_good_unit_price, setFinishedGoodUnitPrice] = useState("");
	const [materials_cost, setMaterialsCost] = useState("");
	const [component_line_items, setComponentLineItems] = useState(default_component_line_items);
	const [journal_entries, setJournalEntries] = useState(default_journal_entries);
	const [balancing_account, setBalancingAccount] = useState("");
	const [stock_acceptance_id, setStockAcceptanceId] = useState(null);
	const [is_editable, setIsEditable] = useState(true);
	const [is_authorizable, setIsAuthorizable] = useState(document_id ? auth.approval_pipelines.find((pipe) => pipe.finished_good_produced_id===document_id)!==undefined : false);

	const [with_expiry_serialized, setHasExpirySerialized] = useState(false);
	const [with_expiry, setWithExpiry] = useState(false);
	const [serialized, setSerialized] = useState(false);

	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: translate.worksheet.default_header.FINISHED_GOOD_PRODUCED,
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const loadDocument = useCallback((finishedGoodProduced, finishedGoodProducedLineItems, journalEntries) => {
		let finishedGoodItemObj = null;
		if (finishedGoodProduced.finished_good_item) {
			finishedGoodItemObj = items.find((item) => item.id===finishedGoodProduced.finished_good_item.id);
		}
		setFinishedGoodProduced(finishedGoodProduced);
		setId(finishedGoodProduced.id);
		setReferenceNumber(finishedGoodProduced.reference_number);
		setTransactionLogs(finishedGoodProduced.transaction_logs);
		setTransactionDescription(finishedGoodProduced.description);
		setNumberOfBatches(finishedGoodProduced.number_of_batches);
		let stockAcceptances = finishedGoodProduced.stock_acceptances;
		let stockAcceptance = null;
		let documentIds = [];
		let documentNumbers = [];
		for (let i=0; i<stockAcceptances.length; i++) {
			stockAcceptance = stockAcceptances[i];
			documentIds.push(stockAcceptance.id);
			documentNumbers.push(stockAcceptance.document_number);
			break;
		}
		setSelectedDocumentIds(documentIds);
		setSelectedDocumentNumber(documentNumbers.join(", "));
		setNumberOfBatches(finishedGoodProduced.number_of_batches);
		setStandardProduction(finishedGoodProduced.standard_production);
		setYieldUom(finishedGoodItemObj ? finishedGoodItemObj.yield_unit_of_measure.code : '');
		setYieldQuantity(finishedGoodItemObj ? finishedGoodItemObj.yield_quantity : '');
		setFinishedGoodsProduced(finished_good_items.find((itm) => finishedGoodItemObj.id===itm.value));
		setActualProduction(finishedGoodProduced.actual_production);
		setDirectLaborCost(finishedGoodProduced.direct_labor_cost);
		setTotalProductionCost(finishedGoodProduced.total_production_cost);
		setOverheadCost(finishedGoodProduced.overhead_cost);
		setFinishedGoodUnitPrice(finishedGoodProduced.finished_good_unit_price);

		const handleResponse = (response) => {
			let stockAcceptanceLineItems = response.payload.data.stock_acceptance_line_items;
			setComponentLineItems(stockAcceptanceLineItems.map((stockAcceptanceLineItem) => ({
					item:{value:stockAcceptanceLineItem.item.id, label:`${stockAcceptanceLineItem.item.code} | ${stockAcceptanceLineItem.item.description}`},
					quantity:stockAcceptanceLineItem.quantity,
					unit_cost:stockAcceptanceLineItem.unit_cost,
					amount:(parseFloat(stockAcceptanceLineItem.quantity)*parseFloat(stockAcceptanceLineItem.unit_cost)).toFixed(2),
					uom:{value:stockAcceptanceLineItem.unit_of_measure.id, label:stockAcceptanceLineItem.unit_of_measure.code},
					location:{value:stockAcceptanceLineItem.location.id, label:`${stockAcceptanceLineItem.location.code} | ${stockAcceptanceLineItem.location.name}`},
					serial_numbers:stockAcceptanceLineItem.serial_numbers,
					expiry_dates:stockAcceptanceLineItem.expiry_dates.map((expDate) => (expDate.length>0 ? moment(expDate, resources.date_format.SYSTEM).format(resources.date_format.INPUT) : "")),
					expiry_quantities:stockAcceptanceLineItem.expiry_quantities.map((expQty) => (expQty.length>0 ? parseFloat(expQty) : 0)),
					item_conditions:stockAcceptanceLineItem.item_conditions,
				}))
			);
		}
		dispatch(fetchAllStockAcceptanceLineItemsAsync({stock_acceptance_id:stockAcceptance.id})).then((response) => handleResponse(response));
		setIsEditable(finishedGoodProduced.is_editable);
		setIsAuthorizable(finishedGoodProduced.id ? auth.approval_pipelines.find((pipe) => pipe.finished_good_produced_id===finishedGoodProduced.id)!==undefined : false);
		setLineItems( finishedGoodProducedLineItems.map((finishedGoodProducedLineItem) => ({
			id:finishedGoodProducedLineItem.id,
			item:{
				label:finishedGoodProducedLineItem.item.label, 
				default_uom_name:finishedGoodProducedLineItem.item.unit_of_measure.name, 
				item_costing:finishedGoodProducedLineItem.item.item_costing, 
				serial_number_type:finishedGoodProducedLineItem.item.serial_number_type, 
				value:finishedGoodProducedLineItem.item.id
			},
			quantity:finishedGoodProducedLineItem.quantity,
			unit_cost:finishedGoodProducedLineItem.unit_cost,
			amount:finishedGoodProducedLineItem.amount,
			uom:{label:finishedGoodProducedLineItem.unit_of_measure.code, value:finishedGoodProducedLineItem.unit_of_measure.id},
			location:{label:`${finishedGoodProducedLineItem.location.code} | ${finishedGoodProducedLineItem.location.name}`, value:finishedGoodProducedLineItem.location.id},
			serial_numbers:finishedGoodProducedLineItem.serial_numbers,
			expiry_dates:finishedGoodProducedLineItem.expiry_dates.map((expDate) => (expDate.length>0 ? moment(expDate, resources.date_format.SYSTEM).format(resources.date_format.INPUT) : "")),
			expiry_quantities:finishedGoodProducedLineItem.expiry_quantities.map((expQty) => (expQty.length>0 ? parseFloat(expQty) : 0)),
			item_conditions:finishedGoodProducedLineItem.item_conditions,
		})) );
		setHasExpirySerialized(false);
		setWithExpiry(false);
		setSerialized(false);
		for (let i=0; i<finishedGoodProducedLineItems.length; i++) {
			let finishedGoodProducedLineItem = finishedGoodProducedLineItems[i];
			if (finishedGoodProduced.approval_status===auth.enums.approval_status.DRAFT) {
				let inventoryItem = items.find((itm) => itm.id===finishedGoodProducedLineItem.item.id);
				if (inventoryItem) {
					let hasExpiry = Util.stringToBoolean(inventoryItem.has_expiry);
					let withSerialNumber = Util.stringToBoolean(inventoryItem.with_serial_number);
					if (hasExpiry && withSerialNumber) {
						setHasExpirySerialized(true);
					} else 
					if (withSerialNumber) {
						if (inventoryItem.serial_number_type===auth.enums.serial_number_types.EXTERNAL) setSerialized(true);
					} else 
					if (hasExpiry) {
						setWithExpiry(true);
					}
				}
			}
		}
		setBalancingAccount(`${auth.enums.balancing_accounts.finished_good_produced.DIRECT_LABOR_COST} + ${auth.enums.balancing_accounts.finished_good_produced.OVERHEAD_COST}`);
		setJournalEntries(journalEntries.map((journalEntry) => ({
			account_code:journalEntry.account.code,
			account_description:journalEntry.account.description,
			profit_cost_center:JSON.parse(journalEntry.profit_cost_center),
			debit_amount:journalEntry.debit_amount,
			credit_amount:journalEntry.credit_amount,
		})));
	},[dispatch, auth.enums.serial_number_types.EXTERNAL, items, auth.enums.balancing_accounts, finished_good_items, auth.approval_pipelines, auth.enums.approval_status.DRAFT]);

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.FINISHED_GOOD_PRODUCED));
	}, [
		dispatch,
		translate.breadcrumb.FINISHED_GOOD_PRODUCED
	]);

	useEffect(() => {
		dispatch(fetchAllLocationsAsync({})).then((response) => response.payload.data ? setLocations(response.payload.data.locations) : void(0));
		const handleItemsResponse = (response) => {
			if (response.payload.data) {
				let itms = response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)));
				setItems(itms);
				setFinishedGoodItems(itms.map((item) => ({label:item.label, value:item.id})));
			}
		}
		dispatch(fetchAllItemsAsync({})).then((response) => handleItemsResponse(response));
	}, [
		dispatch,
		translate.text.FINISHED_GOOD_PRODUCED,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		let totalQty = line_items.reduce((totalQty, lineItem) => totalQty+=lineItem.quantity.length>0 ? parseFloat(lineItem.quantity) : 0, 0);
		setActualProduction(totalQty.toFixed(4));
		setFinishedGoodUnitPrice((totalQty>0&&total_production_cost.length>0 ? total_production_cost/totalQty : 0).toFixed(2));
	}, [line_items, total_production_cost]);

	useEffect(() => {
		if (id) {
			dispatch(fetchAllFinishedGoodProducedLineItemsAsync({finished_good_produced_id:id})).then((response) => response.payload.data ? loadDocument(response.payload.data.finished_good_produced, response.payload.data.finished_good_produced_line_items, response.payload.data.journal_entries) : void(0));
		}
	}, [dispatch, id, loadDocument]);

	const refreshData = () => {
		handleClearButtonClickedEvent();
	}

	const handleUpdateButtonClickedResponse = (response) => {
		let doc = response.payload.data.finished_goods_produced;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.finished_goods_produced.id ? [
				doc.id,
				doc.document_number,
				doc.document_date,
				doc.approval_status,
				doc.reference_number,
				doc.finished_good_item ? doc.finished_good_item.description : "",
				doc.description,
				doc.created_at,
				doc.updated_at,
			] : ds))
		});
		loadDocument(response.payload.data.finished_goods_produced, response.payload.data.finished_goods_produced_line_items, response.payload.data.journal_entries);
		setAttachments([]);
	}

	const handleCreateButtonClickedResponse = (response) => {
		loadDocument(response.payload.data.finished_goods_produced, response.payload.data.finished_goods_produced_line_items, response.payload.data.journal_entries);
		setAttachments([]);
		dispatch(fetchAllFinishedGoodProducesAsync({})).then((response) => dispatch(initializeWorksheet({function_title:translate.text.FINISHED_GOODS_PRODUCED, data:response.payload.data.finished_goods_produced})));
	}

	const clearInputFields = () => {
		setId(null);
		setFinishedGoodProduced(null);
		setDocumentDate(moment(new Date()).format(resources.date_format.INPUT));
		setReferenceNumber("");
		setTransactionDescription("");
		setFinishedGoodsProduced(null);
		setYieldQuantity("");
		setYieldUom("");
		setNumberOfBatches("");
		setStandardProduction("");
		setActualProduction("");
		setDirectLaborCost("");
		setTotalProductionCost("");
		setOverheadCost("");
		setFinishedGoodUnitPrice("");
		setSelectedDocumentNumber("");
		setSelectedDocumentIds([]);
		setComponentLineItems(default_component_line_items);
		setJournalEntries(default_journal_entries);
		setLineItems(default_line_items);
		setAttachments([]);
		setIsEditable(true);
		setIsAuthorizable(false);
		setBalancingAccount("");
		setSerialized(false);
		setWithExpiry(false);
		setHasExpirySerialized(false);
	}

	const handleClearButtonClickedEvent = () => {
		clearInputFields();
		setTransactionSource(null);
		history.push('/warehouse-inventory/finished-goods-produced');
	};

	const handleDeleteRowsButtonClickedEvent = () => {
		let rows = selected_rows.map(
			(row) => auth.worksheet.data_set[row]
		);
		let promises = [];
		for (let i = 0; i < rows.length; i++) {
			promises.push(
				dispatch(
					voidFinishedGoodProducedAsync({
						id: rows[i][0]
					})
				)
			);
		}
		Promise.all(promises).then((response) => {
			var success = true;
			var message = null;
			for (let i = 0; i < response.length; i++) {
				if (response[i].payload !== undefined) {
					if (response[i].payload.status !== resources.status.SUCCESS) {
						success = false;
						break;
					}
					else {
						if (message == null) {
							message = response[i].payload.message;
						}
					}
				}
			}
			if (success === true) {
				toast.success(
					message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			handleClearButtonClickedEvent();
		});
	};

	const handleSubmitButtonClickedEvent = () => {
		dispatch(
			submitFinishedGoodProducedAsync({
				id:id,
				document_date:document_date,
				selected_document_ids:selected_document_ids,
				reference_number:reference_number,
				finished_goods_produced:finished_goods_produced,
				number_of_batches:number_of_batches,
				standard_production:standard_production,
				actual_production:actual_production,
				direct_labor_cost:direct_labor_cost,
				overhead_cost:overhead_cost,
				total_production_cost:total_production_cost,
				finished_good_unit_price:finished_good_unit_price,
				transaction_description:transaction_description,
				line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null)),
				attachments:attachments,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleApproveButtonClickedEvent = () => {
		dispatch(
			approveFinishedGoodProducedAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleRejectButtonClickedEvent = () => {
		dispatch(
			rejectFinishedGoodProducedAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleVoidButtonClickedEvent = () => {
		dispatch(
			voidFinishedGoodProducedAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleEditButtonClickedEvent = async (row) => {
		let id = auth.worksheet.data_set[row][0];
		dispatch(fetchAllFinishedGoodProducedLineItemsAsync({finished_good_produced_id:id})).then((response) => loadDocument(response.payload.data.finished_good_produced, response.payload.data.finished_good_produced_line_items, response.payload.data.journal_entries));
		window.scrollTo(0,0);
	};

	const handleDirectLaborCostChangedEvent = (directLaborCost) => {
		let totalProductionCost = (directLaborCost ? parseFloat(directLaborCost) : 0) + (overhead_cost ? parseFloat(overhead_cost) : 0) + (materials_cost ? parseFloat(materials_cost) : 0);
		let finishedGoodUnitPrice = actual_production&&actual_production>0 ? (totalProductionCost/actual_production) : 0;
		setDirectLaborCost(directLaborCost);
		setTotalProductionCost(totalProductionCost.toFixed(2));
		setFinishedGoodUnitPrice(finishedGoodUnitPrice.toFixed(2));
	}

	const handleOverheadCostChangedEvent = (overHeadCost) => {
		let totalProductionCost = (direct_labor_cost ? parseFloat(direct_labor_cost) : 0) + (overHeadCost ? parseFloat(overHeadCost) : 0) + (materials_cost ? parseFloat(materials_cost) : 0);
		let finishedGoodUnitPrice = actual_production&&actual_production>0 ? (totalProductionCost/actual_production) : 0;
		setOverheadCost(overHeadCost);
		setTotalProductionCost(totalProductionCost.toFixed(2));
		setFinishedGoodUnitPrice(finishedGoodUnitPrice.toFixed(2));
	}

	const handleProcessSelectedDocuments = (selectionObject) => {
		Util.sortByKey(selectionObject.selectedRows, 'id');
		let ERROR = null;
		let selected_stock_acceptance = null;
		let selected_ids = [];
		let selected_document_numbers = [];
		let selected_descriptions = [];
		let selected_reference_numbers = [];
		let selected_line_item_indices = [];
		let selected_line_items = [];
		let i = 0;
		if (!selected_ids.includes(selectionObject.selectedRows[i].id)) selected_ids.push(selectionObject.selectedRows[i].id);
		if (!selected_document_numbers.includes(selectionObject.selectedRows[i].document_number)) selected_document_numbers.push(selectionObject.selectedRows[i].document_number);
		if (!selected_descriptions.includes(selectionObject.selectedRows[i].transaction_description)) selected_descriptions.push(selectionObject.selectedRows[i].transaction_description);
		if (!selected_reference_numbers.includes(selectionObject.selectedRows[i].reference_number)) selected_reference_numbers.push(selectionObject.selectedRows[i].reference_number);
		const handleResponse = (response) => {
			selected_stock_acceptance = response.payload.data.stock_acceptance;
			const fetchItemResponse = (response) => {
				let finishedGoodItemObj = response.payload.data.item;
				setYieldUnitOfMeasure(finishedGoodItemObj.yield_unit_of_measure);
				setFinishedGoodsProduced({label:finishedGoodItemObj.label, default_uom_name:finishedGoodItemObj.unit_of_measure.name, item_costing:finishedGoodItemObj.item_costing, unit_cost:finishedGoodItemObj.unit_cost, value:finishedGoodItemObj.id});
				setYieldQuantity(parseFloat(finishedGoodItemObj.yield_quantity).toFixed(4));
				setYieldUom(finishedGoodItemObj.yield_unit_of_measure.code);
			}
			if (selected_stock_acceptance) {
				dispatch(fetchItemAsync({id:selected_stock_acceptance.stock_issuances[0].finished_good_item.id})).then((response) => fetchItemResponse(response));
			}
			setNumberOfBatches(selected_stock_acceptance.stock_issuances[0].number_of_batches);
			setStandardProduction(selected_stock_acceptance.stock_issuances[0].standard_production);
			setMaterialsCost(selected_stock_acceptance.stock_issuances[0].authorization_amount);
			let stockAcceptanceLineItems = response.payload.data.stock_acceptance_line_items;;
			setStockAcceptanceId(selectionObject.selectedRows[i].id);
			let lineItems = stockAcceptanceLineItems.map((stockAcceptanceLineItem) => ({
				item:{value:stockAcceptanceLineItem.item.id, label:`${stockAcceptanceLineItem.item.code} | ${stockAcceptanceLineItem.item.description}`},
				quantity:stockAcceptanceLineItem.quantity,
				unit_cost:stockAcceptanceLineItem.unit_cost,
				amount:(parseFloat(stockAcceptanceLineItem.quantity)*parseFloat(stockAcceptanceLineItem.unit_cost)).toFixed(2),
				uom:{value:stockAcceptanceLineItem.unit_of_measure.id, label:stockAcceptanceLineItem.unit_of_measure.code},
				location:{value:stockAcceptanceLineItem.location.id, label:`${stockAcceptanceLineItem.location.code} | ${stockAcceptanceLineItem.location.name}`},
				serial_numbers:[],
				expiry_quantities:[],
				expiry_dates:[],
				item_conditions:[],
			}));
			for (let j=0; j<lineItems.length; j++) {
				if (selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`]===undefined) {
					selected_line_items.push(lineItems[j]);
					selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`] = selected_line_items.length-1;
				}
				else {
					let index = selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`];
					let selectedLineItem = selected_line_items[index];
					selected_line_items[index] = selectedLineItem;
				}
			}
			setComponentLineItems(selected_line_items);
		}
		dispatch(fetchAllStockAcceptanceLineItemsAsync({stock_acceptance_id:selectionObject.selectedRows[i].id})).then((response) => handleResponse(response));
		setBalancingAccount(`${auth.enums.balancing_accounts.finished_good_produced.DIRECT_LABOR_COST} + ${auth.enums.balancing_accounts.finished_good_produced.OVERHEAD_COST}`);
		if (ERROR) {
			toast.error(
				ERROR,
				Util.getToasterStyle(resources.status.ERROR)
			);
		}
		else {
			setSelectedDocumentIds(selected_ids);
			setSelectedDocumentNumber(selected_document_numbers.join(", "));
			setReferenceNumber(selected_reference_numbers.join(", "));
			setTransactionDescription(selected_descriptions.join(", "));
			setIsDatatableDialogOpen(false);
		}
	};

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{ Util.functionTitle(translate.text.FINISHED_GOOD_PRODUCED) }
				<div className="function-sub-title">
					{translate.text.FINISHED_GOOD_PRODUCED_SUBTITLE}
				</div>

				{
					auth.worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						{
							id===null ?
							<>
								<Button
									text={translate.button.CREATE_DRAFT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING||!is_editable}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={(e) =>
										dispatch(
											createFinishedGoodProducedAsync({
												document_date:document_date,
												selected_document_ids:selected_document_ids,
												reference_number:reference_number,
												finished_goods_produced:finished_goods_produced,
												number_of_batches:number_of_batches,
												standard_production:standard_production,
												actual_production:actual_production,
												direct_labor_cost:direct_labor_cost,
												overhead_cost:overhead_cost,
												total_production_cost:total_production_cost,
												finished_good_unit_price:finished_good_unit_price,
												transaction_description:transaction_description,
												line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null)),
												attachments:attachments,
											})
										).then((response) => (response.payload.status===resources.status.SUCCESS ? handleCreateButtonClickedResponse(response) : null))
									}
								/>
							</>
							:
							<>
								{
									is_editable &&
									<Button
										text={translate.button.UPDATE}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="floppy-disk" className="orange"/>}
										onClick={(e) =>
											dispatch(
												updateFinishedGoodProducedAsync({
													id:id,
													document_date:document_date,
													selected_document_ids:selected_document_ids,
													reference_number:reference_number,
													finished_goods_produced:finished_goods_produced,
													number_of_batches:number_of_batches,
													standard_production:standard_production,
													actual_production:actual_production,
													direct_labor_cost:direct_labor_cost,
													overhead_cost:overhead_cost,
													total_production_cost:total_production_cost,
													finished_good_unit_price:finished_good_unit_price,
													transaction_description:transaction_description,
													line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null)),
													attachments:attachments,
												})
											).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateButtonClickedResponse(response) : null))
										}
									/>
								}
								{
									is_editable &&
									<Button
										text={translate.button.SUBMIT}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="send-message" className="orange"/>}
										onClick={e => setIsConfirmSubmitDialogOpen(!is_confirm_submit_dialog_open)}
									/>
								}
								<Button
									text={translate.button.PRINT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="print" className="orange"/>}
									onClick={() =>
										PDF.exportFinishedGoodProducedForm(
											translate.worksheet.file_name.FINISHED_GOOD_PRODUCED,
											[finished_good_produced],
											line_items,
											auth.active_company
										)
									}
								/>
								{
									is_editable &&
									<Button
										text={translate.button.VOID}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="ban-circle" className="orange"/>}
										onClick={e => setIsConfirmVoidDialogOpen(!is_confirm_void_dialog_open)}
									/>
								}
								{
									finished_good_produced.approval_status===auth.enums.approval_status.FOR_APPROVAL && is_authorizable &&
									<>
										<Button
											text={translate.button.APPROVE}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="tick" className="orange"/>}
											onClick={e => setIsConfirmApproveDialogOpen(!is_confirm_approve_dialog_open)}
										/>
										<Button
											text={translate.button.REJECT}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="cross" className="orange"/>}
											onClick={e => setIsConfirmRejectDialogOpen(!is_confirm_reject_dialog_open)}
										/>
									</>
								}
							</>
						}
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleClearButtonClickedEvent}
						/>
					</ButtonGroup>
				}

				{
					(with_expiry_serialized || serialized || with_expiry) &&
					<div style={{textAlign:"center", marginBottom:20, color:"blue"}}>
						{
							with_expiry_serialized &&
							<div>
								Your next step is to assign expiry date/s to corresponding serial number/s.
							</div>
						}
						{
							serialized &&
							<div>
								Your next step is to assign serial number/s to corresponding quantity.
							</div>
						}
						{
							with_expiry &&
							<div>
								Your next step is to assign expiry date to each quantity.
							</div> 
						}
					</div>
				}

				{
					auth.worksheet.is_readonly_mode &&
					<div className="function-card">
						<div className="row">
							<Card
								className="col-sm-12 col-md-4 col-lg-3"
								style={{
									background: "lightblue",
								}}
							>
								<FormGroup>
									<H3 className="center" style={{color:"blue"}}>{finished_good_produced!==null ? finished_good_produced.document_number : null}</H3>
									<H5 className="center" style={{color:"green"}}>{finished_good_produced!==null ? finished_good_produced.approval_status : null}</H5>
								</FormGroup>

								<div className="row mb-5">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										id="document_date"
										label={translate.placeholder.PICK_A_DATE}
										type="date"
										variant="outlined"
										size="small"
										defaultValue={document_date}
										inputProps={{readOnly:!is_editable}}
										onChange={(e) => setDocumentDate(e.target.value)}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={reference_number}
										label={translate.placeholder.ENTER_TRANSACTION_REFERENCE_NUMBER}
										onChange={(e) =>
											setReferenceNumber(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
						        </div>

								<div className="row mb-5 mt-5">
									<TextField
										className="col-sm-12 col-md-12 col-lg-12"
										size="small"
										variant="outlined"
										label={translate.placeholder.NUMBER_OF_BATCHES}
										value={number_of_batches}
										inputProps={{readOnly:true}}
									/>
								</div>

								<ControlGroup vertical={false} className="col-sm-12 col-md-12 col-lg-12">
									<TextField
										inputProps={{readOnly:true}}
										size="small"
										variant="outlined"
										value={selected_document_number}
										label={"Search a document"}
										style={{width:"90%"}}
									/>
									<Button
										minimal={true}
										large={true}
										text={translate.button.SEARCH}
										icon={<Icon icon="search" className="orange"/>}
										disabled={auth.status === resources.status.LOADING||id!==null}
										onClick={e => setIsDatatableDialogOpen(true)}
									/>
								</ControlGroup>

								<div className="row mt-5">
									<div className="col-sm-12 col-md-12 col-lg-12">
										<MuiAutocomplete
											isDisabled={true}
											selected_value={finished_goods_produced}
											isMultiple={false}
											label={translate.placeholder.FINISHED_GOODS_PRODUCED}
											data={finished_good_items}
										/>
									</div>
								</div>

								<div className="row">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										label={translate.placeholder.YIELD_QUANTITY}
										value={yield_quantity}
										inputProps={{readOnly:true}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										label={translate.placeholder.YIELD_UOM}
										value={yield_uom}
										inputProps={{readOnly:true}}
									/>
								</div>

								<div className="row">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										label={translate.placeholder.STANDARD_PRODUCTION}
										value={standard_production}
										inputProps={{readOnly:true}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										label={translate.placeholder.ACTUAL_PRODUCTION}
										value={actual_production}
										inputProps={{readOnly:true}}
									/>
								</div>

								<div className="row">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										label={translate.placeholder.DIRECT_LABOR_COST}
										value={direct_labor_cost}
										onChange={(e) => handleDirectLaborCostChangedEvent(e.target.value)}
										type="number"
										inputProps={{readOnly:!is_editable}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										label={translate.placeholder.TOTAL_PRODUCTION_COST}
										value={total_production_cost}
										inputProps={{readOnly:true}}
									/>
								</div>

								<div className="row mb-5">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										type="number"
										variant="outlined"
										label={translate.placeholder.OVERHEAD_COST}
										value={overhead_cost}
										onChange={(e) => handleOverheadCostChangedEvent(e.target.value)}
										inputProps={{readOnly:!is_editable}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										label={translate.placeholder.FINISHED_GOOD_UNIT_PRICE}
										value={finished_good_unit_price}
										inputProps={{readOnly:true}}
									/>
								</div>

						        <div className="row mb-5 mt-5">
									<TextField
										multiline
										minRows={4}
										className="col-sm-12 col-md-12 col-lg-12"
										size="small"
										variant="outlined"
										label={translate.placeholder.ENTER_TRANSACTION_DESCRIPTION}
										value={transaction_description}
										onChange={(e) =>
											setTransactionDescription(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<Attachment
									id={id}
									transaction={finished_good_produced}
									setTransaction={setFinishedGoodProduced}
									attachments={attachments}
									setAttachments={setAttachments}
								/>

								{
									id!==null && finished_good_produced &&
									<>
										<Comment
											id={id}
											document_type={finished_good_produced.document_type.name}
											comments={finished_good_produced.comments}
											setDocument={setFinishedGoodProduced}
											is_editable={is_editable}
										/>
										<TransactionLog
											transaction_logs={transaction_logs}
										/>
				                    </>
								}

							</Card>
							{
								<FinishedGoodProducedLineItem
									id={id}
									is_editable={is_editable}
									finished_goods_produced={finished_goods_produced}
									finished_good_unit_price={finished_good_unit_price}
									total_production_cost={total_production_cost}
									stock_acceptance_id={stock_acceptance_id}
									yield_unit_of_measure={yield_unit_of_measure}
									default_line_items={default_line_items}
									component_line_items={component_line_items}
									line_items={line_items}
									setLineItems={setLineItems}
									default_journal_entries={default_journal_entries}
									journal_entries={journal_entries}
									setJournalEntries={setJournalEntries}
									balancing_account={balancing_account}
									setBalancingAccount={setBalancingAccount}
									locations={locations}
									_items={items}
								/>
							}
						</div>
					</div>
				}

				<Card className="function-card-spreadsheet">
					<FinishedGoodProducedSpreadsheet
						enable_select={true}
						enable_edit={true}
						selected_rows={selected_rows}
						setSelectedRows={setSelectedRows}
						handleEditButtonClickedEvent={handleEditButtonClickedEvent}
						handleClearButtonClickedEvent={handleClearButtonClickedEvent}
						worksheet={worksheet}
						setWorkSheet={setWorkSheet}
						refreshData={refreshData}
						is_confirm_delete_dialog_open={is_confirm_delete_dialog_open}
						setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
						DEFAULT_DATA_SET={DEFAULT_DATA_SET}
					/>
				</Card>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_delete_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDeleteDialogOpen}
					confirmAction={handleDeleteRowsButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted/voided, document will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain document is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_submit_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmSubmitDialogOpen}
					confirmAction={handleSubmitButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">SUBMIT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_approve_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmApproveDialogOpen}
					confirmAction={handleApproveButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">APPROVE</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_reject_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmRejectDialogOpen}
					confirmAction={handleRejectButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">REJECT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_void_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmVoidDialogOpen}
					confirmAction={handleVoidButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">VOID</span> this document ?
						</span>
					}
				/>

				<DatatableDialog
					is_datatable_open={is_datatable_open}
					setIsDatatableDialogOpen={setIsDatatableDialogOpen}
					transaction_source={transaction_source}
					handleProcessSelectedDocuments={handleProcessSelectedDocuments}
				/>

			</div>
		</div>
	);
}
