import React from "react";
import { Dialog, Classes, Button, Icon } from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

export function ConfirmDialog({is_confirm_dialog_open, setIsConfirmDialogOpen, confirmAction, helperText, confirmMessage}) {

    const handleConfirmButtonClickedEvent = () => {
        setIsConfirmDialogOpen(false);
        confirmAction();
    };

    return (
        <Dialog
            title="Confirm Action"
            icon={<Icon className="orange" icon="info-sign"/>}
            isOpen={is_confirm_dialog_open}
            onClose={e => setIsConfirmDialogOpen(false)}
            isCloseButtonShown={true}
        >
            <div className={Classes.DIALOG_BODY}>
                {
                    helperText
                }
                <div className="center">
                    {confirmMessage ? confirmMessage : "Are you sure you want to perform this action?"}
                </div>
            </div>
            <div style={{textAlign:"center"}}>
                <Button
                    icon={<Icon className="orange" icon="cross"/>}
                    onClick={e => setIsConfirmDialogOpen(false)}
                >
                    {"Cancel"}
                </Button>
                <Button
                    style={{marginLeft:"10px"}}
                    icon={<Icon className="orange" icon="tick"/>}
                    onClick={handleConfirmButtonClickedEvent}
                >
                    {"Confirm"}
                </Button>
            </div>
        </Dialog>
    );
}