import React from "react";
import Util from "../../common/Util";
import { resources } from "../../resources/index";
import toast from "react-hot-toast";
import { useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { acceptInvitationAsync } from "./authSlice";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

export function AcceptInvitation() {
	const { company_id } = useParams();
	const { user_id } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const activationResponse = useCallback(
		(response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				toast.success(
					response.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			history.push("/login");
		},
		[history]
	);

	useEffect(() => {
		if (company_id && user_id) {
			dispatch(acceptInvitationAsync({company_id:company_id, user_id:user_id})).then((response) =>
				activationResponse(response)
			);
		}
	}, [dispatch, company_id, user_id, activationResponse]);

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<div className="main-container">
			</div>
		</div>
	);
}