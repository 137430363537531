import React, {useState} from "react";
import { Collapse, Callout, Button, Icon } from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

export function Note({contents}) {
    const [is_collapse_open, setIsCollapseOpen] = useState(false);

    const noteContent = (key, label, value) => {
        return (
            <div key={key}>
                <span style={{fontWeight:"bold", color:"blue"}}>{label}</span> - {value}
            </div>
        );
    }

    return (
        <div className="note-container">
            <Collapse className="note-collapse" isOpen={is_collapse_open}>
                <Callout icon={<Icon icon="info-sign" style={{color:"orange"}}/>} intent="warning" className='note-content'>
                    {contents.map((content, key) => noteContent(key, content.label, content.value))}
                </Callout>
            </Collapse>
            <Button
                minimal={true}
                icon={<Icon icon="help" style={{color:"orange"}}/>}
                text={<span className="note-text"></span>}
                round={true}
                onClick={() => setIsCollapseOpen(!is_collapse_open)}
            />
        </div>
    );
}
