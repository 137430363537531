import React, { useCallback, useEffect, useState } from 'react';
import PDF from '../../../common/PDF';
import Excel from '../../../common/Excel';
import Util from '../../../common/Util';
import { Note } from "../../../common/Note";
import { useDispatch, useSelector } from 'react-redux';
import {
	selectAuth,
	updateNavigation,
	toggleSidebarCollapsed,
	fetchSubscriptionAsync,
	updateSubscriptionAsync,
	fetchAllSubscriptionsAsync
} from '../../auth/authSlice';
import { resources } from '../../../resources/index';
import {
	Button,
	ButtonGroup,
	Card,
	Icon,
	Checkbox
} from '@blueprintjs/core';
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/table/lib/css/table.css';
import { SideBar } from '../../../SideBar';
import { SubscriptionSpreadsheet } from '../../../spreadsheets/SubscriptionSpreadsheet';
import TextField from "@material-ui/core/TextField";
import moment from "moment";

export function Subscriptions() {

	const auth = useSelector(selectAuth);
	const translate = resources['lang'][auth.locale];
	const dispatch = useDispatch();
	const [selected_rows, setSelectedRows] = useState([]);
	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});
	const [id, setId] = useState(null);
	const [company_name, setCompanyName] = useState("");
	const [subscriber_name, setSubscriberName] = useState("");
	const [subscriber_email, setSubscriberEmail] = useState("");
	const [warehouse_inventory, setWarehouseInventory] = useState(false);
	const [point_of_sale, setPointOfSale] = useState(false);
	const [expiry_date, setExpiryDate] = useState("");
	const [industry, setIndustry] = useState("");
	const [country, setCountry] = useState("");
	const [number_of_pos_terminal, setNumberOfPosTerminal] = useState("");

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.MANAGE_SUBSCRIPTIONS));
		dispatch(fetchAllSubscriptionsAsync({all:true}))
		.then((response) => response.payload.data ? 
			setWorkSheet({
				is_readonly_mode: true,
				num_rows: 1000,
				num_cols: 10,
				num_frozen_columns: 0,
				import_limit: 700,
				focused_cell: null,
				selected_regions: null,
				header: translate.worksheet.default_header.MANAGE_SUBSCRIPTIONS,
				alignment: translate.worksheet.default_alignment.MANAGE_SUBSCRIPTIONS,
				comment: [],
				data_set: response.payload.data.subscriptions.map((ds) => [
					ds.id,
					ds.user.email,
					`${ds.user.first_name} ${ds.user.last_name}`,
					ds.company.name,
					ds.company.industry,
					ds.company.country.name,
					ds.expires_at,
					ds.company.inventory===1 ? "Purchased" : "",
					ds.company.pos===1 ? "Purchased" : "",
					ds.company.number_of_pos_terminal,
				])
			}) : void(0)
		);
	}, [
		dispatch,
		translate.breadcrumb.MANAGE_SUBSCRIPTIONS,
		translate.worksheet.default_alignment.MANAGE_SUBSCRIPTIONS,
		translate.worksheet.default_header.MANAGE_SUBSCRIPTIONS
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const onEditButtonClicked = (row) => {
		const loadSubscription = (subscription) => {
			setId(subscription.id);
			setSubscriberEmail(subscription.user.email);
			setSubscriberName(`${subscription.user.first_name} ${subscription.user.last_name}`);
			setCompanyName(subscription.company.name);
			setIndustry(subscription.company.industry);
			setCountry(subscription.company.country.name);
			if (subscription.expires_at) {
				setExpiryDate(moment(subscription.expires_at, resources.date_format.SYSTEM).format(resources.date_format.INPUT));
			}
			else {
				setExpiryDate("");
			}
			setNumberOfPosTerminal(subscription.company.number_of_pos_terminal);
			setWarehouseInventory(subscription.company.inventory===1);
			setPointOfSale(subscription.company.pos===1);
			window.scrollTo(0,0);
		}
		dispatch(fetchSubscriptionAsync({id:worksheet.data_set[row][0]})).then((response) => loadSubscription(response.payload.data.subscription));
	};

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const handleUpdateSubscription = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				let subscription = response.payload.data.subscription;
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: worksheet.data_set.map((ds) => (ds[0]===subscription.id ? [
						subscription.id,
						subscription.user.email,
						`${subscription.user.first_name} ${subscription.user.last_name}`,
						subscription.company.name,
						subscription.company.industry,
						subscription.company.country.name,
						subscription.expires_at,
						subscription.company.inventory===1 ? "Purchased" : "",
						subscription.company.pos===1 ? "Purchased" : "",
						subscription.company.number_of_pos_terminal,
					] : ds))
				});
			}
		}
		dispatch(updateSubscriptionAsync({id:id, expiry_date:expiry_date, number_of_pos_terminal:number_of_pos_terminal, inventory:warehouse_inventory ? true : "", pos:point_of_sale ? true : ""})).then((response) => handleResponse(response));
	}

	const handleClearButtonClickedEvent = () => {
		setId(null);
		setSubscriberEmail("");
		setSubscriberName("");
		setCompanyName("");
		setIndustry("");
		setCountry("");
		setExpiryDate("");
		setNumberOfPosTerminal("");
		setWarehouseInventory(false);
		setPointOfSale(false);
	};

	const handlePOSChangedEvent = () => {
		if (point_of_sale) {
			setNumberOfPosTerminal("");
		}
		setPointOfSale(!point_of_sale);
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						}
					]}
				/>

				{ Util.functionTitle(translate.text.MANAGE_SUBSCRIPTIONS) }

				<ButtonGroup className="button-group" minimal={false}>
					{
						id!==null &&
						<Button
							text={translate.button.UPDATE}
							className="function-button"
							disabled={auth.status === resources.status.LOADING}
							icon={<Icon icon="floppy-disk" className="orange"/>}
							onClick={() => handleUpdateSubscription()}
						/>
					}
					<Button
						text={"Reset"}
						className="function-button"
						icon={<Icon icon="reset" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={handleClearButtonClickedEvent}
					/>
				</ButtonGroup>

				<Card className="function-card">
					{
						id!==null ?
						<>
							<div className='row'>
								<div className='col-lg-4 col-md-4 col-sm-12'>
									<div className='ml-5'>{company_name}</div>
									<div className='ml-5'>{subscriber_name} {subscriber_email.length>0 ? `<${subscriber_email}>` : ''}</div>
									<div className='ml-5'>{industry}</div>
									<div className='ml-5'>{country}</div>
								</div>
								<div className='col-lg-8 col-md-8 col-sm-12'>
									<div>
										<TextField
											className="mb-5 mt-5"
											id="expiry_date"
											style={{width:200}}
											label={"Subscription Expiry Date"}
											type="date"
											variant="outlined"
											size="small"
											value={expiry_date}
											onChange={(e) => setExpiryDate(e.target.value)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</div>
									<div>
										<Checkbox
											checked={ warehouse_inventory }
											large={true}
											inline={true}
											label="Warehouse Inventory"
											onChange={() => setWarehouseInventory(!warehouse_inventory)}
										/>
									</div>
									<div>
										<Checkbox
											checked={ point_of_sale }
											large={true}
											inline={true}
											label="Point of Sale"
											onChange={() => handlePOSChangedEvent()}
										/>
									</div>
									{
										point_of_sale &&
										<div>
											<TextField
												type='number'
												size="small"
												variant="outlined"
												value={number_of_pos_terminal}
												onChange={(e) => setNumberOfPosTerminal(e.target.value)}
												label={"Number of POS Terminal"}
											/>
										</div>
									}
								</div>
							</div>
						</> :
						<h4 style={{textAlign:"center"}}>Please select from the spreadsheet the subscription you want to edit.</h4>
					}
				</Card>

				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={translate.button.SAVE_AS_PDF}
						className="function-button"
						icon={<Icon icon="document" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={
							e => PDF.export(
								translate.worksheet.file_name.MANAGE_SUBSCRIPTIONS,
								worksheet.header,
								worksheet.data_set,
								auth.active_company,
								"a3",
								"landscape",
								[],
								[30, 120, 120, "*", 70, 70, 80, 70, 70, 50]
							)
						}
					/>
					<Button
						text={translate.button.SAVE_AS_XLSX}
						className="function-button"
						icon={<Icon icon="th" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={
							e => Excel.export(
								translate.worksheet.file_name.MANAGE_SUBSCRIPTIONS,
								worksheet.header,
								worksheet.data_set,
								auth.active_company
							)
						}
					/>
					<Button
						text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
						className="function-button"
						icon={<Icon icon="panel-table" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={onFreezePaneButtonClicked}
					/>
				</ButtonGroup>

				<SubscriptionSpreadsheet
					enable_select={false}
					enable_edit={true}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
					onEditButtonClicked={onEditButtonClicked}
				/>

			</div>
		</div>
	);

}
