import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createBeginningBalanceAsync,
	updateBeginningBalanceAsync,
	submitBeginningBalanceAsync,
	rejectBeginningBalanceAsync,
	approveBeginningBalanceAsync,
	voidBeginningBalanceAsync,
	fetchAllStockAdjustmentLineItemsAsync,
	toggleSidebarCollapsed,
	skipProcessAsync,
	fetchAllItemsAsync,
	fetchAllLocationsAsync,
	fetchAllProfitCostCentersAsync,
	importBeginningBalancesAsync,
	fetchAllBeginningBalancesAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	H3,
	H5,
	FormGroup,
	Icon,
	Switch
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { BeginningBalanceLineItem } from "./BeginningBalanceLineItem";
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { Attachment } from "../../../common/Attachment";
import { Comment } from "../../../common/Comment";
import { TransactionLog } from "../../../common/TransactionLog";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { BeginningBalanceSpreadsheet } from "../../../spreadsheets/BeginningBalanceSpreadsheet";

export function BeginningBalance() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const default_line_items = Array(7).fill({
		id:"",
		item:null,
		quantity:"",
		unit_cost:"",
		amount:"",
		uom:null,
		location:null,
		serial_numbers:[],
		expiry_dates:[],
		expiry_quantities:[],
		item_conditions:[],
	});
	const default_journal_entries = Array(1).fill({
		account_code:"",
		account_description:"",
		profit_cost_center:null,
		debit_amount:"",
		credit_amount:"",
	});
	//const [current_application] = useState(auth.applications.find((app) => app.name===translate.text.BEGINNING_BALANCE));
	const { document_id } = useParams();
	const [id, setId] = useState(document_id ? document_id : null);
	const [stock_adjustment, setStockAdjustment] = useState(null);
	const [line_items, setLineItems] = useState(default_line_items);
	const [items, setItems] = useState([]);
	const [locations, setLocations] = useState([]);
	const [document_date, setDocumentDate] = useState(moment(new Date(auth.general_settings.beginning_balance_date)).format(resources.date_format.INPUT));
	const [reference_number, setReferenceNumber] = useState("");
	const [transaction_description, setTransactionDescription] = useState("");
	const [transaction_logs, setTransactionLogs] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [selected_rows, setSelectedRows] = useState([]);
	//const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);
	//const [is_confirm_delete_dialog_open, setIsConfirmDeleteDialogOpen] = useState(false);
	const [is_confirm_submit_dialog_open, setIsConfirmSubmitDialogOpen] = useState(false);
	const [is_confirm_approve_dialog_open, setIsConfirmApproveDialogOpen] = useState(false);
	const [is_confirm_reject_dialog_open, setIsConfirmRejectDialogOpen] = useState(false);
	const [is_confirm_void_dialog_open, setIsConfirmVoidDialogOpen] = useState(false);
	const [journal_entries, setJournalEntries] = useState(default_journal_entries);
	const [balancing_account, setBalancingAccount] = useState("");
	const [is_editable, setIsEditable] = useState(true);
	const [is_authorizable, setIsAuthorizable] = useState(document_id ? auth.approval_pipelines.find((pipe) => pipe.stock_adjustment_id===document_id)!==undefined : false);
	const [profit_cost_centers, setProfitCostCenters] = useState([]);
	const [profit_cost_center, setProfitCostCenter] = useState(null);
	const [with_expiry_serialized, setHasExpirySerialized] = useState(false);
	const [with_expiry, setWithExpiry] = useState(false);
	const [serialized, setSerialized] = useState(false);
	const DEFAULT_HEADER = translate.worksheet.default_header.BEGINNING_BALANCE;
	const DEFAULT_ALIGNMENT = translate.worksheet.default_alignment.BEGINNING_BALANCE;
	const IMPORT_HEADER = translate.worksheet.import_header.BEGINNING_BALANCE;
	const IMPORT_ALIGNMENT = translate.worksheet.import_alignment.BEGINNING_BALANCE;
	const COMMENT = translate.worksheet.import_comment.BEGINNING_BALANCE;
	const NUM_ROWS = 1000;
	const NUM_COLS = DEFAULT_HEADER.length;
	const IMPORT_LIMIT = 700;
	let DEFAULT_DATA_SET = new Array(NUM_ROWS).fill("").map(() => new Array(IMPORT_HEADER.length).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: NUM_ROWS,
		num_cols: NUM_COLS,
		num_frozen_columns: 0,
		import_limit: IMPORT_LIMIT,
		focused_cell: null,
		selected_regions: null,
		header: DEFAULT_HEADER,
		alignment: DEFAULT_ALIGNMENT,
		comment: COMMENT,
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.BEGINNING_BALANCE));
	}, [
		dispatch,
		translate.breadcrumb.BEGINNING_BALANCE
	]);

	useEffect(() => {
		if (worksheet.is_readonly_mode) {
			const handleResponse = (response) => {
				setWorkSheet({
					is_readonly_mode: true,
					num_rows: NUM_ROWS>response.payload.data.stock_adjustments.length ? NUM_ROWS : response.payload.data.stock_adjustments.length,
					num_cols: DEFAULT_HEADER.length,
					num_frozen_columns: 0,
					import_limit: IMPORT_LIMIT,
					focused_cell: null,
					selected_regions: null,
					header: DEFAULT_HEADER,
					alignment: DEFAULT_ALIGNMENT,
					comment: [],
					data_set: response.payload.data.stock_adjustments.map((ds) => [
						ds.id,
						ds.document_number,
						ds.document_date,
						ds.approval_status,
						ds.reference_number,
						ds.profit_cost_center ? JSON.parse(ds.profit_cost_center).label : '',
						ds.description,
					])
				});
			}
			dispatch(fetchAllBeginningBalancesAsync({
				//document_number:document_number ? document_number.value : "",
				//profit_cost_center:profit_cost_center ? profit_cost_center : "",
			})).then((response) => response.payload.data ? 
				handleResponse(response) : void(0)
			);

			dispatch(fetchAllItemsAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setItems(response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)))) : void(0)));
			dispatch(fetchAllLocationsAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setLocations(response.payload.data.locations) : void(0)));
			const handleFetchProfitCostCenterResponse = (response) => {
				if (response.payload.status===resources.status.SUCCESS) {
					let branches = response.payload.data.branches.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((branch) => ({label:`${branch.code} | ${branch.name}`, type:branch.type, value:branch.id}));
					let departments = response.payload.data.departments.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((department) => ({label:`${department.code} | ${department.name}`, type:department.type, value:department.id}));
					let projects = response.payload.data.projects.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((project) => ({label:`${project.code} | ${project.name}`, type:project.type, value:project.id}));
					setProfitCostCenters(branches.concat(departments).concat(projects));
				}
			}
			dispatch(fetchAllProfitCostCentersAsync({})).then((response) => handleFetchProfitCostCenterResponse(response));
		}
	}, [
		dispatch,
		worksheet.is_readonly_mode,
		DEFAULT_ALIGNMENT,
		DEFAULT_HEADER
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const refreshData = () => {
		onClearButtonClicked();
	}

	const loadDocument = useCallback((stockAdjustment, stockAdjustmentLineItems, journalEntries) => {
		let profitCostCenter = stockAdjustment.profit_cost_center ? JSON.parse(stockAdjustment.profit_cost_center) : null;
		profitCostCenter = profitCostCenter ? profit_cost_centers.find((pcc) => pcc.type===profitCostCenter.type && pcc.value===profitCostCenter.value) : null;
		setStockAdjustment(stockAdjustment);
		setId(stockAdjustment.id);
		setReferenceNumber(stockAdjustment.reference_number);
		setProfitCostCenter(profitCostCenter ? profitCostCenter : null);
		setTransactionLogs(stockAdjustment.transaction_logs);
		setTransactionDescription(stockAdjustment.description);
		setIsEditable(stockAdjustment.is_editable);
		setIsAuthorizable(stockAdjustment.id ? auth.approval_pipelines.find((pipe) => pipe.stock_adjustment_id===stockAdjustment.id)!==undefined : false);
		setHasExpirySerialized(false);
		setWithExpiry(false);
		setSerialized(false);
		let loadedItems = [];
		for (let i=0; i<stockAdjustmentLineItems.length; i++) {
			let stockAdjustmentLineItem = stockAdjustmentLineItems[i];
			let quantity = stockAdjustmentLineItem.quantity;
			let unitCost = stockAdjustmentLineItem.unit_cost;
			let amount = stockAdjustmentLineItem.amount;
			let loadedItem =
			{
				id:stockAdjustmentLineItem.id,
				item:{
					label:stockAdjustmentLineItem.item.label, 
					default_uom_name:stockAdjustmentLineItem.item.unit_of_measure.name, 
					item_costing:stockAdjustmentLineItem.item.item_costing, 
					serial_number_type:stockAdjustmentLineItem.item.serial_number_type, 
					value:stockAdjustmentLineItem.item.id
				},
				quantity:quantity,
				unit_cost:unitCost,
				amount:amount,
				uom:{label:stockAdjustmentLineItem.unit_of_measure.code, value:stockAdjustmentLineItem.unit_of_measure.id},
				location:{label:`${stockAdjustmentLineItem.location.code} | ${stockAdjustmentLineItem.location.name}`, value:stockAdjustmentLineItem.location.id},
				serial_numbers:stockAdjustmentLineItem.serial_numbers,
				expiry_dates:stockAdjustmentLineItem.expiry_dates.map((expDate) => (expDate.length>0 ? moment(expDate, resources.date_format.SYSTEM).format(resources.date_format.INPUT) : "")),
				expiry_quantities:stockAdjustmentLineItem.expiry_quantities.map((expQty) => (expQty.length>0 ? parseFloat(expQty) : 0)),
				item_conditions:stockAdjustmentLineItem.item_conditions,
			};
			loadedItems.push(loadedItem);
			if (stockAdjustment.approval_status===auth.enums.approval_status.DRAFT) {
				let inventoryItem = items.find((itm) => itm.id===stockAdjustmentLineItem.item.id);
				if (inventoryItem) {
					let hasExpiry = Util.stringToBoolean(inventoryItem.has_expiry);
					let withSerialNumber = Util.stringToBoolean(inventoryItem.with_serial_number);
					if (hasExpiry && withSerialNumber) {
						setHasExpirySerialized(true);
					} else 
					if (withSerialNumber) {
						if (inventoryItem.serial_number_type===auth.enums.serial_number_types.EXTERNAL) setSerialized(true);
					} else 
					if (hasExpiry) {
						setWithExpiry(true);
					}
				}
			}
		}
		setLineItems(loadedItems);
		setBalancingAccount(auth.enums.balancing_accounts.stock_adjustment.BEGINNING_BALANCE);
		setJournalEntries(journalEntries.map((journalEntry) => ({
			account_code:journalEntry.account.code,
			account_description:journalEntry.account.description,
			profit_cost_center:journalEntry.profit_cost_center ? JSON.parse(journalEntry.profit_cost_center) : null,
			debit_amount:journalEntry.debit_amount,
			credit_amount:journalEntry.credit_amount,
		})));
	},[auth.enums.serial_number_types.EXTERNAL, auth.approval_pipelines, auth.enums.balancing_accounts.stock_adjustment.BEGINNING_BALANCE, profit_cost_centers, items, auth.enums.approval_status.DRAFT]);

	useEffect(() => {
		if (id) {
			dispatch(fetchAllStockAdjustmentLineItemsAsync({stock_adjustment_id:id})).then((response) => loadDocument(response.payload.data.stock_adjustment, response.payload.data.stock_adjustment_line_items, response.payload.data.journal_entries));
		}
	}, [dispatch, id, loadDocument]);

	const handleProfitCostCenterChangedEvent = (profitCostCenter) => {
		setProfitCostCenter(profitCostCenter);
		setLineItems(line_items.map((li) => ({
			id:li.id,
			item:li.item,
			quantity:li.quantity,
			unit_cost:li.unit_cost,
			amount:li.amount,
			uom:li.uom,
			location:null,
			serial_numbers:li.serial_numbers,
			expiry_dates:li.expiry_dates,
			expiry_quantities:li.expiry_quantities,
			item_conditions:li.item_conditions,
		})));
	}

	const onClearButtonClicked = () => {
		setId(null);
		setStockAdjustment(null);
		setReferenceNumber("");
		setProfitCostCenter(null);
		setTransactionDescription("");
		setLineItems(default_line_items);
		setAttachments([]);
		setJournalEntries(default_journal_entries);
		setIsEditable(true);
		setIsAuthorizable(false);
		setSerialized(false);
		setWithExpiry(false);
		setHasExpirySerialized(false);
		setSelectedRows([]);
	};

	const handleSkipButtonClickedEvent = () => {
		dispatch(skipProcessAsync({application:'beginning-balance'})).then((response) => response.payload.data && response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : void(0));
	};

	/*const handleDeleteRowsButtonClickedEvent = () => {
		let rows = selected_rows.map(
			(row) => worksheet.data_set[row]
		);
		let promises = [];
		for (let i = 0; i < rows.length; i++) {
			promises.push(
				dispatch(
					voidBeginningBalanceAsync({
						id: rows[i][0]
					})
				)
			);
		}
		Promise.all(promises).then((response) => {
			var success = true;
			var message = null;
			for (let i = 0; i < response.length; i++) {
				if (response[i].payload !== undefined) {
					if (response[i].payload.status !== resources.status.SUCCESS) {
						success = false;
						break;
					}
					else {
						if (message == null) {
							message = response[i].payload.message;
						}
					}
				}
			}
			if (success === true) {
				toast.success(
					message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			refreshData();
		});
	};*/

	const handleApproveButtonClickedEvent = () => {
		dispatch(
			approveBeginningBalanceAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleRejectButtonClickedEvent = () => {
		dispatch(
			rejectBeginningBalanceAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleVoidButtonClickedEvent = () => {
		const handleVoidButtonClickedResponse = (response) => {
			let doc = response.payload.data.stock_adjustment;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.stock_adjustment.id ? [
					doc.id,
					doc.document_number,
					doc.document_date,
					doc.approval_status,
					doc.reference_number,
					doc.profit_cost_center!==null ? JSON.parse(doc.profit_cost_center).label : null,
					doc.description,
				] : ds))
			});
			onClearButtonClicked();
		}
		dispatch(
			voidBeginningBalanceAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleVoidButtonClickedResponse(response) : null))
	};

	const onSubmitButtonClicked = () => {
		const handleSubmitButtonClickedResponse = (response) => {
			let doc = response.payload.data.stock_adjustment;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.stock_adjustment.id ? [
					doc.id,
					doc.document_number,
					doc.document_date,
					doc.approval_status,
					doc.reference_number,
					doc.profit_cost_center!==null ? JSON.parse(doc.profit_cost_center).label : null,
					doc.description,
				] : ds))
			});
			onClearButtonClicked();
		}
		dispatch(
			submitBeginningBalanceAsync({
				id:id,
				document_date:document_date,
				reference_number:reference_number,
				profit_cost_center:profit_cost_center,
				transaction_description:transaction_description,
				line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.location!==null)),
				attachments:attachments,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleSubmitButtonClickedResponse(response) : null))
	};

	const onUpdateButtonClicked = () => {
		const handleUpdateButtonClickedResponse = (response) => {
			let doc = response.payload.data.stock_adjustment;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.stock_adjustment.id ? [
					doc.id,
					doc.document_number,
					doc.document_date,
					doc.approval_status,
					doc.reference_number,
					doc.profit_cost_center!==null ? JSON.parse(doc.profit_cost_center).label : null,
					doc.description,
				] : ds))
			});
			loadDocument(response.payload.data.stock_adjustment, response.payload.data.stock_adjustment_line_items, response.payload.data.journal_entries);
			setAttachments([]);
		}
		dispatch(
			updateBeginningBalanceAsync({
				id:id,
				document_date:document_date,
				reference_number:reference_number,
				profit_cost_center:profit_cost_center,
				transaction_description:transaction_description,
				line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.location!==null)),
				attachments:attachments,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateButtonClickedResponse(response) : null))
	}

	const onCreateButtonClicked = () => {
		const handleResponse = (response) => {
			let doc = response.payload.data.stock_adjustment;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set:[
					[
						doc.id,
						doc.document_number,
						doc.document_date,
						doc.approval_status,
						doc.reference_number,
						doc.profit_cost_center!==null ? JSON.parse(doc.profit_cost_center).label : null,
						doc.description,
					],
					...worksheet.data_set
				]
			});
			loadDocument(response.payload.data.stock_adjustment, response.payload.data.stock_adjustment_line_items, response.payload.data.journal_entries);
			setAttachments([]);
		}
		dispatch(
			createBeginningBalanceAsync({
				document_date:document_date,
				reference_number:reference_number,
				profit_cost_center:profit_cost_center,
				transaction_description:transaction_description,
				line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.location!==null)),
				attachments:attachments,
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleResponse(response) : null))
	}
	
	const onEditButtonClicked = (row) => {
		let id = worksheet.data_set[row][0];
		setId(id);
		window.scrollTo(0,0);
	};

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const onSaveAsPDFButtonClicked = () => {
		PDF.export(
			translate.worksheet.file_name.BEGINNING_BALANCE,
			worksheet.header,
			worksheet.data_set,
			auth.active_company,
			"legal",
			"landscape",
			[],
			[30, 90, 90, 90, 90, "*", "*"]
		);
	}

	const onSaveAsXLSXButtonClicked = () => {
		Excel.export(
			translate.worksheet.file_name.BEGINNING_BALANCE,
			worksheet.header,
			worksheet.data_set,
			auth.active_company
		);
	}

	const onSpreadSheetModeSwitchChanged = () => {
		let newMode = !worksheet.is_readonly_mode;
		setWorkSheet({
			is_readonly_mode: newMode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearCellsButtonClicked = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearSelectedCellsButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			if (worksheet.selected_regions!=null) {
				let dataSet = [];
				let selectedRegions = worksheet.selected_regions;
				for (let i=0; i<worksheet.data_set.length; i++) {
					let row = [];
					for (let j=0; j<worksheet.data_set[i].length; j++) {
						row.push(worksheet.data_set[i][j]);
					}
					dataSet.push(row);
				}
				for (let i=0; i<selectedRegions.length; i++) {
					let selectedRows = selectedRegions[i].rows;
					let selectedCols = selectedRegions[i].cols;
					let startRow = selectedRows[0];
					let endRow = selectedRows[1];
					let startCol = selectedCols[0];
					let endCol = selectedCols[1];
					for (let j=startRow; j<=endRow; j++) {
						if (dataSet[j]!==undefined) {
							for (let k=startCol; k<=endCol; k++) {
								if (dataSet[j][k]!==undefined) {
									dataSet[j][k] = "";
								}
							}
						}
					}
				}
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: dataSet
				});
			}
		}
	}

	const onImportButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			let dataSet = worksheet.data_set.filter((dataSet) => dataSet[0].length>0||dataSet[1].length>0||dataSet[2].length>0||dataSet[3].length>0||dataSet[4].length>0);
			if (dataSet.length<=IMPORT_LIMIT) {
				const handleResponse = (i, length, status) => {
					if (status===resources.status.SUCCESS) {
						if (i===0) {
							onClearCellsButtonClicked();
							toast.success(translate.message.IMPORT_BEGINNING_BALANCE_QUEUED, Util.getToasterStyle(resources.status.SUCCESS));
						}
						if (i===length-1) {
							toast.success(translate.message.IMPORT_BEGINNING_BALANCE_FINISHED, Util.getToasterStyle(resources.status.SUCCESS));
						}
					}
				} 
				let size = dataSet.length;
				let chunks = Util.chunk(dataSet, size);
				let length = chunks.length;
				for (let i=0; i<chunks.length; i++) {
					dispatch(
						importBeginningBalancesAsync(chunks[i])
					).then((response) => (handleResponse(i, length, response.payload.status)));
				}
			}
			else {
				toast.error(
					translate.message.IMPORT_LIMIT_REACHED,
					Util.getToasterStyle(resources.status.ERROR)
				);
			}
		}
	};

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
						{
							label: translate.text.NOTE_INLINE_EDITING_FIELD_LABEL,
							value: translate.text.NOTE_INLINE_EDITING_FIELD_VALUE
						},
						{
							label: translate.text.NOTE_PASTING_DATA_LABEL,
							value: translate.text.NOTE_PASTING_DATA_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleSkipButtonClickedEvent}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.BEGINNING_BALANCE) }
				<div className="function-sub-title"></div>

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						{
							id===null ?
							<>
								<Button
									text={translate.button.CREATE_DRAFT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING||!is_editable}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={onCreateButtonClicked}
								/>
							</>
							:
							<>
								{
									is_editable &&
									<Button
										text={translate.button.UPDATE}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="floppy-disk" className="orange"/>}
										onClick={onUpdateButtonClicked}
									/>
								}
								{
									is_editable &&
									<Button
										text={translate.button.SUBMIT}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="send-message" className="orange"/>}
										onClick={e => setIsConfirmSubmitDialogOpen(!is_confirm_submit_dialog_open)}
									/>
								}
								<Button
									text={translate.button.PRINT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="print" className="orange"/>}
									onClick={() =>
										PDF.exportBeginningBalanceForm(
											translate.worksheet.file_name.BEGINNING_BALANCE,
											[stock_adjustment],
											line_items,
											auth.active_company
										)
									}
								/>
								{
									is_editable &&
									<Button
										text={translate.button.VOID}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="ban-circle" className="orange"/>}
										onClick={e => setIsConfirmVoidDialogOpen(!is_confirm_void_dialog_open)}
									/>
								}
								{
									stock_adjustment && stock_adjustment.approval_status===auth.enums.approval_status.FOR_APPROVAL && is_authorizable &&
									<>
										<Button
											text={translate.button.APPROVE}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="tick" className="orange"/>}
											onClick={e => setIsConfirmApproveDialogOpen(!is_confirm_approve_dialog_open)}
										/>
										<Button
											text={translate.button.REJECT}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="cross" className="orange"/>}
											onClick={e => setIsConfirmRejectDialogOpen(!is_confirm_reject_dialog_open)}
										/>
									</>
								}
							</>
						}
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onClearButtonClicked}
						/>
					</ButtonGroup>
				}

				{
					(with_expiry_serialized || serialized || with_expiry) &&
					<div style={{textAlign:"center", marginBottom:20, color:"blue"}}>
						{
							with_expiry_serialized &&
							<div>
								Your next step is to assign expiry date/s to corresponding serial number/s.
							</div>
						}
						{
							serialized &&
							<div>
								Your next step is to assign serial number/s to corresponding quantity.
							</div>
						}
						{
							with_expiry &&
							<div>
								Your next step is to assign expiry date to each quantity.
							</div> 
						}
					</div>
				}

				{
					worksheet.is_readonly_mode &&
					<div className="function-card">
						<div className="row">
							<Card
								className="col-sm-12 col-md-4 col-lg-3"
								style={{
									background: "lightblue",
								}}
							>
								<FormGroup>
									<H3 className="center" style={{color:"blue"}}>{stock_adjustment ? stock_adjustment.document_number : null}</H3>
									<H5 className="center" style={{color:"green"}}>{stock_adjustment ? stock_adjustment.approval_status : null}</H5>
								</FormGroup>
								<div className="row mb-5">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										id="document_date"
										label={translate.placeholder.PICK_A_DATE}
										type="date"
										variant="outlined"
										size="small"
										defaultValue={document_date}
										inputProps={{readOnly:true}}
										
										onChange={(e) => setDocumentDate(e.target.value)}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={reference_number}
										label={translate.placeholder.ENTER_TRANSACTION_REFERENCE_NUMBER}
										onChange={(e) =>
											setReferenceNumber(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
						        </div>

								<div className="mb-5 mt-5">
									<MuiAutocomplete
										setSelectedValue={handleProfitCostCenterChangedEvent}
										selected_value={profit_cost_center}
										isMultiple={false}
										label={"Select Profit/Cost Center"}
										data={profit_cost_centers}
										isDisabled={!is_editable}
									/>
								</div>

						        <div className="row mb-5 mt-5">
									<TextField
										multiline
										minRows={4}
										className="col-sm-12 col-md-12 col-lg-12"
										size="small"
										variant="outlined"
										label={translate.placeholder.ENTER_TRANSACTION_DESCRIPTION}
										value={transaction_description}
										onChange={(e) =>
											setTransactionDescription(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<Attachment
									id={id}
									transaction={stock_adjustment}
									setTransaction={setStockAdjustment}
									attachments={attachments}
									setAttachments={setAttachments}
								/>

								{
									id!==null && stock_adjustment &&
									<>
										<Comment
											id={id}
											document_type={stock_adjustment.document_type.name}
											comments={stock_adjustment.comments}
											created_by={stock_adjustment.created_by}
											setDocument={setStockAdjustment}
											is_editable={is_editable}
											is_authorizable={is_authorizable}
										/>
										<TransactionLog
											transaction_logs={transaction_logs}
										/>
				                    </>
								}

							</Card>
							<BeginningBalanceLineItem
								id={id}
								is_editable={is_editable}
								profit_cost_center={profit_cost_center}
								default_line_items={default_line_items}
								line_items={line_items}
								setLineItems={setLineItems}
								default_journal_entries={default_journal_entries}
								journal_entries={journal_entries}
								setJournalEntries={setJournalEntries}
								balancing_account={balancing_account}
								setBalancingAccount={setBalancingAccount}
								items={items}
								locations={locations}
							/>
						</div>
					</div>
				}

				{
					<ButtonGroup className="button-group" minimal={false}>
						<Switch
							className="switch-mode"
							checked={!worksheet.is_readonly_mode}
							disabled={auth.status === resources.status.LOADING}
							labelElement={<span className="switch-mode-label">{translate.label.SWITCH_MODE}</span>}
							innerLabelChecked={translate.label.IMPORT}
							innerLabel={translate.label.READ_ONLY}
							onChange={onSpreadSheetModeSwitchChanged}
						/>
						{
							worksheet.is_readonly_mode &&
							<>
								<Button
									text={translate.button.SAVE_AS_PDF}
									className="function-button"
									icon={<Icon icon="document" className="orange"/>}
									disabled={auth.status === resources.status.LOADING}
									onClick={onSaveAsPDFButtonClicked}
								/>
								<Button
									text={translate.button.SAVE_AS_XLSX}
									className="function-button"
									icon={<Icon icon="th" className="orange"/>}
									disabled={auth.status === resources.status.LOADING}
									onClick={onSaveAsXLSXButtonClicked}
								/>
								{/*
								<Button
									text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="select" className="orange"/>}
									onClick={e => setSelectedRows(selected_rows.length>0 ? [] : [...Array(worksheet.data_set.length).keys()])}
								/>
								*/}
								{/*
									selected_rows.length>0 &&
									<Button
										text={`${translate.button.DELETE_ROWS} (${selected_rows.length})`}
										disabled={auth.status === resources.status.LOADING}
										className="function-button"
										icon={<Icon icon="delete" className="orange"/>}
										onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
									/>
								*/}
							</>
						}
						{
							!worksheet.is_readonly_mode &&
							<>
								<Button
									text={translate.button.IMPORT}
									className="function-button"
									icon={<Icon icon="upload" className="orange"/>}
									disabled={auth.status===resources.status.LOADING}
									onClick={onImportButtonClicked}
								/>
								<Button
									text={translate.button.CLEAR_SELECTED_CELLS}
									className="function-button"
									icon={<Icon icon="clean" className="orange"/>}
									disabled={auth.status===resources.status.LOADING}
									onClick={onClearSelectedCellsButtonClicked}
								/>
								<Button
									text={translate.button.CLEAR_CELLS}
									className="function-button"
									icon={<Icon icon="graph-remove" className="orange"/>}
									disabled={auth.status===resources.status.LOADING}
									onClick={onClearCellsButtonClicked}
								/>
							</>
						}
						{
							worksheet.focused_cell &&
							<Button
								text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
								className="function-button"
								icon={<Icon icon="panel-table" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onFreezePaneButtonClicked}
							/>
						}
					</ButtonGroup>
				}

				<BeginningBalanceSpreadsheet
					enable_select={false}
					enable_edit={true}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					onEditButtonClicked={onEditButtonClicked}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
				/>

				{/*
				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_delete_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDeleteDialogOpen}
					confirmAction={handleDeleteRowsButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted/voided, document will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain document is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>
				*/}

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_submit_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmSubmitDialogOpen}
					confirmAction={onSubmitButtonClicked}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">SUBMIT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_approve_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmApproveDialogOpen}
					confirmAction={handleApproveButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">APPROVE</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_reject_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmRejectDialogOpen}
					confirmAction={handleRejectButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">REJECT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_void_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmVoidDialogOpen}
					confirmAction={handleVoidButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">VOID</span> this document ?
						</span>
					}
				/>

			</div>
		</div>
	);
}
