import React from "react";
import Util from "../../common/Util";
import { resources } from "../../resources/index";
import toast from "react-hot-toast";
import { useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { pendingVerificationAsync } from "./authSlice";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

export function PendingVerification() {
	const { verification_code } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const activationResponse = useCallback(
		(response) => {
			if (response.payload.data && response.payload.data.is_activated && response.payload.data.email) {
				toast.success(
					response.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			history.push("/login");
		},
		[history]
	);

	useEffect(() => {
		dispatch(pendingVerificationAsync(verification_code)).then((response) =>
			activationResponse(response)
		);
	}, [dispatch, verification_code, activationResponse]);

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<div className="main-container">
			</div>
		</div>
	);
}