import React from "react";
import { useState, useCallback, useEffect } from "react";
import { resources } from "../../resources/index";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, verifyAsync, validateVerificationCodeAsync } from "./authSlice";
import { Icon, FormGroup, InputGroup, IconSize, Button, Checkbox } from "@blueprintjs/core";
import logo from "../../logo.png";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import Util from "../../common/Util";
import toast from "react-hot-toast";

export function PendingUserVerification() {
	const auth = useSelector(selectAuth);
	const { verification_code } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const translate = resources["lang"][auth.locale];
	const [password, setPassword] = useState("");
	const [confirm_password, setConfirmPassword] = useState("");
	const [is_password_visible, setIsPasswordVisible] = useState(false);

	const initFetch = useCallback(() => {
		if (verification_code) {
			dispatch(validateVerificationCodeAsync(verification_code)).then((response) => (response.payload.data && response.payload.data.is_valid ? void(0) : history.push("/login")));
		}
	}, [
		dispatch,
		verification_code,
		history
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const activationResponse = useCallback(
		(response) => {
			if (response.payload.data.is_activated && response.payload.data.email) {
				toast.success(
					response.payload.message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			history.push("/login");
		},
		[history]
	);

	const handleTogglePasswordVisibilityEvent = () => {
		setIsPasswordVisible(!is_password_visible);
	}

	return (
		<div className="auth-form" style={{minHeight:"100vh"}}>
			<img
				src={logo}
				className="auth-logo"
				alt={resources.app.NAME}
			/>
			<form className="mt-5">
				<FormGroup
					className="input-form-group"
					label={translate.label.PASSWORD}
					labelInfo={translate.label.REQUIRED}
				>
					<InputGroup
						leftIcon={
							<Icon
								icon="key"
								size={IconSize.SMALL}
								className="orange"
							/>
						}
						placeholder={translate.placeholder.ENTER_PASSWORD}
						type={is_password_visible ? "text" : "password"}
						large={true}
						round={true}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						autoComplete="off"
					></InputGroup>
				</FormGroup>

				<FormGroup
					className="input-form-group"
					label={translate.label.CONFIRM_PASSWORD}
					labelInfo={translate.label.REQUIRED}
				>
					<InputGroup
						leftIcon={
							<Icon
								icon="key"
								size={IconSize.SMALL}
								className="orange"
							/>
						}
						placeholder={translate.placeholder.ENTER_CONFIRM_PASSWORD}
						type={is_password_visible ? "text" : "password"}
						large={true}
						round={true}
						value={confirm_password}
						onChange={(e) => setConfirmPassword(e.target.value)}
						autoComplete="off"
					></InputGroup>
				</FormGroup>

				<div className="row">
					<div className="col-sm-12 col-md-12 col-lg-12 center mt-5 mb-5">
						<Checkbox
							checked={ is_password_visible }
							large={false}
							inline={true}
							label={is_password_visible ? "Hide Password" : "Show Password"}
							onChange={e => handleTogglePasswordVisibilityEvent()}
						>
							<Icon className="ml-5 orange" size={20} icon={is_password_visible ? "eye-open" : "eye-off"}/>
						</Checkbox>
					</div>
				</div>

				<Button
					disabled={auth.status === resources.status.LOADING}
					text={translate.button.SET_PASSWORD}
					fill={true}
					large={true}
					onClick={() => dispatch(verifyAsync({verification_code:verification_code, password:password, confirm_password:confirm_password})).then((response) => response.payload.status===resources.status.SUCCESS ? activationResponse(response) : void(0))}
				/>

			</form>
		</div>
);
}