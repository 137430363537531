import React, { useState } from "react";
import { useSelector } from "react-redux";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { ItemDetailDialog } from "../../warehouse-inventory/item/ItemDetailDialog";
import { InventoryTrackingDialogMod } from "../../warehouse-inventory/item/InventoryTrackingDialogMod";
import {
	selectAuth,
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ControlGroup,
	HTMLTable,
	InputGroup,
	Tooltip,
	Position,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import OmnibarSelect from "../../../common/OmnibarSelect";
import { JournalEntry } from "../../../common/JournalEntry";

export function StockAcceptanceLineItem({id, is_editable, transfer_to, transaction_source, default_line_items, line_items, setLineItems, stock_issuance_transaction_source, default_journal_entries, journal_entries, setJournalEntries, profit_cost_centers, balancing_account, setBalancingAccount, items, locations}) {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const [is_item_detail_dialog_open, setIsItemDetailDialogOpen] = useState(false);
	const [has_expiry, setHasExpiry] = useState(true);
	const [with_serial_number, setWithSerialNumber] = useState(true);
	const [item, setItem] = useState(null);
	const [is_omnibar_open, setIsOmnibarOpen] = useState(false);
	const [omnibar_data, setOmnibarData] = useState([]);
	const [omnibar_ds_type, setOmnibarDsType] = useState(null);
	const [selected_inventory_tracking, setSelectedInventoryTracking] = useState(line_items[0]);
	const [quantity_label, setQuantityLabel] = useState(null);
	const [is_inventory_tracking_dialog_open, setIsInventoryTrackingDialogOpen] = useState(false);
	const [inventory_tracking_row, setInventoryTrackingRow] = useState(null);
	const [serial_number_type, setSerialNumberType] = useState(null);
	const [enable_cancellation] = useState(false);

	const handleLineItemDeleteButtonClickedEvent = (row) => {
		setLineItems(line_items.filter((lineItem, key) => key!==row));
		setJournalEntries(journal_entries.filter((journalEntry,key) => key!==row));
	};

	const handleSaveInventoryTrackingButtonClickedEvent = (inventoryTracking) => {
		setLineItems(line_items.map((lineItem, key) => (key===inventoryTracking.inventory_tracking_row ? {
			id:lineItem.id, 
			item:lineItem.item, 
			requested_quantity:lineItem.requested_quantity, 
			balance_quantity:lineItem.balance_quantity, 
			cancel_quantity:lineItem.cancel_quantity, 
			quantity:lineItem.quantity, 
			unit_cost:lineItem.unit_cost, 
			amount:lineItem.amount, 
			uom:lineItem.uom, 
			to_location:lineItem.to_location,
			serial_numbers:inventoryTracking.serial_numbers,
			expiry_quantities:inventoryTracking.expiry_quantities,
			expiry_dates:inventoryTracking.expiry_dates,
			item_conditions:inventoryTracking.item_conditions,
		} : lineItem)));
		setIsInventoryTrackingDialogOpen(false);
	}

	const handleInventoryTrackingButtonClickedEvent = (row, quantityLabel) => {
		let inventoryItem = items.filter((item) => (item.id===line_items[row].item.value))[0];
		let hasExpiry = Util.stringToBoolean(inventoryItem.has_expiry);
		let withSerialNumber = Util.stringToBoolean(inventoryItem.with_serial_number);
		setHasExpiry(hasExpiry);
		setWithSerialNumber(withSerialNumber);
		setSerialNumberType(inventoryItem.serial_number_type);
		if (hasExpiry || withSerialNumber) {
			setSelectedInventoryTracking(line_items[row]);
			setQuantityLabel(quantityLabel);
			setInventoryTrackingRow(row);
			setIsInventoryTrackingDialogOpen(true);
		}
		else {
			toast.error(
				translate.message.ITEM_HAS_NO_SERIAL_NUMBER_AND_EXPIRY,
				Util.getToasterStyle(resources.status.ERROR)
			);
		}
	};

	const handleDisplayItemDetailsButtonClickedEvent = (selectedItem) => {
		setItem(items.filter((item) => (item.id===selectedItem.value))[0]);
		setIsItemDetailDialogOpen(true);
	};

	const handleOmnibarItemSelectedEvent = (selectedItem) => {
		switch (selectedItem.ds_type.data_source) {
			case resources.omnibar_ds_types.ITEM : {
				setLineItems(line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id, 
					item:{
						label:selectedItem.item.label, 
						default_uom_name:selectedItem.item.default_uom_name, 
						item_costing:selectedItem.item.item_costing, 
						serial_number_type:selectedItem.item.serial_number_type, 
						value:selectedItem.item.id
					}, 
					requested_quantity:lineItem.requested_quantity, 
					issued_quantity:lineItem.issued_quantity, 
					cancelled_quantity:lineItem.cancelled_quantity, 
					quantity:lineItem.quantity, 
					cancel_quantity:lineItem.cancel_quantity, 
					unit_cost:lineItem.unit_cost, 
					amount:lineItem.amount, 
					uom:lineItem.uom,
					to_location:lineItem.to_location,
					serial_numbers:lineItem.serial_numbers,
					expiry_quantities:lineItem.expiry_quantities,
					expiry_dates:lineItem.expiry_dates,
					item_conditions:lineItem.item_conditions,
				} : lineItem)));
				break;
			}
			case resources.omnibar_ds_types.UNIT_OF_MEASURE : {
				setLineItems(line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id,
					item:lineItem.item, 
					requested_quantity:lineItem.requested_quantity, 
					issued_quantity:lineItem.issued_quantity, 
					cancelled_quantity:lineItem.cancelled_quantity, 
					quantity:lineItem.quantity, 
					cancel_quantity:lineItem.cancel_quantity, 
					unit_cost:lineItem.unit_cost,
					amount:lineItem.amount, 
					uom:{label:selectedItem.item.code, value:selectedItem.item.id},
					to_location:lineItem.to_location,
					serial_numbers:lineItem.serial_numbers,
					expiry_quantities:lineItem.expiry_quantities,
					expiry_dates:lineItem.expiry_dates,
					item_conditions:lineItem.item_conditions,
				} : lineItem)));
				break;
			}
			case resources.omnibar_ds_types.LOCATION : {
				setLineItems(line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id,
					item:lineItem.item, 
					requested_quantity:lineItem.requested_quantity, 
					issued_quantity:lineItem.issued_quantity, 
					cancelled_quantity:lineItem.cancelled_quantity, 
					quantity:lineItem.quantity, 
					cancel_quantity:lineItem.cancel_quantity, 
					unit_cost:lineItem.unit_cost, 
					amount:lineItem.amount, 
					uom:lineItem.uom,
					to_location:{label:selectedItem.item.label, value:selectedItem.item.id},
					serial_numbers:lineItem.serial_numbers,
					expiry_quantities:lineItem.expiry_quantities,
					expiry_dates:lineItem.expiry_dates,
					item_conditions:lineItem.item_conditions,
				} : lineItem)));
				break;
			}
			default: break;
		}
	}

	const handleLocationInputClickedEvent = (row) => {
		let locs = [];
		if (transfer_to!==null) {
			locs = locations.filter((loc) => (loc.pcc_id===transfer_to.value)).map((location,key) => ({id:location.id, code:location.code, label:`${location.code} | ${location.name}`}));
		}
		else {
			locs = locations.map((location,key) => ({id:location.id, code:location.code, label:`${location.code} | ${location.name}`}));
		}
		setIsOmnibarOpen(true);
		setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.LOCATION});
		setOmnibarData(locs);
	}

	const handleQuantityInputChangedEvent = (event, row) => {
		let quantity = event.target.value<0 ? 0 : event.target.value;
		setLineItems(line_items.map((lineItem,key) => (key===row ? 
		{
			id:lineItem.id,
			item:lineItem.item, 
			requested_quantity:lineItem.requested_quantity, 
			issued_quantity:lineItem.issued_quantity, 
			cancelled_quantity:lineItem.cancelled_quantity, 
			quantity:quantity, 
			cancel_quantity:lineItem.cancel_quantity, 
			unit_cost:lineItem.unit_cost, 
			amount:lineItem.unit_cost.length>0 && quantity.length>0 ? (Math.round(parseFloat(quantity),4)*Math.round(parseFloat(lineItem.unit_cost),2)).toFixed(2) : '', 
			uom:lineItem.uom,
			to_location:lineItem.to_location,
			serial_numbers:lineItem.serial_numbers,
			expiry_quantities:lineItem.expiry_quantities,
			expiry_dates:lineItem.expiry_dates,
			item_conditions:lineItem.item_conditions,
		} : lineItem)));
        setJournalEntries(journal_entries.map((journalEntry,key) => (key===row ? 
        {
            account_code:journalEntry.account_code,
            account_description:journalEntry.account_description,
            profit_cost_center:journalEntry.profit_cost_center,
            debit_amount:line_items[key].unit_cost.length>0 && quantity.length>0 ? (parseFloat(quantity)*parseFloat(line_items[key].unit_cost)).toFixed(2) : '',
            credit_amount:(0).toFixed(2),
        } : journalEntry)));
	}

	const handleCancelQuantityInputChangedEvent = (event, row) => {
		setLineItems(line_items.map((lineItem,key) => (key===row ? 
		{
			id:lineItem.id,
			item:lineItem.item, 
			requested_quantity:lineItem.requested_quantity, 
			issued_quantity:lineItem.issued_quantity, 
			cancelled_quantity:lineItem.cancelled_quantity, 
			quantity:lineItem.quantity,
			cancel_quantity:event.target.value, 
			unit_cost:lineItem.unit_cost, 
			amount:lineItem.amount, 
			uom:lineItem.uom,
			to_location:lineItem.to_location,
			serial_numbers:lineItem.serial_numbers,
			expiry_quantities:lineItem.expiry_quantities,
			expiry_dates:lineItem.expiry_dates,
			item_conditions:lineItem.item_conditions,
		} : lineItem)));
	}

	const handleItemInputClickedEvent = (row) => {
		let itms = [];
		if (transfer_to!==null) {
			//let itemClasses = auth.item_classes.filter((itemClass) => (itemClass.location_names.includes(transfer_to.label))).map((itemClass) => itemClass.name);
			itms = items.filter((item) => (Util.stringToBoolean(item.is_enabled))).map((item,key) => ({id:item.id, code:item.code, label:`${item.code} | ${item.description}`}));
		}
		else {
			itms = items.filter((item) => Util.stringToBoolean(item.is_enabled)).map((item,key) => ({id:item.id, code:item.code, label:`${item.code} | ${item.description}`}));
		}
		setIsOmnibarOpen(true);
		setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.ITEM});
		setOmnibarData(itms);
	}

	const handleUnitCostInputChangedEvent = (event, row) => {
		let unitCost = event.target.value<0 ? 0 : event.target.value;
		setLineItems(line_items.map((lineItem,key) => (key===row ? 
		{
			id:lineItem.id,
			item:lineItem.item,
			requested_quantity:lineItem.requested_quantity, 
			issued_quantity:lineItem.issued_quantity, 
			cancelled_quantity:lineItem.cancelled_quantity, 
			quantity:lineItem.quantity,
			cancel_quantity:lineItem.cancel_quantity, 
			unit_cost:unitCost, 
			amount:lineItem.quantity.length>0 && unitCost.length>0 ? (Math.round(parseFloat(lineItem.quantity),4)*Math.round(parseFloat(unitCost),2)).toFixed(2) : '', 
			uom:lineItem.uom,
			to_location:lineItem.to_location,
			serial_numbers:lineItem.serial_numbers,
			expiry_quantities:lineItem.expiry_quantities,
			expiry_dates:lineItem.expiry_dates,
			item_conditions:lineItem.item_conditions,
		} : lineItem)));
        setJournalEntries(journal_entries.map((journalEntry,key) => (key===row ? 
        {
            account_code:journalEntry.account_code,
            account_description:journalEntry.account_description,
            profit_cost_center:journalEntry.profit_cost_center,
            debit_amount:line_items[key].quantity.length>0 && unitCost.length>0 ? (Math.round(parseFloat(line_items[key].quantity),4)*Math.round(parseFloat(unitCost),2)).toFixed(2) : '',
            credit_amount:(0).toFixed(2),
        } : journalEntry)));
	}

	const handleUomInputClickedEvent = (row) => {
		let unitOfMeasures = [];
		let selectedItem = line_items[row].item ? items.filter((item) => (item.id===line_items[row].item.value))[0] : null;
		if (selectedItem!==null) {
			unitOfMeasures = selectedItem.unit_of_measure_sets.map((uom) => ({id:uom.id, code:uom.code, label:uom.code}));
			setIsOmnibarOpen(true);
			setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.UNIT_OF_MEASURE});
			setOmnibarData(unitOfMeasures);
		}
	}

	return (
		<ControlGroup className="col-sm-12 col-md-8 col-lg-9 tbl-parent" vertical={true} style={{background: "grey", border:"solid lightgrey 1px", alignItems:"stretch", overflowX:"hidden", overflowY:"scroll", maxHeight:"50vh"}}>
			<ControlGroup className="tbl-group-xl" vertical={true}>

				<OmnibarSelect 
					is_omnibar_open={is_omnibar_open} 
					setIsOmnibarOpen={setIsOmnibarOpen} 
					omnibar_data={omnibar_data}
					omnibar_ds_type={omnibar_ds_type}
					handleOmnibarItemSelectedEvent={handleOmnibarItemSelectedEvent}
				/>

				<ItemDetailDialog
					item={item}
					is_item_detail_dialog_open={is_item_detail_dialog_open}
					setIsItemDetailDialogOpen={setIsItemDetailDialogOpen}
				/>


				{
					is_inventory_tracking_dialog_open && 
					<InventoryTrackingDialogMod
						document_type={auth.enums.document_types.STOCK_ADJUSTMENT}
						is_inventory_tracking_dialog_open={is_inventory_tracking_dialog_open}
						setIsInventoryTrackingDialogOpen={setIsInventoryTrackingDialogOpen}
						quantity_label={quantity_label}
						selected_inventory_tracking={selected_inventory_tracking}
						has_expiry={has_expiry}
						with_serial_number={with_serial_number}
						handleSaveInventoryTrackingButtonClickedEvent={handleSaveInventoryTrackingButtonClickedEvent}
						inventory_tracking_row={inventory_tracking_row}
						is_editable={is_editable}
						serial_number_type={serial_number_type}
					/>
				}

		        <HTMLTable 
		        	className="line-item-table line-item-table-header"
		        >
		            <tbody>
		            	<tr>
		            		<th className="line line-number"></th>
		            		<th className="input-line line-item">Item</th>

		            		{
		            			transaction_source && transaction_source.value!==auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT && stock_issuance_transaction_source!==auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION &&
		            			<>
				            		<th className="input-line quantity">Requested Qty</th>
				            		<th className="input-line quantity">{transaction_source && transaction_source.value===auth.enums.transaction_sources.stock_acceptance.STOCK_ISSUANCE ? "Bal. Issued Qty" : "Balance Qty"}</th>
				            		{
										enable_cancellation &&
										<th className="input-line quantity">Cancelled Qty</th>
									}
		            			</>
		            		}
		            		<th className="input-line uom">UOM</th>
		            		<th className="input-line quantity">Qty</th>
		            		{
		            			enable_cancellation &&
		            			<th className="input-line quantity">Cancel qty</th>
		            		}

		            		<th className="input-line unit-cost">Unit Cost</th>
		            		<th className="input-line total-amount">Amount</th>
		            		<th className="input-line location">To Location</th>

		            		{
		            			transaction_source && transaction_source.value===auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT && is_editable && 
		                    	<th className="input-line delete-line-button">
		                    	</th>
		            		}

		            	</tr>
		            </tbody>
		        </HTMLTable>
		        <HTMLTable 
		        	className="line-item-table"
					style={{
						background: "#F4F4F4",
					}}
		        >
		            <tbody>
		            	{
		            		line_items.map((lineItem, key) => 
		            			<tr key={key} style={{outline: "thin solid lightgrey"}}>
			                    	<td className="line line-number">
			                    		<ControlGroup>
			                        		<Button text={key+1} minimal={true}/>
			                        	</ControlGroup>
			                    	</td>
			                        <td className="input-line line-item">
			                        	<ControlGroup vertical={false}>
				                    		<Button disabled={!line_items[key].item} icon={<Tooltip disabled={line_items[key].item===null} intent={"primary"} content={line_items[key].item ? <span style={{fontSize:"9pt"}}>View Item Details</span> : "Select an Item to view details"} position={Position.RIGHT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} minimal={true} style={{paddingLeft:"15px",paddingRight:"15px"}} onClick={e => (line_items[key].item ? handleDisplayItemDetailsButtonClickedEvent(line_items[key].item) : null)}/>
				                        	<InputGroup fill={true} value={lineItem.item ? lineItem.item.label : ''} readOnly={true} onClick={e => (transaction_source && transaction_source.value===auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT)&&is_editable ? handleItemInputClickedEvent(key) : void(0)}/>
			                        	</ControlGroup>
			                        </td>

				            		{
				            			transaction_source && transaction_source.value!==auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT && stock_issuance_transaction_source!==auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION &&
				            			<>
					                        <td className="input-line quantity">
					                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
					                        		<InputGroup fill={true} value={lineItem.requested_quantity} style={{textAlign:"right"}} readOnly={true}/>
					                        	</ControlGroup>
					                        </td>
					                        <td className="input-line quantity">
					                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
					                        		<InputGroup fill={true} value={lineItem.issued_quantity} style={{textAlign:"right"}} readOnly={true}/>
					                        	</ControlGroup>
					                        </td>
											{
												enable_cancellation &&
												<td className="input-line quantity">
													<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
														<InputGroup fill={true} value={lineItem.cancelled_quantity} style={{textAlign:"right"}} readOnly={true}/>
													</ControlGroup>
												</td>
											}
				            			</>
				            		}

			                        <td className="input-line uom">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true}  value={lineItem.uom ? lineItem.uom.label : ''} readOnly={true} onClick={e => is_editable ? handleUomInputClickedEvent(key) : void(0)}/>
			                        	</ControlGroup>
			                        </td>

			                        <td className="input-line quantity">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
				                    		<Button disabled={line_items[key].quantity.length===0} icon={<Tooltip disabled={line_items[key].quantity.length===0} intent={"primary"} content={line_items[key].quantity.length>0 ? <span style={{fontSize:"9pt"}}>View/Edit/Load Expiry Date & Serial Number (Issue Qty)</span> : "Not Applicable / Disabled"} position={Position.LEFT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} onClick={e => handleInventoryTrackingButtonClickedEvent(key, translate.text.ISSUE_QUANTITY)} minimal={true}/>
			                        		<InputGroup type="number" fill={true} value={lineItem.quantity} style={{textAlign:"right"}} onChange={e => handleQuantityInputChangedEvent(e, key)} readOnly={(stock_issuance_transaction_source===auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION)||!is_editable}/>
			                        	</ControlGroup>
			                        </td>

			                        {
			                        	enable_cancellation &&
				                        <td className="input-line quantity">
				                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
					                    		<Button disabled={line_items[key].quantity.length===0} icon={<Tooltip disabled={line_items[key].quantity.length===0} intent={"primary"} content={line_items[key].quantity.length>0 ? <span style={{fontSize:"9pt"}}>View/Edit/Load Expiry Date & Serial Number (Issue Qty)</span> : "Not Applicable / Disabled"} position={Position.LEFT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} onClick={e => handleInventoryTrackingButtonClickedEvent(key, translate.text.ISSUE_QUANTITY)} minimal={true}/>
				                        		<InputGroup type="number" fill={true} value={lineItem.cancel_quantity} style={{textAlign:"right"}} onChange={e => handleCancelQuantityInputChangedEvent(e, key)} readOnly={!is_editable}/>
				                        	</ControlGroup>
				                        </td>
			                        }

			                        <td className="input-line unit-cost">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.unit_cost} style={{textAlign:"right"}} readOnly={(transaction_source && transaction_source.value===auth.enums.transaction_sources.stock_acceptance.STOCK_ISSUANCE)||!is_editable} onChange={e => handleUnitCostInputChangedEvent(e, key)}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line total-amount">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.amount} style={{textAlign:"right"}} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line location">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true}  value={lineItem.to_location ? lineItem.to_location.label : ''} readOnly={true} onClick={e => is_editable ? handleLocationInputClickedEvent(key) : void(0)}/>
			                        	</ControlGroup>
			                        </td>
			                        {
			                        	transaction_source && transaction_source.value===auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT && is_editable &&
				                    	<td className="input-line delete-line-button">
				                    		<Button icon={<Icon icon="cross" size={12}/>} intent="danger" minimal={true} onClick={e => handleLineItemDeleteButtonClickedEvent(key)} disabled={line_items.length<=1}/>
				                    	</td>
			                        }
		            			</tr>
		            		)
		            	}

		            </tbody>
		        </HTMLTable>
			</ControlGroup>
	        <ControlGroup className="tbl-group-xl" vertical={false}>
	        	<Button 
	        		className="function-button"
	        		large={false} 
	        		onClick={e => setLineItems([...line_items, default_line_items[0]])} 
	        		icon={<Icon icon="add" className="orange"/>}
	        		text={translate.button.ADD_MORE_ITEMS}
					disabled={(transaction_source&&transaction_source.value!==auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT)||!is_editable}
	        	/>
				<Button 
					className="function-button"
					large={false} 
					onClick={e => setLineItems(default_line_items)} 
					icon={<Icon icon="remove" className="orange"/>}
					text={translate.button.REMOVE_ALL_ITEMS}
					disabled={(transaction_source&&transaction_source.value!==auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT)||!is_editable}
				/>
	        </ControlGroup>
			{
				id &&
				<ControlGroup className="tbl-group-xl" vertical={true}>
					<JournalEntry
						default_journal_entries={default_journal_entries}
						journal_entries={journal_entries}
						setJournalEntries={setJournalEntries}
						profit_cost_centers={profit_cost_centers}
						balancing_account={balancing_account}
						setBalancingAccount={setBalancingAccount}
						is_editable={is_editable}
					/>
				</ControlGroup>
			}
		</ControlGroup>

	);
}