import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, toggleSidebarCollapsed } from "./features/auth/authSlice";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { resources } from "./resources/index";
import { Card, Elevation, Button, Icon } from "@blueprintjs/core";
import { SideBar } from './SideBar';
import Util from './common/Util';
import { CreateSubscriptionDialog } from './CreateSubscriptionDialog';
import default_company_logo from "./default_company_logo.png";
import { Note } from './common/Note';

export function MySubscription() {

	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [is_create_subscription_dialog_open, setIsCreateSubscriptionDialogOpen] = useState(false);
	const [colors] = useState(["DodgerBlue", "Gold", "Red", "MediumSpringGreen", "Coral", "Chartreuse", "LightGreen", "Wheat", "FloralWhite", "MediumTurquoise"]);

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<div style={{visibility:"hidden"}}>
					<Note
						contents={[
						]}
					/>
				</div>

				{ Util.functionTitle(translate.text.MY_SUBSCRIPTION) }
				<div className="function-sub-title"></div>

				<div
					className='col-sm-12 col-md-12 col-lg-12 center'
				>
					<Button
						text={translate.text.CREATE_SUBSCRIPTION}
						minimal={true}
						icon={<Icon icon="add" className="orange" size={30}/>}
						onClick={() => setIsCreateSubscriptionDialogOpen(true)}
					/>
					<CreateSubscriptionDialog
						is_create_subscription_dialog_open={is_create_subscription_dialog_open}
						setIsCreateSubscriptionDialogOpen={setIsCreateSubscriptionDialogOpen}
					/>

				</div>

				<div className='mt-5 mb-5 row'>
					<div className='col-sm-12 col-md-11 col-lg-9'>
						{
							auth.subscriptions.map((subscription, key) => (
								<Card
									className='row subscription-card' key={key}
									interactive={true}
									elevation={Elevation.ONE}
									style={{borderLeft:"10px solid", borderLeftColor:colors[key%2]}}
								>
									<div className='col-sm-12 col-md-4 col-lg-2 mt-5'>
										<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
											<img 
												alt="logo"
												src={auth.active_company.logo_source}
												onError={(e) => {
													e.target.src = default_company_logo
												}}
												style={{backgroundColor:"transparent"}}
											/>
										</div>
									</div>
									<div className='col-sm-12 col-md-8 col-lg-10 mt-5'>
										<div>Company Code : <span className='blue'>{subscription.company.code}</span></div>
										<div>Company Name: <span className='blue'>{subscription.company.name}</span></div>
										<div>Country: <span className='blue'>{subscription.company.country.name}</span></div>
										<div>Address : <span className='blue'>{subscription.company.address}</span></div>
									</div>
								</Card>	
							))
						}
					</div>
				</div>

			</div>
		</div>

	);

}
