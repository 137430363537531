import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	fetchEndOfDayReportAsync,
	toggleSidebarCollapsed,
	fetchAllProfitCostCentersAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	Icon,
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import moment from "moment";
import { SideBar } from '../../../SideBar';
import TextField from "@material-ui/core/TextField";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import toast from "react-hot-toast";

export function EndOfDay() {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [transaction_date, setTransactionDate] = useState(moment(new Date()).format(resources.date_format.INPUT));
	const [branch, setBranch] = useState(null);
	const [branches, setBranches] = useState([]);

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.END_OF_DAY));
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setBranches(response.payload.data.branches.map((br) => ({
					label:br.name,
					value:br.id,
				})));
			}
		}
		dispatch(fetchAllProfitCostCentersAsync({type:auth.enums.profit_cost_center_types.BRANCH})).then((res) => handleResponse(res));
	}, [
		dispatch,
		translate.breadcrumb.END_OF_DAY,
		auth.enums.profit_cost_center_types.BRANCH
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const handleDownloadAsPDF = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				if (response.payload.data.end_of_day.length>0) {
					let header = [" ", " ", " ", " ", " "];
					let data_set = [];
					let eod = response.payload.data.end_of_day[0];
					for (let i=0; i<response.payload.data.pos_terminals.length; i++) {
						let pos = response.payload.data.pos_terminals[i];
						data_set = data_set.concat([
							[`End of Day Report`, `(${eod.closing_date})`, " ", " ", " "],
							["POS Terminal Name", pos, " ", " ", ""],
							["Store", eod.branch_name, " ", " ", " "],
							[" ", " ", " ", " ", " "],
							[" ", "Change Fund", "Net Cash Count", "POS Cash Collections", "Cash Overage/ (Shortage)"],
						]);
						let totalChangeFund = 0;
						let totalNetCashCount = 0;
						let totalPosCashCollections = 0;
						let totalCashOverageAndShortage = 0;
						for (let i=0; i<response.payload.data.end_of_day.length; i++) {
							let eodr = response.payload.data.end_of_day[i];
							if (eodr.terminal_name===pos) {
								data_set = data_set.concat([
									[`Shift ${i+1} - Cashier Open`, Util.formatCurrency(`${eodr.cash_beginning}`,2), " ", " ", " "],
									[`Shift ${i+1} - Cashier Closed`, " ", Util.formatCurrency(`${eodr.cash_ending-eodr.cash_beginning}`,2), Util.formatCurrency(`${eodr.pos_cash_collections}`,2), Util.formatCurrency(`${eodr.cash_ending-eodr.cash_beginning-eodr.pos_cash_collections}`,2)],
								]);
								totalChangeFund += parseFloat(eodr.cash_beginning);
								totalNetCashCount += parseFloat(eodr.cash_ending-eodr.cash_beginning);
								totalPosCashCollections += parseFloat(eodr.pos_cash_collections);
								totalCashOverageAndShortage += parseFloat(eodr.cash_ending-eodr.cash_beginning-eodr.pos_cash_collections);
							}
						}
						data_set = data_set.concat([
							[" ", " ", " ", " ", " "],
							["Total", Util.formatCurrency(`${totalChangeFund}`,2), Util.formatCurrency(`${totalNetCashCount}`,2), Util.formatCurrency(`${totalPosCashCollections}`,2), Util.formatCurrency(`${totalCashOverageAndShortage}`,2)],
						]);
						let totalTransactionsCount = parseFloat(response.payload.data.unpaid_orders_count)+parseFloat(response.payload.data.cash_collections_count)+parseFloat(response.payload.data.other_collections_count);
						let totalTransactionsAmount = parseFloat(response.payload.data.unpaid_orders_amount)+parseFloat(response.payload.data.cash_collections_amount)+parseFloat(response.payload.data.other_collections_amount);
						data_set = data_set.concat([
							[" ", " ", " ", " ", " "],
							[`POS Transactions for this day (${eod.closing_date})`, " ", " ", " ", " "],
							[" ", " ", " ", " ", " "],
							[" ", " ", " ", "Transaction Count", "Total Amount"],
							["Unpaid Orders", " ", " ", Util.formatCurrency(`${response.payload.data.unpaid_orders_count}`,2), Util.formatCurrency(`${response.payload.data.unpaid_orders_amount}`,2)],
							["Cash Collections", " ", " ", Util.formatCurrency(`${response.payload.data.cash_collections_count}`,2), Util.formatCurrency(`${response.payload.data.cash_collections_amount}`,2)],
							["Other Collections", " ", " ", Util.formatCurrency(`${response.payload.data.other_collections_count}`,2), Util.formatCurrency(`${response.payload.data.other_collections_amount}`,2)],
							[" ", " ", " ", " ", " "],
							["Total transactions for the day", " ", " ", Util.formatCurrency(`${totalTransactionsCount}`,2), Util.formatCurrency(`${totalTransactionsAmount}`,2)],
						]);
						let totalCollectionsForTheDay = (parseFloat(response.payload.data.cash_sales_amount)+parseFloat(response.payload.data.credit_card_sales_amount)+parseFloat(response.payload.data.loyalty_reward_sales_amount)+parseFloat(response.payload.data.gcash_sales_amount)+parseFloat(response.payload.data.paymaya_sales_amount));
						data_set = data_set.concat([
							[" ", " ", " ", " ", " "],
							[" ", " ", " ", " ", " "],
							["Collections for the day", "", " ", " ", "Amount"],
							["Cash", "", " ", " ", Util.formatCurrency(`${response.payload.data.cash_sales_amount}`,2)],
							["Credit Card", "", " ", " ", Util.formatCurrency(`${response.payload.data.credit_card_sales_amount}`,2)],
							["Loyalty Reward", "", " ", " ", Util.formatCurrency(`${response.payload.data.loyalty_reward_sales_amount}`,2)],
							["GCash", "", " ", " ", Util.formatCurrency(`${response.payload.data.gcash_sales_amount}`,2)],
							["Paymaya", "", " ", " ", Util.formatCurrency(`${response.payload.data.paymaya_sales_amount}`,2)],
							["Total", "", " ", " ", Util.formatCurrency(`${totalCollectionsForTheDay}`,2)],
						]);
						data_set = data_set.concat([
							[" ", " ", " ", " ", " "],
							["Loyalty Credits Purchase:", "", " ", " ", "Amount"],
							["Cash", "", " ", " ", Util.formatCurrency(`${response.payload.data.cash_lcp_amount}`,2)],
							["Credit Card", "", " ", " ", Util.formatCurrency(`${response.payload.data.credit_card_lcp_amount}`,2)],
							["GCash", "", " ", " ", Util.formatCurrency(`${response.payload.data.gcash_lcp_amount}`,2)],
							["Paymaya", "", " ", " ", Util.formatCurrency(`${response.payload.data.paymaya_lcp_amount}`,2)],
							["Total", "", " ", " ", Util.formatCurrency(`${(parseFloat(response.payload.data.cash_lcp_amount)+parseFloat(response.payload.data.credit_card_lcp_amount)+parseFloat(response.payload.data.gcash_lcp_amount)+parseFloat(response.payload.data.paymaya_lcp_amount))}`,2)],
						]);
						data_set = data_set.concat([
							[" ", " ", " ", " ", " "],
							["Collections breakdown:", "", " ", " ", ""],
							["Collected from previous Unpaid Orders", "", " ", " ", Util.formatCurrency(`${parseFloat(response.payload.data.collected_from_previous_unpaid_orders)}`,2)],
							["", "Cash", " ", Util.formatCurrency(`${response.payload.data.cash_collected_from_previous_unpaid_orders}`,2), " "],
							["", "Others", " ", Util.formatCurrency(`${response.payload.data.others_collected_from_previous_unpaid_orders}`,2), " "],
							["Collected from today's invoices/ receipts", "", " ", " ", Util.formatCurrency(`${parseFloat(response.payload.data.cash_collected_from_today_invoices)+parseFloat(response.payload.data.others_collected_from_today_invoices)}`,2)],
							["", "Cash", " ", Util.formatCurrency(`${response.payload.data.cash_collected_from_today_invoices}`,2), " "],
							["", "Others", " ", Util.formatCurrency(`${response.payload.data.others_collected_from_today_invoices}`,2), " "],
							["Total", "", " ", " ", Util.formatCurrency(`${parseFloat(response.payload.data.cash_collected_from_previous_unpaid_orders)+parseFloat(response.payload.data.others_collected_from_previous_unpaid_orders)+parseFloat(response.payload.data.cash_collected_from_today_invoices)+parseFloat(response.payload.data.others_collected_from_today_invoices)}`,2)],
						]);
						data_set = data_set.concat([
							[" ", " ", " ", " ", " "],
							["Unpaid Orders:", "", " ", " ", ""],
							["Beginning Balance", "", " ", " ", Util.formatCurrency(`${response.payload.data.beginning_balance}`,2)],
							["Add: Today's transactions", "", " ", " ", Util.formatCurrency(`${response.payload.data.unpaid_orders_amount}`,2)],
							["Less: Collections", "", " ", " ", Util.formatCurrency(`${(-1*(parseFloat(response.payload.data.collected_from_previous_unpaid_orders)))}`,2)],
							["Ending Balance", "", " ", " ", Util.formatCurrency(`${parseFloat(response.payload.data.beginning_balance)+parseFloat(response.payload.data.unpaid_orders_amount)+(-1*(parseFloat(response.payload.data.collected_from_previous_unpaid_orders)))}`,2)],
						]);
						data_set = data_set.concat([
							[" ", " ", " ", " ", " "],
							["Unpaid Orders: Per Document", "", " ", " ", ""],
							["Order Date", "Document No.", "Customer Name", "", "Amount"],
						]);
						let total = 0;
						for (let i=0; i<response.payload.data.unpaid_orders.length; i++) {
							let unpaidOrder = response.payload.data.unpaid_orders[i];
							data_set = data_set.concat([
								[unpaidOrder.document_date, unpaidOrder.reference_number, unpaidOrder.customer_first_name ? `${unpaidOrder.customer_first_name} ${unpaidOrder.customer_last_name}` : "", "", Util.formatCurrency(`${unpaidOrder.amount_due}`,2)],
							]);
							total += parseFloat(unpaidOrder.amount_due);
						}
						data_set = data_set.concat([
							["", "", "", "Total:", Util.formatCurrency(`${total}`,2)],
						]);
					}
					PDF.exportWithoutHeader(
						translate.worksheet.file_name.END_OF_DAY,
						header,
						data_set,
						auth.active_company,
						"letter",
						"portrait",
						[1, 2, 3, 4],
						["*", "*", "*", "*", "*"]
					);
				}
				else {
					toast.error(
						translate.message.NO_RECORD_FOUND,
						Util.getToasterStyle(resources.status.ERROR)
					);
				}
			}
		}
		dispatch(fetchEndOfDayReportAsync({
			transaction_date:transaction_date ? moment(transaction_date).format(resources.date_format.SYSTEM) : "",
			branch:branch ? branch.value : "",
		})).then((res) => handleResponse(res));
	}

	const handleDownloadAsXLSX = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				if (response.payload.data.end_of_day.length>0) {
					let header = [" ", " ", " ", " ", " ", " "];
					let data_set = [];
					for (let i=0; i<response.payload.data.end_of_day.length; i++) {
						let eod = response.payload.data.end_of_day[i];
						data_set = data_set.concat([
							[`End of Day Report`, `(${eod.closing_date})`, " ", " ", " ", " "],
							["POS Terminal Name", eod.terminal_name, " ", " ", "", ""],
							["Store", eod.branch_name, " ", " ", " ", " "],
						]);
					}
					Excel.export(
						translate.worksheet.file_name.END_OF_DAY,
						header,
						data_set,
						auth.active_company
					)
				}
				else {
					toast.error(
						translate.message.NO_RECORD_FOUND,
						Util.getToasterStyle(resources.status.ERROR)
					);
				}
			}
		}
		dispatch(fetchEndOfDayReportAsync({
			transaction_date:transaction_date ? moment(transaction_date).format(resources.date_format.SYSTEM) : "",
			branch:branch ? branch.value : "",
		})).then((res) => handleResponse(res));
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container center" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>
				{ Util.functionTitle(translate.text.END_OF_DAY) }
				<div className="function-sub-title">
				</div>

				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={"Download as PDF"}
						className="function-button"
						icon={<Icon icon="document-share" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={
							e => handleDownloadAsPDF()
						}
					/>
					<Button
						text={"Download as XLSX"}
						className="function-button"
						icon={<Icon icon="th" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={
							e => handleDownloadAsXLSX()
						}
					/>
				</ButtonGroup>

				<Card className="function-card">
					<div className="row mb-5">
				   		<div className="col-sm-12 col-md-3 col-lg-2 mt-5">
							<MuiAutocomplete
								setSelectedValue={setBranch}
								selected_value={branch}
								isMultiple={false}
								label={"Filter by Branch"}
								data={branches}
							/>
						</div>
						<TextField
							className="col-sm-12 col-md-2 col-lg-1 mt-5"
							id="transaction_date"
							label={"Transaction Date"}
							type="date"
							variant="outlined"
							size="small"
							defaultValue={transaction_date}
							onChange={(e) => setTransactionDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>
				</Card>

			</div>
		</div>
	);
}
