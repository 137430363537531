import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { useParams } from "react-router";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createStockTransferAsync,
	updateStockTransferAsync,
	submitStockTransferAsync,
	rejectStockTransferAsync,
	voidStockTransferAsync,
	approveStockTransferAsync,
	fetchAllStockTransferLineItemsAsync,
	toggleSidebarCollapsed,
	fetchAllProfitCostCentersAsync,
	fetchAllItemsAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	H3,
	H5,
	FormGroup,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { StockTransferLineItem } from "./StockTransferLineItem";
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { Attachment } from "../../../common/Attachment";
import { Comment } from "../../../common/Comment";
import { TransactionLog } from "../../../common/TransactionLog";
import { StockTransferSpreadsheet } from "../../../spreadsheets/StockTransferSpreadsheet";

export function StockTransfer() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const default_line_items = Array(7).fill({item:null, quantity:"", uom:null});
	const { document_id } = useParams();
	const [id, setId] = useState(document_id ? document_id : null);
	const [stock_transfer, setStockTransfer] = useState(null);
	const [line_items, setLineItems] = useState(default_line_items);
	const [document_date, setDocumentDate] = useState(stock_transfer ? moment(stock_transfer.document_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT) : moment(new Date()).format(resources.date_format.INPUT));
	const [reference_number, setReferenceNumber] = useState("");
	const [transaction_description, setTransactionDescription] = useState("");
	const [transaction_logs, setTransactionLogs] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [transfer_from , setTransferFrom] = useState(null);
	const [transfer_to , setTransferTo] = useState(null);
	const [items, setItems] = useState([]);
	const [profit_cost_centers, setProfitCostCenters] = useState([]);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_delete_dialog_open, setIsConfirmDeleteDialogOpen] = useState(false);
	const [is_confirm_submit_dialog_open, setIsConfirmSubmitDialogOpen] = useState(false);
	const [is_confirm_approve_dialog_open, setIsConfirmApproveDialogOpen] = useState(false);
	const [is_confirm_reject_dialog_open, setIsConfirmRejectDialogOpen] = useState(false);
	const [is_confirm_void_dialog_open, setIsConfirmVoidDialogOpen] = useState(false);
	const [is_editable, setIsEditable] = useState(true);
	const [is_authorizable, setIsAuthorizable] = useState(document_id ? auth.approval_pipelines.find((pipe) => pipe.stock_transfer_id===document_id)!==undefined : false);

	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: translate.worksheet.default_header.STOCK_TRANSFER,
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.STOCK_TRANSFER));
	}, [
		dispatch,
		translate.breadcrumb.STOCK_TRANSFER
	]);

	useEffect(() => {
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllItemsAsync({})).then((response) => response.payload.data ? setItems(response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)))) : void(0));
			const handleFetchProfitCostCenterResponse = (response) => {
				if (response.payload.data) {
					let branches = response.payload.data.branches.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((branch) => ({label:`${branch.code} | ${branch.name}`, type:branch.type, value:branch.id}));
					let departments = response.payload.data.departments.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((department) => ({label:`${department.code} | ${department.name}`, type:department.type, value:department.id}));
					let projects = response.payload.data.projects.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((project) => ({label:`${project.code} | ${project.name}`, type:project.type, value:project.id}));
					setProfitCostCenters(branches.concat(departments).concat(projects));
				}
			}
			dispatch(fetchAllProfitCostCentersAsync({})).then((response) => handleFetchProfitCostCenterResponse(response));
		}
	}, [
		dispatch,
		worksheet.is_readonly_mode,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const loadDocument = useCallback((stockTransfer, stockTransferLineItems) => {
		setStockTransfer(stockTransfer);
		setId(stockTransfer.id);
		setDocumentDate(moment(stockTransfer.document_date).format(resources.date_format.INPUT));
		setReferenceNumber(stockTransfer.reference_number);
		setTransferFrom(stockTransfer.transfer_from!==null ? JSON.parse(stockTransfer.transfer_from) : null);
		setTransferTo(stockTransfer.transfer_to!==null ? JSON.parse(stockTransfer.transfer_to) : null);
		setTransactionLogs(stockTransfer.transaction_logs);
		setTransactionDescription(stockTransfer.description);
		setIsEditable(stockTransfer.is_editable);
		setIsAuthorizable(stockTransfer.id ? auth.approval_pipelines.find((pipe) => pipe.stock_transfer_id===stockTransfer.id)!==undefined : false);
		let loadedItems = [];
		for (let i=0; i<stockTransferLineItems.length; i++) {
			let stockTransferLineItem = stockTransferLineItems[i];
			let quantity = stockTransferLineItem.quantity;
			let loadedItem =
			{
				item:{label:stockTransferLineItem.item.label, value:stockTransferLineItem.item.id},
				quantity:quantity,
				uom:{label:stockTransferLineItem.unit_of_measure.code, value:stockTransferLineItem.unit_of_measure.id},
			};
			loadedItems.push(loadedItem);
		}
		setLineItems(loadedItems);
	},[auth.approval_pipelines]);

	useEffect(() => {
		if (id) {
			dispatch(fetchAllStockTransferLineItemsAsync({stock_transfer_id:id})).then((response) => loadDocument(response.payload.data.stock_transfer, response.payload.data.stock_transfer_line_items));
		}
	}, [dispatch, id, loadDocument]);

	const handleClearButtonClickedEvent = () => {
		setId(null);
		setStockTransfer(null);
		setDocumentDate(moment(new Date()).format(resources.date_format.INPUT));
		setReferenceNumber("");
		setTransactionDescription("");
		setTransferFrom(null);
		setTransferTo(null);
		setLineItems(default_line_items);
		setAttachments([]);
		setIsEditable(true);
		setIsAuthorizable(false);
		history.push('/warehouse-inventory/stock-transfer-request');
	};

	const handleDeleteRowsButtonClickedEvent = () => {
		let rows = selected_rows.map(
			(row) => worksheet.data_set[row]
		);
		let promises = [];
		for (let i = 0; i < rows.length; i++) {
			/*promises.push(
				dispatch(
					deleteItemAsync({
						id: rows[i][0]
					})
				)
			);*/
		}
		Promise.all(promises).then((response) => {
			var success = true;
			var message = null;
			for (let i = 0; i < response.length; i++) {
				if (response[i].payload !== undefined) {
					if (response[i].payload.status !== resources.status.SUCCESS) {
						success = false;
						break;
					}
					else {
						if (message == null) {
							message = response[i].payload.message;
						}
					}
				}
			}
			if (success === true) {
				toast.success(
					message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			handleClearButtonClickedEvent();
		});
	};

	const handleSubmitButtonClickedResponse = (response) => {
		let doc = response.payload.data.stock_transfer;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.stock_transfer.id ? [
				doc.id,
				doc.document_number,
				doc.document_date,
				doc.approval_status,
				doc.reference_number,
				doc.description,
				doc.transfer_from!==null&&JSON.parse(doc.transfer_from)!==null ? JSON.parse(doc.transfer_from).label : "",
				doc.transfer_from!==null&&JSON.parse(doc.transfer_from)!==null ? JSON.parse(doc.transfer_from).type : "",
				doc.transfer_to!==null&&JSON.parse(doc.transfer_to)!==null ? JSON.parse(doc.transfer_to).label : "",
				doc.transfer_to!==null&&JSON.parse(doc.transfer_to)!==null ? JSON.parse(doc.transfer_to).type : "",
			] : ds))
		});
		setAttachments([]);
		refreshData();
	}

	const handleUpdateButtonClickedResponse = (response) => {
		let doc = response.payload.data.stock_transfer;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.stock_transfer.id ? [
				doc.id,
				doc.document_number,
				doc.document_date,
				doc.approval_status,
				doc.reference_number,
				doc.description,
				doc.transfer_from!==null&&JSON.parse(doc.transfer_from)!==null ? JSON.parse(doc.transfer_from).label : "",
				doc.transfer_from!==null&&JSON.parse(doc.transfer_from)!==null ? JSON.parse(doc.transfer_from).type : "",
				doc.transfer_to!==null&&JSON.parse(doc.transfer_to)!==null ? JSON.parse(doc.transfer_to).label : "",
				doc.transfer_to!==null&&JSON.parse(doc.transfer_to)!==null ? JSON.parse(doc.transfer_to).type : "",
			] : ds))
		});
		loadDocument(response.payload.data.stock_transfer, response.payload.data.stock_transfer_line_items);
		setAttachments([]);
	}

	const handleCreateButtonClickedResponse = (response) => {
		let doc = response.payload.data.stock_transfer;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:[
				[
					doc.id,
					doc.document_number,
					doc.document_date,
					doc.approval_status,
					doc.reference_number,
					doc.description,
					doc.transfer_from!==null&&JSON.parse(doc.transfer_from)!==null ? JSON.parse(doc.transfer_from).label : "",
					doc.transfer_from!==null&&JSON.parse(doc.transfer_from)!==null ? JSON.parse(doc.transfer_from).type : "",
					doc.transfer_to!==null&&JSON.parse(doc.transfer_to)!==null ? JSON.parse(doc.transfer_to).label : "",
					doc.transfer_to!==null&&JSON.parse(doc.transfer_to)!==null ? JSON.parse(doc.transfer_to).type : "",
				],
				...worksheet.data_set
			]
		});
		loadDocument(response.payload.data.stock_transfer, response.payload.data.stock_transfer_line_items);
		setAttachments([]);
	}

	const handleEditButtonClickedEvent = async (row) => {
		let id = worksheet.data_set[row][0];
		setId(id);
		window.scrollTo(0,0);
	};

	const handleTransferFromChangedEvent = (transferFrom) => {
		setTransferFrom(transferFrom);
		setTransferTo(null);
	}

	const refreshData = () => {
		handleClearButtonClickedEvent();
	}

	const handleSubmitButtonClickedEvent = () => {
		dispatch(
			submitStockTransferAsync({
				id:id,
				document_date:document_date,
				reference_number:reference_number,
				transaction_description:transaction_description,
				transfer_from:transfer_from,
				transfer_to:transfer_to,
				line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.uom!==null)),
				attachments:attachments,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleSubmitButtonClickedResponse(response) : null))
	};

	const handleApproveButtonClickedEvent = () => {
		dispatch(
			approveStockTransferAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleRejectButtonClickedEvent = () => {
		dispatch(
			rejectStockTransferAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleVoidButtonClickedEvent = () => {
		dispatch(
			voidStockTransferAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleVoidButtonClickedResponse(response) : null))
	};

	const handleVoidButtonClickedResponse = (response) => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.filter((ds) => (ds[0]!==response.payload.data.stock_transfer.id))
		});
		setAttachments([]);
		refreshData();
		toast.success(
			response.payload.message,
			Util.getToasterStyle(resources.status.SUCCESS)
		);
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{ Util.functionTitle(translate.text.STOCK_TRANSFER) }
				<div className="function-sub-title">
					{"This form is use for internal transfer request of items between department, branch and/or project."}
				</div>
				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						{
							id===null ?
							<>
								<Button
									text={translate.button.CREATE_DRAFT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING||!is_editable}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={(e) =>
										dispatch(
											createStockTransferAsync({
												document_date:document_date,
												reference_number:reference_number,
												transaction_description:transaction_description,
												transfer_from:transfer_from,
												transfer_to:transfer_to,
												line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.uom!==null)),
												attachments:attachments,
											})
										).then((response) => (response.payload.status===resources.status.SUCCESS ? handleCreateButtonClickedResponse(response) : null))
									}
								/>
							</>
							:
							<>
								<Button
									text={translate.button.UPDATE}
									className="function-button"
									disabled={auth.status === resources.status.LOADING||!is_editable}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={(e) =>
										dispatch(
											updateStockTransferAsync({
												id:id,
												document_date:document_date,
												reference_number:reference_number,
												transaction_description:transaction_description,
												transfer_from:transfer_from,
												transfer_to:transfer_to,
												line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.uom!==null)),
												attachments:attachments,
											})
										).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateButtonClickedResponse(response) : null))
									}
								/>
								<Button
									text={translate.button.SUBMIT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING||!is_editable}
									icon={<Icon icon="send-message" className="orange"/>}
									onClick={e => setIsConfirmSubmitDialogOpen(!is_confirm_submit_dialog_open)}
								/>
								<Button
									text={translate.button.PRINT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="print" className="orange"/>}
									onClick={() =>
										PDF.exportStockTransferForm(
											translate.worksheet.file_name.STOCK_TRANSFER,
											[stock_transfer],
											line_items,
											auth.active_company
										)
									}
								/>
								{
									is_editable &&
									<Button
										text={translate.button.VOID}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="ban-circle" className="orange"/>}
										onClick={e => setIsConfirmVoidDialogOpen(!is_confirm_void_dialog_open)}
									/>
								}
								{
									stock_transfer && stock_transfer.approval_status===auth.enums.approval_status.FOR_APPROVAL && is_authorizable &&
									<>
										<Button
											text={translate.button.APPROVE}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="tick" className="orange"/>}
											onClick={e => setIsConfirmApproveDialogOpen(!is_confirm_approve_dialog_open)}
										/>
										<Button
											text={translate.button.REJECT}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="cross" className="orange"/>}
											onClick={e => setIsConfirmRejectDialogOpen(!is_confirm_reject_dialog_open)}
										/>
									</>
								}
							</>
						}
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleClearButtonClickedEvent}
						/>
					</ButtonGroup>
				}

				{
					worksheet.is_readonly_mode &&
					<div className="function-card">
						<div className="row">
							<Card
								className="col-sm-12 col-md-4 col-lg-3"
								style={{
									background: "lightblue",
								}}
							>
								<FormGroup>
									<H3 className="center" style={{color:"blue"}}>{stock_transfer!==null ? stock_transfer.document_number : null}</H3>
									<H5 className="center" style={{color:"green"}}>{stock_transfer!==null ? stock_transfer.approval_status : null}</H5>
								</FormGroup>

								<div className="row mt-5">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										id="document_date"
										label={translate.placeholder.PICK_A_DATE}
										type="date"
										variant="outlined"
										size="small"
										defaultValue={document_date}
										inputProps={{readOnly:!is_editable}}
										onChange={(e) => setDocumentDate(e.target.value)}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={reference_number}
										label={translate.placeholder.ENTER_TRANSACTION_REFERENCE_NUMBER}
										onChange={(e) =>
											setReferenceNumber(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
						        </div>

								<div className="row mb-5">
									<div className="col-sm-12 col-md-6 col-lg-6 mt-5">
										<MuiAutocomplete
											setSelectedValue={handleTransferFromChangedEvent}
											selected_value={transfer_from}
											isMultiple={false}
											label={`${translate.placeholder.SELECT_TRANSFER_FROM} ${transfer_from ? transfer_from.type : ''}`}
											data={profit_cost_centers}
											isDisabled={!is_editable}
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 mt-5">
										<MuiAutocomplete
											setSelectedValue={setTransferTo}
											selected_value={transfer_to}
											isMultiple={false}
											isDisabled={transfer_from===null||!is_editable}
											label={`${translate.placeholder.SELECT_TRANSFER_TO} ${transfer_to ? transfer_to.type : ''}`}
											data={transfer_from ? profit_cost_centers.filter((pcc,key) => !(pcc.value===transfer_from.value && pcc.type===transfer_from.type) ) : profit_cost_centers}
										/>
									</div>
								</div>

						        <div className="row mb-5 mt-5">
									<TextField
										multiline
										minRows={4}
										className="col-sm-12 col-md-12 col-lg-12"
										size="small"
										variant="outlined"
										label={translate.placeholder.ENTER_TRANSACTION_DESCRIPTION}
										value={transaction_description}
										onChange={(e) =>
											setTransactionDescription(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<Attachment
									id={id}
									transaction={stock_transfer}
									setTransaction={setStockTransfer}
									attachments={attachments}
									setAttachments={setAttachments}
								/>

								{
									id!==null && stock_transfer &&
									<>
										<Comment
											id={id}
											document_type={stock_transfer.document_type.name}
											comments={stock_transfer.comments}
											created_by={stock_transfer.created_by}
											setDocument={setStockTransfer}
											is_editable={is_editable}
											is_authorizable={is_authorizable}
										/>
										<TransactionLog
											transaction_logs={transaction_logs}
										/>
				                    </>
								}

							</Card>
							{
								<StockTransferLineItem
									id={id}
									is_editable={is_editable}
									transfer_from={transfer_from}
									default_line_items={default_line_items}
									line_items={line_items}
									setLineItems={setLineItems}
									items={items}
								/>
							}
						</div>
					</div>
				}

				<Card className="function-card-spreadsheet">
					<StockTransferSpreadsheet
						enable_select={true}
						enable_edit={true}
						selected_rows={selected_rows}
						setSelectedRows={setSelectedRows}
						handleEditButtonClickedEvent={handleEditButtonClickedEvent}
						handleClearButtonClickedEvent={handleClearButtonClickedEvent}
						worksheet={worksheet}
						setWorkSheet={setWorkSheet}
						refreshData={refreshData}
						is_confirm_delete_dialog_open={is_confirm_delete_dialog_open}
						setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
						profit_cost_centers={profit_cost_centers}
						DEFAULT_DATA_SET={DEFAULT_DATA_SET}
					/>
				</Card>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_delete_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDeleteDialogOpen}
					confirmAction={handleDeleteRowsButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted/voided, document will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain document is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_submit_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmSubmitDialogOpen}
					confirmAction={handleSubmitButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">SUBMIT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_approve_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmApproveDialogOpen}
					confirmAction={handleApproveButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">APPROVE</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_reject_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmRejectDialogOpen}
					confirmAction={handleRejectButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">REJECT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_void_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmVoidDialogOpen}
					confirmAction={handleVoidButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">VOID</span> this document ?
						</span>
					}
				/>

			</div>
		</div>
	);
}
