import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import { User } from "./features/system-settings/user/User";
import { ProfitCostCenter } from "./features/system-settings/profit-cost-center/ProfitCostCenter";
import { Permission } from "./features/system-settings/permission/Permission";
import { Authorizer } from "./features/system-settings/authorizer/Authorizer";
import { Taxes } from "./features/system-settings/taxes/Taxes";
import { Discounts } from "./features/system-settings/discounts/Discounts";
import { OrderingAndAppointmentApp } from "./features/system-settings/ordering-and-appointment-app/OrderingAndAppointmentApp";
import { Clock } from "./Clock";
import { SystemSettings } from "./features/system-settings/SystemSettings";
import { WarehouseInventory } from "./features/warehouse-inventory/WarehouseInventory";
import { PointOfSale } from "./features/point-of-sale/PointOfSale";
import { AdminPanel } from "./features/admin-panel/AdminPanel";
import { MySubscription } from "./MySubscription";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { Navigation } from "./Navigation";
import { Auth } from "./features/auth/Auth";
import { AccountDeletion } from "./features/auth/AccountDeletion";
import { PasswordResetLink } from "./features/auth/PasswordResetLink";
import { ForgotPassword } from "./features/auth/ForgotPassword";
import { ChangePassword } from "./features/auth/ChangePassword";
import { PendingVerification } from "./features/auth/PendingVerification";
import { AcceptInvitation } from "./features/auth/AcceptInvitation";
import { PendingUserVerification } from "./features/auth/PendingUserVerification";
import { GeneralSettings } from "./features/warehouse-inventory/general-settings/GeneralSettings";
import { UnitOfMeasure } from "./features/warehouse-inventory/unit-of-measure/UnitOfMeasure";
import { Location } from "./features/warehouse-inventory/location/Location";
import { ItemClass } from "./features/warehouse-inventory/item-class/ItemClass";
import { InventoryItem } from "./features/warehouse-inventory/item/InventoryItem";
import { FinishedGoodsComponent } from "./features/warehouse-inventory/finished-goods-component/FinishedGoodsComponent";
import { InventoryMobileScanner } from "./features/warehouse-inventory/inventory-mobile-scanner/InventoryMobileScanner";
import { ManageTransactions } from "./features/warehouse-inventory/manage-transactions/ManageTransactions";
import { ItemLocationTransfer } from "./features/warehouse-inventory/item-location-transfer/ItemLocationTransfer";
import { StockRequest } from "./features/warehouse-inventory/stock-request/StockRequest";
import { StockTransfer } from "./features/warehouse-inventory/stock-transfer/StockTransfer";
import { FinishedGoodProduced } from "./features/warehouse-inventory/finished-good-produced/FinishedGoodProduced";
import { StockAcceptance } from "./features/warehouse-inventory/stock-acceptance/StockAcceptance";
import { ImportedItemsReceipt } from "./features/warehouse-inventory/imported-items-receipt/ImportedItemsReceipt";
import { StockIssuance } from "./features/warehouse-inventory/stock-issuance/StockIssuance";
import { StockAdjustment } from "./features/warehouse-inventory/stock-adjustment/StockAdjustment";
import { BeginningBalance } from "./features/warehouse-inventory/beginning-balance/BeginningBalance";
import { StockCount } from "./features/warehouse-inventory/stock-count/StockCount";
import { ItemHistory } from "./features/warehouse-inventory/item-history/ItemHistory";
import { DeliveryReceipt } from "./features/warehouse-inventory/delivery-receipt/DeliveryReceipt";
import { IssuedItemsForAcceptance } from "./features/warehouse-inventory/issued-items-for-acceptance/IssuedItemsForAcceptance";
import { StockMovement } from "./features/warehouse-inventory/stock-movement/StockMovement";
import { StockOnHand } from "./features/warehouse-inventory/stock-on-hand/StockOnHand";
import { InventoryItemByStatus } from "./features/warehouse-inventory/inventory-item-by-status/InventoryItemByStatus";
import { ItemConditions } from "./features/warehouse-inventory/item-conditions/ItemConditions";
import { ManageCashierShifts } from "./features/point-of-sale/manage-cashier-shifts/ManageCashierShifts";
import { Sales } from "./features/point-of-sale/sales/Sales";
import { ZReading } from "./features/point-of-sale/z-reading/ZReading";
import { EndOfDay } from "./features/point-of-sale/end-of-day/EndOfDay";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Document } from "./Document";
import { Inbox } from "./Inbox";
import { Order } from "./Order";
import { ErrorPage } from "./ErrorPage";
import { CycleCountReconciliation } from "./CycleCountReconciliation";
import { useSelector } from "react-redux";
import {
	selectAuth
} from "./features/auth/authSlice";
import { resources } from "./resources/index";
import { PosTerminalSettings } from "./features/point-of-sale/pos-terminal-settings/PosTerminalSettings";
import { PosItemsAndServices } from "./features/point-of-sale/pos-items-and-services/PosItemsAndServices";
import { Subscriptions } from "./features/admin-panel/subscriptions/Subscriptions";

function App() {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];

	return (
		<div>
			<Router>
			<Navigation />

			<header className="App-header">
				
					<Switch>
						<Route exact component={Auth} path="/" />
						<Route exact component={AccountDeletion} path="/ordering-and-appointment-app/account-deletion" />

						<Route exact component={Auth} path="/login" />
						
						<Route
							exact
							component={PasswordResetLink}
							path="/password-reset-link"
						/>
						
						<Route
							exact
							component={ForgotPassword}
							path="/forgot-password/:password_reset_token"
						/>
						
						<Route
							exact
							component={PendingVerification}
							path="/pending-verification/:verification_code"
						/>
						
						<Route
							exact
							component={AcceptInvitation}
							path="/accept-invitation/:company_id/:user_id"
						/>
						
						<Route
							exact
							component={PendingUserVerification}
							path="/pending-user-verification/:verification_code"
						/>

						<Route
							exact
							component={PrivacyPolicy}
							path="/privacy-policy"
						/>

						<ProtectedRoute
							exact
							component={ChangePassword}
							path="/change-password"
						/>
						
						<ProtectedRoute
							component={MySubscription}
							path="/my-subscription"
						/>
						
						<ProtectedRoute
							component={User}
							path="/system-settings/user"
						/>
						
						<ProtectedRoute
							component={ProfitCostCenter}
							path="/system-settings/profit-cost-center"
						/>

						<ProtectedRoute
							component={Permission}
							path="/system-settings/permission"
						/>
						
						<ProtectedRoute
							component={Authorizer}
							path="/system-settings/authorizer"
						/>

						<ProtectedRoute
							component={Taxes}
							path="/system-settings/taxes"
						/>

						<ProtectedRoute
							component={Discounts}
							path="/system-settings/discounts"
						/>

						<ProtectedRoute
							component={OrderingAndAppointmentApp}
							path="/system-settings/ordering-and-appointment-app"
						/>

						<ProtectedRoute
							component={SystemSettings}
							path="/system-settings"
						/>

						<ProtectedRoute
							component={GeneralSettings}
							path="/warehouse-inventory/general-settings"
						/>
						
						<ProtectedRoute
							component={UnitOfMeasure}
							path="/warehouse-inventory/unit-of-measure"
						/>
						
						<ProtectedRoute
							component={Location}
							path="/warehouse-inventory/location"
						/>
						
						<ProtectedRoute
							component={ItemClass}
							path="/warehouse-inventory/item-class"
						/>
						
						<ProtectedRoute
							component={InventoryItem}
							path="/warehouse-inventory/item"
						/>
						
						<ProtectedRoute
							component={FinishedGoodsComponent}
							path="/warehouse-inventory/finished-goods-components"
						/>
						
						<ProtectedRoute
							component={InventoryMobileScanner}
							path="/warehouse-inventory/inventory-mobile-scanner"
						/>
						
						<ProtectedRoute
							component={ManageTransactions}
							path="/warehouse-inventory/manage-transactions"
						/>

						<ProtectedRoute
							component={ItemLocationTransfer}
							path="/warehouse-inventory/item-location-transfer/:document_id"
						/>
						
						<ProtectedRoute
							component={ItemLocationTransfer}
							path="/warehouse-inventory/item-location-transfer"
						/>

						<ProtectedRoute
							component={StockRequest}
							path="/warehouse-inventory/stock-purchase-request/:document_id"
						/>
						
						<ProtectedRoute
							component={StockRequest}
							path="/warehouse-inventory/stock-purchase-request"
						/>

						<ProtectedRoute
							component={StockTransfer}
							path="/warehouse-inventory/stock-transfer-request/:document_id"
						/>
						
						<ProtectedRoute
							component={StockTransfer}
							path="/warehouse-inventory/stock-transfer-request"
						/>

						<ProtectedRoute
							component={StockAcceptance}
							path="/warehouse-inventory/stock-acceptance/:document_id"
						/>
						
						<ProtectedRoute
							component={StockAcceptance}
							path="/warehouse-inventory/stock-acceptance"
						/>

						<ProtectedRoute
							component={ImportedItemsReceipt}
							path="/warehouse-inventory/imported-items-receipt/:document_id"
						/>
						
						<ProtectedRoute
							component={ImportedItemsReceipt}
							path="/warehouse-inventory/imported-items-receipt"
						/>

						<ProtectedRoute
							component={StockAdjustment}
							path="/warehouse-inventory/stock-adjustment/:document_id"
						/>
						
						<ProtectedRoute
							component={StockAdjustment}
							path="/warehouse-inventory/stock-adjustment"
						/>

						<ProtectedRoute
							component={FinishedGoodProduced}
							path="/warehouse-inventory/finished-goods-produced/:document_id"
						/>
						
						<ProtectedRoute
							component={FinishedGoodProduced}
							path="/warehouse-inventory/finished-goods-produced"
						/>

						<ProtectedRoute
							component={StockIssuance}
							path="/warehouse-inventory/stock-issuance/:document_id"
						/>
						
						<ProtectedRoute
							component={StockIssuance}
							path="/warehouse-inventory/stock-issuance"
						/>

						<ProtectedRoute
							component={BeginningBalance}
							path="/warehouse-inventory/beginning-balance/:document_id"
						/>
						
						<ProtectedRoute
							component={BeginningBalance}
							path="/warehouse-inventory/beginning-balance"
						/>

						<ProtectedRoute
							component={StockCount}
							path="/warehouse-inventory/stock-count/:document_id"
						/>
						
						<ProtectedRoute
							component={StockCount}
							path="/warehouse-inventory/stock-count"
						/>
						
						<ProtectedRoute
							component={ItemHistory}
							path="/warehouse-inventory/item-history"
						/>
						
						<ProtectedRoute
							component={DeliveryReceipt}
							path="/warehouse-inventory/delivery-receipt"
						/>
						
						<ProtectedRoute
							component={IssuedItemsForAcceptance}
							path="/warehouse-inventory/issued-items-for-acceptance"
						/>
						
						<ProtectedRoute
							component={StockMovement}
							path="/warehouse-inventory/stock-movement"
						/>
						
						<ProtectedRoute
							component={StockOnHand}
							path="/warehouse-inventory/stock-on-hand"
						/>
						
						<ProtectedRoute
							component={InventoryItemByStatus}
							path="/warehouse-inventory/inventory-item-by-status"
						/>
						
						<ProtectedRoute
							component={ItemConditions}
							path="/warehouse-inventory/item-conditions"
						/>

						<ProtectedRoute
							component={WarehouseInventory}
							path="/warehouse-inventory"
						/>

						<ProtectedRoute
							component={ManageCashierShifts}
							path="/point-of-sale/manage-cashier-shifts"
						/>

						<ProtectedRoute
							component={PosTerminalSettings}
							path="/point-of-sale/pos-terminal-settings"
						/>

						<ProtectedRoute
							component={PosItemsAndServices}
							path="/point-of-sale/pos-items-and-services"
						/>

						<ProtectedRoute
							component={Sales}
							path="/point-of-sale/sales"
						/>

						<ProtectedRoute
							component={ZReading}
							path="/point-of-sale/z-reading"
						/>

						<ProtectedRoute
							component={EndOfDay}
							path="/point-of-sale/end-of-day"
						/>

						<ProtectedRoute
							component={PointOfSale}
							path="/point-of-sale"
						/>

						<ProtectedRoute
							component={Subscriptions}
							path="/admin-panel/subscriptions"
						/>

						<ProtectedRoute
							component={AdminPanel}
							path="/admin-panel"
						/>

						<ProtectedRoute
							component={Document}
							path="/documents/:status"
						/>

						<ProtectedRoute
							component={Inbox}
							path="/inbox"
						/>

						<ProtectedRoute
							component={Order}
							path="/orders/:status"
						/>

						<ProtectedRoute
							component={Order}
							path="/orders/view/:id"
						/>

						<ProtectedRoute
							component={CycleCountReconciliation}
							path="/cycle-count-reconciliation"
						/>

						<Route path="*" component={() => <ErrorPage message={"Page not found."}/>} />

					</Switch>
				
			</header>

			{
				auth.token && false &&
				<div className="app-status-bar">
					{
						auth.token && <Clock />
					}
					<div className="app-copyright-text">
						{translate.text.COPYRIGHT}
					</div>
				</div>
			}

		</Router>

		</div>
	);
}

export default App;
