import React from 'react';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

export function PrivacyPolicy() {

	return (
		<object data={(process.env.REACT_APP_PORT ? `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}` : `${process.env.REACT_APP_HOST}`)+`/privacy_policy.pdf`} type="application/pdf" width="1024" height="1024" style={{marginTop:70, marginBottom:70}}>
		</object>
	);

}
