import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../auth/authSlice';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import {
	fetchInventoryValueByItemClassAsync,
	fetchAllItemClassesAsync,
	updateDashboardData
} from './../auth/authSlice';
import { NoDataAvailable } from "../../common/NoDataAvailable";
import Util from '../../common/Util';

export function InventoryValueByItemClassChart() {

	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const [labels, setLabels] = useState([]);
	const [total_value, setTotalValue] = useState(0);
	const [distribution, setDistribution] = useState([]);
	const [initialized, setInitialized] = useState(false);

	const initFetch = useCallback(() => {
		const handleLabelsResponse = (res) => {
			let lbls = res.payload.data ? res.payload.data.item_classes.filter((ic) => Util.testDuplicateRegex(ic.name)).map((ic) => ic.name) : [];
			if (lbls.length>0) {
				setLabels(lbls);
				dispatch(fetchInventoryValueByItemClassAsync({})).then((response) => loadData(response, lbls));
				const loadData = (response, lbls) => {
					if (response.payload.data) {
						let totalValue = parseFloat(response.payload.data.total_value.replace(",",""));
						setTotalValue(totalValue);
						let obj = {};
						for (let i=0; i<response.payload.data.distribution.length; i++) {
							let dist = response.payload.data.distribution[i];
							obj[dist.name] = Math.ceil(parseFloat(dist.inventory_value.replace(",","")))
						}
						setDistribution(obj);
						dispatch(updateDashboardData({inventory_value_by_item_class:{
							total_value:totalValue,
							labels:lbls,
							distribution:obj,
						}}));
						setInitialized(true);
					}
				}
			}
		}
		if (auth.active_company.application_id===null) dispatch(fetchAllItemClassesAsync({})).then((response) => handleLabelsResponse(response));
	}, [dispatch, auth.active_company.application_id]);

	useEffect(() => {
		initFetch()
	}, [initFetch]);

	useEffect(() => {
		if (auth.active_company.application_id===null && auth.dashboard.inventory_value_by_item_class) {
			setTotalValue(auth.dashboard.inventory_value_by_item_class.total_value);
			setLabels(auth.dashboard.inventory_value_by_item_class.labels);
			setDistribution(auth.dashboard.inventory_value_by_item_class.distribution);
		}
	}, [auth.active_company.application_id, auth.dashboard.inventory_value_by_item_class]);

	return (
		total_value>0 ?
		<Bar 
			options={{
			indexAxis: 'y',
			elements: {
				bar: {
					borderWidth: 2,
				},
			},
			responsive: true,
			plugins: {
				legend: {
					position: 'top',
					display: false,
				},
				title: {
					display: true,
					font: {
						size: 17,
						family: "Arial",
					},
					color: "#106ba3",
					text: `Inventory Value by Item Class in ( ${auth.active_company.currency.name} )`,
				},
			},
			}} 
			data={{
				labels,
				datasets: [
					{
						label: '', 
						data: labels.map((itemClass) => distribution[itemClass] ? distribution[itemClass] : 0),
						borderColor: 'gold',
						backgroundColor: 'gold',
					},
				],
			}} 
		/> : 
		<NoDataAvailable text={initialized ? "No Posted Transaction/s Yet." : (auth.active_company.application_id ? "System Implementation Ongoing" : "No Data Found")}/>

	);

}
