import React, { useCallback, useEffect, useState } from "react";
import Util from "../../../common/Util";
import { Note } from "../../../common/Note";
import { SideBar } from '../../../SideBar';
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	toggleSidebarCollapsed,
	skipProcessAsync,
	fetchAllUsersAsync,
	saveCashierShiftAsync,
	fetchCashierShiftsAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { CashierShiftSpreadsheet } from "../../../spreadsheets/CashierShiftSpreadsheet";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";

export function ManageCashierShifts() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [selected_rows, setSelectedRows] = useState([]);
	const [users, setUsers] = useState([]);
	const [days] = useState(Object.values(auth.enums.days_of_week).map((day) => ({label:day, value:day})));
	const [day, setDay] = useState(days[0]);
	const [schedules, setSchedules] = useState([]);
	const [role_user] = useState(auth.role_user.filter((roleUser) => (roleUser.user_id===auth.user.id)).map((roleUser) => roleUser.role_id));
	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		const handleResponse = (response) => {
			let results = response.payload.data.users;
			setUsers(results);
			setSchedules(results.map((usr) => ({
				user_id:usr.id,
				shift_start:"",
				shift_end:"",
			})));
		}
		dispatch(updateNavigation(translate.breadcrumb.MANAGE_CASHIER_SHIFTS));
		dispatch(fetchAllUsersAsync({position:auth.enums.positions.CASHIER})).then((response) => (response.payload.status===resources.status.SUCCESS ? handleResponse(response) : void(0)));
	}, [
		dispatch,
		translate.breadcrumb.MANAGE_CASHIER_SHIFTS,
		auth.enums.positions.CASHIER
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (day) {
			const handleResponse = (response) => {
				if (response.payload.status===resources.status.SUCCESS) {
					if (response.payload.data.cashier_shifts.length>0) {
						setSchedules(response.payload.data.cashier_shifts.map((cashierShift) => ({
							user_id:cashierShift.user_id,
							shift_start:cashierShift.shift_start ? cashierShift.shift_start : "",
							shift_end:cashierShift.shift_end ? cashierShift.shift_end : ""
						})));
					}
					else {
						setSchedules(users.map((usr) => ({
							user_id:usr.id,
							shift_start:"",
							shift_end:"",
						})));
					}
				}
			}
			dispatch(fetchCashierShiftsAsync({day:day.value})).then((response) => handleResponse(response));
		}
	}, [dispatch, day, users]);

	const handleSkipButtonClickedEvent = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'cashier-shift'})).then((response) => response.payload.data && response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const handleFreezePaneButtonClickedEvent = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const refreshData = () => {
		dispatch(fetchCashierShiftsAsync({}))
		.then((response) => response.payload.data ? 
			setWorkSheet({
				is_readonly_mode: true,
				num_rows: 1000,
				num_cols: 10,
				num_frozen_columns: 0,
				import_limit: 700,
				focused_cell: null,
				selected_regions: null,
				header: translate.worksheet.default_header.MANAGE_CASHIER_SHIFTS,
				alignment: translate.worksheet.default_alignment.MANAGE_CASHIER_SHIFTS,
				comment: [],
				data_set: response.payload.data.cashier_shifts.filter((cs) => (cs.shift_start && cs.shift_end)).map((ds) => [
					ds.id,
					ds.day,
					ds.user_first_name,
					ds.user_last_name,
					Util.convertTimeTo12Hour(ds.shift_start),
					Util.convertTimeTo12Hour(ds.shift_end),
					ds.created_at,
					ds.updated_at,
				])
			}) : void(0)
		);
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						}
					]}
				/>

				{
					auth.active_company.application_id && role_user.includes(auth.roles.find((rl) => (rl.name===auth.enums.roles.SYSTEM_ADMIN)).id) && 
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleSkipButtonClickedEvent}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.MANAGE_CASHIER_SHIFTS) }

				{
					auth.worksheet.is_readonly_mode &&
					<>
						<ButtonGroup className="button-group" minimal={false}>
							<Button
								text={translate.button.SAVE}
								className="function-button"
								disabled={auth.status === resources.status.LOADING}
								icon={<Icon icon="floppy-disk" className="orange"/>}
								onClick={() => dispatch(saveCashierShiftAsync({day:day.value, schedules:schedules})).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : void(0)))}
							/>
							{
								worksheet.focused_cell &&
								<Button
									text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
									className="function-button"
									icon={<Icon icon="panel-table" className="orange"/>}
									disabled={auth.status === resources.status.LOADING}
									onClick={() => handleFreezePaneButtonClickedEvent()}
								/>
							}
						</ButtonGroup>
						<Card className="function-card">

							{
								users.length>0 ? 
								<>
									<div className="row mt-5 mb-5">
										<div className="col-sm-12 col-md-5 col-lg-2 text-field">
											<MuiAutocomplete
												setSelectedValue={setDay}
												selected_value={day}
												isMultiple={false}
												label={translate.placeholder.SELECT_DAY}
												data={days}
											/>
										</div>
									</div>

									<div className="mt-5 mb-5">
									{
										users.map((usr, key) => (
											<div className="row mb-4" key={key}>
												<TextField
													className="col-sm-12 col-md-3 col-lg-2 text-field"
													size="small"
													variant="outlined"
													inputProps={
														{ readOnly: true }
													}
													value={usr.name}
													label={translate.placeholder.CASHIER_NAME}
												/>
												<TextField
													className="col-sm-12 col-md-3 col-lg-2 text-field"
													label={translate.placeholder.SHIFT_START}
													type="time"
													variant="outlined"
													size="small"
													value={schedules.length>0 ? schedules.find((schedule) => (schedule.user_id===usr.id)).shift_start : ""}
													onChange={(e) => setSchedules(schedules.map((schedule) => (schedule.user_id===usr.id ? {
														user_id:schedule.user_id,
														shift_start:e.target.value,
														shift_end:schedule.shift_end
													} : schedule)))}
													InputLabelProps={{
														shrink: true,
													}}
												/>
												<TextField
													className="col-sm-12 col-md-3 col-lg-2 text-field"
													id="shift_end"
													label={translate.placeholder.SHIFT_END}
													type="time"
													variant="outlined"
													size="small"
													value={schedules.length>0 ? schedules.find((schedule) => (schedule.user_id===usr.id)).shift_end : ""}
													onChange={(e) => setSchedules(schedules.map((schedule) => (schedule.user_id===usr.id ? {
														user_id:schedule.user_id,
														shift_start:schedule.shift_start,
														shift_end:e.target.value
													} : schedule)))}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</div>
										))
									}
									</div>
								</> :
								<h4 className="red bold center">There are no cashier found.</h4>
							}

						</Card>

						<ButtonGroup className="button-group" minimal={false}>
							
							{
								auth.worksheet.is_readonly_mode &&
								<>
									<Button
										text={translate.button.SAVE_AS_PDF}
										className="function-button"
										icon={<Icon icon="document" className="orange"/>}
										disabled={auth.status === resources.status.LOADING}
										onClick={
											e => PDF.export(
												translate.text.MANAGE_CASHIER_SHIFTS,
												worksheet.header,
												worksheet.data_set,
												auth.active_company,
												"b3",
												"landscape",
												[],
												[40, 60, 60, 60, 80, "*", 60, "*", 60, "*", 60, 80, 60]
											)
										}
									/>
									<Button
										text={translate.button.SAVE_AS_XLSX}
										className="function-button"
										icon={<Icon icon="th" className="orange"/>}
										disabled={auth.status === resources.status.LOADING}
										onClick={
											e => Excel.export(
												translate.text.MANAGE_CASHIER_SHIFTS,
												worksheet.header,
												worksheet.data_set,
												auth.active_company
											)
										}
									/>
								</>
							}
						</ButtonGroup>

						<CashierShiftSpreadsheet
							enable_select={false}
							enable_edit={false}
							selected_rows={selected_rows}
							setSelectedRows={setSelectedRows}
							worksheet={worksheet}
							setWorkSheet={setWorkSheet}
						/>


					</>
				}

			</div>
		</div>
	);
}
