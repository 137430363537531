import React from "react";
import { MuiAutocomplete } from "./common/MuiAutocomplete";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resources } from "./resources/index";
import {
	selectAuth,
	createSubscriptionAsync,
	fetchAllCountriesAsync
} from "./features/auth/authSlice";
import {
	Icon,
	IconSize,
	InputGroup,
	MultistepDialog,
	DialogStep,
	TextArea,
	Intent,
	FormGroup,
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

export function CreateSubscriptionDialog({is_create_subscription_dialog_open, setIsCreateSubscriptionDialogOpen}) {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [country, setCountry] = useState(null);
	const [company_code, setCompanyCode] = useState("");
	const [company_name, setCompanyName] = useState("");
	const [company_address, setCompanyAddress] = useState("");
	const [countries, setCountries] = useState([]);
	const [industries] = useState(Object.values(resources.industries).map((industry) => ({label:industry, value:industry})));
	const [industry, setIndustry] = useState(null);

	useEffect(() => {
		const handleFetchAllCountriesResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setCountries(response.payload.data.map((country) => ({label:country.name, value:country.id})));
			}
		}
		dispatch(fetchAllCountriesAsync({})).then((response) => handleFetchAllCountriesResponse(response));
	}, [dispatch]);

	const finalButtonClickResponse = (response) => {
		if (response.payload.status===resources.status.SUCCESS) {
			setCountry(null);
			setCompanyCode("");
			setCompanyName("");
			setCompanyAddress("");
			setIsCreateSubscriptionDialogOpen(false);
		}
	}

	const finalButtonClickHandler = () => {
		dispatch(
			createSubscriptionAsync({
				country: country.value,
				company_code: company_code,
				company_name: company_name,
				company_address: company_address,
				industry: industry.value,
			})
		).then((response) => (finalButtonClickResponse(response)));
	};

	const finalButtonProps = {
		intent: Intent.PRIMARY,
		onClick: finalButtonClickHandler,
		text: translate.button.CREATE,
		disabled: company_code.length===0||company_name.length===0||country===null||company_address.length===0
	};

	return (

		<MultistepDialog
			isOpen={is_create_subscription_dialog_open}
			onClose={(e) => setIsCreateSubscriptionDialogOpen(false)}
			title={translate.text.CREATE_SUBSCRIPTION}
			finalButtonProps={finalButtonProps}
			nextButtonProps={{
				textAlign:"left",
				disabled: (company_code.length===0||company_name.length===0||country===null||company_address.length===0||industry===null)
			}}
			usePortal={true}
			style={{marginTop:52}}
		>
			<DialogStep
				panel={

					<div className="create-account-panel">
						<div className="create-account-container">
							<FormGroup
								className="input-form-group"
							>
								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-5 mt-5">
										<InputGroup
											className="mr-2"
											leftIcon={
												<Icon
													icon="label"
													size={IconSize.SMALL}
													className="orange"
												/>
											}
											placeholder={translate.placeholder.ENTER_COMPANY_CODE}
											large={true}
											round={true}
											value={company_code}
											onChange={(e) => setCompanyCode(e.target.value)}
											maxLength={100}
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-7 mt-5">
										<InputGroup
											className="ml-2"
											leftIcon={
												<Icon
													icon="label"
													size={IconSize.SMALL}
													className="orange"
												/>
											}
											placeholder={translate.placeholder.ENTER_COMPANY_NAME}
											large={true}
											round={true}
											value={company_name}
											onChange={(e) => setCompanyName(e.target.value)}
											maxLength={200}
										/>
									</div>
								</div>
							</FormGroup>
							<FormGroup
								className="input-form-group"
							>
								<div className="row mt-4">
									<div className="col-sm-12 col-md-6 col-lg-6">
										<MuiAutocomplete 
											className="mr-2"
											setSelectedValue={setIndustry}
											selected_value={industry}
											isMultiple={false}
											label={translate.placeholder.SELECT_INDUSTRY} 
											data={industries}
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<MuiAutocomplete 
											className="mr-2"
											setSelectedValue={setCountry}
											selected_value={country}
											isMultiple={false}
											label={translate.placeholder.SELECT_COUNTRY} 
											data={countries}
										/>
									</div>
								</div>
							</FormGroup>
							<FormGroup
								className="input-form-group"
							>
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-12">
										<TextArea
											className="ml-2"
											fill={true}
											growVertically={true}
											large={true}
											intent={Intent.PRIMARY}
											placeholder={
												translate.placeholder.ENTER_COMPANY_ADDRESS
											}
											value={company_address}
											onChange={(e) =>
												setCompanyAddress(e.target.value)
											}
											maxLength={200}
										/>
									</div>
								</div>
							</FormGroup>

						</div>
					</div>

				}
				title={translate.text.COMPANY_DETAILS}
			/>
		</MultistepDialog>



	);
}