import React, { useState } from "react";
import Util from "../../../common/Util";
import OmnibarSelect from "../../../common/OmnibarSelect";
import { useSelector } from "react-redux";
import {
	selectAuth,
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ControlGroup,
	HTMLTable,
	InputGroup,
	Tooltip,
	Position,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import { ItemDetailDialog } from "../../warehouse-inventory/item/ItemDetailDialog";

export function StockRequestLineItem({id, is_editable, requesting_unit, default_line_items, line_items, setLineItems, items}) {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const [is_item_detail_dialog_open, setIsItemDetailDialogOpen] = useState(false);
	const [item, setItem] = useState(null);
	const [is_omnibar_open, setIsOmnibarOpen] = useState(false);
	const [omnibar_data, setOmnibarData] = useState([]);
	const [omnibar_ds_type, setOmnibarDsType] = useState(null);

	const handleLineItemDeleteButtonClickedEvent = (row) => {
		setLineItems(line_items.filter((lineItem, key) => key!==row));
	};

	const handleDisplayItemDetailsButtonClickedEvent = (selectedItem) => {
		setItem(items.filter((item) => (item.id===selectedItem.value))[0]);
		setIsItemDetailDialogOpen(true);
	};

	const handleOmnibarItemSelectedEvent = (selectedItem) => {
		switch (selectedItem.ds_type.data_source) {
			case resources.omnibar_ds_types.ITEM : {
				setLineItems(line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					item:{label:`${selectedItem.item.code} | ${selectedItem.item.label}`, value:selectedItem.item.id}, 
					quantity:lineItem.quantity, 
					uom:lineItem.uom, 
				} : lineItem)));
				break;
			}
			case resources.omnibar_ds_types.UNIT_OF_MEASURE : {
				setLineItems(line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					item:lineItem.item, 
					quantity:lineItem.quantity, 
					unit_cost:lineItem.unit_cost, 
					amount:lineItem.amount, 
					uom:{label:selectedItem.item.code, value:selectedItem.item.id},
					location:lineItem.location,
					serial_numbers:lineItem.serial_numbers,
					expiry_dates:lineItem.expiry_dates
				} : lineItem)));
				break;
			}
			default: break;
		}
	}

	const handleItemInputClickedEvent = (row) => {
		let itms = [];
		if (requesting_unit!==null) {
			itms = items.filter((item) => (Util.stringToBoolean(item.is_enabled))).map((item,key) => ({id:item.id, code:item.code, label:`${item.code} | ${item.description}`}));
		}
		else {
			itms = items.filter((item) => Util.stringToBoolean(item.is_enabled)).map((item,key) => ({id:item.id, code:item.code, label:`${item.code} | ${item.description}`}));
		}
		setIsOmnibarOpen(true);
		setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.ITEM});
		setOmnibarData(itms);
	}

	const handleUomInputClickedEvent = (row) => {
		let unitOfMeasures = [];
		let selectedItem = line_items[row].item ? items.filter((item) => (item.id===line_items[row].item.value))[0] : null;
		if (selectedItem!==null) {
			unitOfMeasures = selectedItem.unit_of_measure_sets.map((uom) => ({id:uom.id, code:uom.code, label:uom.code}));
			setIsOmnibarOpen(true);
			setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.UNIT_OF_MEASURE});
			setOmnibarData(unitOfMeasures);
		}
	}

	const handleQuantityInputChangedEvent = (event, row) => {
		setLineItems(line_items.map((lineItem,key) => (key===row ? 
		{
			item:lineItem.item, 
			quantity:event.target.value, 
			uom:lineItem.uom,
		} : lineItem)));
	}

	return (
		<ControlGroup className="col-sm-12 col-md-8 col-lg-9 tbl-parent" vertical={true} style={{background: "grey", border:"solid lightgrey 1px", alignItems:"stretch", overflowX:"hidden", overflowY:"scroll", maxHeight:"50vh"}}>

			<ControlGroup className="tbl-group-sm" vertical={true}>
				<OmnibarSelect 
					is_omnibar_open={is_omnibar_open} 
					setIsOmnibarOpen={setIsOmnibarOpen} 
					omnibar_data={omnibar_data}
					omnibar_ds_type={omnibar_ds_type}
					handleOmnibarItemSelectedEvent={handleOmnibarItemSelectedEvent}
				/>

				<ItemDetailDialog
					item={item}
					is_item_detail_dialog_open={is_item_detail_dialog_open}
					setIsItemDetailDialogOpen={setIsItemDetailDialogOpen}
				/>

		        <HTMLTable 
		        	className="line-item-table line-item-table-header"
		        >
		            <tbody>
		            	<tr>
		            		<th className="line line-number"></th>
		            		<th className="input-line line-item">Item</th>
		            		<th className="input-line uom">UOM</th>
		            		<th className="input-line quantity">Quantity</th>
		            		{
								is_editable &&
								<th className="input-line delete-line-button"></th>
							}
		            	</tr>
		            </tbody>
		        </HTMLTable>
		        <HTMLTable 
		        	className="line-item-table"
					style={{
						background: "#F4F4F4",
						width:"100%"
					}}
		        >
		            <tbody>
		            	{
		            		line_items.map((lineItem, key) => 
		            			<tr key={key} style={{outline: "thin solid lightgrey"}}>
			                    	<td className="line line-number">
			                    		<ControlGroup>
			                        		<Button text={key+1} minimal={true}/>
			                        	</ControlGroup>
			                    	</td>
			                        <td className="input-line line-item">
			                        	<ControlGroup vertical={false}>
				                    		<Button disabled={!line_items[key].item} icon={<Tooltip disabled={line_items[key].item===null} intent={"primary"} content={line_items[key].item ? <span style={{fontSize:"9pt"}}>View Item Details</span> : "Select an Item to view details"} position={Position.RIGHT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} minimal={true} style={{paddingLeft:"15px",paddingRight:"15px"}} onClick={e => (line_items[key].item ? handleDisplayItemDetailsButtonClickedEvent(line_items[key].item) : null)}/>
				                        	<InputGroup fill={true} value={lineItem.item ? lineItem.item.label : ''} readOnly={true} onClick={e => is_editable ? handleItemInputClickedEvent(key) : void(0)}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line uom">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true}  value={lineItem.uom ? lineItem.uom.label : ''} readOnly={true} onClick={e => is_editable ? handleUomInputClickedEvent(key) : void(0)}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line quantity">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup type="number" fill={true} value={lineItem.quantity} style={{textAlign:"right"}} onChange={e => handleQuantityInputChangedEvent(e, key)} readOnly={!is_editable}/>
			                        	</ControlGroup>
			                        </td>
									{
										is_editable &&
										<td className="input-line delete-line-button">
											<Button icon={<Icon icon="cross" size={12}/>} intent="danger" minimal={true} onClick={e => handleLineItemDeleteButtonClickedEvent(key)} disabled={line_items.length<=1}/>
										</td>
									}
		            			</tr>
		            		)
		            	}

		            </tbody>
		        </HTMLTable>
	        </ControlGroup>
	        <ControlGroup className="tbl-group-sm" vertical={false}>
            	<Button 
            		className="function-button"
            		large={false} 
            		onClick={e => setLineItems([...line_items, default_line_items[0]])} 
            		icon={<Icon icon="add" className="orange"/>}
            		text={translate.button.ADD_MORE_ITEMS}
					disabled={!is_editable}
            	/>
				<Button 
					className="function-button"
					large={false} 
					onClick={e => setLineItems(default_line_items)} 
					icon={<Icon icon="remove" className="orange"/>}
					text={translate.button.REMOVE_ALL_ITEMS}
					disabled={!is_editable}
				/>
	        </ControlGroup>
		</ControlGroup>
	);
}