import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { useParams } from "react-router";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createStockIssuanceAsync,
	updateStockIssuanceAsync,
	submitStockIssuanceAsync,
	rejectStockIssuanceAsync,
	approveStockIssuanceAsync,
	voidStockIssuanceAsync,
	fetchAllStockIssuanceLineItemsAsync,
	toggleSidebarCollapsed,
	fetchAllProfitCostCentersAsync,
	fetchAllItemsAsync,
	fetchAllItemClassesAsync,
	fetchAllLocationsAsync,
	fetchItemAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	ControlGroup,
	H3,
	H5,
	FormGroup,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { DatatableDialog } from "../../../common/DatatableDialog";
import { StockIssuanceLineItem } from "./StockIssuanceLineItem";
import { SideBar } from '../../../SideBar';
import { Attachment } from "../../../common/Attachment";
import { Comment } from "../../../common/Comment";
import { TransactionLog } from "../../../common/TransactionLog";
import { useHistory } from "react-router-dom";
import { StockIssuanceSpreadsheet } from "../../../spreadsheets/StockIssuanceSpreadsheet";

export function StockIssuance() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const default_line_items = Array(7).fill({
		id:"",
		item:null,
		requested_quantity:"",
		balance_quantity:"",
		cancel_quantity:"",
		quantity:"",
		unit_cost:"",
		amount:"",
		uom:null,
		from_location:null,
		serial_numbers:[],
		expiry_dates:[],
		expiry_quantities:[],
		item_conditions:[],
	});
	const default_journal_entries = Array(2).fill({
		account_code:"",
		account_description:"",
		profit_cost_center:null,
		debit_amount:"",
		credit_amount:"",
	});
	const { document_id } = useParams();
	const [id, setId] = useState(document_id ? document_id : null);
	const [selected_document_number, setSelectedDocumentNumber] = useState('');
	const [selected_document_ids, setSelectedDocumentIds] = useState([]);
	const [stock_issuance, setStockIssuance] = useState(null);
	const [line_items, setLineItems] = useState(default_line_items);
	const [document_date, setDocumentDate] = useState(stock_issuance ? moment(stock_issuance.document_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT) : moment(new Date()).format(resources.date_format.INPUT));
	const [reference_number, setReferenceNumber] = useState("");
	const [transfer_to, setTransferTo] = useState(null);
	const [transfer_from, setTransferFrom] = useState(null);
	const [profit_cost_centers, setProfitCostCenters] = useState([]);
	const [items, setItems] = useState([]);
	const [item_classes, setItemClasses] = useState([]);
	const [locations, setLocations] = useState([]);
	const [sold_by, setSoldBy] = useState(null);
	const [transaction_description, setTransactionDescription] = useState("");
	const [ship_to, setShipTo] = useState("");
	const [transaction_logs, setTransactionLogs] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [transaction_source , setTransactionSource] = useState(null);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_delete_dialog_open, setIsConfirmDeleteDialogOpen] = useState(false);
	const [is_confirm_submit_dialog_open, setIsConfirmSubmitDialogOpen] = useState(false);
	const [is_confirm_approve_dialog_open, setIsConfirmApproveDialogOpen] = useState(false);
	const [is_confirm_reject_dialog_open, setIsConfirmRejectDialogOpen] = useState(false);
	const [is_confirm_void_dialog_open, setIsConfirmVoidDialogOpen] = useState(false);
	const [is_datatable_open, setIsDatatableDialogOpen] = useState(false);
	const [finished_good_item_options, setFinishedGoodItemOptions] = useState([]);
	const [finished_goods_to_produce, setFinishedGoodsToProduce] = useState(null);
	const [yield_quantity, setYieldQuantity] = useState("");
	const [yield_uom, setYieldUom] = useState("");
	const [number_of_batches, setNumberOfBatches] = useState("");
	const [standard_production, setStandardProduction] = useState("");
	const [journal_entries, setJournalEntries] = useState(default_journal_entries);
	const [balancing_account, setBalancingAccount] = useState("");
	const [transaction_sources] = useState(Object.values(auth.enums.transaction_sources.stock_issuance).map((transactionSource) => ({label:transactionSource, value:transactionSource})));
	const [is_editable, setIsEditable] = useState(true);
	const [is_authorizable, setIsAuthorizable] = useState(document_id ? auth.approval_pipelines.find((pipe) => pipe.stock_issuance_id===document_id)!==undefined : false);

	const [with_expiry_serialized, setHasExpirySerialized] = useState(false);
	const [with_expiry, setWithExpiry] = useState(false);
	const [serialized, setSerialized] = useState(false);

	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: translate.worksheet.default_header.STOCK_ISSUANCE,
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.STOCK_ISSUANCE));
	}, [
		dispatch,
		translate.breadcrumb.STOCK_ISSUANCE
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (worksheet.is_readonly_mode) {
			const handleFetchProfitCostCenterResponse = (response) => {
				if (response.payload.data) {
					let branches = response.payload.data.branches.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((branch) => ({label:`${branch.code} | ${branch.name}`, type:branch.type, value:branch.id}));
					let departments = response.payload.data.departments.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((department) => ({label:`${department.code} | ${department.name}`, type:department.type, value:department.id}));
					let projects = response.payload.data.projects.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((project) => ({label:`${project.code} | ${project.name}`, type:project.type, value:project.id}));
					setProfitCostCenters(branches.concat(departments).concat(projects));
				}
			}
			dispatch(fetchAllProfitCostCentersAsync({})).then((response) => handleFetchProfitCostCenterResponse(response));
			const handleFetchItemsResponse = (response) => {
				if (response.payload.data) {
					let itms = response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)));
					setItems(itms);
					let fgItems = itms.filter((itm) => Util.stringToBoolean(itm.with_components)).map((itm) => ({
						label:itm.label,
						value:itm.id
					}));
					setFinishedGoodItemOptions(fgItems);
				}
			}
			dispatch(fetchAllItemsAsync({})).then((response) => handleFetchItemsResponse(response));
			dispatch(fetchAllItemClassesAsync({})).then((response) => (response.payload.data ? setItemClasses(response.payload.data.item_classes.filter((ic) => Util.testDuplicateRegex(ic.name)).map((ic) => ({label:ic.name, value:ic.id}))) : void(0)));
			dispatch(fetchAllLocationsAsync({})).then((response) => response.payload.data ? setLocations(response.payload.data.locations) : void(0));
		}
	}, [
		dispatch,
		worksheet.is_readonly_mode
	]);

	const refreshData = () => {
		handleClearButtonClickedEvent();
	}

	const handleUpdateResponse = (response) => {
		let doc = response.payload.data.stock_issuance;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.stock_issuance.id ? [
				doc.id,
				doc.document_number,
				doc.document_date,
				doc.approval_status,
				doc.reference_number,
				doc.source,
				doc.finished_good_item ? doc.finished_good_item.description : "",
				doc.description,
				doc.transfer_from!==null ? JSON.parse(doc.transfer_from).label : null,
				doc.transfer_from!==null ? JSON.parse(doc.transfer_from).type : null,
				doc.transfer_to!==null ? JSON.parse(doc.transfer_to).label : null,
				doc.transfer_to!==null ? JSON.parse(doc.transfer_to).type : null,
			] : ds))
		});
		loadDocument(response.payload.data.stock_issuance, response.payload.data.stock_issuance_line_items, response.payload.data.journal_entries);
		setAttachments([]);
	}

	const handleCreateButtonClickedResponse = (response) => {
		let doc = response.payload.data.stock_issuance;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:[
				[
					doc.id,
					doc.document_number,
					doc.document_date,
					doc.approval_status,
					doc.reference_number,
					doc.source,
					doc.finished_good_item ? doc.finished_good_item.description : "",
					doc.description,
					doc.transfer_from!==null ? JSON.parse(doc.transfer_from).label : null,
					doc.transfer_from!==null ? JSON.parse(doc.transfer_from).type : null,
					doc.transfer_to!==null ? JSON.parse(doc.transfer_to).label : null,
					doc.transfer_to!==null ? JSON.parse(doc.transfer_to).type : null,
				],
				...worksheet.data_set
			]
		});
		loadDocument(response.payload.data.stock_issuance, response.payload.data.stock_issuance_line_items, response.payload.data.journal_entries);
		setAttachments([]);
	}

	const handleResetResponse = () => {
		setAttachments([]);
		refreshData();
	}

	const loadDocument = useCallback((stockIssuance, stockIssuanceLineItems, journalEntries) => {
		let finishedGoodItemObj = null;
		if (stockIssuance.finished_good_item) {
			finishedGoodItemObj = items.filter((item) => item.id===stockIssuance.finished_good_item.id)[0];
		}
		setStockIssuance(stockIssuance);
		setId(stockIssuance.id);
		setDocumentDate(moment(stockIssuance.document_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT));
		setReferenceNumber(stockIssuance.reference_number);
		setTransactionLogs(stockIssuance.transaction_logs);
		setTransactionDescription(stockIssuance.description);
		setIsEditable(stockIssuance.is_editable);
		setIsAuthorizable(stockIssuance.id ? auth.approval_pipelines.find((pipe) => pipe.stock_issuance_id===stockIssuance.id)!==undefined : false);
		setHasExpirySerialized(false);
		setWithExpiry(false);
		setSerialized(false);
		let stockTransfers = stockIssuance.stock_transfers;
		let documentIds = [];
		let documentNumbers = [];
		for (let i=0; i<stockTransfers.length; i++) {
			let stockTransfer = stockTransfers[i];
			documentIds.push(stockTransfer.id);
			documentNumbers.push(stockTransfer.document_number);
		}
		let transactionSource = transaction_sources.find((ts) => ts.value===stockIssuance.source);
		setTransactionSource(transactionSource);
		switch(transactionSource.value) {
			case auth.enums.transaction_sources.stock_issuance.STOCK_TRANSFER_REQUEST : {
				setBalancingAccount(auth.enums.balancing_accounts.stock_issuance.STOCK_TRANSFER_REQUEST);
				break;
			}
			case auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION : {
				setBalancingAccount(auth.enums.balancing_accounts.stock_issuance.ISSUANCE_TO_PRODUCTION);
				break;
			}
			case auth.enums.transaction_sources.stock_issuance.INTERNAL_DELIVERY_RECEIPT : {
				setBalancingAccount(auth.enums.balancing_accounts.stock_issuance.SALES);
				break;
			}
			case auth.enums.transaction_sources.stock_issuance.EXTERNAL_DELIVERY_RECEIPT : {
				setBalancingAccount(auth.enums.balancing_accounts.stock_issuance.SALES);
				break;
			}
			case auth.enums.transaction_sources.stock_issuance.DIRECT_ISSUANCE : {
				setBalancingAccount(auth.enums.balancing_accounts.stock_issuance.DIRECT_ISSUANCE);
				break;
			}
			default : break;
		}

		setJournalEntries(journalEntries.map((journalEntry) => ({
			account_code:journalEntry.account.code,
			account_description:journalEntry.account.description,
			profit_cost_center:stockIssuance.transfer_from ? JSON.parse(stockIssuance.transfer_from) : null,
			debit_amount:journalEntry.debit_amount,
			credit_amount:journalEntry.credit_amount,
		})));
		setSelectedDocumentIds(documentIds);
		setSelectedDocumentNumber(documentNumbers.join(", "));
		setSoldBy(stockIssuance.sold_by ? profit_cost_centers.find((pcc) => (pcc.value===JSON.parse(stockIssuance.sold_by).value && pcc.type===JSON.parse(stockIssuance.sold_by).type)) : null);
		setNumberOfBatches(stockIssuance.number_of_batches);
		setStandardProduction(stockIssuance.standard_production);
		setYieldUom(finishedGoodItemObj ? finishedGoodItemObj.yield_unit_of_measure.code : '');
		setYieldQuantity(finishedGoodItemObj ? finishedGoodItemObj.yield_quantity : '');
		setShipTo(stockIssuance.ship_to);
		setFinishedGoodsToProduce(stockIssuance.finished_good_item ? finished_good_item_options.find((fgopt) => fgopt.value===stockIssuance.finished_good_item.id) : null);
		if (stockIssuance.source===auth.enums.transaction_sources.stock_issuance.STOCK_TRANSFER_REQUEST) {
			setTransferFrom(stockIssuance.transfer_from ? JSON.parse(stockIssuance.transfer_from).label : "");
			setTransferTo(stockIssuance.transfer_to ? JSON.parse(stockIssuance.transfer_to).label : "");
		} else if (stockIssuance.source===auth.enums.transaction_sources.stock_issuance.DIRECT_ISSUANCE || stockIssuance.source===auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION) {
			setTransferFrom(stockIssuance.transfer_from ? profit_cost_centers.find((pcc) => (pcc.value===JSON.parse(stockIssuance.transfer_from).value && pcc.type===JSON.parse(stockIssuance.transfer_from).type)) : null);
			setTransferTo(stockIssuance.transfer_to ? profit_cost_centers.find((pcc) => (pcc.value===JSON.parse(stockIssuance.transfer_to).value && pcc.type===JSON.parse(stockIssuance.transfer_to).type)) : null);
		}
		else {
			setTransferFrom(stockIssuance.sold_by ? profit_cost_centers.find((pcc) => (pcc.value===JSON.parse(stockIssuance.sold_by).value && pcc.type===JSON.parse(stockIssuance.sold_by).type)) : null);
		}
		setLineItems( stockIssuanceLineItems.map((stockIssuanceLineItem) => ({
			id:stockIssuanceLineItem.id,
			item:{
				label:stockIssuanceLineItem.item.label, 
				default_uom_name:stockIssuanceLineItem.item.unit_of_measure.name, 
				item_costing:stockIssuanceLineItem.item.item_costing, 
				serial_number_type:stockIssuanceLineItem.item.serial_number_type, 
				value:stockIssuanceLineItem.item.id
			},
			requested_quantity:stockIssuanceLineItem.requested_quantity,
			balance_quantity:stockIssuanceLineItem.balance_quantity,
			cancel_quantity:stockIssuanceLineItem.cancel_quantity,
			quantity:stockIssuanceLineItem.quantity,
			unit_cost:stockIssuanceLineItem.unit_cost,
			amount:stockIssuanceLineItem.amount,
			uom:{label:stockIssuanceLineItem.unit_of_measure.code, value:stockIssuanceLineItem.unit_of_measure.id},
			from_location:{label:`${stockIssuanceLineItem.location.code} | ${stockIssuanceLineItem.location.name}`, value:stockIssuanceLineItem.location.id},
			serial_numbers:stockIssuanceLineItem.serial_numbers,
			expiry_dates:stockIssuanceLineItem.expiry_dates.map((expDate) => (expDate.length>0 ? moment(expDate, resources.date_format.SYSTEM).format(resources.date_format.INPUT) : "")),
			expiry_quantities:stockIssuanceLineItem.expiry_quantities.map((expQty) => (expQty.length>0 ? parseFloat(expQty) : 0)),
			item_conditions:stockIssuanceLineItem.item_conditions,
		})) );
		setHasExpirySerialized(false);
		setWithExpiry(false);
		setSerialized(false);
		for (let i=0; i<stockIssuanceLineItems.length; i++) {
			let stockIssuanceLineItem = stockIssuanceLineItems[i];
			if (stockIssuance.approval_status===auth.enums.approval_status.DRAFT) {
				let inventoryItem = items.find((itm) => itm.id===stockIssuanceLineItem.item.id);
				if (inventoryItem) {
					let hasExpiry = Util.stringToBoolean(inventoryItem.has_expiry);
					let withSerialNumber = Util.stringToBoolean(inventoryItem.with_serial_number);
					if (hasExpiry && withSerialNumber) {
						setHasExpirySerialized(true);
					} else 
					if (withSerialNumber) {
						setSerialized(true);
					} else 
					if (hasExpiry) {
						setWithExpiry(true);
					}
				}
			}
		}
	},[auth.enums.balancing_accounts.stock_issuance, profit_cost_centers, finished_good_item_options, auth.enums.transaction_sources.stock_issuance, transaction_sources, auth.approval_pipelines, auth.enums.approval_status.DRAFT, items]);

	useEffect(() => {
		if (id) {
			dispatch(fetchAllStockIssuanceLineItemsAsync({stock_issuance_id:id})).then((response) => loadDocument(response.payload.data.stock_issuance, response.payload.data.stock_issuance_line_items, response.payload.data.journal_entries));
		}
	}, [dispatch, id, loadDocument]);

	useEffect(() => {
		if (id===null && transaction_source) {
			switch(transaction_source.value) {
				case auth.enums.transaction_sources.stock_issuance.STOCK_TRANSFER_REQUEST : {
					setBalancingAccount(auth.enums.balancing_accounts.stock_issuance.STOCK_TRANSFER_REQUEST);
					break;
				}
				case auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION : {
					setBalancingAccount(auth.enums.balancing_accounts.stock_issuance.ISSUANCE_TO_PRODUCTION);
					break;
				}
				case auth.enums.transaction_sources.stock_issuance.INTERNAL_DELIVERY_RECEIPT : {
					setBalancingAccount(auth.enums.balancing_accounts.stock_issuance.SALES);
					break;
				}
				case auth.enums.transaction_sources.stock_issuance.EXTERNAL_DELIVERY_RECEIPT : {
					setBalancingAccount(auth.enums.balancing_accounts.stock_issuance.SALES);
					break;
				}
				case auth.enums.transaction_sources.stock_issuance.DIRECT_ISSUANCE : {
					setBalancingAccount(auth.enums.balancing_accounts.stock_issuance.DIRECT_ISSUANCE);
					break;
				}
				default : break;
			}
		}
	}, [id, transaction_source, auth.enums.transaction_sources, auth.enums.balancing_accounts]);

	const clearInputFields = () => {
		setId(null);
		setStockIssuance(null);
		setDocumentDate(moment(new Date()).format(resources.date_format.INPUT));
		setReferenceNumber("");
		setTransactionDescription("");
		setShipTo("");
		setSoldBy(null);
		setFinishedGoodsToProduce(null);
		setYieldQuantity("");
		setYieldUom("");
		setNumberOfBatches("");
		setStandardProduction("");
		setTransferFrom(null);
		setTransferTo(null);
		setSelectedDocumentNumber("");
		setSelectedDocumentIds([]);
		setLineItems(default_line_items);
		setBalancingAccount("");
		setJournalEntries(default_journal_entries);
		setAttachments([]);
		setIsEditable(true);
		setIsAuthorizable(false);
		setSerialized(false);
		setWithExpiry(false);
		setHasExpirySerialized(false);
		setSelectedRows([]);
	}

	const handleClearButtonClickedEvent = () => {
		clearInputFields();
		setTransactionSource(null);
		history.push('/warehouse-inventory/stock-issuance');
	};

	const handleDeleteRowsButtonClickedEvent = () => {
		let rows = selected_rows.map(
			(row) => worksheet.data_set[row]
		);
		let promises = [];
		for (let i = 0; i < rows.length; i++) {
			promises.push(
				dispatch(
					voidStockIssuanceAsync({
						id: rows[i][0]
					})
				)
			);
		}
		Promise.all(promises).then((response) => {
			var success = true;
			var message = null;
			for (let i = 0; i < response.length; i++) {
				if (response[i].payload !== undefined) {
					if (response[i].payload.status !== resources.status.SUCCESS) {
						success = false;
						break;
					}
					else {
						if (message == null) {
							message = response[i].payload.message;
						}
					}
				}
			}
			if (success === true) {
				toast.success(
					message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			handleClearButtonClickedEvent();
			refreshData();
		});
	};

	const handleSubmitButtonClickedEvent = () => {
		dispatch(
			submitStockIssuanceAsync({
				id:id,
				document_date:document_date,
				selected_document_ids:selected_document_ids,
				reference_number:reference_number,
				transaction_source:transaction_source,
				finished_goods_to_produce:finished_goods_to_produce,
				number_of_batches:number_of_batches,
				sold_by:sold_by,
				ship_to:ship_to,
				transfer_from:transfer_from,
				transfer_to:transfer_to,
				transaction_description:transaction_description,
				line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.cancel_quantity.length>0||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.from_location!==null)),
				attachments:attachments,
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleSubmitButtonClickedResponse(response) : null))
	};

	const handleSubmitButtonClickedResponse = (response) => {
		let doc = response.payload.data.stock_issuance;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.stock_issuance.id ? [
				doc.id,
				doc.document_number,
				doc.document_date,
				doc.approval_status,
				doc.reference_number,
				doc.source,
				doc.finished_good_item ? doc.finished_good_item.description : "",
				doc.description,
				doc.transfer_from!==null ? JSON.parse(doc.transfer_from).label : null,
				doc.transfer_from!==null ? JSON.parse(doc.transfer_from).type : null,
				doc.transfer_to!==null ? JSON.parse(doc.transfer_to).label : null,
				doc.transfer_to!==null ? JSON.parse(doc.transfer_to).type : null,
			] : ds))
		});
		setAttachments([]);
		refreshData();
	}

	const handleApproveButtonClickedEvent = () => {
		dispatch(
			approveStockIssuanceAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleResetResponse() : null))
	};

	const handleRejectButtonClickedEvent = () => {
		dispatch(
			rejectStockIssuanceAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleResetResponse() : null))
	};

	const handleVoidButtonClickedEvent = () => {
		dispatch(
			voidStockIssuanceAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleResetResponse() : null))
	};

	const handleEditButtonClickedEvent = async (row) => {
		let id = worksheet.data_set[row][0];
		setId(id);
		window.scrollTo(0,0);
	};

	const handleProcessSelectedDocuments = (selectionObject) => {
		Util.sortByKey(selectionObject.selectedRows, 'id');
		let ERROR = null;
		let requestingUnit = null;
		let issuingUnit = null;
		let selected_ids = [];
		let selected_document_numbers = [];
		let selected_descriptions = [];
		let selected_reference_numbers = [];
		let selected_line_item_indices = [];
		let selected_line_items = [];
		for (let i=0; i<selectionObject.selectedRows.length; i++) {
			if (!selected_ids.includes(selectionObject.selectedRows[i].id)) selected_ids.push(selectionObject.selectedRows[i].id);
			if (!selected_document_numbers.includes(selectionObject.selectedRows[i].document_number)) selected_document_numbers.push(selectionObject.selectedRows[i].document_number);
			if (!selected_descriptions.includes(selectionObject.selectedRows[i].transaction_description)) selected_descriptions.push(selectionObject.selectedRows[i].transaction_description);
			if (!selected_reference_numbers.includes(selectionObject.selectedRows[i].reference_number)) selected_reference_numbers.push(selectionObject.selectedRows[i].reference_number);
			if (requestingUnit!==null) {
				if (requestingUnit!==selectionObject.selectedRows[i].transfer_to) {
					ERROR = translate.message.CONSOLIDATION_MUST_BELONG_TO_SAME_UNIT;
					break;
				}
			}
			else {
				requestingUnit = selectionObject.selectedRows[i].transfer_to;
			}

			if (issuingUnit!==null) {
				if (issuingUnit!==selectionObject.selectedRows[i].transfer_from) {
					ERROR = translate.message.CONSOLIDATION_MUST_BELONG_TO_SAME_UNIT;
					break;
				}
			}
			else {
				issuingUnit = selectionObject.selectedRows[i].transfer_from;
			}
			let stockTransferLineItems = auth.stock_transfer_line_items.filter((stockTransferLineItem) => (stockTransferLineItem.stock_transfer_id===selectionObject.selectedRows[i].id));
			let lineItems = stockTransferLineItems.map((stockTransferLineItem) => ({
				id:null,
				item:{value:stockTransferLineItem.item.id, label:`${stockTransferLineItem.item.code} | ${stockTransferLineItem.item.description} | ${stockTransferLineItem.item.item_class.name}`},
				requested_quantity:stockTransferLineItem.quantity,
				balance_quantity:(stockTransferLineItem.quantity-stockTransferLineItem.fulfilled_quantity-stockTransferLineItem.cancelled_quantity).toFixed(4),
				cancel_quantity:0,
				quantity:0,
				unit_cost:auth.items.filter((item) => (item.id===stockTransferLineItem.item.id))[0].unit_cost,
				amount:0,
				uom:{value:stockTransferLineItem.unit_of_measure.id, label:stockTransferLineItem.unit_of_measure.code},
				from_location:null,
				serial_numbers:[],
				expiry_dates:[],
				expiry_quantities:[],
				item_conditions:[],
			}));
			for (let j=0; j<lineItems.length; j++) {
				if (selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`]===undefined) {
					selected_line_items.push(lineItems[j]);
					selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`] = selected_line_items.length-1;
				}
				else {
					let index = selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`];
					let selectedLineItem = selected_line_items[index];
					selectedLineItem.requested_quantity = (parseFloat(selectedLineItem.requested_quantity) + parseFloat(lineItems[j].requested_quantity)).toFixed(2);
					selectedLineItem.balance_quantity = (parseFloat(selectedLineItem.balance_quantity) + parseFloat(lineItems[j].balance_quantity)).toFixed(2);
					selected_line_items[index] = selectedLineItem;
				}
			}
		}
		setLineItems(selected_line_items);
		if (ERROR) {
			toast.error(
				ERROR,
				Util.getToasterStyle(resources.status.ERROR)
			);
		}
		else {
			setSelectedDocumentIds(selected_ids);
			setSelectedDocumentNumber(selected_document_numbers.join(", "));
			setReferenceNumber(selected_reference_numbers.join(", "));
			setTransactionDescription(selected_descriptions.join(", "));
			setTransferFrom(issuingUnit);
			setTransferTo(requestingUnit);
			setIsDatatableDialogOpen(false);
		}
	};

	const handleFinishedGoodsToProduceChangedEvent = (finishedGoodsToProduce) => {
		setFinishedGoodsToProduce(finishedGoodsToProduce);
		const handleResponse = (response) => {
			const itemObj = response.payload.data.item;
			let components = response.payload.data.finished_good_components;
			setYieldQuantity(itemObj.yield_quantity);
			setYieldUom(itemObj.yield_unit_of_measure.code);
			setNumberOfBatches("");
			setStandardProduction("");
			setLineItems(components.map((component) => ({
				id:null,
				item:{label:`${component.item.code} | ${component.item.description}`, value:component.item.id},
				requested_quantity:component.quantity,
				balance_quantity:"",
				cancel_quantity:"",
				quantity:component.quantity,
				unit_cost:component.item.unit_cost,
				amount:(parseFloat(component.quantity)*parseFloat(component.item.unit_cost)).toFixed(2),
				uom:{label:component.unit_of_measure.code, value:component.unit_of_measure.id},
				from_location:null,
				serial_numbers:[],
				expiry_dates:[],
				expiry_quantities:[],
				item_conditions:[],
			})));
		}
		dispatch(fetchItemAsync({id:finishedGoodsToProduce.value})).then((response) => handleResponse(response));
	}

	const handleNumberOfBatchesChangedEvent = (e) => {
		let numberOfBatches = e.target.value;
		if (!isNaN(numberOfBatches)) {
			setNumberOfBatches(numberOfBatches);
			setStandardProduction( (parseFloat(numberOfBatches)*parseFloat(yield_quantity)).toFixed(4) );
			let lineItems = line_items.map((lineItem) => ({
				id:lineItem.id,
				item:lineItem.item,
				requested_quantity:lineItem.requested_quantity,
				balance_quantity:lineItem.balance_quantity,
				cancel_quantity:lineItem.cancel_quantity,
				quantity:(parseFloat(numberOfBatches)*parseFloat(lineItem.requested_quantity)).toFixed(4),
				unit_cost:lineItem.unit_cost,
				amount:(parseFloat(numberOfBatches)*parseFloat(lineItem.requested_quantity)*parseFloat(lineItem.unit_cost)).toFixed(2),
				uom:lineItem.uom,
				from_location:lineItem.from_location,
				serial_numbers:lineItem.serial_numbers,
				expiry_dates:lineItem.expiry_dates,
				expiry_quantities:lineItem.expiry_quantities,
				item_conditions:lineItem.item_conditions,
			}));
			setLineItems(lineItems);
		}
	}

	const handleTransferFromChangedEvent = (transferFrom) => {
		setTransferFrom(transferFrom);
		setTransferTo(null);
		setLineItems(line_items.map((li) => ({
			id:li.id,
			item:li.item,
			requested_quantity:li.requested_quantity,
			balance_quantity:li.balance_quantity,
			cancel_quantity:li.cancel_quantity,
			quantity:li.quantity,
			unit_cost:li.unit_cost,
			amount:li.amount,
			uom:li.uom,
			from_location:null,
			serial_numbers:[],
			expiry_dates:[],
			expiry_quantities:[],
			item_conditions:[],
		})));
	}

	const handleSoldByChangedEvent = (soldBy) => {
		setTransferFrom(soldBy);
		setSoldBy(soldBy);
		setLineItems(line_items.map((li) => ({
			id:li.id,
			item:li.item,
			requested_quantity:li.requested_quantity,
			balance_quantity:li.balance_quantity,
			cancel_quantity:li.cancel_quantity,
			quantity:li.quantity,
			unit_cost:li.unit_cost,
			amount:li.amount,
			uom:li.uom,
			from_location:null,
			serial_numbers:[],
			expiry_dates:[],
			expiry_quantities:[],
			item_conditions:[],
		})));
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
						{
							label: translate.text.NOTE_INLINE_EDITING_FIELD_LABEL,
							value: translate.text.NOTE_INLINE_EDITING_FIELD_VALUE
						},
						{
							label: translate.text.NOTE_PASTING_DATA_LABEL,
							value: translate.text.NOTE_PASTING_DATA_VALUE
						},
					]}
				/>

				{ Util.functionTitle(translate.text.STOCK_ISSUANCE) }
				<div className="function-sub-title">
					{translate.text.STOCK_ISSUANCE_SUBTITLE}
				</div>

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						{
							id===null ?
							<>
								<Button
									text={translate.button.CREATE_DRAFT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING||!is_editable}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={(e) =>
										dispatch(
											createStockIssuanceAsync({
												document_date:document_date,
												selected_document_ids:selected_document_ids,
												reference_number:reference_number,
												transaction_source:transaction_source,
												finished_goods_to_produce:finished_goods_to_produce,
												number_of_batches:number_of_batches,
												sold_by:sold_by,
												ship_to:ship_to,
												transfer_from:transfer_from,
												transfer_to:transfer_to,
												transaction_description:transaction_description,
												line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.from_location!==null)),
												attachments:attachments,
											})
										).then((response) => (response.payload.status===resources.status.SUCCESS ? handleCreateButtonClickedResponse(response) : null))
									}
								/>
							</>
							:
							<>
								{
									is_editable &&
									<Button
										text={translate.button.UPDATE}
										className="function-button"
										disabled={auth.status === resources.status.LOADING||!is_editable}
										icon={<Icon icon="floppy-disk" className="orange"/>}
										onClick={(e) =>
											dispatch(
												updateStockIssuanceAsync({
													id:id,
													document_date:document_date,
													selected_document_ids:selected_document_ids,
													reference_number:reference_number,
													transaction_source:transaction_source,
													finished_goods_to_produce:finished_goods_to_produce,
													number_of_batches:number_of_batches,
													sold_by:sold_by,
													ship_to:ship_to,
													transfer_from:transfer_from,
													transfer_to:transfer_to,
													transaction_description:transaction_description,
													line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.cancel_quantity.length>0||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.from_location!==null)),
													attachments:attachments,
												})
											).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateResponse(response) : null))
										}
									/>
								}
								{
									is_editable &&
									<Button
										text={translate.button.SUBMIT}
										className="function-button"
										disabled={auth.status === resources.status.LOADING||!is_editable}
										icon={<Icon icon="send-message" className="orange"/>}
										onClick={e => setIsConfirmSubmitDialogOpen(!is_confirm_submit_dialog_open)}
									/>
								}
								<Button
									text={translate.button.PRINT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="print" className="orange"/>}
									onClick={() =>
										PDF.exportStockIssuanceForm(
											translate.worksheet.file_name.STOCK_ISSUANCE,
											[stock_issuance],
											line_items,
											auth.active_company
										)
									}
								/>
								{
									is_editable &&
									<Button
										text={translate.button.VOID}
										className="function-button"
										disabled={auth.status === resources.status.LOADING||!is_editable}
										icon={<Icon icon="ban-circle" className="orange"/>}
										onClick={e => setIsConfirmVoidDialogOpen(!is_confirm_void_dialog_open)}
									/>
								}
								{
									stock_issuance && stock_issuance.approval_status===auth.enums.approval_status.FOR_APPROVAL && is_authorizable &&
									<>
										<Button
											text={translate.button.APPROVE}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="tick" className="orange"/>}
											onClick={e => setIsConfirmApproveDialogOpen(!is_confirm_approve_dialog_open)}
										/>
										<Button
											text={translate.button.REJECT}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="cross" className="orange"/>}
											onClick={e => setIsConfirmRejectDialogOpen(!is_confirm_reject_dialog_open)}
										/>
									</>
								}
							</>
						}
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleClearButtonClickedEvent}
						/>
					</ButtonGroup>
				}

				{
					(with_expiry_serialized || serialized || with_expiry) &&
					<div style={{textAlign:"center", marginBottom:20, color:"blue"}}>
						{
							with_expiry_serialized &&
							<div>
								Your next step is to assign expiry date/s to corresponding serial number/s.
							</div>
						}
						{
							serialized &&
							<div>
								Your next step is to assign serial number/s to corresponding quantity.
							</div>
						}
						{
							with_expiry &&
							<div>
								Your next step is to assign expiry date to each quantity.
							</div> 
						}
					</div>
				}

				{
					worksheet.is_readonly_mode &&
					<div className="function-card">
						<div className="row">
							<Card
								className="col-sm-12 col-md-4 col-lg-3"
								style={{
									background: "lightblue",
								}}
							>
								<FormGroup>
									<H3 className="center" style={{color:"blue"}}>{stock_issuance ? stock_issuance.document_number : null}</H3>
									<H5 className="center" style={{color:"green"}}>{stock_issuance ? stock_issuance.approval_status : null}</H5>
								</FormGroup>

								<div className="row mb-5">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										id="document_date"
										label={translate.placeholder.PICK_A_DATE}
										type="date"
										variant="outlined"
										size="small"
										value={document_date}
										inputProps={{readOnly:!is_editable}}
										onChange={(e) => setDocumentDate(e.target.value)}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={reference_number}
										label={translate.placeholder.ENTER_TRANSACTION_REFERENCE_NUMBER}
										onChange={(e) =>
											setReferenceNumber(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
						        </div>

								<div className="row mt-5">
									<div className={"col-sm-12 col-md-12 col-lg-12"}>
										<MuiAutocomplete
											setSelectedValue={setTransactionSource}
											selected_value={transaction_source}
											isMultiple={false}
											label={translate.placeholder.SELECT_TRANSACTION_SOURCE}
											data={transaction_sources}
											isDisabled={id!==null||!is_editable}
										/>
									</div>
								</div>

								{
									transaction_source && transaction_source.value===auth.enums.transaction_sources.stock_issuance.STOCK_TRANSFER_REQUEST &&
									<div className={"col-sm-12 col-md-12 col-lg-12 mt-5"}>
										<ControlGroup vertical={false}>
											<TextField
												inputProps={{readOnly:!is_editable}}
												size="small"
												variant="outlined"
												value={selected_document_number}
												label={"Search a document"}
												style={{width:"85%"}}
											/>
											<Button
												minimal={true}
												large={true}
												text={translate.button.SEARCH}
												icon={<Icon icon="search" className="orange"/>}
												disabled={auth.status === resources.status.LOADING||id!==null}
												onClick={e => setIsDatatableDialogOpen(true)}
											/>
										</ControlGroup>
									</div>
								}

								{
									transaction_source && transaction_source.value===auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION &&
									<div className="row mt-5">
										<div className="col-sm-12 col-md-12 col-lg-12">
											<MuiAutocomplete
												setSelectedValue={handleFinishedGoodsToProduceChangedEvent}
												selected_value={finished_goods_to_produce}
												isMultiple={false}
												label={translate.placeholder.SELECT_FINISHED_GOODS_TO_PRODUCE}
												data={finished_good_item_options}
												isDisabled={!is_editable}
											/>
										</div>
									</div>
								}

								{
									transaction_source && transaction_source.value===auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION && finished_goods_to_produce &&
									<>
										<div className="row mb-5 mt-5">
											<TextField
												className="col-sm-12 col-md-6 col-lg-6"
												size="small"
												variant="outlined"
												label={translate.placeholder.YIELD_QUANTITY}
												value={yield_quantity}
												inputProps={{readOnly:true}}
											/>
											<TextField
												className="col-sm-12 col-md-6 col-lg-6"
												size="small"
												variant="outlined"
												label={translate.placeholder.YIELD_UOM}
												value={yield_uom}
												inputProps={{readOnly:true}}
											/>
										</div>

										<div className="row mb-5 mt-5">
											<TextField
												className="col-sm-12 col-md-6 col-lg-6"
												size="small"
												type="number"
												variant="outlined"
												label={translate.placeholder.NUMBER_OF_BATCHES}
												value={number_of_batches}
												onChange={(e) => handleNumberOfBatchesChangedEvent(e)}
												inputProps={{readOnly:!is_editable}}
											/>
											<TextField
												className="col-sm-12 col-md-6 col-lg-6"
												size="small"
												variant="outlined"
												label={translate.placeholder.STANDARD_PRODUCTION}
												value={standard_production}
												inputProps={{readOnly:true}}
											/>
										</div>

									</>

								}

								{
									transaction_source && transaction_source.value===auth.enums.transaction_sources.stock_issuance.STOCK_TRANSFER_REQUEST &&
									<div className="row mb-5">
										<TextField
											className="col-sm-12 col-md-6 col-lg-6 mt-5"
											size="small"
											variant="outlined"
											label={translate.placeholder.SELECT_TRANSFER_FROM}
											value={transfer_from}
											inputProps={{readOnly:true}}
										/>
										<TextField
											className="col-sm-12 col-md-6 col-lg-6 mt-5"
											size="small"
											variant="outlined"
											label={translate.placeholder.SELECT_TRANSFER_TO}
											value={transfer_to}
											inputProps={{readOnly:true}}
										/>
									</div>
								}

								{
									transaction_source && (transaction_source.value===auth.enums.transaction_sources.stock_issuance.DIRECT_ISSUANCE||transaction_source.value===auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION) &&
									<div className="row mb-5">
										<div className="col-sm-12 col-md-6 col-lg-6 mt-5">
											<MuiAutocomplete
												setSelectedValue={handleTransferFromChangedEvent}
												selected_value={transfer_from ? transfer_from : null}
												isMultiple={false}
												label={`${translate.placeholder.SELECT_TRANSFER_FROM} ${transfer_from ? transfer_from.type : ''}`}
												data={profit_cost_centers}
												isDisabled={!is_editable}
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-6 mt-5">
											<MuiAutocomplete
												setSelectedValue={setTransferTo}
												selected_value={transfer_to ? transfer_to : null}
												isMultiple={false}
												isDisabled={transfer_from===null||!is_editable}
												label={`${translate.placeholder.SELECT_TRANSFER_TO} ${transfer_to ? transfer_to.type : ''}`}
												data={profit_cost_centers}
											/>
										</div>
									</div>

								}

								{
									transaction_source && (transaction_source.value===auth.enums.transaction_sources.stock_issuance.INTERNAL_DELIVERY_RECEIPT||transaction_source.value===auth.enums.transaction_sources.stock_issuance.EXTERNAL_DELIVERY_RECEIPT) &&
									<>
										<div className="row mb-5 mt-5">
											<div className="col-sm-12 col-md-12 col-lg-12">
												<MuiAutocomplete
													setSelectedValue={handleSoldByChangedEvent}
													selected_value={sold_by}
													isMultiple={false}
													label={"Sold by (Branch, Department or Project)"}
													data={profit_cost_centers}
													isDisabled={!is_editable}
												/>
											</div>
										</div>
										<div className="row mb-5 mt-5">
											<TextField
												className="col-sm-12 col-md-12 col-lg-12"
												multiline
												minRows={4}
												size="small"
												variant="outlined"
												label={'Ship to'}
												value={ship_to}
												onChange={(e) =>
													setShipTo(
														e.target.value
													)
												}
												inputProps={{readOnly:!is_editable}}
											/>
										</div>
									</>
								}

								<div className="row mb-5 mt-5">
									<TextField
										className="col-sm-12 col-md-12 col-lg-12"
										multiline
										minRows={4}
										size="small"
										variant="outlined"
										label={translate.placeholder.ENTER_TRANSACTION_DESCRIPTION}
										value={transaction_description}
										onChange={(e) =>
											setTransactionDescription(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<Attachment
									id={id}
									transaction={stock_issuance}
									setTransaction={setStockIssuance}
									attachments={attachments}
									setAttachments={setAttachments}
								/>

								{
									id!==null && stock_issuance &&
									<>
										<Comment
											id={id}
											document_type={stock_issuance.document_type.name}
											comments={stock_issuance.comments}
											created_by={stock_issuance.created_by}
											setDocument={setStockIssuance}
											is_editable={is_editable}
											is_authorizable={is_authorizable}
										/>
										<TransactionLog
											transaction_logs={transaction_logs}
										/>
				                    </>
								}

							</Card>
							{
								<StockIssuanceLineItem
									id={id}
									document_date={document_date}
									is_editable={is_editable}
									transfer_from={transfer_from}
									transaction_source={transaction_source}
									default_line_items={default_line_items}
									line_items={line_items}
									setLineItems={setLineItems}
									default_journal_entries={default_journal_entries}
									journal_entries={journal_entries}
									setJournalEntries={setJournalEntries}
									balancing_account={balancing_account}
									setBalancingAccount={setBalancingAccount}
									items={items}
									item_classes={item_classes}
									locations={locations}
								/>
							}
						</div>
					</div>
				}

				{
					!worksheet.is_readonly_mode &&
					<div className="row">
						<div className={"col-sm-12 col-md-3 col-lg-3"}>
							<MuiAutocomplete
								setSelectedValue={setTransactionSource}
								selected_value={transaction_source}
								isMultiple={false}
								label={translate.placeholder.SELECT_TRANSACTION_SOURCE}
								data={transaction_sources.filter((ts) => (ts.value!==auth.enums.transaction_sources.stock_issuance.STOCK_TRANSFER_REQUEST && ts.value!==auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION))}
							/>
						</div>
					</div>
				}

				<Card className="function-card-spreadsheet">
					<StockIssuanceSpreadsheet
						enable_select={true}
						enable_edit={true}
						selected_rows={selected_rows}
						setSelectedRows={setSelectedRows}
						handleEditButtonClickedEvent={handleEditButtonClickedEvent}
						handleClearButtonClickedEvent={handleClearButtonClickedEvent}
						worksheet={worksheet}
						setWorkSheet={setWorkSheet}
						refreshData={refreshData}
						is_confirm_delete_dialog_open={is_confirm_delete_dialog_open}
						setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
						profit_cost_centers={profit_cost_centers}
						DEFAULT_DATA_SET={DEFAULT_DATA_SET}
					/>
				</Card>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_delete_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDeleteDialogOpen}
					confirmAction={handleDeleteRowsButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted/voided, document will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain document is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_submit_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmSubmitDialogOpen}
					confirmAction={handleSubmitButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">SUBMIT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_approve_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmApproveDialogOpen}
					confirmAction={handleApproveButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">APPROVE</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_reject_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmRejectDialogOpen}
					confirmAction={handleRejectButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">REJECT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_void_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmVoidDialogOpen}
					confirmAction={handleVoidButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">VOID</span> this document ?
						</span>
					}
				/>

				<DatatableDialog
					is_datatable_open={is_datatable_open}
					setIsDatatableDialogOpen={setIsDatatableDialogOpen}
					transaction_source={transaction_source}
					handleProcessSelectedDocuments={handleProcessSelectedDocuments}
				/>

			</div>
		</div>
	);
}
