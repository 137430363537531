import React from 'react';
import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, updateNavigation } from '../auth/authSlice';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { resources } from "../../resources/index";
import { Callout, Card, Elevation } from "@blueprintjs/core";
import { SideBar } from '../../SideBar';
import { OnHandFinishedGoodsBySourceChartV2 } from './OnHandFinishedGoodsBySourceChartV2';
import { OnHandInventoriesChart } from './OnHandInventoriesChart';
import { FastMovingInventoriesChart } from './FastMovingInventoriesChart';
import { NearStockoutSkusChart } from './NearStockoutSkusChart';
import { ZeroStockInventoriesChart } from './ZeroStockInventoriesChart';
import { OnHandAndSoldFinishedGoodsV2 } from './OnHandAndSoldFinishedGoodsV2';
import { InventoryValueByItemClassChart } from './InventoryValueByItemClassChart';
import { ItemConditionChart } from './ItemConditionChart';
import 'chart.js/auto';

export function SystemSettings() {

	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.SYSTEM_SETTINGS));
	}, [dispatch, translate.breadcrumb.SYSTEM_SETTINGS]);

	useEffect(() => {
		initFetch()
	}, [initFetch]);

	return (
		!(auth.active_company.inventory===0 && auth.active_company.pos===0) ?
		<>
			<SideBar/>
			<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
				
				<div style={{width:"100%"}}>
					<div className="row">
						<div className="col-sm-12 col-md-3 col-lg-3">
							<OnHandInventoriesChart/>
						</div>
						<div className="col-sm-12 col-md-3 col-lg-3">
							<FastMovingInventoriesChart/>
						</div>
						<div className="col-sm-12 col-md-3 col-lg-3">
							<NearStockoutSkusChart/>
						</div>
						<div className="col-sm-12 col-md-3 col-lg-3">
							<ZeroStockInventoriesChart/>
						</div>
					</div>
					<div className="row" >
						<div className="col-sm-12 col-md-6 col-lg-6 mt-4">
							<Card style={{height:"100%", margin:"2px", minHeight:"35vh"}} interactive={false} elevation={Elevation.TWO}>
								<ItemConditionChart/>
							</Card>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-6 mt-4">
							<Card style={{height:"100%", margin:"2px", minHeight:"35vh"}} interactive={false} elevation={Elevation.TWO}>					
								<InventoryValueByItemClassChart/>
							</Card>
						</div>
					</div>
					<div className="row mb-5" >
						<div className="col-sm-12 col-md-6 col-lg-6 mt-4">
							<Card style={{height:"100%", margin:"2px", minHeight:"35vh"}} interactive={false} elevation={Elevation.TWO}>
								<OnHandFinishedGoodsBySourceChartV2/>
							</Card>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-6 mt-4">
							<Card style={{height:"100%", margin:"2px", minHeight:"35vh"}} interactive={false} elevation={Elevation.TWO}>	
								<OnHandAndSoldFinishedGoodsV2/>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</> : 
		<div className='row mt-5'>
			<div className='col-lg-9 col-md-9 col-sm-12'>
				<Callout intent='warning'>
					<h2>Payment is Pending...</h2>
					<p style={{fontSize:18, marginTop:50}}>
						Please contact <span className='bold'>dhel.narvaez@finximus.com</span> for the access authorization. Afterwards, refresh the page and re-login.
					</p>
				</Callout>
			</div>
		</div>
	);

}
