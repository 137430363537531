import React, { useEffect, useState } from "react";
import { Dialog, Icon } from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import DataTable from 'react-data-table-component';
import { resources } from "../../../resources/index";
import { useDispatch, useSelector } from "react-redux";
import {
    selectAuth,
    fetchItemLedgerAsync,
    fetchAllItemsAsync,
    fetchAllLocationsAsync
} from "../../auth/authSlice";
import moment from "moment";
import Util from "../../../common/Util";

export function ItemLedger({selected_entry, is_datatable_open, setIsDatatableDialogOpen, from_date, to_date}) {

    const auth = useSelector(selectAuth);
    const dispatch = useDispatch();
    const translate = resources["lang"][auth.locale];
    const [columns, setColumns] = useState([]);
    const [items, setItems] = useState([]);
    const [locations, setLocations] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
      dispatch(fetchAllItemsAsync({})).then((response) => response.payload.data ? setItems(response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)))) : void(0));
      dispatch(fetchAllLocationsAsync({})).then((response) => response.payload.data ? setLocations(response.payload.data.locations) : void(0));
    }, [
      dispatch,
    ]);

    useEffect(() => {
      if (selected_entry) {
        setColumns(translate.worksheet.default_header['ITEM_LEDGER'].map((header, key) => ({
                name:header.toUpperCase(),
                selector: row => row[header.replaceAll(' ','_').replaceAll('/','_')],
                sortable: true,
        })));
        let item = items.filter((itm) => (itm.code===selected_entry[0] && itm.description===selected_entry[1]))[0];
        let location = locations.find((loc) => loc.pcc_name===selected_entry[3] && loc.name===selected_entry[4]);
        if (item && location) {
          dispatch(fetchItemLedgerAsync({
            from_date:from_date ? moment(from_date).format(resources.date_format.SYSTEM) : "",
            to_date:to_date ? moment(to_date).format(resources.date_format.SYSTEM) : "",
            item:item.id,
            location:selected_entry[4],
            location_id:location.id,
          })).then((response) => response.payload.data ? setData(response.payload.data.item_ledger.map((il) => ({
            item_code: il.item_code,
            item_description: il.item_description,
            item_class: il.item_class,
            document_date: il.document_date,
            document_number: il.document_number,
            description: il.description,
            profit_cost_center: il.profit_cost_center,
            location: il.location,
            uom: il.uom,
            quantity: il.quantity,
            stock_quantity: il.stock_quantity,
            unit_cost: il.unit_cost,
            amount: il.amount,
          }))) : void(0));
        }
      }
    }, [
      dispatch,
      selected_entry,
      items,
      translate.worksheet.default_header,
      locations,
      from_date,
      to_date
    ]);

    return (
        <Dialog
            className="col-sm-12 col-md-6 col-lg-7"
            title={`Item Ledger`}
            icon={<Icon icon="info-sign" className="orange"/>}
            isOpen={is_datatable_open}
            onClose={e => setIsDatatableDialogOpen(false)}
            isCloseButtonShown={true}
        >
            <div>
                <DataTable
                    columns={columns}
                    data={data}
                    theme="light"
                    pagination
                    dense
                />
            </div>
        </Dialog>
    );
}
