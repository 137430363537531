import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	fetchAllItemClassesAsync,
	toggleSidebarCollapsed
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import { SideBar } from '../../../SideBar';
import { ItemConditionsSpreadsheet } from "../../../spreadsheets/ItemConditionsSpreadsheet";

export function ItemConditions() {
	const auth = useSelector(selectAuth);
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const [item_class_params] = useState(params.get("item_class"));
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [item_classes, setItemClasses] = useState([]);
	const [item_class, setItemClass] = useState(null);
	const [item_condition, setItemCondition] = useState(null);
	const [item_conditions] = useState(Object.values(auth.enums.item_conditions).map((condition) => ({label:condition, value:condition})));
	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const handleFreezePaneButtonClickedEvent = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.ITEM_CONDITIONS));
		dispatch(fetchAllItemClassesAsync({})).then((response) => response.payload.data ? setItemClasses(response.payload.data.item_classes.filter((ic) => Util.testDuplicateRegex(ic.name)).map((itemClass) => ({label:itemClass.name , value:itemClass.id}))) : void(0));
	}, [
		dispatch,
		translate.breadcrumb.ITEM_CONDITIONS
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);
	
	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container center" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>
				{ Util.functionTitle(translate.text.ITEM_CONDITIONS) }
				<div className="function-sub-title">
				</div>

				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={translate.button.SAVE_AS_PDF}
						className="function-button"
						icon={<Icon icon="document" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={
							e => PDF.export(
								translate.worksheet.file_name.ITEM_CONDITIONS,
								auth.worksheet.header,
								auth.worksheet.data_set,
								auth.active_company,
								"legal",
								"landscape",
								[4,5,6],
								[60, "*", 80, 40, 70, 70, 70, 80, 70, 40]
							)
						}
					/>
					<Button
						text={translate.button.SAVE_AS_XLSX}
						className="function-button"
						icon={<Icon icon="th" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={
							e => Excel.export(
								translate.worksheet.file_name.ITEM_CONDITIONS,
								auth.worksheet.header,
								auth.worksheet.data_set,
								auth.active_company
							)
						}
					/>
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={() => handleFreezePaneButtonClickedEvent()}
						/>
					}
				</ButtonGroup>

				<Card className="function-card">
					<div className="row mb-5">

				        <div className="col-sm-12 col-md-3 col-lg-3 mt-5">
							<MuiAutocomplete
								setSelectedValue={setItemClass}
								selected_value={item_class}
								isMultiple={false}
								label={"Filter by Item Class"}
								data={item_classes}
							/>
						</div>
				        <div className="col-sm-12 col-md-3 col-lg-2 mt-5">
							<MuiAutocomplete
								setSelectedValue={setItemCondition}
								selected_value={item_condition}
								isMultiple={false}
								label={"Filter by Item Condition"}
								data={item_conditions}
							/>
						</div>

					</div>
				</Card>

				<ItemConditionsSpreadsheet
					enable_select={false}
					enable_open={false}
					enable_edit={false}
					item_class={item_class}
					item_condition={item_condition}
					item_class_params={item_class_params}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
				/>

			</div>
		</div>
	);
}
