import React, { useCallback, useEffect, useState } from "react";
import PDF from "./common/PDF";
import Excel from "./common/Excel";
import Util from "./common/Util";
import { Note } from "./common/Note";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	toggleSidebarCollapsed,
	fetchOrderAsync,
	updateOrderAsync,
	sendMessageAsync,
	fetchCustomerProfitCostCentersAsync,
	payOrderingAndAppointmentAsync
} from "./features/auth/authSlice";
import { resources } from "./resources/index";
import {
	Button,
	ButtonGroup,
	Icon,
	Card,
	FormGroup,
	H3,
	ControlGroup,
	HTMLTable,
	InputGroup,
	Tag,
	Dialog, 
	Classes,
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import { SideBar } from './SideBar';
import { MuiAutocomplete } from "./common/MuiAutocomplete";
import TextField from "@material-ui/core/TextField";
import { OrderSpreadsheet } from "./spreadsheets/OrderSpreadsheet";
import {
    regions,
    provinces,
    cities,
    barangays,
} from "select-philippines-address";
import moment from "moment";
import { Rating } from "@material-ui/lab";

export function Order() {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const { status } = useParams();
	const [selected_rows, setSelectedRows] = useState([]);
	const [title] = useState("Orders and Appointments");
	const [approval_status, setApprovalStatus] = useState(null);
	const [approval_statuses] = useState([{label:"", value:null}, ...Object.values(auth.enums.oaa_order_status).map((as) => ({label:as, value:as}))]);
	const [order_types] = useState([{label:"", value:null}, ...Object.values(auth.enums.oaa_order_types).map((orderType) => ({label:orderType, value:orderType}))]);
	const [payment_methods] = useState(Object.values(auth.enums.payment_method).map((pm) => ({label:pm, value:pm})));
	const [order_type, setOrderType] = useState(null);
	const [from_date, setFromDate] = useState("");
	const [to_date, setToDate] = useState("");
	const [from_delivery_date, setFromDeliveryDate] = useState("");
	const [to_delivery_date, setToDeliveryDate] = useState("");
	const [region, setRegion] = useState(null);
	const [region_data, setRegionData] = useState([]);
	const [province, setProvince] = useState(null);
	const [province_data, setProvinceData] = useState([]);
	const [city, setCity] = useState(null);
	const [city_data, setCityData] = useState([]);
	const [barangay, setBarangay] = useState(null);
	const [barangay_data, setBarangayData] = useState([]);
	const [is_editable, setIsEditable] = useState(true);
	const [id, setId] = useState(null);
	const [document_type, setDocumentType] = useState("");
	const [document_number, setDocumentNumber] = useState("");
	const [document_status, setDocumentStatus] = useState("");
	const [status_message, setStatusMessage] = useState("");
	const [ profit_cost_centers, setProfitCostCenters ] = useState([]);
	const [ profit_cost_center, setProfitCostCenter ] = useState(null);
	const [customer, setCustomer] = useState("");
	const [pickup_address_id, setPickupAddressId] = useState(null);
	const [pickup_address, setPickupAddress] = useState(null);
	const [pickup_date, setPickupDate] = useState("");
	const [delivery_address_id, setDeliveryAddressId] = useState(null);
	const [delivery_address, setDeliveryAddress] = useState(null);
	const [delivery_date, setDeliveryDate] = useState("");
	const [appointment_address_id, setAppointmentAddressId] = useState(null);
	const [appointment_address, setAppointmentAddress] = useState(null);
	const [appointment_date, setAppointmentDate] = useState("");
	const [line_items, setLineItems] = useState(Array(0).fill(""));
	const [message, setMessage] = useState("");
	const [comments, setComments] = useState([]);
	const [rating, setRating] = useState(null);
	const [transaction_logs, setTransactionLogs] = useState([]);
	const [amount_due, setAmountDue] = useState("");
	const [payment_method, setPaymentMethod] = useState(null);
	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 4,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});
	const [is_payment_dialog_open, setIsPaymentDialogOpen] = useState(false);
	const [payment_status, setPaymentStatus] = useState(auth.enums.payment_status.UNPAID);

	const loadOrder = useCallback((response) => {
		if (response.payload.status===resources.status.SUCCESS) {
			setDocumentNumber(`Order #${response.payload.data.order.id}`);
			setDocumentStatus(approval_statuses.find((as) => (as.value===response.payload.data.order.status)));
			setProfitCostCenter(response.payload.data.order.profit_cost_center ? profit_cost_centers.find((pcc) => pcc.value===JSON.parse(response.payload.data.order.profit_cost_center).id) : null);
			setCustomer(`${response.payload.data.order.customer_first_name} ${response.payload.data.order.customer_last_name}`);
			if (response.payload.data.order.pickup_address_id) {
				setPickupAddressId(response.payload.data.order.pickup_address_id);
				setPickupAddress(`${response.payload.data.order.pickup_address_building} ${response.payload.data.order.pickup_address_subdivision} ${response.payload.data.order.pickup_address_region} ${response.payload.data.order.pickup_address_province} ${response.payload.data.order.pickup_address_city} ${response.payload.data.order.pickup_address_barangay}`);
				setPickupDate(moment(response.payload.data.order.pickup_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT));
			}
			else {
				setPickupAddressId(null);
				setPickupAddress("");
				setPickupDate("");
			}
			if (response.payload.data.order.delivery_address_id) {
				setDeliveryAddressId(response.payload.data.order.delivery_address_id);
				setDeliveryAddress(`${response.payload.data.order.delivery_address_building} ${response.payload.data.order.delivery_address_subdivision} ${response.payload.data.order.delivery_address_region} ${response.payload.data.order.delivery_address_province} ${response.payload.data.order.delivery_address_city} ${response.payload.data.order.delivery_address_barangay}`);
				setDeliveryDate(moment(response.payload.data.order.delivery_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT));
			}
			else {
				setDeliveryAddressId(null);
				setDeliveryAddress("");
				setDeliveryDate("");
			}
			if (response.payload.data.order.appointment_address_id) {
				setAppointmentAddressId(response.payload.data.order.appointment_address_id);
				setAppointmentAddress(`${response.payload.data.order.appointment_address_building} ${response.payload.data.order.appointment_address_subdivision} ${response.payload.data.order.appointment_address_region} ${response.payload.data.order.appointment_address_province} ${response.payload.data.order.appointment_address_city} ${response.payload.data.order.appointment_address_barangay}`);
				setAppointmentDate(moment(response.payload.data.order.appointment_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT));
			}
			else {
				setAppointmentAddressId(null);
				setAppointmentAddress("");
				setAppointmentDate("");
			}
			try {
				let lineItems = JSON.parse(response.payload.data.order.line_items);
				setLineItems(lineItems.map((li) => li));
			}
			catch (e) {}
			setDocumentType(response.payload.data.order.type);
			setComments(response.payload.data.order.comments);
			setTransactionLogs(response.payload.data.order.transaction_logs);
			setStatusMessage(response.payload.data.order.status_message);
			setRating(response.payload.data.order.rating);
			if (response.payload.data.order.status===auth.enums.oaa_order_status.ORDER_APPOINTMENT_CANCELLED||response.payload.data.order.status===auth.enums.oaa_order_status.ORDER_APPOINTMENT_COMPLETED) {
				setIsEditable(false);
			}
			else {
				setIsEditable(true);
			}
			if (response.payload.data.order.payments) {
				let payments = response.payload.data.order.payments;
				if (payments.length>0) {
					let paymentMethod = payment_methods.find((pm) => (pm.value===payments[0].payment_method));
					setAmountDue(payments[0].amount_due);
					setPaymentMethod(paymentMethod ? paymentMethod : null);
					setPaymentStatus(auth.enums.payment_status.PAID);
				}
			}
			window.scrollTo(0,0);
		}
	},[approval_statuses, auth.enums.oaa_order_status, profit_cost_centers, auth.enums.payment_status.PAID, payment_methods]);

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.WAREHOUSE_INVENTORY));
		if (region_data.length===0) {
			regions().then((reg) => setRegionData(reg.map((rg) => ({value:rg.id, code:rg.region_code, label:rg.region_name}))));
		}
		if (region!==null) {
			provinces(region.code).then((prov) => setProvinceData(prov.map((prv) => ({value:prv.id, code:prv.province_code, label:prv.province_name}))));
		} else {
			provinces().then((prov) => setProvinceData(prov.map((prv) => ({value:prv.id, code:prv.province_code, label:prv.province_name}))));
		}
		if (province!==null) {
			cities(province.code).then((cit) => setCityData(cit.map((ct) => ({value:ct.id, code:ct.city_code, label:ct.city_name}))));
		}
		else {
			cities().then((cit) => setCityData(cit.map((ct) => ({value:ct.id, code:ct.city_code, label:ct.city_name}))));
		}
		if (city!==null) {
			barangays(city.code).then((brgy) => setBarangayData(brgy.map((br) => ({value:br.id, code:br.brgy_code, label:br.brgy_name}))));
		}
		else {
			barangays().then((brgy) => setBarangayData(brgy.map((br) => ({value:br.id, code:br.brgy_code, label:br.brgy_name}))));
		}
		if (status) {
			if (status==="to-confirm-schedule") {
				setApprovalStatus(approval_statuses.find((as) => (as.value===auth.enums.oaa_order_status.TO_CONFIRM_SCHEDULE)));
			} else
			if (status==="schedule-confirmed") {
				setApprovalStatus(approval_statuses.find((as) => (as.value===auth.enums.oaa_order_status.SCHEDULE_CONFIRMED)));
			} else
			if (status==="completed") {
				setApprovalStatus(approval_statuses.find((as) => (as.value===auth.enums.oaa_order_status.ORDER_APPOINTMENT_COMPLETED)));
			} else
			if (status==="reschedule") {
				setApprovalStatus(approval_statuses.find((as) => (as.value===auth.enums.oaa_order_status.ORDER_APPOINTMENT_RESCHEDULED)));
			} else
			if (status==="cancelled") {
				setApprovalStatus(approval_statuses.find((as) => (as.value===auth.enums.oaa_order_status.ORDER_APPOINTMENT_CANCELLED)));
			}
			else {
				let order_id = parseInt(status);
				if (order_id) {
					setId(order_id);
					dispatch(fetchOrderAsync({id:order_id})).then((response) => loadOrder(response));
				}
			}
		}
	}, [
		dispatch,
		translate.breadcrumb.WAREHOUSE_INVENTORY,
		region_data.length,
		region,
		province,
		city,
		status,
		auth.enums.oaa_order_status,
		approval_statuses,
		loadOrder
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (auth.active_company) {
			dispatch(fetchCustomerProfitCostCentersAsync({company_id:auth.active_company.id})).then((response) => response.payload.status===resources.status.SUCCESS ? setProfitCostCenters(response.payload.data.profit_cost_centers.map((pcc) => ({value:pcc.id, type:pcc.type, label:pcc.description}))) : void(0));
		}
	}, [dispatch, auth.active_company]);

	const handleEditButtonClickedEvent = async (row) => {
		let id = worksheet.data_set[row][0];
		setId(id);
		dispatch(fetchOrderAsync({id:id})).then((response) => loadOrder(response))
	};

	const handleClearButtonClickedEvent = () => {
	};

	const handleApprovalStatusChangedEvent = (approvalStatus) => {
		setApprovalStatus(approvalStatus);
	};

	const handleOrderTypeChangedEvent = (orderType) => {
		setOrderType(orderType);
	};

	const handleUpdateButtonClickedEvent = () => {
		const updateWorksheet = (response) => {
			let dataSet = worksheet.data_set.map((ds) => (ds[0]===response.payload.data.order.id ? [
				ds[0],
				ds[1],
				response.payload.data.order.status,
				ds[3],
				ds[4],
				ds[5],
				ds[6],
				ds[7],
				ds[8],
				ds[9],
				ds[10],
				ds[11],
			] : ds));
			setWorkSheet({
				is_readonly_mode: true,
				num_rows: 1000,
				num_cols: 10,
				num_frozen_columns: 4,
				import_limit: 700,
				focused_cell: null,
				selected_regions: null,
				header: translate.worksheet.default_header.ORDER,
				alignment: translate.worksheet.default_alignment.ORDER,
				comment: [],
				data_set: dataSet
			});
			if (response.payload.data.order.status===auth.enums.oaa_order_status.ORDER_APPOINTMENT_CANCELLED||response.payload.data.order.status===auth.enums.oaa_order_status.ORDER_APPOINTMENT_COMPLETED) {
				setIsEditable(false);
			}
			setTransactionLogs(response.payload.data.order.transaction_logs);
		}
		dispatch(updateOrderAsync({
			id:id, 
			status:document_status.value,
			profit_cost_center:profit_cost_center,
			status_message:status_message,
			pickup_date:pickup_date,
			delivery_date:delivery_date,
			appointment_date:appointment_date,
		})).then((response) => (response.payload.status===resources.status.SUCCESS ? updateWorksheet(response) : void(0)));
	};

	const handleSendMessageButtonClickEvent = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setMessage("");
				setComments(response.payload.data.comments);
			}
		}
		dispatch(sendMessageAsync({order_id:id, message:message})).then((response) => handleResponse(response));
	};

	const handlePaymentButtonClickedEvent = () => {
		const handlePaymentResponse = (response) => {
			if (response.payload.data.order) {
				setTransactionLogs(response.payload.data.order.transaction_logs);
				if (response.payload.data.order.payments) {
					let payments = response.payload.data.order.payments;
					if (payments.length>0) {
						setPaymentStatus(auth.enums.payment_status.PAID);
						setIsPaymentDialogOpen(false);
					}
				}
			}
		}
		dispatch(payOrderingAndAppointmentAsync({
			id:id, 
			amount_due:amount_due,
			payment_method:payment_method ? payment_method.value : "",
		})).then((response) => (response.payload.status===resources.status.SUCCESS ? handlePaymentResponse(response) : void(0)));
		
	};
	
	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{ Util.functionTitle(title) }
				<div className="function-sub-title"></div>

				<ButtonGroup className="button-group" minimal={false}>
					{
						id && is_editable &&
						<Button
							text={translate.button.UPDATE}
							className="function-button"
							disabled={auth.status === resources.status.LOADING}
							icon={<Icon icon="floppy-disk" className="orange"/>}
							onClick={e => handleUpdateButtonClickedEvent()}
						/>
					}
					{
						id && 
						<Button
							text={payment_status===auth.enums.payment_status.PAID ? "View Payment" : "Make Payment"}
							className="function-button"
							disabled={auth.status === resources.status.LOADING}
							icon={<Icon icon="floppy-disk" className="orange"/>}
							onClick={e => setIsPaymentDialogOpen(true)}
						/>
					}
				</ButtonGroup>

				<Dialog
					title="Payment"
					icon={<Icon className="orange" icon="info-sign"/>}
					isOpen={is_payment_dialog_open}
					onClose={e => setIsPaymentDialogOpen(false)}
					isCloseButtonShown={true}
				>
					<div className={Classes.DIALOG_BODY}>
						<div className="row mb-5 mt-5">
							<TextField
								className="col-sm-12 col-md-12 col-lg-12"
								size="small"
								variant="outlined"
								value={amount_due}
								inputProps={{readOnly:payment_status===auth.enums.payment_status.PAID}}
								label={`Amount Due (in ${auth.active_company.currency.code})`}
								onChange={(e) =>
									setAmountDue(
										e.target.value
									)
								}
							/>
						</div>
						<div className="row mb-5 mt-5">
							<div className="col-sm-12 col-md-12 col-lg-12">
								<MuiAutocomplete
									setSelectedValue={setPaymentMethod}
									selected_value={payment_method}
									isMultiple={false}
									label={"Select Payment Method"}
									data={payment_methods}
									isDisabled={payment_status===auth.enums.payment_status.PAID}
								/>
							</div>
						</div>
					</div>
					<div style={{textAlign:"center"}}>
						<Button
							icon={<Icon className="orange" icon="cross"/>}
							onClick={e => setIsPaymentDialogOpen(false)}
						>
							{payment_status===auth.enums.payment_status.PAID ? "Exit" : "Cancel"}
						</Button>
						{
							payment_status===auth.enums.payment_status.UNPAID &&
							<Button
								style={{marginLeft:"10px"}}
								icon={<Icon className="orange" icon="tick"/>}
								onClick={handlePaymentButtonClickedEvent}
							>
								{'Mark as "PAID"'}
							</Button>
						}
					</div>
				</Dialog>

				{
					id &&
					<div className="function-card">
						<div className="row">
							<Card
								className="col-sm-12 col-md-4 col-lg-3"
								style={{
									background: "lightblue",
								}}
							>
								<FormGroup>
									<H3 className="center" style={{color:"blue"}}>{document_number}</H3>
								</FormGroup>

								<div className="row mb-5 mt-5">
									<div className="col-sm-12 col-md-8 col-lg-8">
										<MuiAutocomplete
											setSelectedValue={setDocumentStatus}
											selected_value={document_status}
											isMultiple={false}
											label={"Status"}
											data={approval_statuses}
											isDisabled={!is_editable}
										/>
									</div>
									<TextField
										className="col-sm-12 col-md-4 col-lg-4"
										label={"Payment Status"}
										variant="outlined"
										size="small"
										value={payment_status}
										inputProps={{readOnly:true}}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</div>

								<div className="row mb-5 mt-5">
									<TextField
										multiline
										minRows={2}
										className="col-sm-12 col-md-12 col-lg-12"
										size="small"
										variant="outlined"
										label={"Status Message"}
										value={status_message}
										onChange={(e) =>
											setStatusMessage(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<div className="row mb-5 mt-5">
									<div className="col-sm-12 col-md-12 col-lg-12">
										<MuiAutocomplete
											setSelectedValue={setProfitCostCenter}
											selected_value={profit_cost_center}
											isMultiple={false}
											label={"Profit Cost / Center"}
											data={profit_cost_centers}
											isDisabled={!is_editable}
										/>
									</div>
								</div>

								{
									pickup_address_id &&
									<div className="row mb-5 mt-5">
										<TextField
											className="col-sm-12 col-md-12 col-lg-12"
											id="pickup_date"
											label={translate.placeholder.PICKUP_DATE}
											type="date"
											variant="outlined"
											size="small"
											value={pickup_date}
											inputProps={{readOnly:!is_editable}}
											onChange={(e) => setPickupDate(e.target.value)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</div>
								}

								{
									delivery_address_id &&
									<div className="row mb-5 mt-5">
										<TextField
											className="col-sm-12 col-md-12 col-lg-12"
											id="delivery_date"
											label={translate.placeholder.DELIVERY_DATE}
											type="date"
											variant="outlined"
											size="small"
											value={delivery_date}
											inputProps={{readOnly:!is_editable}}
											onChange={(e) => setDeliveryDate(e.target.value)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</div>
								}

								{
									appointment_address_id &&
									<div className="row mb-5 mt-5">
										<TextField
											className="col-sm-12 col-md-12 col-lg-12"
											id="appointment_date"
											label={translate.placeholder.APPOINTMENT_DATE}
											type="date"
											variant="outlined"
											size="small"
											value={appointment_date}
											inputProps={{readOnly:!is_editable}}
											onChange={(e) => setAppointmentDate(e.target.value)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</div>
								}

								<div className="row mb-5 mt-5">
									<TextField
										className="col-sm-12 col-md-12 col-lg-12"
										size="small"
										variant="outlined"
										value={document_type}
										inputProps={{readOnly:true}}
										label={"Order Type"}
									/>
								</div>

								<div className="row mb-5 mt-5">
									<TextField
										className="col-sm-12 col-md-12 col-lg-12"
										size="small"
										variant="outlined"
										value={customer}
										inputProps={{readOnly:true}}
										label={"Customer Name"}
										onChange={(e) =>
											setCustomer(
												e.target.value
											)
										}
									/>
								</div>

								{
									pickup_address_id &&
									<div className="row mb-5 mt-5">
										<TextField
											multiline
											minRows={4}
											className="col-sm-12 col-md-12 col-lg-12"
											size="small"
											variant="outlined"
											label={"Pick-up Address"}
											value={pickup_address ? pickup_address : ""}
											onChange={(e) =>
												setPickupAddress(
													e.target.value
												)
											}
											inputProps={{readOnly:true}}
										/>
									</div>
								}

								{
									delivery_address_id &&
									<div className="row mb-5 mt-5">
										<TextField
											multiline
											minRows={4}
											className="col-sm-12 col-md-12 col-lg-12"
											size="small"
											variant="outlined"
											label={"Delivery Address"}
											value={delivery_address ? delivery_address : ""}
											onChange={(e) =>
												setDeliveryAddress(
													e.target.value
												)
											}
											inputProps={{readOnly:true}}
										/>
									</div>
								}

								{
									appointment_address_id &&
									<div className="row mb-5 mt-5">
										<TextField
											multiline
											minRows={4}
											className="col-sm-12 col-md-12 col-lg-12"
											size="small"
											variant="outlined"
											label={"Appointment Address"}
											value={appointment_address ? appointment_address : ""}
											onChange={(e) =>
												setAppointmentAddress(
													e.target.value
												)
											}
											inputProps={{readOnly:true}}
										/>
									</div>
								}

							</Card>

							<ControlGroup className="col-sm-12 col-md-8 col-lg-9 tbl-parent" vertical={true} style={{background: "grey", border:"solid lightgrey 1px", alignItems:"stretch", overflowX:"hidden", overflowY:"scroll", maxHeight:"70vh"}}>
								<ControlGroup className="tbl-group-sm" vertical={true}>

									<HTMLTable 
										className="line-item-table line-item-table-header"
									>
										<tbody>
											<tr>
												<th className="line line-number"></th>
												<th className="input-line line-item">Quantity / Item / Service Description</th>
											</tr>
										</tbody>
									</HTMLTable>
									<HTMLTable 
										className="line-item-table"
										style={{
											background: "#F4F4F4",
											width:"100%",
										}}
									>
										<tbody>
											{
												line_items.map((lineItem, key) => 
													<tr key={key} style={{outline: "thin solid lightgrey"}}>
														<td className="line line-number">
															<ControlGroup>
																<Button text={key+1} minimal={true}/>
															</ControlGroup>
														</td>
														<td className="input-line line-item">
															<ControlGroup vertical={false}>
																<InputGroup fill={true} value={lineItem} readOnly={true}/>
															</ControlGroup>
														</td>
													</tr>
												)
											}

										</tbody>
									</HTMLTable>

									{
										id &&
										<>
											<HTMLTable 
												className="line-item-table line-item-table-header mt-5"
											>
												<tbody>
													<tr>
														<th className="input-line line-item">Messages</th>
													</tr>
												</tbody>
											</HTMLTable>
											<HTMLTable 
												className="line-item-table"
												style={{
													background: "#F4F4F4",
													width:"100%"
												}}
											>
												<tbody>
													<tr style={{outline: "thin solid lightgrey"}}>
														<td className="input-line line-item">
															<ControlGroup vertical={true} style={{margin:10}}>
																<InputGroup
																	placeholder="Type your message here then click 'Send'"
																	fill={true}
																	rightElement={
																		<Button 
																			rightIcon={<Icon icon="send-message" size={10} className="orange"/>}
																			onClick={handleSendMessageButtonClickEvent}
																			disabled={auth.status===resources.status.LOADING||message.length===0}
																			outlined={true}
																			large={false}
																			style={{fontSize:"11pt"}}
																		>
																			Send
																		</Button>
																	}
																	value={message}
																	onChange={e => setMessage(e.target.value)}
																	disabled={!is_editable}
																/>
																{
																	comments.slice(0).reverse().map((comment, key) => (
																		<div key={key} style={{textAlign:auth.user.name===comment.posted_by ? "right" : "left"}}>
																			<Tag fill={true} style={{background:auth.user.name===comment.posted_by ? "white" : "white",textAlign:"justify", marginBottom:"5px",marginTop:"10px"}} multiline={true} round={false}>
																				<div style={{color:auth.user.name===comment.posted_by ? "blue" : "green",fontWeight:"bold", fontSize:"9pt", textAlign:auth.user.name===comment.posted_by ? "left" : "left"}}>{comment.posted_by}</div>
																				<span style={{color:"black"}}>
																					{comment.message}
																				</span>
																				<div style={{color:auth.user.name===comment.posted_by ? "black" : "black",fontWeight:"bold", fontSize:"8pt", textAlign:auth.user.name===comment.posted_by ? "right" : "right"}}>{comment.posted_at}</div>
																			</Tag>
																		</div>
																	))
																}
															</ControlGroup>
														</td>
													</tr>
												</tbody>
											</HTMLTable>
										</>
									}

									{
										rating &&
										<>
											<HTMLTable 
												className="line-item-table line-item-table-header mt-5"
											>
												<tbody>
													<tr>
														<th className="input-line line-item">Customer Rating / Feedback</th>
													</tr>
												</tbody>
											</HTMLTable>
											<HTMLTable 
												className="line-item-table"
												style={{
													background: "#F4F4F4",
													width:"100%"
												}}
											>
												<tbody>
													<tr style={{outline: "thin solid lightgrey"}}>
														<td className="input-line line-item">
															<ControlGroup vertical={true} style={{margin:10, alignSelf:"center"}}>
																<div className="row">
																	<div className="col-sm-12 col-md-4 col-lg-4">
																		<ControlGroup vertical={true}>
																			{rating.rating>0 ? Object.values(auth.enums.oaa_ratings)[rating.rating-1] : 'No Rating'}
																			<Rating name="read-only" value={rating.rating} readOnly style={{alignSelf:"center"}}/>
																		</ControlGroup>
																	</div>
																	<div className="col-sm-12 col-md-8 col-lg-8 left">
																		{
																			rating.feedback
																		}
																	</div>
																</div>
															</ControlGroup>
														</td>
													</tr>
												</tbody>
											</HTMLTable>
										</>
									}

									{
										id &&
										<>
											<HTMLTable 
												className="line-item-table line-item-table-header mt-5"
											>
												<tbody>
													<tr>
														<th className="input-line line-item">Transaction Logs</th>
													</tr>
												</tbody>
											</HTMLTable>
											<HTMLTable 
												className="line-item-table"
												style={{
													background: "#F4F4F4",
													width:"100%"
												}}
											>
												<tbody>
													<tr style={{outline: "thin solid lightgrey"}}>
														<td className="input-line line-item">
															<div style={{margin:10, width:"inherit"}}>
																{
																	transaction_logs.slice(0).reverse().map((log, key) => (
																		<div key={key} style={{whiteSpace:"nowrap", textAlign:"left"}}>
																			[<span style={{color:"green"}}>{log.at}</span>] <span style={{color:"blue", fontWeight:"bold"}}>{log.action}</span> <span style={{color:"green", fontWeight:"bold"}}>by {log.by}</span>
																		</div>
																	))
																}
															</div>
														</td>
													</tr>
												</tbody>
											</HTMLTable>
										</>
									}

								</ControlGroup>
							</ControlGroup>

						</div>
					</div>
				}

				<Card className="function-card" style={{marginTop:20}}>
					<div className="row">

						<div className="col-sm-12 col-md-3 col-lg-2 text-field">
							<MuiAutocomplete
								setSelectedValue={handleApprovalStatusChangedEvent}
								selected_value={approval_status}
								isMultiple={false}
								label={"Filter by Status"}
								data={approval_statuses}
							/>
						</div>

						<div className="col-sm-12 col-md-3 col-lg-2 text-field">
							<MuiAutocomplete
								setSelectedValue={handleOrderTypeChangedEvent}
								selected_value={order_type}
								isMultiple={false}
								label={"Filter by Order Type"}
								data={order_types}
							/>
						</div>

						{
							order_type &&
							<TextField
								className="col-sm-12 col-md-2 col-lg-2 text-field"
								id="from_date"
								label={order_type && order_type.value===auth.enums.oaa_order_types.FOR_PICK_UP_AND_DELIVERY ? "From Pick-up Date" : "From Date"}
								type="date"
								variant="outlined"
								size="small"
								value={from_date}
								onChange={(e) => setFromDate(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						}
						{
							order_type &&
							<TextField
								id="to_date"
								className="col-sm-12 col-md-2 col-lg-2 text-field"
								label={order_type && order_type.value===auth.enums.oaa_order_types.FOR_PICK_UP_AND_DELIVERY ? "To Pick-up Date" : "To Date"}
								type="date"
								variant="outlined"
								size="small"
								value={to_date}
								onChange={(e) => setToDate(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						}
					
					</div>

					{
						order_type && order_type.value===auth.enums.oaa_order_types.FOR_PICK_UP_AND_DELIVERY &&
						<div className="row">
							<TextField
								className="col-sm-12 col-md-2 col-lg-2 text-field"
								id="from_delivery_date"
								label="From Delivery Date"
								type="date"
								variant="outlined"
								size="small"
								value={from_delivery_date}
								onChange={(e) => setFromDeliveryDate(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						
							<TextField
								id="to_delivery_date"
								className="col-sm-12 col-md-2 col-lg-2 text-field"
								label="To Delivery Date"
								type="date"
								variant="outlined"
								size="small"
								value={to_delivery_date}
								onChange={(e) => setToDeliveryDate(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
							/>

						</div>
					}

					{
						order_type &&
						<div className="row">
							<div className="col-sm-12 col-md-3 col-lg-2 text-field">
								<MuiAutocomplete
									setSelectedValue={setRegion}
									selected_value={region}
									isMultiple={false}
									label={"Filter by Region"}
									data={region_data}
								/>
							</div>
							<div className="col-sm-12 col-md-3 col-lg-2 text-field">
								<MuiAutocomplete
									setSelectedValue={setProvince}
									selected_value={province}
									isMultiple={false}
									label={"Filter by Province"}
									data={province_data}
								/>
							</div>
							<div className="col-sm-12 col-md-3 col-lg-2 text-field">
								<MuiAutocomplete
									setSelectedValue={setCity}
									selected_value={city}
									isMultiple={false}
									label={"Filter by City"}
									data={city_data}
								/>
							</div>
							<div className="col-sm-12 col-md-3 col-lg-2 text-field">
								<MuiAutocomplete
									setSelectedValue={setBarangay}
									selected_value={barangay}
									isMultiple={false}
									label={"Filter by Barangay"}
									data={barangay_data}
								/>
							</div>
						</div>
					}

				</Card>
				<ButtonGroup className="button-group" minimal={false}>
					
					{
						auth.worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => PDF.export(
										title,
										worksheet.header,
										worksheet.data_set,
										auth.active_company,
										"b3",
										"landscape",
										[],
										[40, 60, 60, 60, 80, "*", 60, "*", 60, "*", 60, 80, 60]
									)
								}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => Excel.export(
										title,
										worksheet.header,
										worksheet.data_set,
										auth.active_company
									)
								}
							/>
						</>
					}
				</ButtonGroup>

				<OrderSpreadsheet
					enable_select={false}
					enable_open={false}
					enable_edit={true}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					handleEditButtonClickedEvent={handleEditButtonClickedEvent}
					handleClearButtonClickedEvent={handleClearButtonClickedEvent}
					status={status}
					approval_status={approval_status}
					order_type={order_type}
					from_date={from_date}
					to_date={to_date}
					from_delivery_date={from_delivery_date}
					to_delivery_date={to_delivery_date}
					region={region}
					province={province}
					city={city}
					barangay={barangay}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
				/>

			</div>
		</div>
	);
}