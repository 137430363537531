import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createFinishedGoodComponentsAsync,
	importFinishedGoodComponentsAsync,
	toggleSidebarCollapsed,
	skipProcessAsync,
	fetchAllItemsAsync,
	fetchAllFinishedGoodComponentsAsync,
	fetchItemAsync,
	deleteFinishedGoodComponentsAsync,
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Switch,
	Icon,
	Card,
	ControlGroup,
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import { FinishedGoodsComponentLineItem } from "./FinishedGoodsComponentLineItem";
import { SideBar } from '../../../SideBar';
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { useHistory } from "react-router-dom";
import { FinishedGoodComponentsSpreadsheet } from "../../../spreadsheets/FinishedGoodComponentsSpreadsheet";

export function FinishedGoodsComponent() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const default_line_items = Array(9).fill({item:null, quantity:"", uom:null});
	const [current_application] = useState(auth.applications.find((app) => app.name===translate.text.FINISHED_GOODS_COMPONENT));
	const [id, setId] = useState(null);
	const [item_image_source, setItemImageSource] = useState(null);
	const [item, setItem] = useState(null);
	const [finished_good_item, setFinishedGoodItem] = useState(null);
	const [finished_good_items, setFinishedGoodItems] = useState([]);
	const [items, setItems] = useState([]);
	const [yield_quantity, setYieldQuantity] = useState("");
	const [yield_uom, setYieldUom] = useState(null);
	const [line_items, setLineItems] = useState(default_line_items);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);
	const [unit_of_measures, setUnitOfMeasures] = useState([]);
	const DEFAULT_HEADER = translate.worksheet.default_header.FINISHED_GOOD_COMPONENT;
	const DEFAULT_ALIGNMENT = translate.worksheet.default_alignment.FINISHED_GOOD_COMPONENT;
	const IMPORT_HEADER = translate.worksheet.import_header.FINISHED_GOOD_COMPONENT;
	const IMPORT_ALIGNMENT = translate.worksheet.import_alignment.FINISHED_GOOD_COMPONENT;
	const COMMENT = translate.worksheet.import_comment.FINISHED_GOOD_COMPONENT;
	const NUM_ROWS = 1000;
	const NUM_COLS = DEFAULT_HEADER.length;
	const IMPORT_LIMIT = 2000;
	let DEFAULT_DATA_SET = new Array(NUM_ROWS).fill("").map(() => new Array(NUM_COLS).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: NUM_ROWS,
		num_cols: NUM_COLS,
		num_frozen_columns: 0,
		import_limit: IMPORT_LIMIT,
		focused_cell: null,
		selected_regions: null,
		header: DEFAULT_HEADER,
		alignment: DEFAULT_ALIGNMENT,
		comment: COMMENT,
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.FINISHED_GOOD_COMPONENT));
		if (worksheet.is_readonly_mode) {
			const handleItemResponse = (response) => {
				if (response.payload.status===resources.status.SUCCESS) {
					let fgItems = response.payload.data.items.filter((itm) => (Util.stringToBoolean(itm.with_components)));
					setFinishedGoodItems(fgItems.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description))).map((itm) => ({label:itm.label, value:itm.id})));
					setItems(response.payload.data.items.filter((itm) => (itm.item_class.name!==auth.enums.item_classes.FINISHED_GOODS && (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)))));
				}
			}
			dispatch(fetchAllItemsAsync({})).then((response) => handleItemResponse(response));
			const handleResponse = (response) => {
				if (response.payload.status===resources.status.SUCCESS) {
					setWorkSheet({
						is_readonly_mode: true,
						num_rows: NUM_ROWS>response.payload.data.finished_good_components.length ? NUM_ROWS : response.payload.data.finished_good_components.length,
						num_cols: 10,
						num_frozen_columns: 0,
						import_limit: 700,
						focused_cell: null,
						selected_regions: null,
						header: translate.worksheet.default_header.FINISHED_GOOD_COMPONENT,
						alignment: translate.worksheet.default_alignment.FINISHED_GOOD_COMPONENT,
						comment: [],
						data_set: response.payload.data.finished_good_components.map((ds) => [
							ds.id,
							ds.item_code,
							ds.item_description,
							ds.item_class_name,	
							ds.yield_quantity,	
							ds.yield_uom,
							ds.component_item_code,
							ds.component_item_description,
							ds.component_quantity,
							ds.component_uom,
						])
					})
				}
			}
			dispatch(fetchAllFinishedGoodComponentsAsync({})).then((response) => handleResponse(response));
		}
	}, [
		dispatch,
		translate.breadcrumb.FINISHED_GOOD_COMPONENT,
		worksheet.is_readonly_mode,
		auth.enums.item_classes.FINISHED_GOODS,
		translate.worksheet.default_alignment.FINISHED_GOOD_COMPONENT,
		translate.worksheet.default_header.FINISHED_GOOD_COMPONENT
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const onSaveButtonClicked = () => {
		const handleResponse = (response) => {
			let item = response.payload.data.item;
			let fgs = response.payload.data.finished_good_components;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: [
					...fgs.map((ds) => ([
						ds.id,
						ds.item_code,
						ds.item_description,
						ds.item_class_name,	
						ds.yield_quantity,	
						ds.yield_uom,
						ds.component_item_code,
						ds.component_item_description,
						ds.component_quantity,
						ds.component_uom,
					])),
					...worksheet.data_set.filter((ds) => (ds[1]!==item.code))
				]
			});
			onClearButtonClicked();
		}
		dispatch(
			createFinishedGoodComponentsAsync({
				finished_good_item: finished_good_item!==null ? finished_good_item.value : "",
				yield_quantity: yield_quantity,
				yield_uom: yield_uom!==null ? yield_uom.value : "",
				components:line_items.filter((component, key) => (component.item!==null||component.quantity.length>0||component.uom!==null))
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleResponse(response) : null))
	}

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const onClearButtonClicked = () => {
		setId(null);
		setFinishedGoodItem(null);
		setItemImageSource("");
		setYieldQuantity("");
		setYieldUom(null);
		setLineItems(default_line_items);
	};

	const onDeleteRowsButtonClicked = () => {
		let ids = selected_rows.map(
			(row) => worksheet.data_set[row][0]
		);
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: worksheet.data_set.filter((ds) => (!ids.includes(ds[0])))
				});
				setSelectedRows([]);
			}
		}
		dispatch(deleteFinishedGoodComponentsAsync(ids)).then((response) => handleResponse(response));
	};

	const onSkipButtonClicked = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'finished-good-component'})).then((response) => response.payload.data && response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onImportButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			let dataSet = worksheet.data_set.filter((dataSet) => dataSet[0].length>0||dataSet[1].length>0||dataSet[2].length>0||dataSet[3].length>0);
			if (dataSet.length<=IMPORT_LIMIT) {
				const handleResponse = (i, length, status) => {
					if (status===resources.status.SUCCESS) {
						if (i===0) {
							onClearCellsButtonClicked();
							toast.success(translate.message.IMPORT_FINISHED_GOOD_COMPONENTS_QUEUED, Util.getToasterStyle(resources.status.SUCCESS));
						}
						if (i===length-1) {
							toast.success(translate.message.IMPORT_FINISHED_GOOD_COMPONENTS_FINISHED, Util.getToasterStyle(resources.status.SUCCESS));
						}
					}
				} 
				let size = 10;
				let chunks = Util.chunk(dataSet, size);
				let length = chunks.length;
				for (let i=0; i<chunks.length; i++) {
					dispatch(
						importFinishedGoodComponentsAsync(chunks[i])
					).then((response) => (handleResponse(i, length, response.payload.status)));
				}
			}
			else {
				toast.error(
					translate.message.IMPORT_LIMIT_REACHED,
					Util.getToasterStyle(resources.status.ERROR)
				);
			}

		}
	};

	const onFinishedGoodItemSelectChanged = (value) => {
		const handleResponse = (response) => {
			const item = response.payload.data.item;
			if (item!==null) {
				let unitOfMeasures = item.unit_of_measure_sets.map((uom) => ({label:uom.code, value:uom.id}));
				if (response.payload.data.finished_good_components.length>0) {
					setId(item.id);
					setYieldQuantity(item.yield_quantity);
					setYieldUom(unitOfMeasures.find((uom) => uom.value===item.yield_unit_of_measure.id));
					let item_components = response.payload.data.finished_good_components.map((component) => ({item:{label:`${component.item.code} | ${component.item.description}`, value:component.item.id}, quantity:component.quantity, uom:{label:component.unit_of_measure.code, value:component.unit_of_measure.id}}));
					setLineItems(item_components);
					setUnitOfMeasures(unitOfMeasures);
				}
				else {
					setId(null);
					setYieldQuantity("");
					setYieldUom(null);
					setLineItems(default_line_items);
					setUnitOfMeasures(unitOfMeasures);
				}
			}
			setItem(item);
			setFinishedGoodItem(value);
			setItemImageSource(item!==null ? item.image_source : "");
		}
		dispatch(fetchItemAsync({id:value.value})).then((response) => handleResponse(response));
	};

	const onSpreadSheetModeSwitchChanged = () => {
		let newMode = !worksheet.is_readonly_mode;
		setWorkSheet({
			is_readonly_mode: newMode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearCellsButtonClicked = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearSelectedCellsButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			if (worksheet.selected_regions!=null) {
				let dataSet = [];
				let selectedRegions = worksheet.selected_regions;
				for (let i=0; i<worksheet.data_set.length; i++) {
					let row = [];
					for (let j=0; j<worksheet.data_set[i].length; j++) {
						row.push(worksheet.data_set[i][j]);
					}
					dataSet.push(row);
				}
				for (let i=0; i<selectedRegions.length; i++) {
					let selectedRows = selectedRegions[i].rows;
					let selectedCols = selectedRegions[i].cols;
					let startRow = selectedRows[0];
					let endRow = selectedRows[1];
					let startCol = selectedCols[0];
					let endCol = selectedCols[1];
					for (let j=startRow; j<=endRow; j++) {
						if (dataSet[j]!==undefined) {
							for (let k=startCol; k<=endCol; k++) {
								if (dataSet[j][k]!==undefined) {
									dataSet[j][k] = "";
								}
							}
						}
					}
				}
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: dataSet
				});
			}
		}
	}

	const onEditButtonClicked = (row) => {
		let finishedGoodValue = finished_good_items.find((fg) => (fg.label === `${worksheet.data_set[row][1]} | ${worksheet.data_set[row][2]}`));
		if (finishedGoodValue) {
			onFinishedGoodItemSelectChanged(finishedGoodValue);
			window.scrollTo(0,0);
		}
	};

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_SAVE_LABEL,
							value: translate.text.NOTE_SAVE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
						{
							label: translate.text.NOTE_INLINE_EDITING_FIELD_LABEL,
							value: translate.text.NOTE_INLINE_EDITING_FIELD_VALUE
						},
						{
							label: translate.text.NOTE_PASTING_DATA_LABEL,
							value: translate.text.NOTE_PASTING_DATA_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id && (finished_good_items.length===0 || auth.active_company.application_id>current_application.id) &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSkipButtonClicked}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.FINISHED_GOODS_COMPONENT) }

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						<Button
							text={translate.button.SAVE}
							className="function-button"
							disabled={auth.status === resources.status.LOADING}
							icon={<Icon icon="floppy-disk" className="orange"/>}
							onClick={onSaveButtonClicked}
						/>
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onClearButtonClicked}
						/>
					</ButtonGroup>
				}

				{
					worksheet.is_readonly_mode &&
					<div className="function-card">
						<div className="row">
							<Card
								className="col-sm-12 col-md-4 col-lg-4"
								style={{
									background: "lightblue",
								}}
							>
								<div className="center">
									<img alt="" src={item_image_source} style={{marginBottom:"15px", border:'2px solid grey'}}/>
								</div>
								<div>
									<ControlGroup vertical={false} fill={"true"}>
										<MuiAutocomplete
											setSelectedValue={onFinishedGoodItemSelectChanged}
											selected_value={finished_good_item}
											isMultiple={false}
											label={translate.placeholder.SELECT_ITEM_FINISHED_PRODUCT}
											data={finished_good_items}
										/>
									</ControlGroup>
									<ControlGroup vertical={false} fill={"true"}>
										<ControlGroup fill={"true"} style={{marginRight:"5px",marginTop:"10px"}}>
											<TextField
												size="small"
												variant="outlined"
												value={yield_quantity}
												onChange={(e) =>
													setYieldQuantity(
														e.target.value
													)
												}
												label={"Yield quantity"}
												type="number"
												InputProps={{
													inputProps: {
														min: 0.1
													}
												}}
											/>
										</ControlGroup>
										<ControlGroup fill={"true"} style={{marginTop:"10px"}}>
											<MuiAutocomplete
												setSelectedValue={setYieldUom}
												selected_value={yield_uom}
												isMultiple={false}
												label={"Yield uom"}
												data={unit_of_measures}
											/>
										</ControlGroup>
									</ControlGroup>

									<Card style={{textAlign:"left",background: "white",marginTop:"10px", fontWeight:"bold"}} fill={"true"}>
										<table className="item-info-table" style={{fontSize:"9pt",borderCollapse:"collapse"}}>
											<tbody>
												<tr>
													<td style={{padding:"2px", fontWeight:"bold"}}>Item Class</td>
													<td style={{padding:"2px", color:"blue"}}>{finished_good_item!==null ? item.item_class.name : ""}</td>
												</tr>
												<tr>
													<td style={{padding:"2px", fontWeight:"bold"}}>UOM</td>
													<td style={{padding:"2px", color:"blue"}}>{finished_good_item!==null ? item.unit_of_measure.name : ""}</td>
												</tr>
												<tr>
													<td style={{padding:"2px", fontWeight:"bold"}}>Item Costing</td>
													<td style={{padding:"2px", color:"blue"}}>{finished_good_item!==null ? item.item_costing : ""}</td>
												</tr>
												<tr>
													<td style={{padding:"2px", fontWeight:"bold"}}>Inventory Source</td>
													<td style={{padding:"2px", color:"blue"}}>{finished_good_item!==null ? item.inventory_source : ""}</td>
												</tr>
												<tr>
													<td style={{padding:"2px", fontWeight:"bold"}}>Has Expiry</td>
													<td style={{padding:"2px", color:"blue"}}>{finished_good_item!==null ? Util.formatBooleanString(item.has_expiry) : ""}</td>
												</tr>
												<tr>
													<td style={{padding:"2px", fontWeight:"bold"}}>With Components</td>
													<td style={{padding:"2px", color:"blue"}}>{finished_good_item!==null ? Util.formatBooleanString(item.with_components) : ""}</td>
												</tr>
												<tr>
													<td style={{padding:"2px", fontWeight:"bold"}}>Barcode Enabled</td>
													<td style={{padding:"2px", color:"blue"}}>{finished_good_item!==null ? Util.formatBooleanString(item.is_barcode_enabled) : ""}</td>
												</tr>
												<tr>
													<td style={{padding:"2px", fontWeight:"bold"}}>QR Code Enabled</td>
													<td style={{padding:"2px", color:"blue"}}>{finished_good_item!==null ? Util.formatBooleanString(item.is_qr_code_enabled) : ""}</td>
												</tr>
											</tbody>
										</table>
									</Card>
								</div>
							</Card>
							{
								<FinishedGoodsComponentLineItem
									id={id}
									default_line_items={default_line_items}
									line_items={line_items}
									setLineItems={setLineItems}
									items={items}
								/>
							}
						</div>
					</div>
				}

				<ButtonGroup className="button-group" minimal={false}>
					<Switch
						className="switch-mode"
						checked={!worksheet.is_readonly_mode}
						disabled={auth.status === resources.status.LOADING}
						labelElement={<span className="switch-mode-label">{translate.label.SWITCH_MODE}</span>}
						innerLabelChecked={translate.label.IMPORT}
						innerLabel={translate.label.READ_ONLY}
						onChange={onSpreadSheetModeSwitchChanged}
					/>
					{
						worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => PDF.export(
										translate.worksheet.file_name.FINISHED_GOOD_COMPONENT,
										worksheet.header,
										worksheet.data_set,
										auth.active_company,
										"legal",
										"landscape",
										[],
										[30, 60, "*", 90, 60, 60, 60, "*", 60, 60]
									)
								}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => Excel.export(
										translate.worksheet.file_name.FINISHED_GOOD_COMPONENT,
										worksheet.header,
										worksheet.data_set,
										auth.active_company
									)
								}
							/>
							<Button
								text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon={<Icon icon="select" className="orange"/>}
								onClick={e => setSelectedRows(selected_rows.length>0 ? [] : [...Array(worksheet.data_set.length).keys()])}
							/>
							{
								selected_rows.length>0 &&
								<Button
									text={`${translate.button.DELETE_ROWS} (${selected_rows.length})`}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="delete" className="orange"/>}
									onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
								/>
							}
						</>
					}
					{
						!worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.IMPORT}
								className="function-button"
								icon={<Icon icon="upload" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onImportButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_SELECTED_CELLS}
								className="function-button"
								icon={<Icon icon="clean" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearSelectedCellsButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_CELLS}
								className="function-button"
								icon={<Icon icon="graph-remove" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearCellsButtonClicked}
							/>
						</>
					}
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onFreezePaneButtonClicked}
						/>
					}
				</ButtonGroup>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={onDeleteRowsButtonClicked}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>System will automatically conduct further verification if deletion of certain Finished good component/s is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<FinishedGoodComponentsSpreadsheet
					enable_select={true}
					enable_open={false}
					enable_edit={true}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
					onEditButtonClicked={onEditButtonClicked}
				/>

			</div>
		</div>

	);
}
