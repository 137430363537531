import { Toaster } from "react-hot-toast";

export function Toast() {
    return (
        <Toaster
            toastOptions={{
                style: {
                    maxWidth: 550
                }
            }}
            containerStyle={{
                fontSize: "11pt",
                top: 58
            }}
        />
    );
}