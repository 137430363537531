import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../auth/authSlice';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Card, Elevation, Icon } from "@blueprintjs/core";
import 'chart.js/auto';
import {
	fetchOnHandInventoryAsync,
	updateDashboardData
} from './../auth/authSlice';
import { Link } from "react-router-dom";
import { resources } from "../../resources/index";

export function OnHandInventoriesChart() {

	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [application] = useState(auth.applications.find((app) => app.name===translate.text.STOCK_ON_HAND));
	const [has_access] = useState(auth.application_user.filter((appUser) => (appUser.user_id===auth.user.id && appUser.application_id===application.id)).length>0);
	const [total_amount, setTotalAmount] = useState(0);
	const [total_skus, setTotalSkus] = useState(0);

	const initFetch = useCallback(() => {
		dispatch(fetchOnHandInventoryAsync({})).then((response) => loadData(response));
		const loadData = (response) => {
			if (response.payload.data) {
				setTotalAmount(response.payload.data.total_amount);
				setTotalSkus(response.payload.data.total_skus);
				dispatch(updateDashboardData({on_hand_inventories:{
					total_amount:response.payload.data.total_amount,
					total_skus:response.payload.data.total_skus,
				}}));
			}
		}
	}, [dispatch]);

	useEffect(() => {
		initFetch()
	}, [initFetch]);

	useEffect(() => {
		if (auth.dashboard.on_hand_inventories) {
			setTotalAmount(auth.dashboard.on_hand_inventories.total_amount);
			setTotalSkus(auth.dashboard.on_hand_inventories.total_skus);
		}
	}, [auth.dashboard.on_hand_inventories]);

	return (

		<Link
			to={{
				pathname: "/warehouse-inventory/stock-on-hand",
			}}
			style={{textDecoration:"none", pointerEvents:auth.active_company.application_id===null&&has_access ? "" : "none"}}
		>
			<Card style={{marginTop:"4px", marginLeft:"2px", marginRight:"2px", marginBottom:"0px", paddingBottom:"0px", display:"flex", justifyContent:"center", flexDirection:"column", height:"200px"}} interactive={true} elevation={Elevation.TWO}>
				<Icon style={{alignSelf:"center", color:"gold", marginBottom:12}} icon="chart" size={50}/>
				<p style={{fontSize:17, fontFamily:"Arial", fontColor:"black", textAlign:"center", fontWeight:"bold"}}>On hand inventories</p>
				<p style={{fontSize:15, fontColor:"black", textAlign:"center"}}>Total Amount : <span className="bold">{auth.active_company.currency.symbol} {total_amount}</span></p>
				<p style={{fontSize:15, fontColor:"black", textAlign:"center"}}>Total SKUs : <span className="bold">{total_skus}</span></p>
			</Card>
		</Link>

	);

}
