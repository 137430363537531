import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, fetchOnHandFinishedGoodsAsync, fetchAccountingPeriodsAsync, updateDashboardData } from '../auth/authSlice';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { NoDataAvailable } from "../../common/NoDataAvailable";

export function OnHandFinishedGoodsBySourceChartV2() {

	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const [data, setData] = useState(null);
	const [initialized, setInitialized] = useState(false);

	const initFetch = useCallback(() => {
		if (auth.token) {
			const handleResponse = (response) => {
				let lbls = response.payload.data ? (response.payload.data).map((month, key) => month.prefix) : [];
				const initializeData = (res, labels) => {
					let ds = {
						labels,
						datasets: [
							{
								label: auth.enums.inventory_sources.IMPORTED,
								data: res.payload.data.result.map((rs1) => (rs1.on_hand_imported && parseFloat(rs1.on_hand_imported) ? parseFloat(rs1.on_hand_imported) : 0)),
								backgroundColor: 'rgba(255, 0, 0, 0.5)',
							},
							{
								label: auth.enums.inventory_sources.LOCAL_PURCHASE,
								data: res.payload.data.result.map((rs2) => (rs2.on_hand_local_purchase && parseFloat(rs2.on_hand_local_purchase) ? parseFloat(rs2.on_hand_local_purchase) : 0)),
								backgroundColor: 'rgba(0, 255, 0, 0.5)',
							},
							{
								label: auth.enums.inventory_sources.OWN_PRODUCTION,
								data: res.payload.data.result.map((rs3) => (rs3.on_hand_own_production && parseFloat(rs3.on_hand_own_production) ? parseFloat(rs3.on_hand_own_production) : 0)),
								backgroundColor: 'rgba(0, 0, 255, 0.5)',
							},
						]
					};
					setData(ds);
					dispatch(updateDashboardData({on_hand_finished_goods_by_source:ds}));
					setInitialized(true);
				}
				dispatch(fetchOnHandFinishedGoodsAsync({})).then((res) => res.payload.data && res.payload.data.result ? initializeData(res, lbls) : null);
			}
			if (auth.active_company.application_id===null) dispatch(fetchAccountingPeriodsAsync({})).then((res) => handleResponse(res));
			
		}
	}, [dispatch, auth.token, auth.enums.inventory_sources, auth.active_company.application_id]);

	useEffect(() => {
		initFetch()
	}, [initFetch]);

	useEffect(() => {
		if (auth.active_company.application_id===null && auth.dashboard.on_hand_finished_goods_by_source) {
			setData(auth.dashboard.on_hand_finished_goods_by_source);
		}
	}, [auth.active_company.application_id, auth.dashboard.on_hand_finished_goods_by_source]);

	const options = {
		responsive: true,
		plugins: {
		  legend: {
			position: 'top',
		  },
		  title: {
			display: true,
			font: {
				size: 17,
				family: "Arial",
			},
			color: "#106ba3",
			text: `On Hand Finished Goods by Source ( ${auth.active_company.currency.name} )`,
		  },
		},
	};
	
	return (

		data ? 
		<div>
			<Bar options={options} data={data} />
		</div> : 
		<NoDataAvailable text={initialized ? "No FG Posted Transaction/s Yet." : (auth.active_company.application_id ? "System Implementation Ongoing" : "No Data Found")}/>

	);

}
