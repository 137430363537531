import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, fetchItemConditionsPercentageAsync } from '../auth/authSlice';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { NoDataAvailable } from "../../common/NoDataAvailable";
import { MuiAutocomplete } from "../../common/MuiAutocomplete";
import { Link } from "react-router-dom";
import { resources } from "../../resources/index";

export function ItemConditionChart() {

	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [application] = useState(auth.applications.find((app) => app.name===translate.text.ITEM_CONDITIONS));
	const [has_access] = useState(auth.application_user.filter((appUser) => (appUser.user_id===auth.user.id && appUser.application_id===application.id)).length>0);
	const [labels, setLabels] = useState([]);
	const [colors, setColors] = useState([]);
	const [data, setData] = useState([]);
	const [item_classes, setItemClasses] = useState([]);
	const [item_class, setItemClass] = useState(null);
	const [initialized, setInitialized] = useState(false);

	const initFetch = useCallback(() => {
		if (auth.token) {
			const handleResponse = (response) => {
				let itemClasses = response.payload.data.item_classes.map((ic) => ({label:ic.name, value:ic.id}));
				setLabels(response.payload.data.labels);
				setColors(response.payload.data.colors);
				setData(response.payload.data.result);
				setItemClasses(itemClasses);
				setInitialized(true);
			}
			if (auth.active_company.application_id===null) dispatch(fetchItemConditionsPercentageAsync({item_class:item_class ? item_class.value : ""})).then((res) => res.payload.data && res.payload.data.result.length>0 ? handleResponse(res) : void(0));
			
		}
	}, [dispatch, auth.token, item_class, auth.active_company.application_id]);

	useEffect(() => {
		initFetch()
	}, [initFetch]);

	useEffect(() => {
		if (auth.active_company.application_id===null && auth.dashboard.item_conditions_percentage) {
			setData(auth.dashboard.item_conditions_percentage);
			setColors(auth.dashboard.item_conditions_percentage_colors);
			setLabels(auth.dashboard.item_conditions_percentage_labels);
			setItemClasses(auth.dashboard.item_conditions_percentage_item_classes);
		}
	}, [auth.active_company.application_id, auth.dashboard.item_conditions_percentage, auth.dashboard.item_conditions_percentage_colors, auth.dashboard.item_conditions_percentage_labels, auth.dashboard.item_conditions_percentage_item_classes]);
	
	const options = {
		responsive: true,
		plugins: {
		  legend: {
			position: 'top',
		  },
		  title: {
			display: true,
			font: {
				size: 17,
				family: "Arial",
			},
			color: "#106ba3",
			text: `Item Conditions (for Serialized and Has Expiry Inventories)`,
		  },
		},
		maintainAspectRatio: false
	};

	return (

		data.length>0 ? 
		<div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
			<Link
				to={{
					pathname: `/warehouse-inventory/item-conditions/?item_class=${item_class ? item_class.value : ''}`,
				}}
				style={{textDecoration:"none", pointerEvents:auth.active_company.application_id===null&&has_access ? "" : "none"}}
			>
				<Doughnut 
					data={{
						labels: labels,
						datasets: [
						  {
							label: 'Percentage %',
							data: data,
							backgroundColor: colors,
							borderColor: labels.map((lbl) => "grey"),
							borderWidth: 1,
						  },
						],
					}} 
					height="300px"
					width="300px"
					options={options}
				/>
			</Link>
		
			<div className='row'>
				<div className='col-sm-12 col-md-6 col-lg-5 mt-5'>
					<MuiAutocomplete
						setSelectedValue={setItemClass}
						selected_value={item_class}
						isMultiple={false}
						label={"Filter by Item Class"}
						data={item_classes}
						isDisabled={false}
					/>
				</div>
			</div>
		</div>
		: 
		<NoDataAvailable text={initialized ? "No Serialized and Has Expiry Inventories Yet." : (auth.active_company.application_id ? "System Implementation Ongoing" : "No Data Found")}/>

	);

}
