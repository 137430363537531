import React, {useRef} from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../features/auth/authSlice';
import { HotkeysProvider, HotkeysTarget2, Icon, Checkbox, Text } from "@blueprintjs/core";
import { Column, Table2, SelectionModes, Cell, EditableCell, ColumnHeaderCell, TableLoadingOption, RenderMode } from "@blueprintjs/table";
import { resources } from '../resources/index';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";

export function FinishedGoodComponentsSpreadsheet({enable_edit, enable_select, selected_rows, setSelectedRows, onEditButtonClicked, onClearButtonClicked, worksheet, setWorkSheet}) {
	const auth = useSelector(selectAuth);
	const workSheetTable = useRef(null);

	const onFocusedCell = (cell) => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set: worksheet.data_set
		})
	}

	const selectCell = (row, col) => {
		return (
			selected_rows && row <= worksheet.data_set.length-1
			?
			<Cell interactive={false} style={{textAlign:'center'}}>
				<Checkbox label="" checked={selected_rows.includes(row)} onChange={e => handleSelectButtonClickedHandler(row)} />
			</Cell>
			:
			<Cell interactive={false} style={{textAlign:'left'}}/>
		)
	}

	const handleSelectButtonClickedHandler = async (row) => {
		let selectedRows = [];
		if (!selected_rows.includes(row)) {
			selectedRows = selected_rows.map((selectedRow) => (selectedRow));
			selectedRows.push(row);
		}
		else {
			selectedRows = selected_rows.filter((selectedRow) => selectedRow!==row);
		}
		if (selectedRows.length>0 && onClearButtonClicked) {
			onClearButtonClicked();
		}
		setSelectedRows(selectedRows);
	}

	const confirm = async (value, row, col) => {
		let dataSet  = worksheet.data_set.map((ds) => ds);
		dataSet[row][col] = value;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set: dataSet
		});
	}

	const cell = (row, col) => {
		let cellValue = null;
		if (row < worksheet.data_set.length) {
			cellValue = worksheet.data_set[row][col];
			let jsonObject = null;
			try {
				jsonObject = JSON.parse(cellValue);
			}
			catch(e) {}
			if (Array.isArray(jsonObject)) {
				cellValue = jsonObject.map((obj, objKey) => (obj.label)).join(", ")
			}
		}
		let alignment = worksheet.is_readonly_mode ? worksheet.alignment[col] : "left";
		if (worksheet.is_readonly_mode) {
			return (
				<Cell interactive={false} style={{textAlign:alignment}}>
					{cellValue}
				</Cell>
			)
		}
		else {
			return (
				<EditableCell interactive={false} style={{textAlign:alignment}} value={cellValue} onConfirm={value => confirm(value, row, col)}/>
			)
		}
	}

	const editButtonClickHandler = async (row, col) => {
		setSelectedRows([]);
		onEditButtonClicked(row);
	}

	const editCell = (row, col) => {
		return (
			row <= worksheet.data_set.length-1 ?
			<Cell interactive={false} style={{textAlign:'center'}}>
				<div style={{width:'100%', cursor:"pointer"}} onClick={e => (editButtonClickHandler(row, col))}>
				<Icon
					icon="edit"
					size={15}
					className="orange"
				/>
				</div>
			</Cell>
			:
			<Cell interactive={false} style={{textAlign:'left'}}>
			</Cell>
		)
	}

	const nameRenderer = (name, index) => {
		return (
			<>
			    <Text ellipsize={true}>
			    	{
			    		!worksheet.is_readonly_mode && worksheet.comment[index]!==null ?
						<Icon
							intent="primary"
							icon="help"
							size={16}
						/> :
						null
			    	}
			    	{" "}
			    	{worksheet.header[index].toUpperCase()}
			    </Text>
		    </>
		)
	}

	const columnHeaderCellRenderer = (index) => {
		return (
			<ColumnHeaderCell
				name={worksheet.comment[index]}
				nameRenderer={nameRenderer}
			/>
		)
	}

	const renderColumns = () => {
		let headerColumns = worksheet.header.map(
			(header, headerKey) => (
            	<Column
            		key={headerKey}
            		name={header.toUpperCase()}
            		columnHeaderCellRenderer={columnHeaderCellRenderer}
            		cellRenderer={cell}
            	/>
			)
		)

		if (worksheet.is_readonly_mode) {
			if (!(enable_edit===false)) {
				headerColumns.push(<Column key={worksheet.header.length+1} name="EDIT / VIEW" cellRenderer={editCell} />);
			}
			if (!(enable_select===false)) {
				headerColumns.push(<Column key={worksheet.header.length+3} name="SELECT" cellRenderer={selectCell} />);
			}
		}
		return headerColumns;
	};

	const getCellClipboardData = (row, col) => {
		console.log(`copy row:${row} col:${col}`);
	}

	const onCopy = () => {
		console.log('onCopy');
	}

	const textAreaFocusHandler = (event) => {
		let textArea = document.getElementById("clipboard-textarea");
		textArea.textContent = "";
	}

	const onSelection = async (selectedRegions) => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: selectedRegions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set: worksheet.data_set
		});
	}

	const textAreaChangeHandler = (event) => {
		let clipboardData = event.target.value;
		if (!worksheet.is_readonly_mode) {
			if (worksheet.focused_cell!=null) {
				let offsetRow = worksheet.focused_cell.row;
				let offsetCol = worksheet.focused_cell.col;
				let rows = clipboardData.replace(/[^a-zA-Z0-9 _.,/:;@*()-=+><?}{!&%#$/(\r\n\t|\r|\n|\t)/]/g,'').split("\n");
				let dataSet = worksheet.data_set;
				let rowSize = dataSet.length>offsetRow+rows.length ? dataSet.length : offsetRow+rows.length;
				let colSize = worksheet.header.length;
				let data = [];
				for (let i=0; i<rowSize; i++) {
					let row = [];
					for (let j=0; j<colSize; j++) {
						if (dataSet[i]!==undefined) {
							if (dataSet[j]!==undefined) {
								row.push(dataSet[i][j]);
							}
							else {
								row.push("");
							}
						}
						else {
							row.push("");
						}
					}
					data.push(row);
				}
				for (let i=offsetRow; i<offsetRow+rows.length; i++) {
					let row = rows[i-offsetRow];
					let cells = row.split("\t");
					for (let j=offsetCol; j<offsetCol+cells.length; j++) {
						let cell = cells[j-offsetCol];
						//let cleanedCell = Util.cleanCell(cell);
						data[i][j] = cell;
					}
				}
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: data
				});
			}
		}
	}

	const pasteClipboardData = () => {
		let textArea = document.getElementById("clipboard-textarea");
		textArea.textContent = "";
		textArea.value = "";
		textArea.focus();
	}

    const hotkeys = [
        {
            combo: "mod+V",
            global: true,
            label: "Paste Data",
            onKeyDown: () => pasteClipboardData(),
        },
    ];

	return (

			<div style={{display:"flex", justifyContent:"center"}}>
				<textarea style={{opacity:0, position:"absolute"}} id="clipboard-textarea" onFocus={(event) => textAreaFocusHandler(event)} onChange={(event) => textAreaChangeHandler(event)}></textarea>
				<HotkeysProvider>
		            <HotkeysTarget2 hotkeys={hotkeys}>
		                {({ handleKeyDown, handleKeyUp }) => (
		                    <div tabIndex={0} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
		                    </div>
		                )}
		            </HotkeysTarget2>
					<Table2
						className="worksheet-table"
						ref={workSheetTable}
						loadingOptions={auth.status===resources.status.LOADING ? [TableLoadingOption.CELLS, TableLoadingOption.COLUMN_HEADERS, TableLoadingOption.ROW_HEADERS] : []}
						numRows={worksheet.num_rows}
						enableGhostCells={true}
						enableMultipleSelection={true}
						enableRowHeader={true}
						enableFocusedCell={true}
						renderMode={RenderMode.NONE}
						enableRowResizing={false}
						selectionModes={SelectionModes.ALL}
						numFrozenColumns={worksheet.num_frozen_columns}
						forceRerenderOnSelectionChange={false}
						getCellClipboardData={(row, col) => getCellClipboardData(row, col)}
						onCopy={() => onCopy()}
						onFocusedCell={(cell) => onFocusedCell(cell)}
						onSelection={(selectedRegions) => onSelection(selectedRegions)}
					>
						{renderColumns()}
					</Table2>
				</HotkeysProvider>
			</div>

	);
}
