import saveAs from "file-saver";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { resources } from "../resources/index";

class Excel {
    static export = (title, header, dataSet, active_company) => {
        var workbook = new ExcelJS.Workbook();
        var worksheet = workbook.addWorksheet(title);

        worksheet.columns = header.map((headerText) => ({
            header: headerText.toUpperCase(),
            width: 20,
        }));

        const rows = dataSet;
        worksheet.addRows(rows);

        worksheet.duplicateRow(1, 2, true);
        worksheet.getRow(1).values = [active_company ? active_company.name : ""];
        worksheet.mergeCells('A1:F1');
        worksheet.getRow(2).values = [title];
        worksheet.mergeCells('A2:F2');

        worksheet.getRow(1).font = { bold: true };
        worksheet.getRow(2).font = { bold: true };
        worksheet.getRow(3).font = { bold: true };
        worksheet.getRow(3).eachCell(function (cell, colNumber) {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "87CEEB" },
            };
        });

        worksheet.views = [
            {
                state: "frozen",
                ySplit: 3,
                activeCell: "C1",
            },
        ];

        workbook.creator = resources.app.NAME;
        workbook.lastModifiedBy = resources.app.NAME;
        workbook.created = new Date();
        workbook.modified = new Date();

        workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                `${title}.xlsx`
            );
        });
    };
}

export default Excel;