import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	detachAttachmentAsync
} from "../features/auth/authSlice";
import { resources } from "../resources/index";
import {
	Button,
	Card,
	ControlGroup,
	Collapse,
	FileInput,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import axios from "axios";

export function Attachment({id, transaction, setTransaction, attachments, setAttachments}) {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [is_attachments_open , setIsAttachmentsOpen] = useState(false);

	const handleAttachmentsButtonClickedEvent = () => {
		setIsAttachmentsOpen(!is_attachments_open);
	};

	const handleFilesAttachedEvent = (event) => {
		setAttachments(event.target.files);
	};

	const handleDetachAttachmentButtonClickResponse = (response) => {
		if (response.payload) {
			setTransaction(response.payload.data);
			setAttachments([]);
		}
	}
	const handleDetachAttachmentButtonClickEvent = (file_name) => {
		dispatch(
			detachAttachmentAsync({
				id:id,
				file_name:file_name,
				document_type:transaction.document_type.name
			})
		).then((response) => handleDetachAttachmentButtonClickResponse(response));
	};

	const handleDownloadAttachmentButtonClickEvent = (file_name, original_file_name) => {
		let url = (process.env.REACT_APP_PORT ? `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}` : `${process.env.REACT_APP_HOST}`)+`/api/documents/attachments/${file_name}/download`;
		axios({
			url: url,
			method: 'GET',
			responseType: 'blob',
			params: {document_type:transaction.document_type.name}
		  }).then((response) => {
			 const url = window.URL.createObjectURL(new Blob([response.data]));
			 const link = document.createElement('a');
			 link.href = url;
			 link.setAttribute('download', original_file_name); //or any other extension
			 document.body.appendChild(link);
			 link.click();
		  });
	};

	return (
		<div className="row">
			<Button style={{fontWeight:"bold", marginBottom:"2px"}} alignText="left" fill={true} onClick={handleAttachmentsButtonClickedEvent} rightIcon={is_attachments_open ? "caret-up" : "caret-down"}>{translate.text.ATTACHMENTS}</Button>
			<Collapse className="col-sm-12 col-md-12 col-lg-12" isOpen={is_attachments_open} keepChildrenMounted={true}>
				<Card style={{margin:"0px",padding:"4px",height:"120px",overflowY:"scroll"}}>
					{
						(id===null || (transaction && transaction.is_editable)) &&
						<FileInput style={{marginBottom:"5px"}} fill={true} text={attachments&&attachments.length>0 ? `${attachments.length} file/s attached` : "Select file/s to upload..."} inputProps={{ multiple: true }} onInputChange={handleFilesAttachedEvent}/>
					}
					{
						transaction && transaction.attachments &&
						transaction.attachments.map((attachment, key) => (
							<ControlGroup key={key} vertical={false} style={{alignItems:"center"}}>
								<Button disabled={auth.status===resources.status.LOADING} style={{marginLeft:"10px"}} icon={<Icon icon="download" className="orange"/>} small={true} minimal={true} onClick={e => handleDownloadAttachmentButtonClickEvent(attachment.file_name, attachment.original_file_name)}/>
								<div  style={{display:"inline-block", whiteSpace:"nowrap", fontSize:"10pt", marginLeft:"2px"}}>
									{attachment.original_file_name}
								</div>
								{
									transaction.is_editable &&
									<Button disabled={auth.status===resources.status.LOADING} style={{marginLeft:"10px"}} icon="cross" small={true} intent="danger" minimal={true} onClick={e => handleDetachAttachmentButtonClickEvent(attachment.file_name)}/>
								}
							</ControlGroup>
						))
					}
					{
						(transaction===null || (transaction && transaction.attachments===null) || (transaction && transaction.attachments.length===0)) &&
						<div style={{fontSize:"10pt",textAlign:"center"}}>{"No uploaded attachment/s."}</div>
					}
				</Card>
			</Collapse>
		</div>
	);
}
