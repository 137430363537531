import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
	selectAuth, 
	switchCompanyAsync, 
	toggleSidebarCollapsed, 
	resetWorksheetMode, 
	fetchAllProfitCostCentersAsync,
	fetchUserCompaniesAsync,
	selectApplication
} from './features/auth/authSlice';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { resources } from "./resources/index";
import { Icon, Tag, Divider } from "@blueprintjs/core";
import { ProSidebar, Menu as ReactMenu, MenuItem as ReactMenuItem, SubMenu } from 'react-pro-sidebar';
import './custom.scss';
import { useHistory } from "react-router-dom";
import { FaCartPlus, FaCogs, FaFilePowerpoint, FaTools, FaHome, FaQrcode, FaLock, FaRegStar } from 'react-icons/fa';
import { MuiAutocomplete } from "./common/MuiAutocomplete";
import default_company_logo from "./default_company_logo.png";
import Util from './common/Util';

export function SideBar() {

	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const [collapsed, setCollapsed] = useState(auth.is_sidebar_collapsed);
	const [branches, setBranches] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [projects, setProjects] = useState([]);
	const icons = [
		<FaCogs size={20} color="darkorange"/>, 
		<FaTools size={20} color="darkorange"/>, 
		<FaQrcode size={20} color="darkorange"/>,
		<FaCartPlus size={20} color="darkorange"/>, 
		<FaFilePowerpoint size={20} color="darkorange"/>,
	];
	const [companies, setCompanies] = useState(auth.companies.map((cmp) => ({label:`${cmp.code} - ${cmp.name}`, value:cmp.id})));
	const [active_company, setActiveCompany] = useState(companies.find((cmp) => cmp.value===auth.user.active_company_id));
	const [role_user, setRoleUser] = useState(auth.role_user.filter((roleUser) => (roleUser.user_id===auth.user.id)).map((roleUser) => roleUser.role_id));
	const [apps] = useState(auth.applications.filter((app) => (app.application_id===null && app.is_purchased)).map((app) => ({label:app.name, value:app.id})));
	const [selected_application, setSelectedApplication] = useState(apps.find((app) => (app.value===auth.selected_application.id)));

	useEffect(() => {
		setSelectedApplication(apps.find((app) => (app.value===auth.selected_application.id)));
	}, [auth.selected_application, apps]);

	useEffect(() => {
		if (!auth.is_sidebar_collapsed) {
			const handleFetchProfitCostCenterResponse = (response) => {
				if (response.payload.status===resources.status.SUCCESS) {
					setBranches(response.payload.data.branches.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))));
					setDepartments(response.payload.data.departments.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))));
					setProjects(response.payload.data.projects.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))));
				}
			}
			dispatch(fetchAllProfitCostCentersAsync({})).then((response) => handleFetchProfitCostCenterResponse(response));
			dispatch(fetchUserCompaniesAsync({}));
		}
	}, [dispatch, auth.is_sidebar_collapsed]);

	useEffect(() => {
		setCollapsed(auth.is_sidebar_collapsed);
	}, [auth.is_sidebar_collapsed]);

	useEffect(() => {
		setRoleUser(auth.role_user.filter((roleUser) => (roleUser.user_id===auth.user.id)).map((roleUser) => roleUser.role_id));
	}, [auth.role_user, auth.user]);

	useEffect(() => {
		let companies = auth.companies.map((cmp) => ({label:`${cmp.code} - ${cmp.name}`, value:cmp.id}));
		setCompanies(companies);
		setActiveCompany(companies.find((cmp) => cmp.value===auth.user.active_company_id));
	}, [auth.companies, auth.user.active_company_id]);

	const onMenuItemClicked = (route) => {
		dispatch(resetWorksheetMode());
		dispatch(toggleSidebarCollapsed());
		history.push(route);
	}

	const goToDashboard = () => {
		dispatch(resetWorksheetMode());
		dispatch(toggleSidebarCollapsed());
		window.location.href = auth.selected_application.route;
	}

	const onActiveCompanyChanged = (selectedValue) => {
		setActiveCompany(selectedValue);
		dispatch(switchCompanyAsync({user:auth.user, id:selectedValue.value})).then((response) => (response.payload.status===resources.status.SUCCESS ? goToDashboard() : void(0)));
	}

	const onSelectedApplicationChanged = (selectedValue) => {
		let app = auth.applications.find((app) => (app.id===selectedValue.value));
		setSelectedApplication(selectedValue);
		dispatch(selectApplication(app));
		window.location.href = app.route;
	}

	const renderApplicationMenus = () => {
		let modules = auth.applications.filter((application) => (application.is_enabled && application.application_id===auth.selected_application.id));
		let functions = [];
		let application_users = auth.application_user.filter((appUser) => appUser.user_id===auth.user.id).map((appUser) => appUser.application_id);
		for (let i=0; i<modules.length; i++) {
			let module = modules[i];
			//let fns = auth.applications.filter((application) => application.application_id===module.id && application_users.includes(application.id) && application.is_enabled);
			let fns = auth.applications.filter((application) => application.application_id===module.id && application_users.includes(application.id));
			functions[i] = fns;
		}
		return (
			<ReactMenu innerSubMenuArrows={true} subMenuBullets={false}>
				{
					<ReactMenuItem onClick={() => goToDashboard()} icon={<FaHome size={20} color="darkorange"/>}>Dashboard</ReactMenuItem>
				}
				{
					auth.selected_application && modules.map(
					(module, moduleKey) => (
						functions[moduleKey].length>0 ? 
						<SubMenu icon={icons[moduleKey]} key={moduleKey} title={module.name}>
							{
								functions[moduleKey].map(
									(menuItem, menuItemKey) => (
										menuItem.is_enabled ? 
										<ReactMenuItem icon={<FaRegStar size={15} color="darkorange"/>} className='react-menu-item' key={menuItemKey} onClick={() => onMenuItemClicked(menuItem.route)}>{menuItem.name}</ReactMenuItem> : 
										<ReactMenuItem disabled={true} icon={<FaLock size={15} color="grey"/>} className='react-menu-item-disabled' key={menuItemKey}>{menuItem.name}</ReactMenuItem>
									)
								)
							}
						</SubMenu> : 
						null
					))
				}
			</ReactMenu>
		);
	}

	return (
		!collapsed &&
			<ProSidebar
				onToggle={() => setCollapsed(!auth.is_sidebar_collapsed)}
				style={{
					position: "fixed",
					top: 50,
					left: 0,
					zIndex: 200,
					width: '100%'
				}}
				collapsed={collapsed}
			>
				<div style={{width:330, height:"100%", background:"lightblue", overflowY:"scroll"}}>
				{
					!collapsed &&
					<>

						<div style={{marginTop:"7px", minHeight:"135px"}}>
							<div style={{display:"flex", justifyContent:"center", width:"100%"}}>
								<img 
									alt={auth.active_company.logo_source} 
									src={auth.active_company.logo_source}
									onError={(e) => {
										e.target.src = default_company_logo
									}}
									style={{margin:'10px', backgroundColor:"transparent"}}
								/>
							</div>
						</div>
						<div className='center blue bold'>
							<span>{auth.user.email}</span>
						</div>

						{
							auth.user.is_admin===0 &&
							<div style={{minHeight:"135px"}}>

								<div className="app-company-selector">
									<MuiAutocomplete
										setSelectedValue={(selectedValue) => onActiveCompanyChanged(selectedValue)}
										selected_value={active_company}
										isMultiple={false}
										data={companies}
									/>
								</div>

								<div style={{marginLeft:12}}>
									{departments.filter((department) => auth.user_department_ids.includes(department.id)).map(
										(department, departmentKey) => (
											<Tag
												key={departmentKey}
												className="profit-cost-center-tag"
												intent={"warning"}
												minimal={true}
												round={true}
												icon={!collapsed ? null : <Icon  icon="locate"/>}
											>
												{department.name}
											</Tag>
										)
									)}
								</div>
								<div style={{marginLeft:12}}>
									{branches.filter((branch) => auth.user_branch_ids.includes(branch.id)).map(
										(branch, branchKey) => (
											<Tag
												key={branchKey}
												className="profit-cost-center-tag"
												intent={"warning"}
												minimal={true}
												round={true}
												icon={!collapsed ? null : <Icon  icon="locate"/>}
											>
												{branch.name}
											</Tag>							
										)
									)}
								</div>
								<div style={{marginLeft:12}}>
									{projects.filter((project) => auth.user_project_ids.includes(project.id)).map(
										(project, projectKey) => (
											<Tag
												key={projectKey}
												className="profit-cost-center-tag"
												intent={"warning"}
												minimal={true}
												round={true}
												icon={!collapsed ? null : <Icon  icon="locate"/>}
											>
												{project.name}
											</Tag>
										)
									)}
								</div>
							</div>
						}

					</>
				}

				{
					auth.user.is_admin===0 &&
					<>
						<div style={{marginLeft:12}}>
							{
								!collapsed &&
								auth.roles.filter((role) => (role_user.includes(role.id))).map((role, key) => (
									<Tag
										key={key}
										icon={!collapsed ? null : <Icon  icon="hat"/>}
										style={{margin:2, fontWeight:"bold", color:"black"}}
										minimal={true}
									>
										{collapsed ? null : role.name}
									</Tag>
								))
							}
						</div>
						<Divider style={{marginTop:20}}/>
					</>
				}

				<div className="app-selector">
					<MuiAutocomplete
						setSelectedValue={(selectedValue) => onSelectedApplicationChanged(selectedValue)}
						selected_value={selected_application}
						isMultiple={false}
						data={apps}
						//isDisabled={auth.active_company.application_id!==null}
						label={"Select Module"}
					/>
				</div>

				<div style={{marginBottom:50}}>
					{
						active_company && renderApplicationMenus()
					}
				</div>
				
				</div>
				
			</ProSidebar>


	);

}
