import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { useParams } from "react-router";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createStockAcceptanceAsync,
	updateStockAcceptanceAsync,
	submitStockAcceptanceAsync,
	rejectStockAcceptanceAsync,
	approveStockAcceptanceAsync,
	voidStockAcceptanceAsync,
	fetchAllStockAcceptanceLineItemsAsync,
	toggleSidebarCollapsed,
	fetchAllItemsAsync,
	fetchAllLocationsAsync,
	fetchAllProfitCostCentersAsync,
	fetchAllStockIssuanceLineItemsAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	ControlGroup,
	H3,
	H5,
	FormGroup,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { DatatableDialog } from "../../../common/DatatableDialog";
import { StockAcceptanceLineItem } from "./StockAcceptanceLineItem";
import { SideBar } from '../../../SideBar';
import { Attachment } from "../../../common/Attachment";
import { Comment } from "../../../common/Comment";
import { TransactionLog } from "../../../common/TransactionLog";
import { useHistory } from "react-router-dom";
import { StockAcceptanceSpreadsheet } from "../../../spreadsheets/StockAcceptanceSpreadsheet";

export function StockAcceptance() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const default_line_items = Array(7).fill({
		id:"",
		item:null,
		requested_quantity:"",
		issued_quantity:"",
		cancelled_quantity:"",
		quantity:"",
		cancel_quantity:"",
		unit_cost:"",
		amount:"",
		uom:null,
		to_location:null,
		serial_numbers:[],
		expiry_quantities:[],
		expiry_dates:[],
		item_conditions:[],
	});
	const default_journal_entries = Array(2).fill({
		account_code:"",
		account_description:"",
		profit_cost_center:null,
		debit_amount:"",
		credit_amount:"",
	});
	const { document_id } = useParams();
	const [id, setId] = useState(document_id ? document_id : null);
	const [selected_document_number, setSelectedDocumentNumber] = useState('');
	const [selected_document_ids, setSelectedDocumentIds] = useState([]);
	const [stock_acceptance, setStockAcceptance] = useState(null);
	const [line_items, setLineItems] = useState(default_line_items);
	const [document_date, setDocumentDate] = useState(stock_acceptance ? moment(stock_acceptance.document_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT) : moment(new Date()).format(resources.date_format.INPUT));
	const [reference_number, setReferenceNumber] = useState("");
	const [transfer_to, setTransferTo] = useState(null);
	const [transfer_from, setTransferFrom] = useState(null);
	const [transaction_description, setTransactionDescription] = useState("");
	const [transaction_logs, setTransactionLogs] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [transaction_sources] = useState(Object.values(auth.enums.transaction_sources.stock_acceptance).map((transactionSource) => ({label:transactionSource, value:transactionSource})));
	const [transaction_source , setTransactionSource] = useState(null);
	const [profit_cost_centers, setProfitCostCenters] = useState([]);
	const [items, setItems] = useState([]);
	const [locations, setLocations] = useState([]);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_delete_dialog_open, setIsConfirmDeleteDialogOpen] = useState(false);
	const [is_confirm_submit_dialog_open, setIsConfirmSubmitDialogOpen] = useState(false);
	const [is_confirm_approve_dialog_open, setIsConfirmApproveDialogOpen] = useState(false);
	const [is_confirm_reject_dialog_open, setIsConfirmRejectDialogOpen] = useState(false);
	const [is_confirm_void_dialog_open, setIsConfirmVoidDialogOpen] = useState(false);
	const [is_datatable_open, setIsDatatableDialogOpen] = useState(false);
	const [stock_issuance_transaction_source, setStockIssuanceTransactionSource] = useState(null);
	const [finished_goods_to_produce, setFinishedGoodsToProduce] = useState("");
	const [journal_entries, setJournalEntries] = useState(default_journal_entries);
	const [balancing_account, setBalancingAccount] = useState("");
	const [is_editable, setIsEditable] = useState(true);
	const [is_authorizable, setIsAuthorizable] = useState(document_id ? auth.approval_pipelines.find((pipe) => pipe.stock_acceptance_id===document_id)!==undefined : false);

	const [with_expiry_serialized, setHasExpirySerialized] = useState(false);
	const [with_expiry, setWithExpiry] = useState(false);
	const [serialized, setSerialized] = useState(false);

	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: translate.worksheet.default_header.STOCK_ACCEPTANCE,
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const loadDocument = useCallback((stockAcceptance, stockAcceptanceLineItems, journalEntries) => {
		setStockAcceptance(stockAcceptance);
		setId(stockAcceptance.id);
		setReferenceNumber(stockAcceptance.reference_number);
		setTransactionSource(transaction_sources.find((ts) => ts.value===stockAcceptance.source));
		setTransactionLogs(stockAcceptance.transaction_logs);
		setTransactionDescription(stockAcceptance.description);
		setIsEditable(stockAcceptance.is_editable);
		setIsAuthorizable(stockAcceptance.id ? auth.approval_pipelines.find((pipe) => pipe.stock_acceptance_id===stockAcceptance.id)!==undefined : false);
		if (stockAcceptance.source===auth.enums.transaction_sources.stock_acceptance.STOCK_ISSUANCE) {
			let documentSources = stockAcceptance.stock_issuances;
			setSelectedDocumentIds(documentSources.map((documentSource) => documentSource.id));
			setSelectedDocumentNumber(documentSources.map((documentSource) => documentSource.document_number).join(", "));
		}
		if (stockAcceptance.source===auth.enums.transaction_sources.stock_acceptance.STOCK_PURCHASE_REQUEST) {
			let documentSources = stockAcceptance.stock_requests;
			setSelectedDocumentIds(documentSources.map((documentSource) => documentSource.id));
			setSelectedDocumentNumber(documentSources.map((documentSource) => documentSource.document_number).join(", "));
		}

		switch(stockAcceptance.source) {
			case auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT : {
				setBalancingAccount(auth.enums.balancing_accounts.stock_acceptance.DIRECT_RECEIPT);
				break;
			}
			case auth.enums.transaction_sources.stock_acceptance.STOCK_ISSUANCE : {
				setBalancingAccount(auth.enums.balancing_accounts.stock_acceptance.STOCK_ISSUANCE);
				break;
			}
			case auth.enums.transaction_sources.stock_acceptance.STOCK_PURCHASE_REQUEST : {
				setBalancingAccount(auth.enums.balancing_accounts.stock_acceptance.STOCK_PURCHASE_REQUEST);
				break;
			}
			default : break;
		}
		setJournalEntries(journalEntries.map((journalEntry) => ({
			account_code:journalEntry.account.code,
			account_description:journalEntry.account.description,
			profit_cost_center:stockAcceptance.transfer_to ? JSON.parse(stockAcceptance.transfer_to) : null,
			debit_amount:journalEntry.debit_amount,
			credit_amount:journalEntry.credit_amount,
		})));
		setTransferFrom(stockAcceptance.transfer_from ? profit_cost_centers.find((pcc) => (pcc.value===JSON.parse(stockAcceptance.transfer_from).value)) : null);
		setTransferTo(stockAcceptance.transfer_to ? profit_cost_centers.find((pcc) => (pcc.value===JSON.parse(stockAcceptance.transfer_to).value)) : null);
		setLineItems( stockAcceptanceLineItems.map((stockAcceptanceLineItem) => ({
			id:stockAcceptanceLineItem.id,
			item:{
				label:stockAcceptanceLineItem.item.label, 
				default_uom_name:stockAcceptanceLineItem.item.unit_of_measure.name, 
				item_costing:stockAcceptanceLineItem.item.item_costing, 
				serial_number_type:stockAcceptanceLineItem.item.serial_number_type, 
				value:stockAcceptanceLineItem.item.id
			},
			requested_quantity:stockAcceptanceLineItem.requested_quantity,
			issued_quantity:stockAcceptanceLineItem.issued_quantity,
			cancelled_quantity:stockAcceptanceLineItem.cancelled_quantity,
			quantity:stockAcceptanceLineItem.quantity,
			cancel_quantity:stockAcceptanceLineItem.cancel_quantity,
			unit_cost:stockAcceptanceLineItem.unit_cost,
			amount:stockAcceptanceLineItem.amount,
			uom:{label:stockAcceptanceLineItem.unit_of_measure.code, value:stockAcceptanceLineItem.unit_of_measure.id},
			to_location:{label:`${stockAcceptanceLineItem.location.code} | ${stockAcceptanceLineItem.location.name}`, value:stockAcceptanceLineItem.location.id},
			serial_numbers:stockAcceptanceLineItem.serial_numbers,
			expiry_dates:stockAcceptanceLineItem.expiry_dates.map((expDate) => (expDate.length>0 ? moment(expDate, resources.date_format.SYSTEM).format(resources.date_format.INPUT) : "")),
			expiry_quantities:stockAcceptanceLineItem.expiry_quantities.map((expQty) => (expQty.length>0 ? parseFloat(expQty) : 0)),
			item_conditions:stockAcceptanceLineItem.item_conditions,
		})) );
		setHasExpirySerialized(false);
		setWithExpiry(false);
		setSerialized(false);
		for (let i=0; i<stockAcceptanceLineItems.length; i++) {
			let stockAcceptanceLineItem = stockAcceptanceLineItems[i];
			if (stockAcceptance.approval_status===auth.enums.approval_status.DRAFT) {
				let inventoryItem = items.find((itm) => itm.id===stockAcceptanceLineItem.item.id);
				if (inventoryItem) {
					let hasExpiry = Util.stringToBoolean(inventoryItem.has_expiry);
					let withSerialNumber = Util.stringToBoolean(inventoryItem.with_serial_number);
					if (hasExpiry && withSerialNumber) {
						setHasExpirySerialized(true);
					} else 
					if (withSerialNumber) {
						if (inventoryItem.serial_number_type===auth.enums.serial_number_types.EXTERNAL) setSerialized(true);
					} else 
					if (hasExpiry) {
						setWithExpiry(true);
					}
				}
			}
		}
	},[auth.enums.serial_number_types.EXTERNAL, auth.enums.transaction_sources, transaction_sources, auth.enums.balancing_accounts.stock_acceptance, auth.approval_pipelines, auth.enums.approval_status.DRAFT, items, profit_cost_centers]);

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.STOCK_ACCEPTANCE));
	}, [
		dispatch,
		translate.breadcrumb.STOCK_ACCEPTANCE
	]);

	useEffect(() => {
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllItemsAsync({})).then((response) => response.payload.data ? setItems(response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)))) : void(0));
			dispatch(fetchAllLocationsAsync({})).then((response) => response.payload.data ? setLocations(response.payload.data.locations) : void(0));
			const handleFetchProfitCostCenterResponse = (response) => {
				if (response.payload.data) {
					let branches = response.payload.data.branches.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((branch) => ({label:`${branch.code} | ${branch.name}`, type:branch.type, value:branch.id}));
					let departments = response.payload.data.departments.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((department) => ({label:`${department.code} | ${department.name}`, type:department.type, value:department.id}));
					let projects = response.payload.data.projects.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((project) => ({label:`${project.code} | ${project.name}`, type:project.type, value:project.id}));
					setProfitCostCenters(branches.concat(departments).concat(projects));
				}
			}
			dispatch(fetchAllProfitCostCentersAsync({})).then((response) => handleFetchProfitCostCenterResponse(response));
		}
	}, [
		dispatch,
		worksheet.is_readonly_mode,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const refreshData = () => {
		handleClearButtonClickedEvent();
	}

	useEffect(() => {
		if (id) {
			dispatch(fetchAllStockAcceptanceLineItemsAsync({stock_acceptance_id:id})).then((response) => loadDocument(response.payload.data.stock_acceptance, response.payload.data.stock_acceptance_line_items, response.payload.data.journal_entries));
		}
	}, [dispatch, id, loadDocument]);

	const handleClearButtonClickedEvent = () => {
		setId(null);
		setStockAcceptance(null);
		setDocumentDate(moment(new Date()).format(resources.date_format.INPUT));
		setReferenceNumber("");
		setTransactionDescription("");
		setStockIssuanceTransactionSource(null);
		setTransferFrom(null);
		setTransferTo(null);
		setTransactionSource(null);
		setSelectedDocumentNumber("");
		setSelectedDocumentIds([]);
		setLineItems(default_line_items);
		setJournalEntries(default_journal_entries);
		setBalancingAccount("");
		setAttachments([]);
		setIsEditable(true);
		setIsAuthorizable(false);
		setSerialized(false);
		setWithExpiry(false);
		setHasExpirySerialized(false);
		history.push('/warehouse-inventory/stock-acceptance');
	};

	const handleDeleteRowsButtonClickedEvent = () => {
		let rows = selected_rows.map(
			(row) => worksheet.data_set[row]
		);
		let promises = [];
		for (let i = 0; i < rows.length; i++) {
			promises.push(
				dispatch(
					voidStockAcceptanceAsync({
						id: rows[i][0]
					})
				)
			);
		}
		Promise.all(promises).then((response) => {
			var success = true;
			var message = null;
			for (let i = 0; i < response.length; i++) {
				if (response[i].payload !== undefined) {
					if (response[i].payload.status !== resources.status.SUCCESS) {
						success = false;
						break;
					}
					else {
						if (message == null) {
							message = response[i].payload.message;
						}
					}
				}
			}
			if (success === true) {
				toast.success(
					message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			handleClearButtonClickedEvent();
		});
	};

	const handleSubmitButtonClickedEvent = () => {
		dispatch(
			submitStockAcceptanceAsync({
				id:id,
				document_date:document_date,
				selected_document_ids:selected_document_ids,
				reference_number:reference_number,
				transaction_source:transaction_source,
				transaction_description:transaction_description,
				transfer_from:transfer_from,
				transfer_to:transfer_to,
				line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.cancelled_quantity.length>0||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.to_location!==null)),
				attachments:attachments,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleApproveButtonClickedEvent = () => {
		dispatch(
			approveStockAcceptanceAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleRejectButtonClickedEvent = () => {
		dispatch(
			rejectStockAcceptanceAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleVoidButtonClickedEvent = () => {
		dispatch(
			voidStockAcceptanceAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleEditButtonClickedEvent = (row) => {
		let id = worksheet.data_set[row][0];
		setId(id);
		window.scrollTo(0,0);
	};

	const handleCreateButtonClickedResponse = (response) => {
		let doc = response.payload.data.stock_acceptance;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:[
				[
					doc.id,
					doc.document_number,
					doc.document_date,
					doc.approval_status,
					doc.reference_number,
					doc.source,
					doc.stock_issuances.length>0 ? doc.stock_issuances[0].source : "",
					doc.description,
					doc.transfer_to ? JSON.parse(doc.transfer_to).label : "",
					doc.transfer_to ? JSON.parse(doc.transfer_to).type : "",
				],
				...worksheet.data_set
			]
		});
		loadDocument(response.payload.data.stock_acceptance, response.payload.data.stock_acceptance_line_items, response.payload.data.journal_entries);
		setAttachments([]);
	}

	const handleUpdateButtonClickedResponse = (response) => {
		let doc = response.payload.data.stock_acceptance;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.stock_acceptance.id ? [
				doc.id,
				doc.document_number,
				doc.document_date,
				doc.approval_status,
				doc.reference_number,
				doc.source,
				doc.stock_issuances.length>0 ? doc.stock_issuances[0].source : "",
				doc.description,
				doc.transfer_to ? JSON.parse(doc.transfer_to).label : "",
				doc.transfer_to ? JSON.parse(doc.transfer_to).type : "",
			] : ds))
		});
		loadDocument(response.payload.data.stock_acceptance, response.payload.data.stock_acceptance_line_items, response.payload.data.journal_entries);
		setAttachments([]);
	}

	const handleProcessSelectedDocuments = (selectionObject) => {
		Util.sortByKey(selectionObject.selectedRows, 'id');
		let ERROR = null;
		let requestingUnit = null;
		let issuingUnit = null;
		let selected_ids = [];
		let selected_document_numbers = [];
		let selected_descriptions = [];
		let selected_reference_numbers = [];
		let selected_line_item_indices = [];
		let selected_line_items = [];
		for (let i=0; i<selectionObject.selectedRows.length; i++) {
			if (transaction_source.value===auth.enums.transaction_sources.stock_acceptance.STOCK_ISSUANCE) {
				setStockIssuanceTransactionSource(selectionObject.selectedRows[i].transaction_source);
				setFinishedGoodsToProduce(selectionObject.selectedRows[i].finished_goods_to_produce);
				if (!selected_ids.includes(selectionObject.selectedRows[i].id)) selected_ids.push(selectionObject.selectedRows[i].id);
				if (!selected_document_numbers.includes(selectionObject.selectedRows[i].document_number)) selected_document_numbers.push(selectionObject.selectedRows[i].document_number);
				if (!selected_descriptions.includes(selectionObject.selectedRows[i].transaction_description)) selected_descriptions.push(selectionObject.selectedRows[i].transaction_description);
				if (!selected_reference_numbers.includes(selectionObject.selectedRows[i].reference_number)) selected_reference_numbers.push(selectionObject.selectedRows[i].reference_number);
				if (requestingUnit!==null) {
					if (requestingUnit!==selectionObject.selectedRows[i].transfer_to) {
						ERROR = translate.message.CONSOLIDATION_MUST_BELONG_TO_SAME_UNIT;
						break;
					}
				}
				else {
					requestingUnit = selectionObject.selectedRows[i].transfer_to;
				}
				if (issuingUnit!==null) {
					if (issuingUnit!==selectionObject.selectedRows[i].transfer_from) {
						ERROR = translate.message.CONSOLIDATION_MUST_BELONG_TO_SAME_UNIT;
						break;
					}
				}
				else {
					issuingUnit = selectionObject.selectedRows[i].transfer_from;
				}
				const handleResponse = (response) => {
					let forAcceptanceLineItems = response.payload.data.stock_issuance_line_items;
					let lineItems = forAcceptanceLineItems.map((forAcceptanceLineItem) => ({
						id:null,
						item:{value:forAcceptanceLineItem.item.id, label:`${forAcceptanceLineItem.item.code} | ${forAcceptanceLineItem.item.description}`},
						requested_quantity:forAcceptanceLineItem.requested_quantity,
						issued_quantity:forAcceptanceLineItem.quantity,
						cancelled_quantity:forAcceptanceLineItem.cancel_quantity,
						quantity:forAcceptanceLineItem.quantity,
						cancel_quantity:0,
						unit_cost:forAcceptanceLineItem.unit_cost,
						amount:forAcceptanceLineItem.amount,
						uom:{value:forAcceptanceLineItem.unit_of_measure.id, label:forAcceptanceLineItem.unit_of_measure.code},
						to_location:null,
						serial_numbers:[],
						expiry_quantities:[],
						expiry_dates:[],
						item_conditions:[],
					}));
					for (let j=0; j<lineItems.length; j++) {
						if (selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`]===undefined) {
							selected_line_items.push(lineItems[j]);
							selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`] = selected_line_items.length-1;
						}
						else {
							let index = selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`];
							let selectedLineItem = selected_line_items[index];
							selectedLineItem.requested_quantity = parseFloat(selectedLineItem.requested_quantity).toFixed(2);
							selectedLineItem.issued_quantity = (parseFloat(selectedLineItem.issued_quantity) + parseFloat(lineItems[j].issued_quantity)).toFixed(2);
							selectedLineItem.cancelled_quantity = (parseFloat(selectedLineItem.cancelled_quantity) + parseFloat(lineItems[j].cancelled_quantity)).toFixed(2);
							selected_line_items[index] = selectedLineItem;
						}
					}
					setLineItems(selected_line_items);
				}
				dispatch(fetchAllStockIssuanceLineItemsAsync({stock_issuance_id:selectionObject.selectedRows[i].id})).then((response) => handleResponse(response));
			}
			else if (transaction_source.value===auth.enums.transaction_sources.stock_acceptance.STOCK_PURCHASE_REQUEST) {
				if (!selected_ids.includes(selectionObject.selectedRows[i].id)) selected_ids.push(selectionObject.selectedRows[i].id);
				if (!selected_document_numbers.includes(selectionObject.selectedRows[i].document_number)) selected_document_numbers.push(selectionObject.selectedRows[i].document_number);
				if (!selected_descriptions.includes(selectionObject.selectedRows[i].transaction_description)) selected_descriptions.push(selectionObject.selectedRows[i].transaction_description);
				if (!selected_reference_numbers.includes(selectionObject.selectedRows[i].reference_number)) selected_reference_numbers.push(selectionObject.selectedRows[i].reference_number);
				if (requestingUnit!==null) {
					if (requestingUnit!==selectionObject.selectedRows[i].requesting_unit) {
						ERROR = translate.message.CONSOLIDATION_MUST_BELONG_TO_SAME_REQUESTING_UNIT;
						break;
					}
				}
				else {
					requestingUnit = selectionObject.selectedRows[i].requesting_unit;
				}
				let forAcceptanceLineItems = auth.stock_request_line_items.filter((stockRequestLineItem) => (stockRequestLineItem.stock_request_id===selectionObject.selectedRows[i].id));
				let lineItems = forAcceptanceLineItems.map((forAcceptanceLineItem) => ({
					id:null,
					item:{value:forAcceptanceLineItem.item.id, label:`${forAcceptanceLineItem.item.code} | ${forAcceptanceLineItem.item.description} | ${forAcceptanceLineItem.item.item_class.name}`},
					requested_quantity:forAcceptanceLineItem.quantity,
					issued_quantity:forAcceptanceLineItem.quantity-forAcceptanceLineItem.accepted_quantity-forAcceptanceLineItem.cancelled_quantity,
					cancelled_quantity:forAcceptanceLineItem.cancelled_quantity,
					quantity:0,
					cancel_quantity:0,
					unit_cost:0,
					amount:0,
					uom:{value:forAcceptanceLineItem.unit_of_measure.id, label:forAcceptanceLineItem.unit_of_measure.code},
					to_location:null,
					serial_numbers:[],
					expiry_dates:[],
					item_conditions:[],
				}))
				for (let j=0; j<lineItems.length; j++) {
					if (selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`]===undefined) {
						selected_line_items.push(lineItems[j]);
						selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`] = selected_line_items.length-1;
					}
					else {
						let index = selected_line_item_indices[`${lineItems[j].item.value}.${lineItems[j].uom.value}`];
						let selectedLineItem = selected_line_items[index];
						selectedLineItem.requested_quantity = parseFloat(selectedLineItem.requested_quantity).toFixed(2);
						selectedLineItem.issued_quantity = (parseFloat(selectedLineItem.issued_quantity) + parseFloat(lineItems[j].issued_quantity)).toFixed(2);
						selectedLineItem.cancelled_quantity = (parseFloat(selectedLineItem.cancelled_quantity) + parseFloat(lineItems[j].cancelled_quantity)).toFixed(2);
						selected_line_items[index] = selectedLineItem;
					}
				}
			}
		}
		
		if (ERROR) {
			toast.error(
				ERROR,
				Util.getToasterStyle(resources.status.ERROR)
			);
		}
		else {
			setSelectedDocumentIds(selected_ids);
			setSelectedDocumentNumber(selected_document_numbers.join(", "));
			setReferenceNumber(selected_reference_numbers.join(", "));
			setTransactionDescription(selected_descriptions.join(", "));
			if (transaction_source.value===auth.enums.transaction_sources.stock_acceptance.STOCK_ISSUANCE) {
				setTransferTo(profit_cost_centers.find((pcc) => (pcc.label.indexOf(requestingUnit)>=0)));
				setTransferFrom(profit_cost_centers.find((pcc) => (pcc.label.indexOf(issuingUnit)>=0)));
			}
			if (transaction_source.value===auth.enums.transaction_sources.stock_acceptance.STOCK_PURCHASE_REQUEST) {
				let stockPurchaseRequest = auth.stock_requests.filter((stockRequest) => (selected_ids.includes(stockRequest.id)))[0];
				if (stockPurchaseRequest && stockPurchaseRequest.branch.id) {
					setTransferTo({label:stockPurchaseRequest.branch.name, type:auth.enums.profit_cost_center_types.BRANCH, value:stockPurchaseRequest.branch.name.id});
				}
				if (stockPurchaseRequest && stockPurchaseRequest.department.id) {
					setTransferTo({label:stockPurchaseRequest.department.name, type:auth.enums.profit_cost_center_types.DEPARTMENT, value:stockPurchaseRequest.department.name.id});
				}
				if (stockPurchaseRequest && stockPurchaseRequest.project.id) {
					setTransferTo({label:stockPurchaseRequest.project.name, type:auth.enums.profit_cost_center_types.PROJECT, value:stockPurchaseRequest.project.name.id});
				}
			}
			setIsDatatableDialogOpen(false);
		}
	};

	const handleTransactionSourceChangedEvent = (transactionSource) => {
		setTransactionSource(transactionSource);
		switch(transactionSource.value) {
			case auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT : {
				setBalancingAccount(auth.enums.balancing_accounts.stock_acceptance.DIRECT_RECEIPT);
				break;
			}
			case auth.enums.transaction_sources.stock_acceptance.STOCK_ISSUANCE : {
				setBalancingAccount(auth.enums.balancing_accounts.stock_acceptance.STOCK_ISSUANCE);
				break;
			}
			case auth.enums.transaction_sources.stock_acceptance.STOCK_PURCHASE_REQUEST : {
				setBalancingAccount(auth.enums.balancing_accounts.stock_acceptance.STOCK_PURCHASE_REQUEST);
				break;
			}
			default : break;
		}
	}

	const handleTransferToChangedEvent = (transferTo) => {
		setTransferTo(transferTo);
        setJournalEntries(journal_entries.map((journalEntry,key) => (
        {
            account_code:journalEntry.account_code,
            account_description:journalEntry.account_description,
            profit_cost_center:journalEntry.account_code ? transferTo : journalEntry.profit_cost_center,
            debit_amount:journalEntry.debit_amount,
            credit_amount:journalEntry.credit_amount,
        })));
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{ Util.functionTitle(translate.text.STOCK_ACCEPTANCE) }
				<div className="function-sub-title">
					{translate.text.STOCK_ACCEPTANCE_SUBTITLE}
				</div>

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						{
							id===null ?
							<>
								<Button
									text={translate.button.CREATE_DRAFT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING||!is_editable}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={(e) =>
										dispatch(
											createStockAcceptanceAsync({
												document_date:document_date,
												selected_document_ids:selected_document_ids,
												reference_number:reference_number,
												transaction_source:transaction_source,
												transaction_description:transaction_description,
												transfer_from:transfer_from,
												transfer_to:transfer_to,
												line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.to_location!==null)),
												attachments:attachments,
											})
										).then((response) => (response.payload.status===resources.status.SUCCESS ? handleCreateButtonClickedResponse(response) : null))
									}
								/>
							</>
							:
							<>
								{
									is_editable &&
									<Button
										text={translate.button.UPDATE}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="floppy-disk" className="orange"/>}
										onClick={(e) =>
											dispatch(
												updateStockAcceptanceAsync({
													id:id,
													document_date:document_date,
													selected_document_ids:selected_document_ids,
													reference_number:reference_number,
													transaction_source:transaction_source,
													transaction_description:transaction_description,
													transfer_from:transfer_from,
													transfer_to:transfer_to,
													line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.cancelled_quantity.length>0||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.to_location!==null)),
													attachments:attachments,
												})
											).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateButtonClickedResponse(response) : null))
										}
									/>
								}
								{
									is_editable &&
									<Button
										text={translate.button.SUBMIT}
										className="function-button"
										disabled={auth.status === resources.status.LOADING||!is_editable}
										icon={<Icon icon="send-message" className="orange"/>}
										onClick={e => setIsConfirmSubmitDialogOpen(!is_confirm_submit_dialog_open)}
									/>
								}
								<Button
									text={translate.button.PRINT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="print" className="orange"/>}
									onClick={() =>
										PDF.exportStockAcceptanceForm(
											translate.worksheet.file_name.STOCK_ACCEPTANCE,
											[stock_acceptance],
											line_items,
											auth.active_company
										)
									}
								/>
								{
									is_editable &&
									<Button
										text={translate.button.VOID}
										className="function-button"
										disabled={auth.status === resources.status.LOADING||!is_editable}
										icon={<Icon icon="ban-circle" className="orange"/>}
										onClick={e => setIsConfirmVoidDialogOpen(!is_confirm_void_dialog_open)}
									/>
								}
								{
									stock_acceptance.approval_status===auth.enums.approval_status.FOR_APPROVAL && is_authorizable &&
									<>
										<Button
											text={translate.button.APPROVE}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="tick" className="orange"/>}
											onClick={e => setIsConfirmApproveDialogOpen(!is_confirm_approve_dialog_open)}
										/>
										<Button
											text={translate.button.REJECT}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="cross" className="orange"/>}
											onClick={e => setIsConfirmRejectDialogOpen(!is_confirm_reject_dialog_open)}
										/>
									</>
								}
							</>
						}
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleClearButtonClickedEvent}
						/>
					</ButtonGroup>
				}

				{
					(with_expiry_serialized || serialized || with_expiry) &&
					<div style={{textAlign:"center", marginBottom:20, color:"blue"}}>
						{
							with_expiry_serialized &&
							<div>
								Your next step is to assign expiry date/s to corresponding serial number/s.
							</div>
						}
						{
							serialized &&
							<div>
								Your next step is to assign serial number/s to corresponding quantity.
							</div>
						}
						{
							with_expiry &&
							<div>
								Your next step is to assign expiry date to each quantity.
							</div> 
						}
					</div>
				}

				{
					worksheet.is_readonly_mode &&
					<div className="function-card">
						<div className="row">
							<Card
								className="col-sm-12 col-md-4 col-lg-3"
								style={{
									background: "lightblue",
								}}
							>
								<FormGroup>
									<H3 className="center" style={{color:"blue"}}>{stock_acceptance!==null ? stock_acceptance.document_number : null}</H3>
									<H5 className="center" style={{color:"green"}}>{stock_acceptance!==null ? stock_acceptance.approval_status : null}</H5>
								</FormGroup>

								<div className="row mb-5">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										id="document_date"
										label={translate.placeholder.PICK_A_DATE}
										type="date"
										variant="outlined"
										size="small"
										defaultValue={document_date}
										inputProps={{readOnly:!is_editable}}
										onChange={(e) => setDocumentDate(e.target.value)}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={reference_number}
										label={translate.placeholder.ENTER_TRANSACTION_REFERENCE_NUMBER}
										onChange={(e) =>
											setReferenceNumber(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
						        </div>

								<div className="row mb-5 mt-5">
									<div className={"col-sm-12 col-md-12 col-lg-12"}>
										<MuiAutocomplete
											isDisabled={id!==null||!is_editable}
											setSelectedValue={handleTransactionSourceChangedEvent}
											selected_value={transaction_source}
											isMultiple={false}
											label={translate.placeholder.SELECT_TRANSACTION_SOURCE}
											data={transaction_sources}
										/>
									</div>
								</div>

								{
									transaction_source && transaction_source.value!==auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT &&
									<div className={"col-sm-12 col-md-12 col-lg-12"}>
										<ControlGroup vertical={false}>
											<TextField
												inputProps={{readOnly:true}}
												size="small"
												variant="outlined"
												value={selected_document_number}
												label={"Search a document"}
												style={{width:"80%"}}
											/>
											<Button
												minimal={true}
												large={true}
												text={translate.button.SEARCH}
												icon={<Icon icon="search" className="orange"/>}
												disabled={auth.status === resources.status.LOADING||id!==null}
												onClick={e => setIsDatatableDialogOpen(true)}
											/>
										</ControlGroup>
									</div>
								}

								{
									transaction_source && transaction_source.value===auth.enums.transaction_sources.stock_acceptance.STOCK_ISSUANCE ?
						        	<div className="row mb-5">
										<TextField
											className="col-sm-12 col-md-6 col-lg-6 mt-5"
											size="small"
											variant="outlined"
											label={translate.placeholder.SELECT_TRANSFER_FROM}
											value={transfer_from&&transfer_from.label ? transfer_from.label : ""}
											inputProps={{readOnly:true}}
										/>
										<TextField
											className="col-sm-12 col-md-6 col-lg-6 mt-5"
											size="small"
											variant="outlined"
											label={translate.placeholder.SELECT_TRANSFER_TO}
											value={transfer_to&&transfer_to.label ? transfer_to.label : ""}
											inputProps={{readOnly:true}}
										/>
									</div> :
									(
										transaction_source!==null ?
										<div className="row mb-5 mt-5">
											<div className="col-sm-12 col-md-12 col-lg-12">
												<MuiAutocomplete
													setSelectedValue={handleTransferToChangedEvent}
													selected_value={transfer_to}
													isMultiple={false}
													isDisabled={(transaction_source&&transaction_source.value!==auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT)||!is_editable}
													label={transaction_source&&transaction_source.value===auth.enums.transaction_sources.stock_acceptance.DIRECT_RECEIPT ? translate.placeholder.SELECT_RECEIVING_UNIT : translate.placeholder.REQUESTING_UNIT}
													data={profit_cost_centers}
												/>
											</div>
										</div>
										: null
									)
								}

								{
									transaction_source && transaction_source.value===auth.enums.transaction_sources.stock_acceptance.STOCK_ISSUANCE && stock_issuance_transaction_source===auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION &&
						        	<div className="row mb-5 mt-5">
										<TextField
											className="col-sm-12 col-md-6 col-lg-6"
											size="small"
											variant="outlined"
											label={"Stock Issuance Source"}
											value={stock_issuance_transaction_source}
											inputProps={{readOnly:true}}
										/>
										<TextField
											className="col-sm-12 col-md-6 col-lg-6"
											size="small"
											variant="outlined"
											label={"Finished Goods to Produce"}
											value={finished_goods_to_produce}
											inputProps={{readOnly:true}}
										/>
									</div>
								}

								<div className="row mb-5 mt-5">
									<TextField
										className="col-sm-12 col-md-12 col-lg-12"
										multiline
										minRows={4}
										size="small"
										variant="outlined"
										label={translate.placeholder.ENTER_TRANSACTION_DESCRIPTION}
										value={transaction_description}
										onChange={(e) =>
											setTransactionDescription(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<Attachment
									id={id}
									transaction={stock_acceptance}
									setTransaction={setStockAcceptance}
									attachments={attachments}
									setAttachments={setAttachments}
								/>

								{
									id!==null && stock_acceptance &&
									<>
										<Comment
											id={id}
											document_type={stock_acceptance.document_type.name}
											comments={stock_acceptance.comments}
											created_by={stock_acceptance.created_by}
											setDocument={setStockAcceptance}
											is_editable={is_editable}
											is_authorizable={is_authorizable}
										/>
										<TransactionLog
											transaction_logs={transaction_logs}
										/>
				                    </>
								}

							</Card>
							{
								<StockAcceptanceLineItem
									id={id}
									is_editable={is_editable}
									transfer_to={transfer_to}
									transaction_source={transaction_source}
									default_line_items={default_line_items}
									line_items={line_items}
									setLineItems={setLineItems}
									stock_issuance_transaction_source={stock_issuance_transaction_source}
									default_journal_entries={default_journal_entries}
									journal_entries={journal_entries}
									setJournalEntries={setJournalEntries}
									profit_cost_centers={profit_cost_centers}
									balancing_account={balancing_account}
									setBalancingAccount={setBalancingAccount}
									items={items}
									locations={locations}
								/>
							}
						</div>
					</div>
				}

				<Card className="function-card-spreadsheet">
					<StockAcceptanceSpreadsheet
						enable_select={true}
						enable_edit={true}
						selected_rows={selected_rows}
						setSelectedRows={setSelectedRows}
						handleEditButtonClickedEvent={handleEditButtonClickedEvent}
						handleClearButtonClickedEvent={handleClearButtonClickedEvent}
						worksheet={worksheet}
						setWorkSheet={setWorkSheet}
						refreshData={refreshData}
						is_confirm_delete_dialog_open={is_confirm_delete_dialog_open}
						setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
						profit_cost_centers={profit_cost_centers}
						DEFAULT_DATA_SET={DEFAULT_DATA_SET}
					/>
				</Card>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_delete_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDeleteDialogOpen}
					confirmAction={handleDeleteRowsButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted/voided, document will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain document is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_submit_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmSubmitDialogOpen}
					confirmAction={handleSubmitButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">SUBMIT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_approve_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmApproveDialogOpen}
					confirmAction={handleApproveButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">APPROVE</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_reject_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmRejectDialogOpen}
					confirmAction={handleRejectButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">REJECT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_void_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmVoidDialogOpen}
					confirmAction={handleVoidButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">VOID</span> this document ?
						</span>
					}
				/>

				<DatatableDialog
					is_datatable_open={is_datatable_open}
					setIsDatatableDialogOpen={setIsDatatableDialogOpen}
					transaction_source={transaction_source}
					handleProcessSelectedDocuments={handleProcessSelectedDocuments}
				/>

			</div>
		</div>
	);
}
