import React, { useCallback, useEffect, useState } from "react";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { useParams } from "react-router";
import { StockCountSheet } from "./StockCountSheet";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	updateStockCountAsync,
	submitStockCountAsync,
	rejectStockCountAsync,
	approveStockCountAsync,
	voidStockCountAsync,
	fetchEndingInventoriesAsync,
	fetchStockCountSheetAsync,
	byPassStockCountScheduleAsync,
	fetchAllStockCountLineItemsAsync,
	toggleSidebarCollapsed,
	fetchAccountingPeriodsAsync,
	byPassYearEndClosingAsync,
	fetchAllProfitCostCentersAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	ControlGroup,
	H3,
	H5,
	FormGroup,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { BypassConfirmDialog } from "./BypassConfirmDialog";
import { JournalEntry } from "../../../common/JournalEntry";
import { SideBar } from '../../../SideBar';
import { Attachment } from "../../../common/Attachment";
import { Comment } from "../../../common/Comment";
import { TransactionLog } from "../../../common/TransactionLog";
import { useHistory } from "react-router-dom";
import { StockCountSpreadsheet } from "../../../spreadsheets/StockCountSpreadsheet";

export function StockCount() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const default_journal_entries = Array(1).fill({
		account_code:"",
		account_description:"",
		profit_cost_center:null,
		debit_amount:"",
		credit_amount:"",
	});
	const { document_id } = useParams();
	const [id, setId] = useState(document_id ? document_id : null);
	const [stock_count, setStockCount] = useState(null);
	const [document_date, setDocumentDate] = useState(stock_count ? moment(stock_count.document_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT) : moment(new Date(auth.general_settings.stock_count_date)).format(resources.date_format.INPUT));
	const [reference_number, setReferenceNumber] = useState("");
	const [transaction_description, setTransactionDescription] = useState("");
	const [transaction_logs, setTransactionLogs] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_delete_dialog_open, setIsConfirmDeleteDialogOpen] = useState(false);
	const [is_confirm_submit_dialog_open, setIsConfirmSubmitDialogOpen] = useState(false);
	const [is_confirm_approve_dialog_open, setIsConfirmApproveDialogOpen] = useState(false);
	const [is_confirm_reject_dialog_open, setIsConfirmRejectDialogOpen] = useState(false);
	const [is_confirm_void_dialog_open, setIsConfirmVoidDialogOpen] = useState(false);
	const [is_bypass_confirm_dialog_open, setIsBypassConfirmDialogOpen] = useState(false);
	const [is_bypass_yearend_confirm_dialog_open, setIsBypassYearEndConfirmDialogOpen] = useState(false);
	const [profit_cost_centers, setProfitCostCenters] = useState([]);
	const [ending_inventories, setEndingInventories] = useState([]);
	const [total_amount_of_ending_inventory_per_ledger, setTotalAmountOfEndingInventoryPerLedger] = useState((0).toFixed(2));
	const [total_amount_of_ending_inventory_per_count, setTotalAmountOfEndingInventoryPerCount] = useState((0).toFixed(2));
	const [total_amount_of_stock_count_variance, setTotalAmountOfStockCountVariance] = useState((0).toFixed(2));
	const [journal_entries, setJournalEntries] = useState(default_journal_entries);
	const [balancing_account, setBalancingAccount] = useState("");
	const [is_editable, setIsEditable] = useState(true);
	const [is_year_end_closing_button_displayed, setIsYearEndClosingButtonDisplayed] = useState(false);

	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: translate.worksheet.default_header.STOCK_COUNT,
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.STOCK_COUNT));
	}, [
		dispatch,
		translate.breadcrumb.STOCK_COUNT
	]);

	const loadDocument = useCallback((stockCount, stockCountLineItems, journalEntries) => {
		setStockCount(stockCount);
		setId(stockCount.id);
		setReferenceNumber(stockCount.reference_number);
		setTotalAmountOfEndingInventoryPerLedger(stockCount.total_amount_of_ending_inventory_per_ledger);
		setTotalAmountOfEndingInventoryPerCount(stockCount.total_amount_of_ending_inventory_per_count);
		setTotalAmountOfStockCountVariance(stockCount.total_amount_of_stock_count_variance);
		setTransactionLogs(stockCount.transaction_logs);
		setAttachments(stockCount.attachments);
		setTransactionDescription(stockCount.description);
		setEndingInventories(stockCountLineItems.map((scli) => [
			scli.item.code,
			scli.item.description,
			`${scli.location.pcc_name} / ${scli.location.name}`,
			scli.serial_number,
			scli.expiry_date ? moment(new Date(scli.expiry_date)).format(resources.date_format.DEFAULT) : "",
			scli.item_condition,
			scli.unit_of_measure.code,
			scli.ending_quantity,
			scli.unit_cost,
			scli.amount,
			scli.actual_quantity,
			scli.variance_quantity,
			scli.variance_amount,
		]));
		setBalancingAccount(auth.enums.balancing_accounts.stock_count.VARIANCE);
		let entries = journalEntries.map((journalEntry) => ({
            account_code:journalEntry.account_code,
            account_description:journalEntry.account_description,
            profit_cost_center:journalEntry.profit_cost_center,
            debit_amount:journalEntry.debit_amount,
            credit_amount:journalEntry.credit_amount,
		}));
        setJournalEntries(entries);
		setIsEditable(false);
	},[auth.enums.balancing_accounts.stock_count.VARIANCE]);

	useEffect(() => {
		if (worksheet.is_readonly_mode) {
			const handleFetchProfitCostCenterResponse = (response) => {
				if (response.payload.data) {
					let branches = response.payload.data.branches.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((branch) => ({label:`${branch.code} | ${branch.name}`, type:branch.type, value:branch.id}));
					let departments = response.payload.data.departments.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((department) => ({label:`${department.code} | ${department.name}`, type:department.type, value:department.id}));
					let projects = response.payload.data.projects.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((project) => ({label:`${project.code} | ${project.name}`, type:project.type, value:project.id}));
					setProfitCostCenters(branches.concat(departments).concat(projects));
				}
			}
			dispatch(fetchAllProfitCostCentersAsync({})).then((response) => handleFetchProfitCostCenterResponse(response));
			const handleAccountingPeriodResponse = (response) => {
				if (response.payload.data) {
					let accountingPeriods = response.payload.data;
					let warehouseInventory = JSON.parse(auth.general_settings.warehouse_inventory);
					setIsYearEndClosingButtonDisplayed(accountingPeriods[11].end===warehouseInventory.stock_count_date);
				}
			}
			dispatch(fetchAccountingPeriodsAsync({})).then((res) => handleAccountingPeriodResponse(res));
		}
	}, [
		dispatch,
		loadDocument,
		translate.text.STOCK_COUNT,
		worksheet.is_readonly_mode,
		auth.general_settings.warehouse_inventory,
		auth.enums.approval_status.APPROVED
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (id) {
			dispatch(fetchAllStockCountLineItemsAsync({stock_count_id:id})).then((response) => response.payload.data ? loadDocument(response.payload.data.stock_count, response.payload.data.stock_count_line_items, response.payload.data.journal_entries) : void(0));
		}
	}, [dispatch, id, loadDocument]);

	useEffect(() => {
		if (auth.general_settings.stock_count_date) {
			setDocumentDate(moment(new Date(auth.general_settings.stock_count_date)).format(resources.date_format.INPUT));
		}
	}, [auth.general_settings.stock_count_date]);

	const handleClearButtonClickedEvent = () => {
		setId(null);
		setStockCount(null);
		setReferenceNumber("");
		setTransactionDescription("");
		setEndingInventories([]);
		setTotalAmountOfEndingInventoryPerLedger("");
		setTotalAmountOfEndingInventoryPerCount("");
		setTotalAmountOfStockCountVariance("");
		setJournalEntries(default_journal_entries);
		setAttachments([]);
		setIsEditable(false);
		history.push('/warehouse-inventory/stock-count');
	};

	const handleDeleteRowsButtonClickedEvent = () => {
		let rows = selected_rows.map(
			(row) => worksheet.data_set[row]
		);
		let promises = [];
		for (let i = 0; i < rows.length; i++) {
			promises.push(
				dispatch(
					voidStockCountAsync({
						id: rows[i][0]
					})
				)
			);
		}
		Promise.all(promises).then((response) => {
			var success = true;
			var message = null;
			for (let i = 0; i < response.length; i++) {
				if (response[i].payload !== undefined) {
					if (response[i].payload.status !== resources.status.SUCCESS) {
						success = false;
						break;
					}
					else {
						if (message == null) {
							message = response[i].payload.message;
						}
					}
				}
			}
			if (success === true) {
				toast.success(
					message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			handleClearButtonClickedEvent();
		});
	};

	const refreshData = () => {
		handleClearButtonClickedEvent();
	}

	const handleSubmitButtonClickedEvent = () => {
		dispatch(
			submitStockCountAsync({
				id:id,
				reference_number:reference_number,
				transaction_description:transaction_description,
				attachments:attachments,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleApproveButtonClickedEvent = () => {
		dispatch(
			approveStockCountAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleRejectButtonClickedEvent = () => {
		dispatch(
			rejectStockCountAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleVoidButtonClickedEvent = () => {
		dispatch(
			voidStockCountAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? refreshData() : null))
	};

	const handleEditButtonClickedEvent = async (row) => {
		let id = worksheet.data_set[row][0];
		setId(id);
		window.scrollTo(0,0);
	};

	const handleBypassScheduleButtonClickedEvent = () => {
		dispatch(
			byPassStockCountScheduleAsync()
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleClearButtonClickedEvent() : null));
	}

	const handleBypassYearEndClosingButtonClickedEvent = () => {
		dispatch(
			byPassYearEndClosingAsync()
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleClearButtonClickedEvent() : null));
	}

	const handleGenerateEndingInventoryButtonClickedEvent = (data) => {
		setEndingInventories(data.ending_inventories.map((endingInventory) => [
			endingInventory.item_code,
			endingInventory.item_description,
			endingInventory.location_name,
			endingInventory.serial_number,
			endingInventory.expiry_date,
			endingInventory.item_condition,
			endingInventory.uom_code,
			endingInventory.quantity,
			endingInventory.unit_cost,
			endingInventory.amount,
		]));
		setTotalAmountOfEndingInventoryPerLedger(data.total_amount_of_ending_inventory_per_ledger);
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{ Util.functionTitle(translate.text.STOCK_COUNT) }
				<div className="function-sub-title">
					{id ? '' : 'Next'} Stock Count {id ? 'Conducted' : 'Schedule'} on {moment(document_date).format(resources.date_format.DEFAULT)}
				</div>

				{
					worksheet.is_readonly_mode &&
					<>
						<ButtonGroup className="button-group" minimal={false}>
							{
								id===null ?
								<>
									{
										id===null &&
										<Button
											text={"Generate Ending Inventory"}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="document-share" className="orange"/>}
											onClick={
												e => dispatch(fetchEndingInventoriesAsync()).then((response) => (handleGenerateEndingInventoryButtonClickedEvent(response.payload.data)))
											}
										/>
									}
									{
										id===null &&
										<Button
											text={"Download Ending Inventory"}
											className="function-button"
											disabled={auth.status === resources.status.LOADING}
											icon={<Icon icon="download" className="orange"/>}
											onClick={(e) => Excel.export('Ending Inventory', translate.worksheet.import_header.STOCK_COUNT_SHEET, ending_inventories, auth.active_company)}
										/>
									}
									<Button
										text={"Download Count Sheet"}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="document-open" className="orange"/>}
										onClick={
											e => dispatch(fetchStockCountSheetAsync()).then((response) => response.payload.status===resources.status.SUCCESS ? (Excel.export('Count Sheet', translate.worksheet.import_header.STOCK_COUNT_IMPORT_SHEET, response.payload.data.stock_count_sheet.map((countSheet) => ([
													moment(document_date).format(resources.date_format.DEFAULT),
													countSheet.item_code,
													countSheet.item_description,
													countSheet.location_code,
													countSheet.location_name,
													countSheet.serial_number,
													countSheet.expiry_date,
													countSheet.item_condition,
													countSheet.uom_code,
												])), auth.active_company))
												: null
											)
										}
									/>
									{
										id===null ?
										<>
											{
												is_year_end_closing_button_displayed ? 
												<Button
													text={translate.button.BYPASS_YEAR_END_CLOSING}
													className="function-button"
													disabled={auth.status === resources.status.LOADING}
													icon={<Icon icon="chevron-forward" className="orange"/>}
													onClick={e => setIsBypassConfirmDialogOpen(!is_bypass_confirm_dialog_open)}
												/> :
												<Button
													text={translate.button.BYPASS_STOCK_COUNT_SCHEDULE}
													className="function-button"
													disabled={auth.status === resources.status.LOADING}
													icon={<Icon icon="chevron-forward" className="orange"/>}
													onClick={e => setIsBypassConfirmDialogOpen(!is_bypass_confirm_dialog_open)}
												/>
											}
										</> : 
										null
									}
								</>
								:
								<>
									<Button
										text={translate.button.UPDATE}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="floppy-disk" className="orange"/>}
										onClick={(e) =>
											dispatch(
												updateStockCountAsync({
													id:id,
													reference_number:reference_number,
													transaction_description:transaction_description,
													attachments:attachments,
												})
											)
										}
									/>
									<Button
										text={translate.button.SUBMIT}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="send-message" className="orange"/>}
										onClick={e => setIsConfirmSubmitDialogOpen(!is_confirm_submit_dialog_open)}
									/>
									<Button
										text={translate.button.VOID}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="ban-circle" className="orange"/>}
										onClick={e => setIsConfirmVoidDialogOpen(!is_confirm_void_dialog_open)}
									/>
									{
										stock_count && stock_count.approval_status===auth.enums.approval_status.FOR_APPROVAL &&
										<>
											<Button
												text={translate.button.APPROVE}
												className="function-button"
												disabled={auth.status === resources.status.LOADING}
												icon={<Icon icon="tick" className="orange"/>}
												onClick={e => setIsConfirmApproveDialogOpen(!is_confirm_approve_dialog_open)}
											/>
											<Button
												text={translate.button.REJECT}
												className="function-button"
												disabled={auth.status === resources.status.LOADING}
												icon={<Icon icon="cross" className="orange"/>}
												onClick={e => setIsConfirmRejectDialogOpen(!is_confirm_reject_dialog_open)}
											/>
										</>
									}
								</>
							}
							{
								id===null &&
								<Button
									text={translate.button.CLEAR}
									className="function-button"
									icon={<Icon icon="reset" className="orange"/>}
									disabled={auth.status === resources.status.LOADING}
									onClick={handleClearButtonClickedEvent}
								/>
							}
						</ButtonGroup>

						<div className="">
							<div className="row">
								<Card
									className="col-sm-12 col-md-4 col-lg-3"
									style={{
										background: "lightblue",
										border: "solid 1px lightgrey"
									}}
								>
									<FormGroup>
										<H3 className="center" style={{color:"blue"}}>{stock_count!==null ? stock_count.document_number : null}</H3>
										<H5 className="center" style={{color:"green"}}>{stock_count!==null ? stock_count.approval_status : null}</H5>
									</FormGroup>

									<div className="row mb-5">
										<TextField
											className="col-sm-12 col-md-6 col-lg-6 mt-5"
											id="document_date"
											label={translate.placeholder.PICK_A_DATE}
											type="text"
											variant="outlined"
											size="small"
											defaultValue={document_date}
											inputProps={{readOnly:true}}
											onChange={(e) => setDocumentDate(e.target.value)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
										<TextField
											className="col-sm-12 col-md-6 col-lg-6 mt-5"
											size="small"
											variant="outlined"
											value={reference_number}
											label={translate.placeholder.ENTER_TRANSACTION_REFERENCE_NUMBER}
											onChange={(e) =>
												setReferenceNumber(
													e.target.value
												)
											}
										/>
							        </div>

									<div className="row mb-5 mt-5">
										<TextField
										 	className="col-sm-12 col-md-12 col-lg-12"
											inputProps={{readOnly:true, style: { textAlign: 'right' }}}
											size="small"
											variant="outlined"
											value={total_amount_of_ending_inventory_per_ledger.toLocaleString('en-US')}
											label={`Total Amount of Ending Inventory per Ledger as of ${moment(document_date).format(resources.date_format.DEFAULT)}`}
										/>
									</div>

									<div className="row mb-5 mt-5">
										<TextField
										 	className="col-sm-12 col-md-12 col-lg-12"
											inputProps={{readOnly:true, style: { textAlign: 'right' }}}
											size="small"
											variant="outlined"
											value={total_amount_of_ending_inventory_per_count.toLocaleString('en-US')}
											label={`Total Amount of Ending Inventory per Count as of ${moment(document_date).format(resources.date_format.DEFAULT)}`}
										/>
									</div>

									<div className="row mb-5 mt-5">
										<TextField
										 	className="col-sm-12 col-md-12 col-lg-12"
											inputProps={{readOnly:true, style: { textAlign: 'right' }}}
											size="small"
											variant="outlined"
											value={total_amount_of_stock_count_variance.toLocaleString('en-US')}
											label={`Total Amount of Stock Count Variance as of ${moment(document_date).format(resources.date_format.DEFAULT)}`}
										/>
									</div>

									<div className="row mb-5 mt-5">
										<TextField
											className="col-sm-12 col-md-12 col-lg-12"
											multiline
											minRows={1}
											size="small"
											variant="outlined"
											label={translate.placeholder.ENTER_TRANSACTION_DESCRIPTION}
											value={transaction_description}
											onChange={(e) =>
												setTransactionDescription(
													e.target.value
												)
											}
										/>
									</div>

									<Attachment
										id={id}
										transaction={stock_count}
										setTransaction={setStockCount}
										attachments={attachments}
										setAttachments={setAttachments}
									/>

									{
										id!==null && stock_count &&
										<>
											<Comment
												id={id}
												document_type={stock_count.document_type.name}
												comments={stock_count.comments}
												setDocument={setStockCount}
												is_editable={is_editable}
											/>
											<TransactionLog
												transaction_logs={transaction_logs}
											/>
										</>
									}

								</Card>
								<div className="col-sm-12 col-md-7 col-lg-8">
									<div className="row">
										<ControlGroup className="col-sm-12 col-md-12 col-lg-12" vertical={true} style={{background: "grey", border:"solid lightgrey 1px", alignItems:"stretch", overflowX:"hidden", overflowY:"hidden"}}>
											
											<StockCountSheet
												ending_inventories={ending_inventories}
											/>

										</ControlGroup>
										{
											id && journal_entries.length>0 &&
											<ControlGroup className="col-sm-12 col-md-12 col-lg-12" vertical={true}>
												<JournalEntry
													default_journal_entries={default_journal_entries}
													journal_entries={journal_entries}
													setJournalEntries={setJournalEntries}
													profit_cost_centers={profit_cost_centers}
													balancing_account={balancing_account}
													setBalancingAccount={setBalancingAccount}
													is_editable={is_editable}
												/>
											</ControlGroup>
										}
									</div>
								</div>

							</div>

						</div>
					</>

				}

				<Card className="function-card-spreadsheet mt-5">
					<StockCountSpreadsheet
						enable_select={true}
						enable_edit={true}
						selected_rows={selected_rows}
						setSelectedRows={setSelectedRows}
						handleEditButtonClickedEvent={handleEditButtonClickedEvent}
						handleClearButtonClickedEvent={handleClearButtonClickedEvent}
						worksheet={worksheet}
						setWorkSheet={setWorkSheet}
						refreshData={refreshData}
						is_confirm_delete_dialog_open={is_confirm_delete_dialog_open}
						setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
						DEFAULT_DATA_SET={DEFAULT_DATA_SET}
					/>
				</Card>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_delete_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDeleteDialogOpen}
					confirmAction={handleDeleteRowsButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted/voided, document will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain document is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_submit_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmSubmitDialogOpen}
					confirmAction={handleSubmitButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">SUBMIT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_approve_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmApproveDialogOpen}
					confirmAction={handleApproveButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">APPROVE</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_reject_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmRejectDialogOpen}
					confirmAction={handleRejectButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">REJECT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_void_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmVoidDialogOpen}
					confirmAction={handleVoidButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">VOID</span> this document ?
						</span>
					}
				/>

				<BypassConfirmDialog
					is_confirm_dialog_open={is_bypass_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsBypassConfirmDialogOpen}
					confirmAction={handleBypassScheduleButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>You will no longer be able to create a stock count for the current stock count schedule.</li>
		                        <li>The system will set the next stock count schedule.</li>
		                    </ol>
		                </div>
					}
				/>

				<BypassConfirmDialog
					is_confirm_dialog_open={is_bypass_yearend_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsBypassYearEndConfirmDialogOpen}
					confirmAction={handleBypassYearEndClosingButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>You will no longer be able to create transactions for the current active accounting calendar.</li>
		                        <li>The system will generate the next accounting calendar.</li>
		                    </ol>
		                </div>
					}
				/>

			</div>
		</div>
	);
}
