import React from "react";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Icon } from "@blueprintjs/core";

export function NoDataAvailable({text}) {
    return (
		<div style={{display:"flex", height:"100%", alignItems:"center", justifyContent:"center"}}>
			<Icon icon="disable" size={80} style={{color:"lightgrey"}}/>
			<h2 style={{color:"lightgrey", marginLeft:15}}> {text}</h2>
		</div>
    );
}
