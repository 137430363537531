import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
	selectAuth
} from "../features/auth/authSlice";
import { resources } from "../resources/index";
import {
	Button,
	Collapse,
	Card,
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";

export function TransactionLog({transaction_logs}) {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const [is_transaction_logs_open , setIsTransactionLogsOpen] = useState(false);

	const handleTransactionLogsButtonClickedEvent = () => {
		setIsTransactionLogsOpen(!is_transaction_logs_open);
	};

	return (
		<div className="row">
			<Button style={{fontWeight:"bold", marginTop:"2px", marginBottom:"2px"}} alignText="left" fill={true} onClick={handleTransactionLogsButtonClickedEvent} rightIcon={is_transaction_logs_open ? "caret-up" : "caret-down"}>{translate.text.TRANSACTION_LOGS}</Button>
			<Collapse className="col-sm-12 col-md-12 col-lg-12" isOpen={is_transaction_logs_open} keepChildrenMounted={true}>
				<Card style={{margin:"1px", padding:"5px", height:"120px", overflowY:"scroll", fontSize:"9pt", textAlign:"left"}}>
					{
						transaction_logs.slice(0).reverse().map((log, key) => (
							<div key={key} style={{whiteSpace:"nowrap"}}>
								[<span style={{color:"green"}}>{log.at}</span>] <span style={{color:"blue", fontWeight:"bold"}}>{log.action}</span> <span style={{color:"green", fontWeight:"bold"}}>{log.by}</span>
							</div>
						))
					}
				</Card>
			</Collapse>
	</div>
	);
}
