import React, { useCallback, useEffect, useState } from "react";
import Util from "../../../common/Util";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Note } from "../../../common/Note";
import {
	selectAuth,
	updateNavigation,
	updateGeneralSettingsAsync,
	toggleSidebarCollapsed,
	uploadCompanyLogoAsync,
	skipProcessAsync,
	fetchAccountingPeriodsAsync,
	fetchStockCountSchedulesAsync,
	fetchAllStockCountsAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	H4,
	Icon,
	FileInput,
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { DatePicker } from "@blueprintjs/datetime";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import default_company_logo from "../../../default_company_logo.png";

export function GeneralSettings() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const [warehouse_inventory] = useState(auth.active_company.general_settings ? JSON.parse(auth.active_company.general_settings.warehouse_inventory) : null);
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [current_application] = useState(auth.applications.find((app) => app.name===translate.text.GENERAL_SETTINGS));
	const [company_code] = useState(auth.active_company.code);
	const [company_name] = useState(auth.active_company.name);
	const [country] = useState({label:`${auth.active_company.country.code} | ${auth.active_company.country.name}`, value:auth.active_company.country.id});
	const [currency] = useState({label:auth.active_company.currency.name, value:auth.active_company.currency.id});
	const [stock_count_policies] = useState(Object.values(auth.enums.stock_count_policies).map((stockCountPolicy, key) => ({label:stockCountPolicy, value:stockCountPolicy})));
	const [stock_count_policy, setStockCountPolicy] = useState(warehouse_inventory ? stock_count_policies.find((sp) => (sp.value===warehouse_inventory.stock_count_policy)) : null);
	const [stock_count_schedules, setStockCountSchedules] = useState(warehouse_inventory ? warehouse_inventory.stock_count_schedules : []);
	const [selected_date, setSelectedDate] = useState(null);
	const [serial_number_types] = useState(Object.values(auth.enums.serial_number_types).map((serialNumberType) => ({label:serialNumberType, value:serialNumberType})));
	const [beginning_balance_serial_number_setup, setBeginningBalanceSerialNumberSetup] = useState(warehouse_inventory ? serial_number_types.find((snt) => (snt.value===warehouse_inventory.beginning_balance_serial_number_setup)) : null);
	const [period_types] = useState(Object.values(auth.enums.period_types).map((periodType) => ({label:periodType, value:periodType})));
	const [period_type, setPeriodType] = useState(auth.active_company.general_settings ? period_types.find((pt) => (pt.value===auth.active_company.general_settings.period_type)) : null);
	const [years] = useState(Array(5).fill("").map((arr, index) => ({label:`${new Date().getFullYear()+4-index}`, value:new Date().getFullYear()+4-index})));
	const [active_year, setActiveYear] = useState(auth.active_company.general_settings ? years.find((yr) => (yr.value===parseInt(auth.active_company.general_settings.active_year))) : null);
	const [beginning_balance_date, setBeginningBalanceDate] = useState(auth.general_settings&&auth.general_settings.beginning_balance_date ? moment(new Date(auth.general_settings.beginning_balance_date)).format(resources.date_format.INPUT) : "");
	const [min_date, setMinDate] = useState(new Date(new Date().getFullYear(), 0, 1));
	const [max_date, setMaxDate] = useState(new Date(new Date().getFullYear(), 11, 31));
	const [has_transaction] = useState(auth.active_company.application_id===null);
	const [has_stock_count, setHasStockCount] = useState(false);
	const [accounting_periods, setAccountingPeriods] = useState([]);

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.GENERAL_SETTINGS));
		dispatch(fetchAllStockCountsAsync({})).then((res) => res.payload.data ? setHasStockCount(res.payload.data.stock_counts.length>0) : void(0));
		dispatch(fetchAccountingPeriodsAsync({})).then((res) => setAccountingPeriods(res.payload.data));
	}, [
		dispatch,
		translate.breadcrumb.GENERAL_SETTINGS,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (stock_count_policy && beginning_balance_date.length>0) {
			if (stock_count_policy.value===auth.enums.stock_count_policies.MONTHLY||stock_count_policy.value===auth.enums.stock_count_policies.QUARTERLY||stock_count_policy.value===auth.enums.stock_count_policies.SEMI_ANNUALLY||stock_count_policy.value===auth.enums.stock_count_policies.ANNUALLY) {
				const handleResponse = (response) => {
					if (response.payload.data) setStockCountSchedules(response.payload.data.stock_count_schedules);
				}
				dispatch(fetchStockCountSchedulesAsync({
					stock_count_policy:stock_count_policy ? stock_count_policy.value : '',
					period_type:period_type ? period_type.value : '',
					active_year:active_year ? active_year.value : '',
					beginning_balance_date:beginning_balance_date ? moment(beginning_balance_date).format(resources.date_format.SYSTEM) : '',})).then((response) => handleResponse(response)
				);
			}
		}
	}, [dispatch, active_year, period_type, stock_count_policy, beginning_balance_date, auth.enums.stock_count_policies]);

	useEffect(() => {
		if (period_type && active_year && beginning_balance_date) {
			if (period_type.value===auth.enums.period_types.CALENDAR) {
				let minDate = new Date(active_year.value, 0, 1);
				let maxDate = new Date(active_year.value, 11, 31);
				setSelectedDate(minDate);
				setMinDate(minDate);
				setMaxDate(maxDate);
			}
			else {
				let beginningBalanceDate = new Date(beginning_balance_date);
				let yearEndDate = new Date(beginning_balance_date);
				beginningBalanceDate.setDate(beginningBalanceDate.getDate() + 1);
				yearEndDate.setFullYear(yearEndDate.getFullYear() + 1);
				setSelectedDate(beginningBalanceDate);
				setMinDate(beginningBalanceDate);
				setMaxDate(yearEndDate);
			}
		}
	}, [period_type, active_year, beginning_balance_date, auth.enums.period_types.CALENDAR]);

	const onDateSelectionChanged = (selectedDate) => {
		if (stock_count_policy) {
			if (stock_count_policy.value===auth.enums.stock_count_policies.MONTHLY) {
				let lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
				setSelectedDate(lastDay);
			}
			if (stock_count_policy.value===auth.enums.stock_count_policies.QUARTERLY||stock_count_policy.value===auth.enums.stock_count_policies.SEMI_ANNUALLY||stock_count_policy.value===auth.enums.stock_count_policies.ANNUALLY) {
				let lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
				if (stock_count_schedules.includes(moment(lastDay).format(resources.date_format.DEFAULT))) {
					setSelectedDate(lastDay);
				}
			}
			if (stock_count_policy.value===auth.enums.stock_count_policies.CUSTOM_SCHEDULE) {
				setSelectedDate(selectedDate);
			}
		}
	}

	const onStockCountPolicyChanged = (stockCountPolicy) => {
		setStockCountSchedules([]);
		setStockCountPolicy(stockCountPolicy);
	}

	const onAddScheduleButtonClicked = () => {
		let date = moment(selected_date).format(resources.date_format.DEFAULT);
		if (!stock_count_schedules.includes(date)) {
			let schedules = [...stock_count_schedules, date];
			setStockCountSchedules(schedules);
		}
	}

	const onDeleteScheduleButtonClicked = (row) => {
		setStockCountSchedules(stock_count_schedules.filter((stockCountSchedule, key) => key!==row));
	}

	const onPeriodTypeChanged = (periodType) => {
		setPeriodType(periodType);
		if (periodType && active_year) {
			if (periodType.value===auth.enums.period_types.CALENDAR) {
				let date = new Date(active_year.value-1, 11, 31);
				setBeginningBalanceDate(moment(date).format(resources.date_format.INPUT));
			}
			else {
				setBeginningBalanceDate("");
			}
		}
	}

	const onActiveYearChanged = (value) => {
		setActiveYear(value);
		setPeriodType(null);
		setBeginningBalanceDate("");
	}

	const onUploadCompanyLogo = (event) => {
		dispatch(uploadCompanyLogoAsync({url:auth.active_company.logo_source, logo:event.target.files[0]}));
	};
	
	const onSkipButtonClicked = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'general-settings'})).then((response) => response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onBeginningBalanceDateChanged = (beginningBalanceDate) => {
		let parts = beginningBalanceDate.split("-");
		setBeginningBalanceDate(active_year.value+"-"+parts[1]+"-"+parts[2]);
	}
	
	const onUpdateButtonClicked = (response) => {
		if (response.payload.status===resources.status.SUCCESS) {
			dispatch(fetchAccountingPeriodsAsync({})).then((res) => setAccountingPeriods(res.payload.data));
		}
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SAVE_LABEL,
							value: translate.text.NOTE_SAVE_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id && auth.active_company.application_id!==current_application.id &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSkipButtonClicked}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.GENERAL_SETTINGS) }


				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={translate.button.SAVE}
						className="function-button"
						icon={<Icon icon="floppy-disk" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={e => dispatch(updateGeneralSettingsAsync({
							company_code:company_code,
							company_name:company_name,
							currency:currency!==null ? currency.value : '',
							country:country!==null ? country.value : '',
							period_type:period_type ? period_type.value : '',
							active_year:active_year ? active_year.value : '',
							beginning_balance_date:beginning_balance_date ? moment(beginning_balance_date).format(resources.date_format.SYSTEM) : '',
							stock_count_policy:stock_count_policy ? stock_count_policy.value : '',
							stock_count_schedules:stock_count_schedules,
							beginning_balance_serial_number_setup:beginning_balance_serial_number_setup ? beginning_balance_serial_number_setup.value : '',
						})).then((response) => onUpdateButtonClicked(response))}
					/>
				</ButtonGroup>

					<div className="row">


							<Card
								className='col-sm-12 col-md-12 col-lg-11 function-card'
							>
								<div className="row">
									<div className='col-sm-12 col-md-4 col-lg-3 center mt-5'>
										<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
											<img 
												alt={auth.active_company.logo_source} 
												src={auth.active_company.logo_source}
												onError={(e) => {
													e.target.src = default_company_logo
												}}
												style={{margin:'10px', backgroundColor:"transparent"}}
											/>
										</div>
									</div>
									<div className='col-sm-12 col-md-8 col-lg-9 mt-5'>
										<div>Company Code : <span className='blue'>{company_code}</span></div>
										<div>Company Name : <span className='blue'>{company_name}</span></div>
										<div>Country : <span className='blue'>{country.label}</span></div>
										<div>Currency : <span className='blue'>{currency.label}</span></div>
										<FileInput className='col-sm-12 col-md-8 col-lg-8 mt-5' disabled={false} text="Upload Logo (png, jpg, gif)" onInputChange={onUploadCompanyLogo} />
									</div>
								</div>
							
						</Card>
					</div>

					<ButtonGroup className="button-group mt-1 pt-1" minimal={false}>
						{
							auth.active_company.application_id===null &&
							<Button
								text={translate.button.SAVE}
								className="function-button"
								icon={<Icon icon="floppy-disk" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={e => dispatch(updateGeneralSettingsAsync({
									company_code:company_code,
									company_name:company_name,
									currency:currency!==null ? currency.value : '',
									country:country!==null ? country.value : '',
									period_type:period_type ? period_type.value : '',
									active_year:active_year ? active_year.value : '',
									beginning_balance_date:beginning_balance_date ? moment(beginning_balance_date).format(resources.date_format.SYSTEM) : '',
									stock_count_policy:stock_count_policy ? stock_count_policy.value : '',
									stock_count_schedules:stock_count_schedules,
									beginning_balance_serial_number_setup:beginning_balance_serial_number_setup ? beginning_balance_serial_number_setup.value : '',
								})).then((response) => onUpdateButtonClicked(response))}
							/>
						}
					</ButtonGroup>

					<div className="row">
						<Card className="function-card col-sm-12 col-md-12 col-lg-11">


							{
								accounting_periods.length>0 &&
								<div className="mt-5">
									<div className="row">
										<Card className=" col-sm-12 col-md-12 col-lg-10 mb-5" style={{backgroundColor:"transparent"}}>
										<H4 className="gs-section-title center">
											Accounting Calendar
										</H4>

										<div className="row">
											<TextField
												className="col-sm-12 col-md-4 col-lg-3 text-field"
												size="small"
												variant="outlined"
												label={"Accounting Calendar Start Date"}
												value={moment(new Date(accounting_periods[0].start)).format(resources.date_format.DEFAULT)}
												inputProps={{readOnly:true}}
											/>
											<TextField
												className="col-sm-12 col-md-4 col-lg-3 text-field"
												size="small"
												variant="outlined"
												label={"Accounting Calendar End Date"}
												value={moment(new Date(accounting_periods[11].end)).format(resources.date_format.DEFAULT)}
												inputProps={{readOnly:true}}
											/>
										</div>
										<div className="row" style={{marginTop:20, color:"blue", fontSize:"10pt"}}>
											<div className="col-sm-12 col-md-3 col-lg-3">
												{
													accounting_periods.slice(0,3).map((ap, key) => (
														<div key={key} className="center">
															{moment(new Date(ap.start)).format(resources.date_format.DEFAULT)} to {moment(new Date(ap.end)).format(resources.date_format.DEFAULT)}
														</div>
													))
												}
											</div>
											<div className="col-sm-12 col-md-3 col-lg-3">
												{
													accounting_periods.slice(3,6).map((ap, key) => (
														<div key={key} className="center">
															{moment(new Date(ap.start)).format(resources.date_format.DEFAULT)} to {moment(new Date(ap.end)).format(resources.date_format.DEFAULT)}
														</div>
													))
												}
											</div>
											<div className="col-sm-12 col-md-3 col-lg-3">
												{
													accounting_periods.slice(6,9).map((ap, key) => (
														<div key={key} className="center">
															{moment(new Date(ap.start)).format(resources.date_format.DEFAULT)} to {moment(new Date(ap.end)).format(resources.date_format.DEFAULT)}
														</div>
													))
												}
											</div>
											<div className="col-sm-12 col-md-3 col-lg-3">
												{
													accounting_periods.slice(9,12).map((ap, key) => (
														<div key={key} className="center">
															{moment(new Date(ap.start)).format(resources.date_format.DEFAULT)} to {moment(new Date(ap.end)).format(resources.date_format.DEFAULT)}
														</div>
													))
												}
											</div>
											{/*
												accounting_periods.map((ap, key) => (
													<div key={key} className="col-sm-12 col-md-3 col-lg-4 center">
														{moment(new Date(ap.start)).format(resources.date_format.DEFAULT)} to {moment(new Date(ap.end)).format(resources.date_format.DEFAULT)}
													</div>
												))
											*/}
										</div>

										</Card>
									</div>
								</div>
							}


							<H4 className="gs-section-title center">
								Beginning Balance
							</H4>

							<div className="row">
								<div className="col-sm-12 col-md-3 col-lg-3 text-field">
									<MuiAutocomplete
										setSelectedValue={onActiveYearChanged}
										selected_value={active_year}
										isMultiple={false}
										isDisabled={has_transaction}
										label={'Active Year'}
										data={years}
									/>
								</div>
									<TextField
										className="col-sm-12 col-md-4 col-lg-3 text-field"
										id="beginning_balance_date"
										label={"Beginning Balance Date"}
										type="date"
										variant="outlined"
										size="small"
										value={beginning_balance_date}
										inputProps={{readOnly:has_transaction||period_type===null||(period_type&&period_type.value===auth.enums.period_types.CALENDAR)}}
										onChange={(e) => onBeginningBalanceDateChanged(e.target.value)}
										InputLabelProps={{
											shrink: true,
										}}
									/>

							</div>

							<div className="row">
								<div className="col-sm-12 col-md-3 col-lg-3 text-field">
									<MuiAutocomplete
										setSelectedValue={onPeriodTypeChanged}
										selected_value={period_type}
										isMultiple={false}
										isDisabled={has_transaction||active_year===null}
										label={'Period Type'}
										data={period_types}
									/>
								</div>
								<div className="col-sm-12 col-md-4 col-lg-3 text-field">
									<MuiAutocomplete
										setSelectedValue={setBeginningBalanceSerialNumberSetup}
										selected_value={beginning_balance_serial_number_setup}
										isMultiple={false}
										isDisabled={has_transaction}
										label={'Item Beg. Bal. Serial Number Setup'}
										data={serial_number_types}
									/>
								</div>
							</div>

						</Card>
					</div>

					<ButtonGroup className="button-group mt-1 pt-1" minimal={false}>
						{
							auth.active_company.application_id===null &&
							<Button
								text={translate.button.SAVE}
								className="function-button"
								icon={<Icon icon="floppy-disk" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={e => dispatch(updateGeneralSettingsAsync({
									company_code:company_code,
									company_name:company_name,
									currency:currency!==null ? currency.value : '',
									country:country!==null ? country.value : '',
									period_type:period_type ? period_type.value : '',
									active_year:active_year ? active_year.value : '',
									beginning_balance_date:beginning_balance_date ? moment(beginning_balance_date).format(resources.date_format.SYSTEM) : '',
									stock_count_policy:stock_count_policy ? stock_count_policy.value : '',
									stock_count_schedules:stock_count_schedules,
									beginning_balance_serial_number_setup:beginning_balance_serial_number_setup ? beginning_balance_serial_number_setup.value : '',
								})).then((response) => onUpdateButtonClicked(response))}
							/>
						}
					</ButtonGroup>

					<div className="row" style={{marginBottom:50}}>
						<Card className="function-card col-sm-12 col-md-12 col-lg-11">
						<H4 className="gs-section-title center">
							Stock Count
						</H4>

						<div className="row mt-5">
							<div className="col-sm-12 col-md-4 col-lg-3 mr-5 mb-5">

								<div>
									<MuiAutocomplete
										setSelectedValue={onStockCountPolicyChanged}
										selected_value={stock_count_policy}
										isMultiple={false}
										isDisabled={has_stock_count}
										label={"Stock count policy"}
										data={stock_count_policies}
									/>
								</div>
								{
									stock_count_policy &&
									<div style={{textAlign:"center", fontSize:"12pt", fontWeight:"bold", marginTop:"10px", marginBottom:"10px"}}>
										Schedules:
									</div>
								}
								<div className="stock-count-schedules">
									{
										stock_count_schedules.map((stockCountSchedule, key) => (
											<div key={key}>
												{stockCountSchedule.toString()}
												{
													stock_count_policy && stock_count_policy.value===auth.enums.stock_count_policies.CUSTOM_SCHEDULE && !has_stock_count &&
													<Button icon="small-cross" intent="danger" minimal={true} onClick={e => onDeleteScheduleButtonClicked(key)}/>
												}
											</div>
										))
									}
								</div>
								{
									stock_count_schedules.length===0 && stock_count_policy && stock_count_policy.value===auth.enums.stock_count_policies.CUSTOM_SCHEDULE &&
									<div style={{fontSize:"9pt", color:"red", textAlign:"left"}}>
										There are no schedule/s added yet. Select from the Calendar then click "Add Selected Schedule" button.
									</div>
								}
							</div>
							<div className="col-sm-12 col-md-4 col-lg-4">
								<DatePicker className="gs-stock-count-calendar" value={selected_date} canClearSelection={false} onChange={onDateSelectionChanged} minDate={min_date} maxDate={max_date}/>
								{
									stock_count_policy && stock_count_policy.value===auth.enums.stock_count_policies.CUSTOM_SCHEDULE &&
									<Button
										text={translate.button.ADD_SCHEDULE}
										className="function-button"
										icon={<Icon icon="add" className="orange"/>}
										disabled={auth.status === resources.status.LOADING||(selected_date===null && !has_stock_count)}
										onClick={e => onAddScheduleButtonClicked()}
									/>
								}
							</div>
						</div>

						</Card>
					</div>


			</div>
		</div>
	);
}
