import { resources } from "../resources/index";

class Util {
    static getToasterStyle = (status) => {
        switch (status) {
            case resources.status.SUCCESS: {
                return {
                    duration: 8000,
                    style: {
                        color: "black",
                        fontWeight: "bold",
                        background: "paleturquoise",
                    },
                };
            }
            case resources.status.ERROR: {
                return {
                    duration: 8000,
                    style: {
                        color: "black",
                        fontWeight: "bold",
                        background: "salmon",
                    },
                };
            }
            default: {
                break;
            }
        }
    };

    static formatQuantity(number) {
        return Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 4,
        }).format(number);
    }

    static formatCurrency(str) {
        let x = parseFloat(str).toFixed(2);
        let y = `${x}`;
        return y.replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    static formatMoney(number, currency) {
        return number.toLocaleString("en-US", {
            style: "currency",
            currency: currency,
        });
    }

    static formatBooleanString(str) {
        return str ? "yes" : "no";
    }

    static stringToBoolean(str) {
        if (typeof str === 'boolean') {
            return str;
        }
        if (typeof str === 'number') {
            return str===1;
        }
        return typeof str === 'string' || str instanceof String ? str==='1' : false;
    }

    static functionTitle(title) {
        return (
            <div className="function-title">
                {title}
            </div>
        );
    }

    static sortByKey(array, key) {
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    static cleanCell(cell) {
        var result = "";
        /*for (let i=0; i<cell.length; i++) {
            var character = cell.charAt(i);
            var characterCode = cell.charCodeAt(i);
            if (!(characterCode > 47 && characterCode < 58) && !(characterCode > 64 && characterCode < 91) && !(characterCode > 96 && characterCode < 123)) {
                result += character;
            }
            else {
                if (![' ', "    ", '_', '.', ',', '@', '/', ':', ';', '*', '(', ')', '-', '=', '+', '>', '<', '?', '[', ']', '{', '}', '!', '&', '%', '#', '$'].includes(character)) {
                    result += character;
                }
            }
        }*/
        return result;
    }

    static incrementString(string, qty) {  
        var number = string.match(/\d+/) === null ? 0 : string.match(/\d+/)[0];
        var numberLength = number.length
        number = (parseInt(number) + qty).toString();
        while (number.length < numberLength) {
            number = "0" + number;
        }
        return string.replace(/[0-9]/g, '').concat(number);
    }

    static getElapsedTimeSecs(start, end) {
        var timeDiff = end - start;
        timeDiff /= 1000;
        var seconds = Math.round(timeDiff);
        return seconds;
    }

    static randomColor() {
        return ("#" + (Math.floor(Math.random()*16777215).toString(16)));
    };

    static file_exists = (url) => {
        var xhr = new XMLHttpRequest();
        xhr.open("HEAD", url, false);
        xhr.send();
        return (xhr.status !== 404);
    }

    static chunk = (arr, size) => {
        const res = [];
        for(let i = 0; i < arr.length; i++) {
           if(i % size === 0){
              res.push([arr[i]]);
           }
           else{
              res[res.length-1].push(arr[i]);
           };
        };
        return res;
    }

    static testDuplicateRegex(text) {
        return !(/Copy [1-9]/.test(text));
    }

    static convertTimeTo12Hour(time24) {
        var ts = time24;
        var H = +ts.substr(0, 2);
        var h = (H % 12) || 12;
        h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
        var ampm = H < 12 ? " AM" : " PM";
        ts = h + ts.substr(2, 3) + ampm;
        return ts;
    };

}

export default Util;