import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { ItemDetailDialog } from "../../warehouse-inventory/item/ItemDetailDialog";
import {
	selectAuth,
	fetchAverageCostMethodAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ControlGroup,
	HTMLTable,
	InputGroup,
	Tooltip,
	Position,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import OmnibarSelect from "../../../common/OmnibarSelect";
import { JournalEntry } from "../../../common/JournalEntry";
import { InventoryTrackingDialogMod } from "../../warehouse-inventory/item/InventoryTrackingDialogMod";

export function StockIssuanceLineItem({id, document_date, is_editable, transfer_from, transaction_source, default_line_items, line_items, setLineItems, default_journal_entries, journal_entries, setJournalEntries, balancing_account, setBalancingAccount, items, item_classes, locations}) {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [is_item_detail_dialog_open, setIsItemDetailDialogOpen] = useState(false);
	const [has_expiry, setHasExpiry] = useState(true);
	const [with_serial_number, setWithSerialNumber] = useState(true);
	const [item, setItem] = useState(null);
	const [is_omnibar_open, setIsOmnibarOpen] = useState(false);
	const [omnibar_data, setOmnibarData] = useState([]);
	const [omnibar_ds_type, setOmnibarDsType] = useState(null);
	const [selected_inventory_tracking, setSelectedInventoryTracking] = useState(line_items[0]);
	const [quantity_label, setQuantityLabel] = useState(null);
	const [is_inventory_tracking_dialog_open, setIsInventoryTrackingDialogOpen] = useState(false);
	const [inventory_tracking_row, setInventoryTrackingRow] = useState(null);
	const [is_stock_transfer_request, setIsStockTransferRequest] = useState(false);
	const [serial_number_type, setSerialNumberType] = useState(null);

	useEffect(() => {
		if (transaction_source) {
			switch(transaction_source.value) {
				case auth.enums.transaction_sources.stock_issuance.STOCK_TRANSFER_REQUEST : {
					setIsStockTransferRequest(true);
					break;
				}
				case auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION : {
					setIsStockTransferRequest(false);
					break;
				}
				case auth.enums.transaction_sources.stock_issuance.INTERNAL_DELIVERY_RECEIPT : {
					setIsStockTransferRequest(false);
					break;
				}
				case auth.enums.transaction_sources.stock_issuance.EXTERNAL_DELIVERY_RECEIPT : {
					setIsStockTransferRequest(false);
					break;
				}
				case auth.enums.transaction_sources.stock_issuance.DIRECT_ISSUANCE : {
					setIsStockTransferRequest(false);
					break;
				}
				default : break;
			}
		}
    }, [transaction_source, auth.enums.transaction_sources.stock_issuance]);

	const handleSaveInventoryTrackingButtonClickedEvent = (inventoryTracking) => {
		setLineItems(line_items.map((lineItem, key) => (key===inventoryTracking.inventory_tracking_row ? {
			item:lineItem.item, 
			requested_quantity:lineItem.requested_quantity, 
			balance_quantity:lineItem.balance_quantity, 
			cancel_quantity:lineItem.cancel_quantity, 
			quantity:lineItem.quantity, 
			unit_cost:lineItem.unit_cost, 
			amount:lineItem.amount, 
			uom:lineItem.uom, 
			from_location:lineItem.from_location,
			serial_numbers:inventoryTracking.serial_numbers,
			expiry_dates:inventoryTracking.expiry_dates,
			expiry_quantities:inventoryTracking.expiry_quantities,
			item_conditions:inventoryTracking.item_conditions,
		} : lineItem)));
		setIsInventoryTrackingDialogOpen(false);
	}

	const handleInventoryTrackingButtonClickedEvent = (row, quantityLabel) => {
		let inventoryItem = items.filter((item) => (item.id===line_items[row].item.value))[0];
		let hasExpiry = Util.stringToBoolean(inventoryItem.has_expiry);
		let withSerialNumber = Util.stringToBoolean(inventoryItem.with_serial_number);
		setHasExpiry(hasExpiry);
		setWithSerialNumber(withSerialNumber);
		setSerialNumberType(inventoryItem.serial_number_type);
		if (hasExpiry || withSerialNumber) {
			setSelectedInventoryTracking(line_items[row]);
			setQuantityLabel(quantityLabel);
			setInventoryTrackingRow(row);
			setIsInventoryTrackingDialogOpen(true);
		}
		else {
			toast.error(
				translate.message.ITEM_HAS_NO_SERIAL_NUMBER_AND_EXPIRY,
				Util.getToasterStyle(resources.status.ERROR)
			);
		}
	};

	const handleDisplayItemDetailsButtonClickedEvent = (selectedItem) => {
		setItem(items.filter((item) => (item.id===selectedItem.value))[0]);
		setIsItemDetailDialogOpen(true);
	};

	const handleOmnibarItemSelectedEvent = (selectedItem) => {
		switch (selectedItem.ds_type.data_source) {
			case resources.omnibar_ds_types.FROM_LOCATION : {
				let lineItems = line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id, 
					item:lineItem.item, 
					requested_quantity:lineItem.requested_quantity, 
					balance_quantity:lineItem.balance_quantity, 
					cancel_quantity:lineItem.cancel_quantity, 
					quantity:lineItem.quantity, 
					unit_cost:lineItem.unit_cost, 
					amount:lineItem.amount, 
					uom:lineItem.uom,
					from_location:{label:selectedItem.item.label, value:selectedItem.item.id},
					serial_numbers:lineItem.serial_numbers,
					expiry_dates:lineItem.expiry_dates,
					expiry_quantities:lineItem.expiry_quantities,
					item_conditions:lineItem.item_conditions,
				} : lineItem));
				let lineItem = lineItems.find((lineItem,key) => key===selectedItem.ds_type.row);
				if (lineItem.item && selectedItem.item && lineItem.uom) {
					dispatch(fetchAverageCostMethodAsync({item_id:lineItem.item.value, location_id:selectedItem.item.id, document_date:document_date, unit_of_measure_id:lineItem.uom.value})).then((res) => res.payload.data && res.payload.data.average_cost_method ? setLineItems(lineItems.map((li, key) => (key===selectedItem.ds_type.row ? {
						id:lineItem.id, 
						item:lineItem.item, 
						requested_quantity:lineItem.requested_quantity, 
						balance_quantity:lineItem.balance_quantity, 
						cancel_quantity:lineItem.cancel_quantity, 
						quantity:lineItem.quantity, 
						unit_cost:(res.payload.data.multiplier*parseFloat(res.payload.data.average_cost_method.average_unit_cost)), 
						amount:parseFloat(lineItem.quantity)*(res.payload.data.multiplier*parseFloat(res.payload.data.average_cost_method.average_unit_cost)), 
						uom:lineItem.uom,
						from_location:{label:selectedItem.item.label, value:selectedItem.item.id},
						serial_numbers:lineItem.serial_numbers,
						expiry_dates:lineItem.expiry_dates,
						expiry_quantities:lineItem.expiry_quantities,
						item_conditions:lineItem.item_conditions,
					} : li))) : void(0));
				}
				else {
					setLineItems(lineItems);
				}
				break;
			}
			case resources.omnibar_ds_types.UNIT_OF_MEASURE : {
				let lineItems = line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id, 
					item:lineItem.item, 
					requested_quantity:lineItem.requested_quantity, 
					balance_quantity:lineItem.balance_quantity, 
					cancel_quantity:lineItem.cancel_quantity, 
					quantity:lineItem.quantity, 
					unit_cost:lineItem.unit_cost, 
					amount:lineItem.amount, 
					uom:{label:selectedItem.item.code, value:selectedItem.item.id},
					from_location:lineItem.from_location,
					serial_numbers:lineItem.serial_numbers,
					expiry_dates:lineItem.expiry_dates,
					expiry_quantities:lineItem.expiry_quantities,
					item_conditions:lineItem.item_conditions,
				} : lineItem));
				let lineItem = lineItems.find((lineItem,key) => key===selectedItem.ds_type.row);
				if (lineItem.item && lineItem.from_location && selectedItem.item.id) {
					dispatch(fetchAverageCostMethodAsync({item_id:lineItem.item.value, location_id:lineItem.from_location.value, document_date:document_date, unit_of_measure_id:selectedItem.item.id})).then((res) => res.payload.data && res.payload.data.average_cost_method ? setLineItems(lineItems.map((li, key) => (key===selectedItem.ds_type.row ? {
						id:lineItem.id, 
						item:lineItem.item, 
						requested_quantity:lineItem.requested_quantity, 
						balance_quantity:lineItem.balance_quantity, 
						cancel_quantity:lineItem.cancel_quantity, 
						quantity:lineItem.quantity, 
						unit_cost:(res.payload.data.multiplier*parseFloat(res.payload.data.average_cost_method.average_unit_cost)), 
						amount:parseFloat(lineItem.quantity)*(res.payload.data.multiplier*parseFloat(res.payload.data.average_cost_method.average_unit_cost)), 
						uom:{label:selectedItem.item.code, value:selectedItem.item.id},
						from_location:lineItem.from_location,
						serial_numbers:lineItem.serial_numbers,
						expiry_dates:lineItem.expiry_dates,
						expiry_quantities:lineItem.expiry_quantities,
						item_conditions:lineItem.item_conditions,
					} : li))) : void(0));
				}
				else {
					setLineItems(lineItems);
				}
				break;
			}
			case resources.omnibar_ds_types.ITEM : {
				setLineItems(line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id, 
					item:{
						label:selectedItem.item.label, 
						default_uom_name:selectedItem.item.default_uom_name, 
						item_costing:selectedItem.item.item_costing, 
						serial_number_type:selectedItem.item.serial_number_type, 
						value:selectedItem.item.id
					}, 
					requested_quantity:lineItem.requested_quantity,
					balance_quantity:lineItem.balance_quantity,
					cancel_quantity:lineItem.cancel_quantity, 
					quantity:lineItem.quantity, 
					unit_cost:lineItem.unit_cost, 
					amount:lineItem.amount, 
					uom:null,
					from_location:lineItem.from_location,
					serial_numbers:[],
					expiry_dates:[],
					expiry_quantities:[],
					item_conditions:[],
				} : lineItem)));
				break;
			}
			default: break;
		}
	}

	const handleFromLocationInputClickedEvent = (row) => {
		let locs = [];
		if (transfer_from!==null) {
			locs = locations.filter((loc) => (loc.pcc_id===transfer_from.value)).map((location,key) => ({id:location.id, code:location.code, label:`${location.code} | ${location.name}`}));
		}
		else {
			locs = locations.map((location,key) => ({id:location.id, code:location.code, label:`${location.code} | ${location.name}`}));
		}
		setIsOmnibarOpen(true);
		setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.FROM_LOCATION});
		setOmnibarData(locs);
	}

	const handleItemInputClickedEvent = (row) => {
		let itms = [];
		if (transaction_source) {
			if (transfer_from!==null) {
				//let itemClasses = item_classes.filter((itemClass) => (itemClass.location_ids.includes(transfer_from.value))).map((itemClass) => itemClass.name);
				switch (transaction_source.value) {
					case auth.enums.transaction_sources.stock_issuance.DIRECT_ISSUANCE : {
						itms = items.filter((itm, i) => (Util.stringToBoolean(itm.is_enabled))).map((item,key) => ({id:item.id, code:item.code, default_uom_name:item.unit_of_measure.name, item_costing:item.item_costing, label:`${item.code} | ${item.description}`}));
						break;
					}
					case auth.enums.transaction_sources.stock_issuance.INTERNAL_DELIVERY_RECEIPT : {
						itms = items.filter((itm, i) => (Util.stringToBoolean(itm.is_enabled) && Util.stringToBoolean(itm.item_class.for_delivery_receipt))).map((item,key) => ({id:item.id, code:item.code, default_uom_name:item.unit_of_measure.name, item_costing:item.item_costing, label:`${item.code} | ${item.description}`}));
						break;
					}
					case auth.enums.transaction_sources.stock_issuance.EXTERNAL_DELIVERY_RECEIPT : {
						itms = items.filter((itm, i) => (Util.stringToBoolean(itm.is_enabled) && Util.stringToBoolean(itm.item_class.for_delivery_receipt))).map((item,key) => ({id:item.id, code:item.code, default_uom_name:item.unit_of_measure.name, item_costing:item.item_costing, label:`${item.code} | ${item.description}`}));
						break;
					}
					case auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION : {
						itms = items.filter((itm, i) => (Util.stringToBoolean(itm.is_enabled)&&Util.stringToBoolean(itm.is_component))).map((item,key) => ({id:item.id, code:item.code, default_uom_name:item.unit_of_measure.name, item_costing:item.item_costing, label:`${item.code} | ${item.description}`}));
						break;
					}
					default: {
						itms = items.filter((item) => Util.stringToBoolean(item.is_enabled)).map((item,key) => ({id:item.id, code:item.code, default_uom_name:item.unit_of_measure.name, item_costing:item.item_costing, label:`${item.code} | ${item.description}`}));
						break;
					}
				}
			}
			else {
				itms = items.filter((item) => Util.stringToBoolean(item.is_enabled)).map((item,key) => ({id:item.id, code:item.code, default_uom_name:item.unit_of_measure.name, item_costing:item.item_costing, label:`${item.code} | ${item.description}`}));
			}

		}
		setIsOmnibarOpen(true);
		setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.ITEM});
		setOmnibarData(itms);
	}

	const handleUomInputClickedEvent = (row) => {
		let unitOfMeasures = [];
		let selectedItem = line_items[row].item ? items.filter((item) => (item.id===line_items[row].item.value))[0] : null;
		if (selectedItem!==null) {
			unitOfMeasures = selectedItem.unit_of_measure_sets.map((uom) => ({id:uom.id, code:uom.code, label:uom.code}));
			setIsOmnibarOpen(true);
			setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.UNIT_OF_MEASURE});
			setOmnibarData(unitOfMeasures);
		}
	}

	const handleQuantityInputChangedEvent = (event, row) => {
		let quantity = event.target.value<0 ? 0 : event.target.value;
		setLineItems(line_items.map((lineItem,key) => (key===row ? 
		{
			id:lineItem.id, 
			item:lineItem.item, 
			requested_quantity:lineItem.requested_quantity, 
			balance_quantity:lineItem.balance_quantity, 
			cancel_quantity:lineItem.cancel_quantity, 
			quantity:quantity, 
			unit_cost:lineItem.unit_cost, 
			amount:isNaN((parseFloat(quantity)*parseFloat(lineItem.unit_cost))) ? '' : (parseFloat(quantity)*parseFloat(lineItem.unit_cost)).toFixed(2), 
			uom:lineItem.uom,
			from_location:lineItem.from_location,
			serial_numbers:lineItem.serial_numbers,
			expiry_dates:lineItem.expiry_dates,
			expiry_quantities:lineItem.expiry_quantities,
			item_conditions:lineItem.item_conditions,
		} : lineItem)));
	}

	const handleLineItemDeleteButtonClickedEvent = (row) => {
		setLineItems(line_items.filter((lineItem, key) => key!==row));
        setJournalEntries(journal_entries.filter((journalEntry,key) => key!==row));
	};

	return (
		<ControlGroup className="col-sm-12 col-md-8 col-lg-9 tbl-parent" vertical={true} style={{background: "grey", border:"solid lightgrey 1px", alignItems:"stretch", overflowX:"hidden", overflowY:"scroll", maxHeight:"50vh"}}>
			<ControlGroup className="tbl-group-xl" vertical={true}>

				<OmnibarSelect 
					is_omnibar_open={is_omnibar_open} 
					setIsOmnibarOpen={setIsOmnibarOpen} 
					omnibar_data={omnibar_data}
					omnibar_ds_type={omnibar_ds_type}
					handleOmnibarItemSelectedEvent={handleOmnibarItemSelectedEvent}
				/>

				<ItemDetailDialog
					item={item}
					is_item_detail_dialog_open={is_item_detail_dialog_open}
					setIsItemDetailDialogOpen={setIsItemDetailDialogOpen}
				/>

				{
					is_inventory_tracking_dialog_open && 
					<InventoryTrackingDialogMod
						document_type={auth.enums.document_types.STOCK_ISSUANCE}
						is_inventory_tracking_dialog_open={is_inventory_tracking_dialog_open}
						setIsInventoryTrackingDialogOpen={setIsInventoryTrackingDialogOpen}
						quantity_label={quantity_label}
						selected_inventory_tracking={selected_inventory_tracking}
						has_expiry={has_expiry}
						with_serial_number={with_serial_number}
						handleSaveInventoryTrackingButtonClickedEvent={handleSaveInventoryTrackingButtonClickedEvent}
						inventory_tracking_row={inventory_tracking_row}
						is_editable={is_editable}
						serial_number_type={serial_number_type}
					/>
				}

		        <HTMLTable 
		        	className="line-item-table line-item-table-header"
		        >
		            <tbody>
		            	<tr>
		            		<th className="line line-number"></th>
		            		<th className="input-line line-item">Item</th>
		            		{is_stock_transfer_request&&<th className="input-line quantity">Request Qty</th>}
		            		{is_stock_transfer_request&&<th className="input-line quantity">Balance Qty</th>}
		            		<th className="input-line uom">UOM</th>
		            		<th className="input-line quantity">Qty</th>
		            		<th className="input-line unit-cost">Unit Cost</th>
		            		<th className="input-line total-amount">Amount</th>
		            		<th className="input-line location">From Location</th>
					        {
					        	((transaction_source!==null && (transaction_source.value===auth.enums.transaction_sources.stock_issuance.DIRECT_ISSUANCE||transaction_source.value===auth.enums.transaction_sources.stock_issuance.INTERNAL_DELIVERY_RECEIPT||transaction_source.value===auth.enums.transaction_sources.stock_issuance.EXTERNAL_DELIVERY_RECEIPT)) && is_editable) &&
		            			<th className="input-line delete-line-button"></th>
		            		}
		            	</tr>
		            </tbody>
		        </HTMLTable>

		        <HTMLTable 
		        	className="line-item-table"
					style={{
						background: "#F4F4F4",
					}}
		        >
		            <tbody>
		            	{
		            		line_items.map((lineItem, key) => 
		            			<tr key={key} style={{outline: "thin solid lightgrey"}}>
			                    	<td className="line line-number">
			                    		<ControlGroup>
			                        		<Button text={key+1} minimal={true}/>
			                        	</ControlGroup>
			                    	</td>
			                        <td className="input-line line-item">
			                        	<ControlGroup vertical={false}>
				                    		<Button disabled={!line_items[key].item} icon={<Tooltip disabled={line_items[key].item===null} intent={"primary"} content={line_items[key].item ? <span style={{fontSize:"9pt"}}>View Item Details</span> : "Select an Item to view details"} position={Position.RIGHT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} minimal={true} style={{paddingLeft:"15px",paddingRight:"15px"}} onClick={e => (line_items[key].item ? handleDisplayItemDetailsButtonClickedEvent(line_items[key].item) : null)}/>
				                        	<InputGroup fill={true} value={lineItem.item ? lineItem.item.label : ''} readOnly={true} onClick={e => (transfer_from && transaction_source && (transaction_source.value===auth.enums.transaction_sources.stock_issuance.EXTERNAL_DELIVERY_RECEIPT||transaction_source.value===auth.enums.transaction_sources.stock_issuance.INTERNAL_DELIVERY_RECEIPT||transaction_source.value===auth.enums.transaction_sources.stock_issuance.DIRECT_ISSUANCE))&&is_editable ? handleItemInputClickedEvent(key) : null}/>
			                        	</ControlGroup>
			                        </td>
			                        {
			                        	is_stock_transfer_request &&
				                        <td className="input-line quantity">
				                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
				                        		<InputGroup fill={true} value={lineItem.requested_quantity} style={{textAlign:"right"}} readOnly={true}/>
				                        	</ControlGroup>
				                        </td>
			                        }
			                        {
			                        	is_stock_transfer_request &&
				                        <td className="input-line quantity">
				                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
				                        		<InputGroup fill={true} value={lineItem.balance_quantity} style={{textAlign:"right"}} readOnly={true}/>
				                        	</ControlGroup>
				                        </td>
			                        }
			                        <td className="input-line uom">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true}  value={lineItem.uom ? lineItem.uom.label : ''} readOnly={true} onClick={e => (transaction_source && (transaction_source.value===auth.enums.transaction_sources.stock_issuance.EXTERNAL_DELIVERY_RECEIPT||transaction_source.value===auth.enums.transaction_sources.stock_issuance.INTERNAL_DELIVERY_RECEIPT||transaction_source.value===auth.enums.transaction_sources.stock_issuance.DIRECT_ISSUANCE))&&is_editable ? handleUomInputClickedEvent(key) : null}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line quantity">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
				                    		<Button disabled={id===null||!line_items[key].quantity} icon={<Tooltip disabled={line_items[key].quantity.length===0} intent={"primary"} content={line_items[key].quantity.length>0 ? <span style={{fontSize:"9pt"}}>View/Edit/Load Expiry Date & Serial Number (Issue Qty)</span> : "Not Applicable / Disabled"} position={Position.LEFT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} onClick={e => handleInventoryTrackingButtonClickedEvent(key, translate.text.ISSUE_QUANTITY)} minimal={true}/>
			                        		<InputGroup type="number" fill={true} value={lineItem.quantity} style={{textAlign:"right"}} onChange={e => is_editable ? handleQuantityInputChangedEvent(e, key) : void(0)} readOnly={(transaction_source&&transaction_source.value===auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION)||!is_editable}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line unit-cost">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.unit_cost} style={{textAlign:"right"}} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line total-amount">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.amount} style={{textAlign:"right"}} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line location">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true}  value={lineItem.from_location ? lineItem.from_location.label : ''} readOnly={true} onClick={e => is_editable ? handleFromLocationInputClickedEvent(key) : void(0)}/>
			                        	</ControlGroup>
			                        </td>
							        {
							        	((transaction_source!==null && (transaction_source.value===auth.enums.transaction_sources.stock_issuance.DIRECT_ISSUANCE||transaction_source.value===auth.enums.transaction_sources.stock_issuance.INTERNAL_DELIVERY_RECEIPT||transaction_source.value===auth.enums.transaction_sources.stock_issuance.EXTERNAL_DELIVERY_RECEIPT)) && is_editable) &&
				                    	<td className="input-line delete-line-button">
				                    		<Button icon={<Icon icon="cross" size={12}/>} intent="danger" minimal={true} onClick={e => handleLineItemDeleteButtonClickedEvent(key)} disabled={!is_editable||line_items.length<=1}/>
				                    	</td>
			                    	}
		            			</tr>
		            		)
		            	}

		            </tbody>
		        </HTMLTable>

			</ControlGroup>

	        <ControlGroup className="tbl-group-xl" vertical={false}>
	        	<Button 
	        		className="function-button"
	        		large={false} 
	        		onClick={e => setLineItems([...line_items, default_line_items[0]])} 
	        		icon={<Icon icon="add" className="orange"/>}
	        		text={translate.button.ADD_MORE_ITEMS}
					disabled={!is_editable}
	        	/>
				<Button 
					className="function-button"
					large={false} 
					onClick={e => setLineItems(default_line_items)} 
					icon={<Icon icon="remove" className="orange"/>}
					text={translate.button.REMOVE_ALL_ITEMS}
					disabled={!is_editable||line_items.length<=1}
				/>
	        </ControlGroup>

			{
				id &&
				<ControlGroup className="tbl-group-xl" vertical={true}>
					<JournalEntry
						default_journal_entries={default_journal_entries}
						journal_entries={journal_entries}
						setJournalEntries={setJournalEntries}
						profit_cost_centers={[]}
						balancing_account={balancing_account}
						setBalancingAccount={setBalancingAccount}
						is_editable={is_editable}
					/>
				</ControlGroup>
			}

	    </ControlGroup>

	);
}