import React, { useCallback, useEffect, useState } from "react";
import { Dialog, Classes, Icon, Button, ButtonGroup } from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import {
	selectAuth,
	fetchAllItemsAsync,
    pullInventoryItemsAsync
} from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { resources } from "../../../resources";
import Util from "../../../common/Util";
import DataTable from 'react-data-table-component';

export function SelectPosItemsDialog({is_select_pos_items_dialog_open, setIsSelectPosItemsDialogOpen, itemType}) {

    const auth = useSelector(selectAuth);
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [headers] = useState(["id", "code", "description", "item class", "selling price"]);
    const [columns] = useState(headers.map((header, key) => ({
        name:header.toUpperCase(),
        selector: row => row[header.replaceAll(' ','_')],
        sortable: true,
        width: (key===0 ? 70 : 160)+"px"
    })));
    const [data, setData] = useState([]);
    const [selected_rows, setSelectedRows] = useState([]);

	const initFetch = useCallback(() => {
        if (is_select_pos_items_dialog_open) {
            dispatch(fetchAllItemsAsync({is_pos_item:auth.enums.boolean.FALSE})).then((response) => (response.payload.data ? setItems(response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)))) : void(0)));
        }
	}, [
		dispatch,
        is_select_pos_items_dialog_open,
        auth.enums.boolean
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		setData(items.map((item) => ({
            id:item.id,
            code:item.code,
            description:item.description,
            item_class:item.item_class.name,
            selling_price:`${auth.active_company.currency.symbol} ${item.unit_cost}`,
        })));
	}, [items, auth.active_company.currency.symbol]);

    const handleDatatableRowsSelected = (obj) => {
        setSelectedRows(obj.selectedRows);
    }

    const handlePullDataButtonClicked = () => {
        let itemIds = selected_rows.map((row) => row.id);
        const handleResponse = (response) => {
            if (response.payload.status===resources.status.SUCCESS) {
                setIsSelectPosItemsDialogOpen(false);
                setSelectedRows([]);
            }
        }
        dispatch(pullInventoryItemsAsync({item_ids:itemIds, item_type:itemType})).then((response) => handleResponse(response));
    }

    return (
        <Dialog
            title={`Select "${itemType}" Items`}
            icon={<Icon icon="info-sign" className="orange"/>}
            isOpen={is_select_pos_items_dialog_open}
            onClose={e => setIsSelectPosItemsDialogOpen(false)}
            isCloseButtonShown={true}
            className="col-sm-12 col-md-9 col-lg-7"
        >
            <div className={Classes.DIALOG_BODY}>
					<ButtonGroup className="button-group" minimal={false}>
						<Button
							text={"Add Selected Item/s"}
							className="function-button"
							disabled={auth.status === resources.status.LOADING||selected_rows.length===0}
							icon={<Icon icon="import" className="orange"/>}
							onClick={() => handlePullDataButtonClicked()}
						/>
					</ButtonGroup>
                <DataTable
                    columns={columns}
                    data={data}
                    theme="light"
                    pagination
                    dense
                    selectableRows
                    onSelectedRowsChange={handleDatatableRowsSelected}
                />
            </div>
        </Dialog>
    );
}