import React from "react";
import { MuiAutocomplete } from "../../common/MuiAutocomplete";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resources } from "../../resources/index";
import {
	selectAuth,
	signupAsync,
	fetchAllCountriesAsync
} from "./authSlice";
import {
	Icon,
	IconSize,
	InputGroup,
	MultistepDialog,
	DialogStep,
	TextArea,
	Intent,
	FormGroup,
	Text,
	Checkbox
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

export function CreateAccountDialog({is_create_account_dialog_open, setIsCreateAccountDialogOpen}) {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirm_password, setConfirmPassword] = useState("");
	const [country, setCountry] = useState(null);
	const [first_name, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [company_code, setCompanyCode] = useState("");
	const [company_name, setCompanyName] = useState("");
	const [company_address, setCompanyAddress] = useState("");
	const [countries, setCountries] = useState([]);
	const [industries] = useState(Object.values(resources.industries).map((industry) => ({label:industry, value:industry})));
	const [industry, setIndustry] = useState(null);
	const [is_password_visible, setIsPasswordVisible] = useState(false);
	const [inventory] = useState(false);
	const [pos] = useState(false);

	useEffect(() => {
		const handleFetchAllCountriesResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setCountries(response.payload.data.map((country) => ({label:country.name, value:country.id})))
			}
		}
		dispatch(fetchAllCountriesAsync({})).then((response) => handleFetchAllCountriesResponse(response));
	}, [dispatch]);

	const reset = () => {
		setEmail("");
		setPassword("");
		setConfirmPassword("");
		setCountry(null);
		setFirstName("");
		setLastName("");
		setCompanyCode("");
		setCompanyName("");
		setCompanyAddress("");
		setIndustry(null);
		setIsCreateAccountDialogOpen(false);
	}

	const finalButtonClickResponse = (response) => {
		if (response.payload.status===resources.status.SUCCESS) {
			reset();
		}
	}

	const finalButtonClickHandler = () => {
		dispatch(
			signupAsync({
				email: email,
				password: password,
				confirm_password: confirm_password,
				first_name: first_name,
				last_name: last_name,
				country: country.value,
				company_code: company_code,
				company_name: company_name,
				company_address: company_address,
				industry: industry.value,
				inventory: inventory,
				pos: pos,
			})
		).then((response) => (finalButtonClickResponse(response)));
	};

	const finalButtonProps = {
		intent: Intent.PRIMARY,
		onClick: finalButtonClickHandler,
		text: translate.button.CREATE,
		disabled: email.length===0||first_name.length===0||last_name.length===0||password.length===0||confirm_password.length===0||password!==confirm_password||company_code.length===0||company_name.length===0||country===null||company_address.length===0||industry===null
	};

	const handleTogglePasswordVisibilityEvent = () => {
		setIsPasswordVisible(!is_password_visible);
	}

	const handleCloseDialogEvent = () => {
		reset();
	}

	return (

		<MultistepDialog
			isOpen={is_create_account_dialog_open}
			onClose={(e) => handleCloseDialogEvent()}
			title={translate.text.CREATE_ACCOUNT}
			finalButtonProps={finalButtonProps}
			nextButtonProps={{
				disabled: (email.length===0||first_name.length===0||last_name.length===0||password.length===0||confirm_password.length===0||password!==confirm_password||password.length<8||(password.indexOf("@")<0&&password.indexOf("#")<0&&password.indexOf("$")<0&&password.indexOf("%")<0&&password.indexOf("^")<0&&password.indexOf("&")<0&&password.indexOf("+")<0&&password.indexOf("=")<0))
			}}
			usePortal={true}
			style={{marginTop:52}}
		>
			<DialogStep
				panel={

					<div className="create-account-panel">
						<div className="create-account-container">

								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-12 mt-5">
										<InputGroup
											className="text-field"
											leftIcon={
												<Icon
													icon="envelope"
													size={IconSize.SMALL}
													className="orange"
												/>
											}
											placeholder={translate.placeholder.ENTER_EMAIL_ADDRESS}
											large={true}
											round={true}
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											autoComplete="off"
											maxLength={100}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6 mt-5">
										<InputGroup
											className="text-field"
											leftIcon={
												<Icon
													icon="label"
													size={IconSize.SMALL}
													className="orange"
												/>
											}
											placeholder={translate.placeholder.ENTER_FIRST_NAME}
											large={true}
											round={true}
											value={first_name}
											onChange={(e) => setFirstName(e.target.value)}
											maxLength={100}
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 mt-5">
										<InputGroup
											className="text-field"
											leftIcon={
												<Icon
													icon="label"
													size={IconSize.SMALL}
													className="orange"
												/>
											}
											placeholder={translate.placeholder.ENTER_LAST_NAME}
											large={true}
											round={true}
											value={last_name}
											onChange={(e) => setLastName(e.target.value)}
											maxLength={100}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6 mt-5">
										<InputGroup
											className="text-field"
											leftIcon={
												<Icon
													icon="lock"
													size={IconSize.SMALL}
													className="orange"
												/>
											}
											type={is_password_visible ? "text" : "password"}
											placeholder={translate.placeholder.ENTER_PASSWORD}
											large={true}
											round={true}
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											maxLength={100}
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 mt-5">
										<InputGroup
											className="text-field"
											leftIcon={
												<Icon
													icon="lock"
													size={IconSize.SMALL}
													className="orange"
												/>
											}
											type={is_password_visible ? "text" : "password"}
											placeholder={translate.placeholder.ENTER_CONFIRM_PASSWORD}
											large={true}
											round={true}
											value={confirm_password}
											onChange={(e) => setConfirmPassword(e.target.value)}
											maxLength={100}
										/>
									</div>
								</div>

								{
									password.length>0 &&
									<div className="row mb-5">
										<div className="col-sm-12 col-md-12 col-lg-12">
											<Text style={{color:"red", fontWeight:"bold", fontSize:"10pt", marginLeft:"10px"}}>
												{
													confirm_password.length>0 && confirm_password!==password &&
													<div>{translate.message.PASSWORD_DOES_NOT_MATCH}</div>
												}
												{
													password.length<8 &&
													<div>Password must have at least 8 characters.</div>
												}
												{
													(password.indexOf("@")<0&&password.indexOf("#")<0&&password.indexOf("$")<0&&password.indexOf("%")<0&&password.indexOf("^")<0&&password.indexOf("&")<0&&password.indexOf("+")<0&&password.indexOf("=")<0)&&
													<div>Password must contain at least one of the special characters: @#$%^&+=</div>
												}
											</Text>
										</div>
									</div>
								}

								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6 mb-5 mt-5">
										<Checkbox
											style={{marginLeft:10}}
											checked={ is_password_visible }
											large={false}
											inline={true}
											label={is_password_visible ? "Hide Password" : "Show Password"}
											onChange={e => handleTogglePasswordVisibilityEvent()}
										>
											<Icon className="ml-5 orange" size={20} icon={is_password_visible ? "eye-open" : "eye-off"}/>
										</Checkbox>
									</div>
								</div>


						</div>
					</div>

				}
				title={translate.text.BASIC_INFO}
			/>
			<DialogStep
				panel={

					<div className="create-account-panel">
						<div className="create-account-container">
							<FormGroup
								className="input-form-group"
							>
								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-5 mt-5">
										<InputGroup
											className="mr-2"
											leftIcon={
												<Icon
													icon="label"
													size={IconSize.SMALL}
													className="orange"
												/>
											}
											placeholder={translate.placeholder.ENTER_COMPANY_CODE}
											large={true}
											round={true}
											value={company_code}
											onChange={(e) => setCompanyCode(e.target.value)}
											maxLength={100}
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-7 mt-5">
										<InputGroup
											className="ml-2"
											leftIcon={
												<Icon
													icon="label"
													size={IconSize.SMALL}
													className="orange"
												/>
											}
											placeholder={translate.placeholder.ENTER_COMPANY_NAME}
											large={true}
											round={true}
											value={company_name}
											onChange={(e) => setCompanyName(e.target.value)}
											maxLength={200}
										/>
									</div>
								</div>
							</FormGroup>
							<FormGroup
								className="input-form-group"
							>
								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6 mt-5">
										<MuiAutocomplete 
											className="mr-2"
											setSelectedValue={setIndustry}
											selected_value={industry}
											isMultiple={false}
											label={translate.placeholder.SELECT_INDUSTRY} 
											data={industries}
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 mt-5">
										<MuiAutocomplete 
											className="mr-2"
											setSelectedValue={setCountry}
											selected_value={country}
											isMultiple={false}
											label={translate.placeholder.SELECT_COUNTRY} 
											data={countries}
										/>
									</div>
								</div>
							</FormGroup>
							<FormGroup
								className="input-form-group"
							>
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-12 mt-5">
										<TextArea
											className="ml-2"
											fill={true}
											growVertically={true}
											large={true}
											intent={Intent.PRIMARY}
											placeholder={
												translate.placeholder.ENTER_COMPANY_ADDRESS
											}
											value={company_address}
											onChange={(e) =>
												setCompanyAddress(e.target.value)
											}
											maxLength={200}
										/>
									</div>
								</div>
							</FormGroup>

						</div>
					</div>

				}
				title={translate.text.COMPANY_DETAILS}
			/>
			{/*
			<DialogStep
				panel={

					<div className="create-account-panel">
						<div className="create-account-container">
								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6 mb-5 mt-5">
										<Checkbox
											style={{marginLeft:10}}
											checked={ inventory }
											large={false}
											inline={true}
											label={"Inventory"}
											onChange={() => setInventory(!inventory)}
										/>
										<Checkbox
											style={{marginLeft:10}}
											checked={ pos }
											large={false}
											inline={true}
											label={"Point-of-Sale"}
											onChange={() => setPos(!pos)}
										/>
									</div>
								</div>


						</div>
					</div>

				}
				title={translate.text.SELECT_MODULES}
			/>
			*/}
		</MultistepDialog>



	);
}