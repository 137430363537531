import React from "react";
import LoadingScreen from "./common/LoadingScreen";
import { Navbar, Button, Breadcrumbs, Popover, Menu, MenuItem, Icon, Text } from '@blueprintjs/core';
import { useSelector, useDispatch } from 'react-redux';
import { logoutAsync, selectAuth, toggleSidebarCollapsed } from './features/auth/authSlice';
import { resources } from "./resources/index";
import { Toast } from "./common/Toast";
import './App.css';
import logo from "./logo.png";
import { Notifier } from "./Notifier";
import { useHistory } from "react-router-dom";

export function Navigation() {

	const auth = useSelector(selectAuth);
	const history = useHistory();
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();

	const handleMenuItemClickedEvent = (url) => {
		if (!auth.is_sidebar_collapsed) {
			dispatch(toggleSidebarCollapsed());
		}
		history.push(url);
	}

	return (
		<>
			<Navbar fixedToTop={true} style={{backgroundColor:"cornflowerblue", zIndex:2000}}>

				<LoadingScreen is_loading={auth.status === resources.status.LOADING}/>
				<Toast />

				{
					auth.token!==null &&
					<div style={{display:"flex", alignItems:"center"}}>

						{
							<Button
								style={{marginRight:20}}
								icon="menu"
								minimal={true}
								intent="primary"
								onClick={() =>  dispatch(toggleSidebarCollapsed())}
							/>
						}

						<img id="company-logo" className="company-logo" alt="company-logo" src={logo} />

						<Breadcrumbs
							intent={"success"}
							className='navbar-breadcrumb'
							items={auth.navigation}
						/>
						<div className='navbar-buttons'>
							
							{
								!(auth.active_company.inventory===0 && auth.active_company.pos===0) &&
								<Notifier/>
							}

							<Popover
								content={
									<Menu large={false}>
										<MenuItem onClick={() => handleMenuItemClickedEvent("/my-subscription")} text={translate.text.MY_SUBSCRIPTION}></MenuItem>
										<MenuItem onClick={() => handleMenuItemClickedEvent("/change-password")} text={translate.text.CHANGE_PASSWORD}></MenuItem>
										<MenuItem onClick={e => dispatch(logoutAsync())} text={translate.text.LOGOUT}></MenuItem>
									</Menu>
								}
								minimal={false}
								placement="bottom"
							>
								<Button
									icon={<Icon style={{color:"darkorange"}} icon='user'/>}
									text={<Text className="navbar-email-text" ellipsize={true}>{auth.user.name}</Text>}
									className='bp3-minimal'
								/>
							</Popover>
						</div>
					</div>
				}

			</Navbar>
		</>
	);

}
