import React, { useEffect, useState } from "react";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { resources } from "../resources/index";
import {
    ControlGroup,
    HTMLTable,
    InputGroup,
    Button
} from "@blueprintjs/core";
import OmnibarSelect from "./OmnibarSelect";

export function JournalEntry({default_journal_entries, journal_entries, setJournalEntries, profit_cost_centers, balancing_account, setBalancingAccount, is_editable}) {
    const [is_omnibar_open, setIsOmnibarOpen] = useState(false);
    const [omnibar_data] = useState([]);
    const [omnibar_ds_type] = useState(null);
    const [total_debit_amount, setTotalDebitAmount] = useState(0);
    const [total_credit_amount, setTotalCreditAmount] = useState(0);
    const [balancing_amount, setBalancingAmount] = useState(0);

    useEffect(() => {
        let debitAmount = (journal_entries.reduce((total, journalEntry) => total += (journalEntry.debit_amount.length>0 ? parseFloat(journalEntry.debit_amount) : 0), 0)).toFixed(2);
        let creditAmount = (journal_entries.reduce((total, journalEntry) => total += (journalEntry.credit_amount.length>0 ? parseFloat(journalEntry.credit_amount) : 0), 0)).toFixed(2);
        setTotalDebitAmount(debitAmount);
        setTotalCreditAmount(creditAmount);
        setBalancingAmount(debitAmount-creditAmount);
    }, [journal_entries]);

    const handleOmnibarItemSelectedEvent = (selectedItem) => {
        switch (selectedItem.ds_type.data_source) {
            case resources.omnibar_ds_types.PROFIT_COST_CENTER : {
                setJournalEntries(journal_entries.map((journalEntry,key) => (key===selectedItem.ds_type.row ? 
                {
                    account_code:journalEntry.account_code,
                    account_description:journalEntry.account_description,
                    profit_cost_center:{label:selectedItem.item.label, type:selectedItem.item.type, value:selectedItem.item.id},
                    debit_amount:journalEntry.debit_amount,
                    credit_amount:journalEntry.credit_amount,
                } : journalEntry)));
                break;
            }
            default: break;
        }
    }

    const handleDebitAmountChangedEvent = (event, row) => {
        setJournalEntries(journal_entries.map((journalEntry, key) => (key===row ? {
            account_code:journalEntry.account_code,
            account_description:journalEntry.account_description,
            profit_cost_center:journalEntry.profit_cost_center,
            debit_amount:event.target.value.replace(/[^0-9]/g, ''),
            credit_amount:(0).toFixed(2),
        } : journalEntry)));
    }

    const handleCreditAmountChangedEvent = (event, row) => {
        setJournalEntries(journal_entries.map((journalEntry, key) => (key===row ? {
            account_code:journalEntry.account_code,
            account_description:journalEntry.account_description,
            profit_cost_center:journalEntry.profit_cost_center,
            debit_amount:(0).toFixed(2),
            credit_amount:event.target.value.replace(/[^0-9]/g, ''),
        } : journalEntry)));
    }

    return (
        <>
            <ControlGroup className="col-sm-12 col-md-12 col-lg-12" vertical={true} style={{height:"auto", backgroundColor:"#f5f8fa"}}>

                <OmnibarSelect 
                    is_omnibar_open={is_omnibar_open} 
                    setIsOmnibarOpen={setIsOmnibarOpen} 
                    omnibar_data={omnibar_data}
                    omnibar_ds_type={omnibar_ds_type}
                    handleOmnibarItemSelectedEvent={handleOmnibarItemSelectedEvent}
                />

                <HTMLTable 
                    className="line-item-table"
                    style={{
                        background: "white",
                        position:"sticky",
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <tbody>
                        <tr>
                            <th className="line line-number"></th>
                            <th className="input-line account-code">Account Code</th>
                            <th className="input-line account-description">Account Description</th>
                            <th className="input-line profit-cost-center">P/C Center</th>
                            <th className="input-line quantity">Debit Amount</th>
                            <th className="input-line quantity">Credit Amount</th>
                        </tr>
                    </tbody>
                </HTMLTable>
                <HTMLTable 
                    className="line-item-table"
                    style={{
                        background: "#F4F4F4",
                    }}
                >
                    <tbody>
                        {
                            journal_entries.map((journalEntry, row) => 
                                <tr key={row} style={{outline: "thin solid lightgrey"}}>
                                    <td className="line line-number">
                                        <ControlGroup>
                                            <Button text={row+1} minimal={true}/>
                                        </ControlGroup>
                                    </td>
                                    <td className="input-line account-code">
                                        <ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
                                            <InputGroup fill={true} readOnly={true} value={journalEntry.account_code}/>
                                        </ControlGroup>
                                    </td>
                                    <td className="input-line account-description">
                                        <ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
                                            <InputGroup fill={true} readOnly={true} value={journalEntry.account_description}/>
                                        </ControlGroup>
                                    </td>
                                    <td className="input-line profit-cost-center">
                                        <ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
                                            <InputGroup fill={true} readOnly={true} value={journalEntry.profit_cost_center ? journalEntry.profit_cost_center.label : ""}/>
                                        </ControlGroup>
                                    </td>
                                    <td className="input-line debit-amount">
                                        <ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
                                            <InputGroup type="number" min={0} readOnly={true} fill={true} style={{textAlign:"right"}} value={journalEntry.debit_amount} onChange={e => handleDebitAmountChangedEvent(e,row)}/>
                                        </ControlGroup>
                                    </td>
                                    <td className="input-line credit-amount">
                                        <ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
                                            <InputGroup type="number" min={0} readOnly={true} fill={true} style={{textAlign:"right"}} value={journalEntry.credit_amount} onChange={e => handleCreditAmountChangedEvent(e,row)}/>
                                        </ControlGroup>
                                    </td>
                                </tr>
                            )
                        }

                    </tbody>
                    <tfoot>
                        <tr style={{outline: "thin solid lightgrey"}}>
                            <td className="line line-number"></td>
                            <td className="input-line account-code"></td>
                            <td className="input-line account-description">
                                <ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
                                    <InputGroup fill={true} readOnly={true} value={balancing_account}/>
                                </ControlGroup>
                            </td>
                            <td className="input-line profit-cost-center"></td>
                            <td className="input-line quantity">
                                <ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
                                    <InputGroup type="number" min={0} readOnly={true} fill={true} style={{textAlign:"right"}} value={balancing_amount<0 ? Math.abs(balancing_amount).toFixed(2) : (0).toFixed(2)}/>
                                </ControlGroup>
                            </td>
                            <td className="input-line quantity">
                                <ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
                                    <InputGroup type="number" min={0} readOnly={true} fill={true} style={{textAlign:"right"}} value={balancing_amount>0 ? balancing_amount.toFixed(2) : (0).toFixed(2)}/>
                                </ControlGroup>
                            </td>
                        </tr>
                        <tr style={{outline: "tdin solid lightgrey"}}>
                            <td className="line line-number"></td>
                            <td className="input-line account-code"></td>
                            <td className="input-line account-description"></td>
                            <td className="input-line profit-cost-center"></td>
                            <td className="input-line quantity">
                                <ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
                                    <InputGroup type="number" min={0} readOnly={true} fill={true} style={{textAlign:"right", fontWeight:"bold", color:"blue"}} value={total_debit_amount>total_credit_amount ? total_debit_amount : total_credit_amount}/>
                                </ControlGroup>
                            </td>
                            <td className="input-line quantity">
                                <ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
                                    <InputGroup type="number" min={0} readOnly={true} fill={true} style={{textAlign:"right", fontWeight:"bold", color:"blue"}} value={total_credit_amount>total_debit_amount ? total_credit_amount : total_debit_amount}/>
                                </ControlGroup>
                            </td>
                        </tr>
                    </tfoot>

                </HTMLTable>
                {/*
                <ControlGroup vertical={false}>
                    <Button 
                        className="function-button"
                        large={false} 
                        onClick={e => setJournalEntries([...journal_entries, {
                            account_code:"",
                            account_description:"",
                            debit_amount:"",
                            credit_amount:"",
                        }])} 
                        icon="add" 
                        text={"Add more journal entries"}
                    />
                    <Button 
                        className="function-button"
                        large={false} 
                        onClick={e => setJournalEntries(default_journal_entries)} 
                        icon="remove" 
                        text={"Remove all journal entries"}
                    />
                </ControlGroup>
                */}

            </ControlGroup>
        </>
    );
}