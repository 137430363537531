import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Card, Elevation, Icon } from "@blueprintjs/core";
import 'chart.js/auto';
import {
	selectAuth,
	fetchZeroStockInventoriesAsync,
	updateDashboardData
} from '../auth/authSlice';
import { Link } from "react-router-dom";
import { resources } from "../../resources/index";

export function ZeroStockInventoriesChart() {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [application] = useState(auth.applications.find((app) => app.name===translate.text.INVENTORY_ITEM_BY_STATUS));
	const [has_access] = useState(auth.application_user.filter((appUser) => (appUser.user_id===auth.user.id && appUser.application_id===application.id)).length>0);
	const [fast_moving, setFastMoving] = useState(0);
	const [others, setOthers] = useState(0);

	const initFetch = useCallback(() => {
		dispatch(fetchZeroStockInventoriesAsync({})).then((response) => loadData(response));
		const loadData = (response) => {
			if (response.payload.data) {
				setFastMoving(response.payload.data.fast_moving);
				setOthers(response.payload.data.others);
				dispatch(updateDashboardData({zero_stock_inventories:{
					fast_moving:response.payload.data.fast_moving,
					others:response.payload.data.others,
				}}));
			}
		}
	}, [dispatch]);

	useEffect(() => {
		initFetch()
	}, [initFetch]);

	useEffect(() => {
		if (auth.dashboard.zero_stock_inventories) {
			setFastMoving(auth.dashboard.zero_stock_inventories.fast_moving);
			setOthers(auth.dashboard.zero_stock_inventories.others);
		}
	}, [auth.dashboard.zero_stock_inventories]);

	return (

		<Link
			to="/warehouse-inventory/inventory-item-by-status?status=zero_stock"
			style={{textDecoration:"none", pointerEvents:auth.active_company.application_id===null&&has_access ? "" : "none"}}
		>
			<Card style={{marginTop:"4px", marginLeft:"2px", marginRight:"2px", marginBottom:"0px", paddingBottom:"0px", display:"flex", justifyContent:"center", flexDirection:"column", height:"200px"}} interactive={true} elevation={Elevation.TWO}>
				<Icon style={{alignSelf:"center", color:"red", marginBottom:12}} icon="trending-down" size={50}/>
				<p style={{fontSize:17, fontFamily:"Arial", fontColor:"black", textAlign:"center", fontWeight:"bold"}}>Zero stock inventories</p>
				<p style={{fontSize:15, fontColor:"black", textAlign:"center"}}>Fast moving SKUs : <span className="bold">{fast_moving}</span></p>
				<p style={{fontSize:15, fontColor:"black", textAlign:"center"}}>Other SKUs : <span className="bold">{others}</span></p>
			</Card>
		</Link>

	);

}
