import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Card, Elevation, Icon } from "@blueprintjs/core";
import 'chart.js/auto';
import {
	selectAuth,
	fetchNearStockoutSkusAsync,
	updateDashboardData
} from './../auth/authSlice';
import { Link } from "react-router-dom";
import { resources } from "../../resources/index";

export function NearStockoutSkusChart() {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [application] = useState(auth.applications.find((app) => app.name===translate.text.INVENTORY_ITEM_BY_STATUS));
	const [has_access] = useState(auth.application_user.filter((appUser) => (appUser.user_id===auth.user.id && appUser.application_id===application.id)).length>0);
	const [to_reorder, setToReorder] = useState(0);
	const [below_reorder_point, setBelowReorderPoint] = useState(0);

	const initFetch = useCallback(() => {
		dispatch(fetchNearStockoutSkusAsync({})).then((response) => loadData(response));
		const loadData = (response) => {
			if (response.payload.data) {
				setToReorder(response.payload.data.to_reorder);
				setBelowReorderPoint(response.payload.data.below_reorder_point);
				dispatch(updateDashboardData({near_stockout_skus:{
					to_reorder:response.payload.data.to_reorder,
					below_reorder_point:response.payload.data.below_reorder_point,
				}}));
			}
		}
	}, [dispatch]);

	useEffect(() => {
		initFetch()
	}, [initFetch]);

	useEffect(() => {
		if (auth.dashboard.near_stockout_skus) {
			setToReorder(auth.dashboard.near_stockout_skus.to_reorder);
			setBelowReorderPoint(auth.dashboard.near_stockout_skus.below_reorder_point);
		}
	}, [auth.dashboard.near_stockout_skus]);

	return (

		<Link
			to="/warehouse-inventory/inventory-item-by-status?status=near_stockout"
			style={{textDecoration:"none", pointerEvents:auth.active_company.application_id===null&&has_access ? "" : "none"}}
		>
			<Card style={{marginTop:"4px", marginLeft:"2px", marginRight:"2px", marginBottom:"0px", paddingBottom:"0px", display:"flex", justifyContent:"center", flexDirection:"column", height:"200px"}} interactive={true} elevation={Elevation.TWO}>
				<Icon style={{alignSelf:"center", color:"lightblue", marginBottom:12}} icon="grouped-bar-chart" size={50}/>
				<p style={{fontSize:17, fontFamily:"Arial", fontColor:"black", textAlign:"center", fontWeight:"bold"}}>Reorder and near stockout SKUs</p>
				<p style={{fontSize:15, fontColor:"black", textAlign:"center"}}>Equal to reorder point : <span className="bold">{to_reorder}</span></p>
				<p style={{fontSize:15, fontColor:"black", textAlign:"center"}}>Below reorder point : <span className="bold">{below_reorder_point}</span></p>
			</Card>
		</Link>

	);

}
