import React, { useEffect, useState } from "react";
import { Dialog, Button, Icon } from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import DataTable from 'react-data-table-component';
import { resources } from "../resources/index";
import { useDispatch, useSelector } from "react-redux";
import {
    selectAuth,
    fetchAllStockIssuancesAsync,
    fetchAllStockAcceptancesAsync
} from "../features/auth/authSlice";

export function DatatableDialog({is_datatable_open, setIsDatatableDialogOpen, transaction_source, handleProcessSelectedDocuments}) {

    const auth = useSelector(selectAuth);
    const dispatch = useDispatch();
    const translate = resources["lang"][auth.locale];
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [selection, setSelection] = useState(null);
    const [stock_transfers] = useState([]);
    const [stock_requests] = useState([]);

    const handleDatatableRowsSelected = (object) => {
        setSelection(object);
        if (object.selectedCount>0) {
            setSelection(object);
        }
        else {
            setSelection(null);
        }
    }

    useEffect(() => {
        if (transaction_source && transaction_source.value) {
            switch (transaction_source.value) {
                case auth.enums.transaction_sources.stock_issuance.STOCK_TRANSFER_REQUEST : {
                    setColumns(translate.worksheet.default_header['STOCK_TRANSFER'].map((header, key) => ({
                            name:header.toUpperCase(),
                            selector: row => row[header.replaceAll(' ','_')],
                            sortable: true,
                            width: (key===0 ? 70 : 160)+"px"
                    })));
                    setData((stock_transfers).filter((stockTransfer) => (stockTransfer.approval_status===auth.enums.approval_status.APPROVED && parseFloat(stockTransfer.requested_quantity)>(parseFloat(stockTransfer.fulfilled_quantity)+parseFloat(stockTransfer.cancelled_quantity)))).map((stockTransfer) => ({
                        id:stockTransfer.id,
                        document_number:stockTransfer.document_number,
                        document_date:stockTransfer.document_date,
                        approval_status:stockTransfer.approval_status,
                        reference_number:stockTransfer.reference_number,
                        transaction_description:stockTransfer.description,
                        transfer_from:stockTransfer.transfer_from!==null ? JSON.parse(stockTransfer.transfer_from).label : null,
                        transfer_to:stockTransfer.transfer_to!==null ? JSON.parse(stockTransfer.transfer_to).label : null,
                    })));
                    break;
                }
                case auth.enums.transaction_sources.stock_acceptance.STOCK_PURCHASE_REQUEST : {
                    setColumns(translate.worksheet.default_header['STOCK_REQUEST'].map((header, key) => ({
                            name:header.toUpperCase(),
                            selector: row => row[header.replaceAll(' ','_')],
                            sortable: true,
                            width: (key===0 ? 70 : 160)+"px"
                    })));
                    setData(
                        (stock_requests).filter((stockRequest) => (stockRequest.approval_status===auth.enums.approval_status.APPROVED && parseFloat(stockRequest.requested_quantity)>(parseFloat(stockRequest.accepted_quantity)+parseFloat(stockRequest.cancelled_quantity)))).map((stockRequest) => ({
                            id:stockRequest.id,
                            document_number:stockRequest.document_number,
                            document_date:stockRequest.document_date,
                            approval_status:stockRequest.approval_status,
                            reference_number:stockRequest.reference_number,
                            requesting_unit:stockRequest.branch.name ? stockRequest.branch.name : (stockRequest.department.name ? stockRequest.department.name : (stockRequest.project.name ? stockRequest.project.name : null)),
                            transaction_description:stockRequest.description,
                            created_at:stockRequest.created_at,
                            updated_at:stockRequest.updated_at,
                        }))
                    );
                    break;
                }
                case auth.enums.transaction_sources.stock_acceptance.STOCK_ISSUANCE : {
                    setColumns(translate.worksheet.default_header['STOCK_ISSUANCE'].map((header, key) => ({
                            name:header.toUpperCase(),
                            selector: row => row[header.replaceAll(' ','_')],
                            sortable: true,
                            width: (key===0 ? 70 : 160)+"px"
                    })));
                    dispatch(fetchAllStockIssuancesAsync()).then((response) => (
                        response.payload.data ? 
                        setData((response.payload.data.stock_issuances).filter((stockIssuance) => (
                            (stockIssuance.approval_status===auth.enums.approval_status.APPROVED) &&
                            (stockIssuance.source===auth.enums.transaction_sources.stock_issuance.STOCK_TRANSFER_REQUEST||stockIssuance.source===auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION) &&
                            (parseFloat(stockIssuance.accepted_quantity)+parseFloat(stockIssuance.cancelled_quantity))<parseFloat(stockIssuance.issued_quantity)
                        )).map((stockIssuance) => ({
                            id:stockIssuance.id,
                            document_number:stockIssuance.document_number,
                            document_date:stockIssuance.document_date,
                            approval_status:stockIssuance.approval_status,
                            reference_number:stockIssuance.reference_number,
                            transaction_source:stockIssuance.source,
                            finished_goods_to_produce:stockIssuance.finished_good_item ? stockIssuance.finished_good_item.description : "",
                            transaction_description:stockIssuance.description,
                            transfer_from: stockIssuance.transfer_from!==null ? JSON.parse(stockIssuance.transfer_from).label : null,
                            transfer_to: stockIssuance.transfer_to!==null ? JSON.parse(stockIssuance.transfer_to).label : null,
                            created_at:stockIssuance.created_at,
                            updated_at:stockIssuance.updated_at,
                        }))) : void(0)
                    ));
                    break;
                }
                case auth.enums.transaction_sources.finished_good_produced.FINISHED_GOOD_PRODUCED : {
                    setColumns(translate.worksheet.default_header['STOCK_ACCEPTANCE'].map((header, key) => ({
                            name:header.toUpperCase(),
                            selector: row => row[header.replaceAll(' ','_')],
                            sortable: true,
                            width: (key===0 ? 70 : 175)+"px"
                    })));
                    dispatch(fetchAllStockAcceptancesAsync()).then((response) => (
                        (response.payload.data) ?
                            setData((response.payload.data.stock_acceptances).filter((stockAcceptance) => (
                                (stockAcceptance.approval_status===auth.enums.approval_status.APPROVED) &&
                                (stockAcceptance.source===auth.enums.transaction_sources.stock_acceptance.STOCK_ISSUANCE) &&
                                (stockAcceptance.stock_issuances[0].source===auth.enums.transaction_sources.stock_issuance.ISSUANCE_TO_PRODUCTION) 
                            )).map((stockAcceptance) => ({
                                id:stockAcceptance.id,
                                document_number:stockAcceptance.document_number,
                                document_date:stockAcceptance.document_date,
                                approval_status:stockAcceptance.approval_status,
                                reference_number:stockAcceptance.reference_number,
                                source:stockAcceptance.source,
                                stock_issuance_source:stockAcceptance.stock_issuances[0].source,
                                finished_goods_to_produce:"",
                                transaction_description:stockAcceptance.description,
                                profit_cost_center_code:stockAcceptance.transfer_to ? JSON.parse(stockAcceptance.transfer_to).code : "",
                                profit_cost_center_type:stockAcceptance.transfer_to ? JSON.parse(stockAcceptance.transfer_to).type : "",
                            })))
                        : void(0)
                    ));
                    break;
                }
                default : break;
            }
        }
        else {
            return null;
        }
    }, [
        dispatch,
        translate,
        transaction_source,
        stock_requests,
        stock_transfers,
        auth.enums.transaction_sources,
        auth.enums.approval_status.APPROVED
    ]);

    return (
        <Dialog
            style={{width:"1000px"}}
            title={`Select document/s to process`}
            icon={<Icon icon="info-sign" className="orange"/>}
            isOpen={is_datatable_open}
            onClose={e => setIsDatatableDialogOpen(false)}
            isCloseButtonShown={true}
        >
            <div>
                <div className="center" style={{paddingTop:"15px",paddingBottom:"15px"}}>
                {
                    selection!==null &&
                    <Button
                        className="function-button"
                        text={translate.button.PROCESS_SELECTED_DOCUMENTS}
                        icon={<Icon icon="select" className="orange"/>}
                        onClick={e => handleProcessSelectedDocuments(selection)}
                    />
                }
                </div>
                <DataTable
                    columns={columns}
                    data={data}
                    theme="light"
                    pagination
                    dense
                    selectableRows
                    onSelectedRowsChange={handleDatatableRowsSelected}
                />
            </div>
        </Dialog>
    );
}
