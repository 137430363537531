import React, {useRef, useCallback, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, setSelectedRegions, pasteFromClipboard, confirmCell, fetchAllDocumentsAsync } from '../features/auth/authSlice';
import { HotkeysProvider, HotkeysTarget2, Icon, Checkbox, Text } from "@blueprintjs/core";
import { Column, Table2, SelectionModes, Cell, EditableCell, ColumnHeaderCell, TableLoadingOption, RenderMode } from "@blueprintjs/table";
import { resources } from '../resources/index';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";

export function ManageTransactionsSpreadsheet({enable_edit, enable_select, selected_rows, setSelectedRows, handleEditButtonClickedEvent, handleClearButtonClickedEvent, worksheet, setWorkSheet}) {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const workSheetTable = useRef(null);

	const initFetch = useCallback(() => {
		dispatch(fetchAllDocumentsAsync({}))
		.then((response) => response.payload.data ? 
			setWorkSheet({
				is_readonly_mode: true,
				num_rows: 1000,
				num_cols: 10,
				num_frozen_columns: 0,
				import_limit: 700,
				focused_cell: null,
				selected_regions: null,
				header: translate.worksheet.default_header.MANAGE_TRANSACTIONS,
				alignment: translate.worksheet.default_alignment.MANAGE_TRANSACTIONS,
				comment: [],
				data_set: response.payload.data.map((ds) => [
					ds.id,
					ds.document_type_name,
					ds.document_number,
					ds.document_date,
					ds.source,
					ds.approval_status,
					ds.description,
					ds.created_by,
				])
			}) : void(0)
		);
	}, [
		dispatch,
		translate.worksheet.default_header,
		translate.worksheet.default_alignment,
		setWorkSheet
	]);


	useEffect(() => {
		if (auth.active_company) {
			initFetch();
		}
	}, [initFetch, auth.active_company]);

	const onFocusedCell = (cell) => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set: worksheet.data_set
		})
	}

	const selectCell = (row, col) => {
		return (
			selected_rows && row <= worksheet.data_set.length-1
			?
			<Cell interactive={false} style={{textAlign:'center'}}>
				<Checkbox label="" checked={selected_rows.includes(row)} onChange={e => handleSelectButtonClickedHandler(row)} />
			</Cell>
			:
			<Cell interactive={false} style={{textAlign:'left'}}/>
		)
	}

	const handleSelectButtonClickedHandler = async (row) => {
		let selectedRows = [];
		if (!selected_rows.includes(row)) {
			selectedRows = selected_rows.map((selectedRow) => (selectedRow));
			selectedRows.push(row);
		}
		else {
			selectedRows = selected_rows.filter((selectedRow) => selectedRow!==row);
		}
		if (selectedRows.length>0 && handleClearButtonClickedEvent) {
			handleClearButtonClickedEvent();
		}
		setSelectedRows(selectedRows);
	}

	const confirm = async (value, row, col) => {
		dispatch(confirmCell({row:row, col:col, value:value}))
	}

	const cell = (row, col) => {
		let cellValue = null;
		if (row < worksheet.data_set.length) {
			cellValue = worksheet.data_set[row][col];
			let jsonObject = null;
			try {
				jsonObject = JSON.parse(cellValue);
			}
			catch(e) {}
			if (Array.isArray(jsonObject)) {
				cellValue = jsonObject.map((obj, objKey) => (obj.label)).join(", ")
			}
		}
		let alignment = worksheet.is_readonly_mode ? worksheet.alignment[col] : "left";
		if (worksheet.is_readonly_mode) {
			return (
				<Cell interactive={false} style={{textAlign:alignment}}>
					{cellValue}
				</Cell>
			)
		}
		else {
			return (
				<EditableCell interactive={false} style={{textAlign:alignment}} value={cellValue} onConfirm={value => confirm(value, row, col)}/>
			)
		}
	}

	const editButtonClickHandler = async (row, col) => {
		setSelectedRows([]);
		handleEditButtonClickedEvent(row);
	}

	const editCell = (row, col) => {
		return (
			row <= worksheet.data_set.length-1 ?
			<Cell interactive={false} style={{textAlign:'center'}}>
				<div style={{width:'100%', cursor:"pointer"}} onClick={e => (editButtonClickHandler(row, col))}>
				<Icon
					icon="edit"
					size={15}
					className="orange"
				/>
				</div>
			</Cell>
			:
			<Cell interactive={false} style={{textAlign:'left'}}>
			</Cell>
		)
	}

	const nameRenderer = (name, index) => {
		return (
			<>
			    <Text ellipsize={true}>
			    	{
			    		!worksheet.is_readonly_mode && worksheet.comment[index]!==null ?
						<Icon
							intent="primary"
							icon="help"
							size={16}
						/> :
						null
			    	}
			    	{" "}
			    	{worksheet.header[index].toUpperCase()}
			    </Text>
		    </>
		)
	}

	const columnHeaderCellRenderer = (index) => {
		return (
			<ColumnHeaderCell
				name={worksheet.comment[index]}
				nameRenderer={nameRenderer}
			/>
		)
	}

	const renderColumns = () => {
		let headerColumns = worksheet.header.map(
			(header, headerKey) => (
            	<Column
            		key={headerKey}
            		name={header.toUpperCase()}
            		columnHeaderCellRenderer={columnHeaderCellRenderer}
            		cellRenderer={cell}
            	/>
			)
		)

		if (worksheet.is_readonly_mode) {
			if (!(enable_edit===false)) {
				headerColumns.push(<Column key={worksheet.header.length+1} name="EDIT / VIEW" cellRenderer={editCell} />);
			}
			if (!(enable_select===false)) {
				headerColumns.push(<Column key={worksheet.header.length+3} name="SELECT" cellRenderer={selectCell} />);
			}
		}
		return headerColumns;
	};

	const getCellClipboardData = (row, col) => {
		console.log(`copy row:${row} col:${col}`);
	}

	const onCopy = () => {
		console.log('onCopy');
	}

	const textAreaFocusHandler = (event) => {
		let textArea = document.getElementById("clipboard-textarea");
		textArea.textContent = "";
	}

	const onSelection = async (selectedRegions) => {
		dispatch(setSelectedRegions(selectedRegions));
	}

	const textAreaChangeHandler = (event) => {
		dispatch(pasteFromClipboard({clipboardData:event.target.value}));
	}

	const pasteClipboardData = () => {
		let textArea = document.getElementById("clipboard-textarea");
		textArea.textContent = "";
		textArea.value = "";
		textArea.focus();
	}

    const hotkeys = [
        {
            combo: "mod+V",
            global: true,
            label: "Paste Data",
            onKeyDown: () => pasteClipboardData(),
        },
    ];

	return (

			<div style={{display:"flex", justifyContent:"center"}}>
				<textarea style={{opacity:0, position:"absolute"}} id="clipboard-textarea" onFocus={(event) => textAreaFocusHandler(event)} onChange={(event) => textAreaChangeHandler(event)}></textarea>
				<HotkeysProvider>
		            <HotkeysTarget2 hotkeys={hotkeys}>
		                {({ handleKeyDown, handleKeyUp }) => (
		                    <div tabIndex={0} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
		                    </div>
		                )}
		            </HotkeysTarget2>
					<Table2
						className="worksheet-table"
						ref={workSheetTable}
						loadingOptions={auth.status===resources.status.LOADING ? [TableLoadingOption.CELLS, TableLoadingOption.COLUMN_HEADERS, TableLoadingOption.ROW_HEADERS] : []}
						numRows={worksheet.num_rows}
						enableGhostCells={true}
						enableMultipleSelection={true}
						enableRowHeader={true}
						enableFocusedCell={true}
						renderMode={RenderMode.NONE}
						enableRowResizing={false}
						selectionModes={SelectionModes.ALL}
						numFrozenColumns={worksheet.num_frozen_columns}
						forceRerenderOnSelectionChange={false}
						getCellClipboardData={(row, col) => getCellClipboardData(row, col)}
						onCopy={() => onCopy()}
						onFocusedCell={(cell) => onFocusedCell(cell)}
						onSelection={(selectedRegions) => onSelection(selectedRegions)}
					>
						{renderColumns()}
					</Table2>
				</HotkeysProvider>
			</div>

	);
}
