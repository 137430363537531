import React, {useRef, useCallback, useEffect, useState} from 'react';
//import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, setSelectedRegions, pasteFromClipboard, confirmCell, fetchAllStockAcceptancesAsync } from '../features/auth/authSlice';
import { HotkeysProvider, HotkeysTarget2, Icon, Checkbox, Text, Button, ButtonGroup, Switch } from "@blueprintjs/core";
import { Column, Table2, SelectionModes, Cell, EditableCell, ColumnHeaderCell, TableLoadingOption, RenderMode } from "@blueprintjs/table";
import { resources } from '../resources/index';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import PDF from '../common/PDF';
import Excel from '../common/Excel';
import { MuiAutocomplete } from '../common/MuiAutocomplete';
import { TextField } from '@material-ui/core';
import moment from "moment";

export function StockAcceptanceSpreadsheet({enable_edit, enable_select, selected_rows, setSelectedRows, handleEditButtonClickedEvent, handleClearButtonClickedEvent, worksheet, setWorkSheet, refreshData, is_confirm_delete_dialog_open, setIsConfirmDeleteDialogOpen, profit_cost_centers, DEFAULT_DATA_SET}) {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	//const history = useHistory();
	const workSheetTable = useRef(null);
	const [accounting_calendars] = useState(auth.accounting_calendars.map((ac) => ({
		label:`${ac.period_type} ${ac.year}`,
		value:ac.id,
	})));
	const [accounting_calendar, setAccountingCalendar] = useState(accounting_calendars[accounting_calendars.length-1]);
	const [from_date, setFromDate] = useState(null);
	const [to_date, setToDate] = useState(null);
	const [document_numbers, setDocumentNumbers] = useState([]);
	const [document_number, setDocumentNumber] = useState(null);
	const [profit_cost_center, setProfitCostCenter] = useState(null);

	const initFetch = useCallback(() => {
		if (worksheet.is_readonly_mode) {
			const handleResponse = (response) => {
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: response.payload.data.stock_acceptances.map((ds) => [
						ds.id,
						ds.document_number,
						ds.document_date,
						ds.approval_status,
						ds.reference_number,
						ds.source,
						ds.stock_issuances.length>0 ? ds.stock_issuances[0].source : "",
						ds.description,
						ds.transfer_to ? JSON.parse(ds.transfer_to).label : "",
						ds.transfer_to ? JSON.parse(ds.transfer_to).type : "",
					])
				});
			}
			dispatch(fetchAllStockAcceptancesAsync({
				document_number:document_number ? document_number.value : "",
				accounting_calendar:accounting_calendar ? accounting_calendar.value : "",
				profit_cost_center:profit_cost_center ? profit_cost_center : "",
				from_date:from_date ? moment(from_date).format(resources.date_format.SYSTEM) : "",
				to_date:to_date ? moment(to_date).format(resources.date_format.SYSTEM) : "",
			})).then((response) => response.payload.data ? 
				handleResponse(response) : void(0)
			);
		}
	}, [
		dispatch,
		setWorkSheet,
		worksheet.is_readonly_mode,
		worksheet.alignment,
		worksheet.comment,
		worksheet.focused_cell,
		worksheet.header,
		worksheet.import_limit,
		worksheet.num_cols,
		worksheet.num_frozen_columns,
		worksheet.num_rows,
		worksheet.selected_regions,
		document_number,
		accounting_calendar,
		profit_cost_center,
		from_date,
		to_date,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		let docNumbers = worksheet.data_set.filter((row) => (row.length>0 && row[1] && row[1].length>0)).map((row) => ({
			value:row[1],
			label:row[1],
		}));
		if (docNumbers.length>document_numbers.length) {
			setDocumentNumbers(docNumbers);
		}
	}, [worksheet.data_set, document_numbers]);

	const onFocusedCell = (cell) => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set
		});
	}

	const selectCell = (row, col) => {
		return (
			selected_rows && row <= worksheet.data_set.length-1
			?
			<Cell interactive={false} style={{textAlign:'center'}}>
				<Checkbox label="" checked={selected_rows.includes(row)} onChange={e => handleSelectButtonClickedHandler(row)} />
			</Cell>
			:
			<Cell interactive={false} style={{textAlign:'left'}}/>
		)
	}

	const handleSelectButtonClickedHandler = async (row) => {
		let selectedRows = [];
		if (!selected_rows.includes(row)) {
			selectedRows = selected_rows.map((selectedRow) => (selectedRow));
			selectedRows.push(row);
		}
		else {
			selectedRows = selected_rows.filter((selectedRow) => selectedRow!==row);
		}
		if (selectedRows.length>0 && handleClearButtonClickedEvent) {
			handleClearButtonClickedEvent();
		}
		setSelectedRows(selectedRows);
	}

	const confirm = async (value, row, col) => {
		dispatch(confirmCell({row:row, col:col, value:value}))
	}

	const cell = (row, col) => {
		let cellValue = null;
		if (row < worksheet.data_set.length) {
			cellValue = worksheet.data_set[row][col];
			let jsonObject = null;
			try {
				jsonObject = JSON.parse(cellValue);
			}
			catch(e) {}
			if (Array.isArray(jsonObject)) {
				cellValue = jsonObject.map((obj, objKey) => (obj.label)).join(", ")
			}
		}
		let alignment = worksheet.is_readonly_mode ? worksheet.alignment[col] : "left";
		if (worksheet.is_readonly_mode) {
			return (
				<Cell interactive={false} style={{textAlign:alignment}}>
					{cellValue}
				</Cell>
			)
		}
		else {
			return (
				<EditableCell interactive={false} style={{textAlign:alignment}} value={cellValue} onConfirm={value => confirm(value, row, col)}/>
			)
		}
	}

	const editButtonClickHandler = (row, col) => {
		setSelectedRows([]);
		handleEditButtonClickedEvent(row);
	}

	const editCell = (row, col) => {
		return (
			row <= worksheet.data_set.length-1 ?
			<Cell interactive={false} style={{textAlign:'center'}}>
				<div style={{width:'100%', cursor:"pointer"}} onClick={e => (editButtonClickHandler(row, col))}>
				<Icon
					icon="edit"
					size={15}
					className="orange"
				/>
				</div>
			</Cell>
			:
			<Cell interactive={false} style={{textAlign:'left'}}>
			</Cell>
		)
	}

	const nameRenderer = (name, index) => {
		return (
			<>
			    <Text ellipsize={true}>
			    	{
			    		!worksheet.is_readonly_mode && worksheet.comment[index]!==null ?
						<Icon
							intent="primary"
							icon="help"
							size={16}
						/> :
						null
			    	}
			    	{" "}
			    	{worksheet.header[index].toUpperCase()}
			    </Text>
		    </>
		)
	}

	const columnHeaderCellRenderer = (index) => {
		return (
			<ColumnHeaderCell
				name={worksheet.comment[index]}
				nameRenderer={nameRenderer}
			/>
		)
	}

	const renderColumns = () => {
		let headerColumns = worksheet.header.map(
			(header, headerKey) => (
            	<Column
            		key={headerKey}
            		name={header.toUpperCase()}
            		columnHeaderCellRenderer={columnHeaderCellRenderer}
            		cellRenderer={cell}
            	/>
			)
		)

		if (worksheet.is_readonly_mode) {
			if (!(enable_edit===false)) {
				headerColumns.push(<Column key={worksheet.header.length+1} name="EDIT / VIEW" cellRenderer={editCell} />);
			}
			if (!(enable_select===false)) {
				headerColumns.push(<Column key={worksheet.header.length+3} name="SELECT" cellRenderer={selectCell} />);
			}
		}
		return headerColumns;
	};

	const getCellClipboardData = (row, col) => {
		console.log(`copy row:${row} col:${col}`);
	}

	const onCopy = () => {
		console.log('onCopy');
	}

	const textAreaFocusHandler = (event) => {
		let textArea = document.getElementById("clipboard-textarea");
		textArea.textContent = "";
	}

	const onSelection = async (selectedRegions) => {
		dispatch(setSelectedRegions(selectedRegions));
	}

	const textAreaChangeHandler = (event) => {
		dispatch(pasteFromClipboard({clipboardData:event.target.value}));
	}

	const pasteClipboardData = () => {
		let textArea = document.getElementById("clipboard-textarea");
		textArea.textContent = "";
		textArea.value = "";
		textArea.focus();
	}

    const hotkeys = [
        {
            combo: "mod+V",
            global: true,
            label: "Paste Data",
            onKeyDown: () => pasteClipboardData(),
        },
    ];

	const toggleFreezePane = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set
		});
	}

	const clearCells = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:DEFAULT_DATA_SET
		});
	}

	const clearSelectedCells = () => {
		if (worksheet.selected_regions!=null) {
			let dataSet = [];
			let selectedRegions = worksheet.selected_regions;
			for (let i=0; i<worksheet.data_set.length; i++) {
				let row = [];
				for (let j=0; j<worksheet.data_set[i].length; j++) {
					row.push(worksheet.data_set[i][j]);
				}
				dataSet.push(row);
			}
			for (let i=0; i<selectedRegions.length; i++) {
				let selectedRows = selectedRegions[i].rows;
				let selectedCols = selectedRegions[i].cols;
				let startRow = selectedRows[0];
				let endRow = selectedRows[1];
				let startCol = selectedCols[0];
				let endCol = selectedCols[1];
				for (let j=startRow; j<=endRow; j++) {
					if (dataSet[j]!==undefined) {
						for (let k=startCol; k<=endCol; k++) {
							if (dataSet[j][k]!==undefined) {
								dataSet[j][k] = "";
							}
						}
					}
				}
			}
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set:dataSet
			});
		}
	}

	const handleImportButtonClickedEvent = () => {
	}

	const handleSwitchModeEvent = () => {
		refreshData();
		setWorkSheet({
			is_readonly_mode: !worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.is_readonly_mode ? translate.worksheet.import_header.STOCK_ACCEPTANCE : translate.worksheet.default_header.STOCK_ACCEPTANCE,
			alignment: [],
			comment: [],
			data_set:worksheet.is_readonly_mode ? DEFAULT_DATA_SET : worksheet.data_set
		});
	}

	return (
		<>
			{
				worksheet.is_readonly_mode &&
				<div className="row mb-5 mt-5">
					<div className="col-sm-12 col-md-3 col-lg-2 mb-5">
						<MuiAutocomplete
							setSelectedValue={setAccountingCalendar}
							selected_value={accounting_calendar}
							isMultiple={false}
							label='Filter by Year'
							data={accounting_calendars}
						/>
					</div>
					<TextField
						className="col-sm-12 col-md-2 col-lg-2 mb-5"
						id="from_date"
						label={translate.placeholder.FROM_DATE}
						type="date"
						variant="outlined"
						size="small"
						defaultValue={from_date}
						onChange={(e) => setFromDate(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<TextField
						className="col-sm-12 col-md-2 col-lg-2 mb-5"
						id="to_date"
						label={translate.placeholder.TO_DATE}
						type="date"
						variant="outlined"
						size="small"
						defaultValue={to_date}
						onChange={(e) => setToDate(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<div className="col-sm-12 col-md-3 col-lg-2 mb-5">
						<MuiAutocomplete
							setSelectedValue={setProfitCostCenter}
							selected_value={profit_cost_center}
							isMultiple={false}
							label='Filter by Profit / Cost Center'
							data={profit_cost_centers}
							disableClearable={false}
						/>
					</div>
					<div className="col-sm-12 col-md-3 col-lg-2 mb-5">
						<MuiAutocomplete
							setSelectedValue={setDocumentNumber}
							selected_value={document_number}
							isMultiple={false}
							label='Filter by Document Number'
							data={document_numbers}
							disableClearable={false}
						/>
					</div>
				</div>
			}
			<ButtonGroup className="button-group" minimal={false}>
				<Switch
					className="switch-mode"
					checked={!worksheet.is_readonly_mode}
					disabled={auth.status === resources.status.LOADING}
					labelElement={<span className="switch-mode-label">{translate.label.SWITCH_MODE}</span>}
					innerLabelChecked={translate.label.IMPORT}
					innerLabel={translate.label.READ_ONLY}
					onChange={handleSwitchModeEvent}
				/>
				{
					worksheet.is_readonly_mode &&
					<>
						<Button
							text={translate.button.SAVE_AS_PDF}
							className="function-button"
							icon={<Icon icon="document" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={
								e => PDF.export(
									translate.worksheet.file_name.STOCK_ACCEPTANCE,
									worksheet.header,
									worksheet.data_set,
									auth.active_company,
									"a2",
									"landscape",
									[],
									[30, 60, 60, 60, 60, 80, 100, "*", "*", 70, 70]
								)
							}
						/>
						<Button
							text={translate.button.SAVE_AS_XLSX}
							className="function-button"
							icon={<Icon icon="th" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={
								e => Excel.export(
									translate.worksheet.file_name.STOCK_ACCEPTANCE,
									worksheet.header,
									worksheet.data_set,
									auth.active_company
								)
							}
						/>
						<Button
							text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
							disabled={auth.status === resources.status.LOADING}
							className="function-button"
							icon={<Icon icon="select" className="orange"/>}
							onClick={e => setSelectedRows(selected_rows.length>0 ? [] : worksheet.data_set.map((dataSet, key) => key))}
						/>
						{
							selected_rows.length>0 &&
							<Button
								text={`${translate.button.DELETE_ROWS}  (${selected_rows.length})`}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon={<Icon icon="delete" className="orange"/>}
								onClick={e => setIsConfirmDeleteDialogOpen(!is_confirm_delete_dialog_open)}
							/>
						}
					</>
				}
				{
					!worksheet.is_readonly_mode &&
					<>
						<Button
							text={translate.button.IMPORT}
							className="function-button"
							icon={<Icon icon="upload" className="orange"/>}
							disabled={auth.status===resources.status.LOADING}
							onClick={handleImportButtonClickedEvent}
						/>
						<Button
							text={translate.button.CLEAR_SELECTED_CELLS}
							className="function-button"
							icon={<Icon icon="clean" className="orange"/>}
							disabled={auth.status===resources.status.LOADING}
							onClick={e => dispatch(clearSelectedCells())}
						/>
						<Button
							text={translate.button.CLEAR_CELLS}
							className="function-button"
							icon={<Icon icon="graph-remove" className="orange"/>}
							disabled={auth.status===resources.status.LOADING}
							onClick={e => clearCells()}
						/>
					</>
				}
				{
					worksheet.focused_cell &&
					<Button
						text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
						className="function-button"
						icon={<Icon icon="panel-table" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={() => toggleFreezePane()}
					/>
				}
			</ButtonGroup>
			<div style={{display:"flex", justifyContent:"center"}}>
				<textarea style={{opacity:0, position:"absolute"}} id="clipboard-textarea" onFocus={(event) => textAreaFocusHandler(event)} onChange={(event) => textAreaChangeHandler(event)}></textarea>
				<HotkeysProvider>
		            <HotkeysTarget2 hotkeys={hotkeys}>
		                {({ handleKeyDown, handleKeyUp }) => (
		                    <div tabIndex={0} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
		                    </div>
		                )}
		            </HotkeysTarget2>
					<Table2
						className="worksheet-table"
						ref={workSheetTable}
						loadingOptions={auth.status===resources.status.LOADING ? [TableLoadingOption.CELLS, TableLoadingOption.COLUMN_HEADERS, TableLoadingOption.ROW_HEADERS] : []}
						numRows={worksheet.num_rows}
						enableGhostCells={true}
						enableMultipleSelection={true}
						enableRowHeader={true}
						enableFocusedCell={true}
						renderMode={RenderMode.NONE}
						enableRowResizing={false}
						selectionModes={SelectionModes.ALL}
						numFrozenColumns={worksheet.num_frozen_columns}
						forceRerenderOnSelectionChange={false}
						getCellClipboardData={(row, col) => getCellClipboardData(row, col)}
						onCopy={() => onCopy()}
						onFocusedCell={(cell) => onFocusedCell(cell)}
						onSelection={(selectedRegions) => onSelection(selectedRegions)}
					>
						{renderColumns()}
					</Table2>
				</HotkeysProvider>
			</div>
		</>
	);
}
