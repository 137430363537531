import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { SideBar } from '../../../SideBar';
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createUserAsync,
	updateUserAsync,
	deleteUsersAsync,
	importUsersAsync,
	toggleSidebarCollapsed,
	skipProcessAsync,
	fetchUserAsync,
	fetchAllUsersAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	FormGroup,
	Checkbox,
	Icon,
	Switch
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { UserSpreadsheet } from "../../../spreadsheets/UserSpreadsheet";

export function User() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const roles = Object.values(auth.enums.roles);
	const [positions] = useState(Object.values(auth.enums.positions).map((pos) => ({label:pos, value:pos})));
	const [id, setId] = useState(null);
	const [email, setEmail] = useState('');
	const [first_name, setFirstName] = useState('');
	const [last_name, setLastName] = useState('');
	const [position, setPosition] = useState(null);
	const [selected_roles, setSelectedRoles] = useState([]);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);
	const [is_existing, setIsExisting] = useState(false);

	const DEFAULT_HEADER = translate.worksheet.default_header.USER;
	const DEFAULT_ALIGNMENT = translate.worksheet.default_alignment.USER;
	const IMPORT_HEADER = translate.worksheet.import_header.USER;
	const IMPORT_ALIGNMENT = translate.worksheet.import_alignment.USER;
	const COMMENT = translate.worksheet.import_comment.USER;
	const NUM_ROWS = 1000;
	const NUM_COLS = DEFAULT_HEADER.length;
	const IMPORT_LIMIT = 700;
	let DEFAULT_DATA_SET = new Array(NUM_ROWS).fill("").map(() => new Array(NUM_COLS).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: NUM_ROWS,
		num_cols: NUM_COLS,
		num_frozen_columns: 0,
		import_limit: IMPORT_LIMIT,
		focused_cell: null,
		selected_regions: null,
		header: DEFAULT_HEADER,
		alignment: DEFAULT_ALIGNMENT,
		comment: COMMENT,
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.USER));
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllUsersAsync({})).then((response) => response.payload.data ? 
				setWorkSheet({
					is_readonly_mode: true,
					num_rows: NUM_ROWS>response.payload.data.users.length ? NUM_ROWS : response.payload.data.users.length,
					num_cols: DEFAULT_HEADER.length,
					num_frozen_columns: 0,
					import_limit: IMPORT_LIMIT,
					focused_cell: null,
					selected_regions: null,
					header: DEFAULT_HEADER,
					alignment: DEFAULT_ALIGNMENT,
					comment: [],
					data_set: response.payload.data.users.map((ds) => [
						ds.id,
						ds.email,
						ds.first_name,
						ds.last_name,
						ds.roles.join(", "),
						ds.position,
						ds.account_status,
						ds.login_status,
						ds.last_logged_out_at,
					])
				}) : void(0)
			);
		}
	}, [
		dispatch,
		translate.breadcrumb.USER,
		worksheet.is_readonly_mode,
		NUM_ROWS,
		IMPORT_LIMIT,
		DEFAULT_HEADER,
		DEFAULT_ALIGNMENT,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const onClearButtonClicked = () => {
		setId(null);
		setEmail('');
		setFirstName('');
		setLastName('');
		setPosition(null);
		setSelectedRoles([]);
		setSelectedRows([]);
		setIsExisting(false);
	};

	const onSkipButtonClicked = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'user'})).then((response) => response.payload.data && response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onRoleCheckboxChecked = (role) => {
		let selectedRoles = selected_roles.includes(role) ? selected_roles.filter((selectedRole) => selectedRole!==role) : [...selected_roles, role];
		if (selectedRoles.includes(auth.enums.roles.SYSTEM_ADMIN)) {
			if (role===auth.enums.roles.ACCOUNTANT) {
				selectedRoles = selectedRoles.filter((selectedRole) => (selectedRole!==auth.enums.roles.SYSTEM_ADMIN));
			}
			else {
				if (!selectedRoles.includes(auth.enums.roles.ACCOUNTANT)) {
					selectedRoles = [...selectedRoles, auth.enums.roles.ACCOUNTANT];
				}
			}
		}
		if ((auth.user.id===null||auth.user.id!==id) && role===auth.enums.roles.AUTHORIZER && selectedRoles.includes(auth.enums.roles.AUTHORIZER) && selectedRoles.includes(auth.enums.roles.MAKER)) {
			selectedRoles = selectedRoles.filter((selectedRole) => (selectedRole!==auth.enums.roles.MAKER));
		}
		if ((auth.user.id===null||auth.user.id!==id) && role===auth.enums.roles.MAKER && selectedRoles.includes(auth.enums.roles.MAKER) && selectedRoles.includes(auth.enums.roles.AUTHORIZER)) {
			selectedRoles = selectedRoles.filter((selectedRole) => (selectedRole!==auth.enums.roles.AUTHORIZER));
		}
		setSelectedRoles(selectedRoles);
	}

	const onCreateButtonClicked = () => {
		const handleCreateResponse = (response) => {
			let usr = response.payload.data.user;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: [
					[
						usr.id,
						usr.email,
						usr.first_name,
						usr.last_name,
						usr.roles.join(", "),
						usr.position,
						usr.account_status,
						usr.login_status,
						usr.last_logged_out_at,
					],
					...worksheet.data_set
				]
			});
			onClearButtonClicked();
		}
		dispatch(
			createUserAsync({
				email: email,
				first_name: first_name,
				last_name: last_name,
				position: position ? position.value : "",
				selected_roles: selected_roles.length > 0 ? selected_roles : "",
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleCreateResponse(response) : null))
	}

	const onUpdateButtonClicked = () => {
		const handleUpdateResponse = (response) => {
			let usr = response.payload.data.user;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set.map((ds) => (ds[0]===usr.id ? [
					usr.id,
					usr.email,
					usr.first_name,
					usr.last_name,
					usr.roles.join(", "),
					usr.position,
					usr.account_status,
					usr.login_status,
					usr.last_logged_out_at,
				] : ds))
			});
		}
		dispatch(
			updateUserAsync({
				id: id,
				email: email,
				first_name: first_name,
				last_name: last_name,
				position: position ? position.value : "",
				selected_roles: selected_roles.length > 0 ? selected_roles : "",
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleUpdateResponse(response) : null))
	}

	const onImportButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			let dataSet = worksheet.data_set.filter((dataSet) => dataSet[0].length>0||dataSet[1].length>0||dataSet[2].length>0||dataSet[3].length>0||dataSet[4].length>0);
			if (dataSet.length<=worksheet.import_limit) {
				const handleResponse = (i, length, status) => {
					if (status===resources.status.SUCCESS) {
						if (i===0) {
							onClearCellsButtonClicked();
							toast.success(translate.message.IMPORT_USERS_QUEUED, Util.getToasterStyle(resources.status.SUCCESS));
						}
						if (i===length-1) {
							toast.success(translate.message.IMPORT_USERS_FINISHED, Util.getToasterStyle(resources.status.SUCCESS));
						}
					}
				} 
				let size = 10;
				let chunks = Util.chunk(dataSet, size);
				let length = chunks.length;
				for (let i=0; i<chunks.length; i++) {
					dispatch(
						importUsersAsync(chunks[i])
					).then((response) => (handleResponse(i, length, response.payload.status)));
				}
			}
			else {
				toast.error(
					translate.message.IMPORT_LIMIT_REACHED,
					Util.getToasterStyle(resources.status.ERROR)
				);
			}
		}
	};

	const onEmailTextChanged = (e) => {
		let email = e.target.value;
		setEmail(email);
		if (id===null && email.length>0) {
			if (email.indexOf('@')>0 && email.lastIndexOf('.')>email.indexOf('@')) {
				const handleResponse = (response) => {
					if (response.payload.data && response.payload.data.user) {
						setFirstName(response.payload.data.user.first_name);
						setLastName(response.payload.data.user.last_name);
						setIsExisting(true);
					}
					else {
						setIsExisting(false);
					}
				}
				dispatch(fetchUserAsync({email:email})).then((response) => handleResponse(response))
			}
			else {
				setIsExisting(false);
			}
		}
		else {
			setIsExisting(false);
		}
	}

	const onDeleteRowsButtonClicked = () => {
		let ids = selected_rows.map(
			(row) => worksheet.data_set[row][0]
		);
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: worksheet.data_set.filter((ds) => (!ids.includes(ds[0])))
				});
				setSelectedRows([]);
			}
		}
		dispatch(deleteUsersAsync(ids)).then((response) => handleResponse(response));
	};

	const onEditButtonClicked = (row) => {
		let pos = worksheet.data_set[row][5] ? positions.find((pos) => pos.value===worksheet.data_set[row][5]) : null;
		setId(worksheet.data_set[row][0]);
		setEmail(worksheet.data_set[row][1]);
		setFirstName(worksheet.data_set[row][2]);
		setLastName(worksheet.data_set[row][3]);
		setPosition(pos);
		setSelectedRoles(auth.roles.filter((role) => ((auth.role_user.filter((roleUser) => (roleUser.user_id===worksheet.data_set[row][0]))).map((roleUser) => (roleUser.role_id)).includes(role.id))).map((role) =>(role.name)));
		window.scrollTo(0,0);
	};

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const onSaveAsPDFButtonClicked = () => {
		PDF.export(
			translate.worksheet.file_name.USER,
			worksheet.header,
			worksheet.data_set,
			auth.active_company,
			"a3",
			"landscape",
			[],
			[50, 120, 120, 120, "*", 90, 60, 90, 60, 90]
		);
	}

	const onSaveAsXLSXButtonClicked = () => {
		Excel.export(
			translate.worksheet.file_name.USER,
			worksheet.header,
			worksheet.data_set,
			auth.active_company
		);
	}

	const onSpreadSheetModeSwitchChanged = () => {
		let newMode = !worksheet.is_readonly_mode;
		setWorkSheet({
			is_readonly_mode: newMode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearCellsButtonClicked = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearSelectedCellsButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			if (worksheet.selected_regions!=null) {
				let dataSet = [];
				let selectedRegions = worksheet.selected_regions;
				for (let i=0; i<worksheet.data_set.length; i++) {
					let row = [];
					for (let j=0; j<worksheet.data_set[i].length; j++) {
						row.push(worksheet.data_set[i][j]);
					}
					dataSet.push(row);
				}
				for (let i=0; i<selectedRegions.length; i++) {
					let selectedRows = selectedRegions[i].rows;
					let selectedCols = selectedRegions[i].cols;
					let startRow = selectedRows[0];
					let endRow = selectedRows[1];
					let startCol = selectedCols[0];
					let endCol = selectedCols[1];
					for (let j=startRow; j<=endRow; j++) {
						if (dataSet[j]!==undefined) {
							for (let k=startCol; k<=endCol; k++) {
								if (dataSet[j][k]!==undefined) {
									dataSet[j][k] = "";
								}
							}
						}
					}
				}
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: dataSet
				});
			}
		}
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SEND_INVITE_LABEL,
							value: translate.text.NOTE_SEND_INVITE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						}
					]}
				/>

				{
					auth.active_company.application_id && 
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSkipButtonClicked}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.USER) }


				{
					worksheet.is_readonly_mode &&
					<>
						<ButtonGroup className="button-group" minimal={false}>
							{
								id===null ?
								<Button
									text={translate.button.SEND_INVITE}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="send-message" className="orange"/>}
									onClick={onCreateButtonClicked}
								/> :
								<Button
									text={translate.button.UPDATE}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={onUpdateButtonClicked}
								/>
							}
							<Button
								text={translate.button.CLEAR}
								className="function-button"
								icon={<Icon icon="reset" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onClearButtonClicked}
							/>
						</ButtonGroup>
						<Card className="function-card">
							<div className="row">
								<TextField
									className="col-sm-12 col-md-3 col-lg-2 text-field"
									size="small"
									variant="outlined"
									inputProps={
										{ readOnly: id!==null, }
									}
									value={email}
									onChange={(e) => onEmailTextChanged(e)}
									label={translate.placeholder.TYPE_USER_EMAIL}
								/>
								<TextField
									className="col-sm-12 col-md-3 col-lg-2 text-field"
									size="small"
									variant="outlined"
									value={first_name}
									onChange={(e) => setFirstName(e.target.value)}
									label={translate.placeholder.TYPE_FIRST_NAME}
									inputProps={
										{ readOnly: is_existing }
									}
								/>
								<TextField
									className="col-sm-12 col-md-3 col-lg-2 text-field"
									size="small"
									variant="outlined"
									value={last_name}
									onChange={(e) => setLastName(e.target.value)}
									label={translate.placeholder.TYPE_LAST_NAME}
									inputProps={
										{ readOnly: is_existing }
									}
								/>
								<div className="col-sm-12 col-md-3 col-lg-2 text-field">
									<MuiAutocomplete
										setSelectedValue={setPosition}
										selected_value={position}
										isMultiple={false}
										label={translate.placeholder.POSITION}
										data={positions}
									/>
								</div>
							</div>
							<div className="row">
								<FormGroup helperText={<span className="helper-text">{translate.text.ASSIGN_ROLE}</span>}>
									{
										id && selected_roles.includes(auth.enums.roles.SUBSCRIBER) &&
										<Checkbox
											checked={ true }
											large={true}
											inline={true}
											label={<span>{auth.enums.roles.SUBSCRIBER}</span>}
											disabled={true}
										/>
									}
									{
										roles.filter((role, key) => (key > 0)).map(
											(role, roleKey) => (
												<Checkbox
													key={roleKey}
													checked={ selected_roles.includes(role) }
													large={true}
													inline={true}
													label={<span>{role}</span>}
													onChange={e => onRoleCheckboxChecked(role)}
												/>
											)
										)
									}
								</FormGroup>
							</div>
						</Card>
					</>
				}

				{
					<ButtonGroup className="button-group" minimal={false}>
						<Switch
							className="switch-mode"
							checked={!worksheet.is_readonly_mode}
							disabled={auth.status === resources.status.LOADING}
							labelElement={<span className="switch-mode-label">{translate.label.SWITCH_MODE}</span>}
							innerLabelChecked={translate.label.IMPORT}
							innerLabel={translate.label.READ_ONLY}
							onChange={onSpreadSheetModeSwitchChanged}
						/>
						{
							worksheet.is_readonly_mode &&
							<>
								<Button
									text={translate.button.SAVE_AS_PDF}
									className="function-button"
									icon={<Icon icon="document" className="orange"/>}
									disabled={auth.status === resources.status.LOADING}
									onClick={onSaveAsPDFButtonClicked}
								/>
								<Button
									text={translate.button.SAVE_AS_XLSX}
									className="function-button"
									icon={<Icon icon="th" className="orange"/>}
									disabled={auth.status === resources.status.LOADING}
									onClick={onSaveAsXLSXButtonClicked}
								/>
								<Button
									text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="select" className="orange"/>}
									onClick={e => setSelectedRows(selected_rows.length>0 ? [] : [...Array(worksheet.data_set.length).keys()])}
								/>
								{
									selected_rows.length>0 &&
									<Button
										text={`${translate.button.DELETE_ROWS} (${selected_rows.length})`}
										disabled={auth.status === resources.status.LOADING}
										className="function-button"
										icon={<Icon icon="delete" className="orange"/>}
										onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
									/>
								}
							</>
						}
						{
							!worksheet.is_readonly_mode &&
							<>
								<Button
									text={translate.button.IMPORT}
									className="function-button"
									icon={<Icon icon="upload" className="orange"/>}
									disabled={auth.status===resources.status.LOADING}
									onClick={onImportButtonClicked}
								/>
								<Button
									text={translate.button.CLEAR_SELECTED_CELLS}
									className="function-button"
									icon={<Icon icon="clean" className="orange"/>}
									disabled={auth.status===resources.status.LOADING}
									onClick={onClearSelectedCellsButtonClicked}
								/>
								<Button
									text={translate.button.CLEAR_CELLS}
									className="function-button"
									icon={<Icon icon="graph-remove" className="orange"/>}
									disabled={auth.status===resources.status.LOADING}
									onClick={onClearCellsButtonClicked}
								/>
							</>
						}
						{
							worksheet.focused_cell &&
							<Button
								text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
								className="function-button"
								icon={<Icon icon="panel-table" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onFreezePaneButtonClicked}
							/>
						}
					</ButtonGroup>
				}

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={onDeleteRowsButtonClicked}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted, User/s already assigned as Maker/ Approver of certain transaction document/s will be removed automatically from the transaction matrix.</li>
		                        <li>System will automatically conduct further verification if deletion of certain User/s is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<UserSpreadsheet
					enable_select={true}
					enable_edit={true}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
					onEditButtonClicked={onEditButtonClicked}
				/>

			</div>
		</div>
	);
}
