import React, { useCallback, useEffect, useState } from 'react';
import PDF from '../../../common/PDF';
import Excel from '../../../common/Excel';
import Util from '../../../common/Util';
import { Note } from "../../../common/Note";
import { useDispatch, useSelector } from 'react-redux';
import {
	selectAuth,
	updateNavigation,
	initializeWorksheet,
	updatePermissionAsync,
	toggleSidebarCollapsed,
	skipProcessAsync,
	fetchAllUsersAsync
} from '../../auth/authSlice';
import { resources } from '../../../resources/index';
import {
	Button,
	ButtonGroup,
	Card,
	FormGroup,
	Checkbox,
	Icon
} from '@blueprintjs/core';
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/table/lib/css/table.css';
import TextField from "@material-ui/core/TextField";
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { PermissionSpreadsheet } from '../../../spreadsheets/PermissionSpreadsheet';

export function Permission() {

	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources['lang'][auth.locale];
	const [ current_application ] = useState(auth.applications.find((app) => app.name===translate.text.PERMISSION));
	const [ users, setUsers ] = useState([]);
	const [ user, setUser ] = useState(null);
	const [ permissions, setPermissions ] = useState(user ? auth.application_user.filter((usr) => (usr.id===user.id)).map((appUser) => (appUser.application_id)) : []);
	const [selected_rows, setSelectedRows] = useState([]);
	const [roles, setRoles] = useState("");
	const DEFAULT_HEADER = translate.worksheet.default_header.PERMISSION;
	const DEFAULT_ALIGNMENT = translate.worksheet.default_alignment.PERMISSION;
	const COMMENT = translate.worksheet.import_comment.PERMISSION;
	const NUM_ROWS = 1000;
	const NUM_COLS = DEFAULT_HEADER.length;
	const IMPORT_LIMIT = 700;
	let DEFAULT_DATA_SET = new Array(NUM_ROWS).fill("").map(() => new Array(NUM_COLS).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: NUM_ROWS,
		num_cols: NUM_COLS,
		num_frozen_columns: 0,
		import_limit: IMPORT_LIMIT,
		focused_cell: null,
		selected_regions: null,
		header: DEFAULT_HEADER,
		alignment: DEFAULT_ALIGNMENT,
		comment: COMMENT,
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.PERMISSION));
		dispatch(fetchAllUsersAsync({account_status:auth.enums.account_status.VERIFIED}))
		.then((response) => response.payload.data ? 
			setWorkSheet({
				is_readonly_mode: true,
				num_rows: NUM_ROWS>response.payload.data.users.length ? NUM_ROWS : response.payload.data.users.length,
				num_cols: DEFAULT_HEADER.length,
				num_frozen_columns: 0,
				import_limit: IMPORT_LIMIT,
				focused_cell: null,
				selected_regions: null,
				header: DEFAULT_HEADER,
				alignment: DEFAULT_ALIGNMENT,
				comment: [],
				data_set: response.payload.data.users.map((ds) => [
					ds.id,
					ds.email,
					ds.first_name,
					ds.last_name,
					ds.roles.join(", "),
					ds.position,
					ds.account_status,
					ds.created_at,
					ds.permissions_updated_at,
				])
			}) : void(0)
		);
	}, [
		dispatch,
		translate.breadcrumb.PERMISSION,
		auth.enums.account_status.VERIFIED,
		setWorkSheet,
		DEFAULT_ALIGNMENT,
		DEFAULT_HEADER
	]);

	useEffect(() => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setUsers(response.payload.data.users.map((user) => ({label:`${user.name}`, email:user.email, roles:user.roles, value:user.id})));
				dispatch(initializeWorksheet({function_title:translate.text.PERMISSION, data:response.payload.data.users}));
			}
		}
		dispatch(fetchAllUsersAsync({account_status:auth.enums.account_status.VERIFIED})).then((response) => handleResponse(response));
	}, [
		dispatch,
		translate.text.PERMISSION,
		auth.enums.account_status.VERIFIED
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (user) {
			setRoles(user.roles.join(", "));
		}
	}, [user]);

	const onClearButtonClicked = () => {
		setUser(null);
		setPermissions([]);
	};

	const onUpdateButtonClicked = () => {
		const handleUpdateResponse = (response) => {
			let usr = response.payload.data.user;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set.map((ds) => (ds[0]===usr.id ? [
					usr.id,
					usr.email,
					usr.first_name,
					usr.last_name,
					usr.roles.join(", "),
					usr.position,
					usr.account_status,
					usr.created_at,
					usr.permissions_updated_at,
				] : ds))
			});
		}
		dispatch(
			updatePermissionAsync({
				user:user ? user.value : "",
				permissions:permissions,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateResponse(response) : null))
	}

	const onApplicationChanged = (application_id) => {
		let selectedPermissions = permissions.includes(application_id) ? permissions.filter((userPermission) => (userPermission!==application_id)) : [...permissions, application_id];
		setPermissions(selectedPermissions);
	};

	const onUserSelected = (selectedUser) => {
		setUser(selectedUser);
		setPermissions(auth.application_user.filter((applicationUser) => (applicationUser.user_id===selectedUser.value)).map((applicationUser) => (applicationUser.application_id)));
	};

	const onEditButtonClicked = (row) => {
		let selectedUser = users.find((usr) => (usr.value===worksheet.data_set[row][0]));
		setUser(selectedUser);
		setPermissions(auth.application_user.filter((applicationUser) => (applicationUser.user_id===selectedUser.value)).map((applicationUser) => (applicationUser.application_id)));
		window.scrollTo(0,0);
	};

	const onSkipButtonClicked = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'permission'})).then((response) => response.payload.data && response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const onSaveAsPDFButtonClicked = () => {
		PDF.export(
			translate.worksheet.file_name.PERMISSION,
			worksheet.header,
			worksheet.data_set,
			auth.active_company,
			"a3",
			"landscape",
			[],
			[50, 120, 120, 120, "*", 90, 60, 90, 60, 90]
		);
	}

	const onSaveAsXLSXButtonClicked = () => {
		Excel.export(
			translate.worksheet.file_name.PERMISSION,
			worksheet.header,
			worksheet.data_set,
			auth.active_company
		);
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SAVE_LABEL,
							value: translate.text.NOTE_SAVE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id && auth.active_company.application_id!==current_application.id &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSkipButtonClicked}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.PERMISSION) }
				<div className="function-sub-title">
					{translate.text.PERMISSION_SUBTITLE}
				</div>

				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={translate.button.SAVE}
						className="function-button"
						disabled={auth.status === resources.status.LOADING}
						icon={<Icon icon="floppy-disk" className="orange"/>}
						onClick={onUpdateButtonClicked}
					/>
					<Button
						text={translate.button.CLEAR}
						className="function-button"
						icon={<Icon icon="reset" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={onClearButtonClicked}
					/>
				</ButtonGroup>

				{
					worksheet.is_readonly_mode &&
					<Card className="function-card">
						<div className="row">
							<div className="col-sm-12 col-md-6 col-lg-4 mb-5">
								<MuiAutocomplete
									setSelectedValue={onUserSelected}
									selected_value={user}
									isMultiple={false}
									label={translate.label.SELECT_USER_FROM_OPTIONS}
									data={users}
								/>
							</div>
						</div>
						{
							user &&
							<div className="row">
								<TextField
									className="col-sm-12 col-md-3 col-lg-3 mb-5"
									size="small"
									variant="outlined"
									value={user ? user.email : null}
									label={translate.label.REGISTERED_EMAIL_ADDRESS}
									inputProps={{readOnly:true}}
								/>
								<TextField
									className="col-sm-12 col-md-8 col-lg-7 mb-5"
									size="small"
									variant="outlined"
									value={roles}
									label={translate.label.ASSIGNED_ROLE}
									inputProps={{readOnly:true}}
									InputLabelProps={{ shrink: true }}
								/>
							</div>
						}
						<div className="row">
							<Card className="column">
								<FormGroup className="permission-title" label="Setup - Not Applicable">
									{
										auth.applications.filter((app, key) => (app.is_purchased)).map(
											(app, appKey) => (
												app.is_function && (app.group===auth.enums.application_groups.SETUP) ?
												(
													<Checkbox
														key={appKey}
														checked={permissions.includes(app.id)}
														large={true}
														inline={false}
														label={<span className="permission-checkbox">{app.name}</span>}
														onChange={event => onApplicationChanged(app.id)}
														disabled={true}
													>
													</Checkbox>
												) :
												app.application_id===null && <h5 style={{color:"blue"}} key={appKey}>{app.name}</h5>
											)
										)
									}
								</FormGroup>
							</Card>
							<Card className="column">
								<FormGroup className="permission-title" label="Utilities - Not Applicable">
									{
										auth.applications.filter((app, key) => (app.is_purchased)).map(
											(app, appKey) => (
												app.is_function && (app.group===auth.enums.application_groups.UTILITIES) ? 
												(
													<Checkbox
														key={appKey}
														checked={permissions.includes(app.id)}
														large={true}
														inline={false}
														label={<span className="permission-checkbox">{app.name}</span>}
														onChange={event => onApplicationChanged(app.id)}
														disabled={true}
													>
													</Checkbox>
												) : 
												app.application_id===null && app.name!=='Point of Sale (POS)' && <h5 style={{color:"blue"}} key={appKey}>{app.name}</h5>
											)
										)
									}
								</FormGroup>
							</Card>
							<Card className="column">
								<FormGroup className="permission-title" label="Transactions">
									{
										auth.applications.filter((app, key) => (app.is_purchased)).map(
											(app, appKey) => (
												app.is_function && (app.group===auth.enums.application_groups.TRANSACTION) ? 
												(
													<Checkbox
														key={appKey}
														checked={permissions.includes(app.id)}
														large={true}
														inline={false}
														label={<span className="permission-checkbox">{app.name}</span>}
														onChange={event => onApplicationChanged(app.id)}
														disabled={!(roles.indexOf(auth.enums.roles.AUTHORIZER)>=0 || roles.indexOf(auth.enums.roles.MAKER)>=0)}
													>
													</Checkbox>
												) : 
												app.application_id===null && app.name!=='Point of Sale (POS)' && app.name!=='Global Settings' && <h5 style={{color:"blue"}} key={appKey}>{app.name}</h5>
											)
										)
									}
								</FormGroup>
							</Card>
							<Card className="column">
								<FormGroup className="permission-title" label="Reports">
									{
										auth.applications.filter((app, key) => (app.is_purchased)).map(
											(app, appKey) => (
												app.is_function && (app.group===auth.enums.application_groups.REPORT) ? 
												(
													<Checkbox
														key={appKey}
														checked={permissions.includes(app.id)}
														large={true}
														inline={false}
														label={<span className="permission-checkbox">{app.name}</span>}
														onChange={event => onApplicationChanged(app.id)}
													>
													</Checkbox>
												) : 
												app.application_id===null && app.name!=='Global Settings' && <h5 style={{color:"blue"}} key={appKey}>{app.name}</h5>
											)
										)
									}
								</FormGroup>

							</Card>
						</div>
					</Card>
				}

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						<Button
							text={translate.button.SAVE_AS_PDF}
							className="function-button"
							icon={<Icon icon="document" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSaveAsPDFButtonClicked}
						/>
						<Button
							text={translate.button.SAVE_AS_XLSX}
							className="function-button"
							icon={<Icon icon="th" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSaveAsXLSXButtonClicked}
						/>
						{
							worksheet.focused_cell &&
							<Button
								text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
								className="function-button"
								icon={<Icon icon="panel-table" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onFreezePaneButtonClicked}
							/>
						}
					</ButtonGroup>
				}

				<PermissionSpreadsheet
					enable_select={false}
					enable_edit={false}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
					onEditButtonClicked={onEditButtonClicked}
				/>

			</div>
		</div>
	);

}
