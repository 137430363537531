import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from '../../../common/Util';
import toast from 'react-hot-toast';
import { Note } from "../../../common/Note";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createProfitCostCenterAsync,
	updateBranchAsync,
	updateDepartmentAsync,
	updateProjectAsync,
	importProfitCostCentersAsync,
	toggleSidebarCollapsed,
	skipProcessAsync,
	fetchAllUsersAsync,
	duplicateProfitCostCentersAsync,
	fetchAllProfitCostCentersAsync,
	deleteProfitCostCentersAsync
} from "../../auth/authSlice";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Switch,
	Card,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { ProfitCostCenterSpreadsheet } from "../../../spreadsheets/ProfitCostCenterSpreadsheet";

export function ProfitCostCenter() {
	const dispatch = useDispatch();
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const history = useHistory();
	const [current_application] = useState(auth.applications.find((app) => app.name===translate.text.PROFIT_COST_CENTER));
	const [id, setId] = useState(null);
	const [code, setCode] = useState("");
	const [description, setDescription] = useState("");
	const [profit_cost_center_types] = useState(Object.values(auth.enums.profit_cost_center_types).map((pccType) => ({ value: pccType, label: pccType })));
	const [type, setType] = useState(null);
	const [users, setUsers] = useState([]);
	const [selected_users, setSelectedUsers] = useState([]);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);
	const [is_confirm_duplicate_dialog_open, setIsConfirmDuplicateDialogOpen] = useState(false);

	const DEFAULT_HEADER = translate.worksheet.default_header.PROFIT_COST_CENTER;
	const DEFAULT_ALIGNMENT = translate.worksheet.default_alignment.PROFIT_COST_CENTER;
	const IMPORT_HEADER = translate.worksheet.import_header.PROFIT_COST_CENTER;
	const IMPORT_ALIGNMENT = translate.worksheet.import_alignment.PROFIT_COST_CENTER;
	const COMMENT = translate.worksheet.import_comment.PROFIT_COST_CENTER;
	const NUM_ROWS = 1000;
	const NUM_COLS = DEFAULT_HEADER.length;
	const IMPORT_LIMIT = 700;
	let DEFAULT_DATA_SET = new Array(NUM_ROWS).fill("").map(() => new Array(NUM_COLS).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: NUM_ROWS,
		num_cols: NUM_COLS,
		num_frozen_columns: 0,
		import_limit: IMPORT_LIMIT,
		focused_cell: null,
		selected_regions: null,
		header: DEFAULT_HEADER,
		alignment: DEFAULT_ALIGNMENT,
		comment: COMMENT,
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.PROFIT_COST_CENTER));
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllProfitCostCentersAsync({})).then((response) => response.payload.data ? 
				setWorkSheet({
					is_readonly_mode: true,
					num_rows: NUM_ROWS>response.payload.data.branches.concat(response.payload.data.departments).concat(response.payload.data.projects).length ? NUM_ROWS : response.payload.data.branches.concat(response.payload.data.departments).concat(response.payload.data.projects).length,
					num_cols: DEFAULT_HEADER.length,
					num_frozen_columns: 0,
					import_limit: IMPORT_LIMIT,
					focused_cell: null,
					selected_regions: null,
					header: DEFAULT_HEADER,
					alignment: DEFAULT_ALIGNMENT,
					comment: [],
					data_set: response.payload.data.branches.concat(response.payload.data.departments).concat(response.payload.data.projects).map((ds) => [
						ds.id,
						ds.type,
						ds.code,
						ds.name,
						ds.created_at,
						ds.updated_at,
					])
				}) : void(0)
			);
			dispatch(fetchAllUsersAsync({account_status:auth.enums.account_status.VERIFIED})).then((response) => (response.payload.status===resources.status.SUCCESS ? setUsers(response.payload.data.users.map((user) => ({label:`${user.name}`, value:user.id}))) : void(0)));
		}
	}, [
		dispatch,
		translate.breadcrumb.PROFIT_COST_CENTER,
		worksheet.is_readonly_mode,
		NUM_ROWS,
		IMPORT_LIMIT,
		DEFAULT_HEADER,
		DEFAULT_ALIGNMENT,
		auth.enums.account_status
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const onClearButtonClicked = () => {
		setId(null);
		setCode('');
		setDescription('');
		setType(null);
		setSelectedUsers([]);
		setSelectedRows([]);
	};

	const onSkipButtonClicked = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'profit-cost-center'})).then((response) => response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onImportButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			let dataSet = worksheet.data_set.filter((dataSet) => dataSet[0].length>0||dataSet[1].length>0||dataSet[2].length>0);
			if (dataSet.length<=worksheet.import_limit) {
				const handleResponse = (i, length, status) => {
					if (status===resources.status.SUCCESS) {
						if (i===0) {
							onClearCellsButtonClicked();
							toast.success(translate.message.IMPORT_PROFIT_COST_CENTERS_QUEUED, Util.getToasterStyle(resources.status.SUCCESS));
						}
						if (i===length-1) {
							setType(null);
							toast.success(translate.message.IMPORT_PROFIT_COST_CENTERS_FINISHED, Util.getToasterStyle(resources.status.SUCCESS));
						}
					}
				} 
				let size = 10;
				let chunks = Util.chunk(dataSet, size);
				let length = chunks.length;
				for (let i=0; i<chunks.length; i++) {
					dispatch(
						importProfitCostCentersAsync({type:type!=null ? type.value : "", data:chunks[i]})
					).then((response) => (handleResponse(i, length, response.payload.status)));
				}
			}
			else {
				toast.error(
					translate.message.IMPORT_LIMIT_REACHED,
					Util.getToasterStyle(resources.status.ERROR)
				);
			}
		}
	};

	const onClearCellsButtonClicked = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearSelectedCellsButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			if (worksheet.selected_regions!=null) {
				let dataSet = [];
				let selectedRegions = worksheet.selected_regions;
				for (let i=0; i<worksheet.data_set.length; i++) {
					let row = [];
					for (let j=0; j<worksheet.data_set[i].length; j++) {
						row.push(worksheet.data_set[i][j]);
					}
					dataSet.push(row);
				}
				for (let i=0; i<selectedRegions.length; i++) {
					let selectedRows = selectedRegions[i].rows;
					let selectedCols = selectedRegions[i].cols;
					let startRow = selectedRows[0];
					let endRow = selectedRows[1];
					let startCol = selectedCols[0];
					let endCol = selectedCols[1];
					for (let j=startRow; j<=endRow; j++) {
						if (dataSet[j]!==undefined) {
							for (let k=startCol; k<=endCol; k++) {
								if (dataSet[j][k]!==undefined) {
									dataSet[j][k] = "";
								}
							}
						}
					}
				}
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: dataSet
				});
			}
		}
	}

	const onEditButtonClicked = (row) => {
		setId(worksheet.data_set[row][0]);
		setType(profit_cost_center_types.find((pccType) => (pccType.value===worksheet.data_set[row][1])));
		setCode(worksheet.data_set[row][2]);
		setDescription(worksheet.data_set[row][3]);
		switch (worksheet.data_set[row][1]) {
			case auth.enums.profit_cost_center_types.BRANCH : {
				let branchUsers = users.filter((user) => (auth.branch_user.filter((branchUser) => (branchUser.branch_id===worksheet.data_set[row][0])).map((branchUser) => (branchUser.user_id)).includes(user.value)));
				setSelectedUsers(branchUsers.map((branchUser) => (users.find((usr) => (usr.value===branchUser.value)))));
				break;
			}
			case auth.enums.profit_cost_center_types.DEPARTMENT : {
				let departmentUsers = users.filter((user) => (auth.department_user.filter((departmentUser) => (departmentUser.department_id===worksheet.data_set[row][0])).map((departmentUser) => (departmentUser.user_id)).includes(user.value)));
				setSelectedUsers(departmentUsers.map((departmentUser) => (users.find((usr) => (usr.value===departmentUser.value)))));
				break;
			}
			case auth.enums.profit_cost_center_types.PROJECT : {
				let projectUsers = users.filter((user) => (auth.project_user.filter((projectUser) => (projectUser.project_id===worksheet.data_set[row][0])).map((projectUser) => (projectUser.user_id)).includes(user.value)));
				setSelectedUsers(projectUsers.map((projectUser) => (users.find((usr) => (usr.value===projectUser.value)))));
				break;
			}
			default : break;
		}
		window.scrollTo(0,0);
	};

	const onUpdateButtonClicked = () => {
		const handleUpdateResponse = (pcc) => {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set.map((ds) => (ds[0]===pcc.id&&ds[1]===pcc.type ? [
					pcc.id,
					pcc.type,
					pcc.code,
					pcc.name,
					pcc.created_at,
					pcc.updated_at,
				] : ds))
			});
		}
		switch (type.value) {
			case auth.enums.profit_cost_center_types.BRANCH : {
				dispatch(
					updateBranchAsync({
						id: id,
						type: type!==null ? type.value : "",
						code: code,
						description: description,
						selected_users: selected_users.map(user => user.value),
						enums: auth.enums,
					})
				).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateResponse(response.payload.data.branch) : null));
				break;
			}
			case auth.enums.profit_cost_center_types.DEPARTMENT : {
				dispatch(
					updateDepartmentAsync({
						id: id,
						type: type!==null ? type.value : "",
						code: code,
						description: description,
						selected_users: selected_users.map(user => user.value),
						enums: auth.enums,
					})
				).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateResponse(response.payload.data.department) : null));
				break;
			}
			case auth.enums.profit_cost_center_types.PROJECT : {
				dispatch(
					updateProjectAsync({
						id: id,
						type: type!==null ? type.value : "",
						code: code,
						description: description,
						selected_users: selected_users.map(user => user.value),
						enums: auth.enums,
					})
				).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateResponse(response.payload.data.project) : null));
				break;
			}
			default : break;
		}
	};

	const onDeleteRowsButtonClicked = () => {
		let ids = selected_rows.map(
			(row) => worksheet.data_set[row][0]
		);
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: worksheet.data_set.filter((ds) => (!(ids).includes(ds[0])))
				});
				setSelectedRows([]);
			}
		}
		dispatch(deleteProfitCostCentersAsync({ids:ids})).then((response) => handleResponse(response));
	};

	const onDuplicateRowsButtonClicked = () => {
		const handleResponse = (response) => {
			let branches = response.payload.data.branches;
			let departments = response.payload.data.departments;
			let projects = response.payload.data.projects;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: [
					...branches.map((br) => ([
						br.id,
						br.type,
						br.code,
						br.name,
						br.created_at,
						br.updated_at,
					])),
					...departments.map((dpt) => ([
						dpt.id,
						dpt.type,
						dpt.code,
						dpt.name,
						dpt.created_at,
						dpt.updated_at,
					])),
					...projects.map((pr) => ([
						pr.id,
						pr.type,
						pr.code,
						pr.name,
						pr.created_at,
						pr.updated_at,
					])),
					...worksheet.data_set
				]
			});
			onClearButtonClicked();
		}
		let profitCostCenters = selected_rows.map(
			(row) => ({
				id:worksheet.data_set[row][0],
				type:worksheet.data_set[row][1],
			})
		);
		dispatch(
			duplicateProfitCostCentersAsync(profitCostCenters)
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleResponse(response) : null));		
	};

	const onCreateButtonClicked = () => {
		const handleCreateResponse = (response) => {
			let pcc = response.payload.data.branch ? response.payload.data.branch : (response.payload.data.department ? response.payload.data.department : response.payload.data.project);
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: [...worksheet.data_set, [
					pcc.id,
					pcc.type,
					pcc.code,
					pcc.name,
					pcc.created_at,
					pcc.updated_at,
				]]
			});
			onClearButtonClicked();
		}
		dispatch(
			createProfitCostCenterAsync({
				type: type!=null ? type.value : "",
				code: code,
				description: description,
				selected_users: selected_users.map(user => user.value),
				enums: auth.enums,
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleCreateResponse(response) : null))
	}

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const onSaveAsPDFButtonClicked = () => {
		PDF.export(
			translate.worksheet.file_name.PROFIT_COST_CENTER,
			worksheet.header,
			worksheet.data_set,
			auth.active_company,
			"letter",
			"landscape",
			[],
			[50, 90, 70, "*", 90, 90]
		);
	}

	const onSaveAsXLSXButtonClicked = () => {
		Excel.export(
			translate.worksheet.file_name.PROFIT_COST_CENTER,
			worksheet.header,
			worksheet.data_set,
			auth.active_company
		);
	}

	const onSpreadSheetModeSwitchChanged = () => {
		let newMode = !worksheet.is_readonly_mode;
		setWorkSheet({
			is_readonly_mode: newMode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
						{
							label: translate.text.NOTE_INLINE_EDITING_FIELD_LABEL,
							value: translate.text.NOTE_INLINE_EDITING_FIELD_VALUE
						},
						{
							label: translate.text.NOTE_PASTING_DATA_LABEL,
							value: translate.text.NOTE_PASTING_DATA_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id && auth.active_company.application_id!==current_application.id &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSkipButtonClicked}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.PROFIT_COST_CENTER) }
				<div className="function-sub-title">
					{translate.text.PROFIT_COST_CENTER_TYPES}
				</div>

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						{
							id===null ?
							<Button
								text={translate.button.CREATE}
								className="function-button"
								disabled={auth.status === resources.status.LOADING}
								icon={<Icon icon="floppy-disk" className="orange"/>}
								onClick={onCreateButtonClicked}
							/> :
							<Button
								text={translate.button.UPDATE}
								className="function-button"
								disabled={auth.status === resources.status.LOADING}
								icon={<Icon icon="floppy-disk" className="orange"/>}
								onClick={onUpdateButtonClicked}
							/>
						}
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onClearButtonClicked}
						/>
					</ButtonGroup>
				}

				{
					worksheet.is_readonly_mode&&
					<Card className="function-card">


							<div className="row">
								<div className="col-sm-12 col-md-5 col-lg-3 text-field">
									<MuiAutocomplete
										setSelectedValue={setType}
										selected_value={type}
										isMultiple={false}
										isDisabled={id!==null}
										label={translate.placeholder.SELECT_PROFIT_COST_CENTER_TYPE}
										data={profit_cost_center_types}
									/>
								</div>
								<div className="col-sm-12 col-md-8 col-lg-8 text-field">
									<MuiAutocomplete
										setSelectedValues={setSelectedUsers}
										selected_values={selected_users}
										isMultiple={true}
										label={translate.placeholder.SELECT_PROFIT_COST_CENTER_USERS}
										data={users}
									/>
								</div>
							</div>

							<div className="row">
								<TextField
									className="col-sm-12 col-md-3 col-lg-3 text-field"
									size="small"
									variant="outlined"
									value={code}
									onChange={(e) => setCode(e.target.value)}
									label={translate.placeholder.ENTER_PROFIT_COST_CENTER_CODE}
								/>
								<TextField
									className="col-sm-12 col-md-8 col-lg-8 text-field"
									size="small"
									variant="outlined"
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									label={translate.placeholder.ENTER_PROFIT_COST_CENTER_DESCRIPTION}
								/>
							</div>



					</Card>
				}

				<ButtonGroup className="button-group" minimal={false}>
					<Switch
						className="switch-mode"
						checked={!worksheet.is_readonly_mode}
						disabled={auth.status === resources.status.LOADING}
						labelElement={<span className="switch-mode-label">{translate.label.SWITCH_MODE}</span>}
						innerLabelChecked={translate.label.IMPORT}
						innerLabel={translate.label.READ_ONLY}
						onChange={onSpreadSheetModeSwitchChanged}
					/>

					{
						!worksheet.is_readonly_mode &&
						<div className="text-field" style={{width:200}}>
							<MuiAutocomplete
								setSelectedValue={setType}
								selected_value={type}
								isMultiple={false}
								isDisabled={id!==null}
								label={translate.placeholder.SELECT_PROFIT_COST_CENTER_TYPE}
								data={profit_cost_center_types}
							/>
						</div>
					}

					{
						worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsPDFButtonClicked}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsXLSXButtonClicked}
							/>
							<Button
								text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon={<Icon icon="select" className="orange"/>}
								onClick={e => setSelectedRows(selected_rows.length>0 ? [] : [...Array(worksheet.data_set.length).keys()])}
							/>
							{
								selected_rows.length>0 &&
								<Button
									text={`${translate.button.DELETE_ROWS}  (${selected_rows.length})`}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="delete" className="orange"/>}
									onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
								/>
							}
							{
								selected_rows.length>0 &&
								<Button
									text={`${translate.button.DUPLICATE_SELECTED_ROWS}  (${selected_rows.length})`}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="duplicate" className="orange"/>}
									onClick={e => setIsConfirmDuplicateDialogOpen(!is_confirm_duplicate_dialog_open)}
								/>
							}
						</>
					}
					{
						!worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.IMPORT}
								className="function-button"
								icon={<Icon icon="upload" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onImportButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_SELECTED_CELLS}
								className="function-button"
								icon={<Icon icon="clean" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearSelectedCellsButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_CELLS}
								className="function-button"
								icon={<Icon icon="graph-remove" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearCellsButtonClicked}
							/>
						</>
					}
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onFreezePaneButtonClicked}
						/>
					}
				</ButtonGroup>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={onDeleteRowsButtonClicked}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted, User/s already assigned in this profit/cost center will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain profit cost center/s is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_duplicate_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDuplicateDialogOpen}
					confirmAction={onDuplicateRowsButtonClicked}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">DUPLICATE</span> the selected row/s ?
						</span>
					}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>The system will make a copy of each of the selected row/s once you click the "Confirm" button.</li>
		                        <li>You need to update the profit / cost center code and description of each of the copies to be able to access it in the transaction.</li>
		                    </ol>
		                </div>
					}
				/>

				<ProfitCostCenterSpreadsheet
					enable_select={true}
					enable_edit={true}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
					onEditButtonClicked={onEditButtonClicked}
					onClearButtonClicked={onClearButtonClicked}
				/>

			</div>
		</div>
	);
}
