import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createItemAsync,
	updateItemAsync,
	importItemsAsync,
	deleteItemsAsync,
	updateItemImageAsync,
	fetchItemAsync,
	//deleteItemImageAsync,
	toggleSidebarCollapsed,
	skipProcessAsync,
	fetchAllItemClassesAsync,
	fetchAllUnitOfMeasureConversionsAsync,
	fetchAllUnitOfMeasuresAsync,
	duplicateItemsAsync,
	fetchAllTaxesAsync,
	fetchAllDiscountsAsync,
	fetchAllItemsAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Switch,
	Card,
	FileInput,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { InventoryItemSpreadsheet } from "../../../spreadsheets/InventoryItemSpreadsheet";

export function InventoryItem() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [current_application] = useState(auth.applications.find((app) => app.name===translate.text.ITEM));
	const [inventory_sources] = useState(Object.values(auth.enums.inventory_sources).map((source, key) => ({label:source, value:source})));
	const [item_costings] = useState(Object.values(auth.enums.item_costings).map((costing, key) => ({label:costing, value:costing})));
	const [unit_of_measure_conversions, setUnitOfMeasureConversions] = useState([]);
	const [id, setId] = useState(null);
	const [item_code, setItemCode] = useState("");
	const [item_description, setItemDescription] = useState("");
	const [inventory_source, setInventorySource] = useState(null);
	const [account] = useState(null);
	const [account_code, setAccountCode] = useState("");
	const [account_description, setAccountDescription] = useState("");
	const [part_number, setPartNumber] = useState("");
	const [custom_barcode_number, setCustomBarcodeNumber] = useState("");
	const [item_costing, setItemCosting] = useState(null);
	const [item_classes, setItemClasses] = useState([]);
	const [item_class, setItemClass] = useState(null);
	const [unit_of_measures, setUnitOfMeasures] = useState([]);
	const [unit_of_measure, setUnitOfMeasure] = useState(null);
	const [has_expiry, setHasExpiry] = useState(false);
	const [is_barcode_enabled, setIsBarcodeEnabled] = useState(false);
	const [is_qr_code_enabled, setIsQrCodeEnabled] = useState(false);
	const [with_components, setWithComponents] = useState(false);
	const [with_serial_number, setWithSerialNumber] = useState(false);
	const [is_beginning_balance, setIsBeginningBalance] = useState(false);
	const [selected_unit_of_measure_conversions, setSelectedUnitOfMeasureConversions] = useState([]);
	const [item_image, setItemImage] = useState(null);
	const [item_image_source, setItemImageSource] = useState(null);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);
	const [serial_number_types] = useState(Object.values(auth.enums.serial_number_types).map((serialNumberType) => ({label:serialNumberType, value:serialNumberType})));
	const [serial_number_type, setSerialNumberType] = useState(null);
	const [serial_number_formats] = useState(Object.values(auth.enums.serial_number_formats).map((sn, key) => ({label:sn, value:sn})));
	const [serial_number_format, setSerialNumberFormat] = useState(serial_number_formats[0]);
	const [serial_number_series, setSerialNumberSeries] = useState(5);
	const [warehouse_inventory] = useState(auth.active_company.general_settings ? JSON.parse(auth.active_company.general_settings.warehouse_inventory) : null);
	const [is_beginning_balance_automated] = useState(warehouse_inventory.beginning_balance_serial_number_setup===auth.enums.serial_number_types.IN_HOUSE);
	const [is_enabled, setIsEnabled] = useState(true);
	const [reorder_point, setReorderPoint] = useState("");
	const [is_confirm_duplicate_dialog_open, setIsConfirmDuplicateDialogOpen] = useState(false);
	const [selling_price, setSellingPrice] = useState("");
	const [taxes, setTaxes] = useState([]);
	const [tax, setTax] = useState(null);
	const [discounts, setDiscounts] = useState([]);
	const [discount, setDiscount] = useState(null);
	const DEFAULT_HEADER = translate.worksheet.default_header.ITEM;
	const DEFAULT_ALIGNMENT = translate.worksheet.default_alignment.ITEM;
	const IMPORT_HEADER = translate.worksheet.import_header.ITEM;
	const IMPORT_ALIGNMENT = translate.worksheet.import_alignment.ITEM;
	const COMMENT = translate.worksheet.import_comment.ITEM;
	const NUM_ROWS = 1000;
	const NUM_COLS = DEFAULT_HEADER.length;
	const IMPORT_LIMIT = 700;
	let DEFAULT_DATA_SET = new Array(NUM_ROWS).fill("").map(() => new Array(NUM_COLS).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: NUM_ROWS,
		num_cols: NUM_COLS,
		num_frozen_columns: 0,
		import_limit: IMPORT_LIMIT,
		focused_cell: null,
		selected_regions: null,
		header: DEFAULT_HEADER,
		alignment: DEFAULT_ALIGNMENT,
		comment: COMMENT,
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.ITEM));
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllItemsAsync({}))
			.then((response) => response.payload.data ? 
				setWorkSheet({
					is_readonly_mode: true,
					num_rows: 1000,
					num_cols: 10,
					num_frozen_columns: 0,
					import_limit: 700,
					focused_cell: null,
					selected_regions: null,
					header: translate.worksheet.default_header.ITEM,
					alignment: translate.worksheet.default_alignment.ITEM,
					comment: [],
					data_set: response.payload.data.items.map((ds) => [
						ds.id,
						ds.code,
						ds.description,
						ds.item_class.name,
						ds.unit_of_measure.code,
						ds.item_costing,
						ds.inventory_source,
						ds.part_number,
						ds.custom_barcode_number,
						ds.inventory_account&&ds.inventory_account.code ? ds.inventory_account.code : "",
						ds.inventory_account&&ds.inventory_account.description ? ds.inventory_account.description : "",
						ds.reorder_point,
						ds.has_expiry ? 'yes' : 'no',
						ds.with_components ? 'yes' : 'no',
						ds.is_barcode_enabled ? 'yes' : 'no',
						ds.is_qr_code_enabled ? 'yes' : 'no',
						ds.with_serial_number ? 'yes' : 'no',
						ds.created_at,
						ds.updated_at,
					])
				}) : void(0)
			);


			dispatch(fetchAllItemClassesAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setItemClasses(response.payload.data.item_classes.filter((ic) => Util.testDuplicateRegex(ic.name)).map((ic) => ({label:ic.name, value:ic.id}))) : void(0)));
			dispatch(fetchAllUnitOfMeasuresAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setUnitOfMeasures(response.payload.data.unit_of_measures.map((uom, key) => ({label:uom.name, value:uom.id}))) : void(0)));
			dispatch(fetchAllUnitOfMeasureConversionsAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setUnitOfMeasureConversions(response.payload.data.unit_of_measure_conversions.map((umc, key) => ({label:`${umc.code} | ${umc.description} → ${parseFloat(umc.source_quantity).toString()} ${umc.source_unit_of_measure.code} = ${parseFloat(umc.convert_quantity).toString()} ${umc.convert_unit_of_measure.code}`, value:umc.id}))) : void(0)));
			dispatch(fetchAllTaxesAsync({type:auth.enums.tax_types.OUTPUT_TAX})).then((response) => (response.payload.status===resources.status.SUCCESS ? setTaxes(response.payload.data.taxes.map((tax, key) => ({label:tax.name, value:tax.id}))) : void(0)));
			dispatch(fetchAllDiscountsAsync({scheduled:true, source:auth.enums.discount_sources.SALES})).then((response) => (response.payload.status===resources.status.SUCCESS ? setDiscounts(response.payload.data.discounts.filter((dsc) => (dsc.name!==auth.enums.regular_discounts.SENIOR_CITIZEN.name && dsc.name!==auth.enums.regular_discounts.PWD.name && dsc.name!==auth.enums.regular_discounts.DIPLOMAT.name)).map((discount, key) => ({label:discount.name, value:discount.id}))) : void(0)));
		}
	}, [
		dispatch,
		translate.breadcrumb.ITEM,
		worksheet.is_readonly_mode,
		auth.enums.regular_discounts,
		translate.worksheet.default_alignment.ITEM,
		translate.worksheet.default_header.ITEM,
		auth.enums.tax_types.OUTPUT_TAX,
		auth.enums.discount_sources.SALES
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (inventory_source && item_class) {
			if (item_class.label===auth.enums.item_classes.FINISHED_GOODS) {
				switch (inventory_source.value) {
					case auth.enums.inventory_sources.LOCAL_PURCHASE : {
						setWithComponents(false);
						break;
					}
					case auth.enums.inventory_sources.OWN_PRODUCTION : {
						setWithComponents(true);
						break;
					}
					default: break;
				}
			}
		}
	}, [inventory_source, item_class, auth.enums.inventory_sources, auth.enums.item_classes.FINISHED_GOODS]);

	const onItemImageUploaded = (event) => {
		setItemImage(event.target.files[0]);
		if (id!==null) {
			dispatch(updateItemImageAsync({id:id, url:item_image_source, item_image:event.target.files[0]})).then((response) => setItemImageSource(response.payload.data));
		}
	};

	const imageExists = (image_url) => {
	    var http = new XMLHttpRequest();
	    http.open('HEAD', image_url, false);
	    http.send();
	    return http.status !== 404;
	}

	const onClearButtonClicked = () => {
		setId(null);
		setItemCode("");
		setItemDescription("");
		setItemClass(null);
		setUnitOfMeasure(null);
		setItemCosting(null);
		setInventorySource(null);
		setPartNumber("");
		setAccountCode("");
		setAccountDescription("");
		setSellingPrice("");
		setTax(null);
		setDiscount(null);
		setCustomBarcodeNumber("");
		setIsEnabled(true);
		setHasExpiry(false);
		setIsBarcodeEnabled(false);
		setIsQrCodeEnabled(false);
		setWithComponents(false);
		setWithSerialNumber(false);
		setSerialNumberFormat(null);
		setSerialNumberType(null);
		setSerialNumberSeries(5);
		setReorderPoint("");
		setSelectedUnitOfMeasureConversions([]);
		setItemImage(null);
		setItemImageSource(null);
		setSelectedRows([]);
	};

	const onImportButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			let dataSet = worksheet.data_set.filter((dataSet) => dataSet[0].length>0||dataSet[1].length>0||dataSet[2].length>0||dataSet[3].length>0);
			if (dataSet.length<=worksheet.import_limit) {
				const handleResponse = (i, length, size, status) => {
					if (status===resources.status.SUCCESS) {
						if (i===0) {
							onClearCellsButtonClicked();
							toast.success(translate.message.IMPORT_ITEMS_QUEUED, Util.getToasterStyle(resources.status.SUCCESS));
						}
						if (i===length-1) {
							toast.success(translate.message.IMPORT_ITEMS_FINISHED, Util.getToasterStyle(resources.status.SUCCESS));
						}
					}
				} 
				let size = 20;
				let chunks = Util.chunk(dataSet, size);
				let length = chunks.length;
				for (let i=0; i<chunks.length; i++) {
					dispatch(
						importItemsAsync(chunks[i])
					).then((response) => (handleResponse(i, length, size, response.payload.status)));
				}
			}
			else {
				toast.error(
					translate.message.IMPORT_LIMIT_REACHED,
					Util.getToasterStyle(resources.status.ERROR)
				);
			}
		}
	};

	const onDeleteRowsButtonClicked = () => {
		let ids = selected_rows.map(
			(row) => worksheet.data_set[row][0]
		);
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: worksheet.data_set.filter((ds) => (!ids.includes(ds[0])))
				});
				setSelectedRows([]);
			}
		}
		dispatch(deleteItemsAsync({ids:ids})).then((response) => handleResponse(response));
	};

	const onSkipButtonClicked = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'item'})).then((response) => response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const onDuplicateRowsButtonClicked = () => {
		const handleResponse = (response) => {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: [
					...response.payload.data.items.map((ds) => ([
						ds.id,
						ds.code,
						ds.description,
						ds.item_class.name,
						ds.unit_of_measure.code,
						ds.item_costing,
						ds.inventory_source,
						ds.part_number,
						ds.custom_barcode_number,
						ds.inventory_account&&ds.inventory_account.code ? ds.inventory_account.code : "",
						ds.inventory_account&&ds.inventory_account.description ? ds.inventory_account.description : "",
						ds.reorder_point,
						ds.has_expiry ? 'yes' : 'no',
						ds.with_components ? 'yes' : 'no',
						ds.is_barcode_enabled ? 'yes' : 'no',
						ds.is_qr_code_enabled ? 'yes' : 'no',
						ds.with_serial_number ? 'yes' : 'no',
						ds.created_at,
						ds.updated_at,
					])),
					...worksheet.data_set
				]
			});
			onClearButtonClicked();
		}
		let items = selected_rows.map(
			(row) => ({
				id:worksheet.data_set[row][0],
			})
		);
		dispatch(
			duplicateItemsAsync(items)
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleResponse(response) : null));		
	};

	const onEditButtonClicked = (row) => {
		const loadItem = (item) => {
			setId(item.id);
			setItemCode(item.code);
			setItemDescription(item.description);
			setItemClass(item_classes.find((itm) => (itm.value===item.item_class.id)));
			setUnitOfMeasure(unit_of_measures.find((uom) => (uom.value===item.unit_of_measure.id)));
			setItemCosting(item_costings.find((ic) => (ic.value===item.item_costing)));
			setInventorySource(inventory_sources.find((is) => (is.value===item.inventory_source)));
			setPartNumber(item.part_number ? item.part_number : "");
			setReorderPoint(item.reorder_point.replace(",",""));
			setAccountCode(item.inventory_account&&item.inventory_account.code ? item.inventory_account.code : "");
			setAccountDescription(item.inventory_account&&item.inventory_account.description ? item.inventory_account.description : "");
			setSellingPrice(item.unit_cost);
			setTax(taxes.find((tax) => (tax.value===item.tax.id)));
			setDiscount(discounts.find((discount) => (discount.value===item.discount.id)));
			setCustomBarcodeNumber(item.custom_barcode_number ? `${item.custom_barcode_number}` : '');
			setIsEnabled(Util.stringToBoolean(item.is_enabled));
			setHasExpiry(Util.stringToBoolean(item.has_expiry));
			setIsBarcodeEnabled(Util.stringToBoolean(item.is_barcode_enabled));
			setIsQrCodeEnabled(Util.stringToBoolean(item.is_qr_code_enabled));
			setWithComponents(Util.stringToBoolean(item.with_components));
			setWithSerialNumber(Util.stringToBoolean(item.with_serial_number));
			let uom_ids = auth.item_unit_of_measure_conversion.filter((iumc) => (iumc.item_id===item.id)).map((iumc) => iumc.unit_of_measure_conversion_id);
			setSelectedUnitOfMeasureConversions(unit_of_measure_conversions.filter((unitOfMeasureConversion) => uom_ids.includes(unitOfMeasureConversion.value)));
			if (item.image_source!==null && imageExists(item.image_source)) {
				setItemImageSource(item.image_source);
			}
			else {
				setItemImageSource(null);
			}
			let serialNumberType = serial_number_types.find((snt) => (snt.value===item.serial_number_type));
			setSerialNumberType(serialNumberType ? serialNumberType : null);
			let serialNumberFormat = serial_number_formats.find((snf) => (snf.value===item.serial_number_format));
			setSerialNumberFormat(serialNumberFormat ? serialNumberFormat : null);
			setSerialNumberSeries(item.serial_number_series);
			setIsBeginningBalance(Util.stringToBoolean(item.is_beginning_balance));
			window.scrollTo(0,0);
		}
		dispatch(fetchItemAsync({id:worksheet.data_set[row][0]})).then((response) => loadItem(response.payload.data.item))
	};

	const onUpdateButtonClicked = () => {
		const handleResponse = (response) => {
			let itm = response.payload.data.item;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set.map((ds) => (ds[0]===itm.id ? [
					itm.id,
					itm.code,
					itm.description,
					itm.item_class.name,
					itm.unit_of_measure.name,
					itm.item_costing,
					itm.inventory_source,
					itm.part_number,
					itm.custom_barcode_number,
					itm.inventory_account&&itm.inventory_account.code ? itm.inventory_account.code : "",
					itm.inventory_account&&itm.inventory_account.description ? itm.inventory_account.description : "",
					itm.reorder_point,
					itm.has_expiry ? 'yes' : 'no',
					itm.with_components ? 'yes' : 'no',
					itm.is_barcode_enabled ? 'yes' : 'no',
					itm.is_qr_code_enabled ? 'yes' : 'no',
					itm.with_serial_number ? 'yes' : 'no',
					itm.created_at,
					itm.updated_at,
				] : ds))
			});
		}
		dispatch(
			updateItemAsync({
				id: id,
				item_code: item_code,
				item_description: item_description,
				item_class: item_class!==null ? item_class.value : "",
				selling_price: selling_price,
				unit_of_measure: unit_of_measure ? unit_of_measure.value : "",
				item_costing: item_costing!==null ? item_costing.value : "",
				inventory_source: inventory_source!==null ? inventory_source.value : "",
				part_number: part_number,
				tax: tax ? tax.value : "",
				discount: discount ? discount.value : "",
				account_code: account_code,
				account_description: account_description,
				custom_barcode_number: custom_barcode_number,
				inventory_account: account!==null ? account.value : "",
				has_expiry: has_expiry,
				reorder_point: reorder_point,
				is_enabled: is_enabled,
				is_barcode_enabled: is_barcode_enabled,
				is_qr_code_enabled: is_qr_code_enabled,
				with_components: with_components,
				with_serial_number: with_serial_number,
				serial_number_type: serial_number_type ? serial_number_type.value : "",
				serial_number_format: serial_number_format ? serial_number_format.value : "",
				serial_number_series: serial_number_series,
				selected_unit_of_measure_conversions: selected_unit_of_measure_conversions && selected_unit_of_measure_conversions.length===0 ? [] : selected_unit_of_measure_conversions.map((umc) => umc.value),
				item_image: item_image
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleResponse(response) : null))
	}

	const onCreateButtonClicked = () => {
		const handleCreateResponse = (response) => {
			let item = response.payload.data.item;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: [
					[
						item.id,
						item.code,
						item.description,
						item.item_class.name,
						item.unit_of_measure.code,
						item.item_costing,
						item.inventory_source,
						item.part_number,
						item.custom_barcode_number,
						item.inventory_account&&item.inventory_account.code ? item.inventory_account.code : "",
						item.inventory_account&&item.inventory_account.description ? item.inventory_account.description : "",
						item.reorder_point,
						item.has_expiry ? 'yes' : 'no',
						item.with_components ? 'yes' : 'no',
						item.is_barcode_enabled ? 'yes' : 'no',
						item.is_qr_code_enabled ? 'yes' : 'no',
						item.with_serial_number ? 'yes' : 'no',
						item.created_at,
						item.updated_at,
					],
					...worksheet.data_set
				]
			});
			onClearButtonClicked();
		}
		dispatch(
			createItemAsync({
				item_code: item_code,
				item_description: item_description,
				item_class: item_class!==null ? item_class.value : null,
				selling_price: selling_price,
				unit_of_measure: unit_of_measure!==null ? unit_of_measure.value : null,
				item_costing: item_costing!==null ? item_costing.value : null,
				inventory_source: inventory_source!==null ? inventory_source.value : null,
				part_number: part_number,
				tax: tax ? tax.value : "",
				discount: discount ? discount.value : "",
				account_code: account_code,
				account_description: account_description,
				custom_barcode_number: custom_barcode_number,
				inventory_account: account!==null ? account.value : null,
				has_expiry: has_expiry,
				reorder_point: reorder_point,
				is_enabled: is_enabled,
				is_barcode_enabled: is_barcode_enabled,
				is_qr_code_enabled: is_qr_code_enabled,
				with_components: with_components,
				with_serial_number: with_serial_number,
				serial_number_type: serial_number_type ? serial_number_type.value : "",
				serial_number_format: serial_number_format ? serial_number_format.value : "",
				serial_number_series: serial_number_series,
				selected_unit_of_measure_conversions: selected_unit_of_measure_conversions.map((umc) => umc.value),
				item_image: item_image
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleCreateResponse(response) : null))
	}

	const onSpreadSheetModeSwitchChanged = () => {
		let newMode = !worksheet.is_readonly_mode;
		setWorkSheet({
			is_readonly_mode: newMode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearCellsButtonClicked = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearSelectedCellsButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			if (worksheet.selected_regions!=null) {
				let dataSet = [];
				let selectedRegions = worksheet.selected_regions;
				for (let i=0; i<worksheet.data_set.length; i++) {
					let row = [];
					for (let j=0; j<worksheet.data_set[i].length; j++) {
						row.push(worksheet.data_set[i][j]);
					}
					dataSet.push(row);
				}
				for (let i=0; i<selectedRegions.length; i++) {
					let selectedRows = selectedRegions[i].rows;
					let selectedCols = selectedRegions[i].cols;
					let startRow = selectedRows[0];
					let endRow = selectedRows[1];
					let startCol = selectedCols[0];
					let endCol = selectedCols[1];
					for (let j=startRow; j<=endRow; j++) {
						if (dataSet[j]!==undefined) {
							for (let k=startCol; k<=endCol; k++) {
								if (dataSet[j][k]!==undefined) {
									dataSet[j][k] = "";
								}
							}
						}
					}
				}
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: dataSet
				});
			}
		}
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
						{
							label: translate.text.NOTE_INLINE_EDITING_FIELD_LABEL,
							value: translate.text.NOTE_INLINE_EDITING_FIELD_VALUE
						},
						{
							label: translate.text.NOTE_PASTING_DATA_LABEL,
							value: translate.text.NOTE_PASTING_DATA_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id && auth.active_company.application_id!==current_application.id &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSkipButtonClicked}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.ITEM) }

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						{
							id===null ?
							<Button
								text={translate.button.CREATE}
								className="function-button"
								disabled={auth.status === resources.status.LOADING}
								icon={<Icon icon="floppy-disk" className="orange"/>}
								onClick={onCreateButtonClicked}
							/> :
							<Button
								text={translate.button.UPDATE}
								className="function-button"
								disabled={auth.status === resources.status.LOADING}
								icon={<Icon icon="floppy-disk" className="orange"/>}
								onClick={onUpdateButtonClicked}
							/>
						}
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onClearButtonClicked}
						/>
					</ButtonGroup>
				}

				{
					worksheet.is_readonly_mode &&
					<Card className="function-card">
						<div className="row">

							{
								id!==null && item_image_source!==null  ?
								<div className="col-sm-12 col-md-8 col-lg-8 center">
									<img alt="item" src={item_image_source} style={{margin:'10px'}}/>
								</div>
								:
								null

							}
							
								<FileInput  className="col-sm-12 col-md-7 col-lg-6 mb-5 center" disabled={false} large={false} text={id===null&&item_image ? item_image.name : translate.text.CHOOSE_FILE} onInputChange={onItemImageUploaded} />
							

						</div>
						<div className="row">

							<TextField
								className="col-sm-12 col-md-3 col-lg-3 text-field"
								size="small"
								variant="outlined"
								value={item_code}
								onChange={(e) =>
									setItemCode(
										e.target.value
									)
								}
								label={translate.placeholder.ENTER_ITEM_CODE}
							/>

							<TextField
								className="col-sm-12 col-md-3 col-lg-3 text-field"
								size="small"
								variant="outlined"
								value={item_description}
								onChange={(e) =>
									setItemDescription(
										e.target.value
									)
								}
								label={translate.placeholder.ENTER_ITEM_DESCRIPTION}
							/>

							<div className="col-sm-12 col-md-2 col-lg-2 text-field">
								<MuiAutocomplete
									setSelectedValue={setItemClass}
									selected_value={item_class}
									isMultiple={false}
									label={translate.placeholder.ADD_NEW_SELECT_ITEM_CLASS}
									data={item_classes}
								/>
							</div>

							<div className="col-sm-12 col-md-2 col-lg-2 text-field">
								<MuiAutocomplete
									setSelectedValue={setTax}
									selected_value={tax}
									isMultiple={false}
									label={translate.placeholder.SELECT_TAX}
									data={taxes}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 col-md-2 col-lg-3 text-field">
								<MuiAutocomplete
									setSelectedValue={setDiscount}
									selected_value={discount}
									isMultiple={false}
									label={translate.placeholder.SELECT_DISCOUNT}
									data={discounts}
								/>
							</div>
							<TextField
								className="col-sm-12 col-md-2 col-lg-3 text-field"
								size="small"
								type="number"
								variant="outlined"
								value={reorder_point}
								onChange={(e) =>
									setReorderPoint(
										e.target.value<0 ? 0 : e.target.value
									)
								}
						        inputProps={{
						            min:0,
						        }}
								label={translate.placeholder.ENTER_REORDER_POINT}
							/>
							<TextField
								className="col-sm-12 col-md-2 col-lg-2 text-field"
								size="small"
								type="number"
								variant="outlined"
								label={`${translate.placeholder.ENTER_SELLING_PRICE} (${auth.active_company.currency.symbol})`}
								value={selling_price}
								onChange={(e) =>
									setSellingPrice(
										e.target.value
									)
								}
							/>
							<div className="col-sm-12 col-md-2 col-lg-2 text-field">
								<MuiAutocomplete
									setSelectedValue={setUnitOfMeasure}
									selected_value={unit_of_measure}
									isMultiple={false}
									label={translate.placeholder.SELECT_TRANSACTION_UOM}
									data={unit_of_measures}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 col-md-3 col-lg-3 text-field">
								<MuiAutocomplete
									setSelectedValue={setItemCosting}
									selected_value={item_costing}
									isMultiple={false}
									label={translate.placeholder.SELECT_ITEM_COSTING}
									data={item_costings}
								/>
							</div>
							<div className="col-sm-12 col-md-3 col-lg-3 text-field">
								<MuiAutocomplete
									setSelectedValue={setInventorySource}
									selected_value={inventory_source}
									isMultiple={false}
									label={translate.placeholder.SELECT_INVENTORY_SOURCE}
									data={inventory_sources}
								/>
							</div>
							<TextField
								className="col-sm-12 col-md-2 col-lg-2 text-field"
								size="small"
								variant="outlined"
								label={translate.placeholder.ENTER_CUSTOM_BARCODE_NUMBER}
								value={custom_barcode_number}
								onChange={(e) =>
									setCustomBarcodeNumber(
										e.target.value
									)
								}
							/>
							<TextField
								className="col-sm-12 col-md-2 col-lg-2 text-field"
								size="small"
								variant="outlined"
								value={part_number}
								onChange={(e) =>
									setPartNumber(
										e.target.value
									)
								}
								label={translate.placeholder.ENTER_PART_NUMBER}
							/>
						</div>

						<div className="row">
							<TextField
								className="col-sm-12 col-md-3 col-lg-3 text-field"
								size="small"
								variant="outlined"
								value={account_code}
								onChange={(e) =>
									setAccountCode(
										e.target.value
									)
								}
								label={"Enter GL Account Code"}
							/>
							<TextField
								className="col-sm-12 col-md-3 col-lg-3 text-field"
								size="small"
								variant="outlined"
								value={account_description}
								onChange={(e) =>
									setAccountDescription(
										e.target.value
									)
								}
								label={"Enter GL Account Description"}
							/>
							<div className="col-sm-12 col-md-4 col-lg-4 text-field">
								<MuiAutocomplete
									setSelectedValues={setSelectedUnitOfMeasureConversions}
									selected_values={selected_unit_of_measure_conversions}
									isMultiple={true}
									label={translate.placeholder.SELECT_UOM_CONVERSION}
									data={unit_of_measure_conversions}
								/>
							</div>


						</div>

						<div className="row">
							<div className="col-sm-12 col-md-10 col-lg-10 text-field">
								<Switch alignIndicator="center" large={true} innerLabelChecked="Is Enabled" innerLabel="Is Disabled"  inline={true} checked={is_enabled} onChange={(e) => setIsEnabled(!is_enabled)}/>
								<Switch alignIndicator="center" large={true} innerLabelChecked="With Components" innerLabel="Without Components"  inline={true} checked={with_components} onChange={(e) => setWithComponents(!with_components)} disabled={inventory_source && item_class && item_class.label===auth.enums.item_classes.FINISHED_GOODS && (inventory_source.value===auth.enums.inventory_sources.LOCAL_PURCHASE||inventory_source.value===auth.enums.inventory_sources.OWN_PRODUCTION)}/>
								<Switch alignIndicator="center" large={true} innerLabelChecked="Has Expiry" innerLabel="Has No Expiry"  inline={true} checked={has_expiry} onChange={(e) => setHasExpiry(!has_expiry)}/>
								<Switch alignIndicator="center" large={true} innerLabelChecked="W/ Serial Number" disabled={id && is_beginning_balance_automated && is_beginning_balance && with_serial_number} innerLabel="Without Serial Number"  inline={true} checked={with_serial_number} onChange={(e) => setWithSerialNumber(!with_serial_number)}/>
								<div className="text-field" style={{width:"170px", display:"inline-block"}}>
									{
										with_serial_number &&
										<MuiAutocomplete
											setSelectedValue={setSerialNumberType}
											selected_value={serial_number_type}
											isMultiple={false}
											label={'Serial Number Type'}
											data={serial_number_types}
										/>
									}
								</div>
								{
									with_serial_number && serial_number_type && serial_number_type.value===auth.enums.serial_number_types.IN_HOUSE &&
									<>
										<div className="text-field" style={{width:"215px", display:"inline-block"}}>
											<MuiAutocomplete
												setSelectedValue={setSerialNumberFormat}
												selected_value={serial_number_format}
												isMultiple={false}
												label={"Select serial number format"}
												data={serial_number_formats}
											/>
										</div>
										<TextField
											className="text-field"
											style={{width:"135px", display:"inline-block"}}
											size="small"
											variant="outlined"
											value={serial_number_series}
											onChange={(e) =>
												setSerialNumberSeries(
													e.target.value
												)
											}
											label={"Enter the no. of digits"}
										    type="number"
										    InputProps={{
										        inputProps: {
										            min: 1
										        }
										    }}
										/>
									</>
								}
							</div>
						</div>

					</Card>
				}

				<ButtonGroup className="button-group" minimal={false}>
					<Switch
						className="switch-mode"
						checked={!worksheet.is_readonly_mode}
						disabled={auth.status === resources.status.LOADING}
						labelElement={<span className="switch-mode-label">{translate.label.SWITCH_MODE}</span>}
						innerLabelChecked={translate.label.IMPORT}
						innerLabel={translate.label.READ_ONLY}
						onChange={onSpreadSheetModeSwitchChanged}
					/>
					{
						worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => PDF.export(
										translate.worksheet.file_name.ITEM,
										worksheet.header,
										worksheet.data_set,
										auth.active_company,
										"a2",
										"landscape",
										[11],
										[30, 60, "*", 100, 40, 50, 60, 60, 60, 60, 100, 60, 50, 50, 50, 50, 50, 80, 80]
									)
								}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => Excel.export(
										translate.worksheet.file_name.ITEM,
										worksheet.header,
										worksheet.data_set,
										auth.active_company
									)
								}
							/>
							<Button
								text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon={<Icon icon="select" className="orange"/>}
								onClick={e => setSelectedRows(selected_rows.length>0 ? [] : worksheet.data_set.map((dataSet, key) => key))}
							/>
							{
								selected_rows.length>0 &&
								<Button
									text={`${translate.button.DELETE_ROWS}  (${selected_rows.length})`}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="delete" className="orange"/>}
									onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
								/>
							}
							{
								selected_rows.length>0 &&
								<Button
									text={`${translate.button.DUPLICATE_SELECTED_ROWS}  (${selected_rows.length})`}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="duplicate" className="orange"/>}
									onClick={e => setIsConfirmDuplicateDialogOpen(!is_confirm_duplicate_dialog_open)}
								/>
							}
						</>
					}
					{
						!worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.IMPORT}
								className="function-button"
								icon={<Icon icon="upload" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onImportButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_SELECTED_CELLS}
								className="function-button"
								icon={<Icon icon="clean" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearSelectedCellsButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_CELLS}
								className="function-button"
								icon={<Icon icon="graph-remove" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearCellsButtonClicked}
							/>
						</>
					}
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onFreezePaneButtonClicked}
						/>
					}
				</ButtonGroup>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={onDeleteRowsButtonClicked}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>System will automatically conduct further verification if deletion of certain item/s is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_duplicate_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDuplicateDialogOpen}
					confirmAction={onDuplicateRowsButtonClicked}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">DUPLICATE</span> the selected row/s ?
						</span>
					}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>The system will make a copy of each of the selected row/s once you click the "Confirm" button.</li>
		                        <li>You need to update the item code and item name of each of the copies to be able to access it in the transaction.</li>
								<li>The unit of measure conversion/s of the original item will not be assigned to the copies since most of the items have different unit of measure / conversions.</li>
		                    </ol>
		                </div>
					}
				/>

				<InventoryItemSpreadsheet
					enable_select={true}
					enable_open={false}
					enable_edit={true}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
					onEditButtonClicked={onEditButtonClicked}
				/>

			</div>
		</div>
	);
}
