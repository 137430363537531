import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { ItemDetailDialog } from "../../warehouse-inventory/item/ItemDetailDialog";
import {
	selectAuth,
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ControlGroup,
	HTMLTable,
	InputGroup,
	Tooltip,
	Position,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import OmnibarSelect from "../../../common/OmnibarSelect";
import { InventoryTrackingDialogMod } from "../../warehouse-inventory/item/InventoryTrackingDialogMod";
import { JournalEntry } from "../../../common/JournalEntry";

export function FinishedGoodProducedLineItem({id, is_editable, finished_goods_produced, finished_good_unit_price, total_production_cost, stock_acceptance_id, yield_unit_of_measure, default_line_items, component_line_items, line_items, setLineItems, default_journal_entries, journal_entries, setJournalEntries, balancing_account, setBalancingAccount, locations, _items}) {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const [is_item_detail_dialog_open, setIsItemDetailDialogOpen] = useState(false);
	const [has_expiry, setHasExpiry] = useState(true);
	const [with_serial_number, setWithSerialNumber] = useState(true);
	const [items, setItems] = useState([]);
	const [item, setItem] = useState(null);
	const [is_omnibar_open, setIsOmnibarOpen] = useState(false);
	const [omnibar_data, setOmnibarData] = useState([]);
	const [omnibar_ds_type, setOmnibarDsType] = useState(null);
	const [selected_inventory_tracking, setSelectedInventoryTracking] = useState(line_items[0]);
	const [quantity_label, setQuantityLabel] = useState(null);
	const [is_inventory_tracking_dialog_open, setIsInventoryTrackingDialogOpen] = useState(false);
	const [inventory_tracking_row, setInventoryTrackingRow] = useState(null);
	const [serial_number_type, setSerialNumberType] = useState(null);

	useEffect(() => {
		if (finished_goods_produced) {
			setItems([{label:finished_goods_produced.label, id:finished_goods_produced.value}]);
		}
	}, [finished_goods_produced]);

	const handleSaveInventoryTrackingButtonClickedEvent = (inventoryTracking) => {
		let lineItems = line_items.map((lineItem, key) => (key===inventoryTracking.inventory_tracking_row ? {
			id:lineItem.id,
			item:lineItem.item, 
			quantity:lineItem.quantity, 
			unit_cost:lineItem.unit_cost, 
			amount:lineItem.amount, 
			uom:lineItem.uom, 
			location:lineItem.location, 
			serial_numbers:inventoryTracking.serial_numbers,
			expiry_quantities:inventoryTracking.expiry_quantities,
			expiry_dates:inventoryTracking.expiry_dates,
			item_conditions:inventoryTracking.item_conditions,
		} : lineItem));
		setLineItems(lineItems);
		setIsInventoryTrackingDialogOpen(false);
	}

	const handleInventoryTrackingButtonClickedEvent = (row, quantityLabel) => {
		let inventoryItem = _items.filter((item) => (item.id===line_items[row].item.value))[0];
		let hasExpiry = Util.stringToBoolean(inventoryItem.has_expiry);
		let withSerialNumber = Util.stringToBoolean(inventoryItem.with_serial_number);
		setHasExpiry(hasExpiry);
		setWithSerialNumber(withSerialNumber);
		setSerialNumberType(inventoryItem.serial_number_type);
		if (hasExpiry || withSerialNumber) {
			setSelectedInventoryTracking(line_items[row]);
			setQuantityLabel(quantityLabel);
			setInventoryTrackingRow(row);
			setIsInventoryTrackingDialogOpen(true);
		}
		else {
			toast.error(
				translate.message.ITEM_HAS_NO_SERIAL_NUMBER_AND_EXPIRY,
				Util.getToasterStyle(resources.status.ERROR)
			);
		}
	};

	const handleDisplayItemDetailsButtonClickedEvent = (selectedItem) => {
		setItem(_items.filter((item) => (item.id===selectedItem.value))[0]);
		setIsItemDetailDialogOpen(true);
	};

	const handleOmnibarItemSelectedEvent = (selectedItem) => {
		switch (selectedItem.ds_type.data_source) {
			case resources.omnibar_ds_types.TO_LOCATION : {
				let lineItems = line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id,
					item:lineItem.item, 
					quantity:lineItem.quantity, 
					unit_cost:lineItem.unit_cost, 
					amount:lineItem.amount, 
					uom:lineItem.uom,
					location:{label:selectedItem.item.label, value:selectedItem.item.id},
					serial_numbers:lineItem.serial_numbers,
					expiry_quantities:lineItem.expiry_quantities,
					expiry_dates:lineItem.expiry_dates,
					item_conditions:lineItem.item_conditions,
				} : lineItem));
				setLineItems(lineItems);
				break;
			}
			case resources.omnibar_ds_types.UNIT_OF_MEASURE : {
				setLineItems(line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id,
					item:lineItem.item, 
					quantity:lineItem.quantity, 
					unit_cost:lineItem.unit_cost, 
					amount:lineItem.amount, 
					uom:{label:selectedItem.item.code, value:selectedItem.item.id},
					location:lineItem.location,
					serial_numbers:lineItem.serial_numbers,
					expiry_quantities:lineItem.expiry_quantities,
					expiry_dates:lineItem.expiry_dates,
					item_conditions:lineItem.item_conditions,
				} : lineItem)));
				break;
			}
			case resources.omnibar_ds_types.ITEM : {
				let lineItems = line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id,
					item:{label:finished_goods_produced.label, default_uom_name:finished_goods_produced.default_uom_name, item_costing:finished_goods_produced.item_costing, value:finished_goods_produced.value}, 
					quantity:lineItem.quantity, 
					unit_cost:lineItem.unit_cost&&lineItem.unit_cost.length>0 ? lineItem.unit_cost : finished_good_unit_price, 
					amount:lineItem.amount, 
					uom:{label:yield_unit_of_measure.code, value:yield_unit_of_measure.id},
					location:lineItem.location,
					serial_numbers:lineItem.serial_numbers,
					expiry_quantities:lineItem.expiry_quantities,
					expiry_dates:lineItem.expiry_dates,
					item_conditions:lineItem.item_conditions,
				} : lineItem));
				setLineItems(lineItems);
				break;
			}
			default: break;
		}
	}

	const handleLocationInputClickedEvent = (row) => {
		setIsOmnibarOpen(true);
		setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.TO_LOCATION});
		setOmnibarData(locations.map((location,key) => ({id:location.id, code:location.code, label:location.label})));
	}

	const handleQuantityInputChangedEvent = (event, row) => {
		let lineItems = line_items.map((lineItem,key) => (key===row ? 
		{
			id:lineItem.id,
			item:lineItem.item, 
			quantity:event.target.value, 
			unit_cost:lineItem.unit_cost, 
			amount:finished_good_unit_price&&event.target.value.length>0 ? (parseFloat(event.target.value)*parseFloat(finished_good_unit_price)).toFixed(2) : '', 
			uom:lineItem.uom,
			location:lineItem.location,
			serial_numbers:lineItem.serial_numbers,
			expiry_quantities:lineItem.expiry_quantities,
			expiry_dates:lineItem.expiry_dates,
			item_conditions:lineItem.item_conditions,
		} : lineItem));
		let actualProduction = (lineItems.reduce((total, lineItem) => total += (lineItem.quantity.length>0 ? parseFloat(lineItem.quantity) : 0), 0));
		let finishedGoodUnitPrice = actualProduction>0 ? (total_production_cost/actualProduction).toFixed(2) : 0;
		lineItems = lineItems.map((lineItem) => ({
			id:lineItem.id,
			item:lineItem.item, 
			quantity:lineItem.quantity, 
			unit_cost:lineItem.item ? finishedGoodUnitPrice : lineItem.unit_cost, 
			amount:lineItem.quantity.length>0&&finishedGoodUnitPrice>0 ? (parseFloat(lineItem.quantity)*parseFloat(finishedGoodUnitPrice)).toFixed(2) : "", 
			uom:lineItem.uom,
			location:lineItem.location,
			serial_numbers:lineItem.serial_numbers,
			expiry_quantities:lineItem.expiry_quantities,
			expiry_dates:lineItem.expiry_dates,
			item_conditions:lineItem.item_conditions,
		}));
		setLineItems(lineItems);
		let journalEntries = journal_entries.map((journalEntry,key) => ({
            account_code:journalEntry.account_code,
            account_description:journalEntry.account_description,
            profit_cost_center:journalEntry.profit_cost_center,
            debit_amount:lineItems[key]&&lineItems[key].item ? lineItems[key].amount : journalEntry.debit_amount,
            credit_amount:lineItems[key]&&lineItems[key].item ? (0).toFixed(2) : journalEntry.credit_amount,
        }));
        setJournalEntries(journalEntries);
	}

	const handleLineItemDeleteButtonClickedEvent = (row) => {
		setLineItems(line_items.filter((lineItem, key) => key!==row));
		setJournalEntries(journal_entries.filter((journalEntry,key) => key!==row));
	};

	const handleItemInputClickedEvent = (row) => {
		setIsOmnibarOpen(true);
		setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.ITEM});
		setOmnibarData(items);
	}

	return (
		<ControlGroup className="col-sm-12 col-md-8 col-lg-9 tbl-parent" vertical={true} style={{background: "grey", border:"solid lightgrey 1px", alignItems:"stretch", overflowX:"hidden", overflowY:"scroll", maxHeight:"75vh"}}>
			<ControlGroup className="tbl-group-xl" vertical={true}>

				<OmnibarSelect 
					is_omnibar_open={is_omnibar_open} 
					setIsOmnibarOpen={setIsOmnibarOpen} 
					omnibar_data={omnibar_data}
					omnibar_ds_type={omnibar_ds_type}
					handleOmnibarItemSelectedEvent={handleOmnibarItemSelectedEvent}
				/>

				<ItemDetailDialog
					item={item}
					is_item_detail_dialog_open={is_item_detail_dialog_open}
					setIsItemDetailDialogOpen={setIsItemDetailDialogOpen}
				/>

				{
					is_inventory_tracking_dialog_open && 
					<InventoryTrackingDialogMod
						document_type={auth.enums.document_types.FINISHED_GOODS_PRODUCED}
						is_inventory_tracking_dialog_open={is_inventory_tracking_dialog_open}
						setIsInventoryTrackingDialogOpen={setIsInventoryTrackingDialogOpen}
						quantity_label={quantity_label}
						selected_inventory_tracking={selected_inventory_tracking}
						has_expiry={has_expiry}
						with_serial_number={with_serial_number}
						handleSaveInventoryTrackingButtonClickedEvent={handleSaveInventoryTrackingButtonClickedEvent}
						inventory_tracking_row={inventory_tracking_row}
						is_editable={is_editable}
						serial_number_type={serial_number_type}
					/>
				}

		        <HTMLTable 
		        	className="line-item-table line-item-table-header"
		        >
		            <tbody>
		            	<tr>
		            		<th className="line line-number"></th>
		            		<th className="input-line line-item">Item (Used)</th>
		            		<th className="input-line uom">UOM</th>
		            		<th className="input-line quantity">Qty</th>
		            		<th className="input-line unit-cost">Unit Cost</th>
		            		<th className="input-line total-amount">Amount</th>
		            		<th className="input-line location">Location</th>
							{
								is_editable &&
								<th className="input-line delete-line-button"></th>
							}
		            	</tr>
		            </tbody>
		        </HTMLTable>
		        <HTMLTable 
		        	className="line-item-table"
					style={{
						background: "#F4F4F4",
					}}
		        >
		            <tbody>
		            	{
		            		component_line_items.map((lineItem, key) => 
		            			<tr key={key} style={{outline: "thin solid lightgrey"}}>
			                    	<td className="line line-number">
			                    		<ControlGroup>
			                        		<Button text={key+1} minimal={true}/>
			                        	</ControlGroup>
			                    	</td>
			                        <td className="input-line line-item">
			                        	<ControlGroup vertical={false}>
											<Button disabled={!component_line_items[key].item} icon={<Tooltip intent={"primary"} disabled={component_line_items[key].item===null} content={component_line_items[key].item ? <span style={{fontSize:"9pt"}}>View Item Details</span> : "Select an Item to view details"} position={Position.RIGHT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} minimal={true} style={{paddingLeft:"15px",paddingRight:"15px"}} onClick={e => (component_line_items[key].item ? handleDisplayItemDetailsButtonClickedEvent(component_line_items[key].item) : null)}/>
				                        	<InputGroup fill={true} value={lineItem.item ? lineItem.item.label : ''} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line uom">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.uom ? lineItem.uom.label : ''} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line quantity">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
											<Button disabled={!component_line_items[key].quantity} icon={<Tooltip disabled={component_line_items[key].item===null} intent={"primary"} content={component_line_items[key].item!==null ? <span style={{fontSize:"9pt"}}>View/Edit/Load Expiry Date & Serial Number (Quantity)</span> : "Applicable to Qty with Serial No. / Expiry"} position={Position.LEFT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} minimal={true} style={{paddingLeft:"15px",paddingRight:"15px"}} onClick={e => handleInventoryTrackingButtonClickedEvent(key, translate.text.QUANTITY)}/>
			                        		<InputGroup fill={true} value={lineItem.quantity} style={{textAlign:"right"}} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line unit-cost">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.unit_cost} style={{textAlign:"right"}} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line total-amount">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.amount} style={{textAlign:"right"}} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line location">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.location ? lineItem.location.label : ''} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
									{
										is_editable &&
										<td className="input-line delete-line-button">
											<Button icon={<Icon icon="cross" size={12}/>} intent="danger" minimal={true} style={{visibility:"hidden"}}/>
										</td>
									}
		            			</tr>
		            		)
		            	}

		            </tbody>
		            <tfoot>
	        			<tr style={{outline: "thin solid lightgrey"}}>
	                    	<td className="line line-number">
	                    	</td>
	                        <td className="input-line line-item">
	                        </td>
	                        <td className="input-line uom">
	                        </td>
	                        <td className="input-line quantity">
	                        </td>
	                        <td className="input-line unit-cost">
	                        </td>
	                        <td className="input-line total-amount">
	                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
	                        		<InputGroup fill={true} value={component_line_items.reduce((total, lineItem) => total = total + (lineItem.amount.length>0 ? parseFloat(lineItem.amount) : 0), 0).toFixed(2)} style={{textAlign:"right", fontWeight:"bold", color:"blue"}} readOnly={true}/>
	                        	</ControlGroup>
	                        </td>
	                        <td className="input-line location">
	                        </td>
							{
								is_editable &&
								<td className="input-line delete-line-button">
								</td>
							}
	        			</tr>
		            </tfoot>
		        </HTMLTable>


		        <HTMLTable 
					className="line-item-table line-item-table-header mt-5"
		        >
		            <tbody>
		            	<tr>
		            		<th className="line line-number"></th>
		            		<th className="input-line line-item">Item (Produced)</th>
		            		<th className="input-line uom">UOM</th>
		            		<th className="input-line quantity">Qty</th>
		            		<th className="input-line unit-cost">Unit Cost</th>
		            		<th className="input-line total-amount">Amount</th>
		            		<th className="input-line location">To Location</th>
							{
								is_editable &&
								<th className="input-line delete-line-button"></th>
							}
		            	</tr>
		            </tbody>
		        </HTMLTable>
		        <HTMLTable 
		        	className="line-item-table"
					style={{
						background: "#F4F4F4",
					}}
		        >
		            <tbody>
		            	{
		            		line_items.map((lineItem, key) => 
		            			<tr key={key} style={{outline: "thin solid lightgrey",width:"95vw"}}>
			                    	<td className="line line-number">
			                    		<ControlGroup>
			                        		<Button text={key+1} minimal={true}/>
			                        	</ControlGroup>
			                    	</td>
			                        <td className="input-line line-item">
			                        	<ControlGroup vertical={false}>
											<Button disabled={!line_items[key].item} icon={<Tooltip intent={"primary"} disabled={line_items[key].item===null} content={line_items[key].item ? <span style={{fontSize:"9pt"}}>View Item Details</span> : "Select an Item to view details"} position={Position.RIGHT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} minimal={true} style={{paddingLeft:"15px",paddingRight:"15px"}} onClick={e => (line_items[key].item ? handleDisplayItemDetailsButtonClickedEvent(line_items[key].item) : null)}/>
				                        	<InputGroup fill={true} value={lineItem.item ? lineItem.item.label : ''} readOnly={true} onClick={e => is_editable ? handleItemInputClickedEvent(key) : void(0)}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line uom">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.uom ? lineItem.uom.label : ''} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line quantity">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
											<Button disabled={!line_items[key].quantity} icon={<Tooltip disabled={line_items[key].item===null} intent={"primary"} content={line_items[key].item!==null ? <span style={{fontSize:"9pt"}}>View/Edit/Load Expiry Date & Serial Number (Quantity)</span> : "Applicable to Qty with Serial No. / Expiry"} position={Position.LEFT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} minimal={true} style={{paddingLeft:"15px",paddingRight:"15px"}} onClick={e => handleInventoryTrackingButtonClickedEvent(key, translate.text.QUANTITY)}/>
			                        		<InputGroup type="number" fill={true} value={lineItem.quantity} style={{textAlign:"right"}} onChange={e => handleQuantityInputChangedEvent(e, key)} readOnly={!is_editable}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line unit-cost">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.unit_cost} style={{textAlign:"right"}} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line total-amount">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.amount} style={{textAlign:"right"}} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line location">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true} value={lineItem.location ? lineItem.location.label : ''} readOnly={true} onClick={e => is_editable ? handleLocationInputClickedEvent(key) : void(0)}/>
			                        	</ControlGroup>
			                        </td>
									{
										is_editable &&
										<td className="input-line delete-line-button">
											<Button icon="cross" size={12} intent="danger" minimal={true} onClick={e => handleLineItemDeleteButtonClickedEvent(key)} disabled={line_items.length<=1}/>
										</td>
									}
		            			</tr>
		            		)
		            	}

		            </tbody>
		            <tfoot>
	        			<tr style={{outline: "thin solid lightgrey"}}>
	                    	<td className="line line-number">
	                    	</td>
	                        <td className="input-line line-item">
	                        </td>
	                        <td className="input-line uom">
	                        </td>
	                        <td className="input-line quantity">
	                        </td>
	                        <td className="input-line unit-cost">
	                        </td>
	                        <td className="input-line total-amount">
	                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
	                        		<InputGroup fill={true} value={line_items.reduce((total, lineItem) => total = total + (lineItem.amount&&lineItem.amount.length>0 ? parseFloat(lineItem.amount) : 0), 0).toFixed(2)} style={{textAlign:"right", fontWeight:"bold", color:"blue"}} readOnly={true}/>
	                        	</ControlGroup>
	                        </td>
	                        <td className="input-line location">
	                        </td>
							{
								is_editable &&
								<td className="input-line delete-line-button">
								</td>
							}
	        			</tr>
		            </tfoot>
		        </HTMLTable>
			</ControlGroup>

	        <ControlGroup className="tbl-group-xl" vertical={false}>
            	<Button 
            		className="function-button"
            		large={false} 
            		onClick={e => setLineItems([...line_items, default_line_items[0]])} 
            		icon={<Icon icon="add" className="orange"/>}
            		text={translate.button.ADD_MORE_ITEMS}
					disabled={!is_editable}
            	/>
				<Button 
					className="function-button"
					large={false} 
					onClick={e => setLineItems(default_line_items)} 
					icon={<Icon icon="remove" className="orange"/>}
					text={translate.button.REMOVE_ALL_ITEMS}
					disabled={!is_editable}
				/>
	        </ControlGroup>

			{
				id &&
				<ControlGroup className="tbl-group-xl" vertical={true}>
					<JournalEntry
						default_journal_entries={default_journal_entries}
						journal_entries={journal_entries}
						setJournalEntries={setJournalEntries}
						profit_cost_centers={[]}
						balancing_account={balancing_account}
						setBalancingAccount={setBalancingAccount}
						is_editable={is_editable}
					/>
				</ControlGroup>
			}
			
		</ControlGroup>

	);
}