import React from 'react';
import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, updateNavigation } from '../auth/authSlice';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { resources } from "../../resources/index";
import { SideBar } from '../../SideBar';
import sysadm from "../../system_admin.jpg";

export function AdminPanel() {

	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.ADMIN_PANEL));
	}, [dispatch, translate.breadcrumb.ADMIN_PANEL]);

	useEffect(() => {
		initFetch()
	}, [initFetch]);

	return (
		<>
		<SideBar/>
		<div style={{width:"100%", display:"flex", flexDirection:"column", marginTop:"50px", justifyContent:"center", alignItems:"center"}}>
			
			<h2>Welcome Finximus Administrator!</h2>
			<img src={sysadm} alt=""/>

		</div>
		</>
	);

}
