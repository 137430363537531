import React, { useCallback, useEffect, useState } from "react";
import PDF from "./common/PDF";
import Excel from "./common/Excel";
import Util from "./common/Util";
import { Note } from "./common/Note";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	//toggleFreezePane,
	toggleSidebarCollapsed,
} from "./features/auth/authSlice";
import { resources } from "./resources/index";
import { Spreadsheet } from "./Spreadsheet";
import {
	Button,
	ButtonGroup,
	Icon,
	Card
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import { SideBar } from './SideBar';
import { MuiAutocomplete } from "./common/MuiAutocomplete";
import TextField from "@material-ui/core/TextField";

export function Document() {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const history = useHistory();
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const [document_params] = useState(params.get("document"));
	const translate = resources["lang"][auth.locale];
	const { status } = useParams();
	const [selected_rows, setSelectedRows] = useState([]);
	const [title] = useState("Documents");
	const [document_type, setDocumentType] = useState(null);
	const [transaction_sources] = useState([{label:"", value:null}, ...Object.values(auth.enums.transaction_sources.stock_acceptance).map((ts) => ({label:ts, value:ts})), ...Object.values(auth.enums.transaction_sources.stock_issuance).map((ts) => ({label:ts, value:ts}))]);
	const [transaction_source, setTransactionSource] = useState(params.get("source") ? transaction_sources.filter((ts) => ts.value===params.get("source"))[0] : null);
	const [document_number, setDocumentNumber] = useState("");
	const [document_types] = useState([{label:"", value:null}, ...auth.document_types.map((dt) => ({label:dt.name, value:dt.id}))]);
	const [approval_status, setApprovalStatus] = useState(null);
	const [approval_statuses] = useState([{label:"", value:null}, ...Object.values(auth.enums.approval_status).map((as) => ({label:as, value:as}))]);
	const [from_document_date, setFromDocumentDate] = useState("");
	const [to_document_date, setToDocumentDate] = useState("");
	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));

	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.WAREHOUSE_INVENTORY));
	}, [
		dispatch,
		translate.breadcrumb.WAREHOUSE_INVENTORY,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	useEffect(() => {
		if (document_params) {
			setDocumentType(document_types.filter((dt) => dt.label===document_params)[0]);
		}
	}, [document_params, document_types]);

	const handleEditButtonClickedEvent = async (row) => {
		let id = worksheet.data_set[row][0];
		let documentType = worksheet.data_set[row][1];
		switch (documentType) {
			case auth.enums.document_types.STOCK_TRANSFER : {
				history.push(`/warehouse-inventory/stock-transfer-request/${id}`);
				break;
			}
			case auth.enums.document_types.STOCK_REQUEST : {
				history.push(`/warehouse-inventory/stock-purchase-request/${id}`);
				break;
			}
			case auth.enums.document_types.ITEM_LOCATION_TRANSFER : {
				history.push(`/warehouse-inventory/item-location-transfer/${id}`);
				break;
			}
			case auth.enums.document_types.STOCK_ACCEPTANCE : {
				history.push(`/warehouse-inventory/stock-acceptance/${id}`);
				break;
			}
			case auth.enums.document_types.IMPORTED_ITEMS_RECEIPT : {
				history.push(`/warehouse-inventory/imported-items-receipt/${id}`);
				break;
			}
			case auth.enums.document_types.STOCK_ADJUSTMENT : {
				if (worksheet.data_set[row][4]===auth.enums.stock_adjustment_types.BEGINNING_BALANCE) {
					history.push(`/warehouse-inventory/beginning-balance/${id}`);
				}
				else {
					history.push(`/warehouse-inventory/stock-adjustment/${id}`);
				}
				break;
			}
			case auth.enums.document_types.FINISHED_GOODS_PRODUCED : {
				history.push(`/warehouse-inventory/finished-goods-produced/${id}`);
				break;
			}
			case auth.enums.document_types.STOCK_ISSUANCE : {
				history.push(`/warehouse-inventory/stock-issuance/${id}`);
				break;
			}
			case auth.enums.document_types.STOCK_COUNT : {
				history.push(`/warehouse-inventory/stock-count/${id}`);
				break;
			}
			default : break;
		}
	};

	const handleClearButtonClickedEvent = () => {
	};

	const handleTransactionSourceChangedEvent = (transactionSource) => {
		setTransactionSource(transactionSource);
	};

	const handleDocumentTypeChangedEvent = (documentType) => {
		setDocumentType(documentType);
	};

	const handleApprovalStatusChangedEvent = (approvalStatus) => {
		setApprovalStatus(approvalStatus);
	};
	
	/*const handleSelectAllButtonClickedEvent = () => {
		setSelectedRows(selected_rows.length>0 ? [] : worksheet.data_set.map((dataSet, key) => key));
	}*/

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
					]}
				/>

				{ Util.functionTitle(title) }
				<div className="function-sub-title">
					
				</div>


				<Card className="function-card">
					<div className="row">
						<div className="col-sm-12 col-md-3 col-lg-3 text-field">
							<MuiAutocomplete
								setSelectedValue={handleDocumentTypeChangedEvent}
								selected_value={document_type}
								isMultiple={false}
								label={"Document Type"}
								data={document_types}
							/>
						</div>

						<div className="col-sm-12 col-md-3 col-lg-3 text-field">
							<MuiAutocomplete
								setSelectedValue={handleTransactionSourceChangedEvent}
								selected_value={transaction_source}
								isMultiple={false}
								label={"Transaction Source"}
								data={transaction_sources}
							/>
						</div>

						<TextField
							className="col-sm-12 col-md-2 col-lg-2 text-field"
							size="small"
							variant="outlined"
							value={document_number}
							label={"Document Number"}
							onChange={(e) => setDocumentNumber(e.target.value)}
						/>

						<div className="col-sm-12 col-md-3 col-lg-2 text-field">
							<MuiAutocomplete
								setSelectedValue={handleApprovalStatusChangedEvent}
								selected_value={approval_status}
								isMultiple={false}
								label={"Approval Status"}
								data={approval_statuses}
							/>
						</div>

						<TextField
							className="col-sm-12 col-md-2 col-lg-2 text-field"
							id="from_date"
							label="Date From"
							type="date"
							variant="outlined"
							size="small"
							value={from_document_date}
							onChange={(e) => setFromDocumentDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					
						<TextField
							id="to_date"
							className="col-sm-12 col-md-2 col-lg-2 text-field"
							label="Date To"
							type="date"
							variant="outlined"
							size="small"
							value={to_document_date}
							onChange={(e) => setToDocumentDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>
				</Card>






				<ButtonGroup className="button-group" minimal={false}>
					
					{
						auth.worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => PDF.export(
										title,
										worksheet.header,
										worksheet.data_set,
										auth.active_company,
										"a3",
										"landscape",
										[],
										[30, 120, 60, 60, 60, 60, 60, "*", 80, 80]
									)
								}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={
									e => Excel.export(
										title,
										worksheet.header,
										worksheet.data_set,
										auth.active_company
									)
								}
							/>
							{/*
							<Button
								text={selected_rows.length>0 ? translate.button.UNSELECT_ALL : translate.button.SELECT_ALL}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon={<Icon icon="select" className="orange"/>}
								onClick={handleSelectAllButtonClickedEvent}
							/>
							
							{
								selected_rows.length>0 &&
								<Button
									text={translate.button.DELETE_ROWS}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="delete" className="orange"/>}
									onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
								/>
							}
							*/}
						</>
					}
					{/*
						!auth.worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.IMPORT}
								className="function-button"
								icon={<Icon icon="upload" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={handleImportButtonClickedEvent}
							/>
							<Button
								text={translate.button.CLEAR_SELECTED_CELLS}
								className="function-button"
								icon={<Icon icon="clean" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={e => dispatch(clearSelectedCells())}
							/>
							<Button
								text={translate.button.CLEAR_CELLS}
								className="function-button"
								icon={<Icon icon="graph-remove" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={e => dispatch(clearCells())}
							/>
						</>
					*/}
				</ButtonGroup>

				<Spreadsheet
					enable_select={true}
					enable_open={false}
					enable_edit={true}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					handleEditButtonClickedEvent={handleEditButtonClickedEvent}
					handleClearButtonClickedEvent={handleClearButtonClickedEvent}
					status={status}
					document_number={document_number}
					document_type={document_type}
					source={transaction_source ? transaction_source.value : ""}
					approval_status={approval_status}
					from_document_date={from_document_date}
					to_document_date={to_document_date}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
				/>

			</div>
		</div>
	);
}