import React, { useState } from "react";
import Util from "../../../common/Util";
import OmnibarSelect from "../../../common/OmnibarSelect";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
	selectAuth,
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ControlGroup,
	HTMLTable,
	InputGroup,
	Tooltip,
	Position,
	Icon,
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import { ItemDetailDialog } from "../../warehouse-inventory/item/ItemDetailDialog";
import { InventoryTrackingDialogMod } from "../../warehouse-inventory/item/InventoryTrackingDialogMod";
import { JournalEntry } from "../../../common/JournalEntry";

export function BeginningBalanceLineItem({id, is_editable, profit_cost_center, default_line_items, line_items, setLineItems, default_journal_entries, journal_entries, setJournalEntries, balancing_account, setBalancingAccount, items, locations}) {
	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const [is_item_detail_dialog_open, setIsItemDetailDialogOpen] = useState(false);
	const [item, setItem] = useState(null);
	const [has_expiry, setHasExpiry] = useState(true);
	const [with_serial_number, setWithSerialNumber] = useState(true);
	const [selected_inventory_tracking, setSelectedInventoryTracking] = useState(line_items[0]);
	const [quantity_label, setQuantityLabel] = useState(null);
	const [is_inventory_tracking_dialog_open, setIsInventoryTrackingDialogOpen] = useState(false);
	const [inventory_tracking_row, setInventoryTrackingRow] = useState(null);
	const [is_omnibar_open, setIsOmnibarOpen] = useState(false);
	const [omnibar_data, setOmnibarData] = useState([]);
	const [omnibar_ds_type, setOmnibarDsType] = useState(null);
	const [serial_number_type, setSerialNumberType] = useState(null);

	const handleLineItemDeleteButtonClickedEvent = (row) => {
		setLineItems(line_items.filter((lineItem, key) => key!==row));
	};

	const handleDisplayItemDetailsButtonClickedEvent = (selectedItem) => {
		setItem(items.filter((item) => (item.id===selectedItem.value))[0]);
		setIsItemDetailDialogOpen(true);
	};

	const handleInventoryTrackingButtonClickedEvent = (row, quantityLabel) => {
		let inventoryItem = items.filter((item) => (item.id===line_items[row].item.value))[0];
		let hasExpiry = Util.stringToBoolean(inventoryItem.has_expiry);
		let withSerialNumber = Util.stringToBoolean(inventoryItem.with_serial_number);
		setHasExpiry(hasExpiry);
		setWithSerialNumber(withSerialNumber);
		setSerialNumberType(inventoryItem.serial_number_type);
		if (hasExpiry || withSerialNumber) {
			setSelectedInventoryTracking(line_items[row]);
			setQuantityLabel(quantityLabel);
			setInventoryTrackingRow(row);
			setIsInventoryTrackingDialogOpen(true);
		}
		else {
			toast.error(
				translate.message.ITEM_HAS_NO_SERIAL_NUMBER_AND_EXPIRY,
				Util.getToasterStyle(resources.status.ERROR)
			);
		}
	};

	const handleSaveInventoryTrackingButtonClickedEvent = (inventoryTracking) => {
		setLineItems(line_items.map((lineItem, key) => (key===inventory_tracking_row ? {
			id:lineItem.id, 
			item:lineItem.item, 
			quantity:lineItem.quantity, 
			unit_cost:lineItem.unit_cost, 
			amount:lineItem.amount, 
			uom:lineItem.uom, 
			location:lineItem.location,
			serial_numbers:inventoryTracking.serial_numbers,
			expiry_dates:inventoryTracking.expiry_dates,
			expiry_quantities:inventoryTracking.expiry_quantities,
			item_conditions:inventoryTracking.item_conditions,
		} : lineItem)));
		setIsInventoryTrackingDialogOpen(false);
	}

	const handleOmnibarItemSelectedEvent = (selectedItem) => {
		switch (selectedItem.ds_type.data_source) {
			case resources.omnibar_ds_types.ITEM : {
				setLineItems(line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id,
					item:{
						label:selectedItem.item.label, 
						default_uom_name:selectedItem.item.default_uom_name, 
						item_costing:selectedItem.item.item_costing, 
						serial_number_type:selectedItem.item.serial_number_type, 
						value:selectedItem.item.id
					}, 
					quantity:lineItem.quantity, 
					unit_cost:lineItem.unit_cost, 
					amount:lineItem.amount, 
					uom:null, 
					location:lineItem.location,
					serial_numbers:[],
					expiry_dates:[],
					expiry_quantities:[],
					item_conditions:[],
				} : lineItem)));
				break;
			}
			case resources.omnibar_ds_types.UNIT_OF_MEASURE : {
				setLineItems(line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id, 
					item:lineItem.item, 
					quantity:lineItem.quantity, 
					unit_cost:lineItem.unit_cost, 
					amount:lineItem.amount, 
					uom:{label:selectedItem.item.code, value:selectedItem.item.id},
					location:lineItem.location,
					serial_numbers:lineItem.serial_numbers,
					expiry_dates:lineItem.expiry_dates,
					expiry_quantities:lineItem.expiry_quantities,
					item_conditions:lineItem.item_conditions,
				} : lineItem)));
				break;
			}
			case resources.omnibar_ds_types.LOCATION : {
				setLineItems(line_items.map((lineItem,key) => (key===selectedItem.ds_type.row ? 
				{
					id:lineItem.id, 
					item:lineItem.item, 
					quantity:lineItem.quantity, 
					unit_cost:lineItem.unit_cost, 
					amount:lineItem.amount, 
					uom:lineItem.uom,
					location:{label:selectedItem.item.label, value:selectedItem.item.id},
					serial_numbers:lineItem.serial_numbers,
					expiry_dates:lineItem.expiry_dates,
					expiry_quantities:lineItem.expiry_quantities,
					item_conditions:lineItem.item_conditions,
				} : lineItem)));
				break;
			}
			default: break;
		}
	}

	const handleItemInputClickedEvent = (row) => {
		setIsOmnibarOpen(true);
		setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.ITEM});
		setOmnibarData(items.filter((item) => Util.stringToBoolean(item.is_enabled)).map((item,key) => ({
			id:item.id, 
			code:item.code, 
			default_uom_name:item.unit_of_measure.name, 
			item_costing:item.item_costing, 
			serial_number_type:item.serial_number_type, 
			label:`${item.code} | ${item.description}`})
		));
	}

	const handleUomInputClickedEvent = (row) => {
		let unitOfMeasures = [];
		let selectedItem = line_items[row].item ? items.filter((item) => (item.id===line_items[row].item.value))[0] : null;
		if (selectedItem!==null) {
			unitOfMeasures = selectedItem.unit_of_measure_sets.map((uom) => ({id:uom.id, code:uom.code, label:uom.code}));
			setIsOmnibarOpen(true);
			setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.UNIT_OF_MEASURE});
			setOmnibarData(unitOfMeasures);
		}
	}

	const handleLocationInputClickedEvent = (row) => {
		let locs = [];
		if (profit_cost_center!==null) {
			locs = locations.filter((loc) => (loc.pcc_id===profit_cost_center.value)).map((location,key) => ({id:location.id, code:location.code, label:`${location.code} | ${location.name}`}));
		}
		else {
			locs = locations.map((location,key) => ({id:location.id, code:location.code, label:`${location.code} | ${location.name}`}));
		}
		setIsOmnibarOpen(true);
		setOmnibarDsType({row:row, data_source:resources.omnibar_ds_types.LOCATION});
		setOmnibarData(locs);
	}

	const handleQuantityInputChangedEvent = (event, row) => {
		let quantity = event.target.value<0 ? 0 : event.target.value;
		let lineItems = line_items.map((lineItem,key) => (key===row ? 
		{
			id:lineItem.id, 
			item:lineItem.item, 
			quantity:quantity, 
			unit_cost:lineItem.unit_cost, 
			amount:lineItem.unit_cost && quantity && lineItem.unit_cost.length>0 && quantity.length>0 ? (Math.round(parseFloat(quantity),4)*Math.round(parseFloat(lineItem.unit_cost),2)).toFixed(2) : '', 
			uom:lineItem.uom,
			location:lineItem.location,
			serial_numbers:lineItem.serial_numbers,
			expiry_dates:lineItem.expiry_dates,
			expiry_quantities:lineItem.expiry_quantities,
			item_conditions:lineItem.item_conditions,
		} : lineItem));
		setLineItems(lineItems);
	}

	const handleUnitCostInputChangedEvent = (event, row) => {
		let unitCost = event.target.value<0 ? 0 : event.target.value;
		let lineItems = line_items.map((lineItem,key) => (key===row ? 
		{
			id:lineItem.id, 
			item:lineItem.item, 
			quantity:lineItem.quantity, 
			unit_cost:unitCost, 
			amount:lineItem.quantity.length>0 && unitCost.length>0 ? (Math.round(parseFloat(lineItem.quantity),4)*Math.round(parseFloat(unitCost),2)).toFixed(2) : '', 
			uom:lineItem.uom,
			location:lineItem.location,
			serial_numbers:lineItem.serial_numbers,
			expiry_dates:lineItem.expiry_dates,
			expiry_quantities:lineItem.expiry_quantities,
			item_conditions:lineItem.item_conditions,
		} : lineItem));
		setLineItems(lineItems);
	}

	return (
		<ControlGroup className="col-sm-12 col-md-8 col-lg-9 tbl-parent" vertical={true} style={{background: "grey", border:"solid lightgrey 1px", alignItems:"stretch", overflowX:"hidden", overflowY:"scroll", maxHeight:"50vh"}}>
			
			<ControlGroup className="tbl-group-lg" vertical={true}>

				<OmnibarSelect 
					is_omnibar_open={is_omnibar_open} 
					setIsOmnibarOpen={setIsOmnibarOpen} 
					omnibar_data={omnibar_data}
					omnibar_ds_type={omnibar_ds_type}
					handleOmnibarItemSelectedEvent={handleOmnibarItemSelectedEvent}
				/>

				<ItemDetailDialog
					item={item}
					is_item_detail_dialog_open={is_item_detail_dialog_open}
					setIsItemDetailDialogOpen={setIsItemDetailDialogOpen}
				/>

				{
					is_inventory_tracking_dialog_open && 
					<InventoryTrackingDialogMod
						document_type={auth.enums.document_types.STOCK_ADJUSTMENT}
						is_inventory_tracking_dialog_open={is_inventory_tracking_dialog_open}
						setIsInventoryTrackingDialogOpen={setIsInventoryTrackingDialogOpen}
						quantity_label={quantity_label}
						selected_inventory_tracking={selected_inventory_tracking}
						has_expiry={has_expiry}
						with_serial_number={with_serial_number}
						handleSaveInventoryTrackingButtonClickedEvent={handleSaveInventoryTrackingButtonClickedEvent}
						is_editable={is_editable}
						serial_number_type={serial_number_type}
					/>
				}

		        <HTMLTable 
		        	className="line-item-table line-item-table-header"
		        >
		            <tbody>
		            	<tr>
		            		<th className="line line-number"></th>
		            		<th className="input-line line-item">Item</th>
		            		<th className="input-line uom">UOM</th>
		            		<th className="input-line quantity">Qty</th>
		            		<th className="input-line unit-cost">Unit Cost</th>
		            		<th className="input-line total-amount">Amount</th>
		            		<th className="input-line location">Location</th>
							{
								is_editable &&
								<th className="input-line delete-line-button"></th>
							}
		            	</tr>
		            </tbody>
		        </HTMLTable>
		        <HTMLTable 
		        	className="line-item-table"
					style={{
						background: "#F4F4F4",
					}}
		        >
		            <tbody>
		            	{
		            		line_items.map((lineItem, key) => 
		            			<tr key={key} style={{outline: "thin solid lightgrey"}}>
			                    	<td className="line line-number">
			                    		<ControlGroup>
			                        		<Button text={key+1} minimal={true}/>
			                        	</ControlGroup>
			                    	</td>
			                        <td className="input-line line-item">
			                        	<ControlGroup vertical={false}>
				                    		<Button disabled={!line_items[key].item} icon={<Tooltip disabled={lineItem.item===null} intent={"primary"} content={line_items[key].item ? <span style={{fontSize:"9pt"}}>View Item Details</span> : "Select an Item to view details"} position={Position.RIGHT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} minimal={true} style={{paddingLeft:"15px",paddingRight:"15px"}} onClick={e => (line_items[key].item ? handleDisplayItemDetailsButtonClickedEvent(line_items[key].item) : null)}/>
				                        	<InputGroup fill={true} value={lineItem.item ? lineItem.item.label : ''} readOnly={true} onClick={e => is_editable ? handleItemInputClickedEvent(key) : void(0)}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line uom">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true}  value={lineItem.uom ? lineItem.uom.label : ''} readOnly={true} onClick={e => is_editable ? handleUomInputClickedEvent(key) : void(0)}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line quantity">
			                        	<ControlGroup vertical={false}>
				                    		<Button disabled={id===null||!line_items[key].quantity} icon={<Tooltip disabled={line_items[key].item===null} intent={"primary"} content={line_items[key].item!==null ? <span style={{fontSize:"9pt"}}>View/Edit/Load Expiry Date & Serial Number (Quantity)</span> : "Applicable to Qty with Serial No. / Expiry"} position={Position.LEFT} openOnTargetFocus={false}><Icon icon="share" size={12} className="orange"/></Tooltip>} minimal={true} style={{paddingLeft:"15px",paddingRight:"15px"}} onClick={e => handleInventoryTrackingButtonClickedEvent(key, translate.text.QUANTITY)}/>
				                        	<InputGroup type="number" fill={true} value={lineItem.quantity} style={{textAlign:"right"}} onChange={e => handleQuantityInputChangedEvent(e, key)} readOnly={!is_editable}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line unit-cost">
				                        <ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
				                        	<InputGroup type="number" fill={true} value={lineItem.unit_cost ? lineItem.unit_cost : ""} style={{textAlign:"right"}} onChange={e => handleUnitCostInputChangedEvent(e, key)} readOnly={!is_editable}/>
				                        </ControlGroup>
			                        </td>
			                        <td className="input-line total-amount">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true}  value={lineItem.amount ? lineItem.amount : ""} style={{textAlign:"right"}} readOnly={true}/>
			                        	</ControlGroup>
			                        </td>
			                        <td className="input-line location">
			                        	<ControlGroup vertical={false} style={{paddingLeft:"10px"}}>
			                        		<InputGroup fill={true}  value={lineItem.location ? lineItem.location.label : ''} readOnly={true} onClick={e => is_editable ? handleLocationInputClickedEvent(key) : void(0)}/>
			                        	</ControlGroup>
			                        </td>
									{
										is_editable &&
										<td className="input-line delete-line-button">
											<Button icon={<Icon icon="cross" size={12}/>} intent="danger" minimal={true} onClick={e => handleLineItemDeleteButtonClickedEvent(key)} disabled={!is_editable||line_items.length<=1}/>
										</td>
									}
		            			</tr>
		            		)
		            	}

		            </tbody>
		        </HTMLTable>
			</ControlGroup>

	        <ControlGroup className="tbl-group-lg" vertical={false}>
	        	<Button 
	        		className="function-button"
	        		large={false} 
	        		onClick={e => setLineItems([...line_items, default_line_items[0]])} 
	        		icon={<Icon icon="add" className="orange"/>}
	        		text={translate.button.ADD_MORE_ITEMS}
					disabled={!is_editable}
	        	/>
				<Button 
					className="function-button"
					large={false} 
					onClick={e => setLineItems(default_line_items)} 
					icon={<Icon icon="remove" className="orange"/>}
					text={translate.button.REMOVE_ALL_ITEMS}
					disabled={!is_editable||line_items.length<=1}
				/>
	        </ControlGroup>

			{
				id &&
				<ControlGroup className="tbl-group-lg" vertical={true}>
					<JournalEntry
						default_journal_entries={default_journal_entries}
						journal_entries={journal_entries}
						setJournalEntries={setJournalEntries}
						profit_cost_centers={[]}
						balancing_account={balancing_account}
						setBalancingAccount={setBalancingAccount}
						is_editable={is_editable}
					/>
				</ControlGroup>
			}

		</ControlGroup>
	);
}