import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	postCommentAsync,
} from "../features/auth/authSlice";
import { resources } from "../resources/index";
import {
	Button,
	Card,
	ControlGroup,
	Collapse,
	InputGroup,
	Tag,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";

export function Comment({id, document_type, comments, created_by, setDocument, is_editable, is_authorizable}) {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [is_comments_open , setIsCommentsOpen] = useState(false);
	const [message , setMessage] = useState("");

	const handleCommentsButtonClickedEvent = () => {
		setIsCommentsOpen(!is_comments_open);
	};

	const handlePostCommentButtonClickResponse = (response) => {
		if (response.payload.status===resources.status.SUCCESS) {
			setMessage("");
			setDocument(response.payload.data);
		}
	}

	const handlePostCommentButtonClickEvent = (file_name) => {
		dispatch(
			postCommentAsync({
				id:id,
				document_type:document_type,
				message:message,
			})
		).then((response) =>handlePostCommentButtonClickResponse(response));
	};

	return (
		<div className="row">
			<ControlGroup fill={true} vertical={"true"}>
				<Button style={{fontWeight:"bold", marginTop:"2px", marginBottom:"2px"}} alignText="left" fill={true} onClick={handleCommentsButtonClickedEvent} rightIcon={is_comments_open ? "caret-up" : "caret-down"}>{translate.text.COMMENTS}</Button>
				{
					(is_comments_open && (is_editable||is_authorizable)) &&
					<InputGroup
						placeholder="Type your message here then click 'Post'"
						rightElement={
							<Button 
								rightIcon={<Icon icon="send-message" size={10} className="orange"/>}
								onClick={handlePostCommentButtonClickEvent}
								disabled={auth.status===resources.status.LOADING}
								outlined={true}
								large={false}
								style={{fontSize:"9pt"}}
							>
								Post
							</Button>
						}
						value={message}
						onChange={e => setMessage(e.target.value)}
					/>
				}
			</ControlGroup>
			<Collapse className="col-sm-12 col-md-12 col-lg-12" isOpen={is_comments_open} keepChildrenMounted={true}>
				<Card style={{margin:"0px",padding:"4px",height:"120px",overflowY:"scroll"}}>
					{
						comments.slice(0).reverse().map((comment, key) => (
							<div key={key} style={{textAlign:created_by.name===comment.posted_by ? "right" : "left"}}>
								<Tag fill={true} style={{background:created_by.name===comment.posted_by ? "PaleTurquoise" : "PeachPuff",textAlign:"justify", marginBottom:"5px",marginTop:"10px"}} multiline={true} round={false}>
									<div style={{color:created_by.name===comment.posted_by ? "blue" : "black",fontWeight:"bold", fontSize:"9pt", textAlign:created_by.name===comment.posted_by ? "left" : "right"}}>{comment.posted_by}</div>
									<span style={{color:"black"}}>
										{comment.message}
									</span>
									<div style={{color:created_by.name===comment.posted_by ? "blue" : "black",fontWeight:"bold", fontSize:"8pt", textAlign:created_by.name===comment.posted_by ? "right" : "left"}}>{comment.posted_at}</div>
								</Tag>
							</div>
						))
					}
					{
						(comments.length===0) &&
						<div style={{fontSize:"10pt",textAlign:"center"}}>{"No posted comment/s."}</div>
					}
				</Card>
			</Collapse>
		</div>
	);
}
