import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, toggleSidebarCollapsed, forCycleCountReconAsync, updateLaundryCycleCountAsync, finalizeLaundryCycleCountAsync } from "./features/auth/authSlice";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { resources } from "./resources/index";
import { Card, ButtonGroup, Button, Icon } from "@blueprintjs/core";
import { SideBar } from './SideBar';
import Util from './common/Util';
import { Note } from './common/Note';
import TextField from "@material-ui/core/TextField";
import { ConfirmDialog } from './common/ConfirmDialog';

export function CycleCountReconciliation() {

	const auth = useSelector(selectAuth);
	const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [ cycle_counts, setCycleCounts ] = useState([]);
	const [ branches, setBranches ] = useState([]);
	const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);

	useEffect(() => {
		if (auth.active_company && auth.active_company.application_id===null && (auth.user.position===auth.enums.positions.MANAGER)) {
			const handleResponse = (res) => {
				setCycleCounts(res.payload.data.laundry_cycle_counts);
				setBranches(res.payload.data.branches);
			}
			dispatch(forCycleCountReconAsync()).then((res) => res.payload.data ? handleResponse(res) : void(0));
		}
	}, [
		dispatch,
		auth.active_company,
		auth.user.position,
		auth.enums.positions.MANAGER
	]);

	const handleWasherCurrentCycleCountChanged = (id, e) => {
		if (!isNaN(parseFloat(e.target.value))) {
			setCycleCounts(cycle_counts.map((cc) => (cc.id===id ? {
				id:cc.id,
				dryer_previous_cycle_count:cc.dryer_previous_cycle_count,
				washer_previous_cycle_count:cc.washer_previous_cycle_count,
				dryer_current_cycle_count:cc.dryer_current_cycle_count,
				washer_current_cycle_count:parseFloat(e.target.value),
				dryer_cycle_count_for_the_day:cc.dryer_cycle_count_for_the_day,
				washer_cycle_count_for_the_day:(parseFloat(e.target.value)-parseFloat(cc.washer_previous_cycle_count)),
				dryer_pos_quantity:cc.dryer_pos_quantity,
				washer_pos_quantity:cc.washer_pos_quantity,
				dryer_difference:cc.dryer_difference,
				washer_difference:(parseFloat(e.target.value)-parseFloat(cc.washer_previous_cycle_count)-parseFloat(cc.washer_pos_quantity)),
				dryer_reason_for_difference:cc.dryer_reason_for_difference,
				washer_reason_for_difference:(parseFloat(e.target.value)-parseFloat(cc.washer_previous_cycle_count)-parseFloat(cc.washer_pos_quantity))===0 ? "" : cc.washer_reason_for_difference,
				laundry_machine_set_id:cc.laundry_machine_set_id,
				laundry_machine_set_dryer_number:cc.laundry_machine_set_dryer_number,
				laundry_machine_set_washer_number:cc.laundry_machine_set_washer_number,
				branch_id:cc.branch_id,
				reading_date:cc.reading_date,
				created_at:cc.created_at,
			} : cc)));
		}
	}

	const handleDryerCurrentCycleCountChanged = (id, e) => {
		if (!isNaN(parseFloat(e.target.value))) {
			setCycleCounts(cycle_counts.map((cc) => (cc.id===id ? {
				id:cc.id,
				dryer_previous_cycle_count:cc.dryer_previous_cycle_count,
				washer_previous_cycle_count:cc.washer_previous_cycle_count,
				dryer_current_cycle_count:parseFloat(e.target.value),
				washer_current_cycle_count:cc.washer_current_cycle_count,
				dryer_cycle_count_for_the_day:(parseFloat(e.target.value)-parseFloat(cc.dryer_previous_cycle_count)),
				washer_cycle_count_for_the_day:cc.washer_cycle_count_for_the_day,
				dryer_pos_quantity:cc.dryer_pos_quantity,
				washer_pos_quantity:cc.washer_pos_quantity,
				dryer_difference:(parseFloat(e.target.value)-parseFloat(cc.dryer_previous_cycle_count)-parseFloat(cc.dryer_pos_quantity)),
				washer_difference:cc.washer_difference,
				dryer_reason_for_difference:(parseFloat(e.target.value)-parseFloat(cc.dryer_previous_cycle_count)-parseFloat(cc.dryer_pos_quantity))===0 ? "" : cc.dryer_reason_for_difference,
				washer_reason_for_difference:cc.washer_reason_for_difference,
				laundry_machine_set_id:cc.laundry_machine_set_id,
				laundry_machine_set_dryer_number:cc.laundry_machine_set_dryer_number,
				laundry_machine_set_washer_number:cc.laundry_machine_set_washer_number,
				branch_id:cc.branch_id,
				reading_date:cc.reading_date,
				created_at:cc.created_at,
			} : cc)));
		}
	}

	const handleUpdateButtonClickedEvent = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setCycleCounts(response.payload.data.laundry_cycle_counts);
			}
		}
		dispatch(updateLaundryCycleCountAsync({cycle_counts:cycle_counts})).then((res) => handleResponse(res));
	}

	const handleFinalizeButtonClickedEvent = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setCycleCounts(response.payload.data.laundry_cycle_counts);
				setBranches(response.payload.data.branches);
			}
		}
		dispatch(finalizeLaundryCycleCountAsync({cycle_counts:cycle_counts})).then((res) => handleResponse(res));
	}

	const handleDryerReasonForDifferenceChanged = (id, e) => {
		setCycleCounts(cycle_counts.map((cc) => (cc.id===id ? {
			id:cc.id,
			dryer_previous_cycle_count:cc.dryer_previous_cycle_count,
			washer_previous_cycle_count:cc.washer_previous_cycle_count,
			dryer_current_cycle_count:cc.dryer_current_cycle_count,
			washer_current_cycle_count:cc.washer_current_cycle_count,
			dryer_cycle_count_for_the_day:cc.dryer_cycle_count_for_the_day,
			washer_cycle_count_for_the_day:cc.washer_cycle_count_for_the_day,
			dryer_pos_quantity:cc.dryer_pos_quantity,
			washer_pos_quantity:cc.washer_pos_quantity,
			dryer_difference:cc.dryer_difference,
			washer_difference:cc.washer_difference,
			dryer_reason_for_difference:e.target.value,
			washer_reason_for_difference:cc.washer_reason_for_difference,
			laundry_machine_set_id:cc.laundry_machine_set_id,
			laundry_machine_set_dryer_number:cc.laundry_machine_set_dryer_number,
			laundry_machine_set_washer_number:cc.laundry_machine_set_washer_number,
			branch_id:cc.branch_id,
			reading_date:cc.reading_date,
			created_at:cc.created_at,
		} : cc)));
	}

	const handleWasherReasonForDifferenceChanged = (id, e) => {
		setCycleCounts(cycle_counts.map((cc) => (cc.id===id ? {
			id:cc.id,
			dryer_previous_cycle_count:cc.dryer_previous_cycle_count,
			washer_previous_cycle_count:cc.washer_previous_cycle_count,
			dryer_current_cycle_count:cc.dryer_current_cycle_count,
			washer_current_cycle_count:cc.washer_current_cycle_count,
			dryer_cycle_count_for_the_day:cc.dryer_cycle_count_for_the_day,
			washer_cycle_count_for_the_day:cc.washer_cycle_count_for_the_day,
			dryer_pos_quantity:cc.dryer_pos_quantity,
			washer_pos_quantity:cc.washer_pos_quantity,
			dryer_difference:cc.dryer_difference,
			washer_difference:cc.washer_difference,
			dryer_reason_for_difference:cc.dryer_reason_for_difference,
			washer_reason_for_difference:e.target.value,
			laundry_machine_set_id:cc.laundry_machine_set_id,
			laundry_machine_set_dryer_number:cc.laundry_machine_set_dryer_number,
			laundry_machine_set_washer_number:cc.laundry_machine_set_washer_number,
			branch_id:cc.branch_id,
			reading_date:cc.reading_date,
			created_at:cc.created_at,
		} : cc)));
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<div style={{visibility:"hidden"}}>
					<Note
						contents={[
						]}
					/>
				</div>

				{ Util.functionTitle(translate.text.CYCLE_COUNT_RECONCILIATION) }
				<div className="function-sub-title"></div>

				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={translate.button.UPDATE}
						className="function-button"
						icon={<Icon icon="floppy-disk" className="orange"/>}
						disabled={auth.status === resources.status.LOADING||cycle_counts.length===0}
						onClick={handleUpdateButtonClickedEvent}
					/>
					<Button
						text={translate.button.FINALIZE}
						className="function-button"
						icon={<Icon icon="confirm" className="orange"/>}
						disabled={auth.status === resources.status.LOADING||cycle_counts.length===0}
						onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
					/>
				</ButtonGroup>

				{
					cycle_counts.length===0 &&
					<h3 style={{textAlign:"center", color:"red"}}>There are no more cycle count to reconcile</h3>
				}

				{
					branches.map((br,key) => (
						<Card className="function-card" key={key}>
							<h4 className='center'>{br.name}</h4>
							{
								cycle_counts.filter((cc) => (cc.branch_id===br.id)).map((cc, key2) => (
									<div key={key2} style={{marginBottom:15}}>
										{
											key2===0 &&
											<div style={{textAlign:"center", marginBottom:10}}>Reading Date: {cc.reading_date}</div>
										}
										<div className="row">
											<TextField
												className="col-sm-12 col-md-2 col-lg-2 text-field"
												size="small"
												variant="outlined"
												value={cc.dryer_previous_cycle_count}
												label={`Dryer ${cc.laundry_machine_set_dryer_number} Previous Cycle Count`}
												inputProps={
													{ readOnly: true }
												}
												type='number'
											/>
											<TextField
												className="col-sm-12 col-md-2 col-lg-2 text-field"
												size="small"
												variant="outlined"
												value={cc.dryer_current_cycle_count}
												label={`Dryer ${cc.laundry_machine_set_dryer_number} Current Cycle Count`}
												onChange={(e) => handleDryerCurrentCycleCountChanged(cc.id, e)}
												type='number'
											/>
											<TextField
												className="col-sm-12 col-md-2 col-lg-2 text-field"
												size="small"
												variant="outlined"
												value={cc.dryer_cycle_count_for_the_day}
												label={`Dryer ${cc.laundry_machine_set_dryer_number} Cycle Count for the Day`}
												inputProps={
													{ readOnly: true }
												}
												type='number'
											/>
											<TextField
												className="col-sm-12 col-md-1 col-lg-1 text-field"
												size="small"
												variant="outlined"
												value={cc.dryer_pos_quantity}
												label={`Dryer ${cc.laundry_machine_set_dryer_number} POS Qty`}
												inputProps={
													{ readOnly: true }
												}
												type='number'
											/>
											<TextField
												className="col-sm-12 col-md-1 col-lg-1 text-field"
												size="small"
												variant="outlined"
												value={cc.dryer_difference}
												label={`Dryer ${cc.laundry_machine_set_dryer_number} Difference`}
												inputProps={
													{ readOnly: true }
												}
												type='number'
											/>
											<TextField
												className="col-sm-12 col-md-3 col-lg-3 text-field"
												size="small"
												variant="outlined"
												value={cc.dryer_reason_for_difference ? cc.dryer_reason_for_difference : ""}
												label={`Reason/s for the Difference`}
												disabled={cc.dryer_difference===0}
												onChange={(e) => handleDryerReasonForDifferenceChanged(cc.id, e)}
											/>
										</div>
										<div className="row">
											<TextField
												className="col-sm-12 col-md-2 col-lg-2 text-field"
												size="small"
												variant="outlined"
												value={cc.washer_previous_cycle_count}
												label={`Washer ${cc.laundry_machine_set_washer_number} Previous Cycle Count`}
												inputProps={
													{ readOnly: true }
												}
												type='number'
											/>
											<TextField
												className="col-sm-12 col-md-2 col-lg-2 text-field"
												size="small"
												variant="outlined"
												value={cc.washer_current_cycle_count}
												label={`Washer ${cc.laundry_machine_set_washer_number} Current Cycle Count`}
												type='number'
												onChange={(e) => handleWasherCurrentCycleCountChanged(cc.id, e)}
											/>
											<TextField
												className="col-sm-12 col-md-2 col-lg-2 text-field"
												size="small"
												variant="outlined"
												value={cc.washer_cycle_count_for_the_day}
												label={`Washer ${cc.laundry_machine_set_washer_number} Cycle Count for the Day`}
												inputProps={
													{ readOnly: true }
												}
												type='number'
											/>
											<TextField
												className="col-sm-12 col-md-1 col-lg-1 text-field"
												size="small"
												variant="outlined"
												value={cc.washer_pos_quantity}
												label={`Washer ${cc.laundry_machine_set_washer_number} POS Qty`}
												inputProps={
													{ readOnly: true }
												}
												type='number'
											/>
											<TextField
												className="col-sm-12 col-md-1 col-lg-1 text-field"
												size="small"
												variant="outlined"
												value={cc.washer_difference}
												label={`Washer ${cc.laundry_machine_set_washer_number} Difference`}
												inputProps={
													{ readOnly: true }
												}
												type='number'
											/>
											<TextField
												className="col-sm-12 col-md-3 col-lg-3 text-field"
												size="small"
												variant="outlined"
												value={cc.washer_reason_for_difference ? cc.washer_reason_for_difference : ""}
												label={`Reason/s for the Difference`}
												disabled={cc.washer_difference===0}
												onChange={(e) => handleWasherReasonForDifferenceChanged(cc.id, e)}
											/>
										</div>
									</div>
								))
							}
						</Card>
					))
				}

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={handleFinalizeButtonClickedEvent}
				/>
			</div>
		</div>

	);

}
