import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, toggleSidebarCollapsed } from "./features/auth/authSlice";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { ControlGroup, H2, Icon } from "@blueprintjs/core";
import { SideBar } from './SideBar';

export function ErrorPage({message}) {

	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();

	const handleToggleSideBarCollapsed = () => {
		if (auth.is_sidebar_collapsed) {
			void(0);
		}
		else {
			dispatch(toggleSidebarCollapsed());
		}
	}
	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" style={{justifyContent:"center"}} onClick={() => handleToggleSideBarCollapsed()}>

				<ControlGroup vertical={false} style={{alignSelf:"center", justifyContent:"center"}}>
					<Icon icon="error" className='red mr-5' size={30}/><H2 className='red'>{message}</H2>
				</ControlGroup>

			</div>
		</div>

	);

}
