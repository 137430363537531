import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	fetchAllItemClassesAsync,
	createItemClassAsync,
	updateItemClassAsync,
	importItemClassesAsync,
	toggleSidebarCollapsed,
	deleteItemClassesAsync,
	skipProcessAsync,
	fetchAllLocationsAsync,
	duplicateItemClassesAsync
} from "../../auth/authSlice";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Switch,
	Card,
	Icon,
	Checkbox
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import { SideBar } from '../../../SideBar';
import { useHistory } from "react-router-dom";
import { ItemClassSpreadsheet } from "../../../spreadsheets/ItemClassSpreadsheet";

export function ItemClass() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [current_application] = useState(auth.applications.find((app) => app.name===translate.text.ITEM_CLASS));
	const [id, setId] = useState(null);
	const [item_class_description, setItemClassDescription] = useState("");
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_dialog_open, setIsConfirmDialogOpen] = useState(false);
	const [profit_cost_centers, setProfitCostCenters] = useState([]);
	const [selected_profit_cost_centers, setSelectedProfitCostCenters] = useState([]);
	const [for_delivery_receipt, setForDeliveryReceipt] = useState(false);
	const [is_editable, setIsEditable] = useState(true);
	const [is_confirm_duplicate_dialog_open, setIsConfirmDuplicateDialogOpen] = useState(false);
	const DEFAULT_HEADER = translate.worksheet.default_header.ITEM_CLASS;
	const DEFAULT_ALIGNMENT = translate.worksheet.default_alignment.ITEM_CLASS;
	const IMPORT_HEADER = translate.worksheet.import_header.ITEM_CLASS;
	const IMPORT_ALIGNMENT = translate.worksheet.import_alignment.ITEM_CLASS;
	const COMMENT = translate.worksheet.import_comment.ITEM_CLASS;
	const NUM_ROWS = 1000;
	const NUM_COLS = DEFAULT_HEADER.length;
	const IMPORT_LIMIT = 700;
	let DEFAULT_DATA_SET = new Array(NUM_ROWS).fill("").map(() => new Array(NUM_COLS).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: NUM_ROWS,
		num_cols: NUM_COLS,
		num_frozen_columns: 0,
		import_limit: IMPORT_LIMIT,
		focused_cell: null,
		selected_regions: null,
		header: DEFAULT_HEADER,
		alignment: DEFAULT_ALIGNMENT,
		comment: COMMENT,
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.ITEM_CLASS));
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllItemClassesAsync({}))
			.then((response) => response.payload.data ? 
				setWorkSheet({
					is_readonly_mode: true,
					num_rows: NUM_ROWS>response.payload.data.item_classes.length ? NUM_ROWS : response.payload.data.item_classes.length,
					num_cols: DEFAULT_HEADER.length,
					num_frozen_columns: 0,
					import_limit: IMPORT_LIMIT,
					focused_cell: null,
					selected_regions: null,
					header: DEFAULT_HEADER,
					alignment: DEFAULT_ALIGNMENT,
					comment: [],
					data_set: response.payload.data.item_classes.map((ds) => [
						ds.id,
						ds.name,
						ds.location_names.join(', '),
						ds.for_delivery_receipt ? auth.enums.boolean.TRUE : auth.enums.boolean.FALSE,
						ds.created_at,
						ds.updated_at,
					])
				}) : void(0)
			);
			dispatch(fetchAllLocationsAsync({})).then((response) => (response.payload.status===resources.status.SUCCESS ? setProfitCostCenters([...response.payload.data.location_tree[0].childNodes.map((node) => ({label:`${node.code} | ${node.name}`, value:node.id})), ...response.payload.data.location_tree[1].childNodes.map((node) => ({label:`${node.code} | ${node.name}`, value:node.id})), ...response.payload.data.location_tree[2].childNodes.map((node) => ({label:`${node.code} | ${node.name}`, value:node.id}))]) : void(0)));
		}
	}, [
		dispatch,
		translate.breadcrumb.ITEM_CLASS,
		auth.enums.boolean,
		DEFAULT_ALIGNMENT,
		DEFAULT_HEADER,
		worksheet.is_readonly_mode
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const onClearButtonClicked = () => {
		setId(null);
		setItemClassDescription("");
		setSelectedProfitCostCenters([]);
		setForDeliveryReceipt(false);
		setIsEditable(true);
		setSelectedRows([]);
	};

	const onSkipButtonClicked = () => {
		if (auth.active_company.application_id) {
			let nextApplication = auth.applications.find((app) => (app.id===auth.active_company.application_id));
			dispatch(skipProcessAsync({application:'item-class'})).then((response) => response.payload.data.next_application ? history.push(response.payload.data.next_application.route) : history.push(nextApplication.route));
		}
	};

	const onDeleteRowsButtonClicked = () => {
		let ids = selected_rows.map(
			(row) => worksheet.data_set[row][0]
		);
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: worksheet.data_set.filter((ds) => (!ids.includes(ds[0])))
				});
				setSelectedRows([]);
			}
		}
		dispatch(deleteItemClassesAsync(ids)).then((response) => handleResponse(response));
	};

	const onImportButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			let dataSet = worksheet.data_set.filter((dataSet) => dataSet[0].length>0||dataSet[1].length>0||dataSet[2].length>0);
			if (dataSet.length<=worksheet.import_limit) {
				const handleResponse = (i, length, size, status) => {
					if (status===resources.status.SUCCESS) {
						if (i===0) {
							onClearCellsButtonClicked();
							toast.success(translate.message.IMPORT_ITEM_CLASSES_QUEUED, Util.getToasterStyle(resources.status.SUCCESS));
						}
						if (i===length-1) {
							toast.success(translate.message.IMPORT_ITEM_CLASSES_FINISHED, Util.getToasterStyle(resources.status.SUCCESS));
						}
					}
				} 
				let size = 20;
				let chunks = Util.chunk(dataSet, size);
				let length = chunks.length;
				for (let i=0; i<chunks.length; i++) {
					dispatch(
						importItemClassesAsync(chunks[i])
					).then((response) => (handleResponse(i, length, size, response.payload.status)));
				}
			}
			else {
				toast.error(
					translate.message.IMPORT_LIMIT_REACHED,
					Util.getToasterStyle(resources.status.ERROR)
				);
			}
		}
	};

	const onEditButtonClicked = (row) => {
		let locationIds = auth.item_class_location.filter((icl) => (icl.item_class_id===worksheet.data_set[row][0])).map((icl) => (icl.location_id));
		setId(worksheet.data_set[row][0]);
		setItemClassDescription(worksheet.data_set[row][1]);
		setSelectedProfitCostCenters(profit_cost_centers.filter((pcc) => (locationIds.includes(pcc.value))));
		setForDeliveryReceipt(worksheet.data_set[row][3]===auth.enums.boolean.TRUE);
		setIsEditable(!Object.values(auth.enums.item_classes).includes(worksheet.data_set[row][1]));
		window.scrollTo(0,0);
	};

	const onDuplicateRowsButtonClicked = () => {
		const handleResponse = (response) => {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: [
					...response.payload.data.item_classes.map((ds) => ([
						ds.id,
						ds.name,
						ds.location_names.join(', '),
						ds.for_delivery_receipt ? auth.enums.boolean.TRUE : auth.enums.boolean.FALSE,
						ds.created_at,
						ds.updated_at,
					])),
					...worksheet.data_set
				]
			});
			onClearButtonClicked();
		}
		let itemClasses = selected_rows.map(
			(row) => ({
				id:worksheet.data_set[row][0],
			})
		);
		dispatch(
			duplicateItemClassesAsync(itemClasses)
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleResponse(response) : null));		
	};

	const onFreezePaneButtonClicked = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	const onCreateButtonClicked = () => {
		const handleCreateResponse = (response) => {
			let itemClass = response.payload.data.item_class;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: [
					[
						itemClass.id,
						itemClass.name,
						itemClass.location_names.join(', '),
						itemClass.for_delivery_receipt ? auth.enums.boolean.TRUE : auth.enums.boolean.FALSE,
						itemClass.created_at,
						itemClass.updated_at,
					],
					...worksheet.data_set
				]
			});
			onClearButtonClicked();
		}
		dispatch(
			createItemClassAsync({
				item_class_code: item_class_description,
				item_class_description: item_class_description,
				for_delivery_receipt: for_delivery_receipt ? for_delivery_receipt : '',
				selected_profit_cost_centers: selected_profit_cost_centers.map((pcc) => pcc.value)
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleCreateResponse(response) : null))
	}

	const onUpdateButtonClicked = () => {
		const handleUpdateResponse = (response) => {
			let itemClass = response.payload.data.item_class;
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set.map((ds) => (ds[0]===itemClass.id ? [
					itemClass.id,
					itemClass.name,
					itemClass.location_names.join(', '),
					itemClass.for_delivery_receipt ? auth.enums.boolean.TRUE : auth.enums.boolean.FALSE,
					itemClass.created_at,
					itemClass.updated_at,
				] : ds))
			});
		}
		dispatch(
			updateItemClassAsync({
				id: id,
				item_class_code: item_class_description,
				item_class_description: item_class_description,
				for_delivery_receipt: for_delivery_receipt ? for_delivery_receipt : '',
				selected_profit_cost_centers: selected_profit_cost_centers.map((pcc) => pcc.value)
			})
		).then((response) => (response.payload && response.payload.status===resources.status.SUCCESS ? handleUpdateResponse(response) : null))
	}

	const onSaveAsPDFButtonClicked = () => {
		PDF.export(
			translate.worksheet.file_name.ITEM_CLASS,
			worksheet.header,
			worksheet.data_set,
			auth.active_company,
			"a3",
			"landscape",
			[],
			[50, 120, "*", 90, 90, 90]
		);
	}

	const onSaveAsXLSXButtonClicked = () => {
		Excel.export(
			translate.worksheet.file_name.ITEM_CLASS,
			worksheet.header,
			worksheet.data_set,
			auth.active_company
		);
	}

	const onSpreadSheetModeSwitchChanged = () => {
		let newMode = !worksheet.is_readonly_mode;
		setWorkSheet({
			is_readonly_mode: newMode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearCellsButtonClicked = () => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: IMPORT_HEADER,
			alignment: IMPORT_ALIGNMENT,
			comment: COMMENT,
			data_set: DEFAULT_DATA_SET
		});
	}

	const onClearSelectedCellsButtonClicked = () => {
		if (!worksheet.is_readonly_mode) {
			if (worksheet.selected_regions!=null) {
				let dataSet = [];
				let selectedRegions = worksheet.selected_regions;
				for (let i=0; i<worksheet.data_set.length; i++) {
					let row = [];
					for (let j=0; j<worksheet.data_set[i].length; j++) {
						row.push(worksheet.data_set[i][j]);
					}
					dataSet.push(row);
				}
				for (let i=0; i<selectedRegions.length; i++) {
					let selectedRows = selectedRegions[i].rows;
					let selectedCols = selectedRegions[i].cols;
					let startRow = selectedRows[0];
					let endRow = selectedRows[1];
					let startCol = selectedCols[0];
					let endCol = selectedCols[1];
					for (let j=startRow; j<=endRow; j++) {
						if (dataSet[j]!==undefined) {
							for (let k=startCol; k<=endCol; k++) {
								if (dataSet[j][k]!==undefined) {
									dataSet[j][k] = "";
								}
							}
						}
					}
				}
				setWorkSheet({
					is_readonly_mode: worksheet.is_readonly_mode,
					num_rows: worksheet.num_rows,
					num_cols: worksheet.num_cols,
					num_frozen_columns: worksheet.num_frozen_columns,
					import_limit: worksheet.import_limit,
					focused_cell: worksheet.focused_cell,
					selected_regions: worksheet.selected_regions,
					header: worksheet.header,
					alignment: worksheet.alignment,
					comment: worksheet.comment,
					data_set: dataSet
				});
			}
		}
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
						{
							label: translate.text.NOTE_INLINE_EDITING_FIELD_LABEL,
							value: translate.text.NOTE_INLINE_EDITING_FIELD_VALUE
						},
						{
							label: translate.text.NOTE_PASTING_DATA_LABEL,
							value: translate.text.NOTE_PASTING_DATA_VALUE
						},
					]}
				/>

				{
					auth.active_company.application_id && auth.active_company.application_id!==current_application.id &&
					<div className="next-step-container">
						<Button
							text={translate.button.SKIP_PROCESS}
							className="next-step-button"
							large={true}
							rightIcon={<Icon icon="arrow-right" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onSkipButtonClicked}
						/>
					</div>
				}

				{ Util.functionTitle(translate.text.ITEM_CLASS) }
				{
					worksheet.is_readonly_mode &&
					<div className="function-sub-title">
						{translate.text.MANAGE_ITEM_CLASSES_SUBTITLE}
					</div>
				}

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						<Button
							text={id==null ? translate.button.CREATE : translate.button.UPDATE}
							className="function-button"
							disabled={auth.status === resources.status.LOADING}
							icon={<Icon icon="floppy-disk" className="orange"/>}
							onClick={(e) => id ? onUpdateButtonClicked() : onCreateButtonClicked()}
						/>
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onClearButtonClicked}
						/>
					</ButtonGroup>
				}

				{
					worksheet.is_readonly_mode &&
					<Card className="function-card">
						<div className="row">

							<TextField
								className="col-sm-12 col-md-3 col-lg-3 text-field"
								size="small"
								variant="outlined"
								value={item_class_description}
								onChange={(e) => setItemClassDescription(e.target.value)}
								label={'Item Class Description'}
								inputProps={{readOnly:!is_editable}}
							/>

							<div className="col-sm-12 col-md-2 col-lg-2 text-field center" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
								<Checkbox
									checked={for_delivery_receipt}
									large={false}
									inline={true}
									label={"For Delivery Receipt"}
									onChange={() => setForDeliveryReceipt(!for_delivery_receipt)}
									disabled={!is_editable}
								/>
							</div>

							<div className="col-sm-12 col-md-6 col-lg-6 text-field">
								<MuiAutocomplete
									setSelectedValues={setSelectedProfitCostCenters}
									selected_values={selected_profit_cost_centers}
									isMultiple={true}
									label={"Assign Profit Cost Center/s"}
									data={profit_cost_centers}
								/>
							</div>
						</div>
					</Card>
				}

				<ButtonGroup className="button-group" minimal={false}>
					<Switch
						className="switch-mode"
						checked={!worksheet.is_readonly_mode}
						disabled={auth.status === resources.status.LOADING}
						labelElement={<span className="switch-mode-label">{translate.label.SWITCH_MODE}</span>}
						innerLabelChecked={translate.label.IMPORT}
						innerLabel={translate.label.READ_ONLY}
						onChange={onSpreadSheetModeSwitchChanged}
					/>
					{
						worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.SAVE_AS_PDF}
								className="function-button"
								icon={<Icon icon="document" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsPDFButtonClicked}
							/>
							<Button
								text={translate.button.SAVE_AS_XLSX}
								className="function-button"
								icon={<Icon icon="th" className="orange"/>}
								disabled={auth.status === resources.status.LOADING}
								onClick={onSaveAsXLSXButtonClicked}
							/>
							<Button
								text={selected_rows.length>0 ? `${translate.button.UNSELECT_ALL} (${selected_rows.length})` : translate.button.SELECT_ALL}
								disabled={auth.status === resources.status.LOADING}
								className="function-button"
								icon={<Icon icon="select" className="orange"/>}
								onClick={e => setSelectedRows(selected_rows.length>0 ? [] : worksheet.data_set.map((dataSet, key) => key))}
							/>
							{
								selected_rows.length>0 &&
								<Button
									text={`${translate.button.DELETE_ROWS}  (${selected_rows.length})`}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="delete" className="orange"/>}
									onClick={e => setIsConfirmDialogOpen(!is_confirm_dialog_open)}
								/>
							}
							{
								selected_rows.length>0 &&
								<Button
									text={`${translate.button.DUPLICATE_SELECTED_ROWS}  (${selected_rows.length})`}
									disabled={auth.status === resources.status.LOADING}
									className="function-button"
									icon={<Icon icon="duplicate" className="orange"/>}
									onClick={e => setIsConfirmDuplicateDialogOpen(!is_confirm_duplicate_dialog_open)}
								/>
							}
						</>
					}
					{
						!worksheet.is_readonly_mode &&
						<>
							<Button
								text={translate.button.IMPORT}
								className="function-button"
								icon={<Icon icon="upload" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onImportButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_SELECTED_CELLS}
								className="function-button"
								icon={<Icon icon="clean" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearSelectedCellsButtonClicked}
							/>
							<Button
								text={translate.button.CLEAR_CELLS}
								className="function-button"
								icon={<Icon icon="graph-remove" className="orange"/>}
								disabled={auth.status===resources.status.LOADING}
								onClick={onClearCellsButtonClicked}
							/>
						</>
					}
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={onFreezePaneButtonClicked}
						/>
					}
				</ButtonGroup>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDialogOpen}
					confirmAction={onDeleteRowsButtonClicked}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>Once deleted, Item classes will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain item classes are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_duplicate_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDuplicateDialogOpen}
					confirmAction={onDuplicateRowsButtonClicked}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">DUPLICATE</span> the selected row/s ?
						</span>
					}
					helperText={
		                <div>
		                    <ol className="bp3-list">
		                        <li>The system will make a copy of each of the selected row/s once you click the "Confirm" button.</li>
		                        <li>You need to update the item class description of each of the copies to be able to assign it in the item setup.</li>
		                    </ol>
		                </div>
					}
				/>

				<ItemClassSpreadsheet
					enable_select={true}
					enable_open={false}
					enable_edit={true}
					selected_rows={selected_rows}
					setSelectedRows={setSelectedRows}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
					onEditButtonClicked={onEditButtonClicked}
				/>

			</div>
		</div>
	);
}
