import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, toggleSidebarCollapsed, fetchAllMessagesAsync, updateMessageStatusAsync, updateMessagesStatusAsync, deleteMessagesAsync } from './features/auth/authSlice';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { resources } from "./resources/index";
import { H2, Tab, Tabs, Card, InputGroup, Button, Icon, Checkbox } from "@blueprintjs/core";
import { SideBar } from './SideBar';

export function Inbox() {

	const auth = useSelector(selectAuth);
	//const translate = resources["lang"][auth.locale];
	const dispatch = useDispatch();
	const [unread_messages, setUnreadMessages] = useState([]);
	const [filtered_unread_messages, setFilteredUnreadMessages] = useState([]);
	const [read_messages, setReadMessages] = useState([]);
	const [filtered_read_messages, setFilteredReadMessages] = useState([]);
	const [active_tab, setActiveTab] = useState("tab-0");
	const [search_text, setSearchText] = useState("");
	const [select_all_unread, setSelectAllUnread] = useState(false);
	const [selected_unread, setSelectedUnread] = useState([]);
	const [select_all_read, setSelectAllRead] = useState(false);
	const [selected_read, setSelectedRead] = useState([]);
	const [message_labels] = useState(Object.values(auth.enums.message_labels));

	const initFetch = useCallback(() => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setUnreadMessages(response.payload.data.messages.unread);
				setReadMessages(response.payload.data.messages.read);
			}
		}
		let label = message_labels[active_tab.split("-")[1]];
		dispatch(fetchAllMessagesAsync({label:label})).then((response) => handleResponse(response));
	}, [dispatch, active_tab, message_labels]);

	useEffect(() => {
		initFetch()
	}, [initFetch]);
	
	const handleClickMessageEvent = (msg, isUnread) => {
		if (isUnread) {
			const handleResponse = (response) => {
				if (response.payload.status===resources.status.SUCCESS) {
					setUnreadMessages(unread_messages.filter((um) => (um.id!==response.payload.data.id)));
					setReadMessages([response.payload.data, ...read_messages]);
					window.open(response.payload.data.link, '_blank');
				}
			}
			dispatch(updateMessageStatusAsync({id:msg.id})).then((response) => handleResponse(response));
		}
		else {
			window.open(msg.link, '_blank');
		}
	}

	const onSearchTextChanged = async (event) => {
		let searchText = event.target.value.toLowerCase();
		let filteredUnreadMessages = unread_messages.filter((um) => (um.text.toLowerCase().indexOf(searchText)>=0));
		setFilteredUnreadMessages((filteredUnreadMessages));
		let filteredReadMessages = read_messages.filter((rm) => (rm.text.toLowerCase().indexOf(searchText)>=0));
		setFilteredReadMessages((filteredReadMessages));
		setSearchText(event.target.value);
	}

	const onSelectAllUnreadChanged = async () => {
		let selectAllUnread = !select_all_unread;
		setSelectAllUnread(selectAllUnread);
		if (selectAllUnread) {
			let selected = (search_text.length===0 ? unread_messages.map((um) => (um.id)) : filtered_unread_messages.map((fum) => (fum.id)));
			setSelectedUnread(selected);
		}
		else {
			setSelectedUnread([]);
		}
	}

	const onSelectAllReadChanged = async () => {
		let selectAllRead = !select_all_read;
		setSelectAllRead(selectAllRead);
		if (selectAllRead) {
			let selected = (search_text.length===0 ? read_messages.map((rm) => (rm.id)) : filtered_read_messages.map((frm) => (frm.id)));
			setSelectedRead(selected);
		}
		else {
			setSelectedRead([]);
		}
	}

	const renderPanel = () => {
		return (
			<div>
				<Checkbox onChange={() => onSelectAllUnreadChanged()} checked={select_all_unread} inline={true} large={true} labelElement={<span className='bold'>Unread</span>}/>
				<Card style={{width:"100%", height:"30vh", overflowX:"hidden", overflowY:"scroll", padding:0}}>
					{
						(search_text.length>0 ? filtered_unread_messages : unread_messages).map((um, key) => (
							<div key={key} className='message-row bold'>
								<Checkbox onChange={() => setSelectedUnread(selected_unread.includes(um.id) ? selected_unread.filter((suid) => (suid!==um.id)) : [...selected_unread, um.id])} checked={selected_unread.includes(um.id)} inline={true} large={false} style={{paddingTop:0, marginTop:0, paddingBottom:0, marginBottom:0}}/> 
								<span onClick={() => handleClickMessageEvent(um, true)}>{um.text}</span>
							</div>
						))
					}
					{
						unread_messages.length===0 &&
						<p style={{textAlign:"center", marginTop:5}}>No unread messages as of the moment.</p>
					}
				</Card>
				<p style={{textAlign:"right", marginTop:5, fontSize:"10pt"}}>{(search_text.length>0 ? filtered_unread_messages.length : unread_messages.length)} entries</p>


				<Checkbox onChange={() => onSelectAllReadChanged()} checked={select_all_read} inline={true} large={true} labelElement={<span className='bold'>Read</span>}/>
				<Card style={{width:"100%", height:"30vh", overflowX:"hidden", overflowY:"scroll", padding:0}}>
					{
						(search_text.length>0 ? filtered_read_messages : read_messages).map((rm, key) => (
							<div key={key} className='message-row'>
								<Checkbox onChange={() => setSelectedRead(selected_read.includes(rm.id) ? selected_read.filter((srid) => (srid!==rm.id)) : [...selected_read, rm.id])} checked={selected_read.includes(rm.id)} inline={true} large={false} style={{paddingTop:0, marginTop:0, paddingBottom:0, marginBottom:0}}/> 
								<span onClick={() => handleClickMessageEvent(rm, false)}>{rm.text}</span>
							</div>
						))
					}
					{
						read_messages.length===0 &&
						<p style={{textAlign:"center", marginTop:5}}>No read messages as of the moment.</p>
					}
				</Card>
				<p style={{textAlign:"right", marginTop:5, fontSize:"10pt"}}>{(search_text.length>0 ? filtered_read_messages.length : read_messages.length)} entries</p>
			</div>
		);
	}

	const onMarkSelectedAsReadButtonClicked = () => {
		setUnreadMessages(unread_messages.filter((um) => (!selected_unread.includes(um.id))));
		setReadMessages([...unread_messages.filter((um) => (selected_unread.includes(um.id))), ...read_messages]);
		if (search_text.length>0) {
			setFilteredUnreadMessages(filtered_unread_messages.filter((um) => (!selected_unread.includes(um.id))));
			setFilteredReadMessages([...filtered_unread_messages.filter((um) => (selected_unread.includes(um.id))), ...filtered_read_messages]);
		}
		dispatch(updateMessagesStatusAsync({ids:selected_unread}));
		setSelectedUnread([]);
		setSelectAllUnread(false);
		setSearchText("");
	}

	const onDeleteMessagesButtonClicked = () => {
		const handleResponse = (response) => {
			if (response.payload.status===resources.status.SUCCESS) {
				setReadMessages(read_messages.filter((rm) => !selected_read.includes(rm.id)));
				if (search_text.length>0) {
					setFilteredReadMessages(filtered_read_messages.filter((frm) => !selected_read.includes(frm.id)));
				}
				setSelectedRead([]);
				setSelectAllRead(false);
				setSearchText("");
			}
		}
		dispatch(deleteMessagesAsync({ids:selected_read})).then((response) => handleResponse(response));
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>
				<div className='row' style={{minHeight:"100vh"}}>
					<div className='col-sm-12 col-md-4 col-lg-4'>
						<div className='row' style={{marginTop:30, marginBottom:30}}>
							<div className='col-sm-12 col-md-8 col-lg-9'>
								<InputGroup 
									autoComplete='off'
									fill={true}
									asyncControl={true}
									leftIcon="search"
									placeholder="Search by typing a keyword"
									round={true}
									large={true}
									value={search_text}
									onChange={(evt) => onSearchTextChanged(evt)}
								/>
							</div>
						</div>
						<div className='row mt-5'>
							<div className='col-sm-12 col-md-8 col-lg-9'>
								<Button onClick={() => onMarkSelectedAsReadButtonClicked()} icon={<Icon className='orange' icon="star"/>} large={true} text={`Mark Selected as "Read"`} fill={true} disabled={selected_unread.length===0}/>
							</div>
						</div>
						<div className='row mt-5'>
							<div className='col-sm-12 col-md-8 col-lg-9'>
								<Button onClick={() => onDeleteMessagesButtonClicked()} icon={<Icon className='orange' icon="trash"/>} large={true} text={`Delete Selected "Read" Messages`} fill={true} disabled={selected_read.length===0}/>
							</div>
						</div>
					</div>
					<div className='col-sm-12 col-md-8 col-lg-8' style={{background:"beige"}}>
						<div style={{margin:10}}>
							<H2>Inbox</H2>

							<Tabs id="tab-container" onChange={(newTab) => setActiveTab(newTab)} selectedTabId={active_tab}>
								{
									message_labels.map((label,key) => (
										<Tab key={key} id={"tab-"+key} title={label} panel={renderPanel(label)}/>
									))
								}
							</Tabs>

						</div>					
					</div>
				</div>
			</div>
		</div>
	);

}
