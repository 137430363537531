import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from './features/auth/authSlice';
import { ErrorPage } from "./ErrorPage";

function ProtectedRoute({ component: Component, ...rest }) {

	const auth = useSelector(selectAuth);

	const renderComponent = (props) => {
		if (auth && auth.token && auth.user) {
			let application = auth.applications.find((app) => (app.route===props.location.pathname));
			if (application && application.is_function) {
				if (!application.is_enabled || !application.is_purchased) {
					return (
						<ErrorPage message={"Page is not yet available."}/>
					)
				}
				else {
					let permissions = auth.application_user.filter((appUser) => (appUser.user_id===auth.user.id)).map((appUser) => (appUser.application_id));
					if (!permissions.includes(application.id)) {
						return (
							<ErrorPage message={"Page is not allowed to access."}/>
						)
					}
				}
			}
			return (
				<Component {...props} />
			)
		}
		else {
			return (
				<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
			)
		}
	}

	return (
		<Route
			{...rest}
			render={props =>
				renderComponent(props)
			}
		/>
	)

}

export default ProtectedRoute;