import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Tooltip, Position } from "@blueprintjs/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "white"
    }
  }
}));

export function MuiAutocomplete({
	isMultiple,
	isDisabled,
	isReadOnly,
	label,
	data,
	createFunction,
	createParameters,
	setSelectedValue,
	selected_value,
	setSelectedValues,
	selected_values,
	onInputChange,
	setData,
	disableClearable
}) {
	const dispatch = useDispatch();
	const filter = createFilterOptions();
	const classes = useStyles();
	const limit_tags = 5;
	const [select_all, setSelectAll] = useState(false);

	useEffect(() => {
		if (isMultiple) {
			if (selected_values && selected_values.length===0) {
				setSelectAll(false);
			}
		}
	}, [isMultiple, selected_values]);

	const handleSelectAllCheckboxChangedEvent = () => {
		let selectAll = !select_all;
		setSelectAll(selectAll);
		setSelectedValues(selectAll ? data : []);
	}

	if (isMultiple) {
		return (
			<div className="MuiInputAutocomplete-formControl" style={{display:"flex", justifyContent:"center"}}>
				<Autocomplete
					ChipProps={{
						size:"small",
					}}
					limitTags={limit_tags}
					getLimitTagsText={(more) => `+${more} See more`}
					fullWidth
					disableClearable={disableClearable===undefined||disableClearable}
					multiple
					disabled={isDisabled !== undefined ? isDisabled : false}
					value={selected_values}
					onChange={(event, newValue) => {
						setSelectedValues(newValue);
					}}
					options={data}
					getOptionLabel={(option) => option ? option.label : ''}
					renderOption={(option) => (
						<>
							<span style={{ fontSize: "10pt" }}>{option.label}</span>
						</>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							size="small"
							variant="outlined"
							className={classes.root}
							label={label}
						/>
					)}
				/>
				{
					(isDisabled === undefined || !isDisabled) &&
					<Tooltip intent={"primary"} content={`${select_all ? "Unselect" : "Select"} All`} position={Position.TOP_RIGHT} openOnTargetFocus={false}>
						<Checkbox 
							style={{paddingTop:"6px", marginLeft:"10px", paddingRight:"0px", marginRight:"0px"}} 
							inline={true} 
							large={true} 
							checked={select_all} 
							onChange={() => handleSelectAllCheckboxChangedEvent()}
						/>
					</Tooltip>
				}
				
			</div>
		);
	} else {
		return (
			<div className="MuiInputAutocomplete-formControl">
				<Autocomplete
					ChipProps={{
						size:"small",
					}}
					limitTags={limit_tags}
					getLimitTagsText={(more) => `+${more} See more`}
					fullWidth
					disableClearable={disableClearable===undefined||disableClearable}
					disabled={isDisabled !== undefined ? isDisabled : false}
					value={selected_value}
					onChange={(event, newValue) => {
						if (typeof newValue === "string") {
							setSelectedValue({
								label: newValue,
							});
						} else if (newValue && newValue.inputValue) {
							Object.keys(createParameters).forEach((key) => {
								createParameters[key] = newValue.inputValue;
							});
							dispatch(createFunction(createParameters)).then(
								(response) => {
									let newData = {
										label: response.payload.data.name,
										value: response.payload.data.id,
									};
									setData([...data, newData]);
									setSelectedValue(newData);
								}
							);
						} else {
							setSelectedValue(newValue);
						}
					}}
					filterOptions={(options, params) => {
						const filtered = filter(options, params);
						if (createFunction !== undefined) {
							const { inputValue } = params;
							const isExisting = options.some(
								(option) => inputValue === option.label
							);
							if (inputValue !== "" && !isExisting) {
								filtered.push({
									inputValue,
									label: `Create new... "${inputValue}"`,
								});
							}
						}
						return filtered;
					}}
					selectOnFocus
					clearOnBlur
					handleHomeEndKeys
					options={data}
					getOptionLabel={(option) => {
						return option ? option.label : '';
					}}
					renderOption={(option) => (
						<>
							<span style={{ fontSize: "10pt" }}>{option.label}</span>
						</>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							size="small"
							variant="outlined"
							className={classes.root}
							label={label}
						/>
					)}
				/>
			</div>
		);
	}
}