import React from "react";
import logo from "../../logo.png";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { forgotPasswordAsync, selectAuth } from "./authSlice";
import { resources } from "../../resources/index";
import {
	Button,
	FormGroup,
	Icon,
	IconSize,
	InputGroup,
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

export function PasswordResetLink() {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const history = useHistory();
	const translate = resources["lang"][auth.locale];
	const [email, setEmail] = useState("");

	return (
			<div className="auth-form">
				<img
					src={logo}
					className="auth-logo"
					alt={resources.app.NAME}
				/>
				<form>
					<FormGroup
						className="input-form-group"
						label={translate.label.EMAIL_ADDRESS}
						labelInfo={translate.label.REQUIRED}
					>
						<InputGroup
							leftIcon={
								<Icon
									icon="envelope"
									size={IconSize.SMALL}
									className="orange"
								/>
							}
							placeholder={translate.placeholder.ENTER_EMAIL_ADDRESS}
							large={true}
							round={true}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						></InputGroup>
					</FormGroup>

					<Button
						style={{marginTop:30}}
						disabled={auth.status === resources.status.LOADING}
						text={translate.button.SEND_PASSWORD_RESET_LINK}
						fill={true}
						large={true}
						onClick={() =>
							dispatch(
								forgotPasswordAsync(email)
							).then(() => (setEmail("")))
						}
					/>

					<Button
						style={{marginTop:10}}
						disabled={auth.status === resources.status.LOADING}
						text={translate.button.RETURN_TO_LOGIN}
						fill={true}
						large={true}
						onClick={() => history.push("/login")}
					/>

				</form>

			</div>
	);
}
