import axios from "axios";
import { resources } from "../resources/index";

export const api = axios.create({
    baseURL: process.env.REACT_APP_PORT ? `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}` : `${process.env.REACT_APP_HOST}`,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 0,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
});

api.interceptors.request.use(function (config) {
    const auth = localStorage.getItem(resources.slice.AUTH);
    const token = auth != null ? JSON.parse(auth).token : null;
    config.headers.Authorization = token ? `Bearer ${token}` : null;
    return config;
});
