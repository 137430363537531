import React from "react";
import ReactLoading from "react-loading";

export function LoadingScreen({is_loading}) {
	return (
		<div className="loading-screen">
			{
				is_loading ? 
				<ReactLoading
					type="spokes"
					color="#FFD700"
					height={80}
					width={60}
				/> : 
				null
			}
			<div className="loading-screen-text">
				{is_loading ? '' : ''}
			</div>
		</div>
	);
}

export default LoadingScreen;