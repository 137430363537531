import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Util from "../../../common/Util";
import toast from "react-hot-toast";
import { Note } from "../../../common/Note";
import { useParams } from "react-router";
import { MuiAutocomplete } from "./../../../common/MuiAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	createItemLocationTransferAsync,
	updateItemLocationTransferAsync,
	submitItemLocationTransferAsync,
	fetchAllItemLocationTransferLineItemsAsync,
	toggleSidebarCollapsed,
	fetchAllProfitCostCentersAsync,
	fetchAllItemsAsync,
	fetchAllLocationsAsync,
	fetchAllItemClassesAsync,
	voidItemLocationTransferAsync
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	H3,
	H5,
	FormGroup,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { ItemLocationTransferLineItem } from "./ItemLocationTransferLineItem";
import { SideBar } from '../../../SideBar';
import { Attachment } from "../../../common/Attachment";
import { Comment } from "../../../common/Comment";
import { TransactionLog } from "../../../common/TransactionLog";
import { useHistory } from "react-router-dom";
import { ItemLocationTransferSpreadsheet } from "../../../spreadsheets/ItemLocationTransferSpreadsheet";

export function ItemLocationTransfer() {
	const auth = useSelector(selectAuth);
	const history = useHistory();
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const default_line_items = Array(7).fill({
		id:"",
		item:null,
		quantity:"",
		unit_cost:"",
		amount:"",
		uom:null,
		from_location:null,
		to_location:null,
		serial_numbers:[],
		expiry_dates:[],
		expiry_quantities:[],
		item_conditions:[],
	});
	const { document_id } = useParams();
	const [id, setId] = useState(document_id ? document_id : null);
	const [item_location_transfer, setItemLocationTransfer] = useState(null);
	const [line_items, setLineItems] = useState(default_line_items);
	const [document_date, setDocumentDate] = useState(item_location_transfer ? moment(item_location_transfer.document_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT) : moment(new Date()).format(resources.date_format.INPUT));
	const [reference_number, setReferenceNumber] = useState("");
	const [transaction_description, setTransactionDescription] = useState("");
	const [transaction_logs, setTransactionLogs] = useState([]);
	const [attachments, setAttachments] = useState([]);
	const [profit_cost_center , setProfitCostCenter] = useState(null);
	const [profit_cost_centers, setProfitCostCenters] = useState([]);
	const [selected_rows, setSelectedRows] = useState([]);
	const [is_confirm_delete_dialog_open, setIsConfirmDeleteDialogOpen] = useState(false);
	const [is_confirm_submit_dialog_open, setIsConfirmSubmitDialogOpen] = useState(false);
	const [is_confirm_void_dialog_open, setIsConfirmVoidDialogOpen] = useState(false);
	const [is_editable, setIsEditable] = useState(true);
	const [items, setItems] = useState([]);
	const [locations, setLocations] = useState([]);
	const [item_classes, setItemClasses] = useState([]);
	const [with_expiry_serialized, setHasExpirySerialized] = useState(false);
	const [with_expiry, setWithExpiry] = useState(false);
	const [serialized, setSerialized] = useState(false);

	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: translate.worksheet.default_header.ITEM_LOCATION_TRANSFER,
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.ITEM_LOCATION_TRANSFER));
	}, [
		dispatch,
		translate.breadcrumb.ITEM_LOCATION_TRANSFER
	]);

	useEffect(() => {
		if (worksheet.is_readonly_mode) {
			dispatch(fetchAllItemsAsync({})).then((response) => (response.payload.data ? setItems(response.payload.data.items.filter((itm) => (Util.testDuplicateRegex(itm.code) && Util.testDuplicateRegex(itm.description)))) : void(0)));
			dispatch(fetchAllItemClassesAsync({})).then((response) => (response.payload.data ? setItemClasses(response.payload.data.item_classes.filter((ic) => Util.testDuplicateRegex(ic.name)).map((ic) => ({label:ic.name, value:ic.id}))) : void(0)));
			dispatch(fetchAllLocationsAsync({})).then((response) => (response.payload.data ? setLocations(response.payload.data.locations) : void(0)));
			const handleFetchProfitCostCenterResponse = (response) => {
				if (response.payload.data) {
					let branches = response.payload.data.branches.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((branch) => ({label:`${branch.code} | ${branch.name}`, type:branch.type, value:branch.id}));
					let departments = response.payload.data.departments.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((department) => ({label:`${department.code} | ${department.name}`, type:department.type, value:department.id}));
					let projects = response.payload.data.projects.filter((pcc) => (Util.testDuplicateRegex(pcc.code) && Util.testDuplicateRegex(pcc.name))).map((project) => ({label:`${project.code} | ${project.name}`, type:project.type, value:project.id}));
					setProfitCostCenters(branches.concat(departments).concat(projects));
				}
			}
			dispatch(fetchAllProfitCostCentersAsync({})).then((response) => handleFetchProfitCostCenterResponse(response));
		}
	}, [
		dispatch,
		worksheet.is_readonly_mode,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const loadDocument = useCallback((itemLocationTransfer, itemLocationTransferLineItems) => {
		setItemLocationTransfer(itemLocationTransfer);
		setId(itemLocationTransfer.id);
		setDocumentDate(moment(itemLocationTransfer.document_date, resources.date_format.DEFAULT).format(resources.date_format.INPUT));
		setReferenceNumber(itemLocationTransfer.reference_number);
		let profitCostCenter = profit_cost_centers.find((pcc) => pcc.value===JSON.parse(itemLocationTransfer.profit_cost_center).value && pcc.type===JSON.parse(itemLocationTransfer.profit_cost_center).type);
		setProfitCostCenter(profitCostCenter ? profitCostCenter : null);
		setTransactionLogs(itemLocationTransfer.transaction_logs);
		setTransactionDescription(itemLocationTransfer.description);
		setIsEditable(itemLocationTransfer.is_editable);
		let loadedItems = [];
		setHasExpirySerialized(false);
		setWithExpiry(false);
		setSerialized(false);
		for (let i=0; i<itemLocationTransferLineItems.length; i++) {
			let itemLocationTransferLineItem = itemLocationTransferLineItems[i];
			let quantity = itemLocationTransferLineItem.quantity;
			let unitCost = itemLocationTransferLineItem.unit_cost;
			let amount = itemLocationTransferLineItem.amount;
			let loadedItem =
			{
				id:itemLocationTransferLineItem.id,
				item:{
					label:itemLocationTransferLineItem.item.label, 
					default_uom_name:itemLocationTransferLineItem.item.unit_of_measure.name, 
					item_costing:itemLocationTransferLineItem.item.item_costing, 
					serial_number_type:itemLocationTransferLineItem.item.serial_number_type, 
					value:itemLocationTransferLineItem.item.id
				},
				quantity:quantity,
				unit_cost:unitCost,
				amount:amount,
				uom:{label:itemLocationTransferLineItem.unit_of_measure.code, value:itemLocationTransferLineItem.unit_of_measure.id},
				from_location:{label:`${itemLocationTransferLineItem.from_location.code} | ${itemLocationTransferLineItem.from_location.name}`, value:itemLocationTransferLineItem.from_location.id},
				to_location:{label:`${itemLocationTransferLineItem.to_location.code} | ${itemLocationTransferLineItem.to_location.name}`, value:itemLocationTransferLineItem.to_location.id},
				serial_numbers:itemLocationTransferLineItem.serial_numbers,
				expiry_dates:itemLocationTransferLineItem.expiry_dates.map((expDate) => (expDate.length>0 ? moment(expDate, resources.date_format.SYSTEM).format(resources.date_format.INPUT) : "")),
				expiry_quantities:itemLocationTransferLineItem.expiry_quantities.map((expQty) => (expQty.length>0 ? parseFloat(expQty) : 0)),
				item_conditions:itemLocationTransferLineItem.item_conditions,
			};
			loadedItems.push(loadedItem);
			if (itemLocationTransfer.approval_status===auth.enums.approval_status.DRAFT) {
				let inventoryItem = items.find((itm) => itm.id===itemLocationTransferLineItem.item.id);
				if (inventoryItem) {
					let hasExpiry = Util.stringToBoolean(inventoryItem.has_expiry);
					let withSerialNumber = Util.stringToBoolean(inventoryItem.with_serial_number);
					if (hasExpiry && withSerialNumber) {
						setHasExpirySerialized(true);
					} else 
					if (withSerialNumber) {
						setSerialized(true);
					} else 
					if (hasExpiry) {
						setWithExpiry(true);
					}
				}
			}
		}
		setLineItems(loadedItems);
	},[profit_cost_centers, auth.enums.approval_status.DRAFT, items]);

	useEffect(() => {
		if (id) {
			dispatch(fetchAllItemLocationTransferLineItemsAsync({item_location_transfer_id:id})).then((response) => loadDocument(response.payload.data.item_location_transfer, response.payload.data.item_location_transfer_line_items));
		}
	}, [dispatch, id, loadDocument]);

	const handleClearButtonClickedEvent = () => {
		setId(null);
		setItemLocationTransfer(null);
		setReferenceNumber("");
		setTransactionDescription("");
		setProfitCostCenter(null);
		setLineItems(default_line_items);
		setAttachments([]);
		setIsEditable(true);
		setSelectedRows([]);
		setSerialized(false);
		setWithExpiry(false);
		setHasExpirySerialized(false);
		history.push('/warehouse-inventory/item-location-transfer');
	};

	const handleDeleteRowsButtonClickedEvent = () => {
		let rows = selected_rows.map(
			(row) => worksheet.data_set[row]
		);
		let promises = [];
		for (let i = 0; i < rows.length; i++) {
			promises.push(
				dispatch(
					voidItemLocationTransferAsync({
						id: rows[i][0]
					})
				)
			);
		}
		Promise.all(promises).then((response) => {
			var success = true;
			var message = null;
			for (let i = 0; i < response.length; i++) {
				if (response[i].payload !== undefined) {
					if (response[i].payload.status !== resources.status.SUCCESS) {
						success = false;
						break;
					}
					else {
						if (message == null) {
							message = response[i].payload.message;
						}
					}
				}
			}
			if (success === true) {
				toast.success(
					message,
					Util.getToasterStyle(resources.status.SUCCESS)
				);
			}
			refreshData();
		});
	};

	const handleVoidButtonClickedEvent = () => {
		dispatch(
			voidItemLocationTransferAsync({
				id:id,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleVoidButtonClickedResponse(response) : null))
	};

	const handleSubmitButtonClickedEvent = () => {
		dispatch(
			submitItemLocationTransferAsync({
				id:id,
				document_date:document_date,
				reference_number:reference_number,
				transaction_description:transaction_description,
				profit_cost_center:profit_cost_center,
				line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.from_location!==null||lineItem.to_location!==null)),
				attachments:attachments,
			})
		).then((response) => (response.payload.status===resources.status.SUCCESS ? handleSubmitButtonClickedResponse(response) : null))
	};

	const handleVoidButtonClickedResponse = (response) => {
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.filter((ds) => (ds[0]!==response.payload.data.item_location_transfer.id))
		});
		setAttachments([]);
		refreshData();
		toast.success(
			response.payload.message,
			Util.getToasterStyle(resources.status.SUCCESS)
		);
	}

	const handleSubmitButtonClickedResponse = (response) => {
		let doc = response.payload.data.item_location_transfer;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.item_location_transfer.id ? [
				doc.id,
				doc.document_number,
				doc.document_date,
				doc.approval_status,
				doc.reference_number,
				doc.description,
				doc.profit_cost_center!==null ? JSON.parse(doc.profit_cost_center).label : null,
				doc.profit_cost_center!==null ? JSON.parse(doc.profit_cost_center).type : null,
			] : ds))
		});
		setAttachments([]);
		refreshData();
	}

	const handleUpdateButtonClickedResponse = (response) => {
		let doc = response.payload.data.item_location_transfer;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:worksheet.data_set.map((ds) => (ds[0]===response.payload.data.item_location_transfer.id ? [
				doc.id,
				doc.document_number,
				doc.document_date,
				doc.approval_status,
				doc.reference_number,
				doc.description,
				doc.profit_cost_center!==null ? JSON.parse(doc.profit_cost_center).label : null,
				doc.profit_cost_center!==null ? JSON.parse(doc.profit_cost_center).type : null,
			] : ds))
		});
		loadDocument(response.payload.data.item_location_transfer, response.payload.data.item_location_transfer_line_items);
		setAttachments([]);
	}

	const handleCreateButtonClickedResponse = (response) => {
		let doc = response.payload.data.item_location_transfer;
		setWorkSheet({
			is_readonly_mode: worksheet.is_readonly_mode,
			num_rows: worksheet.num_rows,
			num_cols: worksheet.num_cols,
			num_frozen_columns: worksheet.num_frozen_columns,
			import_limit: worksheet.import_limit,
			focused_cell: worksheet.focused_cell,
			selected_regions: worksheet.selected_regions,
			header: worksheet.header,
			alignment: worksheet.alignment,
			comment: worksheet.comment,
			data_set:[
				[
					doc.id,
					doc.document_number,
					doc.document_date,
					doc.approval_status,
					doc.reference_number,
					doc.description,
					doc.profit_cost_center!==null ? JSON.parse(doc.profit_cost_center).label : null,
					doc.profit_cost_center!==null ? JSON.parse(doc.profit_cost_center).type : null,
				],
				...worksheet.data_set
			]
		});
		loadDocument(response.payload.data.item_location_transfer, response.payload.data.item_location_transfer_line_items);
		setAttachments([]);
	}

	const handleEditButtonClickedEvent = (row) => {
		let id = worksheet.data_set[row][0];
		setId(id);
		window.scrollTo(0,0);
	};

	const handleTransferFromChangedEvent = (profitCostCenter) => {
		setProfitCostCenter(profitCostCenter);
		setLineItems(line_items.map((li) => ({
			id:li.id,
			item:li.item,
			quantity:li.quantity,
			unit_cost:li.unit_cost,
			amount:li.amount,
			uom:li.uom,
			from_location:null,
			to_location:null,
			serial_numbers:li.serial_numbers,
			expiry_dates:li.expiry_dates,
			expiry_quantities:li.expiry_quantities,
			item_conditions:li.item_conditions,
		})));
	}

	const refreshData = () => {
		handleClearButtonClickedEvent();
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>

				<Note
					contents={[
						{
							label: translate.text.NOTE_SWITCH_MODE_LABEL,
							value: translate.text.NOTE_SWITCH_MODE_VALUE
						},
						{
							label: translate.text.NOTE_SPREADSHEET_LABEL,
							value: translate.text.NOTE_SPREADSHEET_VALUE
						},
						{
							label: translate.text.NOTE_CREATE_LABEL,
							value: translate.text.NOTE_CREATE_VALUE
						},
						{
							label: translate.text.NOTE_FREEZE_PANE_LABEL,
							value: translate.text.NOTE_FREEZE_PANE_VALUE
						},
						{
							label: translate.text.NOTE_DROPDOWN_LABEL,
							value: translate.text.NOTE_DROPDOWN_VALUE
						},
						{
							label: translate.text.NOTE_INLINE_EDITING_FIELD_LABEL,
							value: translate.text.NOTE_INLINE_EDITING_FIELD_VALUE
						},
						{
							label: translate.text.NOTE_PASTING_DATA_LABEL,
							value: translate.text.NOTE_PASTING_DATA_VALUE
						},
					]}
				/>

				{ Util.functionTitle(translate.text.ITEM_LOCATION_TRANSFER) }
				<div className="function-sub-title">
					{"This form is use for internal transfer request of items within department, branch and/or project."}
				</div>

				{
					worksheet.is_readonly_mode &&
					<ButtonGroup className="button-group" minimal={false}>
						{
							id===null ?
							<>
								<Button
									text={translate.button.CREATE_DRAFT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING||!is_editable}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={(e) =>
										dispatch(
											createItemLocationTransferAsync({
												document_date:document_date,
												reference_number:reference_number,
												transaction_description:transaction_description,
												profit_cost_center:profit_cost_center,
												line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.from_location!==null||lineItem.to_location!==null)),
												attachments:attachments,
											})
										).then((response) => (response.payload.status===resources.status.SUCCESS ? handleCreateButtonClickedResponse(response) : null))
									}
								/>
							</>
							:
							<>
								<Button
									text={translate.button.UPDATE}
									className="function-button"
									disabled={auth.status === resources.status.LOADING||!is_editable}
									icon={<Icon icon="floppy-disk" className="orange"/>}
									onClick={(e) =>
										dispatch(
											updateItemLocationTransferAsync({
												id:id,
												document_date:document_date,
												reference_number:reference_number,
												transaction_description:transaction_description,
												profit_cost_center:profit_cost_center,
												line_items:line_items.filter((lineItem, key) => (lineItem.item!==null||lineItem.quantity.length>0||lineItem.unit_cost.length>0||lineItem.uom!==null||lineItem.from_location!==null||lineItem.to_location!==null)),
												attachments:attachments,
											})
										).then((response) => (response.payload.status===resources.status.SUCCESS ? handleUpdateButtonClickedResponse(response) : null))
									}
								/>
								<Button
									text={translate.button.SUBMIT}
									className="function-button"
									icon={<Icon icon="send-message" className="orange"/>}
									disabled={auth.status === resources.status.LOADING||!is_editable}
									onClick={e => setIsConfirmSubmitDialogOpen(!is_confirm_submit_dialog_open)}
								/>
								<Button
									text={translate.button.PRINT}
									className="function-button"
									disabled={auth.status === resources.status.LOADING}
									icon={<Icon icon="print" className="orange"/>}
									onClick={() =>
										PDF.exportItemLocationTransferForm(
											translate.worksheet.file_name.ITEM_LOCATION_TRANSFER,
											[item_location_transfer],
											line_items,
											auth.active_company
										)
									}
								/>
								{
									is_editable &&
									<Button
										text={translate.button.VOID}
										className="function-button"
										disabled={auth.status === resources.status.LOADING}
										icon={<Icon icon="ban-circle" className="orange"/>}
										onClick={e => setIsConfirmVoidDialogOpen(!is_confirm_void_dialog_open)}
									/>
								}
							</>
						}
						<Button
							text={translate.button.CLEAR}
							className="function-button"
							icon={<Icon icon="reset" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={handleClearButtonClickedEvent}
						/>
					</ButtonGroup>
				}

				{
					(with_expiry_serialized || serialized || with_expiry) &&
					<div style={{textAlign:"center", marginBottom:20, color:"blue"}}>
						{
							with_expiry_serialized &&
							<div>
								Your next step is to assign expiry date/s to corresponding serial number/s.
							</div>
						}
						{
							serialized &&
							<div>
								Your next step is to assign serial number/s to corresponding quantity.
							</div>
						}
						{
							with_expiry &&
							<div>
								Your next step is to assign expiry date to each quantity.
							</div> 
						}
					</div>
				}

				{
					worksheet.is_readonly_mode &&
					<div className="function-card">
						<div className="row">
							<Card
								className="col-sm-12 col-md-4 col-lg-3"
								style={{
									background: "lightblue",
								}}
							>
								<FormGroup>
									<H3 className="center" style={{color:"blue"}}>{item_location_transfer ? item_location_transfer.document_number : null}</H3>
									<H5 className="center" style={{color:"green"}}>{item_location_transfer ? item_location_transfer.approval_status : null}</H5>
								</FormGroup>
								<div className="row mb-5">
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										id="document_date"
										label={translate.placeholder.PICK_A_DATE}
										type="date"
										variant="outlined"
										size="small"
										defaultValue={document_date}
										inputProps={{readOnly:!is_editable}}
										onChange={(e) => setDocumentDate(e.target.value)}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<TextField
										className="col-sm-12 col-md-6 col-lg-6 mt-5"
										size="small"
										variant="outlined"
										value={reference_number}
										label={translate.placeholder.ENTER_TRANSACTION_REFERENCE_NUMBER}
										onChange={(e) =>
											setReferenceNumber(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
						        </div>

				        		<div className="row mb-5 mt-5">
									<div className="col-sm-12 col-md-12 col-lg-12">
										<MuiAutocomplete
											setSelectedValue={handleTransferFromChangedEvent}
											selected_value={profit_cost_center}
											isMultiple={false}
											label={`Select Profit/Cost Center ${profit_cost_center ? profit_cost_center.type : ''}`}
											data={profit_cost_centers}
											isDisabled={!is_editable}
										/>
									</div>
								</div>

						        <div className="row mb-5 mt-5">
									<TextField
										multiline
										minRows={4}
										className="col-sm-12 col-md-12 col-lg-12"
										size="small"
										variant="outlined"
										label={translate.placeholder.ENTER_TRANSACTION_DESCRIPTION}
										value={transaction_description}
										onChange={(e) =>
											setTransactionDescription(
												e.target.value
											)
										}
										inputProps={{readOnly:!is_editable}}
									/>
								</div>

								<Attachment
									id={id}
									transaction={item_location_transfer}
									setTransaction={setItemLocationTransfer}
									attachments={attachments}
									setAttachments={setAttachments}
								/>

								{
									id!==null && item_location_transfer &&
									<>
										<Comment
											id={id}
											document_type={item_location_transfer.document_type.name}
											comments={item_location_transfer.comments}
											created_by={item_location_transfer.created_by}
											setDocument={setItemLocationTransfer}
											is_editable={is_editable}
										/>
										<TransactionLog
											transaction_logs={transaction_logs}
										/>
				                    </>
								}

							</Card>
							{
								<ItemLocationTransferLineItem
									id={id}
									document_date={document_date}
									is_editable={is_editable}
									profit_cost_center={profit_cost_center}
									default_line_items={default_line_items}
									line_items={line_items}
									setLineItems={setLineItems}
									items={items}
									locations={locations}
									item_classes={item_classes}
								/>
							}
						</div>
					</div>
				}

				<Card className="function-card-spreadsheet">
					<ItemLocationTransferSpreadsheet
						enable_select={true}
						enable_edit={true}
						selected_rows={selected_rows}
						setSelectedRows={setSelectedRows}
						handleEditButtonClickedEvent={handleEditButtonClickedEvent}
						handleClearButtonClickedEvent={handleClearButtonClickedEvent}
						worksheet={worksheet}
						setWorkSheet={setWorkSheet}
						refreshData={refreshData}
						is_confirm_delete_dialog_open={is_confirm_delete_dialog_open}
						setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
						profit_cost_centers={profit_cost_centers}
						DEFAULT_DATA_SET={DEFAULT_DATA_SET}
					/>
				</Card>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_delete_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmDeleteDialogOpen}
					confirmAction={handleDeleteRowsButtonClickedEvent}
					helperText={
		                <div>
		                    <ol className="bp3-list">
								<li>Once deleted/voided, document will be removed automatically.</li>
		                        <li>System will automatically conduct further verification if deletion of certain document is/are allowed.</li>
		                    </ol>
		                </div>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_submit_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmSubmitDialogOpen}
					confirmAction={handleSubmitButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">SUBMIT</span> this document ?
						</span>
					}
				/>

				<ConfirmDialog
					is_confirm_dialog_open={is_confirm_void_dialog_open}
					setIsConfirmDialogOpen={setIsConfirmVoidDialogOpen}
					confirmAction={handleVoidButtonClickedEvent}
					confirmMessage={
						<span>
							Are you sure you want to <span className="bold">VOID</span> this document ?
						</span>
					}
				/>

			</div>
		</div>
	);
}
